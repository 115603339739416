import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Text, View } from 'react-native';
import { getValueByPath } from '../../../utilities/objectUtils';
import FormFieldError from './FormFieldError';
import { standardSpacings } from '../../styles/base.style';

const FileInput = ({
  field: { name }, form: {
    setFieldValue,
    touched,
    errors,
  }, onValueChange, ...props
}) => (
  <View style={{ marginBottom: standardSpacings.formVertical }}>
    <Text style={{ marginBottom: 10 }}>{props.label}</Text>
    <input
      type="file"
      {...props}
      onChange={(event) => {
        setFieldValue(name, event.currentTarget.files[0]);
      }}
      className="form-control"
    />
    <FormFieldError error={(getValueByPath(touched, name) && errors[name]) || null} />
  </View>
);

FileInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onValueChange: PropTypes.func,
  label: PropTypes.string.isRequired,
};

FileInput.defaultProps = {
  onValueChange: null,
};

const FormikFieldFileInput = (props) => (
  <Field
    component={FileInput}
    {...props}
  />
);

export default FormikFieldFileInput;
