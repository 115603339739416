/* eslint-disable no-param-reassign */
export function updateLastMessageOnGigChat(chats, gigCandidateChatId, msg, date) {
  return chats.map((chat) => {
    if (chat.get('chatId') === gigCandidateChatId) {
      return chat.set('lastMessageDate', date).set('lastMessage', msg);
    }
    return chat;
  });
}

export function updateLastMessageOnGigList(gigList, gigId, gigCandidateChatId, msg, date) {
  return gigList
    .map((gigChats) => {
      if (gigChats.get('gigId') === gigId || gigId === null) {
        const updatedGigChats = gigChats.set('lastMessageDate', date).set('lastMessage', msg);
        return updatedGigChats.update('candidateChats',
          (gigCandidateChats) => updateLastMessageOnGigChat(gigCandidateChats, gigCandidateChatId, msg, date));
      }
      return gigChats;
    });
}

function chatComparer() {
  return (chat1, chat2) => {
    if (chat1.get('lastMessageDate') == null && chat2.get('lastMessageDate') == null) {
      return chat1.get('chatId').localeCompare(chat2.get('chatId'));
    }
    if (chat1.get('lastMessageDate') == null) {
      return 1;
    }
    if (chat2.get('lastMessageDate') == null) {
      return -1;
    }
    return chat2.get('lastMessageDate') - chat1.get('lastMessageDate');
  };
}

export function sortGigChats(gigChats) {
  return gigChats.sort(chatComparer());
}

export function sortGigList(gigList) {
  const sorted = gigList.map((gig) => gig.update('candidateChats', (gigCandidateChats) => gigCandidateChats.sort(chatComparer())));

  return sorted.sort((gig1, gig2) => {
    if (gig1.get('lastMessageDate') == null && gig2.get('lastMessageDate') == null) {
      return gig1.get('gigId').localeCompare(gig2.get('gigId'));
    } if (gig1.get('lastMessageDate') == null) {
      return 1;
    } if (gig2.get('lastMessageDate') == null) {
      return -1;
    }
    return gig2.get('lastMessageDate') - gig1.get('lastMessageDate');
  });
}

export function updateUnreadOnGigChats(gigChats, unreadChatsMap) {
  return gigChats.map((candidateChat) => {
    const candidateChatId = candidateChat.get('chatId');
    const unreadFromMap = unreadChatsMap.get(candidateChatId);
    // before we get enough data, display as read
    const chatUnread = unreadFromMap !== undefined ? unreadFromMap : false;
    return candidateChat.set('unread', chatUnread);
  });
}

/**
 * It modifies the unread flag of gig and its chats by looking into unreadChats map
 */
export function updateUnreadOnGigAndGigCandidateChat(gig, unreadChatsMap) {
  const modifiedCandidateChats = updateUnreadOnGigChats(gig.get('candidateChats'), unreadChatsMap);
  const gigUnread = modifiedCandidateChats.find((c) => c.get('unread') === true) !== undefined;
  return gig.set('unread', gigUnread).set('candidateChats', modifiedCandidateChats);
}

export function updateUnreadOnGigChatList(gigsList, unreadChatsMap) {
  return gigsList.map((gig) => updateUnreadOnGigAndGigCandidateChat(gig, unreadChatsMap));
}

export function updateUnreadOnGigChatsMap(gigsMap, unreadChatsMap) {
  return gigsMap.map((gigChats) => updateUnreadOnGigChats(gigChats, unreadChatsMap));
}

export function updateUnreadOnGigChat(gigsList, unreadChatsMap, updatedGigId) {
  const gigIndex = gigsList.findIndex((gig) => gig.get('gigId') === updatedGigId);
  // might be not loaded from server yet
  if (gigIndex !== -1) {
    const gig = gigsList.get(gigIndex);

    return gigsList.setIn([gigIndex], updateUnreadOnGigAndGigCandidateChat(gig, unreadChatsMap));
  }
  return gigsList;
}

export function reduceCandidates(candidateChats, name, position) {
  return candidateChats.reduce((map, candidateChat) => {
    map[candidateChat.candidateId] = {
      chatId: candidateChat.chatId,
      gigName: name,
      gigPosition: position,
      userName: candidateChat.candidateName,
      userProfileImageUri: candidateChat.candidateProfileImageUri,
    };
    return map;
  }, {});
}

/**
 * generate gig data for each chat based on chats list from backend
 */
export function reduceGigsChats(payload) {
  return payload.reduce((map, gigWithChats) => {
    const { candidateChats, name, position } = gigWithChats;
    map[gigWithChats.gigId] = reduceCandidates(candidateChats, name, position);
    return map;
  }, {});
}
