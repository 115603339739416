import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { HIRED_LIST_KEY } from '../candidateMyGigsReducer';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { clearCandidateGigs, getCandidateGigs, removeNewContentIndicator } from '../candidateMyGigsActions';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import CandidateMyGigsViewOfferModal from '../offers/CandidateViewOfferModal';
import GigListItem from '../GigListItem';
import ListItem from '../../../../common/components/ListItem';
import { CANDIDATE_GIG_CHAT } from '../../../../constants/routes';

class CandidateMyGigsHiredView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModalVisible: false,
      modalGigId: undefined,
    };
  }

  componentDidMount() {
    this.props.getCandidateGigs(HIRED_LIST_KEY, [GIG_CANDIDATE_STATUS.HIRED], false)
      .then(() => {
        this.setTabInactive();
      });
  }

  componentWillUnmount() {
    this.props.clearCandidateGigs(HIRED_LIST_KEY);
  }

  setTabInactive = () => {
    if (this.props.newContentIndicators.includes(GIG_CANDIDATE_STATUS.HIRED)) {
      this.props.removeNewContentIndicator(GIG_CANDIDATE_STATUS.HIRED);
    }
  };

  openViewModal = (gigId) => {
    this.setState({ viewModalVisible: true, modalGigId: gigId });
  };

  closeViewModal = () => {
    this.setState({ viewModalVisible: false });
  };

  startChat(gigId) {
    this.props.history.push(CANDIDATE_GIG_CHAT.replace(':id', gigId));
  }

  renderViewModal() {
    const { viewModalVisible, modalGigId } = this.state;
    return (
      <CandidateMyGigsViewOfferModal
        visible={viewModalVisible}
        closeModal={this.closeViewModal}
        gigId={modalGigId}
      />
    );
  }

  render() {
    return (
      <>
        <PrimaryFlatList
          emptyListTextKey="candidateMyGigs.notHired"
          data={this.props.hiredList}
          keyExtractor={(item) => item.gigId}
          renderItem={({ item: matchedGig }) => (
            <ListItem
              renderContent={() => (
                <GigListItem
                  gig={matchedGig}
                  online={this.props.usersPresence[matchedGig.createdBy.userId]}
                  internalFunctions={{
                    openModal: this.openViewModal,
                    startChat: () => this.startChat(matchedGig.gigId),
                  }}
                />
              )}
            />
          )}
        />
        {this.renderViewModal()}
      </>
    );
  }
}

CandidateMyGigsHiredView.propTypes = {
  getCandidateGigs: PropTypes.func.isRequired,
  clearCandidateGigs: PropTypes.func.isRequired,
  removeNewContentIndicator: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  hiredList: PropTypes.arrayOf(matchedGigForCandidatePropType),
  history: ReactRouterPropTypes.history.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

CandidateMyGigsHiredView.defaultProps = {
  hiredList: null,
};

const mapStateToProps = (state) => ({
  hiredList: state.candidateMyGigs.get(HIRED_LIST_KEY) ? state.candidateMyGigs.get(HIRED_LIST_KEY).toJS() : null,
  newContentIndicators: state.candidateMyGigs.get('newContentIndicators'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, { getCandidateGigs, clearCandidateGigs, removeNewContentIndicator })(CandidateMyGigsHiredView);
