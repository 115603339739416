import { Map } from 'immutable';
import { ON_INTERNET_CONNECTION_CHANGE } from '../../../constants/actionTypes';

const INITIAL_APP_STATE = {
  isConnected: false,
};

export default function appStateReducer(state = Map(INITIAL_APP_STATE), action) {
  switch (action.type) {
    case ON_INTERNET_CONNECTION_CHANGE:
      return state.set('isConnected', action.isConnected);
    default:
      return state;
  }
}
