import { GET_ENABLED_FEATURES_REQUEST } from '../../constants/actionTypes';

export function getEnabledFeatures() {
  return {
    type: GET_ENABLED_FEATURES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/feature/enabled',
      },
    },
  };
}
