import { Map } from 'immutable';
import {
  COMPANY_SHIFTS_STORE_SCROLL_POSITION,
} from '../../constants/actionTypes';

const INITIAL_MAP = {
  shiftsListScrollPosition: 0,
};

export default function companyCandidatesPoolReducer(state = new Map(INITIAL_MAP), action) {
  switch (action.type) {
    case COMPANY_SHIFTS_STORE_SCROLL_POSITION:
      return state.set('shiftsListScrollPosition', action.scrollPosition);
    default:
      return state;
  }
}
