import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import WarningIcon from './image/WarningIcon';
import {
  colors, dynamicSpacings, fontFamily, fontSizes, spacings,
} from '../styles/base.style';
import { font } from '../styles/mixins';
import { stylePropTypes } from '../../constants/propTypes';

export const MESSAGE_BOX_TYPE = {
  WARNING: 'warning',
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: dynamicSpacings.md,
    paddingVertical: spacings.sm,
  },
  wrapper: {
    flexDirection: 'row',
  },
  title: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.sm),
    marginLeft: spacings.sm,
    paddingRight: spacings.sm,
  },
  text: {
    marginTop: spacings.sm,
    ...font(fontFamily.SFProDisplayItalic, fontSizes.md),
  },
  [`bg-${MESSAGE_BOX_TYPE.WARNING}`]: {
    backgroundColor: colors.gold,
  },
});

const icons = {
  [MESSAGE_BOX_TYPE.WARNING]: <WarningIcon />,
};

const MessageBox = ({
  type, title, text, style,
}) => (
  <View style={[styles.container, styles[`bg-${type}`], style.container]}>
    <View style={[styles.wrapper]}>
      {icons[type]}
      <Text style={styles.title}>{title}</Text>
    </View>
    { !!text && <Text style={styles.text}>{text}</Text> }
  </View>
);

MessageBox.propTypes = {
  type: PropTypes.oneOf(Object.values(MESSAGE_BOX_TYPE)),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  style: PropTypes.shape({
    container: stylePropTypes,
    title: stylePropTypes,
    text: stylePropTypes,
  }),
};

MessageBox.defaultProps = {
  type: MESSAGE_BOX_TYPE.WARNING,
  text: '',
  style: {},
};

export { MessageBox };
