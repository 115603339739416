import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import EditableField from '../../../../common/components/EditableField';
import { candidateProfileSetHomeLocation } from '../../CandidateProfileActions';
import CandidateProfileHomeLocationForm from '../../forms/homeLocation/CandidateProfileHomeLocationForm';
import { candidateHomeLocationPropType, candidateLocationPropType } from '../../../../constants/propTypes';
import { getHomeLocationByCoordinates } from '../../../../common/geolocation/geolocationActions';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import ModalFooter from '../../../../common/components/modal/ModalFooter';

class EditableFieldHomeLocationModalAware extends React.Component {
  modalRefreshable = false;

  constructor(props) {
    super(props);
    this.state = { homeLocation: props.homeLocation };
  }

  componentDidUpdate() {
    if (this.modalRefreshable) {
      this.openModal();
    }
  }

  onRegionChange = (region) => {
    this.props.getHomeLocationByCoordinates(region).then((res) => {
      if (res.payload && !res.payload.error && !res.payload.isError) {
        this.setState({
          homeLocation: { geoLoc: { lat: region.latitude, lon: region.longitude }, ...res.payload.address, locationName: res.payload.locationName },
        });
      }
    });
  };

  handleSubmit = () => {
    this.props.candidateProfileSetHomeLocation({ homeLocation: this.state.homeLocation }).then((response) => {
      if (!response.isError) {
        this.props.closeModal();
        this.modalRefreshable = false;
      }
    });
  };

  openModal = () => {
    const {
      translate, closeModal, homeLocation, location,
    } = this.props;
    this.modalRefreshable = true;

    this.props.setModalContent(
      translate('candidateProfile.details.homeLocationLabel'),
      <>
        <CandidateProfileHomeLocationForm
          homeLocation={this.state.homeLocation || homeLocation}
          location={location}
          onRegionChange={this.onRegionChange}
          style={{ input: { top: 20 } }}
        />
        <ModalFooter>
          <Button
            type={BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.FLEX_MD}
            title={translate('generic.close')}
            onPress={() => {
              this.modalRefreshable = false;
              this.setState({ homeLocation: this.props.homeLocation });
              closeModal();
            }}
          />
          <Button
            size={BUTTON_SIZES.FLEX_MD}
            title={translate('generic.save')}
            onPress={() => this.handleSubmit()}
          />
        </ModalFooter>
      </>,
    );
  };

  render() {
    const { translate, homeLocation } = this.props;
    return (
      <EditableField
        label={translate('candidateProfile.details.homeLocationLabel')}
        value={homeLocation && homeLocation.locationName}
        placeholder={translate('candidateProfile.details.homeLocationPlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

EditableFieldHomeLocationModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateProfileSetHomeLocation: PropTypes.func.isRequired,
  getHomeLocationByCoordinates: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  homeLocation: candidateHomeLocationPropType,
  location: candidateLocationPropType.isRequired,
};

EditableFieldHomeLocationModalAware.defaultProps = {
  homeLocation: {
    locationName: null,
    geoLoc: null,
  },
};

const mapStateToProps = (state) => {
  const homeLocation = state.candidate.get('profile').get('homeLocation');
  return {
    homeLocation: homeLocation ? homeLocation.toJS() : null,
    location: state.candidate.get('profile')?.get('location')?.toJS() ?? null
  };
};

export default connect(mapStateToProps, { candidateProfileSetHomeLocation, getHomeLocationByCoordinates })(withLocalize(EditableFieldHomeLocationModalAware));
