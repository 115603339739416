import React from 'react';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Routing from '../core/routing/index';
import { getEnabledFeatures } from './featureToggle/featureToggleActions';
import { COMPANY_USER } from '../constants/userScopes';
import {
  CANDIDATE, CANDIDATE_LOGIN, COMPANY, COMPANY_LOGIN, WELCOME, WELCOME_CAROUSEL,
} from '../constants/routes';

const { Redirect } = Routing;

function Root({
  authenticated, userScope, sessionTimedOut, userLoggedInOnce, grantedFeatures,
}) {
  if (authenticated) {
    if (userScope === COMPANY_USER) {
      return <Redirect to={COMPANY} />;
    }
    return <Redirect to={CANDIDATE} />;
  }

  if (!authenticated && sessionTimedOut) {
    if (userScope === COMPANY_USER) {
      return <Redirect to={COMPANY_LOGIN} />;
    }
    return <Redirect to={CANDIDATE_LOGIN} />;
  }

  if (grantedFeatures.includes('WELCOME_CAROUSEL') && !userLoggedInOnce && Platform.OS !== 'web') {
    return (
      <Redirect to={WELCOME_CAROUSEL} />
    );
  }

  return (
    <Redirect to={WELCOME} />
  );
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  userScope: state.auth.get('userScope'),
  sessionTimedOut: state.auth.get('timeout'),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  userLoggedInOnce: state.auth.get('loggedInAtLeastOnce'),
});

Root.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  userScope: PropTypes.string,
  sessionTimedOut: PropTypes.bool.isRequired,
  userLoggedInOnce: PropTypes.bool.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Root.defaultProps = {
  userScope: '',
};

export default connect(mapStateToProps, { getEnabledFeatures })(Root);
