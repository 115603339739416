import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import googleAnalytics from './googleAnalytics';

class GoogleAnalyticsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props.history.listen(this.onRouteChange);
  }

  onRouteChange = (location) => {
    googleAnalytics.trackPageHit(location.pathname);
  };

  render() {
    return (null);
  }
}

GoogleAnalyticsComponent.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default GoogleAnalyticsComponent;
