import {
  CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST,
  CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST,
  CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST,
  CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST,
} from '../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';
import { POOL_CHAT_TYPE } from '../../../common/chat/pool/poolChatReducerHelper';

export function getCandidatePoolGroupChatsInfo(handleInternalError = true) {
  return {
    type: CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/candidate/candidatesPool/group/chat',
      },
    },
    handleInternalError,
    chatType: POOL_CHAT_TYPE.GROUP,
  };
}

export function createOrGetCandidatePoolGroupChat(groupId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.CANDIDATE.MY_POOLS.CHAT.OPEN_GROUP_CHAT);
  return {
    type: CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/candidatesPool/group/${groupId}/chat`,
        data: { groupId },
      },
    },
    poolChatId: groupId,
    chatType: POOL_CHAT_TYPE.GROUP,
  };
}

export function getCandidatePoolDirectChatsInfo(handleInternalError = true) {
  return {
    type: CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/candidate/candidatesPool/chat',
      },
    },
    handleInternalError,
    chatType: POOL_CHAT_TYPE.DIRECT,
  };
}

export function createOrGetCandidatePoolDirectChat(poolCandidateId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.CANDIDATE.MY_POOLS.CHAT.OPEN_DIRECT_CHAT);
  return {
    type: CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/candidatesPool/${poolCandidateId}/chat`,
        data: { poolCandidateId },
      },
    },
    poolChatId: poolCandidateId,
    chatType: POOL_CHAT_TYPE.DIRECT,
  };
}
