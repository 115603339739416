import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import TermsAndAgreementScreen from '../../common/screens/TermsAndAgreementScreen';

import { CANDIDATE_USER } from '../../constants/userScopes';
import { CANDIDATE_SIGNUP } from '../../constants/routes';
import { resetSignUpSuccess } from '../../common/auth/authActions';

class CandidateContractAgreementContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isContractAgreed: false,
    };
  }

  onAgreeContract = () => {
    this.setState({ isContractAgreed: true });
  };

  render() {
    return (
      <TermsAndAgreementScreen
        userType={CANDIDATE_USER}
        redirectPath={CANDIDATE_SIGNUP}
        redirect={this.state.isContractAgreed}
        onSubmit={this.onAgreeContract}
      />
    );
  }
}

CandidateContractAgreementContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = (state) =>
  // const formValues = state.auth.get('formValues').toJS();
  // console.log("formValuesss", formValues)

   ({
    // formValues,
    locale: state.locale.get('locale')
  })
;
// const mapStateToProps = (state) => ({
//   locale: state.locale.get('locale')
// });

export default connect(mapStateToProps, {})(CandidateContractAgreementContainer);
