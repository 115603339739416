import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Text, ScrollView, StyleSheet, Platform, Linking, AppState } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Constants from 'expo-constants';
import SelectListItem from '../../common/components/form/SelectListItem';
import Routing from '../../core/routing/index';
import NavigationBar from '../../common/components/navigation/NavigationBar';
import NavItem from '../../common/components/navigation/NavItem';
import {
  CANDIDATE_CHAT, CANDIDATE_FIND_GIGS, CANDIDATE_MY_GIGS, CANDIDATE_PROFILE, CANDIDATE_MY_POOLS,
} from '../../constants/routes';
import ChatNavItem from '../../common/components/navigation/ChatNavItem';
import NavItemWithIndicator from '../../common/components/navigation/NavItemWithIndicator';
import { CANDIDATES_POOLS_CONTENT_FIELDS } from '../myPools/candidateMyPoolsReducer';
import renderMenu from './appMenuConfig';
import { getMissingProfilePropsNumber } from '../profile/profileHelper';
import { showAlertMessage } from '../../common/components/alert/alerts';
import { getCurrentVersion } from '../../common/version/VersionActions';
import SignupDocumentModal from '../../common/screens/SignupDocumentModal';
import candidateAppTerms from '../../../app_terms/candidateAppTerms';
import { setCandidateContractStatus } from '../profile/CandidateProfileActions';

const { withRouter } = Routing;

const searchIconOn = require('../../assets/icons/menu/iconLoupeOnFull.png');
const searchIconOff = require('../../assets/icons/menu/iconLoupeOffFull.png');

const myGigsIconOn = require('../../assets/icons/menu/iconMyGigsOn.png');
const myGigsIconOff = require('../../assets/icons/menu/iconMyGigsOff.png');

const chatIconOn = require('../../assets/icons/menu/iconChatOffFullOn.png');
const chatIconOff = require('../../assets/icons/menu/iconChatOffFull.png');

const myPoolsIconOn = require('../../assets/icons/menu/iconMyPoolsOn.png');
const myPoolsIconOff = require('../../assets/icons/menu/iconMyPoolsOff.png');

const profileIconOn = require('../../assets/icons/menu/iconMyProfileOn.png');
const profileIconOff = require('../../assets/icons/menu/iconMyProfileOff.png');

const isIOS = Platform.OS === 'ios';

const styles = StyleSheet.create({

  container: {
    padding: 20,
  },
  paragraphs: {
    fontSize: 16,
    lineHeight: 24,
    paddingHorizontal: 20
  },
  bottomInnerContainer: {
    paddingVertical: 20
  },
  linkButtonContainer: {
    paddingVertical: 15
  }
});

class CandidateNavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContractAccepted: this.props.acceptedContract,
      isCheckBoxEnabled: false,
      isChecked: false,
    };

    console.log('isContractAcceptedisContractAccepted', this.state.isContractAccepted);
  }

  componentDidMount() {
    if (renderMenu(this.props.location.pathname)) {
      AppState.addEventListener('change', this.handleAppStateChange);
      // calls API function that checks version based on platform, if app is not updated, display alert message
      this.checkAppVersion();
      // this.checkContractAgreement();
    }
  }

  handleScroll = (event) => {
    const { layoutMeasurement, contentSize, contentOffset } = event.nativeEvent;

    // Calculate the current scroll position
    const scrollY = contentOffset.y;

    // Calculate whether the ScrollView is at the bottom
    const isCheckBoxEnabled = Math.floor(layoutMeasurement.height + scrollY + 5) >= Math.floor(contentSize.height);

    if (isCheckBoxEnabled) {
      this.setState({ isCheckBoxEnabled });
    }
  };

  handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active') {
      // The app has resumed from the background
      this.checkAppVersion();
    }
  };

  checkAppVersion = () => {
    if (Platform.OS !== 'web' && process.env.NODE_ENV === 'development') {
      this.props.getCurrentVersion().then((response) => {
        console.log(`app-version-android ${Constants.expoConfig.version}`, response.payload.android);
        console.log(`app-version-ios ${Constants.expoConfig.version}`, response.payload.ios);

        if (!response.isError) {
          if (Platform.OS === 'ios') {
            if (Constants.expoConfig.version < response.payload.ios) {
              showAlertMessage(this.props.translate, 'generic.updateNow', 'generic.iOSNewVersionMessage', 'generic.newVersionAvailable', () => {
                // redirects app store
                this.openStoreForUpdate('ios');
              });
            }
          } else if (Constants.expoConfig.version < response.payload.android) {
            showAlertMessage(this.props.translate, 'generic.updateNow', 'generic.androidNewVersionMessage', 'generic.newVersionAvailable', () => {
              // redirects to google play store
              this.openStoreForUpdate('android');
            });
          }
        }
      });
    }
  }

  checkContractAgreement = () => {
    this.props.getCurrentVersion().then((response) => {
      if (!response.isError) {
        this.setState({ isContractAccepted: false });
      }
    });
  }

  handleCloseContractAgreement = () => {
    this.props.setCandidateContractStatus().then((response) => {
      if (!response.isError) {
        console.log('responseee ', JSON.stringify(response));
        this.setState({ isContractAccepted: response.payload.acceptedContract });
      }
    });
  };

  openStoreForUpdate = (platform) => {
    let storeUrl = '';

    if (platform === 'ios') {
      storeUrl = 'https://apps.apple.com/app/id1448023337';
    } else if (platform === 'android') {
      storeUrl = 'https://play.google.com/store/apps/details?id=com.letsgig.prd&pli=1';
    }

    if (storeUrl) {
      Linking.openURL(storeUrl)
        .then(() => {
          console.log(`Opened ${platform === 'ios' ? 'App Store' : 'Play Store'}`);
        })
        .catch((err) => {
          console.error('Error opening store URL', err);
        });
    }
  };

  render() {
    const {
      translate,
      location,
      isSthNewInMyGigs,
      grantedFeatures,
      isSthNewInMyPools,
      missingProfileProps,
      acceptedContract
    } = this.props;

    return (
      renderMenu(location.pathname) && (
        <View>
          <NavigationBar>
            <NavItem
              iconOn={searchIconOn}
              iconOff={searchIconOff}
              text={translate('generic.findGigs')}
              link={CANDIDATE_FIND_GIGS}
            />
            <NavItemWithIndicator
              showSthNewIndicator={isSthNewInMyGigs}
              iconOn={myGigsIconOn}
              iconOff={myGigsIconOff}
              text={translate('generic.myGigs')}
              link={CANDIDATE_MY_GIGS}
            />
            <ChatNavItem
              iconOn={chatIconOn}
              iconOff={chatIconOff}
              text={translate('generic.chat')}
              link={CANDIDATE_CHAT}
            />
            {grantedFeatures.includes('CANDIDATES_POOL') && (
              <NavItemWithIndicator
                showSthNewIndicator={isSthNewInMyPools}
                iconOn={myPoolsIconOn}
                iconOff={myPoolsIconOff}
                text={translate('candidate.myPools.menu')}
                link={CANDIDATE_MY_POOLS}
              />
            )}
            <NavItemWithIndicator
              showSthNewIndicator={missingProfileProps > 0}
              indicatorText={missingProfileProps}
              iconOn={profileIconOn}
              iconOff={profileIconOff}
              text={translate('generic.myProfile')}
              link={CANDIDATE_PROFILE}
            />
          </NavigationBar>

          <SignupDocumentModal
            visible={!this.state.isContractAccepted}
            handleCloseModal={this.handleCloseContractAgreement}
            header={translate('candidateWelcomeScreen.signupScreen.contractAgreementHeader')}
            disabled={!this.state.isChecked}
            buttonTitle={translate('generic.submit')}
          >
            <ScrollView
              onScroll={this.handleScroll}
              scrollEventThrottle={16}
            >
              <View style={styles.container}>
                <Text style={styles.paragraphs}>{candidateAppTerms}</Text>
              </View>
            </ScrollView>

            <View style={styles.bottomInnerContainer}>
              <SelectListItem
                key={1}
                value="accept"
                label={translate('candidateWelcomeScreen.signupScreen.contractAgreementText')}
                selected={this.state.isChecked}
                disabled={!this.state.isCheckBoxEnabled}
                iconPosition="left"
                onPress={() => this.setState({ isChecked: !this.state.isChecked })}
              />
            </View>
          </SignupDocumentModal>

          <SignupDocumentModal
            visible={!this.state.isContractAccepted}
            handleCloseModal={this.handleCloseContractAgreement}
            header={translate('candidateWelcomeScreen.signupScreen.contractAgreementHeader')}
            disabled={!this.state.isChecked}
            buttonTitle={translate('generic.submit')}
          >
            <ScrollView
              onScroll={this.handleScroll}
              scrollEventThrottle={16}
            >
              <View style={styles.container}>
                <Text style={styles.paragraphs}>{candidateAppTerms}</Text>
              </View>
            </ScrollView>

            <View style={styles.bottomInnerContainer}>
              <SelectListItem
                key={1}
                value="accept"
                label={translate('candidateWelcomeScreen.signupScreen.contractAgreementText')}
                selected={this.state.isChecked}
                disabled={!this.state.isCheckBoxEnabled}
                iconPosition="left"
                onPress={() => this.setState({ isChecked: !this.state.isChecked })}
              />
            </View>
          </SignupDocumentModal>

        </View>

      )
    );
  }
}

CandidateNavigationBar.propTypes = {
  translate: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  isSthNewInMyGigs: PropTypes.bool.isRequired,
  isSthNewInMyPools: PropTypes.bool.isRequired,
  missingProfileProps: PropTypes.number.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const isNewChat = Object.values(state.poolChat.get('poolChatsData').toJS()).find((item) => item.unread) != null;
  const myPoolsContentMap = state.candidateMyPools.get('newContent').toJS();
  const isNewCandidateInMyPools = myPoolsContentMap[CANDIDATES_POOLS_CONTENT_FIELDS.MY_POOLS].length > 0
    || myPoolsContentMap[CANDIDATES_POOLS_CONTENT_FIELDS.GROUPS].length > 0 || myPoolsContentMap[CANDIDATES_POOLS_CONTENT_FIELDS.SHIFTS].length > 0;

  const contractStatus = state.candidate.get('profile').get('acceptedContract');
  console.log('contractAcceptedStatus ', contractStatus);
  return {
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
    isSthNewInMyGigs: state.candidateMyGigs.get('newContentIndicators').length > 0,
    isSthNewInMyPools: isNewChat || isNewCandidateInMyPools,
    missingProfileProps: getMissingProfilePropsNumber(state.candidate.get('profile').toJS()),
    acceptedContract: contractStatus
  };
};

export default connect(mapStateToProps, { getCurrentVersion, setCandidateContractStatus })(withLocalize(withRouter(CandidateNavigationBar)));
