import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import Routing from '../../../../core/routing/index';
import ProfileInfo from '../../../../common/components/ProfileInfo';
import Container from '../../../../common/components/Container';
import { colors, fontFamily, fontSizes, spacings } from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import Arrow from '../../../../common/components/Arrow';
import { toDatetime } from '../../../../utilities/dateFormatter';
import getDefinition from './listItemDefinitions';
import { matchingDataPropType } from '../../../../constants/propTypes';
import Touchable from '../../../../common/components/Touchable';
import { COMPANY_GIG_CANDIDATE_VIEW } from '../../../../constants/routes';
import GigCandidateActionsSection from './GigCandidateActionsSection';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    marginTop: spacings.md,
  },
  topContainer: {
    marginHorizontal: spacings.md,
    marginBottom: spacings.sm,
    flexDirection: 'row',
    alignItems: 'center',
  },
  middleContainer: {
    paddingHorizontal: spacings.md,
    paddingVertical: spacings.sm,
  },
  bottomContainer: {
    marginTop: spacings.sm,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  seeProfileStyle: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xs, null, colors.magenta),
  },
  middleText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, null, colors.white),
    lineHeight: 17,
  },
  middleSubText: {
    marginBottom: spacings.xxs,
    ...font(fontFamily.SFProTextRegular, fontSizes.xxxs, null, colors.white),
  },
  middleFooter: {
    marginTop: spacings.xs,
    ...font(fontFamily.SFProTextRegular, fontSizes.xxxs, null, colors.white),
  },
  bottomRadius: {
    borderBottomRightRadius: 17,
    borderBottomLeftRadius: 17,
  },
  heartContainer: {
    paddingHorizontal: spacings.sm,
    paddingBottom: spacings.xs,
    color: colors.lipstick,
    lineHeight: fontSizes.xxl,
  },
});

const candidateProfileInfoStyles = StyleSheet.create({
  wrapper: {
    marginBottom: spacings.xs,
  },
  name: {
    marginBottom: spacings.xxs,
  },
});

export function shouldComponentUpdate(currentProps, nextProps) {
  return !shallowequal(currentProps.candidate, nextProps.candidate);
}

class CompanyGigCandidateListItem extends Component {
  shouldComponentUpdate(nextProps) {
    return shouldComponentUpdate(this.props, nextProps);
  }

  viewCandidateDetails = () => {
    this.props.history.push(COMPANY_GIG_CANDIDATE_VIEW.replace(':gigId', this.props.gigId).replace(':candidateId', this.props.candidate.userId));
  };

  render() {
    const itemDefinition = getDefinition(this.props.candidate.matching);
    const t = (key, ...args) => this.props.translate(`companyGigs.candidates.${key}`, ...args);

    return (
      <Container style={[styles.container, itemDefinition.actions && { marginBottom: spacings.sm }]}>
        <Touchable onPress={this.viewCandidateDetails}>
          <Container style={styles.topContainer}>
            <Container>
              <ProfileInfo
                profile={this.props.candidate}
                lastNameOneLetter
                imageSize={{ width: 54, height: 54, borderRadius: 27 }}
                style={candidateProfileInfoStyles}
                online={this.props.online}
              />
            </Container>
            <View>
              <Arrow />
            </View>
          </Container>
        </Touchable>
        {this.props.candidate.matching.isInFavourites && <Text style={styles.heartContainer}>❤</Text>}
        <Container style={[styles.middleContainer, { backgroundColor: itemDefinition.middleColor }, !itemDefinition.actions && styles.bottomRadius]}>
          <Text style={styles.middleText}>{t(itemDefinition.middleText, { firstName: this.props.candidate.firstName })}</Text>
          {itemDefinition.middleSubText && <Text style={styles.middleSubText}>{t(itemDefinition.middleSubText)}</Text>}
          <Text style={styles.middleFooter}>{`${t('updated')} ${toDatetime(this.props.candidate.matching.lastTransitionDate)}`}</Text>
        </Container>
        {itemDefinition.actions && (
          <Container style={styles.bottomContainer}>
            <GigCandidateActionsSection candidate={this.props.candidate} gigId={this.props.gigId} internalFunctions={this.props.internalFunctions} />
          </Container>
        )}
      </Container>
    );
  }
}

CompanyGigCandidateListItem.propTypes = {
  candidate: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profileImageUri: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
    }).isRequired,
    matching: matchingDataPropType,
    companyHasAccess: PropTypes.bool.isRequired,
  }).isRequired,
  gigId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  internalFunctions: PropTypes.object,
  online: PropTypes.bool,
};

CompanyGigCandidateListItem.defaultProps = {
  internalFunctions: {},
  online: null,
};

export const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (action) => dispatch(action),
});

export default connect(null, mapDispatchToProps)(withRouter(withLocalize(CompanyGigCandidateListItem)));
