import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export function startOfToday() {
  return moment().startOf('day').toDate();
}

export function getDefaultDate() {
  const minDate = moment();
  if (minDate.minute() !== 0) {
    minDate.add(1, 'h').minute(0);
  }
  return minDate.valueOf();
}

export function buildRange(minutesOfDayStart, minutesOfDayEnd) {
  return minutesOfDayStart < minutesOfDayEnd
    ? moment.range(
      moment().utc().startOf('day').add(minutesOfDayStart, 'm'),
      moment().utc().startOf('day').add(minutesOfDayEnd, 'm'),
    )
    : moment.range(
      moment().utc().startOf('day').add(minutesOfDayStart, 'm'),
      moment().utc().startOf('day').add(24, 'h')
        .add(minutesOfDayEnd, 'm'),
    );
}

export const getMinutesSinceDayStart = (timestamp) => +(moment(timestamp).hours() * 60 + moment(timestamp).minutes());
