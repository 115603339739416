import { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { gigCandidatePropType } from '../../constants/propTypes';

class CandidateActionSheet extends Component {
  componentDidMount() {
    // Child passes its method to the parent
    this.props.registerOpenActionSheetFunction(this.getOpenActionSheetFunction);
  }

  getOpenActionSheetFunction = () => {
    const {
      translate, candidate, onInviteCandidateToPool, onRemove, onStartDirectChat,
    } = this.props;

    const actionSheetParams = { options: [] };
    const actionHandlers = [];
    let optionsSize;

    if (!candidate.poolCandidate || !['INVITED', 'ACTIVE'].includes(candidate.poolCandidate.status)) {
      if (onInviteCandidateToPool) {
        optionsSize = actionSheetParams.options.push(translate('company.candidatesPool.inviteToPool'));
        actionHandlers[optionsSize - 1] = async () => {
          onInviteCandidateToPool(candidate.userId);
        };
      }
    }

    if (candidate.poolCandidate && ['INVITED', 'ACTIVE'].includes(candidate.poolCandidate.status)) {
      if (candidate.poolCandidate.status === 'ACTIVE' && onStartDirectChat) {
        optionsSize = actionSheetParams.options.push(translate('company.candidatesPool.directChat'));
        actionHandlers[optionsSize - 1] = async () => {
          onStartDirectChat(candidate.poolCandidate.poolCandidateId);
        };
      }

      if (onRemove) {
        optionsSize = actionSheetParams.options.push(translate('company.candidatesPool.removeFromPool'));
        actionSheetParams.destructiveButtonIndex = optionsSize - 1;
        actionHandlers[optionsSize - 1] = async () => {
          onRemove(candidate.poolCandidate.poolCandidateId);
        };
      }
    }

    optionsSize = actionSheetParams.options.push(translate('generic.cancel'));
    actionSheetParams.cancelButtonIndex = optionsSize - 1;
    actionHandlers[optionsSize - 1] = () => {};

    this.props.showActionSheetWithOptions(
      {
        ...actionSheetParams,
        showSeparators: true,
      },
      (buttonIndex) => {
        actionHandlers[buttonIndex]();
      },
    );
  };

  render() {
    return null;
  }
}

CandidateActionSheet.propTypes = {
  translate: PropTypes.func.isRequired,
  showActionSheetWithOptions: PropTypes.func.isRequired,
  registerOpenActionSheetFunction: PropTypes.func.isRequired,
  candidate: gigCandidatePropType,
  onInviteCandidateToPool: PropTypes.func,
  onRemove: PropTypes.func,
  onStartDirectChat: PropTypes.func,
};

CandidateActionSheet.defaultProps = {
  candidate: null,
  onInviteCandidateToPool: null,
  onRemove: null,
  onStartDirectChat: null,
};

export default withLocalize(connectActionSheet(CandidateActionSheet));
