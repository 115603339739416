import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import { fontSizes, spacings } from '../../../../../common/styles/base.style';
import Button, { BUTTON_TYPES } from '../../../../../common/components/buttons/Button';
import { candidateExperiencePropType } from '../../../../../constants/propTypes';
import LinkButton from '../../../../../common/components/buttons/LinkButton';
import CandidateProfileHistoryList from '../../../history_list/CandidateProfileHistoryList';
import AddProfileExperienceModalScreen from './AddProfileExperienceModalScreen';
import { getExperienceKey } from './profileExperienceHelper';
import { fontsNames } from '../../../../../config/fonts';
import Container from '../../../../../common/components/Container';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';

const styles = StyleSheet.create({
  container: {
    paddingVertical: spacings.md,
  },
  content: {
    display: 'flex',
    flex: 1,
  },
  text: {
    marginVertical: spacings.xxs,
    textAlign: 'center',
    fontSize: fontSizes.lg,
    fontFamily: fontsNames.SFProDisplayRegular,
  },
});

const ProfileCreatorStepExperienceScreen = ({
  stepNumber,
  experience,
  translate,
  redirect,
  nextScreen,
  handleRemoveExperience,
  handleSubmit,
  handleSkip,
  handleAddExperience,
  handleEditExperience,
  experienceToEdit,
  openEmptyModal,
  closeModal,
  modalVisible,
}) => (
  <>
    <Container>
      <ProfileCreatorHeader
        stepNo={stepNumber}
        title={translate('candidateProfileCreator.experience.header')}
        infoText={translate('candidateProfileCreator.experience.subheader')}
      />
      <CandidateProfileHistoryList
        items={experience.map((e) => (
          {
            title: e.position,
            subtitle: e.companyName,
            ...e,
          }
        ))}
        handleRemove={handleRemoveExperience}
        handleOpen={handleEditExperience}
        keyExtractor={getExperienceKey}
      />

      <View style={{ paddingTop: spacings.md }}>
        <Button
          title={translate('candidateProfileCreator.experience.addExperienceBtn')}
          onPress={openEmptyModal}
        />
      </View>

      {experience.length === 0
          && (
            <>
              <Text style={[styles.text, { fontFamily: fontsNames.SFProDisplaySemiBold }]}>
                {translate('candidateProfileCreator.experience.noExperienceText1')}
              </Text>
              <Text style={styles.text}>{translate('candidateProfileCreator.experience.noExperienceText2')}</Text>
              <Text style={styles.text}>{translate('candidateProfileCreator.experience.noExperienceText3')}</Text>
              <LinkButton
                type={BUTTON_TYPES.TEXT}
                to={nextScreen}
                doRedirect={redirect}
                onPress={handleSkip}
                title={translate('candidateProfileCreator.experience.noExperienceBtn')}
              />
            </>
          )}
      <AddProfileExperienceModalScreen
        visible={modalVisible}
        handleCancel={closeModal}
        handleSubmit={handleAddExperience}
        experience={experienceToEdit}
      />
    </Container>
    {experience.length > 0
        && (
        <StickyButtonsWrapper>
          <LinkButton
            to={nextScreen}
            doRedirect={redirect}
            onPress={handleSubmit}
            title={translate('generic.next')}
          />
        </StickyButtonsWrapper>
        )}

  </>
);

ProfileCreatorStepExperienceScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  nextScreen: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  experience: PropTypes.arrayOf(candidateExperiencePropType.isRequired).isRequired,
  experienceToEdit: candidateExperiencePropType,
  handleRemoveExperience: PropTypes.func.isRequired,
  handleAddExperience: PropTypes.func.isRequired,
  handleEditExperience: PropTypes.func.isRequired,
  openEmptyModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
};

ProfileCreatorStepExperienceScreen.defaultProps = {
  experienceToEdit: undefined,
};

export default withLocalize(ProfileCreatorStepExperienceScreen);
