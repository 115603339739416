import React, { PureComponent } from 'react';
import { Image } from 'react-native';
import PropTypes from 'prop-types';
import { checkboxIconStyle } from './checkboxIcon.style';
import { imagePropType } from '../../../../constants/propTypes';

const unCheckedImage = require('../../../../assets/icons/checkbox/off.png');

class CheckboxImageUnchecked extends PureComponent {
  render() {
    return (
      <Image
        style={[checkboxIconStyle.icon, !this.props.show && checkboxIconStyle.hidden]}
        source={this.props.customIcon || unCheckedImage}
      />
    );
  }
}

CheckboxImageUnchecked.propTypes = {
  show: PropTypes.bool.isRequired,
  customIcon: imagePropType,
};

CheckboxImageUnchecked.defaultProps = {
  customIcon: undefined,
};

export default CheckboxImageUnchecked;
