import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import Routing from '../../../core/routing/index';
import { toDatetime } from '../../../utilities/dateFormatter';
import {
  fontSizes, colors, fontFamily, spacings,
} from '../../../common/styles/base.style';
import { CLOSED } from '../../../constants/gigStatus';
import { gigItemPropType } from '../../../constants/propTypes';
import { COMPANY_GIG_DETAILS, COMPANY_GIG_EDIT_START } from '../../../constants/routes';
import GeneralFormError from '../../../common/components/form/GeneralFormError';
import Button from '../../../common/components/buttons/Button';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../common/components/header/ScreenHeader';
import { gigCreatorInitEditing } from '../../gig_creator/gigCreatorActions';
import CompanyGigActionSheet from '../CompanyGigActionSheet';

const { withRouter } = Routing;

const textColor = colors.white;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.sm,
    paddingVertical: spacings.md,
    paddingTop: spacings.sm,
    backgroundColor: 'rgb(176, 176, 186)',
  },
  title: {
    fontSize: fontSizes.xl,
    color: textColor,
    alignSelf: 'center',
    textAlign: 'center',
  },
  dates: {
    color: colors.greyishBrown,
    textAlign: 'center',
    fontFamily: fontFamily.SFProDisplayRegular,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: spacings.sm,
  },
});

class CompanyGigMatchingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    };
  }

  onViewPress = () => {
    this.props.history.push(COMPANY_GIG_DETAILS.replace(':id', this.props.gig.gigId));
  };

  onEditPress = async () => {
    this.props.history.push(COMPANY_GIG_EDIT_START.replace(':gigId', this.props.gig.gigId));
  };

  getDateCopy(status, closeDate, expiryDate) {
    const label = status === CLOSED
      ? this.props.translate('companyGigs.list.closedLabel')
      : this.props.translate('companyGigs.list.expiresLabel');

    return {
      label,
      date: status === CLOSED ? toDatetime(closeDate) : toDatetime(expiryDate),
    };
  }

  openActionSheet = () => {
    this.openActionSheet();
  };

  registerOpenActionSheet = (openActionSheet) => {
    this.openActionSheet = openActionSheet;
  };

  render() {
    const { translate, gig } = this.props;
    const {
      name,
      status,
      closeDate,
      expiryDate,
      payments,
    } = gig;

    const dateCopy = this.getDateCopy(status, closeDate, expiryDate);

    return (
      <ScreenHeader
        showBackArrow
        title={name}
        rightButton={RIGHT_BUTTONS.MENU}
        handlerForRightButton={this.openActionSheet}
        subheaderFunc={() => (
          <>
            {payments && payments.accessUntilDate && moment.utc(payments.accessUntilDate).isAfter(moment.utc())
            && (
              <Text style={styles.dates}>{`${this.props.translate('companyGigs.list.accessLabel')} ${toDatetime(payments.accessUntilDate)}`}</Text>
            )}
            {payments && payments.unlimitedAccess
          && <Text style={styles.dates}>{this.props.translate('companyGigs.list.unlimitedAccessLabel')}</Text>}
            <Text style={styles.dates}>{`${dateCopy.label} ${dateCopy.date}`}</Text>
            <View style={styles.buttonsWrapper}>
              <Button size="sm" title={translate('companyGigs.details.previewGigBtn')} onPress={this.onViewPress} />
              {status !== CLOSED
              && <Button size="sm" title={translate('companyGigs.details.editGigBtn')} onPress={this.onEditPress} />}
            </View>
            <GeneralFormError errors={this.state.errors} />
            <CompanyGigActionSheet
              gig={this.props.gig}
              registerOpenActionSheetFunction={this.registerOpenActionSheet}
              postCloseCallback={this.props.postCloseCallback}
            />
          </>
        )}
      />
    );
  }
}

CompanyGigMatchingHeader.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  gig: PropTypes.shape(gigItemPropType).isRequired,
  postCloseCallback: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { gigCreatorInitEditing })(withLocalize(CompanyGigMatchingHeader)));
