import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import PrimaryModal from '../../../../common/components/modal/PrimaryModal';
import { standardSpacings } from '../../../../common/styles/base.style';
import { MessageBox } from '../../../../common/components/MessageBox';
import Container from '../../../../common/components/Container';
import { acceptOffer, getGigOffer, rejectGig } from '../candidateMyGigsActions';
import { MY_GIGS_CONTEXT } from '../../../../constants/actionContext';
import { OFFERS_LIST_KEY } from '../candidateMyGigsReducer';
import DisplayField from '../../../../common/components/DisplayField';
import Wrapper from '../../../../common/components/Wrapper';
import GigOfferView from '../../../../common/gig/offer/GigOfferView';
import { rejectGigOfferAlertParams, showConfirmAlert } from '../../../../common/components/alert/alerts';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import ModalFooter from '../../../../common/components/modal/ModalFooter';
import ButtonRequestAware from '../../../../common/components/buttons/ButtonRequestAware';
import { CANDIDATE_ACCEPT_OFFER_REQUEST, CANDIDATE_REJECT_REQUEST } from '../../../../constants/actionTypes';
import { LinkButton } from '../../../../common/components/index';
import { CANDIDATE_GIG_CHAT } from '../../../../constants/routes';

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const messageBoxStyles = StyleSheet.create({
  container: { marginBottom: standardSpacings.formVertical },
});

class CandidateAcceptOfferModal extends Component {
  translationKey = 'candidate.gigOffer';

  constructor(props) {
    super(props);
    this.state = { offer: undefined };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      // eslint-disable-next-line
      this.setState({ offer: undefined });
      this.loadOffer();
    }
  }

  handleReject = () => {
    const {
      translate, rejectAction, gigId, closeModal,
    } = this.props;

    showConfirmAlert(translate, rejectGigOfferAlertParams, () => {
      rejectAction(gigId, { context: MY_GIGS_CONTEXT, listKey: OFFERS_LIST_KEY })
        .then((response) => {
          if (!response.isError) {
            closeModal();
          }
        });
    });
  };

  handleSubmit = () => {
    const {
      acceptAction, gigId, closeModal,
    } = this.props;

    acceptAction(gigId, { context: MY_GIGS_CONTEXT, listKey: OFFERS_LIST_KEY })
      .then((response) => {
        if (!response.isError) {
          closeModal();
        }
      });
  };

  loadOffer = () => {
    const { getGigOfferAction, gigId } = this.props;
    getGigOfferAction(gigId)
      .then((response) => {
        if (!response.isError) {
          this.setState({ offer: response.payload });
        }
      });
  };

  t = (key, args) => this.props.translate(`${this.translationKey}.${key}`, args);

  renderForm() {
    const {
      handleSubmit,
      handleReject,
      t,
      translationKey,
    } = this;
    const { offer } = this.state;
    const { translate, closeModal } = this.props;

    return (
      <Container>
        <ScrollView>
          <MessageBox title={t('messageBoxHeader')} text={t('messageBoxText')} style={messageBoxStyles} />
          <Wrapper>
            <DisplayField label={t('companyLabel')} value={offer.company.name} />
          </Wrapper>
          <GigOfferView offer={offer} translationKey={translationKey} />
        </ScrollView>
        <ModalFooter>
          <View>
            <View style={styles.buttonContainer}>
              <ButtonRequestAware
                size="md"
                type="secondary"
                title={translate('generic.reject')}
                onPress={handleReject}
                disabledForRequest={[CANDIDATE_REJECT_REQUEST, CANDIDATE_ACCEPT_OFFER_REQUEST]}
              />
              <ButtonRequestAware
                size="md"
                title={t('confirmBtn')}
                onPress={handleSubmit}
                disabledForRequest={[CANDIDATE_REJECT_REQUEST, CANDIDATE_ACCEPT_OFFER_REQUEST]}
              />
            </View>
            <Button
              type={BUTTON_TYPES.TEXT}
              size={BUTTON_SIZES.MD}
              title={t('laterBtn')}
              onPress={closeModal}
            />
          </View>
        </ModalFooter>
      </Container>
    );
  }

  render() {
    const {
      visible,
      closeModal,
      gigId,
      translate,
    } = this.props;

    const chatButton = () => (
      <LinkButton
        size={BUTTON_SIZES.SM}
        to={CANDIDATE_GIG_CHAT}
        title={translate('generic.chat')}
        replacePathValue={gigId}
      />
    );

    return (
      <PrimaryModal
        visible={visible}
        handleCancel={closeModal}
        header={this.t('header')}
        headerProps={{ showBackArrow: true, shadow: true, subheaderFunc: chatButton }}
      >
        {this.state.offer ? this.renderForm() : null}
      </PrimaryModal>
    );
  }
}

CandidateAcceptOfferModal.propTypes = {
  translate: PropTypes.func.isRequired,
  rejectAction: PropTypes.func.isRequired,
  acceptAction: PropTypes.func.isRequired,
  getGigOfferAction: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

CandidateAcceptOfferModal.defaultProps = {
  gigId: undefined,
};

export default connect(null, { getGigOfferAction: getGigOffer, rejectAction: rejectGig, acceptAction: acceptOffer })(withLocalize(CandidateAcceptOfferModal));
