import React from 'react';
import PropTypes from 'prop-types';
import { Platform, View, StyleSheet } from 'react-native';
import { spacings } from '../../styles/base.style';

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    top: 4,
    right: 0,
    zIndex: 2000,
    paddingTop: spacings.sm,
    paddingRight: spacings.sm,
    ...Platform.select({
      web: {
        paddingTop: spacings.xl,
        paddingRight: spacings.xl,
        top: 0,
      },
    }),
  },
});

const NavigationRightButtonWrapper = ({ children, paddingTop, paddingRight }) => (
  <View style={[styles.wrapper, paddingTop ? { paddingTop } : null, paddingRight ? { paddingRight } : null]}>
    {children}
  </View>
);

NavigationRightButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.number,
  paddingRight: PropTypes.number,
};

NavigationRightButtonWrapper.defaultProps = {
  paddingTop: undefined,
  paddingRight: undefined,
};

export default NavigationRightButtonWrapper;
