import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import containerStyles from '../../../../../common/styles/container.style';
import Button, { BUTTON_TYPES } from '../../../../../common/components/buttons/Button';
import { candidateEducationPropType } from '../../../../../constants/propTypes';
import LinkButton from '../../../../../common/components/buttons/LinkButton';
import AddProfileEducationModalScreen from './AddProfileEducationModalScreen';
import CandidateProfileHistoryList from '../../../history_list/CandidateProfileHistoryList';
import { getEducationKey } from './profileEducationHelper';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';
import GeneralFormError from '../../../../../common/components/form/GeneralFormError';
import { spacings } from '../../../../../common/styles/base.style';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flex: 1,
  },
});

const incompleteEducationExists = (educations, grantedFeatures) => {
  if (!grantedFeatures.includes('PROFILE_DATA_EXTENSION')) {
    return false;
  }
  if (educations.length) {
    return educations.filter((education) => education.category == null || education.graduationDate == null).length > 0;
  }
  return true;
};

const ProfileCreatorStepEducationScreen = ({
  stepNumber,
  education,
  translate,
  redirect,
  nextScreen,
  handleRemoveEducation,
  handleSubmit,
  handleSkip,
  handleAddEducation,
  handleEditEducation,
  educationToEdit,
  openEmptyModal,
  closeModal,
  modalVisible,
  errors,
  grantedFeatures,
}) => (
  <>
    <View style={styles.container}>
      <ProfileCreatorHeader
        stepNo={stepNumber}
        title={translate('candidateProfileCreator.education.header')}
        infoText={translate('candidateProfileCreator.education.subheader')}
      />
      <View style={[containerStyles.flexVerticalTopCentered, styles.content]}>
        <CandidateProfileHistoryList
          items={education.map((e) => (
            {
              title: e.school,
              subtitle: e.course,
              ...e,
            }
          ))}
          handleRemove={handleRemoveEducation}
          handleOpen={handleEditEducation}
          keyExtractor={getEducationKey}
        />

        <View style={{ paddingTop: spacings.md }}>
          <Button
            title={translate('candidateProfileCreator.education.addEducationBtn')}
            onPress={openEmptyModal}
          />
        </View>

        {education.length === 0
          && (
          <LinkButton
            type={BUTTON_TYPES.TEXT}
            to={nextScreen}
            doRedirect={redirect}
            onPress={handleSkip}
            title={translate('candidateProfileCreator.education.noEducationBtn')}
          />
          )}
      </View>

      <AddProfileEducationModalScreen
        visible={modalVisible}
        handleCancel={closeModal}
        handleSubmit={handleAddEducation}
        education={educationToEdit}
      />
    </View>
    <StickyButtonsWrapper>
      <GeneralFormError all errors={errors} />

      {education.length > 0
        && (
        <LinkButton
          to={nextScreen}
          doRedirect={redirect}
          onPress={handleSubmit}
          disabled={incompleteEducationExists(education, grantedFeatures)}
          title={translate('generic.next')}
        />
        )}
    </StickyButtonsWrapper>
  </>
);

ProfileCreatorStepEducationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  nextScreen: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  education: PropTypes.arrayOf(candidateEducationPropType.isRequired).isRequired,
  educationToEdit: candidateEducationPropType,
  handleRemoveEducation: PropTypes.func.isRequired,
  handleAddEducation: PropTypes.func.isRequired,
  handleEditEducation: PropTypes.func.isRequired,
  openEmptyModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any,
};

ProfileCreatorStepEducationScreen.defaultProps = {
  educationToEdit: undefined,
  errors: null,
};

export default withLocalize(ProfileCreatorStepEducationScreen);
