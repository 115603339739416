import { COMPANY_STATUS_REQUEST } from '../../constants/actionTypes';

export const getCompanyStatus = () => ({
  type: COMPANY_STATUS_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/status',
    },
  },
});
