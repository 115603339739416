import { fromJS, List, Map } from 'immutable';
import {
  ADMIN_GIGS_FIND_FILTER_FORM_SUBMIT,
  ADMIN_GIGS_FIND_GIGS_REQUEST_SUCCESS, ADMIN_GIG_DETAILS_REQUEST_SUCCESS, ADMIN_GIG_DETAILS_CLEAR,
} from '../../constants/actionTypes';

const INITIAL_ADMIN_GIG_FILTERS = {
  formValues: fromJS({
    providerGigId: '', gigId: '', category: undefined, city: undefined, gigProvider: undefined, companyName: '', limit: '100', page: '1',
  }),
  adminGigsList: List(),
  adminGigsPageInfo: Map(),
  gigDetails: Map(),
};

export default function adminFindGigsUiReducer(state = new Map(INITIAL_ADMIN_GIG_FILTERS), action) {
  switch (action.type) {
    case ADMIN_GIGS_FIND_FILTER_FORM_SUBMIT:
      return state.set('formValues', fromJS(action.formValues));
    case ADMIN_GIGS_FIND_GIGS_REQUEST_SUCCESS:
      return state.set('adminGigsList', fromJS(action.payload.docs))
        .set('adminGigsPageInfo', fromJS({
          ...action.payload, docs: undefined,
        }));
    case ADMIN_GIG_DETAILS_REQUEST_SUCCESS:
      return state.set('gigDetails', fromJS(action.payload));
    case ADMIN_GIG_DETAILS_CLEAR:
      return state.set('gigDetails', Map());
    default:
      return state;
  }
}
