import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Routing from '../../../core/routing/index';

const { Route, Redirect, withRouter } = Routing;

function doRender(render, Component, props) {
  if (render) {
    return render(props);
  }
  return <Component {...props} />;
}

const authorized = (authenticated, only, userScope) => {
  if (authenticated) {
    if (Array.isArray(only)) {
      return only.includes(userScope);
    }
    return !(only.length > 1 && only !== userScope);
  }
  return false;
};

const AuthorizedRoute = ({
  component: Component, authenticated, only, userScope, render, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      authorized(authenticated, only, userScope)
        ? doRender(render, Component, props)
        : <Redirect to="/" />
    )}
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  userScope: state.auth.get('userScope'),
});

AuthorizedRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // you can pass either component or render func, just as in standard Route
  render: PropTypes.func,
  only: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  userScope: PropTypes.string,
};

AuthorizedRoute.defaultProps = {
  component: null,
  render: null,
  only: '',
  userScope: '',
};

export default withRouter(connect(mapStateToProps)(AuthorizedRoute));
