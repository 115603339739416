import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import containerStyles from '../styles/container.style';
import Container from '../components/Container';
import KeyboardAwareFlexWrapper from '../components/KeyboardAwareFlexWrapper';
import FormikWithValidation from '../components/form/FormikWithValidation';
import GeneralFormError from '../components/form/GeneralFormError';
import AutoScrollableKeyboardAwareScrollView from '../keyboard/AutoScrollableKeyboardAwareScrollView';
import FormikFieldPrimaryTextInput from '../components/form/FormikFieldPrimaryTextInput';
import { font } from '../styles/mixins';
import { fontFamily, fontSizes } from '../styles/base.style';
import { passwordValidation } from '../auth/LoginValidation';
import Button from '../components/buttons/Button';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 30,
  },
  text: {
    marginVertical: 30,
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, 0),
  },
});

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onSubmit, translate } = this.props;
    return (
      <Container>
        <View style={containerStyles.flexVerticalTopCentered}>

          <FormikWithValidation
            validation={passwordValidation}
            onSubmit={onSubmit}
            initialValues={{ }}
            render={({ handleSubmit, isBtnDisabled, errors }) => (
              <AutoScrollableKeyboardAwareScrollView>
                <Container>
                  <KeyboardAwareFlexWrapper>
                    <View style={styles.container}>
                      <FormikFieldPrimaryTextInput
                        innerRef={(ref) => !this.state.passwordInputRef && this.setState({ passwordInputRef: ref })}
                        name="password"
                        label={translate('loginScreen.newPassword')}
                        secureTextEntry
                        autoCapitalize="none"
                        returnKeyType="done"
                        showPasswordBtn
                      />
                    </View>
                  </KeyboardAwareFlexWrapper>
                  <KeyboardAwareFlexWrapper flexOnStart={false}>
                    <GeneralFormError errors={errors} />
                    <Button
                      title={translate('loginScreen.setNewPassword')}
                      onPress={handleSubmit}
                      disabled={isBtnDisabled}
                      testID="signupBtn"
                    />
                  </KeyboardAwareFlexWrapper>
                </Container>
              </AutoScrollableKeyboardAwareScrollView>
            )}
          />
        </View>
      </Container>
    );
  }
}

ResetPasswordScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withLocalize(ResetPasswordScreen);
