import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { ADMIN_USER, SUPERADMIN_USER } from '../../constants/userScopes';
import {
  ADMIN_GIGS, ADMIN_FIND_GIGS, ADMIN_GIG_DETAILS,
} from '../../constants/routes';
import AdminFindGigsContainer from './find_gigs/AdminFindGigsContainer';
import AdminGigDetailsView from './find_gigs/AdminGigDetailsView';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_FIND_GIGS;

const AdminGigRouting = () => (
  <>
    <Route exact path={ADMIN_GIGS} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute only={[SUPERADMIN_USER, ADMIN_USER]} path={ADMIN_FIND_GIGS} component={AdminFindGigsContainer} />
    <AuthorizedRoute only={[SUPERADMIN_USER, ADMIN_USER]} path={ADMIN_GIG_DETAILS} component={AdminGigDetailsView} />
  </>
);

export default AdminGigRouting;
