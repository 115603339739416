import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import AdminProfileDetailsView from './AdminProfileDetailsView';
import { candidateProfilePropType } from '../../../constants/propTypes';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../common/components/header/ScreenHeader';
import Container from '../../../common/components/Container';

const AdminProfileViewDetailsScreen = ({
  translate, profile, onSettingsButton,
}) => (
  <Container>
    <ScreenHeader
      title={translate('candidateMyProfile.header')}
      rightButton={RIGHT_BUTTONS.SETTINGS}
      handlerForRightButton={onSettingsButton}
    />
    {
      profile
      && (
        <Container>
          <AdminProfileDetailsView
            profile={profile}
          />
        </Container>
      )
    }
  </Container>
);

AdminProfileViewDetailsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  onSettingsButton: PropTypes.func.isRequired,
  profile: candidateProfilePropType,
};

AdminProfileViewDetailsScreen.defaultProps = {
  profile: undefined,
};

export default withLocalize(AdminProfileViewDetailsScreen);
