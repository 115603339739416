export function getEducationKey(education) {
  return education ? education.educationId || education.tmpEducationId : null;
}

/**
 * The key can be either tmpEducationId (used by frontend only) or educationId
 */
export function getEducationByKey(educationList, key) {
  return educationList.find((e) => getEducationKey(e) === key);
}

export function getNewEducation() {
  return {
    tmpEducationId: `${Math.random()}`,
    category: undefined,
    school: '',
    course: '',
    fromDate: undefined,
    toDate: undefined,
    graduationDate: undefined,
    ongoing: false,
  };
}
