import { List, Map } from 'immutable';
import {
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_CATEGORIES,
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_LOCATION,
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_POSITION_SEARCH,
} from '../../../../constants/actionTypes';

const INITIAL_CANDIDATE_GIGS = {
  categories: undefined,
  cityKey: undefined,
  positionSearch: undefined,
};

export default function candidateFindGigsUiReducer(state = new Map(INITIAL_CANDIDATE_GIGS), action) {
  switch (action.type) {
    case CANDIDATE_GIGS_FIND_GIGS_CHANGE_LOCATION:
      return state.set('cityKey', action.cityKey);
    case CANDIDATE_GIGS_FIND_GIGS_CHANGE_CATEGORIES:
      return state.set('categories', List(action.categories));
    case CANDIDATE_GIGS_FIND_GIGS_CHANGE_POSITION_SEARCH:
      return state.set('positionSearch', action.positionSearch);
    default:
      return state;
  }
}
