import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import { ActivityIndicator } from 'react-native';
import { connectActionSheet } from '@expo/react-native-action-sheet';

import Routing from '../../../core/routing/index';
import {
  COMPANY_CANDIDATES_POOL_SHIFTS_ARCHIVED,
  redirectToErrorScreen,
} from '../../../constants/routes';
import { closeShift, deleteShift, getShift } from '../companyShiftsActions';
import ShiftDetails from '../../../common/gig/ShiftDetails';
import { activityIndicator } from '../../../common/styles/base.style';

const { withRouter } = Routing;

class CompanyShiftViewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shift: undefined };
  }

  componentDidMount() {
    this.props.getShift(this.props.match.params.gigId).then((response) => {
      if (!response.isError) {
        this.setState({ shift: response.payload });
      } else if (response && response.status === 404) {
        redirectToErrorScreen(this.props.history, 'errors.notFoundTitle', 'errors.notFoundText');
      }
    });
  }

  openActionSheet = () => {
    const { translate } = this.props;
    const { shift } = this.state;

    const actionSheetParams = { options: [] };
    const actionHandlers = [];
    let optionsSize;

    if (shift && !shift.closedManually) {
      optionsSize = actionSheetParams.options.push(translate('shiftPreview.archiveShift'));
      actionHandlers[optionsSize - 1] = async () => {
        this.closeShift(shift.gigId, () => this.props.history.go(-2));
      };
    }

    if (shift && shift.closedManually) {
      optionsSize = actionSheetParams.options.push(translate('generic.remove'));
      actionHandlers[optionsSize - 1] = async () => {
        this.deleteShift(shift.gigId, () => this.props.history.replace(COMPANY_CANDIDATES_POOL_SHIFTS_ARCHIVED));
      };
    }

    optionsSize = actionSheetParams.options.push(translate('generic.cancel'));
    actionSheetParams.cancelButtonIndex = optionsSize - 1;
    actionHandlers[optionsSize - 1] = () => {};

    this.props.showActionSheetWithOptions(
      {
        ...actionSheetParams,
        showSeparators: true,
      },
      (buttonIndex) => {
        actionHandlers[buttonIndex]();
      },
    );
  };

  closeShift = (gigId, callback) => {
    this.props.closeShift(gigId).then((response) => {
      if (!response.isError) {
        callback();
      } else {
        redirectToErrorScreen(this.props.history, 'errors.candidateGigCurrentlyUnavailableTitle');
      }
    });
  };

  deleteShift = (gigId, callback) => {
    this.props.deleteShift(gigId).then((response) => {
      if (!response.isError) {
        callback();
      } else {
        redirectToErrorScreen(this.props.history, 'errors.candidateGigCurrentlyUnavailableTitle');
      }
    });
  };

  render() {
    const { shift } = this.state;

    if (!shift) {
      return (<ActivityIndicator size="large" style={{ ...activityIndicator }} />);
    }

    return (
      <ShiftDetails shift={shift} actionSheet={this.openActionSheet} />
    );
  }
}

CompanyShiftViewContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  getShift: PropTypes.func.isRequired,
  showActionSheetWithOptions: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  closeShift: PropTypes.func.isRequired,
  deleteShift: PropTypes.func.isRequired,
};

export default connect(null, { getShift, closeShift, deleteShift })(withRouter(withLocalize(connectActionSheet(CompanyShiftViewContainer))));
