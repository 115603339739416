import validator from 'validator';
import moment from 'moment';
import { isFutureDateIgnoreDayUtc } from '../../../../core/validation/validators';

export const CANDIDATE_PROFILE_DESCRIPTION_MAX_LENGTH = 100;
export const CANDIDATE_PROFILE_COMPANY_NAME_MAX_LENGTH = 30;
export const CANDIDATE_PROFILE_POSITION_MAX_LENGTH = 80;

export default [
  {
    field: 'category',
    method: validator.isEmpty,
    validWhen: false,
    message: 'null_value',
  },
  {
    field: 'position',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'position',
    method: validator.isLength,
    args: [{ max: CANDIDATE_PROFILE_POSITION_MAX_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'companyName',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'companyName',
    method: validator.isLength,
    args: [{ max: CANDIDATE_PROFILE_COMPANY_NAME_MAX_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'fromDate',
    custom: (value) => value != null,
    message: 'null_value',
  },
  {
    field: 'fromDate',
    custom: (value) => !isFutureDateIgnoreDayUtc(value),
    message: 'future_date',
  },
  {
    field: 'toDate',
    custom: (value, args, data) => (data.ongoing ? true : value != null),
    message: 'null_value',
  },
  {
    field: 'toDate',
    custom: (value, args, data) => (data.ongoing ? true : !isFutureDateIgnoreDayUtc(value)),
    message: 'future_date',
  },
  {
    field: 'toDate',
    custom: (value, args, data) => (data.ongoing ? true : moment(value).isSameOrAfter(data.fromDate)),
    message: 'before_start_date',
  },
  {
    field: 'description',
    method: validator.isLength,
    args: [{ max: CANDIDATE_PROFILE_DESCRIPTION_MAX_LENGTH }],
    message: 'too_long',
  },
];
