import { COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE_GET_REQUEST } from '../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';

export function getCandidateProfile(id) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.GET_POOL_CANDIDATE_PROFILE);
  return {
    type: COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE_GET_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `company/candidatesPool/candidate/${id}`,
      },
    },
  };
}
