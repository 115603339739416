import validator from 'validator';

export const MAX_NAME_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 80;

export default
[
  {
    field: 'name',
    custom: (value) => value && value.trim().length > 0,
    message: 'too_short',
  },
  {
    field: 'name',
    method: validator.isLength,
    args: [{ max: MAX_NAME_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'description',
    method: validator.isLength,
    args: [{ max: MAX_DESCRIPTION_LENGTH }],
    message: 'too_long',
  },
];
