import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Row, Rows, Table } from 'react-native-table-component';
import PropTypes from 'prop-types';
import moment from 'moment';
import { spacings, standardColors } from '../../common/styles/base.style';
import Routing from '../../core/routing/index';
import { getFortnoxCandidates } from './AdminFortnoxActions';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});

class AdminFortnoxCandidateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHead: ['Worker Name', 'Worker ID', 'Rate', 'Hours', 'Gig', 'Company', 'Date Start'],
      flexArr: [1, 1, 1, 1, 1, 1, 1, 1],
      fortnoxItems: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.getFortnoxCandidates().then((res) => {
      this.setState({ fortnoxItems: res.payload });
    });
  };

  render() {
    const itemsData = this.state.fortnoxItems.map((frtnx) => [
      frtnx.workerName,
      frtnx.workerId,
      frtnx.rate,
      frtnx.hoursWorked,
      frtnx.gigName,
      frtnx.companyName,
      frtnx.dateOfGig && moment(frtnx.dateOfGig).format('MMM DD, YYYY'),
    ]);
    const { state } = this;
    return (
      <>
        <View style={styles.container}>
          <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
            <Row data={state.tableHead} style={styles.head} textStyle={styles.text} flexArr={state.flexArr} />
            <Rows data={itemsData} textStyle={styles.text} flexArr={state.flexArr} />
          </Table>
        </View>
      </>
    );
  }
}

AdminFortnoxCandidateList.propTypes = {
  getFortnoxCandidates: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, {
    getFortnoxCandidates,
  })(withLocalize(AdminFortnoxCandidateList))
);
