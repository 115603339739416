import validator from 'validator';

export const MAX_NAME_LENGTH = 70;
export const MAX_DESCRIPTION_LENGTH = 1200;

export default
[
  {
    field: 'position',
    custom: (value) => value && value.trim().length > 0,
    message: 'required',
  },
  {
    field: 'position',
    method: validator.isLength,
    args: [{ max: MAX_NAME_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'description',
    custom: (value) => value && value.trim().length > 0,
    message: 'required',
  },
  {
    field: 'description',
    method: validator.isLength,
    args: [{ max: MAX_DESCRIPTION_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'candidatesNumber',
    method: validator.isInt,
    args: [{ max: 99 }],
    message: 'too_big',
  },
  {
    field: 'candidatesNumber',
    method: validator.isInt,
    args: [{ min: 1 }],
    message: 'too_small',
  },
  {
    field: 'position',
    method: validator.isEmpty,
    validWhen: false,
    message: 'required',
  },
  {
    field: 'description',
    method: validator.isEmpty,
    validWhen: false,
    message: 'required',
  },
  {
    field: 'candidatesNumber',
    method: validator.isEmpty,
    validWhen: false,
    message: 'required',
  },
];
