import { Dimensions, PixelRatio, Platform } from 'react-native';
import { fontsNames } from '../../config/fonts';

export const windowWidth = Dimensions.get('window').width;
export const windowHeight = Dimensions.get('window').height;
export const contentWidth = Platform.OS === 'web' && windowWidth > 1200 ? 1200 : windowWidth;
export const webContentPercentWidth = 85;
export const webMenuPercentWidth = 15;

export const wp = (widthPercent) => {
  const elemWidth = parseFloat(widthPercent);
  return PixelRatio.roundToNearestPixel((contentWidth * elemWidth) / 100);
};

export const hp = (heightPercent) => {
  const elemHeight = parseFloat(heightPercent);
  return PixelRatio.roundToNearestPixel((windowHeight * elemHeight) / 100);
};

export const fontFamily = { ...fontsNames };

export const modernColors = {
  lightGrey: '#F1F1F1',
  darkGrey: '#CBCBCB',
  grey: '#969696',
  dark: '#040303',
  magenta: '#c3006b',
};

export const socialColors = {
  facebook: {
    blue: '#1877f2',
  },
};

export const colors = {
  white: '#ffffff',
  colgateWhite: '#fcfcfc',
  smokeWhite: '#f9f9f9',
  alabaster: '#f8f8f8',
  seashell: '#f1f1f1',
  ultraLightGrey: '#e8e8e8',
  veryLightGrey: '#e2e2e2',
  veryLightPinkTwo: '#ebebeb',
  veryLightPink: '#d7d7d7',
  lightPeach: '#b6b6b6',
  brownGrey: '#919191',
  brownishGrey: '#6f6f6f',
  greyishBrown: '#494949',
  gondola: '#040303',
  black: '#000000',

  bluePurpleTwo: '#5c2db2',
  purpleyBlue: '#5a3cdc',
  bluePurple: '#452bb8',
  sapphire: '#362098',

  tealish: '#33bcb1',
  blueChill: '#119F92',
  seablue: '#119fbf',
  greenishTeal: '#33bc78',
  brightOrange: '#ff6d00',
  magenta: '#c3006b',
  lipstick: '#C5006B',
  gold: '#ffd302',
};

export const externalColors = {
  workbuster: {
    background: '#3C7BC0',
  },
};

export const standardColors = {
  primary: '',
  secondary: '',
  warning: '',
  error: colors.magenta,
  border: colors.veryLightGrey,
  text: colors.greyishBrown,
  listBackground: colors.smokeWhite,
  webContentBackground: colors.colgateWhite,
};

export const spacings = {
  xxs: 5,
  xs: 7,
  sm: 14,
  md: 21,
  lg: 28,
  xl: 35,
  xxl: 42,
  xxxl: 56,
  xxxxl: 72,
};

export const heightSpacingsBolek = {
  p01: hp(0.1),
  p10: hp(1),
  p15: hp(1.5),
  p20: hp(2),
  p40: hp(4),
  p140: hp(14),
};

export const widthSpacingsBolek = {
  p20: wp(2),
  p40: wp(4),
  p80: wp(8),
  p160: wp(16),
};

export const standardSpacings = {
  formVertical: spacings.lg,
};

export const fontSizes = {
  xxxs: 11,
  xxs: 12,
  xs: 13,
  sm: 15,
  md: 16,
  lg: 18,
  xl: 19,
  xxl: 22,
  xxxl: 32,
};

export const dynamicSpacings = {
  sm: wp(5),
  md: wp(7.2),
  lg: wp(11),
};

/*
@deprecated use spacings
 */
export const padding = {
  xxs: 2,
  xs: 7,
  sm: 14,
  md: 21,
  lg: 28,
  xl: 35,
  xxl: 42,
  xxxl: 56,
};

/*
@deprecated use fontSizes
 */
export const fontSize = {
  sm: 10,
  md: 12,
  lg: 14,
  xl: 19,
  xxl: 24,
};

/*
@deprecated use colors
 */
export const color = {
  primary: '#080808',
  primaryContrast: '#FFF',
  secondary: '#636363',
  secondaryContrast: '#FFF',
  light: '#efefef',
  lightgrey: '#bfbfbf',
  lightContrast: '#000000',
  inputLabel: '#9B9A9B',
  inputText: '#b0b0ba',
  inputBorderBorderColor: '#e9e9ea',
  modalBorderColor: '#aeaeae',
  error: '#c4000c',
  green: '#25CD1F',
  red: '#E71B25',
};

const baseShadow = {
  backgroundColor: 'white',
  shadowColor: '#000',
  shadowOpacity: 0.1,
  zIndex: 1000,
};

export const shadow = {
  ...baseShadow,
  shadowOpacity: 0.15,
  shadowOffset: { width: 0, height: 4 },
  shadowRadius: 15,

  elevation: 8,
};

export const shadowBottom = {
  ...baseShadow,
  shadowOffset: { width: 0, height: 1 },
  shadowRadius: 1,

  elevation: 1,
};

export const shadowTop = {
  ...baseShadow,
  shadowOffset: { width: 0, height: -1 },
  shadowRadius: 1,

  elevation: 4,
};

export const activityIndicator = {
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
};
