import React from 'react';
import { StyleSheet, View } from 'react-native';
import CandidateFindGigsFilters from './filters/CandidateFindGigsFilters';
import CandidateFindGigsList from './CandidateFindGigsList';
import Container from '../../../common/components/Container';
import { shadowBottom, spacings } from '../../../common/styles/base.style';

const styles = StyleSheet.create({
  container: {
    ...shadowBottom,
    marginBottom: 0,
    paddingHorizontal: spacings.sm,
  },
});

function CandidateFindGigsListContainer() {
  return (
    <Container>
      <View style={styles.container}>
        <CandidateFindGigsFilters />
      </View>
      <CandidateFindGigsList />
    </Container>
  );
}
export default CandidateFindGigsListContainer;
