import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet, ScrollView, View, Platform } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { colors, fontFamily, fontSizes, spacings } from '../styles/base.style';
import FormikWithValidation from '../components/form/FormikWithValidation';
import FormikFieldPrimaryTextInput from '../components/form/FormikFieldPrimaryTextInput';
import GeneralFormError from '../components/form/GeneralFormError';
import FormikFieldPrimarySwitch from '../components/form/switch/FormikFieldPrimarySwitch';
import LinkButton from '../components/buttons/LinkButton';
import { CANDIDATE_USER, COMPANY_USER, SUPERADMIN_USER } from '../../constants/userScopes';
import { validationSchemaPropTypes } from '../../constants/propTypes';
import { USER_FIRST_NAME_MAX_LENGTH, USER_LAST_NAME_MAX_LENGTH } from '../auth/UserSignupValidation';
import ScreenHeader from '../components/header/ScreenHeader';
import PrimaryKeyboardAwareScrollView from '../keyboard/PrimaryKeyboardAwareScrollView';
import Container from '../components/Container';
import Wrapper from '../components/Wrapper';
import SignupDocumentModal from './SignupDocumentModal';
import { getTermsAndPrivacySwitchLabel } from './termsAndConditionSwitchLabel';
import FormikPhoneNumber from '../components/form/FormikPhoneNumber';
import FormikFieldPrimaryPicker from '../components/form/FormikFieldPrimaryPicker';
import { getAvailableMunicipalities } from '../gig/city/gigCitiesActions';
import { font } from '../styles/mixins';
import { getAssociations } from '../../association/AssociationActions';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  bottomWrapper: {
    backgroundColor: colors.white,
  },
  emailContainer: {
    padding: 20,
  },
  extraInfo: {
    ...font(fontFamily.SFProTextRegular, fontSizes.lg),
    marginBottom: spacings.sm,
  },
  header: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxl),
    marginBottom: spacings.sm,
  },
});

class SignupScreen extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      privacyText: '',
      termsText: '',
    };
    this.initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      termsAndPrivacy: false,
      city: '',
      association: '',
    };
    if (props.userType === CANDIDATE_USER || props.userType === SUPERADMIN_USER) {
      this.initialValues.password = '';
    }
    if (props.userType === COMPANY_USER) {
      this.initialValues.companyName = '';
      this.initialValues.msisdn = {
        countryCode: '',
        phoneNumber: '',
      };
    }
    fetch('https://s3-eu-west-1.amazonaws.com/assets.letsgig.com/privacyPolicy.txt')
      .then((resp) => {
        if (resp.ok) {
          return resp.text();
        }
        return '';
      })
      .then((theText) => {
        this.setState({ privacyText: theText });
      });

    fetch('https://s3-eu-west-1.amazonaws.com/assets.letsgig.com/terms.txt')
      .then((resp) => {
        if (resp.ok) {
          return resp.text();
        }
        return '';
      })
      .then((theText) => {
        this.setState({ termsText: theText });
      });
  }

  componentDidMount() {
    this.props.getAvailableMunicipalities();
  }

  getTranslatedList = (nullable, list) => {
    const categories = list.map((c) => ({
      key: c.key,
      value: c.key,
      label: c.name ?? '',
    }));
    if (Platform.OS !== 'ios' && !list.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  getAssociationsByCity = (city) => {
    this.props.getAssociations(city);
  };

  render() {
    const {
      translate,
      onSubmit,
      redirect,
      userType,
      validation,
      redirectPath,
      termsModalVisible,
      privacyModalVisible,
      handleCloseModal,
      onOpenPrivacy,
      onOpenTerms,
      signUpSuccess,
      handleCloseSignup,
      availableMunicipalities,
    } = this.props;
    const t = (key) => translate(`${userType.toLowerCase()}WelcomeScreen.signupScreen.${key}`);

    return (
      <Container>
        <SignupDocumentModal visible={signUpSuccess} handleCloseModal={handleCloseSignup} header={t('success')}>
          <View style={styles.emailContainer}>
            <Text style={{ fontSize: '150%' }}>{t('checkEmail')}</Text>
          </View>
        </SignupDocumentModal>
        <SignupDocumentModal visible={termsModalVisible} handleCloseModal={handleCloseModal} header={t('termsHeader')}>
          <ScrollView>
            <Text>{this.state.termsText}</Text>
          </ScrollView>
        </SignupDocumentModal>

        <SignupDocumentModal visible={privacyModalVisible} handleCloseModal={handleCloseModal} header={t('privacyHeader')}>
          <ScrollView>
            <Text>{this.state.privacyText}</Text>
          </ScrollView>
        </SignupDocumentModal>

        <ScreenHeader title={t('header')} showBackArrow />
        <PrimaryKeyboardAwareScrollView testID="scrollView">
          <FormikWithValidation
            validation={validation}
            initialValues={this.initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, isBtnDisabled, errors }) => (
              <Container>
                <Wrapper noFlex>
                  {(userType === COMPANY_USER || userType === SUPERADMIN_USER) && (
                    <>
                      <FormikFieldPrimaryTextInput name="companyName" label={t('companyNameLabel')} />
                      <FormikFieldPrimaryTextInput name="companyRegistrationNumber" label={t('companyRegistrationNumberLabel')} />
                    </>
                  )}
                  <FormikFieldPrimaryTextInput name="firstName" label={t('firstNameLabel')} maxLength={USER_FIRST_NAME_MAX_LENGTH} />
                  <FormikFieldPrimaryTextInput name="lastName" label={t('lastNameLabel')} maxLength={USER_LAST_NAME_MAX_LENGTH} />

                  <FormikFieldPrimaryTextInput
                    name="email"
                    autoCapitalize="none"
                    label={translate('generic.emailLabel')}
                    keyboardType="email-address"
                  />
                  <FormikFieldPrimaryTextInput
                    name="password"
                    label={translate('generic.passwordLabel')}
                    secureTextEntry
                    showPasswordBtn
                    autoCapitalize="none"
                  />
                  {userType === COMPANY_USER && (
                    <FormikPhoneNumber
                      countryCodeName="msisdn.countryCode"
                      phoneNumberName="msisdn.phoneNumber"
                      countryCodeLabel={translate('generic.phoneCodeLabel')}
                      countryCodePlaceholder={translate('generic.phoneCodePlaceholder')}
                      phoneNumberLabel={translate('generic.phoneLabel')}
                      phoneNumberPlaceholder={translate('generic.phonePlaceholder')}
                    />
                  )}

                  {/* {userType === SUPERADMIN_USER && (
                     <FormikFieldPrimaryTextInput
                     name="freeAds"
                     label={t('freeAds')}
                     maxLength={USER_LAST_NAME_MAX_LENGTH}
                   />
                  )}
                  */}
                  <Text style={styles.header}>{translate('adminPanel.doYouBelong')}</Text>
                  <Text style={styles.extraInfo}>{translate('adminPanel.selectInTheList')}</Text>
                  <Text style={styles.extraInfo}>{translate('adminPanel.ifNotAffiliated')}</Text>
                  <FormikFieldPrimaryPicker
                    name="city"
                    items={this.getTranslatedList(true, availableMunicipalities)}
                    label={t('cityLabel')}
                    nullable
                    onValueChange={(e) => this.getAssociationsByCity(e)}
                  />
                  <FormikFieldPrimaryPicker
                    enabled={this.props.associations.length > 1}
                    name="association"
                    items={this.props.associations}
                    label={t('chooseOrganization')}
                    nullable
                  />
                </Wrapper>
                <Wrapper style={styles.bottomWrapper}>
                  {userType !== SUPERADMIN_USER && (
                    <FormikFieldPrimarySwitch
                      name="termsAndPrivacy"
                      renderLabel={() => getTermsAndPrivacySwitchLabel(t, onOpenPrivacy, onOpenTerms)}
                    />
                  )}
                  <GeneralFormError errors={errors} />
                  <LinkButton
                    to={redirectPath}
                    title={t('signupButton')}
                    doRedirect={redirect}
                    onPress={handleSubmit}
                    disabled={isBtnDisabled}
                    testID="signupBtn"
                  />
                </Wrapper>
              </Container>
            )}
          />
        </PrimaryKeyboardAwareScrollView>
      </Container>
    );
  }
}

SignupScreen.propTypes = {
  userType: PropTypes.oneOf([CANDIDATE_USER, COMPANY_USER, SUPERADMIN_USER]).isRequired,
  validation: validationSchemaPropTypes.isRequired,
  redirectPath: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  termsModalVisible: PropTypes.bool.isRequired,
  privacyModalVisible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  onOpenPrivacy: PropTypes.func.isRequired,
  onOpenTerms: PropTypes.func.isRequired,
  signUpSuccess: PropTypes.bool.isRequired,
  handleCloseSignup: PropTypes.func.isRequired,
  availableMunicipalities: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAvailableMunicipalities: PropTypes.func.isRequired,
  getAssociations: PropTypes.func.isRequired,
  associations: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  availableMunicipalities: state.gigCities.toJS(),
  associations: state.association.get('associationsList'),
});
export default connect(mapStateToProps, {
  getAvailableMunicipalities,
  getAssociations,
})(withLocalize(SignupScreen));
