import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import GigCreatorWelcome from './GigCreatorWelcome';
import GigCreatorStepInfoContainer from './steps/info/GigCreatorStepInfoContainer';
import GigCreatorStepCategoryContainer from './steps/categories/GigCreatorStepCategoryContainer';
import GigCreatorStepCompensationContainer from './steps/compensation/GigCreatorStepCompensationContainer';
import GigCreatorStepDescriptionContainer from './steps/description/GigCreatorStepDescriptionContainer';
import GigCreatorStepDatesContainer from './steps/dates/GigCreatorStepDatesContainer';
import GigCreatorStepLocationContainer from './steps/location/GigCreatorStepLocationContainer';
import GigCreatorStepAvailabilityContainer from './steps/availability/GigCreatorStepAvailabilityContainer';
import GigCreatorStepExperienceContainer from './steps/experience/GigCreatorStepExperienceContainer';
import GigCreatorStepLanguageContainer from './steps/languages/GigCreatorStepLanguageContainer';
import GigCreatorStepQuestionnaireContainer from './steps/questionnaire/GigCreatorStepQuestionnaireContainer';

import { COMPANY_USER } from '../../constants/userScopes';
import {
  COMPANY_GIG_EDIT,
  COMPANY_GIG_EDIT_AVAILABILITY,
  COMPANY_GIG_EDIT_CATEGORY,
  COMPANY_GIG_EDIT_COMPENSATION,
  COMPANY_GIG_EDIT_DATES,
  COMPANY_GIG_EDIT_DESCRIPTION,
  COMPANY_GIG_EDIT_EXPERIENCE,
  COMPANY_GIG_EDIT_INFO,
  COMPANY_GIG_EDIT_LANGUAGE,
  COMPANY_GIG_EDIT_LOCATION,
  COMPANY_GIG_EDIT_OCCUPATION,
  COMPANY_GIG_EDIT_PHOTO,
  COMPANY_GIG_EDIT_QUESTIONNAIRE,
  COMPANY_GIG_EDIT_START,

} from '../../constants/routes';
import GigCreatorStepPhotoContainer from './steps/photo/GigCreatorStepPhotoContainer';
import GigCreatorStepOccupationContainer from './steps/occupation/GigCreatorStepOccupationContainer';
import ViewportController from '../../common/components/ViewportController';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = COMPANY_GIG_EDIT_START;

const GigCreatorRouting = () => (
  <ViewportController safeAreaBottom>
    <Route exact path={COMPANY_GIG_EDIT} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_START} component={GigCreatorWelcome} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_INFO} component={GigCreatorStepInfoContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_OCCUPATION} component={GigCreatorStepOccupationContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_CATEGORY} component={GigCreatorStepCategoryContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_DESCRIPTION} component={GigCreatorStepDescriptionContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_LOCATION} component={GigCreatorStepLocationContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_DATES} component={GigCreatorStepDatesContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_COMPENSATION} component={GigCreatorStepCompensationContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_AVAILABILITY} component={GigCreatorStepAvailabilityContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_EXPERIENCE} component={GigCreatorStepExperienceContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_LANGUAGE} component={GigCreatorStepLanguageContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_QUESTIONNAIRE} component={GigCreatorStepQuestionnaireContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT_PHOTO} component={GigCreatorStepPhotoContainer} />
  </ViewportController>
);

export default GigCreatorRouting;
