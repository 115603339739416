import React, { Component } from 'react';
import {
  Linking, StyleSheet, Text, View,
} from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../../common/components/index';
import {
  colors, fontFamily, fontSizes, shadowTop, standardSpacings, spacings,
} from '../../../../common/styles/base.style';
import GigCreatorHeader from '../../GigCreatorHeader';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorStepCompensationValidation from './GigCreatorStepCompensationValidation';
import Container from '../../../../common/components/Container';
import PrimaryText from '../../../../common/components/PrimaryText';
import FormikFieldPrimarySwitch from '../../../../common/components/form/switch/FormikFieldPrimarySwitch';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import HorizontalWrapper from '../../../../common/components/HorizontalWrapper';
import FormikFieldCompensationInput from '../../../../common/components/form/FormikFieldCompensationInput';
import { font } from '../../../../common/styles/mixins';
import Button from '../../../../common/components/buttons/Button';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';
import { PER } from '../../../../constants/salaryTypes';
import AutoScrollableKeyboardAwareScrollView from '../../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import { getSalaryUnits } from '../../../../common/gig/salaryFormatter';

const styles = StyleSheet.create({
  titleLabel: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    paddingTop: spacings.lg,
    marginBottom: spacings.lg,
  },
  switchLabel: {
    fontFamily: fontFamily.SFProTextRegular,
    fontSize: fontSizes.md,
  },
  additionalInfo: {
    ...font(fontFamily.SFProTextRegular, fontSizes.xs),
  },
  link: {
    ...font(fontFamily.SFProTextSemiBold, fontSizes.xs, null, colors.magenta),
  },
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
});

const inputStyle = StyleSheet.create({
  container: {
    marginBottom: 10,
    width: 150,
  },
  input: {
    marginBottom: 0,
    paddingBottom: 0,
  },
});

const horizontalPicker = StyleSheet.create({
  androidAndWebWrapper: {
    borderBottomWidth: 0,
    width: 150,
    alignItems: 'flex-end',
  },
  container: {
    marginBottom: standardSpacings.formVertical,
    display: 'flex',
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  label: {
    fontFamily: fontFamily.SFProTextRegular,
    fontSize: fontSizes.md,
    alignSelf: 'center',
  },
  dashLabelStyle: {
    marginLeft: spacings.xxs,
  },
});

const MIN = 'min';
const MAX = 'max';
const SALARY_PER = 'salaryPer';
const SALARY_BY_AGREEMENT = 'salaryByAgreement';
const LABOUR_CONTRACT = 'labourContract';
const VOLUNTEERING = 'volunteering';
const INTERNSHIP_TRAINEE = 'internshipTrainee';

function openLink() {
  Linking.openURL('https://www.foretagarna.se/juridisk-faq/anstallning/sommaranstalla');
}

class GigCreatorStepCompensationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLink = (t) => (
    <Text onPress={openLink} style={styles.link}>{t('readMore.part2')}</Text>
  );

  render() {
    const {
      translate, initialValues, onSubmit, stepNumber, isEdit, nextScreen, maxStepsNumber,
    } = this.props;
    const t = (key) => translate(`company.createGig.salary.${key}`);

    return (
      <Container>
        <FormikWithValidation
          validation={GigCreatorStepCompensationValidation}
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({
            handleSubmit, setFieldValue, status, isBtnDisabled, values,
          }) => (
            <>
              <GigCreatorHeader
                isEdit={isEdit}
                stepNo={stepNumber}
                maxStepsNumber={maxStepsNumber}
                title={t('header')}
                onRightButtonPress={handleSubmit}
                isRightButtonDisabled={isBtnDisabled}
              />
              <AutoScrollableKeyboardAwareScrollView>
                <KeyboardAwareFlexWrapper>
                  <PrimaryText style={styles.titleLabel}>{t('title')}</PrimaryText>

                  {/* <FormikFieldPrimarySwitch
                    style={{ label: styles.switchLabel }}
                    name={SALARY_BY_AGREEMENT}
                    onValueChange={
                      () => {
                        setFieldValue(MIN, '');
                        setFieldValue(MAX, '');
                      }
                    }
                    label={t('salaryByAgreement')}
                  /> */}

                  <FormikFieldPrimaryPicker
                    name={SALARY_PER}
                    enabled={!values[SALARY_BY_AGREEMENT]}
                    items={getSalaryUnits(translate)}
                    label={`${t('per')} `}
                    style={{ ...horizontalPicker }}
                    inputStyle={inputStyle}
                  />
                  <HorizontalWrapper noFlex>
                    <FormikFieldCompensationInput
                      name={MIN}
                      placeholder=" "
                      disabled={values[SALARY_BY_AGREEMENT]}
                      editable={!values[SALARY_BY_AGREEMENT]}
                      label={t('from')}
                      nextComponent={this.state.maxInputRef}
                      leftLabel="SEK"
                    />
                    <FormikFieldCompensationInput
                      innerRef={(ref) => !this.state.maxInputRef && this.setState({ maxInputRef: ref })}
                      name={MAX}
                      placeholder=" "
                      disabled={values[SALARY_BY_AGREEMENT]}
                      editable={!values[SALARY_BY_AGREEMENT]}
                      label={t('to')}
                      leftLabel="-"
                      leftLabelStyle={horizontalPicker.dashLabelStyle}
                    />
                  </HorizontalWrapper>
                  <FormikFieldPrimarySwitch
                    style={{ label: styles.switchLabel }}
                    name={LABOUR_CONTRACT}
                    label={t('labourContract')}
                  />
                  <FormikFieldPrimarySwitch
                    style={{ label: styles.switchLabel }}
                    name={VOLUNTEERING}
                    label={t('volunteering')}
                    onValueChange={
                      (val) => {
                        if (val) {
                          setFieldValue(INTERNSHIP_TRAINEE, false);
                        }
                      }
                    }
                  />
                  <FormikFieldPrimarySwitch
                    style={{ label: styles.switchLabel }}
                    name={INTERNSHIP_TRAINEE}
                    label={t('internshipTrainee')}
                    onValueChange={
                      (val) => {
                        if (val) {
                          setFieldValue(VOLUNTEERING, false);
                        }
                      }
                    }
                  />
                  {/* <Text style={styles.additionalInfo}>{t('additionalInfo')}</Text>
                  <Container row>
                    <Text style={[styles.additionalInfo, { paddingBottom: spacings.md }]}>
                      <Text>{t('readMore.part1')}</Text>
                      {this.renderLink(t)}
                      <Text>.</Text>
                    </Text>
                  </Container> */}
                </KeyboardAwareFlexWrapper>
                <View style={styles.button}>
                  {isEdit ? (
                    <Button
                      title={translate('generic.save')}
                      disabled={isBtnDisabled}
                      onPress={handleSubmit}
                    />
                  ) : (
                    <LinkButton
                      to={nextScreen}
                      title={translate('generic.next')}
                      doRedirect={status && status.redirect}
                      disabled={isBtnDisabled}
                      onPress={handleSubmit}
                    />
                  )}
                </View>
              </AutoScrollableKeyboardAwareScrollView>
            </>
          )}
        />
      </Container>
    );
  }
}

GigCreatorStepCompensationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    min: PropTypes.string.isRequired,
    max: PropTypes.string.isRequired,
    salaryPer: PropTypes.oneOf([PER.HOUR, PER.MONTH]).isRequired,
    labourContract: PropTypes.bool.isRequired,
    salaryByAgreement: PropTypes.bool.isRequired,
    volunteering: PropTypes.bool.isRequired,
    internshipTrainee: PropTypes.bool.isRequired,
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,

};

export default withLocalize(GigCreatorStepCompensationScreen);
