import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native';

import { getAvailableGroups } from './candidatesPoolGroupsActions';
import CompanyCandidatesPoolGroupsScreen from './CompanyCandidatesPoolGroupsScreen';
import { activityIndicator } from '../../../common/styles/base.style';
import Container from '../../../common/components/Container';

class CompanyCandidatesPoolGroupsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poolGroups: null,
    };
  }

  componentDidMount() {
    this.props.getAvailableGroups({ privateOnly: false }).then((res) => {
      this.setState({ poolGroups: res.payload });
    });
  }

  render() {
    if (!this.state.poolGroups) {
      return (
        <Container>
          <ActivityIndicator size="large" style={{ ...activityIndicator }} />
        </Container>
      );
    }
    return (<CompanyCandidatesPoolGroupsScreen poolGroups={this.state.poolGroups} />);
  }
}

CompanyCandidatesPoolGroupsContainer.propTypes = {
  getAvailableGroups: PropTypes.func.isRequired,
};

CompanyCandidatesPoolGroupsContainer.defaultProps = {
};

export default connect(null, {
  getAvailableGroups,
})(CompanyCandidatesPoolGroupsContainer);
