import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withLocalize } from 'react-localize-redux';
import {
  Text, View, StyleSheet, Image,
} from 'react-native';
import {
  fontFamily, fontSizes, spacings, colors,
} from '../styles/base.style';
import ImageSelectorComponent from './image/ImageSelectorComponent';
import UserProfileImage from './image/UserProfileImage';
import Header from './header/Header';
import { font } from '../styles/mixins';
import OnlineIndicator from './chat/OnlineIndicator';
import { activeLanguagePropTypes, stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacings.md,
  },
  defaultImageSize: {
    width: 107,
    height: 107,
    borderRadius: 53,
  },
  infoWrapper: {
    marginLeft: spacings.md,
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  name: {
    marginBottom: spacings.xs,
    textAlign: 'left',
  },
  extraInfo: {
    ...font(fontFamily.SFProTextRegular, fontSizes.xs, -0.2, colors.lightPeach),
    paddingRight: spacings.sm,
  },
  boldText: {
    fontWeight: 'bold'
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: spacings.sm,
  },
  locationIcon: {
    width: 11,
    marginRight: spacings.xs,
    resizeMode: 'contain',
  },
  companyName: {
    ...font(fontFamily.SFProTextSemiBold, fontSizes.lg, 0, colors.greyishBrown),
    marginTop: 5,
  },
  email: {
    ...font(fontFamily.SFProTextRegular, fontSizes.sm, -0.2, colors.lightPeach),
    marginBottom: spacings.xs,
  },
  onlineIndicator: {
    position: 'absolute',
    bottom: 1,
    right: 1,
  },
  lastSeen: {
    marginTop: spacings.xs,
    ...font(fontFamily.SFProTextRegular, fontSizes.xxxs, -0.2, colors.lightPeach),
  },
});

const location = require('../../assets/icons/location/locationIcon.png');
const userProfile = require('../../assets/icons/user_profile.png');

const LAST_SEEN_ROLLOUT_DATE = '2019-10-01';

export const renderEmail = (profile) => (profile.email && (<Text style={styles.email}>{profile.email}</Text>));

export const renderLocation = (profile) => (profile.location && (
  <View style={styles.locationContainer}>
    <Image source={location} style={styles.locationIcon} />
    <Text style={styles.extraInfo}>{profile.location.cityV2.name}</Text>
  </View>
));

export const renderAssociation = (profile) => (profile.association && (
  <View>
    <Text style={[styles.companyName, styles.boldText]}>{profile.association}</Text>
  </View>
));

export const renderHomeLocation = (profile) => (profile.homeLocation ? (
  <View style={styles.locationContainer}>
    <Image source={location} style={styles.locationIcon} />
    <Text style={styles.extraInfo}>{profile.homeLocation.locationName}</Text>
  </View>
) : renderLocation(profile));

export const renderCompanyInfo = (profile) => (
  <View>
    <Text style={styles.extraInfo}>{profile.email}</Text>
    <Text style={styles.companyName}>{profile.companyName}</Text>
  </View>
);

export const renderAdminInfo = (profile) => (
  <View>
    <Text style={styles.extraInfo}>{profile.email}</Text>
  </View>
);

class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { lastSeenTimestamp: props.profile.lastActivityDate };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.online && prevProps.online) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ lastSeenTimestamp: moment().valueOf() });
    }
  }

  getLastSeen = () => {
    if (this.state.lastSeenTimestamp) {
      const lastActivityMoment = moment(this.state.lastSeenTimestamp, undefined, this.props.activeLanguage.code);
      return lastActivityMoment.fromNow();
    }
    return `${this.props.translate('generic.moreThan')} ${moment(LAST_SEEN_ROLLOUT_DATE, undefined, this.props.activeLanguage.code).utc().fromNow()}`;
  };

  render() {
    const {
      profile, lastNameOneLetter, renderExtraInfo, imageSize, style, editImage, editProfileImageAction, online, grantedFeatures, currentUserId,
    } = this.props;
    const effectiveImageSize = (imageSize || styles.defaultImageSize).width;
    const radius = Math.round(effectiveImageSize / 3.5);
    return (
      <View style={[styles.container, style.wrapper]}>
        {!editImage && (
          <View style={{ alignSelf: 'flex-start' }}>
            <UserProfileImage profileImageUri={profile.profileImageUri} style={[imageSize || styles.defaultImageSize, { alignSelf: 'flex-start' }]} />
            <OnlineIndicator online={online} style={{ ...styles.onlineIndicator, radius }} />
          </View>
        )}
        {editImage && (
          <ImageSelectorComponent
            defaultImage={userProfile}
            uri={profile.profileImageUri}
            style={{ image: [imageSize || styles.defaultImageSize, { alignSelf: 'flex-start' }] }}
            uploadImageAction={editProfileImageAction}
            resizedWidth={2000}
            cropToSquare
          />
        )}
        <View style={[styles.infoWrapper, style.infoWrapper]}>
          {!!profile.firstName && (
            <Header style={[styles.name, style.name]}>{`${profile.firstName} ${lastNameOneLetter ? profile.lastName.substr(0, 1) : profile.lastName}`}</Header>
          )}
          {renderExtraInfo && renderExtraInfo(profile)}
          {renderAssociation(profile)}
          {grantedFeatures.includes('HOME_LOCATION') ? renderHomeLocation(profile) : renderLocation(profile)}
          {!online && currentUserId !== profile.userId && (
            <Text style={styles.lastSeen}>{`${this.props.translate('generic.lastSeen')} ${this.getLastSeen()}`}</Text>
          )}
        </View>
      </View>
    );
  }
}

ProfileInfo.propTypes = {
  translate: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  grantedFeatures: PropTypes.arrayOf(String).isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  editProfileImageAction: PropTypes.func,
  profile: PropTypes.shape({
    userId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    profileImageUri: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
    }),
    homeLocation: PropTypes.shape({
      locationName: PropTypes.string,
    }),
    lastActivityDate: PropTypes.number,
  }),
  lastNameOneLetter: PropTypes.bool,
  renderExtraInfo: PropTypes.func,
  imageSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  style: stylePropTypes,
  editImage: PropTypes.bool,
  online: PropTypes.bool,
};

ProfileInfo.defaultProps = {
  profile: {
    firstName: '',
    lastName: '',
    email: '',
  },
  lastNameOneLetter: false,
  renderExtraInfo: undefined,
  imageSize: undefined,
  style: {
    wrapper: null,
    name: null,
  },
  editImage: false,
  editProfileImageAction: undefined,
  online: null,
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  currentUserId: state.auth.get('userId'),
});

export default connect(mapStateToProps, null)(withLocalize(ProfileInfo));
