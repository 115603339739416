import SFProDisplayBold from '../assets/fonts/SF-Pro-Display-Bold.otf';
import SFProDisplayRegular from '../assets/fonts/SF-Pro-Display-Regular.otf';
import SFProDisplaySemiBold from '../assets/fonts/SF-Pro-Display-Semibold.otf';
import SFProDisplaySemiBoldItalic from '../assets/fonts/SF-Pro-Display-SemiboldItalic.otf';
import SFProTextRegular from '../assets/fonts/SF-Pro-Text-Regular.otf';
import SFProTextSemiBold from '../assets/fonts/SF-Pro-Text-Semibold.otf';
import SFProDisplayMedium from '../assets/fonts/SF-Pro-Display-Medium.otf';
import SFProDisplayItalic from '../assets/fonts/SF-Pro-Display-RegularItalic.otf';

export const fontsMap = {
  SFProDisplayRegular,
  SFProDisplayBold,
  SFProDisplaySemiBold,
  SFProDisplaySemiBoldItalic,
  SFProTextRegular,
  SFProTextSemiBold,
  SFProDisplayMedium,
  SFProDisplayItalic,
};

/**
 * Creates maps of font family names based on configuration
 */
export const fontsNames = Object.keys(fontsMap).reduce((acc, fontName) => {
  acc[fontName] = fontName;
  return acc;
}, {});
