import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Translate, withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../common/components/index';
import { spacings } from '../../../common/styles/base.style';
import { CANDIDATE_PROFILE } from '../../../constants/routes';
import BackgroundImageScreen from '../../../common/screens/BackgroundImageScreen';
import Header from '../../../common/components/header/Header';
import ViewportController from '../../../common/components/ViewportController';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';

const styles = StyleSheet.create({
  container: {
    paddingTop: spacings.xxl,
    justifyContent: 'flex-start',
  },
  text: {
    marginBottom: spacings.xxl,
  },
});

const welcomeImage = require('../../../assets/photos/afterLogin/afterLogin.jpg');

class ProfileCreatorSuccessRequiredSteps extends Component {
  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.SUCCESS);
  }

  render() {
    const { translate } = this.props;
    return (
      <ViewportController statusBarHidden>
        <BackgroundImageScreen style={{ content: styles.container }} img={welcomeImage}>
          <Header style={styles.text}>
            <Translate id="candidateProfileCreator.successRequiredSteps.header" />
          </Header>
          <LinkButton to={CANDIDATE_PROFILE} title={translate('candidateProfileCreator.successRequiredSteps.btn')} />
        </BackgroundImageScreen>
      </ViewportController>
    );
  }
}

ProfileCreatorSuccessRequiredSteps.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withLocalize(ProfileCreatorSuccessRequiredSteps);
