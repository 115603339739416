import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import PrimaryModal from '../../../../common/components/modal/PrimaryModal';
import GigCategorySelectListContainer from '../../../../common/gig/GigCategorySelectListContainer';
import EditModalFooter from '../../../../common/components/modal/EditModalFooter';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';

const FindGigsSelectCategoryModal = ({
  translate, visible, categories, handleSubmit, handleCancel,
}) => (
  <PrimaryModal
    visible={visible}
    handleCancel={handleCancel}
    header={translate('candidateProfileCreator.category.header')}
    headerProps={{ shadow: true }}
  >
    <FormikWithValidation
      validation={[]}
      initialValues={{ categories }}
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
        <>
          <GigCategorySelectListContainer
            name="general"
          />
          <EditModalFooter
            handleCancel={handleCancel}
            handleSubmitFormik={handleSubmitFormik}
            isBtnDisabled={isBtnDisabled}
            confirmLabel={translate('generic.choose')}
          />
        </>
      )}
    />
  </PrimaryModal>

);

FindGigsSelectCategoryModal.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withLocalize(FindGigsSelectCategoryModal);
