import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { View, Platform, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { getAvailableMunicipalities } from '../../../../common/gig/city/gigCitiesActions';
import { adminFindAssociationFilterPropType } from '../../../../constants/propTypes';
import { windowWidth, spacings } from '../../../../common/styles/base.style';
import { Button, LinkButton } from '../../../../common/components';
import FormikFieldNumberInput from '../../../../common/components/form/FormikFieldNumberInput';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';
import { ADMIN_NEW_ASSOCIATIONS } from '../../../../constants/routes';
import { BUTTON_SIZES } from '../../../../common/components/buttons/Button';
import { adminFindAssociationSearch, adminFindAssociations, resetStatus } from '../../AdminAssociationActions';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pagination: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationWrapper: {
    flexDirection: 'row',
  },
  column: {
    minWidth: 290,
    marginRight: 30,
  },
  page: {
    width: 50,
  },
  resultsWrapper: {
    justifyContent: 'center',
    marginBottom: spacings.sm,
    marginLeft: spacings.lg,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

class AdminFindAssociationFilters extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.windowWidth = windowWidth;
  }

  componentDidMount() {
    this.props.getAvailableMunicipalities();
  }

  componentDidUpdate(prevProps) {
    // Check if isSuccess has changed to true
    if (this.props.isSuccess && !prevProps.isSuccess) {
      // Trigger Formik submit programmatically
      if (this.formik.current) {
        this.formik.current.submitForm();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetStatus();
  }

  doSearch = async (values, { navigate }) => {
    const modifiedValues = { ...values, limit: values.limit.toString(), page: values.page.toString() };
    if (!navigate) {
      modifiedValues.page = '1';
    }

    const { city, limit, page } = modifiedValues;

    if (!city) {
      // eslint-disable-next-line no-alert
      alert('Välj en kommun.');
      return;
    }

    this.props.adminFindAssociationSearch(modifiedValues);
    this.formik.current.setFieldValue('page', modifiedValues.page);

    this.props.adminFindAssociations({
      city,
      limit,
      page,
    });
  };

  getTranslatedList = (nullable, list) => {
    const categories = list.map((c) => ({
      key: c.key,
      value: c.key,
      label: c.name ?? '',
    }));
    if (Platform.OS !== 'ios' && !list.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  changePage = (setFieldValue, values, difference) => {
    const newPage = (Number(values.page) + difference).toString();
    setFieldValue('page', newPage);
    return { ...values, page: newPage };
  };

  render() {
    const { translate, availableMunicipalities } = this.props;

    const t = (key) => translate(`adminFindAssociationScreen.${key}`);

    return (
      <>
        <View style={{ paddingBottom: spacings.xs, paddingTop: spacings.xxxxl }}>
          <Formik validate={false} onSubmit={this.doSearch} initialValues={this.props.formValues} innerRef={this.formik}>
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                <View style={styles.wrapper}>
                  <View style={styles.column}>
                    <FormikFieldPrimaryPicker
                      name="city"
                      items={this.getTranslatedList(true, availableMunicipalities)}
                      label={t('cityLabel')}
                      nullable
                    />
                  </View>
                </View>
                <View style={styles.buttons}>
                  <Button size="md" title={translate('generic.search')} onPress={handleSubmit} />
                  <LinkButton
                    title={translate('adminFindAssociationScreen.newAssociation')}
                    to={ADMIN_NEW_ASSOCIATIONS}
                    size={BUTTON_SIZES.MD}
                  />
                </View>
                {this.props.associationsList && this.props.associationsList.totalPages > 0 && (
                  <View style={styles.pagination}>
                    <View style={styles.paginationWrapper}>
                      <Button
                        type="text"
                        size="md"
                        title={t('previous')}
                        disabled={Number(values.page) === 1}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, -1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginRight: spacings.md } }}
                      />
                      <FormikFieldNumberInput
                        name="page"
                        label={t('page')}
                        rightLabel={`/${this.props.associationsList.totalPages}`}
                        onChange={() => this.doSearch(values, { navigate: true })}
                        style={styles.page}
                      />
                      <Button
                        type="text"
                        size="md"
                        title={translate('generic.next')}
                        disabled={Number(values.page) === this.props.associationsList.totalPages}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, 1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginLeft: spacings.md } }}
                      />
                      <View style={styles.resultsWrapper}>
                        <Text>{`${this.props.associationsList.totalDocs} results`}</Text>
                      </View>
                    </View>
                  </View>
                )}
              </>
            )}
          </Formik>
        </View>
      </>
    );
  }
}

AdminFindAssociationFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  availableMunicipalities: PropTypes.arrayOf(PropTypes.any).isRequired,
  formValues: adminFindAssociationFilterPropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  associationsList: PropTypes.any.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  adminFindAssociationSearch: PropTypes.func.isRequired,
  adminFindAssociations: PropTypes.func.isRequired,
  getAvailableMunicipalities: PropTypes.func.isRequired,
  resetStatus: PropTypes.func.isRequired,
};

AdminFindAssociationFilters.defaultProps = {};

const mapStateToProps = (state) => {
  const formValues = state.admin.get('associations').get('formValues').toJS();

  return {
    associationsList: state.admin.get('associations').get('associationsList'),
    formValues,
    availableCategories: state.gigCategories.toJS(),
    availableMunicipalities: state.gigCities.toJS(),
    isSuccess: state.admin.get('associations').get('isSuccess')
  };
};

export default connect(mapStateToProps, {
  getAvailableMunicipalities,
  adminFindAssociationSearch,
  adminFindAssociations,
  resetStatus
})(withLocalize(AdminFindAssociationFilters));
