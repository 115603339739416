import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../../styles/base.style';

const styles = StyleSheet.create({
  style: {
    backgroundColor: colors.seablue,
    width: 19,
    height: 19,
    borderRadius: 9,
  },
});

function ChatUnreadIndicator() {
  return (
    <View style={styles.style} />
  );
}

export default ChatUnreadIndicator;
