import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LayoutAnimation, Platform, StyleSheet, UIManager,
} from 'react-native';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withLocalize } from 'react-localize-redux';
import { NotificationBox } from './NotificationBox';
import ViewportController from '../components/ViewportController';
import notificationTypes from '../../constants/notificationsTypes';
import { minimizeNotification, removeNotification, reviveNotification } from './notificationCenterActions';
import { PrintingContext } from '../../core/printingContext';

const styles = StyleSheet.create({
  container: {
    zIndex: 9999,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    left: 0,
    top: 0,
    right: 0,
    elevation: 100,
  },
});

class NotificationCenter extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static contextType = PrintingContext;

  minimizeHandles = {};
  removeHandles = {};

  notificationId = undefined;

  customLayoutNewNotification = {
    duration: 200,

    // showing new notification box
    create: {
      type: LayoutAnimation.Types.linear,
      property: LayoutAnimation.Properties.opacity,
    },
    // not sure why delete doesn't work
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line no-unused-expressions
    UIManager.setLayoutAnimationEnabledExperimental
    && UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  componentDidUpdate(prevProps) {
    const prevNotifications = prevProps.notifications;
    const currentNotifications = this.props.notifications;
    // new notification, no old
    if ((prevNotifications.isEmpty() && !currentNotifications.isEmpty())) {
      this.handleNewNotification(currentNotifications.get(0));
    } else if (!prevNotifications.isEmpty() && !currentNotifications.isEmpty()) {
      // new notification, old one disappearing
      const prevFirstNotification = prevNotifications.get(0);
      const currentFirstNotification = currentNotifications.get(0);

      if (prevFirstNotification.get('id') !== currentFirstNotification.get('id')) {
        this.handleNewNotification(currentFirstNotification, prevFirstNotification.get('id'));
      }
    }
  }

  getTimeoutDuration = (notification) => {
    const { translate } = this.props;
    const text = this.getBody(notification, translate);
    const title = this.getTitle(notification, translate);
    const computedDuration = 50 * (title.length + text.length);
    if (computedDuration < 2000) {
      return 2000;
    }
    if (computedDuration > 7000) {
      return 7000;
    }
    return computedDuration;
  };

  clearHandles = (id) => {
    this.clearHandle(this.minimizeHandles[id]);
    this.clearHandle(this.removeHandles[id]);
  };

  clearHandle = (handle) => {
    if (handle) { clearTimeout(handle); }
  };

  handleNewNotification = (notification, oldNotificationId) => {
    LayoutAnimation.configureNext(this.customLayoutNewNotification);
    this.notificationId = notification.get('id');
    if (oldNotificationId) { this.clearHandles(oldNotificationId); }
    if (!notification.get('permanent') && !notification.get('shouldDisappear')) {
      this.scheduleMinimize(notification);
      return false;
    }
    if (notification.get('shouldDisappear')) {
      this.scheduleRemove(notification);
    }
    return false;
  };

  scheduleMinimize = (notification) => {
    const minimizeAction = this.props.minimizeNotification;
    this.minimizeHandles[notification.get('id')] = setTimeout(() => {
      minimizeAction(notification.get('id'));
      this.scheduleRemove(notification);
    }, this.getTimeoutDuration(notification));
  };

  scheduleRemove = (notification) => {
    const removeAction = this.props.removeNotification;
    this.removeHandles[notification.get('id')] = setTimeout(() => {
      removeAction(notification.get('id'));
    }, 2500);
  };

  closeHandler = (id) => {
    this.clearHandles(id);
    this.props.removeNotification(id);
  };

  reviveHandler = (id) => {
    if (this.notificationId === id) {
      this.clearHandles(id);
      this.props.reviveNotification(id);
      this.scheduleMinimize(this.props.notifications.get(0));
    }
  };

  getBody = (notification, translate) => {
    if (notification.get('text')) {
      return notification.get('text');
    }
    if (notification.get('textKey')) {
      return translate(notification.get('textKey'));
    }
    return '';
  };

  getTitle = (notification, translate) => {
    if (notification.get('title')) {
      return notification.get('title');
    }
    return translate(notification.get('titleKey'));
  };

  render() {
    const { notifications, translate } = this.props;
    if (notifications.isEmpty()) {
      return null;
    }

    const notification = notifications.get(0);
    const { isPrint } = this.context;
    return !isPrint && (
      <ViewportController style={styles.container} safeAreaTop>
        <NotificationBox
          id={notification.get('id')}
          type={notification.get('type')}
          title={this.getTitle(notification, translate)}
          text={this.getBody(notification, translate)}
          minimized={notification.get('minimized')}
          closeHandler={this.closeHandler}
          reviveHandler={this.reviveHandler}
        />
      </ViewportController>
    );
  }
}

NotificationCenter.propTypes = {
  translate: PropTypes.func.isRequired,
  minimizeNotification: PropTypes.func.isRequired,
  reviveNotification: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  notifications: ImmutablePropTypes.listOf(ImmutablePropTypes.contains({
    titleKey: PropTypes.string,
    title: PropTypes.string,
    textKey: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(Object.values(notificationTypes)).isRequired,
    minimized: PropTypes.bool,
    shouldDisappear: PropTypes.bool.isRequired,
  })).isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notificationCenter.get('notifications'),
});
export default connect(mapStateToProps, { minimizeNotification, removeNotification, reviveNotification })(withLocalize(NotificationCenter));
