import {
  CANDIDATE_POOLS_GET_SHIFT_REQUEST,
  CANDIDATE_POOLS_BOOK_SHIFT_REQUEST,
  CANDIDATE_POOLS_GET_AVAILABLE_SHIFTS_REQUEST,
} from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics.web';

export function getShifts(params) {
  return {
    type: CANDIDATE_POOLS_GET_AVAILABLE_SHIFTS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/shift',
        params: { ...params, sortBy: 'startDate' },
      },
    },
  };
}

export function getShift(gigId) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.SHIFT.VIEW_DETAILS);
  return {
    type: CANDIDATE_POOLS_GET_SHIFT_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `candidate/shift/${gigId}`,
      },
    },
  };
}

export function bookShift(gigId) {
  googleAnalytics.trackCandidateEvent(gigId, ACTIONS.CANDIDATE.SHIFT.CANDIDATE_CLICK_BOOK);
  return {
    type: CANDIDATE_POOLS_BOOK_SHIFT_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `candidate/shift/${gigId}/book`,
      },
    },
  };
}
