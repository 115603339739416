import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ActivityIndicator } from 'react-native';

import { getGroup, deleteGroup } from '../candidatesPoolGroupsActions';
import CompanyCandidatesPoolGroupViewScreen from './CompanyCandidatesPoolGroupViewScreen';
import Routing from '../../../../core/routing/index';
import { redirectToErrorScreen } from '../../../../constants/routes';
import { activityIndicator } from '../../../../common/styles/base.style';
import Container from '../../../../common/components/Container';

const { withRouter } = Routing;

class CompanyCandidatesPoolGroupEditableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.getGroup(this.props.match.params.groupId).then((res) => {
      if (!res.isError) {
        this.setState({ poolGroup: res.payload });
      } else if (res && res.status === 404) {
        redirectToErrorScreen(this.props.history, 'errors.notFoundTitle', 'errors.notFoundText');
      }
    });
  }

  onDelete = () => {
    this.props.deleteGroup(this.props.match.params.groupId).then(() => {
      this.props.history.goBack();
    });
  };

  render() {
    if (!this.state.poolGroup) {
      return (
        <Container>
          <ActivityIndicator size="large" style={{ ...activityIndicator }} />
        </Container>
      );
    }
    return (
      <CompanyCandidatesPoolGroupViewScreen
        poolGroup={this.state.poolGroup}
        deleteAction={this.onDelete}
      />
    );
  }
}

CompanyCandidatesPoolGroupEditableContainer.propTypes = {
  getGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

CompanyCandidatesPoolGroupEditableContainer.defaultProps = {
};

export default connect(null, {
  getGroup, deleteGroup,
})(withRouter(CompanyCandidatesPoolGroupEditableContainer));
