import { ACTIONS as GOOGLE_ANALYTICS_ACTIONS } from './googleAnalyticsActions';

const googleAnalytics = {
  trackPageHit: () => {},
  trackGigEvent: () => {},
  trackCandidateEvent: () => {},
  trackCompanyEvent: () => {},
  trackUserEvent: () => {},
  trackErrorEvent: () => {},
};

export default googleAnalytics;
export const ACTIONS = GOOGLE_ANALYTICS_ACTIONS;
