import { PureComponent } from 'react';
import { AppState } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { onAppStateChange } from './appStateActions';

const APP_STATE_CHANGE_EVENT = 'change';

export const APP_STATE_ACTIVE = 'active';
export const APP_STATE_INACTIVE = 'inactive';
export const APP_STATE_BACKGROUND = 'background';

class AppStateWatcher extends PureComponent {
  constructor(props) {
    super(props);
    this.props.onAppStateChange(AppState.currentState);
  }

  componentDidMount() {
    AppState.addEventListener(APP_STATE_CHANGE_EVENT, this.handleAppStateChange);
  }

  componentWillUnmount() {
    AppState.removeEventListener(APP_STATE_CHANGE_EVENT, this.handleAppStateChange);
  }

  handleAppStateChange = (newAppState) => {
    this.props.onAppStateChange(newAppState);
  };

  render() {
    return null;
  }
}

AppStateWatcher.propTypes = {
  onAppStateChange: PropTypes.func.isRequired,
};

export default connect(null, { onAppStateChange })(AppStateWatcher);
