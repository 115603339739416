import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Platform } from 'react-native';
import Routing from '../../../core/routing/index';
import { colors, shadowTop } from '../../styles/base.style';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  menu: {
    display: 'flex',
    height: 65,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'baseline',
    backgroundColor: colors.white,
    ...shadowTop,
    shadowOpacity: 0.08,
  },
});

const NavigationBar = ({ children, location, history }) => {
  const childrenWithExtraProp = React.Children.map(children, (child) => child && React.cloneElement(child, { location, history }));

  return (
    <View style={Platform.OS === 'web' ? null : styles.menu}>
      { childrenWithExtraProp }
    </View>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathName: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

NavigationBar.defaultProps = {
  location: null,
  history: null,
};

export default withRouter(NavigationBar);
