import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import SectionHeader from '../../components/header/SectionHeader';
import Wrapper from '../../components/Wrapper';
import DisplayField from '../../components/DisplayField';
import HorizontalWrapper from '../../components/HorizontalWrapper';
import { toDate } from '../../../utilities/dateFormatter';
import { spacings } from '../../styles/base.style';
import PrimaryText from '../../components/PrimaryText';

class GigOfferView extends PureComponent {
  render() {
    const { offer, translate, translationKey } = this.props;
    const t = (key, args) => translate(`${translationKey}.${key}`, args);

    return (
      <>
        <SectionHeader title={t('generalInfoSubheader')} />
        <Wrapper noFlex>

          <DisplayField label={t('positionLabel')} value={offer.position} />
          <HorizontalWrapper>
            <DisplayField label={t('startDateLabel')} value={toDate(offer.startDate)} />
            <DisplayField style={{ marginLeft: spacings.md }} label={t('endDateLabel')} value={offer.endDate ? toDate(offer.endDate) : '-'} />
          </HorizontalWrapper>
        </Wrapper>
        <SectionHeader title={t('salary.salarySubheader')} />
        <Wrapper noFlex>
          <DisplayField label={t('salary.salaryPer', { per: translate(`gigSalary.per.${offer.compensation.salaryPer}`) })} value={offer.compensation.salary} />
          {offer.compensation.labourContract && <PrimaryText>{t('salary.labourContract')}</PrimaryText>}
          {offer.compensation.salaryByAgreement && <PrimaryText>{t('salary.salaryByAgreement')}</PrimaryText>}
          {offer.compensation.volunteering && <PrimaryText>{t('salary.volunteering')}</PrimaryText>}
          {offer.compensation.internshipTrainee && <PrimaryText>{t('salary.internshipTrainee')}</PrimaryText>}
        </Wrapper>

        <SectionHeader title={t('availabilitySubheader')} />
        <Wrapper noFlex>
          <DisplayField label={t('workloadLabel')} value={offer.workload} />
          {!offer.noShift && <DisplayField label={t('shiftLabel')} value={offer.shift} />}
        </Wrapper>

        <SectionHeader title={t('locationSubheader')} />
        <Wrapper noFlex>
          <DisplayField label={t('cityLabel')} value={offer.location.cityV2.name} />
          <DisplayField label={t('streetLabel')} value={offer.location.street} />
          {offer.remoteWorkAllowed && <PrimaryText>{t('remoteWorkAllowedLabel')}</PrimaryText>}
        </Wrapper>

        {offer.additionalInfo
          && (
            <>
              <SectionHeader title={t('otherSubheader')} />
              <Wrapper noFlex>
                <PrimaryText>
                  {' '}
                  {offer.additionalInfo}
                </PrimaryText>
              </Wrapper>
            </>
          )}
      </>
    );
  }
}

GigOfferView.propTypes = {
  translate: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    position: PropTypes.string.isRequired,
    startDate: PropTypes.number.isRequired,
    endDate: PropTypes.number,
    compensation: PropTypes.shape({
      salary: PropTypes.number.isRequired,
      salaryPer: PropTypes.string.isRequired,
      labourContract: PropTypes.bool,
      salaryByAgreement: PropTypes.bool,
      volunteering: PropTypes.bool,
      internshipTrainee: PropTypes.bool,
    }).isRequired,
    workload: PropTypes.string.isRequired,
    shift: PropTypes.string,
    noShift: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      cityV2: PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
      street: PropTypes.string,
    }).isRequired,
    remoteWorkAllowed: PropTypes.bool.isRequired,
    additionalInfo: PropTypes.string,
  }).isRequired,
};

export default withLocalize(GigOfferView);
