export const NOT_STARTED = 'NOT_STARTED';
export const NOT_COMPLETED = 'NOT_COMPLETED';
export const REQUIRED_COMPLETED = 'REQUIRED_COMPLETED';
export const COMPLETED = 'COMPLETED';

export function isCompletedOrRequiredCompleted(status) {
  return [REQUIRED_COMPLETED, COMPLETED].includes(status);
}

export function isProfileNotReady(profile) {
  return !profile || !isCompletedOrRequiredCompleted(profile.profileCompleteness);
}
