import React, { Component } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { Row, Rows, Table } from 'react-native-table-component';
import PropTypes from 'prop-types';
import {
  spacings, standardColors,
} from '../../common/styles/base.style';
import Routing from '../../core/routing/index';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },

  container: {
    flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff',
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});

class AdminFindCandidatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHead: ['Name', 'Surname', 'Email', 'Categories', 'City', 'Available', 'Association'],
      flexArr: [2, 2, 2, 2, 2, 1, 3],
    };
  }

  render() {
    const { candidatesList } = this.props;
    const itemsData = candidatesList.map((c) => [
      c.firstName,
      c.lastName,
      c.email,
      c.categories ? c.categories.join(',') : '',
      c.cityName,
      c.available ? 'yes' : 'no',
      c.association
    ]);
    const { state } = this;
    return (
      <>
        <View style={styles.container}>
          <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
            <Row data={state.tableHead} style={styles.head} textStyle={styles.text} flexArr={state.flexArr} />
            <Rows data={itemsData} textStyle={styles.text} flexArr={state.flexArr} />
          </Table>
        </View>
      </>

    );
  }
}

AdminFindCandidatesList.propTypes = {
  candidatesList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  candidatesList: state.admin.get('candidates').get('adminCandidatesList').toJS(),
});

export default withRouter(connect(mapStateToProps, {
})(withLocalize(AdminFindCandidatesList)));
