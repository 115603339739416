import React from 'react';
import {
  ActivityIndicator, Image, Platform, StyleSheet, View,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

import { Button } from '../../../common/components';
import {
  activityIndicator, colors, wp, widthSpacingsBolek, heightSpacingsBolek, modernColors,
} from '../../../common/styles/base.style';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import SectionHeaderBolek from '../../../common/components/header/SectionHeaderBolek';
import ViewportController from '../../../common/components/ViewportController';
import AutoScrollableKeyboardAwareScrollView from '../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import validation, { MAX_DESCRIPTION_LENGTH, MAX_CITY_LENGTH, MAX_STREET_LENGTH } from './CompanyCandidatesPoolProfileEditValidation';
import KeyboardAwareFlexWrapper from '../../../common/components/KeyboardAwareFlexWrapper';
import FormikFieldPrimaryInputTextNextSupport from '../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import FormikWithValidation from '../../../common/components/form/FormikWithValidation';
import FormikFieldPrimaryTextInput from '../../../common/components/form/FormikFieldPrimaryTextInput';
import { poolProfilePropType } from '../../../constants/propTypes';
import ImageSelectorComponent from '../../../common/components/image/ImageSelectorComponent';
import { showInformationAlert } from '../../../common/components/alert/alerts';
import HighlightedSection from '../../../common/components/HighlightedSection';
import PrimaryTextBolek, { TYPES } from '../../../common/components/PrimaryTextBolek';
import { profileImagePercentageAspect } from '../../../common/shift/CandidatesPoolDetailsScreen';

const camera = require('../../../assets/icons/camera/camera.png');

const PHOTO_URI_CONFIG = {
  TARGET_ASPECT: [100, profileImagePercentageAspect],
  TARGET_WIDTH: 3000,
  SPINNER_COLOR: colors.magenta,
};

const styles = StyleSheet.create({
  takePictureWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: wp(profileImagePercentageAspect),
  },
  takePictureIcon: {
    width: widthSpacingsBolek.p80,
    alignSelf: 'center',
    marginBottom: heightSpacingsBolek.p15,
  },
  takePictureText: {
    color: modernColors.grey,
  },
  iconWrapper: {
  },
  profileImage: {
    width: wp(100),
    height: wp(profileImagePercentageAspect),
  },
  photoWrapper: {
    width: '100%',
  },
});

class CompanyCandidatesPoolProfileEditScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  validateImage = (result) => {
    const { width, height } = result;
    if (width >= height) {
      return true;
    }

    showInformationAlert(this.props.translate, {
      confirmKey: 'generic.ok',
      titleKey: 'company.candidatesPool.profile.image.alert.title',
      textKey: 'company.candidatesPool.profile.image.alert.text',
    });
    return false;
  };

  render() {
    const {
      translate, profile, photoUri, handleSubmit, uploadProfilePhoto, onPhotoUploadComplete,
    } = this.props;
    const translateByKey = (key) => translate(`company.candidatesPool.profile.${key}`);

    if (!profile) {
      return (<ActivityIndicator size="large" style={{ ...activityIndicator, backgroundColor: colors.smokeWhite }} />);
    }

    return (
      <ViewportController safeAreaBottom>
        <ScreenHeader
          title={translateByKey('header')}
          showBackArrow
        />
        <AutoScrollableKeyboardAwareScrollView>
          <HighlightedSection noSpacings>
            {!photoUri && Platform.OS !== 'web' && (
              <ImageSelectorComponent
                style={{ wrapper: styles.iconWrapper }}
                uri={photoUri}
                showEditIcon={false}
                uploadImageAction={uploadProfilePhoto}
                uploadHandler={onPhotoUploadComplete}
                imageValidator={this.validateImage}
                resizedWidth={PHOTO_URI_CONFIG.TARGET_WIDTH}
                spinnerColor={PHOTO_URI_CONFIG.SPINNER_COLOR}
                aspect={PHOTO_URI_CONFIG.TARGET_ASPECT}
                actionSheetMessage={translateByKey('image.actionSheetMessage')}
              >
                <View style={styles.takePictureWrapper}>
                  <Image source={camera} style={styles.takePictureIcon} fadeDuration={0} resizeMode="contain" />
                  <PrimaryTextBolek type={TYPES.SECONDARY} style={styles.takePictureText}>{translateByKey('image.description')}</PrimaryTextBolek>
                </View>
              </ImageSelectorComponent>
            )}
            {photoUri && (
              <ImageSelectorComponent
                style={{ wrapper: styles.photoWrapper, image: styles.profileImage }}
                uri={photoUri}
                defaultImage={camera}
                uploadImageAction={uploadProfilePhoto}
                uploadHandler={onPhotoUploadComplete}
                imageValidator={this.validateImage}
                resizedWidth={PHOTO_URI_CONFIG.TARGET_WIDTH}
                spinnerColor={PHOTO_URI_CONFIG.SPINNER_COLOR}
                aspect={PHOTO_URI_CONFIG.TARGET_ASPECT}
                actionSheetMessage={translateByKey('image.actionSheetMessage')}
              />
            )}
          </HighlightedSection>
          <FormikWithValidation
            enableReinitialize
            validation={validation}
            initialValues={profile}
            onSubmit={handleSubmit}
            render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
              <>
                <KeyboardAwareFlexWrapper>
                  <SectionHeaderBolek
                    title={translateByKey('descriptionHeader')}
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    name="description"
                    placeholder={translateByKey('descriptionPlaceholder')}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    numberOfLines={Platform.OS === 'web' ? 20 : 1}
                    counter
                    autoscroll
                    multiline
                    scrollEnabled={false}
                    nextComponent={this.state.streetInputRef}
                  />
                  <SectionHeaderBolek
                    title={translateByKey('contactInfoHeader')}
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    innerRef={(ref) => !this.state.streetInputRef && this.setState({ streetInputRef: ref })}
                    name="contactInfo.street"
                    placeholder={translateByKey('streetPlaceholder')}
                    maxLength={MAX_STREET_LENGTH}
                    autoscroll
                    nextComponent={this.state.cityInputRef}
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    innerRef={(ref) => !this.state.cityInputRef && this.setState({ cityInputRef: ref })}
                    name="contactInfo.city"
                    placeholder={translateByKey('cityPlaceholder')}
                    maxLength={MAX_CITY_LENGTH}
                    autoscroll
                    nextComponent={this.state.phoneNumberInputRef}
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    innerRef={(ref) => !this.state.phoneNumberInputRef && this.setState({ phoneNumberInputRef: ref })}
                    name="contactInfo.phoneNumber"
                    placeholder={translateByKey('phoneNumberPlaceholder')}
                    maxLength={MAX_CITY_LENGTH}
                    autoscroll
                    nextComponent={this.state.emailInputRef}
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    innerRef={(ref) => !this.state.emailInputRef && this.setState({ emailInputRef: ref })}
                    name="contactInfo.email"
                    placeholder={translateByKey('emailPlaceholder')}
                    maxLength={MAX_CITY_LENGTH}
                    nextComponent={this.state.websiteInputRef}
                    autoscroll
                  />
                  <FormikFieldPrimaryTextInput
                    innerRef={(ref) => !this.state.websiteInputRef && this.setState({ websiteInputRef: ref })}
                    name="contactInfo.website"
                    placeholder={translateByKey('websitePlaceholder')}
                    maxLength={MAX_CITY_LENGTH}
                    autoscroll
                  />
                </KeyboardAwareFlexWrapper>
                <Button
                  title={translate('generic.save')}
                  disabled={isBtnDisabled}
                  onPress={handleSubmitFormik}
                />
              </>
            )}
          />
        </AutoScrollableKeyboardAwareScrollView>
      </ViewportController>
    );
  }
}

CompanyCandidatesPoolProfileEditScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  profile: poolProfilePropType,
  uploadProfilePhoto: PropTypes.func.isRequired,
  onPhotoUploadComplete: PropTypes.func.isRequired,
  photoUri: PropTypes.string,
};

CompanyCandidatesPoolProfileEditScreen.defaultProps = {
  profile: null,
  photoUri: null,
};

export default withLocalize(CompanyCandidatesPoolProfileEditScreen);
