import {
  COMPANY_CANDIDATES_POOL_CREATE_GROUP_REQUEST,
  COMPANY_CANDIDATES_POOL_RENAME_GROUP_REQUEST,
  COMPANY_CANDIDATES_POOL_GET_GROUPS_REQUEST,
  COMPANY_CANDIDATES_POOL_GET_GROUP_REQUEST,
  COMPANY_CANDIDATES_POOL_DELETE_GROUP_REQUEST,
} from '../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';

export function getAvailableGroups({ privateOnly }) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.VIEW_GROUPS);
  return {
    type: COMPANY_CANDIDATES_POOL_GET_GROUPS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `company/candidatesPool/group?privateOnly=${!!privateOnly}`,
      },
    },
  };
}

export function getGroup(groupId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.VIEW_GROUP);
  return {
    type: COMPANY_CANDIDATES_POOL_GET_GROUP_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `company/candidatesPool/group/${groupId}`,
      },
    },
  };
}

export function deleteGroup(groupId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.DELETE_GROUP);
  return {
    type: COMPANY_CANDIDATES_POOL_DELETE_GROUP_REQUEST,
    payload: {
      request: {
        method: 'delete',
        url: `company/candidatesPool/group/${groupId}`,
      },
    },
  };
}

export function createGroup(name, description, candidates) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.CREATE_GROUP);
  return {
    type: COMPANY_CANDIDATES_POOL_CREATE_GROUP_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'company/candidatesPool/group',
        data: { name, description, candidates },
      },
    },
  };
}

export function modifyGroup(groupId, { name, description, candidates }) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.MODIFY_GROUP);
  return {
    type: COMPANY_CANDIDATES_POOL_RENAME_GROUP_REQUEST,
    payload: {
      request: {
        method: 'put',
        url: `company/candidatesPool/group/${groupId}`,
        data: { name, description, candidates },
      },
    },
  };
}
