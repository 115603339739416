import validator from 'validator';

export const MIN_COMPENSATION = 0;

export default [
  {
    field: 'min',
    custom: (value) => value === null || value === '' || validator.isInt(value),
    message: 'wrong_format',
  },
  {
    field: 'min',
    custom: (value, args, data) => {
      if (data.volunteering || data.internshipTrainee || data.salaryByAgreement) {
        return true;
      }
      return value !== null && value !== '';
    },
    message: 'null_value',
  },
  {
    field: 'min',
    custom: (value, args, data) => {
      if (data.salaryByAgreement) {
        return true;
      }
      return value >= MIN_COMPENSATION;
    },
    message: 'too_small',
  },
  {
    field: 'max',
    custom: (value, args, data) => {
      if (!data.min || !value) {
        return true;
      }
      return Number(value) >= Number(data.min);
    },
    message: 'too_small',
  },
  {
    field: 'internshipTrainee',
    custom: (value, args, data) => (data.internshipTrainee ? !data.volunteering : true),
    message: 'only_one',
  },
  {
    field: 'volunteering',
    custom: (value, args, data) => (data.volunteering ? !data.internshipTrainee : true),
    message: 'only_one',
  },
];
