import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import PropTypes from 'prop-types';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'image-xxs': {
    width: 32,
    height: 32,
  },
  'image-xs': {
    width: 40,
    height: 40,
  },
  'image-sm': {
    width: 64,
    height: 64,
  },
  'image-md': {
    width: 96,
    height: 96,
  },
  'image-lg': {
    width: 128,
    height: 128,
  },
});

const ImageComponent = ({
  image, size, style,
}) => (
  <View style={[styles.imageContainer, style.wrapper]}>
    <Image source={image} style={[styles[`image-${size}`], style.image]} fadeDuration={0} />
  </View>
);

ImageComponent.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      uri: PropTypes.string,
    }),
  ]).isRequired,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  style: PropTypes.shape({
    wrapper: stylePropTypes,
    image: stylePropTypes,
  }),
};
ImageComponent.defaultProps = {
  style: {
    wrapper: null,
    image: null,
  },
  size: 'md',
};

export default ImageComponent;
