import { Map } from 'immutable';
import { LOCALE_SET } from '../../constants/actionTypes';

export default function localeReducer(state = Map({ locale: null }), action) {
  switch (action.type) {
    case LOCALE_SET:
      return state.set('locale', action.locale);
    default:
      return state;
  }
}
