import { View, StyleSheet } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { colors, spacings } from '../styles/base.style';

const styles = StyleSheet.create({
  hr: {
    borderBottomColor: colors.veryLightGrey,
    marginBottom: spacings.md,
  },
});

class Hr extends React.PureComponent {
  render() {
    const { style } = this.props;
    return <View style={[styles.hr, style, { borderBottomWidth: this.props.height }]} />;
  }
}

Hr.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  height: PropTypes.number,
};

Hr.defaultProps = {
  style: null,
  height: 1,
};

export default Hr;
