import {
  PROFILE_CREATOR_ADD_CATEGORIES,
  PROFILE_CREATOR_ADD_AVAILABILITY,
  PROFILE_CREATOR_SAVE_PROFILE_REQUEST,
  PROFILE_CREATOR_ADD_EXPERIENCE,
  PROFILE_CREATOR_CLEAR_ALL_DATA,
  PROFILE_CREATOR_ADD_EDUCATION,
  PROFILE_CREATOR_COPY_DATA_FROM_PROFILE,
  PROFILE_CREATOR_ADD_CURRENT_OCCUPATION,
  PROFILE_CREATOR_ADD_LANGUAGES,
  PROFILE_CREATOR_ADD_GIG_LOCATION,
  PROFILE_CREATOR_ADD_HOME_LOCATION,
} from '../../../constants/actionTypes';
import { transformRequestTransforms } from '../../../core/client/axiosClientConfig';
import { clearToDateIfOngoing } from '../profileHelper';

// eslint-disable-next-line import/prefer-default-export
export function profileCreatorAddCategories(categories) {
  return {
    type: PROFILE_CREATOR_ADD_CATEGORIES,
    categories,
  };
}

export function profileCreatorAddAvailability(availability) {
  return {
    type: PROFILE_CREATOR_ADD_AVAILABILITY,
    availability,
  };
}

export function profileCreatorAddGigLocation(location) {
  return {
    type: PROFILE_CREATOR_ADD_GIG_LOCATION,
    location,
  };
}

export function profileCreatorAddHomeLocation(homeLocation) {
  return {
    type: PROFILE_CREATOR_ADD_HOME_LOCATION,
    homeLocation,
  };
}

export function profileCreatorAddExperience(experience) {
  return {
    type: PROFILE_CREATOR_ADD_EXPERIENCE,
    experience,
  };
}

export function profileCreatorAddEducation(education) {
  return {
    type: PROFILE_CREATOR_ADD_EDUCATION,
    education,
  };
}

export function profileCreatorAddCurrentOccupation(currentOccupation) {
  return {
    type: PROFILE_CREATOR_ADD_CURRENT_OCCUPATION,
    currentOccupation,
  };
}

export function profileCreatorAddLanguages(languages) {
  return {
    type: PROFILE_CREATOR_ADD_LANGUAGES,
    languages,
  };
}

export function profileCreatorClearAllData() {
  return {
    type: PROFILE_CREATOR_CLEAR_ALL_DATA,
  };
}

export function profileCreatorCopyDataFromProfile(profile) {
  return {
    type: PROFILE_CREATOR_COPY_DATA_FROM_PROFILE,
    profile,
  };
}

export function profileCreatorSaveProfile(profileData) {
  return {
    type: PROFILE_CREATOR_SAVE_PROFILE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/candidate/profile',
        data: profileData,
        transformRequest: [function transformExpAndEdu(data) {
          const transformedData = data;
          transformedData.experience = data.experience.map((e) => clearToDateIfOngoing(e));
          transformedData.education = data.education.map((e) => clearToDateIfOngoing(e));
          return transformedData;
        }].concat(transformRequestTransforms),
      },
    },
  };
}
