import React from 'react';
import {
  View, StyleSheet, Dimensions, ScrollView, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import { colors, spacings } from '../styles/base.style';
import { imagePropType, translationsPropType } from '../../constants/propTypes';
import ViewportController from '../components/ViewportController';
import googleAnalytics, { ACTIONS } from '../analytics/googleAnalytics.web';

const styles = StyleSheet.create({
  indicators: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    left: 0,
    right: 0,
    bottom: 30,
    height: 17,
  },
  indicator: {
    borderColor: colors.magenta,
    borderWidth: 2,
    borderRadius: 10,
    width: 17,
    marginHorizontal: spacings.xs,
  },
  indicatorSelected: {
    backgroundColor: colors.magenta,
  },
});

const SCREEN_WIDTH = Dimensions.get('window').width;

class WelcomeCarouselScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollView: undefined,
      selectedScreen: 1,
      screens: props.screens,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedScreen !== this.state.selectedScreen) {
      googleAnalytics.trackUserEvent(ACTIONS.USER.WELCOME_CAROUSEL_SHOW_MORE, `Screen ${this.state.selectedScreen}`);
    }
  }

  setReference = (ref) => {
    if (!this.state.scrollView) {
      this.setState({ scrollView: ref });
      this.scrollView = ref;
    }
  };

  onScroll = (event) => {
    const scrollPosition = event.nativeEvent.contentOffset.x;
    this.setState({ selectedScreen: Math.round(scrollPosition / SCREEN_WIDTH) + 1 });
  };

  scrollTo(screenNumber) {
    const { scrollView, screens } = this.state;
    if (screenNumber > screens.length) {
      this.props.onQuit();
    } else if (scrollView) {
      scrollView.scrollTo({ x: (screenNumber - 1) * SCREEN_WIDTH, animated: true });
    }
  }

  renderIndicators = () => {
    const { selectedScreen, screens } = this.state;
    return (
      <View style={styles.indicators}>
        {screens.map((item, index) => (
          <TouchableOpacity
            key={index + 1}
            style={[styles.indicator, selectedScreen === index + 1 && styles.indicatorSelected]}
            onPress={() => this.scrollTo(+index + 1)}
          />
        ))}
      </View>
    );
  };

  render() {
    const children = React.Children.map(this.props.children, (child) => React.cloneElement(child, {
      scrollTo: (screenNumber) => this.scrollTo(screenNumber),
    }));

    return (
      <ViewportController safeAreaBottom statusBarHidden>
        <ScrollView
          ref={this.setReference}
          pagingEnabled
          horizontal
          scrollEventThrottle={40}
          onScroll={this.onScroll}
          showsHorizontalScrollIndicator={false}
        >
          {children}
        </ScrollView>
        {this.renderIndicators()}
      </ViewportController>
    );
  }
}

WelcomeCarouselScreen.propTypes = {
  screens: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: imagePropType.isRequired,
    header: translationsPropType.isRequired,
    text: translationsPropType.isRequired,
    buttonLabel: translationsPropType.isRequired,
    skipLabel: translationsPropType,
  })).isRequired,
  onQuit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withLocalize(WelcomeCarouselScreen);
