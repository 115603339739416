import React, { useEffect } from 'react';
import { Platform } from 'react-native';

const PaymentDeepLink = () => {
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;
    const myDomain = process.env.NODE_ENV === 'production' ? 'web.letsgig.com' : 'web-stage.letsgig.com';
    const deepLinkUrl = `letsgig://company/gigs/${params.get('gigId')}/matching/candidates/offers${currentUrl.search}`;
    const webUrl = `https://${myDomain}/company/gigs/${params.get('gigId')}/matching/candidates/offers${currentUrl.search}`;

    const redirectUrl = Platform.OS === 'web' && !/Mobi|Android/i.test(navigator.userAgent) ? webUrl : deepLinkUrl;

    window.location.replace(redirectUrl);
  }, []);

  return <></>;
};

export default PaymentDeepLink;
