import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { spacings } from '../styles/base.style';
import Header from '../components/header/Header';
import PrimaryText from '../components/PrimaryText';

const styles = StyleSheet.create({
  container: { marginTop: spacings.xxxl },
  text: {
    textAlign: 'center',
    marginTop: spacings.md,
  },
});

const ChatUnavailableMessage = ({ translate }) => (
  <View style={styles.container}>
    <Header>{translate('errors.chatUnavailableTitle')}</Header>
    <PrimaryText style={styles.text}>{translate('errors.chatUnavailableText')}</PrimaryText>
  </View>
);

ChatUnavailableMessage.propTypes = { translate: PropTypes.func.isRequired };

export default withLocalize(ChatUnavailableMessage);
