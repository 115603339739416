import React from 'react';
import {
  Image,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';
import Touchable from '../../components/Touchable';
import GigDetailsImage from '../GigDetailsImage';
import { gigPropType } from '../../../constants/propTypes';
import { font } from '../../styles/mixins';
import {
  fontFamily,
  fontSizes,
  colors,
  shadow,
  spacings,
} from '../../styles/base.style';

const locationIcon = require('../../../assets/icons/location/locationIcon.png');

const styles = StyleSheet.create({
  jobOfferContainer: {
    width: '46%',
  },
  jobOffer: {
    width: '100%',
    borderRadius: 7,
  },
  imageWrapper: {
    overflow: 'hidden',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  gigImage: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    height: 80,
  },
  container: {
    width: '100%',
    paddingHorizontal: spacings.xs,
    marginVertical: spacings.xs,
  },
  position: {
    width: '100%',
    marginTop: spacings.xs,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
    height: 37,
    lineHeight: 15,
  },
  companyName: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, null, colors.lightPeach),
    height: 32,
  },
  locationContainer: {
    width: '100%',
    flexDirection: 'row',
    marginTop: spacings.xs,
    alignItems: 'center',
  },
  location: {
    flex: 1,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, null, colors.lightPeach),
    maxHeight: 18,
  },
  locationIcon: {
    width: 8,
    height: 11,
    marginRight: spacings.xxs,
  },
  publishDate: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, null, colors.brownishGrey),
    marginTop: spacings.xxs,
    textAlign: 'right',
  },
});

const SimilarGig = (props) => {
  const { gig, onPress } = props;

  return (
    <View style={styles.jobOfferContainer}>
      <Touchable
        style={[styles.jobOffer, shadow]}
        activeOpacity={0.7}
        onPress={() => (onPress ? onPress(gig) : null)}
      >
        <View style={styles.imageWrapper}>
          <GigDetailsImage gig={gig} gigImageStyles={styles.gigImage} />
        </View>
        <View style={styles.container}>
          <Text style={styles.position} ellipsizeMode="tail" numberOfLines={2}>{gig.position}</Text>
          <Text style={styles.companyName} ellipsizeMode="tail" numberOfLines={2}>{gig.companyName}</Text>
          <View style={styles.locationContainer}>
            <Image style={styles.locationIcon} source={locationIcon} />
            <Text style={styles.location} ellipsizeMode="tail" numberOfLines={2}>
              {gig.location.city}
              {gig.location.street ? `, ${gig.location.street}` : ''}
            </Text>
          </View>
          <Text style={styles.publishDate}>{moment(gig.publishDate).from(moment())}</Text>
        </View>
      </Touchable>
    </View>
  );
};

SimilarGig.propTypes = {
  gig: gigPropType.isRequired,
  onPress: PropTypes.func,
};

SimilarGig.defaultProps = {
  onPress: null,
};

export default SimilarGig;
