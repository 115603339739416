import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import CandidateProfileSettingsScreen from './CandidateProfileSettingsScreen';
import { logoutUser } from '../../../common/auth/authActions';
import { deleteAccount } from '../../../common/user/userActions';
import Routing from '../../../core/routing/index';
import { getInstallationId } from '../../../common/auth/deviceDetailsUtil';
import { FIRST_CHECK_USER_STATUS } from '../../../constants/routes';

const { withRouter } = Routing;

class CandidateProfileSettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logsModalVisible: false };
  }

  onLogout = () => {
    this.props.logoutUser(getInstallationId());
  };

  onDelete = () => {
    this.props.deleteAccount();
  };

  goBack = () => {
    if (queryString.parse(this.props.location.search).notificationSettings) {
      this.props.history.replace(FIRST_CHECK_USER_STATUS);
      return;
    }
    this.props.history.goBack();
  };

  onOpenLogsModal = () => {
    this.setState({
      logsModalVisible: true,
    });
  };

  onCloseLogsModal = () => {
    this.setState({ logsModalVisible: false });
  };

  render() {
    return (
      <CandidateProfileSettingsScreen
        onLogout={this.onLogout}
        onDelete={this.onDelete}
        handleBack={this.goBack}
        onOpenLogsModal={this.onOpenLogsModal}
        onCloseLogsModal={this.onCloseLogsModal}
        logsModalVisible={this.state.logsModalVisible}
      />
    );
  }
}

CandidateProfileSettingsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  logoutUser: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { logoutUser, deleteAccount })(CandidateProfileSettingsContainer));
