import React from 'react';
import PropTypes from 'prop-types';
import validation from './CandidateProfileCategoryFormValidation';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCategorySelectListContainer from '../../../../common/gig/GigCategorySelectListContainer';
import { candidateCategoryValidationParams } from '../../../../constants/propTypes';

const CandidateProfileCategoryForm = ({
  renderFooter, selectedCategories, handleSubmit, candidateCategoriesValidationParams,
}) => (
  <FormikWithValidation
    validation={validation(candidateCategoriesValidationParams)}
    initialValues={{ categories: selectedCategories }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <GigCategorySelectListContainer
          name="general"
          maxSelect={(candidateCategoriesValidationParams && candidateCategoriesValidationParams.max) ? candidateCategoriesValidationParams.max : undefined}
        />
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfileCategoryForm.propTypes = {
  candidateCategoriesValidationParams: candidateCategoryValidationParams.isRequired,
  renderFooter: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CandidateProfileCategoryForm;
