import {
  StyleSheet, View, Text, ScrollView,
} from 'react-native';
import React from 'react';
import moment from 'moment';
import debounce from 'debounce';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import FormikFieldCompensationInput from '../../../../common/components/form/FormikFieldCompensationInput';
import FormikFieldDateTimePicker from '../../../../common/components/form/FormikFieldDateTimePicker';
import { obPaymentPeriodPropType, shiftSalaryPropType } from '../../../../constants/propTypes';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import validation, { MINIMUM_SALARY } from './CompanyShiftsSalaryStepEditableValidation';
import FormikFieldCheckBox from '../../../../common/components/form/checkbox/FormikFieldCheckBox';
import CheckBoxBolek from '../../../../common/components/form/checkbox/CheckBoxBolek';
import Button, { BUTTON_SIZES } from '../../../../common/components/buttons/Button';
import ViewportController from '../../../../common/components/ViewportController';
import ScreenHeader from '../../../../common/components/header/ScreenHeader';
import obPaymentValidation from './CompanyShiftsOBPaymentsValidation';
import Container from '../../../../common/components/Container';
import { startOfToday } from '../../../../utilities/dateUtils';
import { toTime } from '../../../../utilities/dateFormatter';
import { font } from '../../../../common/styles/mixins';
import ShiftSalarySummary from './ShiftSalarySummary';
import Routing from '../../../../core/routing/index';
import {
  dynamicSpacings, colors, fontFamily, fontSizes, modernColors, spacings, wp,
} from '../../../../common/styles/base.style';
import OBPaymentRow from './OBPaymentRow';
import { SALARY_COMPONENTS } from '../../../../common/gig/shiftSalaryUtils';

const { withRouter } = Routing;

const deleteIcon = require('../../../../assets/icons/removeBtn.png');

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
  },
  subheader: {
    marginBottom: spacings.md,
    marginHorizontal: spacings.xl,
    textAlign: 'center',
  },
  fieldHeader: {
    marginTop: spacings.md,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xxl),
  },
  salaryInputWrapper: {
    paddingHorizontal: wp(25),
    marginTop: spacings.md,
  },
  salaryInputRightLabel: {
    paddingLeft: spacings.sm,
    marginTop: spacings.xxs,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
  },
  checkboxesWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: spacings.md,
    marginBottom: spacings.md,
    marginRight: spacings.lg,
  },
  checkboxWrapper: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
    marginBottom: 0,
    marginLeft: 0,
  },
  checkboxLabel: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
  },
});

const obPaymentsStyles = StyleSheet.create({
  obPaymentsContainer: {
    flexDirection: 'row',
    backgroundColor: modernColors.lightGrey,
    borderRadius: 10,
    marginHorizontal: spacings.md,
    marginVertical: spacings.md,
    paddingHorizontal: spacings.xs,
    paddingVertical: spacings.sm,
  },
  columnLeft: {
    flex: 1,
    flexDirection: 'column',
  },
  columnRight: {
    flexDirection: 'column',
    width: wp(30),
    marginLeft: wp(10),
  },
  label: {
    paddingLeft: spacings.xs,
  },
  dateInputsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dash: {
    paddingTop: spacings.xxs,
    paddingHorizontal: spacings.xxs,
  },
  obPaymentsListWrapper: {
    flexDirection: 'column',
    paddingBottom: spacings.md,
    paddingHorizontal: dynamicSpacings.sm,
  },
  headersWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: wp(76),
  },
  headerText: {
    flexDirection: 'row',
    paddingHorizontal: spacings.sm,
    width: wp(39),
  },
  errors: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm, 0, colors.magenta),
    marginBottom: spacings.md,
  },
});

const inputStyling = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: spacings.xs,
    marginTop: spacings.xs,
    marginBottom: 0,
  },
  label: {
    paddingLeft: spacings.xs,
  },
  input: {
    textAlign: 'center',
    borderBottomWidth: 0,
  },
  inputBackgroundWrapper: {
    backgroundColor: colors.white,
  },
});

class CompanyShiftsSalaryStepEditableScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderSummary: props.shiftSalary.salary >= MINIMUM_SALARY,
      showOBPayment: props.shiftSalary.obPayment.applicable,
    };
  }

  // eslint-disable-next-line react/sort-comp
  showSummary = (value) => {
    this.setState({ renderSummary: !!value && value >= MINIMUM_SALARY });
  };

  onSalaryChangeDebounce = debounce(this.showSummary, 50);

  showOBPaymentForm = (showOBPayment) => {
    this.setState({ showOBPayment });
  };

  render() {
    const {
      translate, handleNext, shiftSalary, onAddOBPayment, onRemoveOBPayment, obPaymentPeriods, error, resetOBPaymentFormikField,
    } = this.props;

    const { renderSummary, showOBPayment } = this.state;

    const translateByKey = (key, params) => translate(`company.shifts.shift.${key}`, params);

    const initialValues = { ...shiftSalary };

    const obPaymentInitialValues = {
      startTime: null,
      endTime: null,
      salaryBonus: null,
    };

    const obPaymentsExist = obPaymentPeriods && obPaymentPeriods.length > 0;

    return (
      <ViewportController safeAreaBottom>
        <ScreenHeader
          title={translateByKey('salary.salaryHeader')}
          showBackArrow
          shadow
          subheaderFunc={() => (<Text style={styles.subheader}>{translateByKey('salary.salarySubheader')}</Text>)}
        />
        <FormikWithValidation
          enableReinitialize
          validation={validation}
          initialValues={initialValues}
          onSubmit={handleNext}
          render={({ handleSubmit, isBtnDisabled, values }) => (
            <ScrollView bounces={false}>
              <Container>
                <Text style={styles.fieldHeader}>{translateByKey('salary.fieldHeader')}</Text>
                <FormikFieldCompensationInput
                  name="salary"
                  placeholder={translateByKey('salary.salaryPlaceholder')}
                  rightLabel={translateByKey('salary.perHour')}
                  rightLabelStyle={styles.salaryInputRightLabel}
                  wrapperStyle={styles.salaryInputWrapper}
                  onValueChange={(value) => this.onSalaryChangeDebounce(value)}
                  style={{ input: { textAlign: 'center' }, container: { flex: 1 } }}
                />
                <View style={styles.checkboxesWrapper}>
                  <FormikFieldCheckBox
                    CheckBox={CheckBoxBolek}
                    name="holidayPay"
                    label={translateByKey('salary.holidayPay', { percent: SALARY_COMPONENTS.holidayPay })}
                    style={{ label: styles.checkboxLabel, wrapper: styles.checkboxWrapper }}
                  />
                  <FormikFieldCheckBox
                    CheckBox={CheckBoxBolek}
                    name="obPayment.applicable"
                    label={translateByKey('salary.obPayment')}
                    onPress={(value) => this.showOBPaymentForm(value)}
                    style={{ label: styles.checkboxLabel, wrapper: styles.checkboxWrapper }}
                  />
                </View>
              </Container>
              {showOBPayment && (
                <Container>
                  <FormikWithValidation
                    validation={obPaymentValidation}
                    initialValues={obPaymentInitialValues}
                    onSubmit={onAddOBPayment}
                    render={({
                      handleSubmit: handleSubmitOBP, isBtnDisabled: isBtnDisabledOBP, values: valuesOBP, status, setStatus,
                    }) => (
                      <>
                        <Container>
                          <View style={obPaymentsStyles.obPaymentsContainer}>
                            <View style={obPaymentsStyles.columnLeft}>
                              <Text style={obPaymentsStyles.label}>{translateByKey('salary.timeLabel')}</Text>
                              <View style={obPaymentsStyles.dateInputsWrapper}>
                                <FormikFieldDateTimePicker
                                  name="startTime"
                                  mode="time"
                                  placeholder={translateByKey('salary.from')}
                                  defaultDateFunc={() => startOfToday()}
                                  onDateChange={() => error && resetOBPaymentFormikField(setStatus)}
                                  style={inputStyling}
                                />
                                <Text style={obPaymentsStyles.dash}>-</Text>
                                <FormikFieldDateTimePicker
                                  name="endTime"
                                  mode="time"
                                  placeholder={translateByKey('salary.to')}
                                  defaultDateFunc={() => (valuesOBP.startTime ? moment(valuesOBP.startTime).add(1, 'h').minute(0) : startOfToday())}
                                  onDateChange={() => error && resetOBPaymentFormikField(setStatus)}
                                  style={inputStyling}
                                />
                              </View>
                            </View>
                            <View style={obPaymentsStyles.columnRight}>
                              <Text style={obPaymentsStyles.label}>{translateByKey('salary.obPaymentLabel')}</Text>
                              <FormikFieldCompensationInput
                                name="salaryBonus"
                                placeholder={translateByKey('salary.salaryBonusPlaceholder')}
                                style={inputStyling}
                              />
                            </View>
                          </View>
                          {!!error && (<Text style={obPaymentsStyles.errors}>{translateByKey(`salary.${error}`)}</Text>)}
                        </Container>
                        <Button
                          title={translateByKey('add')}
                          disabled={isBtnDisabledOBP && !(status && status.forceEnabled)}
                          onPress={handleSubmitOBP}
                          size={BUTTON_SIZES.MD}
                        />
                        {obPaymentsExist && (
                          <View style={obPaymentsStyles.obPaymentsListWrapper}>
                            <View style={obPaymentsStyles.headersWrapper}>
                              <View style={obPaymentsStyles.headerText}>
                                <Text>{translateByKey('salary.timeLabel')}</Text>
                              </View>
                              <View style={obPaymentsStyles.headerText}>
                                <Text>{translateByKey('salary.obPaymentLabel')}</Text>
                              </View>
                            </View>
                            {obPaymentPeriods.map((item) => (
                              <OBPaymentRow
                                itemId={item.id}
                                key={item.id}
                                leftValue={`${toTime(item.startTime)} - ${toTime(item.endTime)}`}
                                rightValue={item.salaryBonus}
                                onActionPress={onRemoveOBPayment}
                                actionImg={deleteIcon}
                              />
                            ))}
                          </View>
                        )}
                      </>
                    )}
                  />
                </Container>
              )}
              {renderSummary && (<ShiftSalarySummary values={values} />)}
              <View style={styles.button}>
                <Button
                  title={translate('generic.next')}
                  disabled={isBtnDisabled}
                  onPress={handleSubmit}
                />
              </View>
            </ScrollView>
          )}
        />
      </ViewportController>
    );
  }
}

CompanyShiftsSalaryStepEditableScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  onAddOBPayment: PropTypes.func.isRequired,
  onRemoveOBPayment: PropTypes.func.isRequired,
  resetOBPaymentFormikField: PropTypes.func.isRequired,
  error: PropTypes.string,
  shiftSalary: shiftSalaryPropType,
  obPaymentPeriods: PropTypes.arrayOf(obPaymentPeriodPropType).isRequired,
};

CompanyShiftsSalaryStepEditableScreen.defaultProps = {
  shiftSalary: [],
  error: undefined,
};

export default withLocalize(withRouter(CompanyShiftsSalaryStepEditableScreen));
