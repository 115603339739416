import { SEARCH_LOCATION_BY_COORDINATES, SEARCH_LOCATIONS_BY_NAME } from '../../constants/actionTypes';

export function getHomeLocationByCoordinates({ longitude, latitude }) {
  return {
    type: SEARCH_LOCATION_BY_COORDINATES,
    payload: {
      request: {
        method: 'get',
        url: 'geolocation/reverseSearchForHomeLocation',
        params: {
          lat: latitude,
          lon: longitude,
        },
      },
    },
  };
}

export function searchForHomeLocation(text, lon, lat) {
  return {
    type: SEARCH_LOCATIONS_BY_NAME,
    payload: {
      request: {
        method: 'get',
        url: 'geolocation/searchForHomeLocation',
        params: {
          text,
          lon,
          lat,
        },
      },
    },
  };
}

export function searchForShiftLocation(text, lon, lat) {
  return {
    type: SEARCH_LOCATIONS_BY_NAME,
    payload: {
      request: {
        method: 'get',
        url: 'geolocation/searchForShiftLocation',
        params: {
          text,
          lon,
          lat,
        },
      },
    },
  };
}
