import {
  LOGIN_REQUEST,
  LOGIN_WITH_FACEBOOK_REQUEST,
  LOGOUT_REQUEST,
  SESSION_TIMEOUT,
  USER_RESET_PASSWORD_REQUEST,
  USER_SET_PASSWORD_REQUEST,
  USER_VISITED_FIRST_SCREEN,
  RESET_SIGNUP_SUCCESS,
} from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../analytics/googleAnalytics';

export function sessionTimeout() {
  return {
    type: SESSION_TIMEOUT,
  };
}

export function logoutUser(installationId) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.LOGOUT);
  return {
    type: LOGOUT_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'auth/logout',
        data: { installationId },
      },
    },
  };
}

export function loginUser(userData) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.LOGIN);
  return {
    type: LOGIN_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'auth/login',
        data: userData,
      },
    },
  };
}

export function loginWithFacebook(accessToken) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.LOGIN_WITH_FACEBOOK);
  return {
    type: LOGIN_WITH_FACEBOOK_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/auth/candidate/facebook',
        data: { access_token: accessToken },
      },
    },
  };
}

export function resetPassword(email) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.REQUEST_RESET_PASSWORD);
  return {
    type: USER_RESET_PASSWORD_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'auth/requestPasswordReset',
        data: email,
      },
    },
  };
}

export function setNewPassword(password, token) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.SET_NEW_PASSWORD);
  return {
    type: USER_SET_PASSWORD_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `auth/setPassword?token=${token}`,
        data: password,
      },
    },
  };
}

export function getAndroidVersion() {
  googleAnalytics.trackUserEvent(ACTIONS.USER.SET_NEW_PASSWORD);
  return {
    type: USER_SET_PASSWORD_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `auth/setPassword?token=${token}`,
        data: password,
      },
    },
  };
}

export function getIosVersion() {
  googleAnalytics.trackUserEvent(ACTIONS.USER.SET_NEW_PASSWORD);
  return {
    type: USER_SET_PASSWORD_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `auth/setPassword?token=${token}`,
      },
    },
  };
}

export function setSplashScreenVisited() {
  return {
    type: USER_VISITED_FIRST_SCREEN,
  };
}

export function resetSignUpSuccess() {
  return {
    type: RESET_SIGNUP_SUCCESS,
  };
}
