import React from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView, StyleSheet, Platform,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  colors, dynamicSpacings, fontFamily, fontSizes, spacings,
} from '../../common/styles/base.style';
import Container from '../../common/components/Container';
import ScreenHeader, { RIGHT_BUTTONS } from '../../common/components/header/ScreenHeader';
import Wrapper from '../../common/components/Wrapper';
import ProfileInfo, { renderCompanyInfo } from '../../common/components/ProfileInfo';
import { BUTTON_ALIGN, BUTTON_TYPES } from '../../common/components/buttons/Button';
import Header from '../../common/components/header/Header';
import PrimaryText from '../../common/components/PrimaryText';
import { font } from '../../common/styles/mixins';
import Routing from '../../core/routing/index';
import { COMPANY_PROFILE_EDIT, COMPANY_PROFILE_SETTINGS } from '../../constants/routes';
import ProfileLinkWrapper from '../../common/components/HighlightedSection';
import { companyProfilePropType } from '../../constants/propTypes';
import LinkButton from '../../common/components/buttons/LinkButton';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  profileInfoHeader: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xxl, 0.4),
    textAlign: 'left',
    marginBottom: spacings.sm,
  },
  linksWrapper: {
    backgroundColor: colors.smokeWhite,
    paddingVertical: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
  },
  linkColor: {
    color: colors.greyishBrown,
  },
  adminButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: spacings.sm,
  },
});

const settingsButtonStyle = StyleSheet.create({
  text: {
    color: colors.greyishBrown,
  },
  wrapper: {
    marginBottom: 0,
  },
});

export const renderCompanyAbout = (translate, { companyDescription, companyName }) => (
  <>
    <Header style={styles.profileInfoHeader}>{`${translate('generic.about')} ${companyName}`}</Header>
    {companyDescription ? <PrimaryText>{companyDescription}</PrimaryText> : null}
  </>
);

const CompanyProfileScreen = ({
  profile, translate, history, companyProfileEditProfileImage,
}) => (
  <Container>
    <ScreenHeader
      title={translate('generic.myProfile')}
      rightButton={RIGHT_BUTTONS.EDIT}
      handlerForRightButton={() => history.push(COMPANY_PROFILE_EDIT)}
    />
    <ScrollView>
      <Wrapper style={Platform.OS === 'web' ? { paddingTop: spacings.lg } : null}>
        <ProfileInfo
          profile={profile}
          renderExtraInfo={renderCompanyInfo}
          editImage
          editProfileImageAction={companyProfileEditProfileImage}
          online
        />
        {profile && renderCompanyAbout(translate, profile)}
      </Wrapper>
    </ScrollView>

    <ProfileLinkWrapper>
      <LinkButton
        type={BUTTON_TYPES.TEXT}
        alignText={BUTTON_ALIGN.LEFT}
        to={COMPANY_PROFILE_SETTINGS}
        title={translate('settings.settingsButton')}
        style={settingsButtonStyle}
      />
    </ProfileLinkWrapper>
  </Container>
);

CompanyProfileScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  companyProfileEditProfileImage: PropTypes.func.isRequired,
  profile: companyProfilePropType,
};

CompanyProfileScreen.defaultProps = {
  profile: null,
};

export default withLocalize(withRouter(CompanyProfileScreen));
