import React from 'react';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import CompanyProfileContainer from './CompanyProfileContainer';
import { COMPANY_USER } from '../../constants/userScopes';
import {
  COMPANY_PAYMENTS, COMPANY_PROFILE, COMPANY_PROFILE_EDIT, COMPANY_PROFILE_SETTINGS,
} from '../../constants/routes';
import CompanyProfileEditContainer from './edit/CompanyProfileEditContainer';
import CompanyProfileSettingsContainer from './settings/CompanyProfileSettingsContainer';
import CompanyPaymentsRouting from '../payments/CompanyPaymentsRouting';

const CompanyProfileRouting = () => (
  <>
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_PAYMENTS} component={CompanyPaymentsRouting} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PROFILE} component={CompanyProfileContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PROFILE_EDIT} component={CompanyProfileEditContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PROFILE_SETTINGS} component={CompanyProfileSettingsContainer} />
  </>
);

export default CompanyProfileRouting;
