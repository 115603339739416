import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PrimaryModal from '../../components/modal/PrimaryModal';
import Container from '../../components/Container';
import Wrapper from '../../components/Wrapper';
import GigOfferView from './GigOfferView';
import Button from '../../components/buttons/Button';
import ModalFooter from '../../components/modal/ModalFooter';
import { standardSpacings } from '../../styles/base.style';

const styles = StyleSheet.create({
  container: {
    paddingTop: standardSpacings.formVertical,
  },
});

class GigViewOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = { offer: undefined };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      // eslint-disable-next-line
      this.setState({ offer: undefined });
      this.loadOffer();
    }
  }

  loadOffer = () => {
    const { getGigOfferAction, gigId, candidateId } = this.props;
    // only gigId will be set in case of candidate
    getGigOfferAction(gigId, candidateId)
      .then((response) => {
        if (!response.isError) {
          this.setState({ offer: response.payload });
        }
      });
  };

  renderOffer() {
    const { offer } = this.state;

    return (
      <Container>
        <ScrollView contentContainerStyle={styles.container}>
          <Wrapper noFlex>
            {this.props.renderFirstSection(offer)}
          </Wrapper>
          <GigOfferView offer={offer} translationKey={this.props.translationKey} />
        </ScrollView>
        <ModalFooter>
          <Button
            size="md"
            type="secondary"
            title={this.props.translate('generic.close')}
            onPress={this.props.closeModal}
          />
        </ModalFooter>
      </Container>
    );
  }

  render() {
    const {
      translate,
      translationKey,
      visible,
      closeModal,
      headerProps,
      chatButton,
      ...props
    } = this.props;

    const headerPropsExtends = {
      ...headerProps,
      showBackArrow: true,
      shadow: true,
      subheaderFunc: chatButton,
    };

    return (
      <PrimaryModal
        visible={visible}
        handleCancel={closeModal}
        header={translate(`${translationKey}.header`)}
        headerProps={headerPropsExtends}
        {...props}
      >
        {this.state.offer ? this.renderOffer() : null}
      </PrimaryModal>
    );
  }
}

GigViewOfferModal.propTypes = {
  translate: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  getGigOfferAction: PropTypes.func.isRequired,
  renderFirstSection: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  candidateId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  chatButton: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  headerProps: PropTypes.object,
};

GigViewOfferModal.defaultProps = {
  gigId: undefined,
  candidateId: undefined,
  headerProps: {},
};

export default withLocalize(GigViewOfferModal);
