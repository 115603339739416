import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { candidateExperiencePropType } from '../../../../../constants/propTypes';
import CandidateProfileExperienceForm from '../../../forms/experience/CandidateProfileExperienceForm';
import EditModalFooter from '../../../../../common/components/modal/EditModalFooter';
import { getExperienceKey } from './profileExperienceHelper';
import PrimaryModal from '../../../../../common/components/modal/PrimaryModal';

const AddProfileExperienceModalScreen = ({
  translate, visible, experience, handleSubmit, handleCancel,
}) => (
  <PrimaryModal
    visible={visible}
    handleCancel={handleCancel}
    header={translate('candidateProfile.details.experienceHeader')}
    // this will force recreation of the modal
    key={getExperienceKey(experience)}
  >
    <CandidateProfileExperienceForm
      experience={experience}
      handleSubmit={handleSubmit}
      renderFooter={({ handleSubmitFormik, isBtnDisabled }) => (
        <EditModalFooter
          handleCancel={handleCancel}
          handleSubmitFormik={handleSubmitFormik}
          isBtnDisabled={isBtnDisabled}
          confirmLabel={translate('candidateProfile.addExperience.confirmBtn')}
        />
      )}
    />
  </PrimaryModal>

);

AddProfileExperienceModalScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  experience: candidateExperiencePropType.isRequired,
};

export default withLocalize(AddProfileExperienceModalScreen);
