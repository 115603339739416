import React, { Component } from 'react';
import { ScrollView, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import PrimaryModal from '../../../../../common/components/modal/PrimaryModal';
import ModalFooter from '../../../../../common/components/modal/ModalFooter';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../../../common/components/buttons/Button';
import { filledOutQuestionnairePropType } from '../../../../../constants/propTypes';
import FilledOutQuestionnaireScreen from '../../../../../common/gig/questionnaire/FilledOutQuestionnaireScreen';

class CandidateQuestionnaireModalView extends Component {
  renderForm() {
    const {
      questionnaire,
    } = this.props;
    if (!questionnaire) {
      return null;
    }
    return (<FilledOutQuestionnaireScreen questionnaire={questionnaire} />);
  }

  render() {
    const { visible, closeModal, translate } = this.props;
    return (
      <PrimaryModal
        visible={visible}
        handleCancel={closeModal}
        header={translate('company.gig.candidate.questionnaireModal.header')}
        headerProps={{ showBackArrow: true, shadow: true }}
      >
        <ScrollView style={{ flex: 1 }}>
          { this.renderForm() }
        </ScrollView>
        <ModalFooter>
          <View>
            <Button
              size={BUTTON_SIZES.MD}
              type={BUTTON_TYPES.SECONDARY}
              title={this.props.translate('generic.close')}
              onPress={this.props.closeModal}
            />
          </View>
        </ModalFooter>
      </PrimaryModal>
    );
  }
}

CandidateQuestionnaireModalView.propTypes = {
  translate: PropTypes.func.isRequired,
  questionnaire: PropTypes.arrayOf(filledOutQuestionnairePropType),
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

CandidateQuestionnaireModalView.defaultProps = {
  questionnaire: null,
};

export default withLocalize(CandidateQuestionnaireModalView);
