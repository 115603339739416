import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gigCandidatePropType } from '../../../../constants/propTypes';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../../common/components/header/ScreenHeader';
import CandidateProfileViewDetailsView from '../../../../common/components/candidate_profile/view/CandidateProfileViewDetailsView';
import Routing from '../../../../core/routing/index';
import {
  colors, fontFamily, fontSizes, shadowTop, spacings,
} from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import FilledOutQuestionnaireScreen from '../../../../common/gig/questionnaire/FilledOutQuestionnaireScreen';
import Header from '../../../../common/components/header/Header';
import SectionHeader from '../../../../common/components/header/SectionHeader';
import GigCandidateActionsSection from './GigCandidateActionsSection';
import getDefinition from './listItemDefinitions';
import ViewportController from '../../../../common/components/ViewportController';
import CandidateActionSheet from '../../../action_sheet/CandidateActionSheet';
import FeatureToggle from '../../../../common/components/FeatureToggle';
import { PrintingContext } from '../../../../core/printingContext';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonsWrapper: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  onlineIndicator: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacings.xxs,
    paddingHorizontal: spacings.xxs,
    borderRadius: 16,
    height: 32,
    borderColor: colors.greenishTeal,
    borderWidth: 2,
  },
  onlineIndicatorText: {
    backgroundColor: colors.colgateWhite,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0.2, colors.tealish),
  },
  actionsSectionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: spacings.xs,
    ...shadowTop,
  },
});

class GigCandidateProfileScreen extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = PrintingContext;

  openActionSheet = () => {
    this.openActionSheet();
  };

  registerOpenActionSheet = (openActionSheet) => {
    this.openActionSheet = openActionSheet;
  };

  renderButtonsSection() {
    const {
      profile,
      match,
      postAction,
    } = this.props;

    const itemDefinition = getDefinition(profile.matching);
    return itemDefinition.actions ? (
      <ViewportController noFlex safeAreaBottom style={styles.actionsSectionContainer}>
        <GigCandidateActionsSection
          candidate={profile}
          gigId={match.params.gigId}
          context="CANDIDATE_PROFILE"
          postAction={postAction}
        />
      </ViewportController>
    ) : null;
  }

  renderProfileView() {
    const {
      translate, profile, online,
    } = this.props;
    const { questionnaire } = profile.matching;
    return (
      <CandidateProfileViewDetailsView profile={profile} stickyFooter={() => this.renderButtonsSection()} online={online}>
        {questionnaire && questionnaire.length > 0
        && (
          <>
            <SectionHeader title="" />
            <Header style={{ marginBottom: spacings.md }}>{translate('company.gig.candidate.profile.questionnaire')}</Header>
            <FilledOutQuestionnaireScreen questionnaire={questionnaire} />
          </>
        )}
      </CandidateProfileViewDetailsView>
    );
  }

  render() {
    const { translate, profile, grantedFeatures } = this.props;
    const { isPrint } = this.context;
    return (
      <View style={[styles.container, isPrint ? { width: '90%' } : null]}>
        {!isPrint && (
        <ScreenHeader
          title={translate('candidateProfile.details.view')}
          showBackArrow
          rightButton={grantedFeatures.includes('CANDIDATES_POOL') ? RIGHT_BUTTONS.MENU : null}
          handlerForRightButton={this.openActionSheet}
        />
        )}
        {profile ? this.renderProfileView() : (<ActivityIndicator />)}
        {!isPrint && (
        <FeatureToggle requestedFeature="CANDIDATES_POOL">
          <CandidateActionSheet
            registerOpenActionSheetFunction={this.registerOpenActionSheet}
            candidate={profile}
            onInviteCandidateToPool={this.props.onInviteCandidateToPool}
            onRemove={this.props.onRemoveCandidateFromPool}
          />
        </FeatureToggle>
        )}
      </View>
    );
  }
}

GigCandidateProfileScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  profile: gigCandidatePropType,
  match: ReactRouterPropTypes.match.isRequired,
  online: PropTypes.bool,
  postAction: PropTypes.func,
  onInviteCandidateToPool: PropTypes.func,
  onRemoveCandidateFromPool: PropTypes.func,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCandidateProfileScreen.defaultProps = {
  profile: undefined,
  online: null,
  postAction: null,
  onInviteCandidateToPool: null,
  onRemoveCandidateFromPool: null,
};

export default withRouter(withLocalize(GigCandidateProfileScreen));
