import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { Button } from '../../../../common/components';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import { FIND_GIGS_CONTEXT } from '../../../../constants/actionContext';
import {
  fontFamily, fontSizes, shadowTop, spacings,
} from '../../../../common/styles/base.style';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import { font } from '../../../../common/styles/mixins';
import { approveShortlisting, rejectGig } from '../../my_gigs/candidateMyGigsActions';
import { showInformationAlert } from '../../../../common/components/alert/alerts';
import { isQuestionnaireFilledOut } from '../../questionnaire/CandidateQuestionnaireValidation';
import { filledOutQuestionnairePropType } from '../../../../constants/propTypes';
import CandidateQuestionnaireModalEdit from '../../questionnaire/CandidateQuestionnaireModalEdit';

const {
  REJECTED_BY_CANDIDATE,
  SHORTLISTED,
} = GIG_CANDIDATE_STATUS;

const styles = StyleSheet.create({
  pendingShortlistingText: {
    textAlign: 'center',
    marginBottom: 20,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
  },
});

const showAlert = (translate, err) => (
  showInformationAlert(
    translate,
    { confirmKey: 'generic.close', titleKey: 'errors.error', textKey: JSON.stringify(err.errors) },
  )
);

class ApproveRejectAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaireModalVisible: false,
      questionnaire: props.questionnaire,
    };
  }

  onApproveShortlisting = () => {
    const {
      translate,
      approveShortlistingAction,
      updateGigDataOnPreviewScreen,
      gigId,
    } = this.props;
    const { questionnaire } = this.state;
    if (questionnaire && questionnaire.length && !isQuestionnaireFilledOut(questionnaire)) {
      this.openQuestionnaireModal();
    } else {
      approveShortlistingAction({ gigId, questionnaire }, { context: FIND_GIGS_CONTEXT }).then((response) => {
        if (!response.isError) {
          updateGigDataOnPreviewScreen(gigId, SHORTLISTED);
        } else {
          showAlert(translate, response);
        }
      });
    }
  };

  openQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: true });
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  saveQuestionnaireAnswers = () => {
    this.closeQuestionnaireModal();
    this.onApproveShortlisting();
  };

  handleUpdateAnswer = (questionId, newAnswer) => {
    const currentQuestionnaireItems = this.state.questionnaire;
    for (let i = 0; i < currentQuestionnaireItems.length; i += 1) {
      if (currentQuestionnaireItems[i].question.questionId === questionId) {
        currentQuestionnaireItems[i].answer.text = newAnswer;
        this.setState({ questionnaire: currentQuestionnaireItems });
        break;
      }
    }
  };

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, questionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalEdit
        visible={questionnaireModalVisible}
        onClose={this.closeQuestionnaireModal}
        onSave={this.saveQuestionnaireAnswers}
        handleUpdateAnswer={this.handleUpdateAnswer}
        questionnaire={questionnaire}
      />
    );
  }

  render() {
    const {
      translate,
      rejectShortlistingAction,
      updateGigDataOnPreviewScreen,
      gigId,
    } = this.props;
    return (
      <View style={{ paddingHorizontal: spacings.md, paddingTop: 10, ...shadowTop }}>
        <Text style={styles.pendingShortlistingText}>{translate('gigPreview.actions.shortlistingText')}</Text>
        <View style={{ flexDirection: 'row' }}>
          <Button
            size={BUTTON_SIZES.FLEX_MD}
            type={BUTTON_TYPES.SECONDARY}
            title={translate('generic.no')}
            onPress={() => {
              rejectShortlistingAction(gigId, { context: FIND_GIGS_CONTEXT }).then((response) => {
                if (!response.isError) {
                  updateGigDataOnPreviewScreen(gigId, REJECTED_BY_CANDIDATE);
                } else {
                  showAlert(translate, response);
                }
              });
            }}
          />
          <Button
            size={BUTTON_SIZES.FLEX_MD}
            type={BUTTON_TYPES.PRIMARY_RED}
            title={translate('generic.yes')}
            onPress={this.onApproveShortlisting}
          />
          {this.renderQuestionnaireModal()}
        </View>
      </View>
    );
  }
}

ApproveRejectAction.propTypes = {
  gigId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  rejectShortlistingAction: PropTypes.func.isRequired,
  approveShortlistingAction: PropTypes.func.isRequired,
  updateGigDataOnPreviewScreen: PropTypes.func.isRequired,
  questionnaire: PropTypes.arrayOf(filledOutQuestionnairePropType),
};

ApproveRejectAction.defaultProps = {
  questionnaire: undefined,
};

export default connect(null, {
  rejectShortlistingAction: rejectGig, approveShortlistingAction: approveShortlisting,
})(withLocalize(ApproveRejectAction));
