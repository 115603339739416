import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withGigEditPropTypes, workload as workloadPropType } from '../../../../constants/propTypes';
import { gigCreatorAddAvailability } from '../../gigCreatorActions';
import GigCreatorStepAvailabilityScreen from './GigCreatorStepAvailabilityScreen';
import { withGigEdit } from '../../withGigEdit';
import Routing from '../../../../core/routing/index';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepAvailabilityContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.AVAILABILITY));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.AVAILABILITY);
  }

  handleSubmit = ({ workload, shifts }, { setSubmitting }) => {
    this.props.gigCreatorAddAvailability(workload, shifts);

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.WORKLOAD, { workload, shifts })
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  render = () => (
    <GigCreatorStepAvailabilityScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      shifts={this.props.shifts}
      workload={this.props.workload}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  );
}

GigCreatorStepAvailabilityContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  gigCreatorAddAvailability: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.number).isRequired,
  workload: workloadPropType,
  isEdit: PropTypes.bool.isRequired,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCreatorStepAvailabilityContainer.defaultProps = {
  workload: null,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      shifts,
      workload,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { shifts, workload, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddAvailability })(GigCreatorStepAvailabilityContainer));
