import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import Container from './common/components/Container';
import WebMenuContainer from './WebMenuContainer';
import { standardColors } from './common/styles/base.style';

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    backgroundColor: standardColors.webContentBackground,
  },
});

function AppContentWrapper(props) {
  return (
    <Container row>
      <WebMenuContainer />
      <View style={styles.contentWrapper}>
        {props.children}
      </View>
    </Container>
  );
}

AppContentWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

export default AppContentWrapper;
