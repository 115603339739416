import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '../../styles/base.style';
import * as propTypes from '../../../constants/propTypes';

const styles = StyleSheet.create({
  onlineBorder: {
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  online: {
    backgroundColor: colors.greenishTeal,
  },
});

function OnlineIndicator({ online, style }) {
  const radius = style.radius || 16;
  return online && (
    <View style={[styles.onlineBorder, { width: radius, height: radius, borderRadius: radius / 2 }, style]}>
      <View style={[styles.online, { width: radius - 4, height: radius - 4, borderRadius: (radius - 4) / 2 }]} />
    </View>
  );
}

OnlineIndicator.propTypes = {
  online: PropTypes.bool,
  style: propTypes.stylePropTypes,
};

OnlineIndicator.defaultProps = {
  online: false,
  style: null,
};

export default OnlineIndicator;
