import React from 'react';
import { StyleSheet, View } from 'react-native';
import AdminFindGigsFilters from './filters/AdminFindGigsFilters';
import CandidateFindGigsList from './AdminFindGigsList';
import Container from '../../../common/components/Container';
import { shadowBottom, spacings } from '../../../common/styles/base.style';

const styles = StyleSheet.create({
  container: {
    ...shadowBottom,
    marginBottom: 0,
    paddingHorizontal: spacings.sm,
  },
});

function AdminFindGigsContainer() {
  return (
    <Container>
      <View style={styles.container}>
        <AdminFindGigsFilters />
      </View>
      <CandidateFindGigsList />
    </Container>
  );
}

export default AdminFindGigsContainer;
