import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../core/routing/index';
import { gigCreatorInitEditing, gigCreatorUpdateGig } from './gigCreatorActions';
import { successNotification } from '../../common/notifications_center/notificationCenterActions';
import { getGig } from '../gigs/companyGigActions';

const { withRouter } = Routing;

export const withGigEdit = (wrappedMapStateToProps, wrappedActions) => (WrappedComponent) => {
  class DecoratingComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        shouldRender: true,
      };
      this.isEdit = this.props.match.path.includes('edit');
      this.gigId = this.props.match.params.gigId || '';

      this.connectedComponent = connect(wrappedMapStateToProps, wrappedActions)(WrappedComponent);
    }

    componentDidMount() {
      if (this.isEdit) {
        this.loadGig();
      }
    }

    loadGig = async () => {
      this.setState({ shouldRender: false });
      const gig = await this.props.getGig(this.gigId);
      this.props.gigCreatorInitEditing(gig.payload);
      this.setState({ shouldRender: true });
    };

    updateGig = (sectionName, gigData) => new Promise((resolve, reject) => {
      this.props.gigCreatorUpdateGig(this.gigId, sectionName, gigData)
        .then((response) => {
          if (response.isError) {
            reject(response.error);
          } else {
            this.props.successNotification('companyGigCreator.edit.notification.success.title', 'companyGigCreator.edit.notification.success.text');
            resolve(response);
          }
        });
    });

    render() {
      const ConnectedComponent = this.connectedComponent;
      if (this.state.shouldRender) {
        return (
          <ConnectedComponent
            isEdit={this.isEdit}
            gigId={this.gigId}
            updateGig={this.updateGig}
            {...this.props}
          />
        );
      }
      return null;
    }
  }

  DecoratingComponent.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    gigCreatorUpdateGig: PropTypes.func.isRequired,
    successNotification: PropTypes.func.isRequired,
    getGig: PropTypes.func.isRequired,
    gigCreatorInitEditing: PropTypes.func.isRequired,
  };

  return withRouter(connect(null, {
    gigCreatorUpdateGig, successNotification, getGig, gigCreatorInitEditing,
  })(DecoratingComponent));
};
