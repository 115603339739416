import { fromJS, List, Map } from 'immutable';
import {
  CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_CATEGORY_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_EMAIL_REQUEST_SUCCESS,
  PROFILE_CREATOR_SAVE_PROFILE_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST_SUCCESS,
  // CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST_SUCCESS,
  ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST_SUCCESS,
  CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST_SUCCESS
} from '../../constants/actionTypes';

export const INITIAL_PROFILE_STATE = {
  categoryValidationParams: Map(),
  categories: List(),
  available: null,
  hours: null,
  times: List(),
  location: null,
  homeLocation: null,
  experience: List(),
  education: List(),
  profileImageUri: null,
  msisdn: undefined,
  personalNumber: undefined,
  currentOccupation: List(),
  languages: List(),
  // contractAccepted: false
};

function getIndexByEducationId(education, educationId) {
  return education.findIndex((e) => e.get('educationId') === educationId);
}

function getIndexByExperienceId(experience, experienceId) {
  return experience.findIndex((e) => e.get('experienceId') === experienceId);
}

export default function (state = new Map(INITIAL_PROFILE_STATE), action) {
  switch (action.type) {
    case CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST_SUCCESS:
      return state.set('categoryValidationParams', fromJS(action.payload));
    case CANDIDATE_PROFILE_REQUEST_SUCCESS:
    case PROFILE_CREATOR_SAVE_PROFILE_REQUEST_SUCCESS:
      return state.merge(fromJS(action.payload));
    case CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST_SUCCESS:
      return state.set('available', action.payload.available);
    case CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST_SUCCESS: {
      const { firstName, lastName } = action.payload;
      return state.set('firstName', firstName).set('lastName', lastName);
    }
    case CANDIDATE_PROFILE_SET_EMAIL_REQUEST_SUCCESS: {
      const { email } = action.payload;
      return state.set('email', email);
    }
    case CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST_SUCCESS: {
      return state.merge(fromJS(action.payload));
    }
    case CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST_SUCCESS:
      return state.set('location', fromJS(action.payload.location));
    case CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST_SUCCESS:
      return state.set('homeLocation', fromJS(action.payload.homeLocation));
    case CANDIDATE_PROFILE_SET_CATEGORY_REQUEST_SUCCESS:
      return state.set('categories', List(action.payload.categories));
    case CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST_SUCCESS:
      return state.set('currentOccupation', List(action.payload.currentOccupation));
    case CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST_SUCCESS:
      return state.set('languages', List(action.payload.languages));
    case CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST_SUCCESS:
      return state.set('education', state.get('education').push(fromJS(action.payload.education)));
    case CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST_SUCCESS: {
      const education = state.get('education');
      const updatedEducation = fromJS(action.payload.education);
      return state.set('education', education.set(
        getIndexByEducationId(education, updatedEducation.get('educationId')),
        updatedEducation,
      ));
    }
    case CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST_SUCCESS: {
      const education = state.get('education');
      return state.set('education', education.remove(getIndexByEducationId(education, action.meta.previousAction.educationId)));
    }
    case CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST_SUCCESS:
      return state.set('experience', state.get('experience').push(fromJS(action.payload.experience)));
    case CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST_SUCCESS: {
      const experience = state.get('experience');
      const updatedExperience = fromJS(action.payload.experience);
      const indexByExperienceId = getIndexByExperienceId(experience, updatedExperience.get('experienceId'));
      return state.set('experience', experience.set(
        indexByExperienceId,
        updatedExperience,
      ));
    }
    case CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST_SUCCESS: {
      const experience = state.get('experience');
      return state.set('experience', experience.remove(getIndexByExperienceId(experience, action.meta.previousAction.experienceId)));
    }

    case CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST_SUCCESS: {
      return state.set('aboutMe', action.payload.aboutMe);
    }

    case CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST_SUCCESS: {
      return state.set('personalNumber', action.payload.personalNumber);
    }

    case CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST_SUCCESS: {
      return state.set('clearingNumber', action.payload.clearingNumber);
    }

    case CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST_SUCCESS: {
      return state.set('bankAccountNo', action.payload.bankAccountNo);
    }

    case CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST_SUCCESS: {
      return state.set('msisdn', fromJS(action.payload.msisdn));
    }

    case CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS: {
      return state.set('profileImageUri', action.payload.uri);
    }

    case ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST_SUCCESS: {
      return state.set('association', action.payload.association);
    }

    // case CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST_SUCCESS: {
    //   return state.set('contractAccepted', action.payload.acceptedContract);
    // }
    default:
      return state;
  }
}
