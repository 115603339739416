import validator from 'validator';

export const MAX_STREET_LENGTH = 100;
export const MAX_INFO_LENGTH = 100;

export default [
  {
    field: 'street',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'street',
    method: validator.isLength,
    args: [{ max: MAX_STREET_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'info',
    method: validator.isLength,
    args: [{ max: MAX_INFO_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'cityKey',
    custom: (value) => !!value,
    message: 'too_few',
  },
];
