import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { MATCHED_LIST_KEY } from '../candidateMyGigsReducer';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { clearCandidateGigs, getCandidateGigs, removeNewContentIndicator } from '../candidateMyGigsActions';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import ListItem from '../../../../common/components/ListItem';
import GigListItem from '../GigListItem';
import { CANDIDATE_GIG_CHAT } from '../../../../constants/routes';

class CandidateMyGigsMatchedView extends Component {
  componentDidMount() {
    this.props.getCandidateGigs(MATCHED_LIST_KEY, [GIG_CANDIDATE_STATUS.SHORTLISTED], false)
      .then(() => {
        this.setTabInactive();
      });
  }

  componentWillUnmount() {
    this.props.clearCandidateGigs(MATCHED_LIST_KEY);
  }

  setTabInactive = () => {
    if (this.props.newContentIndicators.includes(GIG_CANDIDATE_STATUS.SHORTLISTED)) {
      this.props.removeNewContentIndicator(GIG_CANDIDATE_STATUS.SHORTLISTED);
    }
  };

  startChat(gigId) {
    this.props.history.push(CANDIDATE_GIG_CHAT.replace(':id', gigId));
  }

  render() {
    return (
      <PrimaryFlatList
        emptyListTextKey="candidateMyGigs.notMatched"
        data={this.props.matched}
        keyExtractor={(item) => item.gigId}
        renderItem={({ item: matchedGig }) => (
          <ListItem
            renderContent={() => (
              <GigListItem
                gig={matchedGig}
                online={this.props.usersPresence[matchedGig.createdBy.userId]}
                internalFunctions={{
                  startChat: () => this.startChat(matchedGig.gigId),
                }}
              />
            )}
          />
        )}
      />
    );
  }
}

CandidateMyGigsMatchedView.propTypes = {
  getCandidateGigs: PropTypes.func.isRequired,
  clearCandidateGigs: PropTypes.func.isRequired,
  removeNewContentIndicator: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  matched: PropTypes.arrayOf(matchedGigForCandidatePropType),
  history: ReactRouterPropTypes.history.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

CandidateMyGigsMatchedView.defaultProps = {
  matched: null,
};

const mapStateToProps = (state) => ({
  matched: state.candidateMyGigs.get(MATCHED_LIST_KEY) ? state.candidateMyGigs.get(MATCHED_LIST_KEY).toJS() : null,
  newContentIndicators: state.candidateMyGigs.get('newContentIndicators'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, { getCandidateGigs, clearCandidateGigs, removeNewContentIndicator })(CandidateMyGigsMatchedView);
