import React from 'react';
import {
  ActivityIndicator, StyleSheet, Text, View,
} from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { shiftPropType } from '../../../constants/propTypes';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import {
  activityIndicator, colors, dynamicSpacings, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import BaseListItem from '../../../common/components/BaseListItem';
import ProfileInfo from '../../../common/components/ProfileInfo';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import LinkButton from '../../../common/components/buttons/LinkButton';
import { COMPANY_SHIFT_EDIT, COMPANY_SHIFT_VIEW } from '../../../constants/routes';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingTop: spacings.sm,
  },
  subheader: {
    paddingLeft: spacings.xl,
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl),
  },
  list: {
    backgroundColor: colors.white,
  },
  subheaderButtons: {
    paddingTop: spacings.xs,
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: dynamicSpacings.lg,
  },
});

const candidateProfileInfoStyles = StyleSheet.create({
  wrapper: {
    marginBottom: 0,
  },
  name: {
    marginBottom: spacings.xxs,
  },
});

class CompanyShiftCandidatesScreen extends React.Component {
  renderSubHeader() {
    const { shift, translate } = this.props;

    const comment = 'Remove me when button edit works';

    return (
      <View style={styles.subheaderButtons}>
        <LinkButton
          title={translate('company.shifts.shift.viewShift')}
          type={BUTTON_TYPES.GRAY}
          size={BUTTON_SIZES.FLEX_SM}
          onPress={(redirect) => redirect()}
          to={COMPANY_SHIFT_VIEW.replace(':gigId', shift.gigId)}
        />
        {!comment && (
          <LinkButton
            title={translate('company.shifts.shift.editShift')}
            type={BUTTON_TYPES.GRAY}
            size={BUTTON_SIZES.FLEX_SM}
            onPress={(redirect) => redirect()}
            to={COMPANY_SHIFT_EDIT.replace(':gigId', shift.gigId)}
          />
        )}
      </View>
    );
  }

  render() {
    const { shift, translate, onOpenCandidate } = this.props;

    if (!shift) {
      return (<ActivityIndicator size="large" style={{ ...activityIndicator }} />);
    }

    return (
      <>
        <ScreenHeader
          title={shift.position}
          showBackArrow
          subheaderFunc={() => this.renderSubHeader()}
        />
        <View style={styles.wrapper}>
          <Text style={styles.subheader}>{translate('company.shifts.candidates.bookedCandidates')}</Text>
          <PrimaryFlatList
            style={styles.list}
            emptyListTextKey={translate('company.shifts.candidates.noCandidates')}
            data={shift.shiftCandidates}
            keyExtractor={(shiftCandidate) => shiftCandidate.userId}
            renderItem={({ item: shiftCandidate }) => (
              <BaseListItem
                renderMainContent={() => (
                  <ProfileInfo
                    profile={shiftCandidate}
                    imageSize={{ width: 54, height: 54, borderRadius: 27 }}
                    style={candidateProfileInfoStyles}
                  />
                )}
                onPress={() => onOpenCandidate(shiftCandidate.userId)}
              />
            )}
          />
        </View>
      </>
    );
  }
}

CompanyShiftCandidatesScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  onOpenCandidate: PropTypes.func.isRequired,
  shift: shiftPropType,
};

CompanyShiftCandidatesScreen.defaultProps = {
  shift: null,
};

export default withLocalize(CompanyShiftCandidatesScreen);
