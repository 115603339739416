import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import { candidateProfileEditPhoneNumber } from '../../CandidateProfileActions';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import CandidateProfilePhoneForm from '../../forms/phone/CandidateProfilePhoneForm';
import { msisdnPropType } from '../../../../constants/propTypes';
import COUNTRY_CODES from '../../../../common/msisdn/msisdnConstants';

class CandidateProfileEditDetailsPhoneModalAware extends React.Component {
  handleSubmit = (value) => {
    const { candidateProfileEditPhoneNumber: editAction, closeModal } = this.props;
    editAction(value).then((response) => { if (!response.isError) { closeModal(); } });
  };

  openModal = () => {
    const {
      translate, msisdn, closeModal,
    } = this.props;

    msisdn.countryCode = msisdn.countryCode || COUNTRY_CODES.SE;

    this.props.setModalContent(
      translate('candidateProfile.details.phone'),
      <ModalKeyboardAwareScrollView>
        <CandidateProfileEditDetailsFormWithFooter
          component={CandidateProfilePhoneForm}
          closeModal={closeModal}
          msisdn={msisdn}
          handleSubmit={this.handleSubmit}
        />
      </ModalKeyboardAwareScrollView>,
    );
  };

  render() {
    const { translate, msisdn: { phoneNumber, countryCode } } = this.props;

    return (
      <EditableField
        label={translate('candidateProfile.details.phone')}
        value={countryCode + phoneNumber}
        placeholder={translate('candidateProfile.details.phonePlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

CandidateProfileEditDetailsPhoneModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  msisdn: msisdnPropType,
  candidateProfileEditPhoneNumber: PropTypes.func.isRequired,
};

CandidateProfileEditDetailsPhoneModalAware.defaultProps = {
  msisdn: {
    countryCode: '',
    phoneNumber: '',
  },
};

const mapStateToProps = (state) => {
  const msisdn = state.candidate.get('profile').get('msisdn');
  return {
    msisdn: msisdn ? msisdn.toJS() : undefined,
  };
};

export default connect(mapStateToProps, { candidateProfileEditPhoneNumber })(withLocalize(CandidateProfileEditDetailsPhoneModalAware));
