import { List, Map } from 'immutable';
import {
  CANDIDATE_GET_SIMILAR_GIGS_REQUEST_SUCCESS,
  CANDIDATE_CLEAR_SIMILAR_GIGS,
  CANDIDATE_GET_GIG_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

const LAST_SEEN_SIMILAR_GIGS_SIZE = 6;

export const INITIAL_SIMILAR_GIGS = {
  lastSeenSimilarGigs: List(),
};

export default function candidateSimilarGigsReducer(state = new Map(INITIAL_SIMILAR_GIGS), action) {
  let lastSeenSimilarGigs;
  switch (action.type) {
    case CANDIDATE_GET_SIMILAR_GIGS_REQUEST_SUCCESS: {
      lastSeenSimilarGigs = state.get('lastSeenSimilarGigs');

      const gigs = action.payload;
      gigs.forEach((gig) => {
        if (lastSeenSimilarGigs.size >= LAST_SEEN_SIMILAR_GIGS_SIZE) {
          lastSeenSimilarGigs = lastSeenSimilarGigs.shift();
        }
        lastSeenSimilarGigs = lastSeenSimilarGigs.push(gig.gigId);
      });

      return state.set('lastSeenSimilarGigs', lastSeenSimilarGigs);
    }
    case CANDIDATE_GET_GIG_REQUEST_SUCCESS:
      lastSeenSimilarGigs = state.get('lastSeenSimilarGigs');
      if (lastSeenSimilarGigs.size >= LAST_SEEN_SIMILAR_GIGS_SIZE) {
        lastSeenSimilarGigs = lastSeenSimilarGigs.shift();
      }
      lastSeenSimilarGigs = lastSeenSimilarGigs.push(action.payload.gigId);
      return state.set('lastSeenSimilarGigs', lastSeenSimilarGigs);
    case CANDIDATE_CLEAR_SIMILAR_GIGS:
      return state.set('lastSeenSimilarGigs', List());
    default:
      return state;
  }
}
