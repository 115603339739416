// https://github.com/rt2zz/redux-persist-state-manager
import { Map } from 'immutable';
import { CANDIDATE_USER, COMPANY_USER } from '../../constants/userScopes';

export default {
  1: (state) => {
    // migration to set scope for existing users
    const userScope = state.auth.get('userScope');
    const userScopeSet = userScope === COMPANY_USER || userScope === CANDIDATE_USER;
    if (userScopeSet) {
      return state;
    }
    return ({
      ...state,
      auth: state.auth.set('userScope', COMPANY_USER),
    });
  },
  3: (state) => {
    const { candidate } = state;
    if (candidate === undefined || !candidate.has('profile')) {
      return state;
    }
    const profile = candidate.get('profile');
    if (profile.has('categoryValidationParams')) {
      return state;
    }
    return state.candidate.setIn(['profile', 'categoryValidationParams'], Map());
  },
};
