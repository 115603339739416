import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { getTranslationForCategoryKey } from '../../../../common/gig/gigCategoriesHelper';
import { colors, fontFamily, fontSizes } from '../../../../common/styles/base.style';
import { getTranslationForCityKey } from '../../../../common/gig/city/gigCitiesHelper';

const styles = StyleSheet.create({
  value: {
    color: colors.greyishBrown,
    fontSize: fontSizes.md,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    textAlign: 'left',
  },
  highlight: {
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.magenta,
  },
});

export const CITY_OPTION_GIGS_ANYWHERE = 'city_gigs_anywhere';

const renderTextElement = (value, prefix, highlight) => (
  <Text>
    {prefix
    && (
    <Text style={[styles.value, { fontFamily: fontFamily.SFProDisplayRegular }]}>
      {prefix}
      {' '}
    </Text>
    )}
    <Text style={[styles.value, highlight && styles.highlight]}>
      {value}
      {' '}
    </Text>
  </Text>
);

export const renderCategories = (categories, availableCategories, activeLanguage, translate) => {
  const translatedCategories = categories.map((cKey) => getTranslationForCategoryKey(availableCategories, cKey, activeLanguage));

  if (translatedCategories.length === 0) {
    return renderTextElement(translate('candidateFindGigs.allCategories'));
  }
  return renderTextElement(translatedCategories.join(', '));
};

export const renderLocalization = (cityKey, gigCities, translate) => {
  if (cityKey === CITY_OPTION_GIGS_ANYWHERE) {
    return renderTextElement(translate('candidateFindGigs.anywhere'), null, true);
  }
  return renderTextElement(getTranslationForCityKey(gigCities, cityKey), translate('candidateFindGigs.in'));
};
