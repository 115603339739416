import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, findNodeHandle } from 'react-native';
import PrimaryKeyboardAwareScrollView from './PrimaryKeyboardAwareScrollView';

const scrollOnFocus = (event, propFocus) => {
  if (this.scroll) {
    this.scroll.props.scrollToFocusedInput(findNodeHandle(event.target));
  }
  if (propFocus) {
    propFocus();
  }
};

function recursivelyAddAutoScrollToChildren(children) {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }
    const props = { ...{ className: '' }, ...child.props };
    let childProps = {};
    if (React.isValidElement(child) && !props.className.includes('exclude-node')
      && props.autoscroll) {
      childProps = {
        onFocus: (event) => {
          scrollOnFocus(event, child.props.onFocus);
        },
      };
    }
    childProps.children = recursivelyAddAutoScrollToChildren(child.props.children);
    return React.cloneElement(child, childProps);
  });
}

class AutoScrollableKeyboardAwareScrollView extends Component {
  render() {
    const {
      children, setRef, ...props
    } = this.props;
    // android scrolls to input automatically and plugin add extra height
    const childrenWithExtraProp = Platform.OS === 'ios' ? recursivelyAddAutoScrollToChildren(children) : children;

    return (
      <PrimaryKeyboardAwareScrollView
        innerRef={(ref) => {
          this.scroll = ref;
          if (setRef) { setRef(); }
        }}
        {...props}
      >
        {childrenWithExtraProp}
      </PrimaryKeyboardAwareScrollView>
    );
  }
}

AutoScrollableKeyboardAwareScrollView.propTypes = {
  children: PropTypes.node.isRequired,
  setRef: PropTypes.func,
};

AutoScrollableKeyboardAwareScrollView.defaultProps = {
  setRef: undefined,
};

export default AutoScrollableKeyboardAwareScrollView;
