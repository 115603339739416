import {
  ADMIN_SETTINGS_SET_GIG_LIMIT_REQUEST,
  ADMIN_SETTINGS_CREATE_CITY_REQUEST,
  ADMIN_SETTINGS_GET_REQUEST,
  ADMIN_SETTINGS_FORM_SUBMIT,
  ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST,
  ADMIN_SETTINGS_GET_CITIES_REQUEST,
  ADMIN_SETTINGS_UPDATE_CITY_REQUEST,
  ADMIN_SETTINGS,
  ADMIN_SETTINGS_SET_SETTING_REQUEST,
} from '../../constants/actionTypes';

export function adminSettingsChangeForm(formValues) {
  return {
    type: ADMIN_SETTINGS_FORM_SUBMIT,
    formValues,
  };
}

export function adminSetFreeGig(params) {
  console.log(`adminSetFreeGig${params}`);
  return {
    type: ADMIN_SETTINGS_SET_GIG_LIMIT_REQUEST,
    payload: {
      request: {
        method: 'put',
        url: 'admin/settings',
        data: {
          name: 'gig limit',
          value: params,
        },
      },
    },
  };
}

export function adminSetSetting(data) {
  console.log(`adminSetSetting()  ${JSON.stringify(data)}`);
  return {
    type: ADMIN_SETTINGS_SET_SETTING_REQUEST,
    payload: {
      request: {
        method: 'put',
        url: 'admin/settings',
        data,
      },
    },
  };
}

export function getAllAdminSettings() {
  console.log('adminGetAllSettings');
  return {
    type: ADMIN_SETTINGS_GET_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/settings',
      },
    },
  };
}

export function getAdminSettingGigLimit() {
  console.log('getAdminSettingGigLimit');
  return {
    type: ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/settings/gig limit',
      },
    },
  };
}

export function createAdminCity(params) {
  console.log(`createAdminCity${JSON.stringify(params)}`);
  return {
    type: ADMIN_SETTINGS_CREATE_CITY_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'admin/city',
        data: params,
      },
    },
  };
}

export function getAdminCityList() {
  console.log('getAdminCityList');
  return {
    type: ADMIN_SETTINGS_GET_CITIES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/city?page=1&pageSize=100',
      },
    },
  };
}

export function updateAdminCity(params) {
  console.log('updateAdminCity');
  return {
    type: ADMIN_SETTINGS_UPDATE_CITY_REQUEST,
    payload: {
      request: {
        method: 'put',
        url: 'admin/city',
        data: params,
      },
    },
  };
}

export function resetAdminValues() {
  console.log('resetAdminValues');
  return {
    type: ADMIN_SETTINGS,
  };
}
