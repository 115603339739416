import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import CompanyCandidatesPoolShiftsScreen from './CompanyCandidatesPoolShiftsScreen';
import { getShifts } from '../../shifts/companyShiftsActions';
import {
  COMPANY_CANDIDATES_POOL_SHIFTS, COMPANY_CANDIDATES_POOL_SHIFTS_OPEN, COMPANY_CANDIDATES_POOL_SHIFTS_CLOSED, COMPANY_CANDIDATES_POOL_SHIFTS_ARCHIVED,
} from '../../../constants/routes';
import Routing from '../../../core/routing/index';
import Container from '../../../common/components/Container';
import {
  activityIndicator, colors, dynamicSpacings, spacings,
} from '../../../common/styles/base.style';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import { SHIFT_TABS } from './shiftsConstants';

const { Route, Redirect, withRouter } = Routing;

const styles = StyleSheet.create({
  shiftTypesButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: spacings.md,
    backgroundColor: colors.ultraLightGrey,
    paddingHorizontal: dynamicSpacings.md,
  },
});

class CompanyCandidatesPoolShiftsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shifts: [], inProgress: true, activeTab: SHIFT_TABS.OPEN };
  }

  componentDidMount() {
    switch (this.props.location.pathname) {
      case COMPANY_CANDIDATES_POOL_SHIFTS:
      case COMPANY_CANDIDATES_POOL_SHIFTS_OPEN: {
        this.fetchShifts(false);
        break;
      }
      case COMPANY_CANDIDATES_POOL_SHIFTS_CLOSED: {
        this.fetchShifts(true);
        break;
      }
      case COMPANY_CANDIDATES_POOL_SHIFTS_ARCHIVED: {
        this.fetchShifts(true, true);
        break;
      }
      default:
    }
  }

  fetchShifts = (closed, closedManually = false) => {
    if (closedManually) {
      this.setState({ inProgress: true, activeTab: SHIFT_TABS.ARCHIVED });
      this.props.history.replace(COMPANY_CANDIDATES_POOL_SHIFTS_ARCHIVED);
    } else {
      this.setState({ inProgress: true, activeTab: closed ? SHIFT_TABS.CLOSED : SHIFT_TABS.OPEN });
      this.props.history.replace(closed ? COMPANY_CANDIDATES_POOL_SHIFTS_CLOSED : COMPANY_CANDIDATES_POOL_SHIFTS_OPEN);
    }
    this.props.getShifts({ closed, closedManually }).then((res) => {
      this.setState({ shifts: res.payload, inProgress: false });
    });
  };

  render() {
    const { translate } = this.props;
    const { activeTab, inProgress } = this.state;

    return (
      <Container>
        <Route exact path={COMPANY_CANDIDATES_POOL_SHIFTS} render={() => <Redirect to={COMPANY_CANDIDATES_POOL_SHIFTS_OPEN} />} />
        <View style={styles.shiftTypesButtonsContainer}>
          <Button
            title={translate('company.candidatesPool.shifts.openShifts')}
            type={activeTab === SHIFT_TABS.OPEN ? BUTTON_TYPES.PRIMARY_RED : BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.FLEX_SM}
            onPress={() => this.fetchShifts(false)}
          />
          <Button
            title={translate('company.candidatesPool.shifts.closedShifts')}
            type={activeTab === SHIFT_TABS.CLOSED ? BUTTON_TYPES.PRIMARY_RED : BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.FLEX_SM}
            onPress={() => this.fetchShifts(true)}
          />
          <Button
            title={translate('company.candidatesPool.shifts.archivedShifts')}
            type={activeTab === SHIFT_TABS.ARCHIVED ? BUTTON_TYPES.PRIMARY_RED : BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.FLEX_SM}
            onPress={() => this.fetchShifts(true, true)}
          />
        </View>
        <Container>
          {inProgress && (
            <ActivityIndicator size="large" style={{ ...activityIndicator, backgroundColor: colors.smokeWhite }} />
          )}
          {!inProgress && (
            <CompanyCandidatesPoolShiftsScreen {...this.state} />
          )}
        </Container>
      </Container>
    );
  }
}

CompanyCandidatesPoolShiftsContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  getShifts: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default connect(null, { getShifts })(withLocalize(withRouter(CompanyCandidatesPoolShiftsContainer)));
