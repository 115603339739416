export const PROVIDER_ENUM = {
  LETSGIG: 'LETSGIG',
  AMS: 'AMS',
  SP: 'SP',
  WORKBUSTER: 'WORKBUSTER',
  ZEROLIME: 'ZEROLIME',
};

export const PROVIDER_CONFIG = {
  [PROVIDER_ENUM.LETSGIG]: {
    nameFnc: (gig) => gig.companyName,
  },
  [PROVIDER_ENUM.AMS]: {
    nameFnc: (gig) => gig.companyName,
  },
  [PROVIDER_ENUM.ZEROLIME]: {
    nameFnc: (gig) => gig.companyName,
  },
  [PROVIDER_ENUM.WORKBUSTER]: {
    nameFnc: (gig) => gig.companyName,
  },
};
