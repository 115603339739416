import { List, Map } from 'immutable';
import {
  ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS,
  ASSOCIATIONS_RESET_STATUS
} from '../constants/actionTypes';

const INITIAL_ASSOCIATION_STATE = {
  associationsList: List(),
};

export default function associationssReducer(state = new Map(INITIAL_ASSOCIATION_STATE), action) {
  switch (action.type) {
    case ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS:
        return state.set('associationsList', action.payload.associations);
    case ASSOCIATIONS_RESET_STATUS:
        return state.set('associationsList', []);
    default:
      return state;
  }
}
