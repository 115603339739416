import { fromJS, List, Map } from 'immutable';
import { ADMIN_COMPANY_FIND_COMPANY_REQUEST_SUCCESS, ADMIN_COMPANY_FIND_FILTER_FORM_SUBMIT } from '../../constants/actionTypes';

const INITIAL_ADMIN_COMPANY_FILTERS = {
  formValues: fromJS({
    providerGigId: '',
    gigId: '',
    category: undefined,
    city: undefined,
    gigProvider: undefined,
    companyName: '',
    availability: undefined,
    limit: '100',
    page: '1',
    registerNumber: '',
  }),
  adminCandidatesList: List(),
  adminCandidatesPageInfo: Map(),
  adminCompanyList: List(),
  adminGigsList: List(),
  adminGigsPageInfo: Map(),
  adminCompanyPageInfo: Map(),
  gigDetails: Map(),
};

export default function adminCompaniesCandidatesReducer(state = new Map(INITIAL_ADMIN_COMPANY_FILTERS), action) {
  switch (action.type) {
    case ADMIN_COMPANY_FIND_FILTER_FORM_SUBMIT:
      return state.set('formValues', fromJS(action.formValues));
    case ADMIN_COMPANY_FIND_COMPANY_REQUEST_SUCCESS:
      return state.set('adminCompanyList', fromJS(action.payload.docs)).set(
        'adminCompanyPageInfo',
        fromJS({
          ...action.payload,
          docs: undefined,
        })
      );
    default:
      return state;
  }
}
