import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../../core/routing/index';
import PricingPlansScreen from './PricingPlansScreen';
import { getCardData, getPricingPlans, saveSelectedPricingPlan, subscribe, getBillingProfile } from '../companyPaymentsActions';
import { COMPANY_PAYMENTS_BILLING_PROFILE_NEW, COMPANY_PAYMENTS_CARD_NEW, COMPANY_PAYMENTS_PRICING_PLANS_INITIAL } from '../../../constants/routes';
import { successNotification, infoNotification } from '../../../common/notifications_center/notificationCenterActions';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../constants/subscriptionTypes';

const { withRouter } = Routing;

class PricingPlansContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { plans: null };
    this.firstChoice = this.props.location.pathname === COMPANY_PAYMENTS_PRICING_PLANS_INITIAL;
  }

  componentDidMount() {
    this.props.getPricingPlans().then(({ payload, isError }) => {
      if (!isError) {
        this.setState({ plans: payload.plans });
      }
    });
  }

  onChoosePlan = (selectedPricingPlanId) => {
    this.props.saveSelectedPricingPlan(selectedPricingPlanId);
    this.props.getCardData().then(({ isError: cardDataError, payload: cardDataPayload }) => {
      if (!cardDataError) {
        if (!cardDataPayload) {
          this.props.history.replace(COMPANY_PAYMENTS_CARD_NEW);
        } else {
          this.props.getBillingProfile().then(({ isError: billingError, payload: billingPayload }) => {
            if (!billingError) {
              if (!billingPayload || !billingPayload.address) {
                this.props.history.replace(COMPANY_PAYMENTS_BILLING_PROFILE_NEW);
              } else {
                this.props.subscribe(selectedPricingPlanId).then(({ isError, payload }) => {
                  if (!isError) {
                    if (payload.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE) {
                      this.props.successNotification('company.payments.subscription.subscribe.successTitle');
                    } else {
                      this.props.infoNotification(
                        'company.payments.subscription.subscribe.pendingTitle',
                        'company.payments.subscription.subscribe.pendingText'
                      );
                    }
                    this.goBack();
                  }
                });
              }
            }
          });
        }
      }
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <PricingPlansScreen
        handleBack={this.goBack}
        plans={this.state.plans}
        onChoosePlan={this.onChoosePlan}
        selectedPlanId={this.firstChoice ? this.props.selectedPricingPlanId : undefined}
      />
    );
  }
}

PricingPlansContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getPricingPlans: PropTypes.func.isRequired,
  getCardData: PropTypes.func.isRequired,
  getBillingProfile: PropTypes.func.isRequired,
  saveSelectedPricingPlan: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  infoNotification: PropTypes.func.isRequired,
  selectedPricingPlanId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PricingPlansContainer.defaultProps = {
  selectedPricingPlanId: undefined,
  location: null,
};

const mapStateToProps = (state) => ({
  selectedPricingPlanId: state.company.get('payments').get('selectedPricingPlanId'),
});

export default connect(mapStateToProps, {
  getPricingPlans,
  saveSelectedPricingPlan,
  subscribe,
  getCardData,
  getBillingProfile,
  successNotification,
  infoNotification,
})(withRouter(PricingPlansContainer));
