import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';
import queryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  colors, fontFamily, spacings, fontSizes,
} from '../../../common/styles/base.style';
import Touchable from '../../../common/components/Touchable';
import Routing from '../../../core/routing/index';
import { stylePropTypes } from '../../../constants/propTypes';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  label: {
    paddingVertical: spacings.xxs,
    flexDirection: 'row',
    paddingLeft: spacings.sm,
  },
  shape: {
    height: 22,
    borderRadius: 11,
    backgroundColor: colors.magenta,
    paddingHorizontal: spacings.xs,
    justifyContent: 'center',
  },
  shapeText: {
    color: colors.white,
    fontSize: fontSizes.xs,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    textAlign: 'center',
    minWidth: 8,
  },
  text: {
    fontSize: fontSizes.md,
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.greyishBrown,
    marginLeft: spacings.sm,
  },
});

class ListItemBadge extends Component {
  goToMyGigsTab = ({ route, tabIndex }) => {
    this.props.history.push(`${route}?${queryString.stringify({ tabIndex })}`);
  };

  render() {
    const {
      value, label, routeConfig, style,
    } = this.props;
    const shouldBeGrayedOut = value === 0;

    return (
      <Touchable
        style={[styles.base, style]}
        activeOpacity={0.5}
        onPress={() => this.goToMyGigsTab(routeConfig)}
      >
        <View style={styles.label}>
          <View style={[styles.shape, shouldBeGrayedOut && { backgroundColor: colors.veryLightPink }]}>
            <Text style={styles.shapeText}>{value}</Text>
          </View>
          <Text style={[styles.text, shouldBeGrayedOut && { color: colors.veryLightPink }]}>{label}</Text>
        </View>
      </Touchable>
    );
  }
}

ListItemBadge.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  routeConfig: PropTypes.shape({
    tabIndex: PropTypes.number,
    route: PropTypes.string.isRequired,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  style: stylePropTypes,
};

ListItemBadge.defaultProps = {
  style: undefined,
};

export default withRouter(ListItemBadge);
