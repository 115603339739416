import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { createGroup, modifyGroup, getGroup } from '../candidatesPoolGroupsActions';
import { get } from '../../companyCandidatesPoolActions';
import CompanyCandidatesPoolGroupEditableScreen from './CompanyCandidatesPoolGroupEditableScreen';
import Routing from '../../../../core/routing/index';
import { redirectToErrorScreen } from '../../../../constants/routes';

const { withRouter } = Routing;

class CompanyCandidatesPoolGroupEditableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: this.props.match.params.groupId != null };
  }

  componentDidMount() {
    this.props.get().then((res) => {
      if (!res.isError) {
        const availablePoolCandidates = res.payload.filter((item) => item.status === 'ACTIVE').map((item) => ({
          label: `${item.candidate.firstName} ${item.candidate.lastName}`,
          value: item.candidate.userId,
        }));
        if (!this.state.isEdit) {
          this.setState({ availablePoolCandidates });
        } else {
          this.props.getGroup(this.props.match.params.groupId).then((res2) => {
            if (!res2.isError) {
              this.setState({ poolGroup: res2.payload, availablePoolCandidates });
            } else if (res2 && res2.status === 404) {
              redirectToErrorScreen(this.props.history, 'errors.notFoundTitle', 'errors.notFoundText');
            }
          });
        }
      }
    });
  }

  handleSubmit = ({ name, description, candidates }, { setStatus }) => {
    if (this.state.isEdit) {
      this.props.modifyGroup(this.state.poolGroup.groupId, { name, description, candidates }).then(() => {
        setStatus({ redirect: true });
      });
    } else {
      this.props.createGroup(name, description, candidates).then(() => {
        setStatus({ redirect: true });
      });
    }
  };

  render() {
    return (
      <CompanyCandidatesPoolGroupEditableScreen
        handleSubmit={this.handleSubmit}
        isEdit={this.state.isEdit}
        poolGroup={this.state.poolGroup}
        availablePoolCandidates={this.state.availablePoolCandidates}
      />
    );
  }
}

CompanyCandidatesPoolGroupEditableContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  createGroup: PropTypes.func.isRequired,
  modifyGroup: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
};

export default connect(null, {
  createGroup, modifyGroup, getGroup, get,
})(withRouter(CompanyCandidatesPoolGroupEditableContainer));
