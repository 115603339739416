import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native';
import NavItem from './NavItem';
import { fontFamily, colors, fontSizes } from '../../styles/base.style';
import { font } from '../../styles/mixins';

const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
    width: 20,
    height: 20,
    right: 4,
    top: 9,
    backgroundColor: colors.seablue,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxxs, -0.5, colors.white),
  },
});

class NavItemWithIndicator extends Component {
  renderExtras = () => {
    if (this.props.showSthNewIndicator) {
      return <View style={styles.bubble}><Text style={styles.text}>{this.props.indicatorText || '!'}</Text></View>;
    }
    return null;
  };

  render() {
    return (
      <NavItem
        {...this.props}
        renderExtras={this.renderExtras}
        renderExtrasData={this.props.showSthNewIndicator}
      />
    );
  }
}

NavItemWithIndicator.propTypes = {
  showSthNewIndicator: PropTypes.bool,
  indicatorText: PropTypes.string,
};

NavItemWithIndicator.defaultProps = {
  showSthNewIndicator: false,
  indicatorText: null,
};

export default NavItemWithIndicator;
