import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { questionnairePropType } from '../../constants/propTypes';
import { spacings } from '../styles/base.style';
import PrimaryText from '../components/PrimaryText';
import QuestionnaireFormRow from './QuestionnaireFormRow';
import Button, { BUTTON_SIZES } from '../components/buttons/Button';

const styles = StyleSheet.create({
  description: {
    marginTop: spacings.md,
  },
  buttonWrapper: {
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },
});

const QuestionnaireForm = ({
  questionnaire, handleAddQuestion, handleRemoveQuestion, handleUpdateQuestion, formDescription, btnTitle,
}) => (
  <View>
    <PrimaryText style={styles.description}>{formDescription}</PrimaryText>
    {
      questionnaire && questionnaire.map((questionItem) => (
        <QuestionnaireFormRow
          questionItem={questionItem}
          handleRemove={handleRemoveQuestion}
          handleOnChange={handleUpdateQuestion}
          key={questionItem.questionId}
        />
      ))
    }
    <Button
      style={{ wrapper: styles.buttonWrapper }}
      title={btnTitle}
      onPress={() => handleAddQuestion()}
      size={BUTTON_SIZES.MD}
      disabled={questionnaire && questionnaire.filter((questionItem) => !questionItem.text).length > 0}
    />
  </View>
);

QuestionnaireForm.propTypes = {
  questionnaire: PropTypes.arrayOf(questionnairePropType).isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  handleUpdateQuestion: PropTypes.func.isRequired,
  formDescription: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
};

export default withLocalize(QuestionnaireForm);
