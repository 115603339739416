import React from 'react';
import { Translate } from 'react-localize-redux';
import { StyleSheet, Text } from 'react-native';
import { fontFamily, fontSizes, spacings } from '../styles/base.style';
import { font } from '../styles/mixins';

const styles = StyleSheet.create({
  sessionTimedOut: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm),
    textAlign: 'center',
    marginTop: spacings.xs,
  },
});

const SessionTimedOutText = () => (
  <Text style={styles.sessionTimedOut}><Translate id="loginScreen.sessionTimedOut" /></Text>
);

SessionTimedOutText.propTypes = {};

export default SessionTimedOutText;
