import { List, Map, fromJS } from 'immutable';
import {
  SHIFT_CREATOR_CLEAR,
  SHIFT_CREATOR_SET_SALARY,
  SHIFT_CREATOR_USE_TEMPLATE,
  SHIFT_CREATOR_SET_POSITION,
  SHIFT_CREATOR_SET_LOCATION,
  SHIFT_CREATOR_SET_DESCRIPTION,
  SHIFT_CREATOR_SET_DATE_AND_TIMES,
  SHIFT_CREATOR_SET_BOOK_SEPARATELY,
  SHIFT_CREATOR_SET_ACCESS_ENTITIES,
  SHIFT_CREATOR_SET_SAVE_AS_TEMPLATE,
  SHIFT_CREATOR_SET_CANDIDATES_NUMBER,
} from '../../../constants/actionTypes';

export const INITIAL_NEW_SHIFT_DATA_STATE = {
  shift: Map({
    position: '',
    description: '',
    candidatesNumber: 1,
    location: Map({
      cityV2: Map({
        key: 'stockholm',
      }),
      street: '',
      place: '',
    }),
    accessEntities: Map({
      teams: List(),
    }),
    shiftDatesAndTimes: List(),
    shiftSalary: Map({
      salary: '',
      holidayPay: false,
      obPayment: Map({
        applicable: false,
        periods: List(),
      }),
    }),
    bookSeparately: false,
    saveAsTemplate: false,
  }),
};

export default function gigCreatorReducer(state = new Map(INITIAL_NEW_SHIFT_DATA_STATE), action) {
  switch (action.type) {
    case SHIFT_CREATOR_CLEAR:
      return new Map(INITIAL_NEW_SHIFT_DATA_STATE);
    case SHIFT_CREATOR_USE_TEMPLATE:
      return state.set('shift', fromJS(action.template))
        .setIn(['shift', 'shiftDatesAndTimes'], fromJS([]))
        .setIn(['shift', 'saveAsTemplate'], fromJS(false))
        .setIn(['shift', 'bookSeparately'], fromJS(false));
    case SHIFT_CREATOR_SET_POSITION:
      return state.setIn(['shift', 'position'], action.position);
    case SHIFT_CREATOR_SET_DESCRIPTION:
      return state.setIn(['shift', 'description'], action.description);
    case SHIFT_CREATOR_SET_CANDIDATES_NUMBER:
      return state.setIn(['shift', 'candidatesNumber'], action.candidatesNumber);
    case SHIFT_CREATOR_SET_LOCATION:
      return state.setIn(['shift', 'location'], fromJS({ ...action.location }));
    case SHIFT_CREATOR_SET_ACCESS_ENTITIES:
      return state.setIn(['shift', 'accessEntities'], fromJS(action.accessEntities));
    case SHIFT_CREATOR_SET_DATE_AND_TIMES:
      return state.setIn(['shift', 'shiftDatesAndTimes'], fromJS(action.shiftDatesAndTimes));
    case SHIFT_CREATOR_SET_BOOK_SEPARATELY:
      return state.setIn(['shift', 'bookSeparately'], fromJS(action.bookSeparately));
    case SHIFT_CREATOR_SET_SAVE_AS_TEMPLATE:
      return state.setIn(['shift', 'saveAsTemplate'], fromJS(action.saveAsTemplate));
    case SHIFT_CREATOR_SET_SALARY:
      return state.setIn(['shift', 'shiftSalary'], fromJS(action.shiftSalary));
    default:
      return state;
  }
}
