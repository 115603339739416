import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import EditableField from '../../../../common/components/EditableField';
import { candidateProfileSetLanguages } from '../../CandidateProfileActions';
import { activeLanguagePropTypes, languagePropType } from '../../../../constants/propTypes';
import getWorldLanguagesListFor, { getLanguageNameFor } from '../../../../core/localization/languagesListProvider';
import LanguagesForm from '../../../../common/languages/LanguagesForm';
import ModalFooter from '../../../../common/components/modal/ModalFooter';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import { LANGUAGE_LEVEL } from '../../../../constants/languageTypes';

class EditableFieldLanguagesModalAware extends Component {
  modalRefreshable = false;

  worldLanguagesList = getWorldLanguagesListFor(this.props.activeLanguage.code);

  constructor(props) {
    super(props);
    this.state = { selectedLanguages: this.props.languages };
  }

  componentDidUpdate() {
    if (this.modalRefreshable) {
      this.openModal();
    }
  }

  getFirstNotUsedLanguage = () => {
    const usedLanguagesCode = this.state.selectedLanguages.map((l) => l.code);
    return this.worldLanguagesList.find((i) => !usedLanguagesCode.includes(i.code));
  };

  getLanguageKey = (language) => (language ? language.code : null);

  handleAddLanguage = () => {
    const firstNotUsedLanguage = this.getFirstNotUsedLanguage();
    const newLanguage = { code: (firstNotUsedLanguage && firstNotUsedLanguage.code) || '', level: LANGUAGE_LEVEL.NATIVE };
    this.setState({ selectedLanguages: this.state.selectedLanguages.concat(newLanguage) });
  };

  handleRemoveLanguage = (key) => {
    this.setState({ selectedLanguages: this.state.selectedLanguages.filter((lang) => lang.code !== key) });
  };

  handleUpdateLanguage = (oldItem, changedLanguage) => {
    const oldLanguageKey = this.getLanguageKey(oldItem);
    const currentLanguages = this.state.selectedLanguages;
    for (let i = 0; i < currentLanguages.length; i += 1) {
      if (this.getLanguageKey(currentLanguages[i]) === oldLanguageKey) {
        currentLanguages[i] = changedLanguage;
        this.setState({ selectedLanguages: currentLanguages });
        break;
      }
    }
  };

  handleSubmit = (values) => {
    this.props.candidateProfileSetLanguages(values).then((response) => {
      if (!response.isError) {
        this.modalRefreshable = false;
        this.props.closeModal();
      }
    });
  };

  openModal = () => {
    const { translate, closeModal } = this.props;
    this.modalRefreshable = true;

    this.props.setModalContent(
      translate('candidateProfile.details.languages'),
      <>
        <LanguagesForm
          languages={this.state.selectedLanguages}
          handleAddLanguage={this.handleAddLanguage}
          handleRemoveLanguage={this.handleRemoveLanguage}
          handleUpdateLanguage={this.handleUpdateLanguage}
          formDescription={translate('candidateProfile.languages.label')}
          btnTitle={translate('candidateProfile.languages.addLanguageBtn')}
        />
        <ModalFooter>
          <Button
            size={BUTTON_SIZES.FLEX_MD}
            type={BUTTON_TYPES.SECONDARY}
            title={translate('generic.cancel')}
            onPress={() => {
              this.modalRefreshable = false;
              this.setState({ selectedLanguages: this.props.languages });
              closeModal();
            }}
          />
          <Button
            size={BUTTON_SIZES.FLEX_MD}
            title={translate('generic.save')}
            disabled={this.state.selectedLanguages.length === 0}
            onPress={() => this.handleSubmit(this.state.selectedLanguages)}
          />
        </ModalFooter>
      </>,
    );
  };

  render() {
    const { translate, activeLanguage, languages } = this.props;
    return (
      <EditableField
        label={translate('candidateProfile.details.languages')}
        value={languages.map((lang) => `${getLanguageNameFor(lang.code, activeLanguage.code)} (${translate(`languageLevel.${lang.level}`)})`).join(', ')}
        placeholder={translate('candidateProfile.details.languagesPlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

EditableFieldLanguagesModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  languages: PropTypes.arrayOf(languagePropType).isRequired,
  setModalContent: PropTypes.func.isRequired,
  candidateProfileSetLanguages: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  languages: state.candidate.get('profile').get('languages').toJS(),
});

export default connect(mapStateToProps, { candidateProfileSetLanguages })(withLocalize(EditableFieldLanguagesModalAware));
