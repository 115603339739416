export default function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 *
 * @param obj
 * @param path has to be in dot notation eg. "test.name"
 * @returns {*}
 */
export const getValueByPath = (obj, path) => {
  if (path.indexOf('.') !== -1) {
    return path.split('.').reduce((o, key) => (o && o[key] ? o[key] : null), obj);
  }
  return obj[path];
};

/**
 * This is meant for checking array of responses returned by Promise.all
 */
export const anyErrorsInResponses = (responses) => responses.reduce((anyError, response) => anyError || response.isError, false);
