import { List, Map } from 'immutable';
import {
  COMPANY_CANDIDATES_POOL_STORE_SCROLL_POSITION,
  COMPANY_CANDIDATES_POOL_GROUPS_STORE_SCROLL_POSITION,
  COMPANY_CANDIDATES_POOL_GROUP_MEMBERS_STORE_SCROLL_POSITION,
  COMPANY_CANDIDATES_POOL_SET_NEW_CONTENT,
  COMPANY_CANDIDATES_POOL_CLEAR_NEW_CONTENT,
} from '../../constants/actionTypes';

export const COMPANY_POOLS_CONTENT_FIELDS = {
  MY_POOL: 'MY_POOL',
  GROUPS: 'GROUPS',
  SHIFTS: 'SHIFTS',
};

const INITIAL_MAP = {
  candidatesPoolScrollPosition: 0,
  candidatesPoolGroupsScrollPosition: 0,
  candidatesPoolGroupMembersScrollPosition: 0,
  newContent: Map({
    [COMPANY_POOLS_CONTENT_FIELDS.GROUPS]: List(),
    [COMPANY_POOLS_CONTENT_FIELDS.MY_POOL]: List(),
    [COMPANY_POOLS_CONTENT_FIELDS.SHIFTS]: List(),
  }),
};

export default function companyCandidatesPoolReducer(state = new Map(INITIAL_MAP), action) {
  switch (action.type) {
    case COMPANY_CANDIDATES_POOL_STORE_SCROLL_POSITION:
      return state.set('candidatesPoolScrollPosition', action.scrollPosition);
    case COMPANY_CANDIDATES_POOL_GROUPS_STORE_SCROLL_POSITION:
      return state.set('candidatesPoolGroupsScrollPosition', action.scrollPosition);
    case COMPANY_CANDIDATES_POOL_GROUP_MEMBERS_STORE_SCROLL_POSITION:
      return state.set('candidatesPoolGroupMembersScrollPosition', action.scrollPosition);
    case COMPANY_CANDIDATES_POOL_SET_NEW_CONTENT: {
      const { field, value } = action;
      return state.setIn(['newContent', field], state.get('newContent').get(field).concat(value));
    }
    case COMPANY_CANDIDATES_POOL_CLEAR_NEW_CONTENT:
      return state.setIn(['newContent', action.field], List());
    default:
      return state;
  }
}
