export const ACTIONS = {
  USER: {
    LOGIN: 'Login',
    LOGIN_WITH_FACEBOOK: 'Login with facebook',
    LOGOUT: 'Logout',
    REQUEST_RESET_PASSWORD: 'Request reset password',
    SET_NEW_PASSWORD: 'Set new password',
    UPDATE_NOTIFICATION_SETTINGS: 'Update notification settings',
    DISABLED_NOTIFICATIONS: 'Disable notifications',
    UPDATE_FEED_NOTIFICATION_SETTINGS: 'Update feed notification settings',
    DELETE_ACCOUT: 'Delete account',
    NOTIFICATION_RECEIVED: 'Notification received',
    WELCOME_CAROUSEL_SHOW: 'Show welcome carousel',
    WELCOME_CAROUSEL_SHOW_MORE: 'Show welcome carousel - more',
  },
  CANDIDATE: {
    SIGN_UP: 'Sign up',
    UPDATE_AVAILABILITY: 'Update Availability',
    UPDATE_FULL_NAME: 'Update full name',
    UPDATE_EMAIL: 'Update email address',
    UPDATE_AVAILABILITY_DETAILS: 'Update availability details',
    UPDATE_LOCATION: 'Update location',
    UPDATE_HOME_LOCATION: 'Update home location',
    UPDATE_CATEGORY: 'Update category',
    UPDATE_CURRENT_OCCUPATION: 'Update current occupation',
    UPDATE_EDUCATION: 'Update education',
    UPDATE_EXPERIENCE: 'Update experience',
    UPDATE_ABOUT_ME: 'Update about me',
    UPDATE_MSISDN: 'Update msisdn',
    UPDATE_PERSONAL_NUMBER: 'Update personal number',
    UPDATE_CLEARING_NUMBER: 'Update clearing number',
    UPDATE_BANK_ACCOUNT_NUMBER: 'Update bank account number',
    UPDATE_ASSOCIATION: 'Update Association',
    UPDATE_LANGUAGES: 'Update languages',
    COMPLETE_PROFILE: {
      WELCOME: 'Complete profile flow - Welcome',
      AVAILABILITY: 'Complete profile flow - Availability',
      CATEGORY: 'Complete profile flow - Category',
      CURRENT_OCCUPATION: 'Complete profile flow - Current occupation',
      EDUCATION: 'Complete profile flow - Education',
      EXPERIENCE: 'Complete profile flow - Experience',
      HOME_LOCATION: 'Complete profile flow - Home location',
      LANGUAGE: 'Complete profile flow - Language',
      PREFERRED_LOCATION: 'Complete profile flow - Preferred location',
      SUCCESS: 'Complete profile flow - Success',
    },
    FIND_GIGS: {
      FIND_GIGS: 'Find Gigs',
      FIND_GIGS_FAVOURITES: 'Find Gigs - Favourites',
      FIND_GIGS_WITH_TEXT_SEARCH: 'Find Gigs - With text search',
      FIND_GIGS_FETCH_MORE: 'Find Gigs - Fetch more',
    },
    MY_POOLS: {
      ACCEPT_INVITATION: 'My pools - accept invitation',
      REJECT_INVITATION: 'My pools - reject invitation',
      SIGN_OFF: 'My pools - sing off',
      GROUPS: {
        SIGN_OFF: 'My pools - group - sing off',
      },
      CHAT: {
        OPEN_GROUP_CHAT: 'My pool - Chat - Open group chat',
        OPEN_DIRECT_CHAT: 'My pool - Chat - Open direct chat',
      },
    },
    SHIFT: {
      VIEW_DETAILS: 'Candidate - View shift details',
      SHARE: 'Candidate - Share shift',
      CANDIDATE_CLICK_BOOK: 'Candidate - Booking a shift',
    },
  },
  COMPANY: {
    AUTH: {
      SIGN_UP: 'Sign up (contact)',
      AUTO_CREATE_SIGN_UP: 'Autocreate sign up',
    },
    UPDATE_PROFILE: 'Update profile',
    CREATE_GIG: 'Create gig',
    CHECK_GIG_LIMIT: 'Check Gig Limit',
    UPDATE_GIG: 'Update gig',
    CREATE_GIG_FLOW: {
      WELCOME_SCREEN: 'Create gig flow - Welcome screen',
      STEPS: 'Create gig flow - Steps',
      AVAILABILITY: 'Create gig flow - Availability',
      CATEGORY: 'Create gig flow - Category',
      COMPENSATION: 'Create gig flow - Compensation',
      DATES: 'Create gig flow - Dates',
      DESCRIPTION: 'Create gig flow - Description',
      EXPERIENCE: 'Create gig flow - Experience',
      INFO: 'Create gig flow - Info',
      LANGUAGES: 'Create gig flow - Languages',
      LOCATION: 'Create gig flow - Location',
      PREFERRED_OCCUPATION: 'Create gig flow - Preferred occupation',
      PHOTO: 'Create gig flow - Photo',
      QUESTIONNAIRE: 'Create gig flow - Questionnaire',
      PREVIEW: 'Create gig flow - Preview',
    },
    CANDIDATE_POOLS: {
      INVITE: 'Candidates Pool - Invite',
      REMOVE: 'Candidates Pool - Remove',
      MY_POOL: 'Candidates Pool - Show pool',
      VIEW_GROUPS: 'Candidates Pool - View groups',
      CREATE_GROUP: 'Candidates Pool - Create group',
      VIEW_GROUP: 'Candidates Pool - View group details',
      MODIFY_GROUP: 'Candidates Pool - Modify group',
      DELETE_GROUP: 'Candidates Pool - Delete group',
      GET_POOL_CANDIDATE_PROFILE: 'Candidates Pool - View pool candidate profile',
      CHAT: {
        OPEN_GROUP_CHAT: 'Candidates Pool - Chat - Open group chat',
        OPEN_DIRECT_CHAT: 'Candidates Pool - Chat - Open direct chat',
      },
    },
    SHIFTS: {
      CREATE: 'Shifts - Create shift',
    },
  },
  GIG: {
    VIEW_DETAILS: 'View details',
    SHARE: 'Share',
    CANDIDATE_OPEN_CHAT: 'Candidate - Open chat',
    CANDIDATE_CLICK_APPLY: 'Candidate - Click on apply',
    CANDIDATE_APPROVE_SHORTLISTING: 'Candidate - Approve shortlisting',
    CANDIDATE_ACCEPT_OFFER: 'Candidate - Accept offer',
    CANDIDATE_REJECT: 'Candidate - Reject',
    CANDIDATE_APPLY: 'Candidate - Apply',
    COMPANY_OPEN_CHAT: 'Company - Open chat',
    UPDATE: 'Update',
    COMPANY_SUGGEST_CANDIDATES: 'Company - suggest candidates',
    COMPANY_EXCLUDE_CANDIDATE: 'Company - exclude candidate from suggested',
    COMPANY_SHORTLIST: 'Company - shortlist candidate',
    COMPANY_SEND_OFFER: 'Company - send offer to candidate',
    COMPANY_HIRE_OWN: 'Company - hire own',
    COMPANY_HIRE_LG: 'Company - hire LG',
    COMPANY_REJECT_CANDIDATE: 'Company - reject candidate',
  },
  ERROR: {
    FACEBOOK_INIT: 'FACEBOOK_INIT',
    FACEBOOK_AUTH: 'FACEBOOK_AUTH',
  },
};
