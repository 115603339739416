import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { gigPropType } from '../../../constants/propTypes';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../common/components/header/ScreenHeader';
import GigDetails from '../../../common/gig/GigDetails';
import CompanyGigActionSheet from '../CompanyGigActionSheet';

class CompanyGigDetailsScreen extends Component {
  openActionSheet = () => {
    this.openActionSheet();
  };

  registerOpenActionSheet = (openActionSheet) => {
    this.openActionSheet = openActionSheet;
  };

  render() {
    const { gig, translate } = this.props;
    if (!gig) {
      return null;
    }

    return (
      <>
        <ScreenHeader
          title={translate('gigPreview.header')}
          showBackArrow
          rightButton={RIGHT_BUTTONS.MENU}
          handlerForRightButton={this.openActionSheet}
        />
        <GigDetails gig={gig} translationKey="company.gigPreview" />
        <CompanyGigActionSheet
          gig={this.props.gig}
          registerOpenActionSheetFunction={this.registerOpenActionSheet}
          postCloseCallback={this.props.postCloseCallback}
        />
      </>
    );
  }
}

CompanyGigDetailsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  postCloseCallback: PropTypes.func.isRequired,
  gig: gigPropType,
};

CompanyGigDetailsScreen.defaultProps = {
  gig: null,
};

export default withLocalize(CompanyGigDetailsScreen);
