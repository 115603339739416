import React, { Component } from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import PrimaryModal from '../../../common/components/modal/PrimaryModal';
import ModalFooter from '../../../common/components/modal/ModalFooter';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import { filledOutQuestionnairePropType } from '../../../constants/propTypes';
import FilledOutQuestionnaireScreen from '../../../common/gig/questionnaire/FilledOutQuestionnaireScreen';
import { isQuestionnaireFilledOut } from './CandidateQuestionnaireValidation';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import KeyboardAwareShowView from '../../../common/components/KeyboardAwareShowView';
import ModalKeyboardAwareScrollView from '../../../common/keyboard/ModalKeyboardAwareScrollView';

const styles = StyleSheet.create({
  subheader: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xxs, null, colors.greyishBrown),
    marginBottom: spacings.sm,
    alignItems: 'center',
    textAlign: 'center',
  },
});

class CandidateQuestionnaireModalEdit extends Component {
  renderForm() {
    const { questionnaire, handleUpdateAnswer } = this.props;
    if (!questionnaire) {
      return null;
    }
    return (<FilledOutQuestionnaireScreen questionnaire={questionnaire} isEdit handleUpdateAnswer={handleUpdateAnswer} />);
  }

  renderSubheader() {
    return (<Text style={styles.subheader}>{this.props.translate('candidate.gig.questionnaireModal.subheader')}</Text>);
  }

  renderFooter() {
    const { onClose, onSave, questionnaire } = this.props;
    return (
      <ModalFooter>
        <Button
          size={BUTTON_SIZES.MD}
          type={BUTTON_TYPES.SECONDARY}
          title={this.props.translate('generic.close')}
          onPress={onClose}
        />
        <Button
          size={BUTTON_SIZES.MD}
          title={this.props.translate('generic.save')}
          onPress={onSave}
          disabled={!isQuestionnaireFilledOut(questionnaire)}
        />
      </ModalFooter>
    );
  }
  render() {
    const { visible, onClose, translate } = this.props;
    return (
      <PrimaryModal
        visible={visible}
        handleCancel={onClose}
        header={translate('candidate.gig.questionnaireModal.header')}
        headerProps={{ showBackArrow: true, shadow: true, subheaderFunc: () => this.renderSubheader() }}
      >
        <ModalKeyboardAwareScrollView>
          { this.renderForm() }
          <KeyboardAwareShowView>
            {this.renderFooter()}
          </KeyboardAwareShowView>
        </ModalKeyboardAwareScrollView>
        {this.renderFooter()}
      </PrimaryModal>
    );
  }
}

CandidateQuestionnaireModalEdit.propTypes = {
  translate: PropTypes.func.isRequired,
  questionnaire: PropTypes.arrayOf(filledOutQuestionnairePropType),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleUpdateAnswer: PropTypes.func,
};

CandidateQuestionnaireModalEdit.defaultProps = {
  questionnaire: null,
  handleUpdateAnswer: undefined,
};

export default withLocalize(CandidateQuestionnaireModalEdit);
