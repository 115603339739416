import React from 'react';
import PrimaryKeyboardAwareScrollView from './PrimaryKeyboardAwareScrollView';

const ModalKeyboardAwareScrollView = ({ ...props }) => (
  <PrimaryKeyboardAwareScrollView
    enableOnAndroid={false}
    {...props}
  />
);

export default ModalKeyboardAwareScrollView;
