import React, { Component } from 'react';
import { Text } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { adminClearGigDetails, adminGigDetails } from '../AdminGigsActions';

class AdminGigDetailsView extends Component {
  componentDidMount() {
    this.props.adminGigDetails(this.props.match.params.id);
  }
  componentWillUnmount() {
    this.props.adminClearGigDetails();
  }

  render() {
    return <Text>{JSON.stringify(this.props.gig, null, 2)}</Text>;
  }
}

AdminGigDetailsView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  gig: PropTypes.any.isRequired,
  adminGigDetails: PropTypes.func.isRequired,
  adminClearGigDetails: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

const mapStateToProps = (state) => ({
  gig: state.admin.get('gigs').get('gigDetails').toJS(),
});

export default withRouter(connect(mapStateToProps, { adminGigDetails, adminClearGigDetails })(withLocalize(AdminGigDetailsView)));
