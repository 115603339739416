import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import CandidateProfileEmailForm from '../../forms/CandidateProfileEmailForm';
import EditableField from '../../../../common/components/EditableField';
import { candidateProfileSetEmail } from '../../CandidateProfileActions';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';

class EditableFieldEmailModalAware extends React.Component {
  openModal = () => {
    const {
      translate, closeModal, email,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.emailLabel'),

      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileEmailForm}
        closeModal={closeModal}
        email={email}
        handleSubmit={(values) => {
          this.props.candidateProfileSetEmail(values)
            .then((response) => {
              if (!response.isError) {
                closeModal();
              }
            });
        }}
      />,
    );
  };

  render() {
    const { translate, email } = this.props;
    return (
      <EditableField
        label={translate('candidateProfile.details.emailLabel')}
        value={email}
        onPress={this.openModal}
      />
    );
  }
}

EditableFieldEmailModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateProfileSetEmail: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const profile = state.candidate.get('profile');
  return {
    email: profile.get('email'),
  };
};

export default connect(mapStateToProps, { candidateProfileSetEmail })(withLocalize(EditableFieldEmailModalAware));
