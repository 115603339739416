import {
  COMPANY_GIGS_STORE_GIG_DATA,
  COMPANY_GIGS_CLEAR_LIST,
  CLOSE_GIG_REQUEST,
  COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST,
  COMPANY_GIGS_GET_OPEN_GIGS_REQUEST,
  GET_GIG_REQUEST,
  COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_SET,
  COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_REMOVE,
} from '../../constants/actionTypes';

export function getGig(gigId) {
  return {
    type: GET_GIG_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/company/gig/${gigId}`,
      },
    },
  };
}

export function getOpenGigs() {
  return {
    type: COMPANY_GIGS_GET_OPEN_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/gig?closed=false&sortBy=expiryDate&sortDirection=ASC',
      },
    },
  };
}

export function getClosedGigs() {
  return {
    type: COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/gig?closed=true&sortBy=closeDate&sortDirection=DESC',
      },
    },
  };
}

export function closeGig(gigId) {
  return {
    type: CLOSE_GIG_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/company/gig/${gigId}/close`,
      },
    },
  };
}

export function clearGigList(listKey) {
  return {
    type: COMPANY_GIGS_CLEAR_LIST,
    listKey,
  };
}

export function prepareGigDataToPreview(gigId) {
  return {
    type: COMPANY_GIGS_STORE_GIG_DATA,
    gigId,
  };
}

export function addGigCandidateStatusIndicator(gigId, gigCandidateStatus) {
  return {
    type: COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_SET,
    payload: {
      gigId,
      gigCandidateStatus,
    },
  };
}

export function removeGigCandidateStatusIndicator(gigId, gigCandidateStatus) {
  return {
    type: COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_REMOVE,
    payload: {
      gigId,
      gigCandidateStatus,
    },
  };
}
