import {
  ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Autocomplete from 'react-native-autocomplete-input';
import debounce from 'debounce';

import Routing from '../../../../core/routing/index';
import { Button } from '../../../../common/components';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import validation, { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from './CompanyShiftsMainStepEditableValidation';
import ScreenHeader from '../../../../common/components/header/ScreenHeader';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import {
  colors, fontFamily, fontSizes, heightSpacingsBolek, shadowTop, spacings, standardSpacings, widthSpacingsBolek,
} from '../../../../common/styles/base.style';
import { shiftLocationPropType, shiftPropType } from '../../../../constants/propTypes';
import { font } from '../../../../common/styles/mixins';
import ViewportController from '../../../../common/components/ViewportController';
import AutoScrollableKeyboardAwareScrollView from '../../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import FormikFieldPrimaryInputTextNextSupport from '../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import PrimaryLocationAutocompleteTextInput from '../../../../common/components/form/PrimaryLocationAutocompleteTextInput';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';
import Wrapper from '../../../../common/components/Wrapper';
import GeneralFormError from '../../../../common/components/form/GeneralFormError';
import HighlightedSection from '../../../../common/components/HighlightedSection';
import selectIcon from '../../../../assets/icons/select/select.png';
import removeIcon from '../../../../assets/icons/remove/remove.png';
import IconButtonBolek from '../../../../common/components/buttons/IconButtonBolek';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
  oldShiftsHeader: {
    textAlign: 'center',
    ...font(fontFamily.SFProTextSemiBold, fontSizes.md),
  },
  templateRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: heightSpacingsBolek.p20,
  },
  templateName: {
    flex: 1,
    ...font(fontFamily.SFProTextRegular, fontSizes.md),
  },
  titleLabel: {
    textAlign: 'center',
    ...font(fontFamily.SFProTextSemiBold, fontSizes.md),
    paddingTop: spacings.md,
    marginBottom: spacings.md,
  },
  suggestedPlace: {
    padding: spacings.xs,
  },
  autocompleteContainer: {
    flex: 1,
    opacity: 0.8,
  },
  spinner: {
    position: 'absolute',
    paddingTop: 30,
    paddingRight: 11,
    right: 0,
  },
});

class CompanyShiftsMainStepEditableScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      locationItems: [],
      chosenCityCoords: {
        lon: props.shiftLocation ? props.shiftLocation.geoLoc.lon : 18.068581,
        lat: props.shiftLocation ? props.shiftLocation.geoLoc.lat : 59.329323,
      },
    };
  }

  onPressSuggestion = async (shiftLocation) => {
    this.props.onLocationChange(null).then(() => this.props.onLocationChange(shiftLocation));
    this.setState({ locationItems: [] });
  };

  onTextSearchChangeAggregationDebounce = debounce(this.onTextSearchChangeAggregation, 600);

  onTextSearchChangeAggregation(text) {
    const { chosenCityCoords } = this.state;
    if (text && text.length > 3) {
      this.setState({ inProgress: true });
      this.props.searchForShiftLocation(text, chosenCityCoords.lon, chosenCityCoords.lat).then((res) => {
        if (res && res.payload && !res.payload.error && !res.payload.isError) {
          const locationItems = res.payload.map((item) => ({
            ...item,
            key: `${item.geoLoc.lon},${item.geoLoc.lat}`,
          }));
          this.setState({ locationItems, inProgress: false });
        } else {
          console.warn('Unable to search for location by text=%s', text);
        }
      });
    } else {
      this.setState({ locationItems: [] });
    }
  }

  onCityChange = (cityKey) => {
    const chosenCityData = this.props.cities.find((city) => city.value === cityKey);
    if (!chosenCityData) {
      return;
    }
    const { chosenCityCoords } = this.state;
    if (chosenCityCoords.lon !== chosenCityData.coordinates[0] || chosenCityCoords.lat !== chosenCityData.coordinates[1]) {
      this.props.onLocationChange(null);
      this.setState({
        chosenCityCoords: { lon: chosenCityData.coordinates[0], lat: chosenCityData.coordinates[1] },
        locationItems: [],
      });
    }
  };

  render() {
    const {
      translate, initialValues, shiftLocation, onChooseTemplate, onRemoveTemplate, templates, handleSubmit, cities, errors,
    } = this.props;
    const { inProgress, locationItems } = this.state;

    const translateByKey = (key) => translate(`company.shifts.shift.${key}`);

    return (
      <ViewportController safeAreaBottom>
        <ScreenHeader
          title={translateByKey('main.headerShiftAdd')}
          showBackArrow
          shadow
        />
        <AutoScrollableKeyboardAwareScrollView>
          {templates && !!templates.length && (
            <HighlightedSection>
              <Text style={styles.oldShiftsHeader}>{translateByKey('main.yourOldShifts')}</Text>
              {templates.map((template) => (
                <View style={styles.templateRow} key={template.gigId}>
                  <Text style={styles.templateName}>{template.position}</Text>
                  <IconButtonBolek
                    style={{ paddingLeft: widthSpacingsBolek.p20 }}
                    onPress={() => onRemoveTemplate(template)}
                    source={removeIcon}
                  />
                  <IconButtonBolek
                    style={{ paddingLeft: widthSpacingsBolek.p20 }}
                    onPress={() => onChooseTemplate(template)}
                    source={selectIcon}
                  />
                </View>
              ))}
            </HighlightedSection>
          )}
          <FormikWithValidation
            enableReinitialize
            validation={validation}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
              <>
                <KeyboardAwareFlexWrapper style={{ paddingTop: spacings.md }}>
                  <FormikFieldPrimaryInputTextNextSupport
                    name="position"
                    label={translateByKey('main.position')}
                    maxLength={MAX_NAME_LENGTH}
                    counter
                    autoscroll
                    scrollEnabled={false}
                    nextComponent={this.state.descriptionInputRef}
                  />
                  <FormikFieldPrimaryTextInput
                    innerRef={(ref) => !this.state.descriptionInputRef && this.setState({ descriptionInputRef: ref })}
                    name="description"
                    label={translateByKey('main.description')}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    multiline
                    numberOfLines={Platform.OS === 'web' ? 20 : 1}
                    counter
                    autoscroll
                    scrollEnabled={false}
                  />
                  <FormikFieldPrimaryTextInput
                    innerRef={(ref) => !this.state.candidatesNumberInputRef && this.setState({ candidatesNumberInputRef: ref })}
                    name="candidatesNumber"
                    keyboardType="numeric"
                    label={translateByKey('main.candidatesNumber')}
                    autoscroll
                    selectTextOnFocus
                    returnKeyType="done"
                  />
                  <FormikFieldPrimaryPicker
                    name="cityKey"
                    items={cities}
                    label={translateByKey('main.cityTitle')}
                    onValueChange={(cityKey) => this.onCityChange(cityKey)}
                  />
                  <View style={styles.autocompleteContainer}>
                    {inProgress && <ActivityIndicator size="small" color={colors.magenta} style={styles.spinner} />}
                    <Autocomplete
                      defaultValue={shiftLocation && `${shiftLocation.street}, ${shiftLocation.place}`}
                      inputContainerStyle={{ borderWidth: 0 }}
                      containerStyle={{ paddingTop: spacings.sm, margin: 0, marginBottom: 30 + locationItems.length * 30 }}
                      listContainerStyle={{ padding: 0, margin: 0 }}
                      renderTextInput={(props) => <PrimaryLocationAutocompleteTextInput {...props} />}
                      data={locationItems}
                      onChangeText={(text) => this.onTextSearchChangeAggregationDebounce(text)}
                      flatListProps={{
                        keyExtractor: (item) => item.key,
                        renderItem: ({ item }) => (
                          <TouchableOpacity onPress={() => this.onPressSuggestion(item)}>
                            <Text style={styles.suggestedPlace}>{`${item.street}, ${item.place}`}</Text>
                          </TouchableOpacity>
                        ),
                      }}
                      inProgress={inProgress}
                      label={translateByKey('main.workplaceLocation')}
                      placeholder={translateByKey('main.placeholder')}
                      name="shiftLocation"
                    />
                  </View>
                  <Wrapper noFlex>
                    <GeneralFormError all errors={errors} style={{ wrapper: { marginVertical: standardSpacings.formVertical } }} />
                  </Wrapper>
                </KeyboardAwareFlexWrapper>
                <View style={styles.button}>
                  <Button
                    title={translate('generic.next')}
                    disabled={isBtnDisabled || !shiftLocation}
                    onPress={handleSubmitFormik}
                  />
                </View>
              </>
            )}
          />
        </AutoScrollableKeyboardAwareScrollView>
      </ViewportController>
    );
  }
}

CompanyShiftsMainStepEditableScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onChooseTemplate: PropTypes.func.isRequired,
  onRemoveTemplate: PropTypes.func.isRequired,
  searchForShiftLocation: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    position: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    candidatesNumber: PropTypes.string.isRequired,
    cityKey: PropTypes.string.isRequired,
  }).isRequired,
  shiftLocation: shiftLocationPropType,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  templates: PropTypes.arrayOf(shiftPropType),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any,
};

CompanyShiftsMainStepEditableScreen.defaultProps = {
  shiftLocation: undefined,
  templates: null,
  errors: undefined,
};

export default withLocalize(withRouter(CompanyShiftsMainStepEditableScreen));
