import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { PrimaryTextInput } from '../index';
import { getValueByPath } from '../../../utilities/objectUtils';

const FormikPrimaryTextInput = ({
  field: { name, value }, form: {
    setFieldValue, setFieldTouched, touched, errors,
  }, onValueChange, ...props
}) => {
  const { onChangeTextModifyValue } = props;
  return (
    <PrimaryTextInput
      {...props}
      onChangeText={(val) => {
        setFieldValue(name, (onChangeTextModifyValue ? onChangeTextModifyValue(val) : val));
        if (onValueChange) {
          onValueChange(val);
        }
      }}
      onBlur={
        () => setFieldTouched(name, true)
      }
      value={value || ''}
      error={(getValueByPath(touched, name) && errors[name]) || null}
      testID={`${name}Input`}
    />
  );
};

FormikPrimaryTextInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onValueChange: PropTypes.func,
  onChangeTextModifyValue: PropTypes.func,
};

FormikPrimaryTextInput.defaultProps = {
  onChangeTextModifyValue: undefined,
  onValueChange: undefined,
};

const FormikFieldPrimaryTextInput = (props) => (
  <Field
    component={FormikPrimaryTextInput}
    {...props}
  />
);

export default FormikFieldPrimaryTextInput;
