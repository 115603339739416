import React, { PureComponent } from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { toDatetime } from '../../../utilities/dateFormatter';
import {
  fontSizes, fontFamily, colors, spacings,
} from '../../../common/styles/base.style';
import { OPEN } from '../../../constants/gigStatus';
import { gigItemPropType } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  date: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xs,
    color: colors.lightPeach,
    marginTop: spacings.xxs,
    marginBottom: spacings.xs,
  },
  highlighted: {
    fontWeight: 'bold',
  },
  name: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.xl,
    letterSpacing: -0.1,
    color: colors.greyishBrown,
  },
  grayOutName: {
    color: colors.brownGrey,
  },
  newContentName: {
    color: colors.seablue,
  },
});

class CompanyGigsListItem extends PureComponent {
  render() {
    const {
      translate, name, expiryDate, closeDate, status, grayOutName, isNewContent,
    } = this.props;
    return (
      <>
        <Text style={[styles.name, grayOutName && styles.grayOutName, isNewContent && styles.newContentName]}>{name}</Text>
        <Text style={styles.date}>
          {status === OPEN
            ? translate('companyGigs.list.expiresLabel') + toDatetime(expiryDate)
            : translate('companyGigs.list.closedLabel') + toDatetime(closeDate)}
        </Text>
      </>
    );
  }
}

CompanyGigsListItem.propTypes = {
  translate: PropTypes.func.isRequired,
  ...gigItemPropType,
  grayOutName: PropTypes.bool,
  isNewContent: PropTypes.bool,
};

CompanyGigsListItem.defaultProps = {
  grayOutName: false,
  isNewContent: false,
};

export default CompanyGigsListItem;
