import queryString from 'query-string';

/* Public */

export const ROOT = '/';
export const WELCOME = '/welcome';
export const WELCOME_CAROUSEL = '/welcome/carousel';
export const ERROR = '/error';

/* Shared */

export const NOTIFICATION_PERMISSION = '/notificationpermission';
export const FIRST_CHECK_USER_STATUS = '/first_check_user_status';
export const FORGOT_PASSWORD = '/forgot_password';
export const DEEP_LINK_REDIRECT = '/deep_link_redirect';
export const RESET_PASSWORD = '/reset_password/:token';
export const HOME_LOCATION = '/home_location';

/* Company */
export const COMPANY = '/company';

/* Company Auth routing */
export const COMPANY_AUTH = '/company/auth';
export const COMPANY_WELCOME = '/company/auth/welcome';
export const COMPANY_AUTOCREATE = '/company/auth/autoCreate/:autoCreateId';
export const COMPANY_LOGIN = '/company/auth/login';
export const COMPANY_CONTRACT_AGREEMENT = '/company/auth/contractAgreement';
export const COMPANY_SIGNUP = '/company/auth/signup';
export const COMPANY_CONTACT_REQUEST_SENT = '/company/auth/contact/sent';

/* Company My Gigs routing */
export const COMPANY_GIGS_ROOT = '/company/gigs';
export const COMPANY_GIGS_LIST = '/company/gigs/list';
export const COMPANY_OPEN_GIGS = '/company/gigs/list/open';
export const COMPANY_CLOSED_GIGS = '/company/gigs/list/closed';
export const COMPANY_GIG_MATCHING = '/company/gigs/:id/matching';
export const COMPANY_GIG_MATCHING_REVIEW = '/company/gigs/:id/matching/candidates/suggested';
export const COMPANY_GIG_MATCHING_SHORTLISTED = '/company/gigs/:id/matching/candidates/shortlisted';
export const COMPANY_GIG_MATCHING_MATCHED = '/company/gigs/:id/matching/candidates/matched';
export const COMPANY_GIG_MATCHING_HIRED = '/company/gigs/:id/matching/candidates/hired';
export const COMPANY_GIG_MATCHING_OFFERS = '/company/gigs/:id/matching/candidates/offers';
export const COMPANY_GIG_MATCHING_HISTORY = '/company/gigs/:id/matching/candidates/history';
export const COMPANY_GIG_DETAILS = '/company/gigs/:id/details';
export const COMPANY_GIG_EDIT = '/company/gigs/edit';
export const COMPANY_GIG_EDIT_START = '/company/gigs/edit/:gigId/start';
export const COMPANY_GIG_EDIT_INFO = '/company/gigs/edit/:gigId/info';
export const COMPANY_GIG_EDIT_CATEGORY = '/company/gigs/edit/:gigId/categories';
export const COMPANY_GIG_EDIT_DESCRIPTION = '/company/gigs/edit/:gigId/description';
export const COMPANY_GIG_EDIT_LOCATION = '/company/gigs/edit/:gigId/location';
export const COMPANY_GIG_EDIT_DATES = '/company/gigs/edit/:gigId/dates';
export const COMPANY_GIG_EDIT_COMPENSATION = '/company/gigs/edit/:gigId/compensation';
export const COMPANY_GIG_EDIT_AVAILABILITY = '/company/gigs/edit/:gigId/availability';
export const COMPANY_GIG_EDIT_EXPERIENCE = '/company/gigs/edit/:gigId/experience';
export const COMPANY_GIG_EDIT_LANGUAGE = '/company/gigs/edit/:gigId/language';
export const COMPANY_GIG_EDIT_QUESTIONNAIRE = '/company/gigs/edit/:gigId/questionnaire';
export const COMPANY_GIG_EDIT_PHOTO = '/company/gigs/edit/:gigId/photo';
export const COMPANY_GIG_EDIT_OCCUPATION = '/company/gigs/edit/:gigId/occupation';

export const COMPANY_GIG_CANDIDATE_VIEW = '/company/gigs/:gigId/candidate/:candidateId/view';
export const COMPANY_GIG_PAYMENT_BILLING_PROFILE = '/company/gigs/:gigId/pay/billingProfile/:paymentPlanType';

/* Company Chat routing */
export const COMPANY_CHAT = '/company/chat';
export const COMPANY_CHAT_GIG_LIST = '/company/chat/list';
export const COMPANY_OPEN_CHAT_GIG_LIST = '/company/chat/list/active';
export const COMPANY_CLOSED_CHAT_GIG_LIST = '/company/chat/list/closed';
export const COMPANY_CHAT_GIG_CANDIDATES_LIST = '/company/chat/gigs/:id/';
export const COMPANY_GIG_CHAT = '/company/chat/gig/:gigId/candidate/:candidateId';

/* Company Create Gig routing */
export const COMPANY_POSTGIG = '/company/postGig'; // redirects to CREATE_GIG on click
export const COMPANY_CREATE_GIG = '/company/postGig/create';
export const COMPANY_CREATE_GIG_WELCOME = '/company/postGig/create/welcome';
export const COMPANY_CREATE_GIG_INFO = '/company/postGig/create/info';
export const COMPANY_CREATE_GIG_CATEGORY = '/company/postGig/create/categories';
export const COMPANY_CREATE_GIG_DESCRIPTION = '/company/postGig/create/description';
export const COMPANY_CREATE_GIG_LOCATION = '/company/postGig/create/location';
export const COMPANY_CREATE_GIG_DATES = '/company/postGig/create/dates';
export const COMPANY_CREATE_GIG_COMPENSATION = '/company/postGig/create/compensation';
export const COMPANY_CREATE_GIG_AVAILABILITY = '/company/postGig/create/availability';
export const COMPANY_CREATE_GIG_EXPERIENCE = '/company/postGig/create/experience';
export const COMPANY_CREATE_GIG_LANGUAGE = '/company/postGig/create/language';
export const COMPANY_CREATE_GIG_QUESTIONNAIRE = '/company/postGig/create/questionnaire';
export const COMPANY_CREATE_GIG_PHOTO = '/company/postGig/create/photo';
export const COMPANY_CREATE_GIG_OCCUPATION = '/company/postGig/create/occupation';
export const COMPANY_CREATE_GIG_PREVIEW = '/company/postGig/create/preview'; // redirects to COMPANY_GIGS_LIST on success
export const COMPANY_CHECK_GIG_LEFT = '/company/profile/check-limit';

/* Company Candidates Pool */
export const COMPANY_CANDIDATES_POOL = '/company/candidatesPool';
export const COMPANY_CANDIDATES_POOL_MAIN = '/company/candidatesPool/myPool';
export const COMPANY_CANDIDATES_POOL_MY_POOL = '/company/candidatesPool/myPool/candidates';
export const COMPANY_CANDIDATES_POOL_DIRECT_CHAT = '/company/candidatesPool/:poolCandidateId/chat';
export const COMPANY_CANDIDATES_POOL_GROUPS = '/company/candidatesPool/myPool/groups';
export const COMPANY_CANDIDATES_POOL_SHIFTS = '/company/candidatesPool/myPool/shifts';
export const COMPANY_CANDIDATES_POOL_SHIFTS_OPEN = '/company/candidatesPool/myPool/shifts/open';
export const COMPANY_CANDIDATES_POOL_SHIFTS_CLOSED = '/company/candidatesPool/myPool/shifts/closed';
export const COMPANY_CANDIDATES_POOL_SHIFTS_ARCHIVED = '/company/candidatesPool/myPool/shifts/archived';
export const COMPANY_CANDIDATES_POOL_GROUP_MAIN = '/company/candidatesPool/group';
export const COMPANY_CANDIDATES_POOL_GROUP_CREATE = '/company/candidatesPool/group/create';
export const COMPANY_CANDIDATES_POOL_GROUP_VIEW = '/company/candidatesPool/group/:groupId/view';
export const COMPANY_CANDIDATES_POOL_GROUP_MODIFY = '/company/candidatesPool/group/:groupId/modify';
export const COMPANY_CANDIDATES_POOL_GROUP_CHAT = '/company/candidatesPool/group/:groupId/chat';
export const COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE = '/company/candidatesPool/candidatesProfile/:candidateId';
export const COMPANY_CANDIDATES_POOL_PROFILE_EDIT = '/company/candidatesPool/profile/edit';
export const COMPANY_CANDIDATES_POOL_PROFILE_VIEW = '/company/candidatesPool/profile/view';

/* Company shifts */
export const COMPANY_SHIFT_ROOT = '/company/shift';
export const COMPANY_SHIFT_CREATE = '/company/shift/create';
export const COMPANY_SHIFT_CREATE_MAIN = '/company/shift/create/main';
export const COMPANY_SHIFT_CREATE_SALARY = '/company/shift/create/salary';
export const COMPANY_SHIFT_CREATE_DATES_AND_TIMES = '/company/shift/create/datesAndTimes';
export const COMPANY_SHIFT_CREATE_ACCESS_ENTITIES = '/company/shift/create/accessEntities';

export const COMPANY_SHIFT_CANDIDATES = '/company/shift/:gigId/candidates';
export const COMPANY_SHIFT_VIEW = '/company/shift/:gigId/view';
export const COMPANY_SHIFT_EDIT = '/company/shift/:gigId/edit';

/* Company Profile routing */
export const COMPANY_PROFILE = '/company/profile';
export const COMPANY_PROFILE_EDIT = '/company/profile/edit';
export const COMPANY_PROFILE_SETTINGS = '/company/profile/settings';
/* -- Payments - creating new */
export const COMPANY_PAYMENTS = '/company/profile/payments';
export const COMPANY_PAYMENTS_PRICING_PLANS_INITIAL = '/company/profile/payments/pricingPlans';
export const COMPANY_PAYMENTS_CARD_NEW = '/company/profile/payments/card/new';
export const COMPANY_PAYMENTS_BILLING_PROFILE_NEW = '/company/profile/payments/billingProfile/new';
export const COMPANY_PAYMENTS_CARD_NEW_ON_HIRE = '/company/profile/payments/card/hire';
/* -- Payments - changing existing */
export const COMPANY_PAYMENTS_OVERVIEW = '/company/profile/payments/overview';
export const COMPANY_PAYMENTS_CARD_CHANGE = '/company/profile/payments/card/change';
export const COMPANY_PAYMENTS_BILLING_PROFILE_CHANGE = '/company/profile/payments/billingProfile/change';
export const COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE = '/company/profile/payments/pricingPlans/subscribe';
export const COMPANY_PAYMENTS_PRICING_PLANS_HIRE = '/company/profile/payments/pricingPlans/hire';
export const COMPANY_PAYMENTS_PRICING_PLANS_CHANGE = '/company/profile/payments/pricingPlans/change';

/* Candidate */
export const CANDIDATE = '/candidate';

export const CANDIDATE_WELCOME = '/candidate/auth/welcome';
export const CANDIDATE_AUTH = '/candidate/auth';
export const CANDIDATE_LOGIN = '/candidate/auth/login';
export const CANDIDATE_SIGNUP = '/candidate/auth/signup';
export const CANDIDATE_SPLASH = '/candidate/splash';
export const CANDIDATE_CONTRACT_AGREEMENT = '/candidate/auth/contractAgreement';

export const CANDIDATE_GIGS = '/candidate/gigs';
export const CANDIDATE_FIND_GIGS = '/candidate/gigs/find';
export const CANDIDATE_FIND_GIGS_LIST = '/candidate/gigs/find/list';
export const CANDIDATE_FIND_GIGS_SINGLE = '/candidate/gigs/find/single';
export const CANDIDATE_FIND_GIGS_SINGLE_VIEW = '/candidate/gigs/find/single/view';
export const CANDIDATE_FIND_GIGS_SINGLE_FAVOURITES = '/candidate/gigs/find/single/favourites';
export const CANDIDATE_FIND_GIGS_SINGLE_REJECTED = '/candidate/gigs/find/single/rejected';
export const CANDIDATE_GIG_DETAILS = '/candidate/gigs/:id/details';
export const CANDIDATE_SHIFT_DETAILS = '/candidate/shifts/:id/details';
export const CANDIDATE_MY_GIGS = '/candidate/gigs/my';
export const CANDIDATE_MY_GIGS_SHORTLISTED = '/candidate/gigs/my/shortlisted';
export const CANDIDATE_MY_GIGS_MATCHED = '/candidate/gigs/my/matched';
export const CANDIDATE_MY_GIGS_OFFERS = '/candidate/gigs/my/offers';
export const CANDIDATE_MY_GIGS_HIRED = '/candidate/gigs/my/hired';
export const CANDIDATE_MY_GIGS_HISTORY = '/candidate/gigs/my/history';
export const CANDIDATE_MY_GIGS_APPLIED = '/candidate/gigs/my/applied';

export const CANDIDATE_MY_POOLS = '/candidate/myPools';
export const CANDIDATE_MY_POOLS_POOLS = '/candidate/myPools/pools';
export const CANDIDATE_MY_POOLS_GROUPS = '/candidate/myPools/groups';
export const CANDIDATE_MY_POOLS_SHIFTS = '/candidate/myPools/shifts';
export const CANDIDATE_MY_POOLS_SHIFTS_AVAILABLE = '/candidate/myPools/shifts/available';
export const CANDIDATE_MY_POOLS_SHIFTS_BOOKED = '/candidate/myPools/shifts/booked';
export const CANDIDATE_MY_POOLS_POOL_PROFILE = '/candidate/myPools/poolProfile/:companyId';
export const CANDIDATE_MY_POOLS_GROUP_CHAT = '/candidate/myPools/group/:groupId/chat';
export const CANDIDATE_MY_POOLS_DIRECT_CHAT = '/candidate/myPools/:poolCandidateId/chat';

export const CANDIDATE_PROFILE = '/candidate/profile';
export const CANDIDATE_PROFILE_SETTINGS = '/candidate/profile/settings/';
export const CANDIDATE_PROFILE_VIEW = '/candidate/profile/view';
export const CANDIDATE_PROFILE_EDIT = '/candidate/profile/edit';

export const CANDIDATE_CREATE_PROFILE = '/candidate/profile/create';
export const CANDIDATE_CREATE_PROFILE_WELCOME = '/candidate/profile/create/welcome';
export const CANDIDATE_CREATE_PROFILE_CURRENT_OCCUPATION = '/candidate/profile/create/currentOccupation';
export const CANDIDATE_CREATE_PROFILE_CATEGORY = '/candidate/profile/create/category';
export const CANDIDATE_CREATE_PROFILE_GIG_LOCATION = '/candidate/profile/create/location';
export const CANDIDATE_CREATE_PROFILE_HOME_LOCATION = '/candidate/profile/create/homeLocation';
export const CANDIDATE_CREATE_PROFILE_AVAILABILITY = '/candidate/profile/create/availability';
export const CANDIDATE_CREATE_PROFILE_LANGUAGES = '/candidate/profile/create/languages';
export const CANDIDATE_CREATE_PROFILE_EXPERIENCE = '/candidate/profile/create/experience';
export const CANDIDATE_CREATE_PROFILE_EDUCATION = '/candidate/profile/create/education';
export const CANDIDATE_CREATE_PROFILE_SUCCESS_REQUIRED_STEPS = '/candidate/profile/create/success/required';

export const CANDIDATE_CHAT = '/candidate/chat';
export const CANDIDATE_GIG_CHAT_LIST = '/candidate/chat/gigs';
export const CANDIDATE_GIG_CHAT = '/candidate/chat/gig/:id';

export const ADMIN = '/admin';
export const ADMIN_GIGS = '/admin/gigs';
export const ADMIN_FIND_GIGS = '/admin/gigs/find';
export const ADMIN_FIND_CANDIDATES = '/admin/candidates/find';
export const ADMIN_CANDIDATES = '/admin/candidates';
export const ADMIN_GIG_DETAILS = '/admin/gigs/details/:id';
export const ADMIN_PROFILE = '/admin/profile';
export const ADMIN_NOTIFICATION = '/admin/notification';
export const ADMIN_COMPANIES = '/admin/companies';
export const ADMIN_COMPANY = '/admin/company';
export const ADMIN_FIND_COMPANY = '/admin/company/find';

export const ADMIN_SETTINGS = '/admin/settings';
export const ADMIN_FORTNOX = '/admin/fortnox';
export const ADMIN_ASSOCIATIONS = '/admin/associations';
export const ADMIN_NEW_ASSOCIATIONS = '/admin/associations/new';
export const ADMIN_FORTNOX_CANDIDATES = '/admin/fortnox/candidates';
export const ADMIN_SETTINGS_SET_GIG_LIMIT_SUCCESS = '/admin/settings/signUp';
export const ADMIN_SETTINGS_GET_CITY_LIST_SUCCESS = '/admin/settings/getCities';
export const ADMIN_SETTINGS_UPDATE_CITY_SUCCESS = '/admin/settings/updateCity';
export const ADMIN_ASSOC_DETAILS = '/admin/associations/edit/:id';

export const ADMIN_COMPANY_SIGN_UP = '/admin/companies/signUp';
export const ADMIN_COMPANY_SIGN_UP_SUCCESS = '/admin/companies/signUp/success';
export const ADMIN_NOTIFICATION_LIST = '/admin/notification/list';
export const ADMIN_NOTIFICATION_NEW = '/admin/notification/new';
export const ADMIN_PROFILE_VIEW = '/admin/profile/view';
export const ADMIN_PROFILE_SETTINGS = '/admin/profile/settings';

export const redirectToErrorScreen = (history, titleKey, textKey) => {
  history.replace(`${ERROR}?${queryString.stringify({ titleKey, textKey })}`);
};
