import React, { PureComponent } from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { inputTextStyle } from '../../styles/form.style';
import { spacings } from '../../styles/base.style';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  base: {
    alignSelf: 'flex-start',
    marginBottom: spacings.lg,
  },
});

class StaticFormLabel extends PureComponent {
  getStyle = () => {
    switch (this.props.size) {
      case 'sm':
        return { ...inputTextStyle.inputLabelSmall };
      case 'md':
        return { ...inputTextStyle.inputLabelMedium };
      case 'lg':
        return { ...inputTextStyle.inputLabelLarge };
      default:
        console.log('Wrong size prop value!');
        return {};
    }
  };

  render() {
    const { style, label } = this.props;
    if (!label) {
      return null;
    }

    return (
      <Text
        style={[
          styles.base,
          this.getStyle(),
          style,
        ]}
      >
        {label}
      </Text>
    );
  }
}

StaticFormLabel.propTypes = {
  style: stylePropTypes,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

StaticFormLabel.defaultProps = {
  style: null,
  size: 'lg',
};

export default StaticFormLabel;
