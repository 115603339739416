import React from 'react';
import {
  View, StyleSheet, Dimensions, Text, Image,
} from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import { WELCOME } from '../../constants/routes';
import LinkButton from '../components/buttons/LinkButton';
import {
  colors, fontFamily, fontSizes, hp, spacings,
} from '../styles/base.style';
import Button, { BUTTON_TYPES } from '../components/buttons/Button';
import { font } from '../styles/mixins';
import { activeLanguagePropTypes, imagePropType, translationsPropType } from '../../constants/propTypes';

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    width,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageStyle: {
    height: hp(40),
    marginBottom: spacings.lg,
  },
  header: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayBold, 24, null, colors.black),
    marginBottom: spacings.md,
  },
  text: {
    textAlign: 'center',
    height: 60,
    marginBottom: spacings.sm,
  },
});

const skipButtonStyle = StyleSheet.create({
  text: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, null, colors.brownishGrey),
  },
});

class WelcomeCarouselSlide extends React.PureComponent {
  renderContent() {
    const {
      screen: {
        header, text, buttonLabel, skipLabel,
      },
      scrollTo,
      activeLanguage,
      screenNumber,
    } = this.props;

    return (
      <View>
        <Text style={styles.header}>{header[activeLanguage.code]}</Text>
        <Text style={styles.text}>{text[activeLanguage.code]}</Text>
        <Button
          onPress={() => scrollTo(screenNumber + 1)}
          title={buttonLabel[activeLanguage.code]}
        />
        <LinkButton
          to={WELCOME}
          title={skipLabel ? skipLabel[activeLanguage.code] : ''}
          type={BUTTON_TYPES.TEXT}
          style={skipButtonStyle}
          disabled={!skipLabel}
        />
      </View>
    );
  }

  render() {
    const {
      screen: { imageUrl },
    } = this.props;

    return (
      <View style={styles.container}>
        <Image
          style={styles.imageStyle}
          source={{ uri: imageUrl }}
          resizeMode="contain"
        />
        {this.renderContent()}
      </View>
    );
  }
}

WelcomeCarouselSlide.propTypes = {
  screen: PropTypes.shape({
    imageUrl: imagePropType.isRequired,
    header: translationsPropType.isRequired,
    text: translationsPropType.isRequired,
    buttonLabel: translationsPropType.isRequired,
    skipLabel: translationsPropType,
  }).isRequired,
  scrollTo: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  screenNumber: PropTypes.number.isRequired,
};

export default withLocalize(WelcomeCarouselSlide);
