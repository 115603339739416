import React from 'react';
import Routing from '../../core/routing/index';
import {
  CANDIDATE_CHAT,
  CANDIDATE_GIG_CHAT,
  CANDIDATE_GIG_CHAT_LIST,
} from '../../constants/routes';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../../constants/userScopes';
import CandidateChatGigListContainer from './CandidateChatGigListContainer';
import CandidateGigChatContainer from './CandidateGigChatContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = CANDIDATE_GIG_CHAT_LIST;

const CandidateChatRouting = () => (
  <>
    <Route exact path={CANDIDATE_CHAT} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={CANDIDATE_USER} path={CANDIDATE_GIG_CHAT_LIST} component={CandidateChatGigListContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_GIG_CHAT} component={CandidateGigChatContainer} />
  </>
);

export default CandidateChatRouting;
