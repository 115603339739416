import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import Container from '../../../common/components/Container';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import config from '../../../config/config';
import StripeCardView from './StripeCardView';

const styles = StyleSheet.create({
  activityIndicator: {
    position: 'absolute',
    zIndex: 20000,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
    opacity: 0.5,
    elevation: 100,
  },
});

class PaymentCardScreen extends Component {
  componentDidUpdate(prevProps) {
    /* if (prevProps.error !== this.props.error) {
      this.webview.postMessage(this.props.error);
    } */
  }

  render() {
    const {
      translate, handleBack, handleWebviewAction, showActivityIndicator,
    } = this.props;
    const t = (key) => translate(`company.payments.paymentCardView.${key}`);
    return (
      <Container>
        <ScreenHeader
          title={t('headerAdd')}
          showBackArrow
          handleBack={handleBack}
          shadow
        />
        {showActivityIndicator && <ActivityIndicator size="large" style={styles.activityIndicator} />}

        <StripeCardView
          sourceUri={`${config.mainPageUrl}/payments/tokenizeCard.html`}
          onMessage={handleWebviewAction}
        />
      </Container>
    );
  }
}

PaymentCardScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  error: PropTypes.string,
  handleBack: PropTypes.func.isRequired,
  handleWebviewAction: PropTypes.func.isRequired,
  showActivityIndicator: PropTypes.bool.isRequired,
};

PaymentCardScreen.defaultProps = {
  error: undefined,
};

export default withLocalize(PaymentCardScreen);
