import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorPreviewScreen from './GigCreatorPreviewScreen';
import { companyProfilePropType, gigPropType } from '../../../constants/propTypes';
import { gigCreatorSaveGig } from '../gigCreatorActions';
import { COMPANY_GIGS_LIST, COMPANY_GIG_MATCHING_REVIEW } from '../../../constants/routes';
import Routing from '../../../core/routing/index';
import { getProfile } from '../../profile/companyProfileActions';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorPreviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.PREVIEW);
    this.props.getProfile();
  }

  saveGig = () => {
    this.props.gigCreatorSaveGig({
      ...this.props.gig,
    })
      .then((res) => {
        if (!res.isError) {
          // back arrow in COMPANY_GIG_MATCHING_REVIEW goes to COMPANY_GIGS_LIST
          this.props.history.replace(COMPANY_GIGS_LIST);
          this.props.history.push(COMPANY_GIG_MATCHING_REVIEW.replace(':id', res.payload.gigId));
        } else {
          this.setState({ errors: res.errors });
        }
      });
  };

  render() {
    const { gig } = this.props;
    const { companyDescription, companyName } = this.props.profile;
    gig.company = {
      name: companyName,
      description: companyDescription,
    };

    return (
      <GigCreatorPreviewScreen
        gig={this.props.gig}
        onSaveGig={this.saveGig}
        errors={this.state.errors}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  gig: state.companyGig.get('gigCreator').get('gig').toJS(),
  profile: state.company.get('profile').toJS(),
});

GigCreatorPreviewContainer.propTypes = {
  getProfile: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  profile: companyProfilePropType,
  gigCreatorSaveGig: PropTypes.func.isRequired,
  gig: gigPropType.isRequired,
};
GigCreatorPreviewContainer.defaultProps = {
  profile: undefined,
};

export default withRouter(connect(mapStateToProps, { getProfile, gigCreatorSaveGig })(GigCreatorPreviewContainer));
