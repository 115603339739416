import React, { Component } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, Platform, ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import {
  colors, fontFamily, fontSizes, spacings, wp,
} from '../../styles/base.style';
import { font } from '../../styles/mixins';
import { stylePropTypes } from '../../../constants/propTypes';

export const BUTTON_TYPES = {
  PRIMARY_RED: 'primary-red',
  PRIMARY_BLUE: 'primary-blue',
  SECONDARY: 'secondary',
  GRAY: 'gray',
  DISABLED: 'disabled',
  TEXT: 'text',
  GRAY_TEXT: 'gray-text',
};

export const BUTTON_SIZES = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  FLEX_SM: 'flex-sm',
  FLEX_MD: 'flex-md',
};

export const BUTTON_ALIGN = {
  CENTER: 'center',
  LEFT: 'left',
};

const styles = {
  /*
  Common
  */
  base: StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    text: {
      fontFamily: fontFamily.SFProDisplayBold,
    },
  }),
  margin: StyleSheet.create({
    wrapper: {
      marginBottom: spacings.md,
      marginHorizontal: spacings.xs,
    },
  }),
  /*
  Sizes
  */
  'size-lg': StyleSheet.create({
    wrapper: {
      minWidth: wp(72),
      // we need padding because of the radius
      paddingHorizontal: wp(6),
      height: 54,
      borderRadius: 27,
      ...Platform.select({
        web: {
          minWidth: wp(57),
        },
      }),
    },
    text: {
      fontSize: fontSizes.sm,
      letterSpacing: 1,
    },
  }),
  'size-md': StyleSheet.create({
    wrapper: {
      minWidth: wp(25),
      // we need padding because of the radius
      paddingHorizontal: wp(5),
      height: 52,
      borderRadius: 27,
    },
    text: {
      fontSize: fontSizes.sm,
      letterSpacing: 1,
    },
  }),
  'size-sm': StyleSheet.create({
    wrapper: {
      minWidth: wp(22.7),
      // we need padding because of the radius
      paddingHorizontal: wp(4),
      height: 32,
      borderRadius: 16,
    },
    text: {
      fontSize: fontSizes.xxs,
      letterSpacing: 0.4,
    },
  }),
  'size-flex-sm': StyleSheet.create({
    wrapper: {
      flex: 1,
      // we need padding because of the radius
      paddingHorizontal: wp(4),
      height: 32,
      borderRadius: 16,
    },
    text: {
      fontSize: fontSizes.xxs,
    },
  }),
  'size-flex-md': StyleSheet.create({
    wrapper: {
      flex: 1,
      // we need padding because of the radius
      paddingHorizontal: wp(4),
      height: 41,
      borderRadius: 21,
    },
    text: {
      fontSize: fontSizes.sm,
    },
  }),

  /*
  Sizes when type is text
   */
  'size-text-lg': StyleSheet.create({
    wrapper: {
      height: 32,
    },
    text: {
      ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl, 0, colors.magenta),
    },
  }),
  'size-text-md': StyleSheet.create({
    wrapper: {
      height: 25,
    },
    text: {
      ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md, 0, colors.magenta),
    },
  }),
  'size-gray-text-sm': StyleSheet.create({
    wrapper: {
      height: 16,
    },
    text: {
      ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md, 0, colors.magenta),
    },
  }),

  /*
  Types
  */
  'type-primary-red': StyleSheet.create({
    wrapper: {
      backgroundColor: colors.magenta,
    },
    text: {
      color: colors.white,
    },
  }),
  'type-primary-blue': StyleSheet.create({
    wrapper: {
      backgroundColor: colors.purpleyBlue,
    },
    text: {
      color: colors.white,
    },
  }),
  'type-secondary': StyleSheet.create({
    wrapper: {
      backgroundColor: colors.white,
      borderColor: colors.magenta,
      borderWidth: 2,
    },
    text: {
      color: colors.magenta,
    },
  }),
  'type-text': StyleSheet.create({
    wrapper: {
      borderRadius: 0,
      paddingHorizontal: 0,
      paddingVertical: 0,
      marginVertical: 0,
      marginHorizontal: 0,
      marginBottom: spacings.sm,
    },
  }),
  'align-text-center': {
    wrapper: {
      alignItems: 'center',
    },
  },
  'align-text-left': {
    wrapper: {
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  'type-gray': StyleSheet.create({
    wrapper: {
      backgroundColor: colors.veryLightGrey,
    },
    text: {
      color: colors.greyishBrown,
      fontSize: fontSizes.xl,
      letterSpacing: 0,
    },
  }),
  'type-gray-text': StyleSheet.create({
    wrapper: {
      borderRadius: 0,
      paddingHorizontal: 0,
      paddingVertical: 0,
      marginVertical: 0,
      marginHorizontal: 0,
      marginBottom: spacings.sm,
    },
    text: {
      color: colors.lightPeach,
      fontSize: fontSizes.xxxs,
      letterSpacing: 0,
    },
  }),
  disabled: StyleSheet.create({
    wrapper: {
      backgroundColor: colors.veryLightPink,
      borderColor: colors.veryLightPink,
    },
    text: {
      color: colors.white,
    },
  }),
  'disabled-text': StyleSheet.create({
    text: {
      color: colors.lightPeach,
    },
  }),
};

function getTextValue(type, title, preventUpperCase) {
  if ([BUTTON_TYPES.TEXT, BUTTON_TYPES.GRAY].includes(type) || preventUpperCase) {
    return title;
  }
  return title.toUpperCase();
}

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = { buttonPressed: false };
  }

  render() {
    const {
      size,
      type,
      margin,
      onPress,
      title,
      style,
      preventUpperCase,
      disabled,
      testID,
      alignText,
      showLoaderOnPress,
    } = this.props;

    const disabledStyle = disabled ? styles[`disabled-${type}`] || styles.disabled : undefined;
    const marginStyle = margin ? styles.margin : undefined;
    const typeStyle = styles[`type-${type}`];
    const sizeStyle = type !== BUTTON_TYPES.TEXT ? styles[`size-${size}`] : styles[`size-text-${size}`];
    const alignTextStyle = styles[`align-text-${alignText}`];

    return (
      <TouchableOpacity
        activeOpacity={disabled ? 1 : 0.5}
        style={[
          styles.base.wrapper,
          marginStyle && marginStyle.wrapper,
          sizeStyle.wrapper,
          typeStyle.wrapper,
          alignTextStyle.wrapper,
          style && style.wrapper,
          disabledStyle && disabledStyle.wrapper,
        ]}
        onPress={() => {
          if (!disabled) {
            this.setState({ buttonPressed: true });
            onPress();
          }
        }}
        testID={testID}
      >
        {
          showLoaderOnPress && this.state.buttonPressed
            ? <ActivityIndicator size="small" />
            : (
              <Text
                style={[
                  styles.base.text,
                  sizeStyle.text,
                  typeStyle.text,
                  style && style.text,
                  disabledStyle && disabledStyle.text,
                ]}
              >
                {getTextValue(type, title, preventUpperCase)}
              </Text>
            )
        }
      </TouchableOpacity>
    );
  }
}

Button.propTypes = {
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  alignText: PropTypes.oneOf(Object.values(BUTTON_ALIGN)),
  margin: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.shape({
    wrapper: stylePropTypes,
    text: stylePropTypes,
  }),
  disabled: PropTypes.bool,
  preventUpperCase: PropTypes.bool,
  showLoaderOnPress: PropTypes.bool,
  testID: PropTypes.string,
};

Button.defaultProps = {
  size: BUTTON_SIZES.LG,
  type: BUTTON_TYPES.PRIMARY_RED,
  alignText: BUTTON_ALIGN.CENTER,
  margin: true,
  style: null,
  preventUpperCase: false,
  disabled: false,
  testID: undefined,
  showLoaderOnPress: false,
};

export default Button;
