import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HISTORY_LIST_KEY } from '../candidateMyGigsReducer';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { clearCandidateGigs, getCandidateGigs } from '../candidateMyGigsActions';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import GigListItem from '../GigListItem';
import ListItem from '../../../../common/components/ListItem';

class CandidateMyGigsHistoryView extends Component {
  componentDidMount() {
    this.props.getCandidateGigs(HISTORY_LIST_KEY, [], true);
  }

  componentWillUnmount() {
    this.props.clearCandidateGigs(HISTORY_LIST_KEY);
  }

  render() {
    return (
      <PrimaryFlatList
        emptyListTextKey="candidateMyGigs.noHistory"
        data={this.props.gigsHistory}
        keyExtractor={(item) => item.gigId}
        renderItem={({ item: matchedGig }) => (
          <ListItem
            renderContent={() => (
              <GigListItem
                gig={matchedGig}
                online={this.props.usersPresence[matchedGig.createdBy.userId]}
              />
            )}
          />
        )}
      />
    );
  }
}

CandidateMyGigsHistoryView.propTypes = {
  getCandidateGigs: PropTypes.func.isRequired,
  clearCandidateGigs: PropTypes.func.isRequired,
  gigsHistory: PropTypes.arrayOf(matchedGigForCandidatePropType),
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

CandidateMyGigsHistoryView.defaultProps = {
  gigsHistory: null,
};

const mapStateToProps = (state) => ({
  gigsHistory: state.candidateMyGigs.get(HISTORY_LIST_KEY) ? state.candidateMyGigs.get(HISTORY_LIST_KEY).toJS() : null,
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, { getCandidateGigs, clearCandidateGigs })(CandidateMyGigsHistoryView);
