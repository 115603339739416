import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCandidateProfileScreen from './GigCandidateProfileScreen';
import { getGigCandidateProfile } from './companyGigCandidatesActions';
import { invite, remove } from '../../../candidatesPool/companyCandidatesPoolActions';
import { successNotification } from '../../../../common/notifications_center/notificationCenterActions';
import Routing from '../../../../core/routing/index';

const { withRouter } = Routing;

class GigCandidateProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
    };
  }

  componentDidMount() {
    this.loadCandidateProfile();
  }

  onInviteCandidateToPool() {
    this.props.inviteCandidateToPool(this.state.profile.userId).then(() => {
      this.props.successNotification('Candidate has been successfully invited.');
      this.setState({ profile: null });
      this.loadCandidateProfile();
    });
  }

  onRemoveCandidateFromPool(poolCandidateId) {
    this.props.removeCandidateFromPool(poolCandidateId).then(() => {
      this.props.successNotification('Candidate has been successfully removed.');
      this.setState({ profile: null });
      this.loadCandidateProfile();
    });
  }

  loadCandidateProfile() {
    const { gigId, candidateId } = this.props.match.params;
    this.props.getGigCandidateProfile(gigId, candidateId).then((res) => {
      this.setState({ profile: res.payload });
    });
  }

  render() {
    return (
      <GigCandidateProfileScreen
        profile={this.state.profile}
        online={this.props.usersPresence[this.props.match.params.candidateId]}
        postAction={() => this.props.history.goBack()}
        onInviteCandidateToPool={(candidateId) => this.onInviteCandidateToPool(candidateId)}
        onRemoveCandidateFromPool={(poolCandidateId) => this.onRemoveCandidateFromPool(poolCandidateId)}
        grantedFeatures={this.props.grantedFeatures}
      />
    );
  }
}

GigCandidateProfileContainer.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  getGigCandidateProfile: PropTypes.func.isRequired,
  inviteCandidateToPool: PropTypes.func.isRequired,
  removeCandidateFromPool: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  usersPresence: state.chat.get('usersPresence').toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default withRouter(connect(mapStateToProps, {
  getGigCandidateProfile,
  inviteCandidateToPool: invite,
  removeCandidateFromPool: remove,
  successNotification,
})(GigCandidateProfileContainer));
