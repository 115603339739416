import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import CompanyPaymentsScreen from './CompanyPaymentsScreen';
import CompanyBreakdownScreen from './CompanyBreakdownScreen';
import Routing from '../../core/routing/index';
import { getPaymentsOverview, cancelSubscription, getProfitMargin } from './companyPaymentsActions';
import { COMPANY_PAYMENTS_CARD_NEW_ON_HIRE, COMPANY_PAYMENTS_PRICING_PLANS_CHANGE } from '../../constants/routes';

const { withRouter } = Routing;

class CompanyPaymentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { paymentsData: undefined, profitMargin: undefined };
  }

  componentDidMount() {
    this.getPaymentsOverview();
    this.getProfitMargin();
  }

  getPaymentsOverview() {
    this.props.getPaymentsOverview().then((response) => {
      if (!response.isError) {
        this.setState({ paymentsData: response.payload });
      }
    });
  }

  getProfitMargin() {
    this.props.getProfitMargin().then((response) => {
      if (!response.isError) {
        this.setState({ profitMargin: response.payload.value });
      }
    });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  redirectToChangePlan() {
    this.props.history.push(COMPANY_PAYMENTS_PRICING_PLANS_CHANGE);
  }

  cancelSubscription() {
    this.props.cancelSubscription().then(() => {
      this.setState({ paymentsData: undefined });
      this.getPaymentsOverview();
    });
  }

  redirectToBillingProfile(gigId, paymentPlanType, grandTotal, candidateId) {
    const redirectFunc = this.props.history.push;

    redirectFunc(COMPANY_PAYMENTS_CARD_NEW_ON_HIRE, {
      goBackTo: this.props.history.location,
      grandTotal,
      gigId,
      paymentPlanType,
      candidateId
    });
  }

  render() {
    const { isPaymentBreakdown, offer, gigId, paymentPlanType, candidateId } = this.props.location.state;
    return isPaymentBreakdown ? (
      <CompanyBreakdownScreen
        handleBack={this.goBack}
        paymentsData={this.state.paymentsData}
        offer={offer}
        gigId={gigId}
        paymentPlanType={paymentPlanType}
        redirectToBillingProfile={(grandTotal) => this.redirectToBillingProfile(gigId, paymentPlanType, grandTotal, candidateId)}
        profitMargin={this.state.profitMargin}
      />
    ) : (
      <CompanyPaymentsScreen
        handleBack={this.goBack}
        paymentsData={this.state.paymentsData}
        cancelSubscription={() => this.cancelSubscription()}
        changePlan={() => this.redirectToChangePlan()}
      />
    );
  }
}

CompanyPaymentsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getPaymentsOverview: PropTypes.func.isRequired,
  getProfitMargin: PropTypes.func.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  paymentPlanType: PropTypes.string,
  offer: PropTypes.object,
  isPaymentBreakdown: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
  candidateId: PropTypes.string,
};

CompanyPaymentsContainer.defaultProps = {
  isPaymentBreakdown: false,
  gigId: undefined,
  offer: undefined,
  paymentPlanType: undefined,
  candidateId: undefined,
};

export default withRouter(connect(null, { getPaymentsOverview, cancelSubscription, getProfitMargin })(CompanyPaymentsContainer));
