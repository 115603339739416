import { COMPANY_CHAT_DATA_REQUEST, COMPANY_CHATS_REQUEST, COMPANY_GIG_CHATS_DETAILS_REQUEST } from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export function getCompanyChats(handleInternalError = true) {
  return {
    type: COMPANY_CHATS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/chat/gigs',
      },
    },
    handleInternalError,
  };
}
export function getCompanyGigChatsInfo(gigId, handleInternalError = true) {
  return {
    type: COMPANY_GIG_CHATS_DETAILS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/company/chat/gigs/${gigId}`,
      },
    },
    gigId,
    handleInternalError,
  };
}

export function createOrGetCompanyChat(gigId, candidateId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_OPEN_CHAT);
  return {
    type: COMPANY_CHAT_DATA_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/company/chat',
        data: { gigId, candidateId },
      },
    },
    gigId,
    candidateId,
  };
}
