export function getTranslationForCategory(category, activeLanguage) {
  return (!activeLanguage || !category) ? '' : category.translations.filter((c) => c.code === activeLanguage.code)[0].value;
}

export function findCategoryByKey(categories, key) {
  return categories.find((c) => c.key === key);
}

export function getTranslationForCategoryKey(categories, categoryKey, activeLanguage) {
  return getTranslationForCategory(findCategoryByKey(categories, categoryKey), activeLanguage);
}

export function sortCategoriesByOrder(categories, availableCategories) {
  if (!availableCategories || !availableCategories.length) {
    return [];
  }

  return categories.slice().sort((a, b) => {
    const aWithOrder = findCategoryByKey(availableCategories, a);
    const bWithOrder = findCategoryByKey(availableCategories, b);
    if (!aWithOrder && !bWithOrder) {
      return a > b ? 1 : -1;
    }
    if (!aWithOrder) {
      return 1;
    }
    if (!bWithOrder) {
      return -1;
    }
    return aWithOrder.order - bWithOrder.order;
  });
}
