import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { questionnairePropType } from '../../constants/propTypes';
import IconButton, { ICON_SIZE } from '../components/buttons/IconButton';
import PrimaryTextInput from '../components/form/PrimaryTextInput';
import { spacings, wp } from '../styles/base.style';

const rejectImg = require('../../assets/icons/buttonReject.png');

const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: spacings.sm,
  },
  iconButtonWrapper: {
    alignSelf: 'flex-start',
    paddingTop: Platform.select({
      android: 8,
      ios: 5,
      web: 0,
    }),
  },
});

const inputStyle = StyleSheet.create({
  container: {
    marginBottom: 0,
    width: Platform.select({
      web: '100%',
    }),
  },
  input: {
    marginRight: spacings.xs,
    width: Platform.select({
      android: wp(80) - (ICON_SIZE / 2),
      ios: wp(80) - (ICON_SIZE / 2),
    }),
  },
});

const MAX_QUESTION_LENGTH = 1200;

const QuestionnaireFormRow = ({
  questionItem, handleOnChange, handleRemove, translate,
}) => (
  <View style={styles.rowContainer}>
    <PrimaryTextInput
      style={inputStyle}
      value={questionItem.text}
      onChangeText={(newQuestion) => {
        handleOnChange(questionItem, newQuestion);
      }}
      multiline
      numberOfLines={1}
      placeholder={translate('companyGigCreator.questionnaire.defaultQuestionPlaceholder')}
      label={translate('companyGigCreator.questionnaire.questionLabel')}
      maxLength={MAX_QUESTION_LENGTH}
    />
    <IconButton
      style={styles.iconButtonWrapper}
      onPress={() => handleRemove(questionItem.questionId)}
      source={rejectImg}
      label={translate('companyGigCreator.questionnaire.remove')}
    />
  </View>
);

QuestionnaireFormRow.propTypes = {
  translate: PropTypes.func.isRequired,
  questionItem: questionnairePropType.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default withLocalize(QuestionnaireFormRow);
