import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorStepDatesScreen from './GigCreatorStepDatesScreen';
import { gigCreatorAddDates } from '../../gigCreatorActions';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepDatesContainer extends Component {
  constructor(props) {
    super(props);

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.DATES));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.DATES);
  }

  onSubmit = (values, { setStatus, setSubmitting }) => {
    this.props.gigCreatorAddDates(values);

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.START_AND_END_DATE, values)
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      setStatus({ redirect: true });
    }
  };

  render() {
    const {
      startDate, noStartDate, endDate, noEndDate, isEdit,
    } = this.props;

    const initialValues = {
      startDate, noStartDate, endDate, noEndDate,
    };

    return (
      <GigCreatorStepDatesScreen
        stepNumber={this.stepNumber}
        nextScreen={this.nextScreen}
        maxStepsNumber={this.maxStepsNumber}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        isEdit={isEdit}
      />
    );
  }
}

GigCreatorStepDatesContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  startDate: PropTypes.number,
  noStartDate: PropTypes.bool,
  endDate: PropTypes.number,
  noEndDate: PropTypes.bool,
  gigCreatorAddDates: PropTypes.func.isRequired,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCreatorStepDatesContainer.defaultProps = {
  startDate: null,
  noStartDate: false,
  endDate: null,
  noEndDate: false,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      startDate,
      noStartDate,
      endDate,
      noEndDate,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return {
    startDate, noStartDate, endDate, noEndDate, grantedFeatures,
  };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddDates })(GigCreatorStepDatesContainer));
