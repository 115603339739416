import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Row, Rows, Table } from 'react-native-table-component';
import PropTypes from 'prop-types';
import { spacings, standardColors } from '../../../common/styles/base.style';
import Routing from '../../../core/routing/index';
import { LinkButton } from '../../../common/components';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import { ADMIN_ASSOC_DETAILS } from '../../../constants/routes';
import { deleteAssociation } from '../AdminAssociationActions';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});

class AdminAssociationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHead: [props.translate('adminFindAssociationScreen.associationName'), ''],
      flexArr: [1, 1],
    };
  }

  confirmDeletion = (id) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm(this.props.translate('adminFindAssociationScreen.confirmDelete'))) {
      this.props.deleteAssociation(id);
    }
  }

  renderLinks = (assoc) => {
    const detailsLink = (
      <>
        <LinkButton
          title="Edit"
          to={ADMIN_ASSOC_DETAILS}
          replacePathValue={assoc.associationId}
          type={BUTTON_TYPES.TEXT}
          size={BUTTON_SIZES.MD}
          style={{ wrapper: { marginBottom: 0, alignSelf: 'flex-start', marginRight: 5 } }}
        />
        <LinkButton
          title="Delete"
          onPress={() => this.confirmDeletion(assoc.associationId)}
          type={BUTTON_TYPES.TEXT}
          size={BUTTON_SIZES.MD}
          style={{ wrapper: { marginBottom: 0, alignSelf: 'flex-start', marginRight: 5 } }}
        />
      </>
    );

    return <View style={{ flexDirection: 'row' }}>{detailsLink}</View>;
  };

  render() {
    const { associationsList } = this.props;
    const itemsData = associationsList?.associations?.map((a) => [a.name, this.renderLinks(a)] ?? []);
    const { state } = this;
    return (
      <>
        <View style={styles.container}>
          <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
            <Row data={state.tableHead} style={styles.head} textStyle={styles.text} flexArr={state.flexArr} />
            <Rows data={itemsData} textStyle={styles.text} flexArr={state.flexArr} />
          </Table>
        </View>
      </>
    );
  }
}

AdminAssociationsList.propTypes = {
  associationsList: PropTypes.arrayOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
  deleteAssociation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  associationsList: state.admin.get('associations').get('associationsList'),
});

export default withRouter(connect(mapStateToProps, { deleteAssociation })(withLocalize(AdminAssociationsList)));
