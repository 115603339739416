import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import validation from '../../../forms/currentOccupation/CandidateProfileCurrentOccupationFormValidation';
import { LinkButton } from '../../../../../common/components';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';
import FormikWithValidation from '../../../../../common/components/form/FormikWithValidation';
import { OCCUPATION } from '../../../../../constants/occupation';
import FormikFieldSelectList from '../../../../../common/components/form/FormikSelectList';
import Wrapper from '../../../../../common/components/Wrapper';
import Container from '../../../../../common/components/Container';

const getAvailableCurrentOccupations = (translate) => Object.values(OCCUPATION)
  .filter((item) => item !== OCCUPATION.ALL)
  .map((val) => ({
    label: translate(`candidateProfileCreator.currentOccupation.occupation.${val}`),
    value: val,
  }));

const ProfileCreatorStepCurrentOccupationScreen = ({
  stepNumber, translate, nextScreen, redirect, handleSubmit, selectedCurrentOccupation,
}) => (
  <Container>
    <ProfileCreatorHeader
      stepNo={stepNumber}
      title={translate('candidateProfileCreator.currentOccupation.header')}
      infoText={translate('candidateProfileCreator.currentOccupation.currentOccupationDescription')}
    />
    <FormikWithValidation
      validation={validation}
      initialValues={{ currentOccupation: selectedCurrentOccupation }}
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleSubmitFormik, setFieldValue, isBtnDisabled }) => (
        <>
          <Wrapper>
            <FormikFieldSelectList
              items={getAvailableCurrentOccupations(translate)}
              name="currentOccupation"
              multiple
              scroll
              iconPosition="right"
              onValueChange={
                  (val) => {
                    if (val.indexOf(OCCUPATION.SCHOOL_STUDENT) === val.length - 1) {
                      setFieldValue('currentOccupation', val.filter((name) => name !== OCCUPATION.UNIVERSITY_STUDENT));
                    } else if (val.indexOf(OCCUPATION.UNIVERSITY_STUDENT) === val.length - 1) {
                      setFieldValue('currentOccupation', val.filter((name) => name !== OCCUPATION.SCHOOL_STUDENT));
                    }
                    if (val.indexOf(OCCUPATION.PART_TIME_WORKER) === val.length - 1) {
                      setFieldValue('currentOccupation', val.filter((name) => name !== OCCUPATION.FULL_TIME_WORKER));
                    } else if (val.indexOf(OCCUPATION.FULL_TIME_WORKER) === val.length - 1) {
                      setFieldValue('currentOccupation', val.filter((name) => name !== OCCUPATION.PART_TIME_WORKER));
                    }
                  }
                }
            />
          </Wrapper>
          <StickyButtonsWrapper>
            <LinkButton
              to={nextScreen}
              title={translate('generic.next')}
              onPress={handleSubmitFormik}
              doRedirect={redirect}
              disabled={isBtnDisabled}
            />
          </StickyButtonsWrapper>
        </>
      )}
    />
  </Container>
);

ProfileCreatorStepCurrentOccupationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  selectedCurrentOccupation: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
};

export default withLocalize(ProfileCreatorStepCurrentOccupationScreen);
