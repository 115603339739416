import React from 'react';
import {
  CANDIDATE_MY_POOLS,
  CANDIDATE_MY_POOLS_POOLS,
  CANDIDATE_MY_POOLS_GROUPS,
  CANDIDATE_MY_POOLS_SHIFTS,
  CANDIDATE_MY_POOLS_POOL_PROFILE,
  CANDIDATE_MY_POOLS_GROUP_CHAT,
  CANDIDATE_MY_POOLS_DIRECT_CHAT,
} from '../../constants/routes';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../../constants/userScopes';
import CandidateMyPoolsTabsContainer from './CandidateMyPoolsTabsContainer';
import CandidatesPoolProfileContainer from './pools/CandidatesPoolProfileContainer';
import CandidateMyPoolsGroupChatContainer from './chat/CandidatePoolGroupChatContainer';
import CandidateMyPoolsDirectChatContainer from './chat/CandidatePoolDirectChatContainer';

const { Route, Redirect } = Routing;

const CandidateMyPoolsRouting = () => (
  <>
    <Route exact path={CANDIDATE_MY_POOLS} render={() => <Redirect to={CANDIDATE_MY_POOLS_POOLS} />} />
    <AuthorizedRoute
      only={CANDIDATE_USER}
      path={[CANDIDATE_MY_POOLS_POOLS, CANDIDATE_MY_POOLS_GROUPS, CANDIDATE_MY_POOLS_SHIFTS]}
      component={CandidateMyPoolsTabsContainer}
    />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS_POOL_PROFILE} component={CandidatesPoolProfileContainer} />
    <AuthorizedRoute exact only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS_GROUP_CHAT} component={CandidateMyPoolsGroupChatContainer} />
    <AuthorizedRoute exact only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS_DIRECT_CHAT} component={CandidateMyPoolsDirectChatContainer} />
  </>
);

export default CandidateMyPoolsRouting;
