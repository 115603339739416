import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import Container from '../../common/components/Container';
import FormikFieldPrimaryTextInput from '../../common/components/form/FormikFieldPrimaryTextInput';
import FormikWithValidation from '../../common/components/form/FormikWithValidation';
import AdminNewNotificationValidation, {
  MAX_TEXT_LENGTH, MAX_TITLE_LENGTH, MAX_USER_IDS_LENGTH,
} from './AdminNewNotificationValidation';
import Button from '../../common/components/buttons/Button';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../common/styles/base.style';
import { adminSendNotification } from './AdminNotificationActions';
import { font } from '../../common/styles/mixins';
import GeneralFormError from '../../common/components/form/GeneralFormError';
import FormikFieldFileInput from '../../common/components/form/FormikFieldFileInput';
import PrimaryText from '../../common/components/PrimaryText';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacings.md,
    paddingVertical: spacings.sm,
  },
  successContainer: {
    padding: spacings.md,
    backgroundColor: colors.greenishTeal,
    marginBottom: spacings.sm,
  },
  successText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0, colors.white),
  },
  errorText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0, colors.magenta),
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class AdminNewNotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  handleSubmit = (values, { setErrors, setSubmitting, resetForm }) => {
    setSubmitting(true);
    this.props.adminSendNotification({
      type: 'PUSH',
      receivers: values.userIds.split(',')
        .map((id) => id.trim()),
      enTitle: values.enTitle,
      svTitle: values.svTitle,
      enText: values.enText,
      svText: values.svText,
      receiversCsv: values.receiversCsv,
    }).then((response) => {
      if (response.isError) {
        setErrors(response.errors);
        setSubmitting(false);
      } else {
        setSubmitting(false);
        resetForm();
        this.setState({ success: true });
      }
    });
  }

  render() {
    return (
      <Container style={styles.container}>
        <PrimaryText>
          You can use either `&quot;`User IDs`&quot;` field or upload a CSV file.
          If you fill in both, only CSV will be used.
        </PrimaryText>
        <FormikWithValidation
          validation={AdminNewNotificationValidation}
          initialValues={{
            userIds: '',
            svTitle: '',
            svText: '',
            enTitle: '',
            enText: '',
            receiversCsv: null,
          }}
          onSubmit={this.handleSubmit}
          render={({ handleSubmit, isBtnDisabled, errors }) => (
            <>
              <FormikFieldPrimaryTextInput
                name="userIds"
                multiline
                numberOfLines={7}
                label="User IDs"
                placeholder="abdc-abdc-abdc-abdc,efgh-efgh-efgh-efgh"
                maxLength={MAX_USER_IDS_LENGTH}
              />
              <FormikFieldFileInput
                name="receiversCsv"
                label="Receivers CSV (instead of User IDs)"
                accept=".csv"
              />
              <FormikFieldPrimaryTextInput
                name="svTitle"
                multiline
                numberOfLines={1}
                label="SWEDISH title"
                placeholder="This will be the title"
                maxLength={MAX_TITLE_LENGTH}
                counter
              />
              <FormikFieldPrimaryTextInput
                name="svText"
                multiline
                numberOfLines={2}
                label="SWEDISH text"
                placeholder="This will be the main text"
                maxLength={MAX_TEXT_LENGTH}
                counter
              />
              <FormikFieldPrimaryTextInput
                name="enTitle"
                multiline
                numberOfLines={1}
                label="ENGLISH title"
                placeholder="This will be the title"
                maxLength={MAX_TITLE_LENGTH}
                counter
              />
              <FormikFieldPrimaryTextInput
                name="enText"
                multiline
                numberOfLines={2}
                label="ENGLISH text"
                placeholder="This will be the main text"
                maxLength={MAX_TEXT_LENGTH}
                counter
              />
              {this.state.success && (
              <View style={styles.successContainer}>
                <Text style={styles.successText}>Request accepted, push notifications are being sent...</Text>
              </View>
              )}
              <GeneralFormError errors={errors} />
              <Button
                title="SEND"
                disabled={isBtnDisabled}
                onPress={handleSubmit}
              />
            </>
          )}
        />
      </Container>
    );
  }
}

AdminNewNotificationContainer.propTypes = {
  adminSendNotification: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  adminSendNotification,
})(AdminNewNotificationContainer);
