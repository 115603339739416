import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View, ScrollView } from 'react-native';

import { withLocalize } from 'react-localize-redux';
import Container from '../../common/components/Container';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import { fontFamily, fontSizes, spacings } from '../../common/styles/base.style';

import { font } from '../../common/styles/mixins';
import SectionHeader from '../../common/components/header/SectionHeader';
import Wrapper from '../../common/components/Wrapper';
import KeyValueRow from '../../common/components/KeyValueRow';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../common/components/buttons/Button';

const mainFont = font(fontFamily.SFProDisplayRegular, fontSizes.md);

const styles = StyleSheet.create({
  sectionWrapper: {
    marginBottom: spacings.sm,
  },
  mainFont: {
    ...mainFont,
  },
  cardBrand: {
    ...mainFont,
    paddingRight: spacings.sm,
    flex: 1,
  },
  cardLast4: {
    ...mainFont,
    paddingRight: spacings.sm,
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  toRight: {
    ...mainFont,
    textAlign: 'right',
    marginTop: 5,
    fontWeight: 'bold',
  },
  changePlanButton: {
    marginBottom: 10,
    alignSelf: 'flex-end',
  },
});
// Function to calculate holiday pay
function calculateHolidayPay(baseSalaryPerHour, holidayPayPercentage) {
  return ((Number(baseSalaryPerHour) * Number(holidayPayPercentage)) / 100);
}

// Function to calculate subtotal
function calculateSubtotal(baseSalaryPerHour, holidayPay) {
  return (Number(baseSalaryPerHour) + Number(holidayPay));
}

// Function to calculate labor cost
function calculateLaborCost(subtotal, laborCostPercentage) {
  return ((Number(subtotal) * Number(laborCostPercentage)) / 100);
}

// Function to calculate special payroll tax
function calculateSpecialPayrollTax(subtotal, specialPayrollTaxPercentage, fixedRate) {
  return (subtotal * (specialPayrollTaxPercentage / 100)) * (fixedRate / 100);
}

function calculateSurcharge(total, surchargePercentage) {
  return ((Number(total) * Number(surchargePercentage)) / 100);
}

function formatCurrencySEK(currencyValue) {
  // Add spaces as thousands separators
  const parts = currencyValue.toString().split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Assuming you want to add SEK as the currency symbol
  return `${parts.join(',')} SEK`;
}

function calculateVAT(total, vatPercentage) {
  return ((Number(total) * Number(vatPercentage)) / 100);
}

const CompanyBreakdownScreen = ({ translate, handleBack, offer, redirectToBillingProfile, profitMargin }) => {
  const baseSalary = Number(offer.compensation.salary);
  const holidayPay = Math.round(calculateHolidayPay(baseSalary, 12));
  const subTotal = Math.round(Number(calculateSubtotal(baseSalary, holidayPay)));
  const laborCost = Math.round(calculateLaborCost(subTotal, 31.42));
  const specialPayrollTax = Math.round(calculateSpecialPayrollTax(subTotal, 24.26, 4.5));
  const total = Math.round(subTotal + laborCost + specialPayrollTax);
  const fee = Math.round(calculateSurcharge(total, Number(profitMargin)));
  const hours = offer.workload;
  const costPerHourExVat = baseSalary + holidayPay + laborCost + specialPayrollTax + fee;
  const grandTotal = hours * costPerHourExVat;
  const grandTotalWithVat = calculateVAT(grandTotal, 25);
  const totalPaymentWithVat = grandTotal + grandTotalWithVat;
  return (
    <Container>
      <ScreenHeader title={translate('company.payments.mainView.header')} showBackArrow handleBack={handleBack} shadow />
      <ScrollView>
        <View>
          <SectionHeader title="" />
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow label={translate('company.payments.mainView.breakdown.hourlyWage')} value={formatCurrencySEK(baseSalary)} />
          </Wrapper>
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow label={`${translate('company.payments.mainView.breakdown.holidayPay')} (12%)`} value={formatCurrencySEK(holidayPay)} />
          </Wrapper>
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow
              label={`${translate('company.payments.mainView.breakdown.labourCost')} (31,42%)`}
              value={formatCurrencySEK(laborCost)}
            />
          </Wrapper>
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow
              label={`${translate('company.payments.mainView.breakdown.specialPayrollTax')}`}
              value={formatCurrencySEK(specialPayrollTax)}
            />
          </Wrapper>
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow label={`${translate('company.payments.mainView.breakdown.fee')}`} value={formatCurrencySEK(fee)} />
          </Wrapper>
          <SectionHeader title={translate('company.payments.mainView.breakdown.costPerHourExVat')}>
            <View>
              <Text style={styles.toRight}>{formatCurrencySEK(costPerHourExVat)}</Text>
            </View>
          </SectionHeader>
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow label={translate('company.payments.mainView.breakdown.noOfHours')} value={hours?.toString()} />
          </Wrapper>
          <SectionHeader title={translate('company.payments.mainView.breakdown.totalToBePaidExVat')}>
            <View>
              <Text style={styles.toRight}>{formatCurrencySEK(grandTotal)}</Text>
            </View>
          </SectionHeader>
          <Wrapper noFlex style={styles.sectionWrapper}>
            <KeyValueRow label={`${translate('company.payments.mainView.breakdown.vat')} (25%)`} value={formatCurrencySEK(grandTotalWithVat)} />
          </Wrapper>
          <SectionHeader title={translate('company.payments.mainView.breakdown.totalToBePaid')}>
            <View>
              <Text style={styles.toRight}>{formatCurrencySEK(totalPaymentWithVat)}</Text>
            </View>
          </SectionHeader>
        </View>

        <Button
          size={BUTTON_SIZES.MD}
          type={BUTTON_TYPES.PRIMARY_RED}
          title={translate('generic.next')}
          onPress={() => redirectToBillingProfile(totalPaymentWithVat)}
        />
      </ScrollView>
    </Container>
  );
};

CompanyBreakdownScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  offer: PropTypes.object.isRequired,
  redirectToBillingProfile: PropTypes.func.isRequired,
  profitMargin: PropTypes.string.isRequired,
};

export default withLocalize(CompanyBreakdownScreen);
