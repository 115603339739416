import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import CompanyShiftCandidatesScreen from './CompanyShiftCandidatesScreen';
import { getShift } from '../companyShiftsActions';
import { COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE } from '../../../constants/routes';
import Routing from '../../../core/routing/index';

const { withRouter } = Routing;

class CompanyShiftCandidatesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shift: null,
    };
  }

  componentDidMount() {
    const { gigId } = this.props.match.params;
    this.props.getShift(gigId)
      .then((response) => {
        if (!response.isError) {
          this.setState({ shift: response.payload });
        }
      });
  }

  goToCandidate = (candidateId) => {
    this.props.history.push(COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE.replace(':candidateId', candidateId));
  };

  render() {
    return <CompanyShiftCandidatesScreen shift={this.state.shift} onOpenCandidate={this.goToCandidate} />;
  }
}

CompanyShiftCandidatesContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getShift: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default connect(null, { getShift })(withRouter(CompanyShiftCandidatesContainer));
