import { ActivityIndicator, StyleSheet, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import Routing from '../../../../core/routing/index';
import { LinkButton } from '../../../../common/components';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import validation, { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from './CompanyCandidatesPoolGroupEditableValidation';
import ScreenHeader from '../../../../common/components/header/ScreenHeader';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import { COMPANY_CANDIDATES_POOL_GROUP_VIEW, COMPANY_CANDIDATES_POOL_GROUPS } from '../../../../constants/routes';
import {
  activityIndicator, fontFamily, fontSizes, shadowTop, spacings,
} from '../../../../common/styles/base.style';
import { poolGroupPropType } from '../../../../constants/propTypes';
import FormikSelectList from '../../../../common/components/form/FormikSelectList';
import { SelectListItemDataPropType } from '../../../../common/components/form/SelectListItem';
import PrimaryText from '../../../../common/components/PrimaryText';
import { font } from '../../../../common/styles/mixins';
import ViewportController from '../../../../common/components/ViewportController';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
  titleLabel: {
    textAlign: 'center',
    ...font(fontFamily.SFProTextSemiBold, fontSizes.md),
    paddingTop: spacings.md,
    marginBottom: spacings.md,
  },
});

class CompanyCandidatesPoolGroupEditableScreen extends React.PureComponent {
  render() {
    const {
      translate, handleSubmit, isEdit, poolGroup, availablePoolCandidates,
    } = this.props;

    const translateByKey = (key) => translate(`company.candidatesPool.group.${key}`);

    if ((isEdit && !poolGroup) || !availablePoolCandidates) {
      return (<ActivityIndicator size="large" style={{ ...activityIndicator }} />);
    }

    return (
      <ViewportController safeAreaBottom>
        <FormikWithValidation
          validation={validation}
          initialValues={poolGroup ? {
            name: poolGroup.name,
            description: poolGroup.description,
            candidates: poolGroup.candidates.map((item) => item.userId),
          } : { candidates: [] }}
          onSubmit={handleSubmit}
          render={({ handleSubmit: handleSubmitFormik, status, isBtnDisabled }) => (
            <>
              <ScreenHeader
                title={isEdit ? translateByKey('headerGroupModify') : translateByKey('headerGroupNew')}
                showBackArrow
                shadow
              />
              <KeyboardAwareFlexWrapper style={{ paddingTop: spacings.md }}>
                <FormikFieldPrimaryTextInput
                  name="name"
                  label={translateByKey('name')}
                  maxLength={MAX_NAME_LENGTH}
                  counter
                  autoscroll
                  scrollEnabled={false}
                  returnKeyType="done"
                />
                <FormikFieldPrimaryTextInput
                  name="description"
                  label={translateByKey('description')}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  counter
                  autoscroll
                  scrollEnabled={false}
                  returnKeyType="done"
                />
                {availablePoolCandidates.length > 0 && (
                  <>
                    <PrimaryText style={styles.titleLabel}>{translateByKey('availableCandidates')}</PrimaryText>
                    <FormikSelectList
                      items={availablePoolCandidates}
                      name="candidates"
                      multiple
                      scroll
                      iconPosition="right"
                    />
                  </>
                )}
              </KeyboardAwareFlexWrapper>
              <View style={styles.button}>
                {!isEdit && (
                  <LinkButton
                    to={COMPANY_CANDIDATES_POOL_GROUPS}
                    title={translate('generic.create')}
                    doRedirect={status && status.redirect}
                    disabled={isBtnDisabled}
                    onPress={handleSubmitFormik}
                  />
                )}
                {isEdit && (
                  <LinkButton
                    replace
                    to={COMPANY_CANDIDATES_POOL_GROUP_VIEW.replace(':groupId', poolGroup.groupId)}
                    title={translate('generic.save')}
                    doRedirect={status && status.redirect}
                    disabled={isBtnDisabled}
                    onPress={handleSubmitFormik}
                  />
                )}
              </View>
            </>
          )}
        />
      </ViewportController>
    );
  }
}

CompanyCandidatesPoolGroupEditableScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  poolGroup: poolGroupPropType,
  availablePoolCandidates: PropTypes.arrayOf(PropTypes.shape(SelectListItemDataPropType)),
};

CompanyCandidatesPoolGroupEditableScreen.defaultProps = {
  poolGroup: undefined,
  availablePoolCandidates: undefined,
};

export default withLocalize(withRouter(CompanyCandidatesPoolGroupEditableScreen));
