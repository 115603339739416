import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import {
  colors, dynamicSpacings, fontFamily, fontSizes, spacings,
} from '../../styles/base.style';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.veryLightPinkTwo,
    marginBottom: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
  },
  header: {
    fontSize: fontSizes.md,
    paddingVertical: spacings.xs,
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.brownishGrey,
  },
});
const SectionHeader = ({ title, children, style }) => (
  <View style={[styles.wrapper, style && style.wrapper]}>
    <Text style={[styles.header, style && style.header]}>{title}</Text>
    {children}
  </View>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: stylePropTypes,
};

SectionHeader.defaultProps = {
  children: null,
  style: undefined,
};

export default SectionHeader;
