// eslint-disable-next-line no-unused-vars
const ReduxSimpleLogger = (store) => (next) => (action) => {
  if (process.env.NODE_ENV === 'development') {
    console.group(action.type);
    console.info('dispatching', action);
    const result = next(action);
    console.groupEnd();
    return result;
  }

  return next(action);
};

export default ReduxSimpleLogger;
