import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../../core/routing/index';
import PricingPlansScreen from './PricingPlansScreen';
import { changePricingPlan, getCurrentSubscription, getPricingPlans } from '../companyPaymentsActions';
import { successNotification, infoNotification } from '../../../common/notifications_center/notificationCenterActions';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../constants/subscriptionTypes';

const { withRouter } = Routing;

class PricingPlansContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { plans: null };
  }

  componentDidMount() {
    Promise.all([this.props.getPricingPlans(), this.props.getCurrentSubscription()])
      .then((results) => {
        const [{
          payload: {
            plans,
          },
        }, {
          payload: {
            plan: currentPlan,
          },
        }] = results;

        const availablePlans = plans.filter((plan) => plan.planId !== currentPlan.planId);
        this.setState({ plans: availablePlans });
      });
  }

  onChoosePlan = (selectedPricingPlanId) => {
    this.props.changePricingPlan(selectedPricingPlanId)
      .then(({ isError, payload }) => {
        if (!isError) {
          if (payload.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE) {
            this.props.successNotification('company.payments.subscription.subscribe.successTitle');
          } else {
            this.props.infoNotification('company.payments.subscription.subscribe.pendingTitle', 'company.payments.subscription.subscribe.pendingText');
          }
          this.goBack();
        }
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <PricingPlansScreen
        handleBack={this.goBack}
        plans={this.state.plans}
        onChoosePlan={this.onChoosePlan}
        chooseButtonLabel="changePlanButton"
      />
    );
  }
}

PricingPlansContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getPricingPlans: PropTypes.func.isRequired,
  changePricingPlan: PropTypes.func.isRequired,
  getCurrentSubscription: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  infoNotification: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PricingPlansContainer.defaultProps = {
  location: null,
};

export default connect(null, {
  getPricingPlans,
  getCurrentSubscription,
  changePricingPlan,
  successNotification,
  infoNotification,
})(withRouter(PricingPlansContainer));
