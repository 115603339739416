import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { loginUser } from '../../common/auth/authActions';
import LoginScreen from '../../common/auth/LoginScreen';
import Routing from '../../core/routing/index';
import { FIRST_CHECK_USER_STATUS, NOTIFICATION_PERMISSION, WELCOME } from '../../constants/routes';

const { withRouter } = Routing;

class LoginContainer extends React.Component {
  onSubmit = (values, { setErrors, setSubmitting }) => {
    this.props.loginUser({ ...values, locale: this.props.locale })
      .then((response) => {
        if (response.isError) {
          setErrors(response.errors);
          setSubmitting(false);
        }
      });
  };

  onBack = () => {
    if (this.props.history.length === 1) {
      this.props.history.replace(WELCOME);
    }
    this.goBack();
  };

  getRedirectPathAfterLogin() {
    switch (this.props.loginOnNewInstallation) {
      case false:
        return FIRST_CHECK_USER_STATUS;
      case true:
        return NOTIFICATION_PERMISSION;
      default:
        return undefined;
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <LoginScreen
        onSubmit={this.onSubmit}
        onBack={this.onBack}
        sessionTimedOut={this.props.sessionTimedOut}
        redirect={this.props.authenticated && this.props.loginOnNewInstallation != null}
        redirectTo={this.getRedirectPathAfterLogin()}
      />
    );
  }
}

LoginContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  loginUser: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  sessionTimedOut: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  loginOnNewInstallation: PropTypes.bool,
};

LoginContainer.defaultProps = {
  loginOnNewInstallation: undefined,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  sessionTimedOut: state.auth.get('timeout'),
  locale: state.locale.get('locale'),
  loginOnNewInstallation: state.auth.get('loginOnNewInstallation'),
});

export default withRouter(connect(mapStateToProps, { loginUser })(LoginContainer));
