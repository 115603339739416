import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { CANDIDATE_USER } from '../../constants/userScopes';
import {
  CANDIDATE_GIGS, CANDIDATE_FIND_GIGS, CANDIDATE_MY_GIGS, CANDIDATE_GIG_DETAILS,
} from '../../constants/routes';
import CandidateMyGigsRouting from './my_gigs/CandidateMyGigsRouting';
import CandidateGigDetailsContainer from './CandidateGigDetailsContainer';
import CandidateFindGigsRouting from './find_gigs/CandidateFindGigsRouting';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = CANDIDATE_FIND_GIGS;

const CandidateGigRouting = () => (
  <>
    <Route exact path={CANDIDATE_GIGS} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_FIND_GIGS} component={CandidateFindGigsRouting} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_MY_GIGS} component={CandidateMyGigsRouting} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_GIG_DETAILS} component={CandidateGigDetailsContainer} />
  </>
);

export default CandidateGigRouting;
