import {
  COMPANY_GIG_CANDIDATE_PROFILE_REQUEST,
  COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST,
  COMPANY_GIG_REJECT_CANDIDATE_REQUEST,
  COMPANY_GIG_CLEAR_CANDIDATES,
  COMPANY_GIG_GET_CANDIDATES_REQUEST,
  COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST,
  COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST,
  COMPANY_GIG_HIRE_BY_OWN_REQUEST,
  COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST,
  COMPANY_GIG_GET_OFFER_REQUEST,
  COMPANY_GIG_STORE_SCROLL_POSITION,
} from '../../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const handleGigValidationError = {
  titleKey: 'companyGigs.candidates.errors.genericErrorTitle',
  textKey: 'companyGigs.candidates.errors.gigStateChanged',
};

export function clearGigCandidates(listKey) {
  return {
    type: COMPANY_GIG_CLEAR_CANDIDATES,
    listKey,
  };
}

export function getGigCandidates(gigId, statuses, listKey) {
  return {
    type: COMPANY_GIG_GET_CANDIDATES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `company/gig/${gigId}/candidate`,
        params: {
          statuses,
        },
      },
    },
    listKey,
  };
}

export function suggestCandidates(gigId, listKey) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_SUGGEST_CANDIDATES);
  return {
    type: COMPANY_GIG_GET_CANDIDATES_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `company/gig/${gigId}/candidate/suggest`,
      },
    },
    listKey,
  };
}

export function excludeFromSuggested(listKey, gigId, candidateId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_EXCLUDE_CANDIDATE);
  return {
    type: COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `company/gig/${gigId}/candidate/${candidateId}/excludeFromSuggested`,
      },
    },
    candidateId,
    handleValidationError: handleGigValidationError,
  };
}

export function getGigCandidateProfile(gigId, candidateId) {
  return {
    type: COMPANY_GIG_CANDIDATE_PROFILE_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/company/gig/${gigId}/candidate/${candidateId}`,
      },
    },
  };
}

export function shortlistCandidate(listKey, gigId, candidateId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_SHORTLIST);
  return {
    type: COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `company/gig/${gigId}/candidate/${candidateId}/shortlist`,
      },
    },
    listKey,
    candidateId,
    handleValidationError: handleGigValidationError,
  };
}

export function sendOfferToCandidate(gigId, candidateId, { listKey }, offer) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_SEND_OFFER);
  return {
    type: COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `company/gig/${gigId}/candidate/${candidateId}/offerGig`,
        data: { ...offer, compensation: { ...offer.compensation, salary: parseInt(offer.compensation.salary, 10) } },
      },
    },
    candidateId,
    listKey,
    handleValidationError: handleGigValidationError,
  };
}

export function hireByOwn(gigId, candidateId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_HIRE_OWN);
  return {
    type: COMPANY_GIG_HIRE_BY_OWN_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/company/gig/${gigId}/candidate/${candidateId}/hireByCompany`,
      },
    },
    candidateId,
    handleValidationError: handleGigValidationError,
  };
}

export function hireByLetsgig(gigId, candidateId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_HIRE_LG);
  return {
    type: COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `company/gig/${gigId}/candidate/${candidateId}/hireByLetsgig`,
      },
    },
    candidateId,
    handleValidationError: handleGigValidationError,
  };
}

export function rejectCandidate(listKey, gigId, candidateId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.COMPANY_REJECT_CANDIDATE);
  return {
    type: COMPANY_GIG_REJECT_CANDIDATE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `company/gig/${gigId}/candidate/${candidateId}/reject`,
      },
    },
    candidateId,
    listKey,
    handleValidationError: handleGigValidationError,
  };
}

export function getGigOffer(gigId, candidateId) {
  return {
    type: COMPANY_GIG_GET_OFFER_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `company/gig/${gigId}/candidate/${candidateId}/offer`,
      },
    },
    candidateId,
  };
}

export function storeMyGigsScrollPosition(scrollPosition) {
  return {
    type: COMPANY_GIG_STORE_SCROLL_POSITION,
    scrollPosition,
  };
}
