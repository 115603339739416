import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Routing from '../../../core/routing/index';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';

import CompanyGigMatchingHeader from './CompanyGigMatchingHeader';
import { gigItemPropType } from '../../../constants/propTypes';
import {
  COMPANY_GIG_MATCHING,
  COMPANY_GIG_MATCHING_REVIEW,
  COMPANY_GIG_MATCHING_SHORTLISTED,
  COMPANY_GIG_MATCHING_MATCHED,
  COMPANY_GIG_MATCHING_HISTORY,
  COMPANY_GIG_MATCHING_OFFERS,
  COMPANY_GIG_MATCHING_HIRED,
} from '../../../constants/routes';
import { COMPANY_USER } from '../../../constants/userScopes';
import TabBar from '../../../common/components/tabs/TabBar';
import CompanyGigCandidatesToReviewView from './candidates/tabs/CompanyGigCandidatesToReviewView';
import CompanyGigShortlistedCandidatesView from './candidates/tabs/CompanyGigShortlistedCandidatesView';
import CompanyGigOffersView from './candidates/tabs/CompanyGigOffersView';
import CompanyGigHistoryView from './candidates/tabs/CompanyGigHistoryView';
import { getGig } from '../companyGigActions';
import Container from '../../../common/components/Container';
import { shadowBottom } from '../../../common/styles/base.style';
import { GIG_CANDIDATE_STATUS } from '../../../constants/matchingStatus';
import CompanyGigMatchedCandidatesView from './candidates/tabs/CompanyGigMatchedCandidatesView';
import CompanyGigHiredView from './candidates/tabs/CompanyGigHiredView';
import { TAB_NAMES } from '../../../constants/gigCandidateStatusConfig';
import { checkPaymentIntent } from '../../payments/companyPaymentsActions';
import { successNotification, errorNotification } from '../../../common/notifications_center/notificationCenterActions';
import { hireByLetsgig } from './candidates/companyGigCandidatesActions';

const { SUGGESTED, APPLIED, PENDING_SHORTLIST_APPROVAL, PENDING_GIG_OFFER, GIG_OFFER_ACCEPTED, SHORTLISTED, HIRED } = GIG_CANDIDATE_STATUS;

const { Route, Redirect, withRouter } = Routing;

const FIRST_SCREEN = COMPANY_GIG_MATCHING_REVIEW;

const styles = StyleSheet.create({
  shadow: {
    ...shadowBottom,
  },
});

class CompanyGigMatching extends React.Component {
  componentDidMount() {
    if (!this.props.gig) {
      this.props.getGig(this.props.match.params.id);
    }

    const querySearch = queryString.parse(this.props.location.search);

    if (querySearch.payment_intent) {
      this.getPaymentIntent(querySearch.payment_intent, querySearch.gigId, querySearch.candidateId);
    }
  }

  getPaymentIntent = (paymentIntentId, gigId, candidateId) => {
    const bodyData = {
      paymentIntentId,
    };
    this.props.checkPaymentIntent(bodyData).then(async (data) => {
      const intentStatus = data.payload.status;
      if (intentStatus === 'requires_capture') {
        await this.props.hireByLetsgig(gigId, candidateId);
        this.props.successNotification('company.payments.directHiring.successPayTitle');
        this.props.history.replace(COMPANY_GIG_MATCHING_HIRED.replace(':id', gigId));
      } else {
        this.props.errorNotification('company.payments.directHiring.failedPayTitle');
        this.props.history.replace(COMPANY_GIG_MATCHING_OFFERS.replace(':id', gigId));
      }
    });
  };

 isNewContentInTab = (status) => !!this.props.newContentIndicators.find(({ gigId, gigCandidateStatus }) => gigId === this.props.gig.gigId && gigCandidateStatus === status);

  postCloseCallback = () => this.props.getGig(this.props.match.params.id);

  render() {
    const tabIndex = parseInt(queryString.parse(this.props.location.search).tabIndex, 10);

    const { gig } = this.props;
    if (!gig) {
      return null;
    }
    return (
      <>
        <Route exact path={COMPANY_GIG_MATCHING} render={() => <Redirect to={FIRST_SCREEN.replace(':id', gig.gigId)} />} />
        <View style={styles.shadow}>
          <CompanyGigMatchingHeader gig={gig} postCloseCallback={this.postCloseCallback} />
          <TabBar
            items={[
              {
                textKey: 'companyGigs.details.reviewLabel',
                link: COMPANY_GIG_MATCHING_REVIEW.replace(':id', gig.gigId),
                showCounter: !!gig.candidatesCount,
                counterValue: gig.candidatesCount ? gig.candidatesCount[SUGGESTED] + gig.candidatesCount[APPLIED] : undefined,
                isNewContent: this.isNewContentInTab(TAB_NAMES.REVIEW),
                tabIndex: 1,
              },
              {
                textKey: 'companyGigs.details.shortlistedLabel',
                link: COMPANY_GIG_MATCHING_SHORTLISTED.replace(':id', gig.gigId),
                showCounter: !!gig.candidatesCount,
                counterValue: gig.candidatesCount ? gig.candidatesCount[PENDING_SHORTLIST_APPROVAL] : undefined,
                isNewContent: this.isNewContentInTab(PENDING_SHORTLIST_APPROVAL),
                tabIndex: 2,
              },
              {
                textKey: 'companyGigs.details.matchedLabel',
                link: COMPANY_GIG_MATCHING_MATCHED.replace(':id', gig.gigId),
                showCounter: !!gig.candidatesCount,
                counterValue: gig.candidatesCount ? gig.candidatesCount[SHORTLISTED] : undefined,
                isNewContent: this.isNewContentInTab(SHORTLISTED),
                tabIndex: 3,
              },
              {
                textKey: 'companyGigs.details.offersLabel',
                link: COMPANY_GIG_MATCHING_OFFERS.replace(':id', gig.gigId),
                showCounter: !!gig.candidatesCount,
                counterValue: gig.candidatesCount ? gig.candidatesCount[PENDING_GIG_OFFER] + gig.candidatesCount[GIG_OFFER_ACCEPTED] : undefined,
                isNewContent: this.isNewContentInTab(GIG_OFFER_ACCEPTED),
                tabIndex: 4,
              },
              {
                textKey: 'companyGigs.details.hiredLabel',
                link: COMPANY_GIG_MATCHING_HIRED.replace(':id', gig.gigId),
                showCounter: !!gig.candidatesCount,
                counterValue: gig.candidatesCount ? gig.candidatesCount[HIRED] : undefined,
                tabIndex: 5,
              },
              {
                textKey: 'companyGigs.details.historyLabel',
                link: COMPANY_GIG_MATCHING_HISTORY.replace(':id', gig.gigId),
                tabIndex: 6,
              },
            ]}
            onTabPressCallback={() => {
              this.props.getGig(this.props.match.params.id);
            }}
            scrollToTabIndex={tabIndex}
          />
        </View>
        <Container>
          <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING_REVIEW} component={CompanyGigCandidatesToReviewView} />
          <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING_SHORTLISTED} component={CompanyGigShortlistedCandidatesView} />
          <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING_MATCHED} component={CompanyGigMatchedCandidatesView} />
          <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING_OFFERS} component={CompanyGigOffersView} />
          <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING_HIRED} component={CompanyGigHiredView} />
          <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING_HISTORY} component={CompanyGigHistoryView} />
        </Container>
      </>
    );
  }
}

CompanyGigMatching.propTypes = {
  getGig: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  gig: PropTypes.shape(gigItemPropType),
  newContentIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      gigId: PropTypes.string.isRequired,
      gigCandidateStatus: PropTypes.string.isRequired,
    })
  ).isRequired,
  checkPaymentIntent: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  errorNotification: PropTypes.func.isRequired,
  hireByLetsgig: PropTypes.func.isRequired,
};

CompanyGigMatching.defaultProps = {
  gig: null,
};

const mapStateToProps = (state, props) => {
  const gigId = props.match.params.id;
  const gigDataToPreview = state.companyGig.get('gigsList').get('gigDataToPreview');
  return {
    gig: gigDataToPreview && gigDataToPreview.get('gigId') === gigId ? gigDataToPreview.toJS() : null,
    newContentIndicators: state.companyGig.get('gigsList').get('newContentIndicators').toJS(),
  };
};

export default connect(mapStateToProps, { getGig, checkPaymentIntent, successNotification, hireByLetsgig, errorNotification })(withRouter(CompanyGigMatching));
