import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorStepCategoryScreen from './GigCreatorStepCategoryScreen';
import { gigCreatorAddCategories } from '../../gigCreatorActions';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepCategoryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.CATEGORY));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.CATEGORY);
  }

  handleSubmit = ({ categories }, { setSubmitting }) => {
    this.props.gigCreatorAddCategories(categories);
    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.CATEGORIES, { categories })
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      this.doRedirect();
    }
  };

  doRedirect = () => this.setState({ redirect: true });

  render = () => (
    <GigCreatorStepCategoryScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      selectedCategories={this.props.categories}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  );
}

GigCreatorStepCategoryContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  gigCreatorAddCategories: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      categories,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();
  return { categories, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddCategories })(GigCreatorStepCategoryContainer));
