import React, { Component } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikFieldMonthYearPicker from '../../../../common/components/form/FormikFieldMonthYearPicker';
import FormikFieldPrimarySwitch from '../../../../common/components/form/switch/FormikFieldPrimarySwitch';
import { activeLanguagePropTypes, candidateExperiencePropType, gigCategoryPropType } from '../../../../constants/propTypes';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';
import validation, {
  CANDIDATE_PROFILE_COMPANY_NAME_MAX_LENGTH,
  CANDIDATE_PROFILE_DESCRIPTION_MAX_LENGTH,
  CANDIDATE_PROFILE_POSITION_MAX_LENGTH,
} from './CandidateProfileExperienceFormValidation';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import Wrapper from '../../../../common/components/Wrapper';
import { getAvailableGigCategories } from '../../../../common/gig/gigCategoriesActions';
import FormikFieldPrimaryInputTextNextSupport from '../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';

class CandidateProfileExperienceForm extends Component {
  constructor(props) {
    super(props);
    this.state = { btnInsideScrollView: false };
  }
  componentDidMount() {
    this.props.getAvailableGigCategories();
  }

  getTranslatedCategories = () => this.props.availableCategories.map((c) => ({
    value: c.key,
    label: c.translations.find((t) => t.code === this.props.activeLanguage.code).value,
  }));

  keyboardDidShow = () => {
    this.setState({ btnInsideScrollView: true });
  };

  keyboardWillHide = () => {
    this.setState({ btnInsideScrollView: false });
  };

  render() {
    const {
      translate, renderFooter, experience, handleSubmit,
    } = this.props;

    const t = (key) => translate(`candidateProfile.addExperience.${key}`);

    return (
      <FormikWithValidation
        validation={validation}
        initialValues={experience}
        enableReinitialize
        onSubmit={handleSubmit}
        render={({
          handleSubmit: handleSubmitFormik, isBtnDisabled, values,
        }) => (
          <>
            <ModalKeyboardAwareScrollView
              onKeyboardDidShow={this.keyboardDidShow}
              onKeyboardWillHide={this.keyboardWillHide}
            >
              <Wrapper>
                <FormikFieldPrimaryPicker
                  name="category"
                  items={this.getTranslatedCategories()}
                  label={t('categoryLabel')}
                />

                <FormikFieldPrimaryInputTextNextSupport
                  name="position"
                  label={t('positionLabel')}
                  placeholder={t('positionPlaceholder')}
                  maxLength={CANDIDATE_PROFILE_POSITION_MAX_LENGTH}
                  counter
                  nextComponent={this.state.companyNameInputRef}
                />
                <FormikFieldPrimaryTextInput
                  innerRef={(ref) => !this.state.companyNameInputRef && this.setState({ companyNameInputRef: ref })}
                  name="companyName"
                  label={t('companyNameLabel')}
                  placeholder={t('companyNamePlaceholder')}
                  maxLength={CANDIDATE_PROFILE_COMPANY_NAME_MAX_LENGTH}
                  counter
                  returnKeyType="done"
                />
                <FormikFieldMonthYearPicker
                  name="fromDate"
                  label={t('fromLabel')}
                />
                <FormikFieldMonthYearPicker
                  name="toDate"
                  label={t('toLabel')}
                  enabled={!values.ongoing}
                />

                <FormikFieldPrimarySwitch
                  name="ongoing"
                  label={t('ongoingLabel')}
                />
                <FormikFieldPrimaryTextInput
                  name="description"
                  label={t('descriptionLabel')}
                  placeholder={t('descriptionPlaceholder')}
                  maxLength={CANDIDATE_PROFILE_DESCRIPTION_MAX_LENGTH}
                  counter
                  multiline
                  numberOfLines={4}
                  scrollEnabled={false}
                />
              </Wrapper>
              {Platform.OS === 'ios' && this.state.btnInsideScrollView && renderFooter({ handleSubmitFormik, isBtnDisabled })}
            </ModalKeyboardAwareScrollView>
            {renderFooter({ handleSubmitFormik, isBtnDisabled })}
          </>
        )}
      />
    );
  }
}

CandidateProfileExperienceForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  experience: candidateExperiencePropType.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  availableCategories: state.gigCategories.toJS(),
});

export default connect(mapStateToProps, { getAvailableGigCategories })(withLocalize(CandidateProfileExperienceForm));
