export const POOL_CHAT_TYPE = {
  GROUP: 'GROUP',
  DIRECT: 'DIRECT',
};

export function updateUnreadOnPoolChat(poolChat, unreadPoolChatsMap) {
  return poolChat.set('unread', unreadPoolChatsMap.get(poolChat.get('chatId')) !== undefined ? unreadPoolChatsMap.get(poolChat.get('chatId')) : false);
}

export function updateUnreadOnPoolChats(poolChats, unreadPoolChatsMap) {
  return poolChats.map((poolChat) => (updateUnreadOnPoolChat(poolChat, unreadPoolChatsMap)));
}

export function updateLastMessageOnPoolChatsList(poolChats, chatId, msg, date) {
  return poolChats.map((poolChat) => (poolChat.get('chatId') === chatId ? poolChat.set('lastMessageDate', date).set('lastMessage', msg) : poolChat));
}

export function getLastMessageReadTimestampsMap(poolChats) {
  return poolChats.map((poolChat) => poolChat.get('lastMessageDate'));
}
