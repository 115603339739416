import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const styles = StyleSheet.create({
  contentStyle: {
    // flex: 1 breaks the android, and we need flexGrow for some view which at the beginning are smaller than screen
    display: 'flex',
    flexGrow: 1,
  },
});

const PrimaryKeyboardAwareScrollView = ({
  children, testID, setRef, extraHeight, extraScrollHeight, enableOnAndroid, ...props
}) => (
  <KeyboardAwareScrollView
    innerRef={setRef}
    keyboardShouldPersistTaps="handled"
    enableOnAndroid={enableOnAndroid}
    extraHeight={extraHeight}
    extraScrollHeight={extraScrollHeight}
    contentContainerStyle={styles.contentStyle}
    {...props}
  >
    {children}
  </KeyboardAwareScrollView>
);

PrimaryKeyboardAwareScrollView.propTypes = {
  children: PropTypes.node.isRequired,
  extraHeight: PropTypes.number,
  extraScrollHeight: PropTypes.number,
  enableOnAndroid: PropTypes.bool,
  testID: PropTypes.string,
  setRef: PropTypes.func,
};

PrimaryKeyboardAwareScrollView.defaultProps = {
  extraHeight: 200,
  extraScrollHeight: 0,
  enableOnAndroid: true,
  testID: undefined,
  setRef: undefined,
};

export default PrimaryKeyboardAwareScrollView;
