import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SelectList, { SelectListValuePropType } from './SelectList';
import { SelectListItemDataPropType } from './SelectListItem';
import { getValueByPath } from '../../../utilities/objectUtils';

const FormikSelectList = ({
  field: { name, value }, form: {
    setFieldValue, setFieldTouched, touched, errors,
  }, onValueChange, ...props
}) => (
  <SelectList
    {...props}
    value={value}
    onValueChange={(val) => {
      setFieldValue(name, val);
      setFieldTouched(name, true);
      if (onValueChange) {
        onValueChange(val);
      }
    }}
    error={(getValueByPath(touched, name) && errors[name]) || null}
  />
);

FormikSelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SelectListItemDataPropType)).isRequired,
  maxSelect: PropTypes.number,
  multiple: PropTypes.bool,
  scroll: PropTypes.bool,

  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: SelectListValuePropType,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onValueChange: PropTypes.func,
};

FormikSelectList.defaultProps = {
  scroll: false,
  multiple: false,
  maxSelect: Number.MAX_SAFE_INTEGER,
  onValueChange: null,
};

const FormikFieldSelectList = (props) => (
  <Field
    component={FormikSelectList}
    {...props}
  />
);

FormikFieldSelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SelectListItemDataPropType)).isRequired,
  onMaxSelect: PropTypes.func,
  multiple: PropTypes.bool,
  maxSelect: PropTypes.number,
  scroll: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

FormikFieldSelectList.defaultProps = {
  onMaxSelect: undefined,
  multiple: undefined,
  maxSelect: undefined,
  scroll: undefined,
  iconPosition: undefined,
};

export default FormikFieldSelectList;
