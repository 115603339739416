import React, { PureComponent } from 'react';
import {
  View, Text, StyleSheet, Linking, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import Container from '../../../common/components/Container';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { pricingPlanPropTypes } from '../../../constants/propTypes';
import { font } from '../../../common/styles/mixins';
import { convertAmount, getPricingPlanName, getPriceLabel } from './pricingPlanUtils';

const READ_MORE_URL_METADATA_PROPERTY_NAME = 'read_more_url';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: spacings.sm,
  },
  infoLine: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: spacings.xs,
  },
  readMoreContainer: {
    alignItems: 'flex-end',
  },
  readMoreLink: {
    ...font(fontFamily.SFProTextSemiBold, fontSizes.sm, null, colors.magenta),
  },
  name: {
    marginRight: spacings.md,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xl, null, colors.greyishBrown),
  },
  price: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, null, colors.greyishBrown),
  },
  msg: {
    flex: 1,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, null, colors.brownishGrey),
  },
  selectedMsg: {
    color: colors.black,
  },
  selected: {
    color: colors.magenta,
  },
});

const getDescription = (plan, locale, translate) => {
  const descFromMetaData = plan.metadata[`description_${locale}`];

  return descFromMetaData || translate('backupDescription', {
    ...plan,
    amount: convertAmount(plan.amount, locale),
    currency: plan.currency.toUpperCase(),
  });
};

class PricingPlanItem extends PureComponent {
  onReadMore = () => {
    Linking.openURL(this.props.plan.metadata[READ_MORE_URL_METADATA_PROPERTY_NAME]);
  };

  render() {
    const {
      plan,
      locale,
      translate,
      isSelected,
    } = this.props;
    const price = getPriceLabel(plan, locale);
    return (
      <View style={styles.container}>
        <Container>
          <View style={styles.infoLine}>
            <Text style={[styles.name, isSelected && styles.selected]}>{getPricingPlanName(plan, locale)}</Text>
            {!!price && <Text style={[styles.price, isSelected && styles.selected]}>{price}</Text>}
          </View>
          <View style={styles.infoLine}>
            <Text style={styles.msg}>{getDescription(plan, locale, translate)}</Text>
          </View>
          {plan.metadata[READ_MORE_URL_METADATA_PROPERTY_NAME]
            && (
            <TouchableOpacity
              onPress={this.onReadMore}
              style={styles.readMoreContainer}
              hitSlop={{
                top: 15, bottom: 15, left: 15, right: 15,
              }}
            >
              <Text style={styles.readMoreLink}>{translate('readMore')}</Text>
            </TouchableOpacity>
            )}
        </Container>
      </View>
    );
  }
}

PricingPlanItem.propTypes = {
  translate: PropTypes.func.isRequired,
  plan: pricingPlanPropTypes.isRequired,
  locale: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default PricingPlanItem;
