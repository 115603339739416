import React, { Component } from 'react';
import App from './App';
import { fontsMap } from './config/fonts';

function injectStyleToHead(stylesString) {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(stylesString));
  document.head.appendChild(style);
}

function loadCustomFonts() {
  let customFonts = '';

  Object.keys(fontsMap).forEach((fontName) => {
    customFonts += `
      @font-face {
        src: url(${fontsMap[fontName]});
        font-family: ${fontName};
      }`;
  });

  injectStyleToHead(customFonts);
}

class AppLoader extends Component {
  componentDidMount() {
    loadCustomFonts();
  }

  render() {
    return (
      <App />
    );
  }
}

export default AppLoader;
