import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileCreatorAddGigLocation } from '../../profileCreatorActions';
import ProfileCreatorStepLocationScreen from './ProfileCreatorStepLocationScreen';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import { getAvailableGigCities } from '../../../../../common/gig/city/gigCitiesActions';
import { getTranslationForCity, findCityByKey } from '../../../../../common/gig/city/gigCitiesHelper';
import { candidateLocationPropType, gigCityPropType } from '../../../../../constants/propTypes';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepLocationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.PREFERRED_LOCATION);
    this.props.getAvailableGigCities();
  }

  handleSubmit = ({ cityKey }) => {
    this.props.profileCreatorAddGigLocation({ cityV2: findCityByKey(this.props.availableCities, cityKey) });
    this.setState({ redirect: true });
  };

  render = () => (
    <ProfileCreatorStepLocationScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.GIG_LOCATION, this.props.grantedFeatures)}
      location={this.props.location}
      cities={this.props.availableCities.map((city) => ({ value: city.key, label: getTranslationForCity(city) }))}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.GIG_LOCATION, this.props.grantedFeatures)}
    />
  );
}

ProfileCreatorStepLocationContainer.propTypes = {
  profileCreatorAddGigLocation: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType).isRequired,
  location: candidateLocationPropType,
};

ProfileCreatorStepLocationContainer.defaultProps = {
  location: undefined,
};

const mapStateToProps = (state) => {
  const { location } = state.candidate.get('profileData').toJS();

  return ({
    location,
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
    availableCities: state.gigCities.toJS(),
  });
};

export default connect(mapStateToProps, { profileCreatorAddGigLocation, getAvailableGigCities })(ProfileCreatorStepLocationContainer);
