import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileCreatorStepAvailabilityScreen from './ProfileCreatorStepAvailabilityScreen';
import { profileCreatorAddAvailability } from '../../profileCreatorActions';
import { candidateProfileAvailabilityPropType } from '../../../../../constants/propTypes';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepAvailabilityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.AVAILABILITY);
  }

  handleSubmit = (availabilityData) => {
    this.props.profileCreatorAddAvailability(availabilityData);
    this.setState({ redirect: true });
  };

  render = () => (
    <ProfileCreatorStepAvailabilityScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.AVAILABILITY, this.props.grantedFeatures)}
      availability={this.props.availability}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.AVAILABILITY, this.props.grantedFeatures)}
    />
  );
}

ProfileCreatorStepAvailabilityContainer.propTypes = {
  profileCreatorAddAvailability: PropTypes.func.isRequired,
  availability: candidateProfileAvailabilityPropType.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const {
    available, times, hours,
  } = state.candidate.get('profileData').toJS();

  return ({
    availability: {
      available,
      times,
      hours,
    },
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  });
};

export default connect(mapStateToProps, { profileCreatorAddAvailability })(ProfileCreatorStepAvailabilityContainer);
