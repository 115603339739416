import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { spacings } from '../styles/base.style';

const styles = StyleSheet.create({
  successContainer: {
    backgroundColor: '#C3EDC0',
    flexDirection: 'row',
    marginHorizontal: spacings.xl,
    marginTop: spacings.md,
  },
  successBorder: {
    width: 20,
    backgroundColor: '#7AA874',
  },
  successText: {
    color: '#4F7942',
  },
  errorContainer: {
    backgroundColor: '#FCC8D1',
    flexDirection: 'row',
    marginHorizontal: spacings.xl,
    marginTop: spacings.md,
  },
  errorBorder: {
    width: 20,
    backgroundColor: '#C70039',
  },
  textView: {
    padding: spacings.md,
  },
  errorText: {
    color: '#C70039',
  },
  pendingContainer: {
    backgroundColor: '#c8defc',
    flexDirection: 'row',
    marginHorizontal: spacings.xl,
    marginTop: spacings.md,
  },
  pendingBorder: {
    width: 20,
    backgroundColor: '#0040c7',
  },
  pendingText: {
    color: '#0040c7',
  },
});

const getStatusStyles = (isSuccess, isPending) => {
  if (isSuccess) {
    return {
      container: styles.successContainer,
      border: styles.successBorder,
      text: styles.successText,
    };
  }
  if (isPending) {
    return {
      container: styles.pendingContainer,
      border: styles.pendingBorder,
      text: styles.pendingText,
    };
  }
  return {
    container: styles.errorContainer,
    border: styles.errorBorder,
    text: styles.errorText,
  };
};

const Banner = ({ isSuccess, text, isPending }) => {
  const statusStyles = getStatusStyles(isSuccess, isPending);

  return (
    <View style={statusStyles.container}>
      <View style={statusStyles.border} />
      <View style={styles.textView}>
        <Text style={statusStyles.text}>{text}</Text>
      </View>
    </View>
  );
};

Banner.propTypes = {
  isPending: PropTypes.bool,
  isSuccess: PropTypes.bool,
  text: PropTypes.string,
};

Banner.defaultProps = {
  isPending: null,
  isSuccess: null,
  text: '',
};

export default Banner;
