import React from 'react';
import { StatusBar } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as propTypes from '../../constants/propTypes';

const ViewportController = (props) => {
  const style = props.noFlex ? {} : { flex: 1, backgroundColor: 'transparent', opacity: 1 };
  const edges = ['right', 'left'];
  if (props.safeAreaTop) {
    edges.push('top');
  }
  if (props.safeAreaBottom) {
    edges.push('bottom');
  }
  return (
    <SafeAreaView style={[style, props.style]} edges={edges}>
      {props.statusBarHidden && <StatusBar hidden />}
      {props.children}
    </SafeAreaView>
  );
};

ViewportController.propTypes = {
  style: propTypes.stylePropTypes,
  children: PropTypes.node.isRequired,
  statusBarHidden: PropTypes.bool,
  safeAreaTop: PropTypes.bool,
  safeAreaBottom: PropTypes.bool,
  noFlex: PropTypes.bool,
};

ViewportController.defaultProps = {
  style: {},
  statusBarHidden: false,
  safeAreaTop: false,
  safeAreaBottom: false,
  noFlex: false,
};

export default ViewportController;
