import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

class FormikEffect extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.formik !== this.prevProps) {
      const {
        values, touched, errors, isSubmitting,
      } = prevProps.formik;

      const {
        values: nextValues,
        touched: nextTouched,
        errors: nextErrors,
        isSubmitting: nextIsSubmitting,
      } = this.props.formik;

      this.props.onChange(
        {
          values,
          touched,
          errors,
          isSubmitting,
        },
        {
          values: nextValues,
          touched: nextTouched,
          errors: nextErrors,
          isSubmitting: nextIsSubmitting,
        },
      );
    }
  }

  render() {
    return null;
  }
}

FormikEffect.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.any.isRequired,
};

export default connect(FormikEffect);
