import React from 'react';
import PropTypes from 'prop-types';
import {
  View, Text, StyleSheet,
} from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import Container from '../../../common/components/Container';
import Routing from '../../../core/routing/index';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { LinkButton } from '../../../common/components';
import { BUTTON_TYPES } from '../../../common/components/buttons/Button';
import { COMPANY_CANDIDATES_POOL_GROUP_CREATE, COMPANY_CANDIDATES_POOL_GROUP_VIEW, COMPANY_CANDIDATES_POOL_GROUP_CHAT } from '../../../constants/routes';
import ListItem from '../../../common/components/ListItem';
import Touchable from '../../../common/components/Touchable';
import { poolGroupPropType } from '../../../constants/propTypes';
import { storeCandidatesPoolGroupsScrollPosition } from '../companyCandidatesPoolActions';
import PrimarySectionList from '../../../common/components/PrimarySectionList';
import PoolChatButton from '../../../common/chat/pool/PoolChatButton';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  footerFloatingButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  listItemContainer: {
    flexDirection: 'column',
    paddingTop: spacings.xs,
    paddingBottom: spacings.sm,
    paddingHorizontal: spacings.sm,
  },
  itemTitleRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemLabel: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  groupMemberCounterShape: {
    height: 22,
    borderRadius: 11,
    backgroundColor: colors.magenta,
    paddingHorizontal: spacings.xs,
    justifyContent: 'center',
  },
  groupMemberCounterShapeText: {
    color: colors.white,
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    textAlign: 'center',
    minWidth: 8,
  },
  groupNameText: {
    fontSize: fontSizes.md,
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.greyishBrown,
    marginLeft: spacings.sm,
  },
  groupNameDescription: {
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplayRegular,
    color: colors.brownishGrey,
    paddingTop: spacings.sm,
  },
});

class CompanyCandidatesPoolGroupsScreen extends React.Component {
  componentDidMount() {
    this.scrollPosition = 0;
    if (this.props.history.action === 'POP') {
      const { storedScrollPosition } = this.props;
      if (storedScrollPosition) {
        this.scrollToLastKnownPosition(storedScrollPosition);
      }
    } else {
      this.props.storeScrollPosition(this.scrollPosition);
    }
  }

  componentWillUnmount() {
    this.props.storeScrollPosition(this.scrollPosition);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  goToGroup = (groupId) => {
    this.props.history.push(COMPANY_CANDIDATES_POOL_GROUP_VIEW.replace(':groupId', groupId));
  };

  startChat(groupId) {
    this.props.history.push(COMPANY_CANDIDATES_POOL_GROUP_CHAT.replace(':groupId', groupId));
  }

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToLocation({
      sectionIndex: 0,
      animated: true,
      itemIndex: 0,
      viewOffset: -scrollPosition + 7,
      viewPosition: 0,
    }), 0);
  }

  render() {
    const { poolGroups, translate, userId } = this.props;
    const privateGroups = (poolGroups && poolGroups.filter((group) => group.access === 'PRIVATE' || group.ownerUserId === userId)) || [];
    const sharedGroups = (poolGroups && poolGroups.filter((group) => group.access === 'SHARED' && group.ownerUserId !== userId)) || [];

    return (
      <Container>
        <PrimarySectionList
          emptyListTextKey={({ key }) => translate(`company.candidatesPool.group.groupsEmptyList.${key}`)}
          contentContainerStyle={{ paddingVertical: spacings.xs, paddingBottom: spacings.xxxxl }}
          sections={[
            {
              key: 'private',
              title: translate('company.candidatesPool.group.private'),
              data: privateGroups,
              dataLoading: !poolGroups,
            },
            {
              key: 'shared',
              title: translate('company.candidatesPool.group.shared'),
              data: sharedGroups,
              dataLoading: !poolGroups,
            },
          ]}
          keyExtractor={(poolGroup) => poolGroup.groupId}
          innerRef={this.setListRef}
          onScroll={this.onScroll}
          renderItem={({ item: poolGroup }) => (
            <ListItem
              renderContent={() => (
                <Touchable activeOpacity={0.5} onPress={() => this.goToGroup(poolGroup.groupId)}>
                  <View style={styles.listItemContainer}>
                    <View style={styles.itemTitleRow}>
                      <View style={styles.itemLabel}>
                        <View style={[styles.groupMemberCounterShape, poolGroup.candidates.length === 0 && { backgroundColor: colors.veryLightPink }]}>
                          <Text style={styles.groupMemberCounterShapeText}>{poolGroup.candidates.length}</Text>
                        </View>
                        <Text style={styles.groupNameText}>{poolGroup.name}</Text>
                      </View>
                      {poolGroup.ownerUserId === userId && (
                        <PoolChatButton
                          poolChatId={poolGroup.groupId}
                          onPress={() => this.props.history.push(COMPANY_CANDIDATES_POOL_GROUP_CHAT.replace(':groupId', poolGroup.groupId))}
                        />
                      )}
                    </View>
                    {!!poolGroup.description && <Text style={styles.groupNameDescription}>{poolGroup.description}</Text>}
                  </View>
                </Touchable>
              )}
            />
          )}
        />
        <View style={styles.footerFloatingButton}>
          <LinkButton
            type={BUTTON_TYPES.PRIMARY_RED}
            title={translate('company.candidatesPool.groupList.createGroup')}
            to={COMPANY_CANDIDATES_POOL_GROUP_CREATE}
          />
        </View>
      </Container>
    );
  }
}

CompanyCandidatesPoolGroupsScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  poolGroups: PropTypes.arrayOf(poolGroupPropType),
  storedScrollPosition: PropTypes.number.isRequired,
  storeScrollPosition: PropTypes.func.isRequired,
};

CompanyCandidatesPoolGroupsScreen.defaultProps = {
  poolGroups: [],
};

const mapStateToProps = (state) => ({
  userId: state.auth.get('userId'),
  storedScrollPosition: state.companyCandidatesPool.get('candidatesPoolGroupsScrollPosition'),
});

export default connect(
  mapStateToProps,
  { storeScrollPosition: storeCandidatesPoolGroupsScrollPosition },
)(withLocalize(withRouter(CompanyCandidatesPoolGroupsScreen)));
