import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getProfile from '../CandidateProfileActions';
import CandidateProfileEditDetailsScreen from './CandidateProfileEditDetailsScreen';
import { candidateProfilePropType } from '../../../constants/propTypes';

class CandidateProfileEditDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalHeader: '',
      modalBody: undefined,
    };
  }

  componentDidMount() {
    this.props.getProfile();
  }

  setModalContent = (header, body) => {
    this.setState({
      modalVisible: true,
      modalHeader: header,
      modalBody: body,
    });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
    // we want to erase view only after it's been hidden
    setTimeout(() => {
      this.setState({
        modalHeader: '',
        modalBody: undefined,
      });
    }, 300);
  };

  render() {
    return (
      <CandidateProfileEditDetailsScreen
        profile={this.props.profile}
        setModalContent={this.setModalContent}
        closeModal={this.closeModal}
        modalVisible={this.state.modalVisible}
        modalHeader={this.state.modalHeader}
        modalBody={this.state.modalBody}
      />
    );
  }
}

CandidateProfileEditDetailsContainer.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: candidateProfilePropType.isRequired,
};

const mapStateToProps = (state) => ({ profile: state.candidate.get('profile').toJS() });

export default connect(mapStateToProps, { getProfile })(CandidateProfileEditDetailsContainer);
