import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyleSheet, View, Platform, Linking, AppState } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import Constants from 'expo-constants';
import { LinkButton } from '../components/index';
import { setLocale } from '../../core/localization/setLocaleAction';
import { CANDIDATE_WELCOME, COMPANY_WELCOME } from '../../constants/routes';
import BackgroundImageScreen from './BackgroundImageScreen';
import WelcomeScreenTitle from './WelcomeScreenTitle';
import ViewportController from '../components/ViewportController';
import { colors, spacings } from '../styles/base.style';
import Button from '../components/buttons/Button';
import { showAlertMessage } from '../components/alert/alerts';
import { getCurrentVersion } from '../version/VersionActions';

const imgSrc = require('../../assets/photos/welcome/welcome.jpg');

const stylesButton = StyleSheet.create({
  text: {
    color: colors.brownishGrey,
  },
  wrapper: {
    paddingHorizontal: spacings.sm,
  },
});

const styles = StyleSheet.create({
  languageButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    paddingBottom: spacings.xxs,
  },
});

class WelcomeScreen extends Component {
  componentDidMount() {
    AppState.addEventListener('change', this.handleAppStateChange);
    this.checkAppVersion();
  }

  handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active') {
      // The app has resumed from the background
      this.checkAppVersion();
    }
  };

  checkAppVersion = () => {
    if (Platform.OS !== 'web') {
      this.props.getCurrentVersion().then((response) => {
        if (!response.isError) {
          if (Platform.OS === 'ios') {
            if (Constants.expoConfig.version < response.payload.ios) {
              showAlertMessage(
                this.props.translate,
                'generic.updateNow',
                'generic.iOSNewVersionMessage',
                'generic.newVersionAvailable',
                () => {
                  // redirects app store
                  this.openStoreForUpdate('ios');
                }
              );
            }
          } else if (Constants.expoConfig.version < response.payload.android) {
            showAlertMessage(
              this.props.translate,
              'generic.updateNow',
              'generic.androidNewVersionMessage',
              'generic.newVersionAvailable',
              () => {
                // redirects to google play store
                this.openStoreForUpdate('android');
              }
            );
          }
        }
      });
    }
  };

  openStoreForUpdate = (platform) => {
    let storeUrl = '';

    if (platform === 'ios') {
      storeUrl = 'https://apps.apple.com/app/id1448023337';
    } else if (platform === 'android') {
      storeUrl = 'https://play.google.com/store/apps/details?id=com.letsgig.prd&pli=1';
    }

    if (storeUrl) {
      Linking.openURL(storeUrl)
        .then(() => {
          console.log(`Opened ${platform === 'ios' ? 'App Store' : 'Play Store'}`);
        })
        .catch((err) => {
          console.error('Error opening store URL', err);
        });
    }
  };

  render() {
    const { translate, setLocale: onLanguageChange } = this.props;

    return (
      <ViewportController safeAreaBottom statusBarHidden>
        <BackgroundImageScreen img={imgSrc}>
          <WelcomeScreenTitle title={translate('welcomeScreen.areYouLookingText')} />
          <LinkButton to={COMPANY_WELCOME} title={translate('welcomeScreen.wantToHireButton')} testID="companyBtn" />

          <LinkButton
            to={CANDIDATE_WELCOME}
            type="primary-blue"
            title={translate('welcomeScreen.lookingForGigButton')}
            testID="candidateBtn"
          />

          <View style={styles.languageButtonsContainer}>
            <Button
              title={translate('welcomeScreen.englishLanguage')}
              type="text"
              size="md"
              onPress={() => onLanguageChange('en')}
              style={stylesButton}
            />
            <Button
              title={translate('welcomeScreen.swedishLanguage')}
              type="text"
              size="md"
              onPress={() => onLanguageChange('sv')}
              style={stylesButton}
            />
          </View>
        </BackgroundImageScreen>
      </ViewportController>
    );
  }
}

WelcomeScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
  getCurrentVersion: PropTypes.func.isRequired,
};

export default connect(null, { setLocale, getCurrentVersion })(withLocalize(WelcomeScreen));
