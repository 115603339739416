import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import DisplayField from '../../../../../common/components/DisplayField';
import { getGigOffer } from '../companyGigCandidatesActions';
import GigViewOfferModal from '../../../../../common/gig/offer/GigViewOfferModal';
import { LinkButton } from '../../../../../common/components/index';
import { BUTTON_SIZES } from '../../../../../common/components/buttons/Button';
import { COMPANY_GIG_CHAT } from '../../../../../constants/routes';

class CompanyGigCandidateViewOfferModal extends Component {
  chatButton = () => {
    const {
      gigId,
      candidateId,
    } = this.props;
    return (
      <LinkButton
        size={BUTTON_SIZES.SM}
        to={COMPANY_GIG_CHAT.replace(':gigId', gigId).replace(':candidateId', candidateId)}
        title={this.props.translate('generic.chat')}
      />
    );
  };

  renderFirstSection = (offer) => (
    <DisplayField
      label={this.props.translate('company.gigOffer.candidateLabel')}
      value={`${offer.candidate.firstName} ${offer.candidate.lastName}`}
    />
  );

  render() {
    return (
      <GigViewOfferModal
        {...this.props}
        renderFirstSection={this.renderFirstSection}
        translationKey="company.gigOffer"
        chatButton={this.chatButton}
      />
    );
  }
}

CompanyGigCandidateViewOfferModal.propTypes = {
  translate: PropTypes.func.isRequired,
  getGigOfferAction: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  candidateId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

CompanyGigCandidateViewOfferModal.defaultProps = {
  gigId: undefined,
  candidateId: undefined,
};

export default connect(null, { getGigOfferAction: getGigOffer })(withLocalize(CompanyGigCandidateViewOfferModal));
