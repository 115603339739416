export default function getCandidateMyPoolsActionSheet({
  translate, status, onAccept, onReject, onSignOff, onOpenDirectChat, onOpenPoolProfile,
}) {
  const actionSheetParams = { options: [] };
  const actionHandlers = [];
  let optionsSize;

  optionsSize = actionSheetParams.options.push(translate('candidate.myPools.pools.actionSheet.poolDetails'));
  actionHandlers[optionsSize - 1] = async () => {
    onOpenPoolProfile();
  };

  if (status === 'INVITED') {
    optionsSize = actionSheetParams.options.push(translate('candidate.myPools.pools.actionSheet.accept'));
    actionHandlers[optionsSize - 1] = async () => {
      onAccept();
    };

    optionsSize = actionSheetParams.options.push(translate('candidate.myPools.pools.actionSheet.reject'));
    actionSheetParams.destructiveButtonIndex = optionsSize - 1;
    actionHandlers[optionsSize - 1] = async () => {
      onReject();
    };
  }

  if (status === 'ACTIVE') {
    optionsSize = actionSheetParams.options.push(translate('candidate.myPools.pools.actionSheet.directChat'));
    actionHandlers[optionsSize - 1] = async () => {
      onOpenDirectChat();
    };

    optionsSize = actionSheetParams.options.push(translate('candidate.myPools.pools.actionSheet.signOff'));
    actionSheetParams.destructiveButtonIndex = optionsSize - 1;
    actionHandlers[optionsSize - 1] = async () => {
      onSignOff();
    };
  }

  optionsSize = actionSheetParams.options.push(translate('generic.cancel'));
  actionSheetParams.cancelButtonIndex = optionsSize - 1;
  actionHandlers[optionsSize - 1] = () => {};

  return {
    options: {
      ...actionSheetParams,
      showSeparators: true,
    },
    handlers: (buttonIndex) => {
      actionHandlers[buttonIndex]();
    },
  };
}
