import { combineReducers } from 'redux-immutable';
import profileCreatorReducer from './profile/profile_creator/profileCreatorReducer';
import profileReducer from './profile/profileReducer';
import statusReducer from './status/candidateStatusReducer';

export default combineReducers({
  // this is temporary data used by profile creator
  profileData: profileCreatorReducer,
  profile: profileReducer,
  status: statusReducer,
});
