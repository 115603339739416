import validator from 'validator';
import moment from 'moment';

export default [
  {
    field: 'name',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'expiryDate',
    custom: (value) => {
      if (!value) {
        return false;
      }
      return moment(value).isAfter(moment());
    },
    message: 'past_date',
  },
];
