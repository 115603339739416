import validator from 'validator';

export const ABOUT_ME_MAX_LENGTH = 255;

export default [
  {
    field: 'aboutMe',
    method: validator.isLength,
    args: [{ max: ABOUT_ME_MAX_LENGTH }],
    message: 'too_long',
  },
];
