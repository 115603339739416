import { GIG_CITIES_REQUEST, GIG_MUNICIPALITIES_REQUEST } from '../../../constants/actionTypes';

export function getAvailableGigCities() {
  return {
    type: GIG_CITIES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'gig/city',
      },
    },
  };
}

export function getAvailableMunicipalities() {
  return {
    type: GIG_MUNICIPALITIES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'public/gig/municipality',
      },
    },
  };
}
