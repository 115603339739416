import validator from 'validator';
import { isFutureDateIgnoreTime } from '../../../../../core/validation/validators';
import { getUtcOffsetForGigLocation } from '../../../../../utilities/dateFormatter';

export const MAX_POSITION_LENGTH = 70;
export const MAX_WORKLOAD_LENGTH = 300;
export const MAX_SHIFT_LENGTH = 300;
export const MAX_STREET_LENGTH = 100;
export const MAX_ADDITIONAL_INFO_LENGTH = 3000;

const digitOnlyRegex = /^[1-9]\d*$/;

export default [
  {
    field: 'position',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'position',
    method: validator.isLength,
    args: [{ max: MAX_POSITION_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'startDate',
    custom: (value) => {
      if (!value) {
        return true;
      }
      return isFutureDateIgnoreTime(value, getUtcOffsetForGigLocation());
    },
    message: 'past_date',
  },
  {
    field: 'endDate',
    custom: (value, args, data) => {
      if (data.noEndDate || !value) {
        return true;
      }
      return isFutureDateIgnoreTime(value, getUtcOffsetForGigLocation());
    },
    message: 'past_date',
  },
  {
    field: 'endDate',
    custom: (value, args, data) => {
      if (data.startDate && data.endDate) {
        return (data.endDate < data.startDate);
      }
      return false;
    },
    validWhen: false,
    message: 'before_start_date',
  },
  {
    field: 'general',
    custom: (value, args, data) => !!(data.startDate && (data.endDate || data.noEndDate)),
    message: 'no_date',
  },
  {
    field: 'compensation.salary',
    method: validator.isInt,
    args: [{ min: 0 }],
    message: 'too_small',
  },
  {
    field: 'compensation.salary',
    custom: (value) => value === null || value === '' || validator.isInt(value),
    message: 'wrong_format',
  },
  {
    field: 'compensation.salary',
    custom: (value) => value !== null && value !== '',
    message: 'null_value',
  },
  {
    field: 'workload',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'workload',
    method: validator.isLength,
    args: [{ max: MAX_WORKLOAD_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'workload',
    custom: (value) => ((value && value.length > 0) ? digitOnlyRegex.test(value) : true),
    message: 'wrong_format',
  },
  {
    field: 'shift',
    custom: (value, args, data) => data.noShift || (value && value.length > 1),
    message: 'too_short',
  },
  {
    field: 'shift',
    method: validator.isLength,
    args: [{ max: MAX_SHIFT_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'location.street',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'location.street',
    method: validator.isLength,
    args: [{ max: MAX_STREET_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'additionalInfo',
    method: validator.isLength,
    args: [{ max: MAX_ADDITIONAL_INFO_LENGTH }],
    message: 'too_long',
  },
];
