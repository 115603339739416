import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import CompanyOpenGigsContainer from './CompanyOpenGigsContainer';
import CompanyClosedGigsContainer from './CompanyClosedGigsContainer';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import {
  COMPANY_OPEN_GIGS,
  COMPANY_CLOSED_GIGS,
  COMPANY_GIGS_LIST,
} from '../../constants/routes';
import TabBar from '../../common/components/tabs/TabBar';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../constants/userScopes';
import Routing from '../../core/routing/index';
import { shadowBottom } from '../../common/styles/base.style';
import Container from '../../common/components/Container';

const { Route, Redirect } = Routing;

const styles = StyleSheet.create({
  shadow: {
    ...shadowBottom,
  },
});

const CompanyGigsContainer = ({ translate }) => (
  <>
    <View style={styles.shadow}>
      <ScreenHeader title={translate('companyGigs.title')} />
      <Route exact path={COMPANY_GIGS_LIST} render={() => <Redirect to={COMPANY_OPEN_GIGS} />} />
      <TabBar items={[
        { textKey: 'companyGigs.open', link: COMPANY_OPEN_GIGS },
        { textKey: 'companyGigs.closed', link: COMPANY_CLOSED_GIGS },
      ]}
      />
    </View>
    <Container>
      <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_OPEN_GIGS} component={CompanyOpenGigsContainer} />
      <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CLOSED_GIGS} component={CompanyClosedGigsContainer} />
    </Container>
  </>
);

CompanyGigsContainer.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withLocalize(CompanyGigsContainer);
