import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../../../common/components/index';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import { candidateProfileAvailabilityPropType } from '../../../../../constants/propTypes';
import CandidateProfileAvailabilityForm from '../../../forms/availability/CandidateProfileAvailabilityForm';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const ProfileCreatorStepAvailabilityScreen = ({
  translate, availability, handleSubmit, stepNumber, nextScreen, redirect,
}) => (
  <View style={styles.container}>
    <ProfileCreatorHeader
      stepNo={stepNumber}
      title={translate('candidateProfileCreator.availability.topHeader')}
      infoText={translate('candidateProfileCreator.availability.header')}
    />
    <CandidateProfileAvailabilityForm
      availability={availability}
      handleSubmit={handleSubmit}
      renderFooter={({ handleSubmitFormik, isBtnDisabled }) => (
        <StickyButtonsWrapper>
          <LinkButton
            to={nextScreen}
            title={translate('generic.next')}
            doRedirect={redirect}
            disabled={isBtnDisabled}
            onPress={handleSubmitFormik}
          />
        </StickyButtonsWrapper>
      )}
    />
  </View>
);

ProfileCreatorStepAvailabilityScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  availability: candidateProfileAvailabilityPropType.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
};

export default withLocalize(ProfileCreatorStepAvailabilityScreen);
