import React from 'react';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../../constants/userScopes';
import {
  CANDIDATE_PROFILE,
  CANDIDATE_PROFILE_VIEW,
  CANDIDATE_PROFILE_EDIT,
  CANDIDATE_CREATE_PROFILE,
  CANDIDATE_PROFILE_SETTINGS,
} from '../../constants/routes';
import Routing from '../../core/routing';
import CandidateProfileSettingsContainer from './settings/CandidateProfileSettingsContainer';
import ProfileCreatorRouting from './profile_creator/ProfileCreatorRouting';
import CandidateProfileViewDetailsContainer from './view/CandidateProfileViewDetailsContainer';
import CandidateProfileEditDetailsContainer from './edit/CandidateProfileEditDetailsContainer';

const { Redirect } = Routing;

const DEFAULT_SCREEN = CANDIDATE_PROFILE_VIEW;

const CandidateProfileRouting = () => (
  <>
    <AuthorizedRoute exact only={CANDIDATE_USER} path={CANDIDATE_PROFILE} render={() => <Redirect to={DEFAULT_SCREEN} />} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_PROFILE_VIEW} component={CandidateProfileViewDetailsContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_PROFILE_EDIT} component={CandidateProfileEditDetailsContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE} component={ProfileCreatorRouting} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_PROFILE_SETTINGS} component={CandidateProfileSettingsContainer} />
  </>
);

export default CandidateProfileRouting;
