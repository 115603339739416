import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';
import { spacings } from '../styles/base.style';
import ViewportController from '../components/ViewportController';
import Routing from '../../core/routing/index';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  centeredWithMargins: {
    alignItems: 'center',
    marginTop: spacings.xl,
    marginBottom: spacings.xl,
  },
  loading: {
    marginTop: spacings.lg,
    justifyContent: 'center',
  },
  loadingIcon: {
    marginTop: spacings.lg,
    justifyContent: 'center',
  },
  container: {
    marginTop: spacings.md,
  },
});

class CheckUserStatusScreen extends Component {
  componentDidMount() {
    const { entries } = this.props.history;

    if (entries) {
      // it's only work when is used createMemoryHistory which doesn't assume a DOM environment
      this.props.history.entries = [entries[this.props.history.length - 1]];
    }

    this.props.history.index = 0;
    this.props.history.length = 1;
  }

  render() {
    return (
      <ViewportController safeAreaTop>
        <View style={styles.centeredWithMargins}>
          <ActivityIndicator size="large" style={styles.loadingIcon} />
        </View>
      </ViewportController>
    );
  }
}

CheckUserStatusScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(CheckUserStatusScreen);
