export const GIG_EDIT_SECTIONS = {
  INFO: 'info',
  PREFERRED_OCCUPATION: 'preferredOccupation',
  CATEGORIES: 'categories',
  POSITION_AND_DESCRIPTION: 'positionAndDescription',
  LOCATION: 'location',
  HOME_LOCATION: 'homeLocation',
  START_AND_END_DATE: 'startAndEndDate',
  SALARY: 'salary',
  WORKLOAD: 'workload',
  EXPERIENCE: 'experience',
  LANGUAGES: 'languages',
  QUESTIONNAIRE: 'questionnaire',
  PHOTO: 'photo',
};
