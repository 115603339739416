import { StyleSheet } from 'react-native';
import {
  colors, fontFamily, fontSizes, spacings, standardColors, standardSpacings,
} from './base.style';
import { font } from './mixins';

export const formBorderBottomWidth = 1;

export const inputTextStyle = {
  inputLabelLarge: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
  },
  inputLabelMedium: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.md,
    color: colors.greyishBrown,
  },
  inputLabelSmall: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xs,
    color: colors.greyishBrown,
  },
  inputText: {
    fontFamily: fontFamily.SFProDisplayMedium,
    fontSize: fontSizes.xl,
    color: colors.black,
  },
  inputCounter: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.xxs,
    color: colors.lightPeach,
  },
  inputError: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xxs,
    color: standardColors.error,
  },
};

const inputFieldStyle = {
  paddingVertical: spacings.xxs,
  borderBottomWidth: formBorderBottomWidth,
  borderBottomColor: standardColors.border,
  width: '100%',
};

export const textInputStyle = StyleSheet.create({
  container: {
    marginBottom: standardSpacings.formVertical,
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  // applied only when label present;
  containerForFloatingLabel: {
    marginTop: inputTextStyle.inputLabelSmall.fontSize,
  },
  label: {
    width: '100%',
  },
  // you can't set background colour on TextInput, so we need to wrap it
  inputBackgroundWrapper: {
    width: '100%',
    alignSelf: 'stretch',
  },
  inputTouchableWrapper: {
    width: '100%',
  },
  // we need to separate these because Picker doesn't accept fontSize
  inputFieldStyle,
  input: {
    textAlignVertical: 'top',
    ...inputFieldStyle,
    ...inputTextStyle.inputText,
  },
  inputFocus: {
    borderBottomColor: colors.magenta,
  },
  inputError: {
    ...inputTextStyle.inputError,
  },
  inputDisabled: {
    color: colors.lightPeach,
    borderBottomWidth: 0,
  },
  inputDisabledLabel: {
    color: colors.lightPeach,
  },
  counter: {
    ...inputTextStyle.inputCounter,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  showPasswordBtn: {
    position: 'absolute',
    right: 0,
    top: 7,
    zIndex: 5,
    backgroundColor: colors.white,
    paddingHorizontal: spacings.xs,
  },
  showPasswordBtnText: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm, 0, colors.brownishGrey),
  },
});
