import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import {
  SectionList, View, ActivityIndicator, Text, StyleSheet,
} from 'react-native';
import ListEmptyLabel from './ListEmptyLabel';
import {
  colors, fontFamily, fontSizes, spacings, standardColors,
} from '../styles/base.style';
import { font } from '../styles/mixins';
import EmptyElementForPadding from './EmptyElementForPadding';

const styles = StyleSheet.create({
  container: {
    backgroundColor: standardColors.listBackground,
  },
  sectionHeader: {
    marginBottom: spacings.sm,
    backgroundColor: standardColors.listBackground,
  },
  title: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.lg, undefined, colors.greyishBrown),
    backgroundColor: standardColors.listBackground,
    paddingHorizontal: spacings.xxs,
    zIndex: 2,
  },
  defaultTopMargin: {
    marginTop: 10,
  },
  titleWrapper: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: spacings.sm,
  },
  strikethrough: {
    borderColor: colors.veryLightPink,
    borderWidth: StyleSheet.hairlineWidth,
    position: 'absolute',
    top: 26,
    left: spacings.md,
    right: spacings.md,
  },
  emptyLabel: {
    paddingTop: 0,
    paddingBottom: spacings.sm,
    paddingHorizontal: spacings.md,
  },
});

const PrimarySectionList = ({
  paddingTop,
  paddingBottom,
  emptyListTextKey,
  translate,
  innerRef,
  ...props
}) => (
  <SectionList
    style={styles.container}
    ListFooterComponent={<EmptyElementForPadding padding={paddingBottom} />}
    renderSectionHeader={({ section: { title, dataLoading } }) => (
      <View style={styles.sectionHeader}>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{title}</Text>
          <View style={styles.strikethrough} />
        </View>
        {dataLoading && <ActivityIndicator style={styles.defaultTopMargin} size="small" />}
      </View>
    )}
    renderSectionFooter={({ section }) => {
      let emptyLabel;
      if (typeof emptyListTextKey === 'function') {
        emptyLabel = emptyListTextKey(section);
      } else {
        emptyLabel = translate(emptyListTextKey);
      }
      return (section.data.length > 0 ? <EmptyElementForPadding padding={10} /> : <ListEmptyLabel textKey={emptyLabel} style={styles.emptyLabel} />
      );
    }}
    stickySectionHeadersEnabled
    ref={innerRef}
    {...props}
  />
);

PrimarySectionList.propTypes = {
  emptyListTextKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  translate: PropTypes.func.isRequired,
  innerRef: PropTypes.func,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

PrimarySectionList.defaultProps = {
  innerRef: undefined,
  paddingTop: 10,
  paddingBottom: 10,
};

export default withLocalize(PrimarySectionList);
