import { store as reduxStore } from '../../../core/redux/store';
import { CANDIDATE_USER, COMPANY_USER } from '../../../constants/userScopes';
import { getCompanyPoolGroupChatsInfo, getCompanyPoolDirectChatsInfo } from '../../../company/candidatesPool/chat/companyPoolChatActions';
import { getCandidatePoolGroupChatsInfo, getCandidatePoolDirectChatsInfo } from '../../../candidate/myPools/chat/candidatePoolChatActions';

export function getPoolDirectChatsList(userScope) {
  if (COMPANY_USER === userScope) {
    return reduxStore.dispatch(getCompanyPoolDirectChatsInfo(false));
  }
  if (CANDIDATE_USER === userScope) {
    return reduxStore.dispatch(getCandidatePoolDirectChatsInfo(false));
  }
  return new Promise();
}

export function getPoolGroupChatsList(userScope) {
  if (COMPANY_USER === userScope) {
    return reduxStore.dispatch(getCompanyPoolGroupChatsInfo(false));
  }
  if (CANDIDATE_USER === userScope) {
    return reduxStore.dispatch(getCandidatePoolGroupChatsInfo(false));
  }
  return new Promise();
}

export function countUnreadPoolChats(store = reduxStore) {
  return store.getState().poolChat.get('poolChatsData').values().count((poolDirectChat) => poolDirectChat.get('unread'));
}

export function isNewPoolChat(poolChatId, store = reduxStore) {
  return store.getState().poolChat.get('poolChatsData').get('poolChatId') === undefined;
}
