import React from 'react';
import {
  Image, StyleSheet, View, Text,
} from 'react-native';
import PropTypes from 'prop-types';
import { getImageSourceForGig } from '../../../../common/components/image/imageHelper';
import {
  colors, externalColors, fontFamily, fontSizes,
} from '../../../../common/styles/base.style';
import { gigImageStyle } from '../../../../common/gig/GigDetailsImage';
import * as propTypes from '../../../../constants/propTypes';
import { PROVIDER_CONFIG } from '../../../../constants/providerTypes';
import { stylePropTypes } from '../../../../constants/propTypes';

const styles = StyleSheet.create({
  headerWrapper: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
    opacity: 0.8,
    top: 0,
    left: 0,
    paddingVertical: 5,
    backgroundColor: colors.smokeWhite,
  },
  headerText: {
    fontSize: fontSizes.xs,
    fontFamily: fontFamily.SFProDisplayBold,
    paddingVertical: 5,
    marginRight: 10,
    marginLeft: 10,
    textAlign: 'right',
    color: colors.greyishBrown,
  },
  'headerWrapper-WORKBUSTER': {
    backgroundColor: externalColors.workbuster.background,
  },
  'headerText-WORKBUSTER': {
    color: colors.white,
  },
});

function getHeaderText(translate, gig) {
  const providerConfig = PROVIDER_CONFIG[gig.gigProvider];

  let gigFrom;
  if (providerConfig && providerConfig.nameFnc) {
    gigFrom = providerConfig.nameFnc(gig);
  } else {
    gigFrom = translate(`gigProviders.${gig.gigProvider}`);
  }
  return translate('candidate.gigOverview.partnerHeader', { from: gigFrom });
}

const ExternalGigImage = ({
  imageUrl, gig, translate, headerStyle, gigImageStyles,
}) => (
  <>
    <Image
      source={getImageSourceForGig(imageUrl)}
      style={[gigImageStyle.image, gigImageStyles]}
    />
    <View style={[styles.headerWrapper, headerStyle, styles[`headerWrapper-${gig.gigProvider}`]]}>
      <Text style={[styles.headerText, styles[`headerText-${gig.gigProvider}`]]}>{getHeaderText(translate, gig)}</Text>
    </View>
  </>
);

ExternalGigImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  gig: propTypes.fingGigPropType.isRequired,
  translate: PropTypes.func.isRequired,
  headerStyle: propTypes.stylePropTypes,
  gigImageStyles: stylePropTypes,
};

ExternalGigImage.defaultProps = {
  headerStyle: undefined,
  gigImageStyles: null,
};

export default ExternalGigImage;
