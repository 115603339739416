import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import TermsAndAgreementScreen from '../../common/screens/TermsAndAgreementScreen';
import { signupCompanyNew } from './companySignupActions';

import { COMPANY_USER } from '../../constants/userScopes';
import { COMPANY_LOGIN, COMPANY_SIGNUP } from '../../constants/routes';
import { resetSignUpSuccess } from '../../common/auth/authActions';

class CompanyTermsAgreementContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isContractAgreed: false,
    };
  }

  onAgreeContract = () => {
    this.setState({ isContractAgreed: true });
  };

  render() {
    return (
      <TermsAndAgreementScreen
        userType={COMPANY_USER}
        redirectPath={COMPANY_SIGNUP}
        redirect={this.state.isContractAgreed}
        onSubmit={this.onAgreeContract}
      />
    );
  }
}

CompanyTermsAgreementContainer.propTypes = {
  signupCompanyNew: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  resetSignUpSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) =>
  // const formValues = state.auth.get('formValues').toJS();
  // console.log("formValuesss", formValues)

   ({
    // formValues,
    locale: state.locale.get('locale')
  })
;
// const mapStateToProps = (state) => ({
//   locale: state.locale.get('locale')
// });

export default connect(mapStateToProps, { signupCompanyNew, resetSignUpSuccess })(CompanyTermsAgreementContainer);
