import {
  APP_VERSION_REQUEST,
} from '../../constants/actionTypes';

export function getCurrentVersion() {
  return {
    type: APP_VERSION_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'public/gig/version',
      },
    },
  };
}
