import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, ScrollView } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import Container from '../../../common/components/Container';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import SettingsSupportSection from '../../../common/settings/SettingsSupportSection';
import SettingsNotificationContainer from '../../../common/settings/notifications/SettingsNotificationContainer';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import { version } from '../../../../package.json';
import FeatureToggle from '../../../common/components/FeatureToggle';
import { BUTTON_ALIGN, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import { COMPANY_PAYMENTS } from '../../../constants/routes';
import LinkButton from '../../../common/components/buttons/LinkButton';
import ViewportController from '../../../common/components/ViewportController';

const styles = StyleSheet.create({
  containerWrapper: {
    padding: spacings.md,
  },
  version: {
    position: 'absolute',
    bottom: spacings.xs,
    right: spacings.xs,
    fontSize: fontSizes.xxs,
    color: colors.brownGrey,
  },
  sectionHeader: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, 0.2),
    textAlign: 'center',
    marginBottom: spacings.md,
  },
  switchLabel: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl, undefined, colors.greyishBrown),
  },
});

const CompanyProfileSettingsScreen = ({
  translate,
  handleBack,
  onLogout,
  onDelete,
}) => (
  <ViewportController safeAreaBottom>
    <Container>
      <ScreenHeader
        title={translate('settings.settingsScreenHeader')}
        showBackArrow
        handleBack={handleBack}
      />
      <ScrollView contentContainerStyle={styles.containerWrapper}>
        <FeatureToggle requestedFeature="PAYMENTS">
          <LinkButton
            type={BUTTON_TYPES.TEXT}
            alignText={BUTTON_ALIGN.LEFT}
            to={COMPANY_PAYMENTS}
            title={translate('company.payments.settingsMenuOption')}
          />
        </FeatureToggle>
        <FeatureToggle requestedFeature="PUSH_NOTIFICATIONS">
          <SettingsNotificationContainer />
        </FeatureToggle>
        <SettingsSupportSection onLogout={onLogout} onDelete={onDelete} />
      </ScrollView>
      <Text style={styles.version}>{version}</Text>
    </Container>
  </ViewportController>
);

CompanyProfileSettingsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withLocalize(CompanyProfileSettingsScreen);
