import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class FeatureToggle extends PureComponent {
  render() {
    const {
      children, requestedFeature, grantedFeatures,
    } = this.props;
    return (
      grantedFeatures.includes(requestedFeature) && children
    );
  }
}

FeatureToggle.propTypes = {
  children: PropTypes.node.isRequired,
  requestedFeature: PropTypes.string.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, null)(FeatureToggle);
