import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { LinkButton } from '../../common/components/index';
import { COMPANY_CONTRACT_AGREEMENT, COMPANY_LOGIN, WELCOME } from '../../constants/routes';
import BackButton, { themes } from '../../common/components/navigation/BackButton';
import WelcomeScreenTitle from '../../common/screens/WelcomeScreenTitle';
import ViewportController from '../../common/components/ViewportController';
import CompanyBackgroundImageScreen from '../../common/screens/CompanyBackgroundImageScreen';
import Routing from '../../core/routing/index';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
    alignItems: 'center',
  },
});

const handleBack = (history) => {
  history.replace(WELCOME);
};

const CompanyWelcomeScreen = ({ translate, history }) => (
  <ViewportController safeAreaBottom statusBarHidden>
    <View style={{ position: 'absolute', zIndex: 100 }}>
      <BackButton onPress={() => handleBack(history)} theme={themes.LIGHT} />
    </View>

    <CompanyBackgroundImageScreen>
      <View style={styles.container}>
        <WelcomeScreenTitle title={translate('companyWelcomeScreen.header')} />
        <LinkButton to={COMPANY_LOGIN} title={translate('generic.loginButton')} testID="loginBtn" />
        <LinkButton to={COMPANY_CONTRACT_AGREEMENT} type="primary-blue" title={translate('generic.signupButton')} testID="signupBtn" />
      </View>
    </CompanyBackgroundImageScreen>
  </ViewportController>
);

CompanyWelcomeScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter((withLocalize(CompanyWelcomeScreen)));
