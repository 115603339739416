import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { candidateEducationPropType } from '../../../../../constants/propTypes';
import EditModalFooter from '../../../../../common/components/modal/EditModalFooter';
import CandidateProfileEducationForm from '../../../forms/education/CandidateProfileEducationForm';
import { getEducationKey } from './profileEducationHelper';
import PrimaryModal from '../../../../../common/components/modal/PrimaryModal';

const AddProfileEducationModalScreen = ({
  translate, visible, education, handleSubmit, handleCancel,
}) => (
  <PrimaryModal
    visible={visible}
    handleCancel={handleCancel}
    header={translate('candidateProfile.details.educationHeader')}
    // this will force recreation of the modal
    key={getEducationKey(education)}
  >
    <CandidateProfileEducationForm
      education={education}
      handleSubmit={handleSubmit}
      renderFooter={({ handleSubmitFormik, isBtnDisabled }) => (
        <EditModalFooter
          handleCancel={handleCancel}
          handleSubmitFormik={handleSubmitFormik}
          isBtnDisabled={isBtnDisabled}
          confirmLabel={translate('candidateProfile.addEducation.confirmBtn')}
        />
      )}
    />
  </PrimaryModal>
);

AddProfileEducationModalScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  education: candidateEducationPropType.isRequired,
};

export default withLocalize(AddProfileEducationModalScreen);
