import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import CandidateProfilePersonalNumberForm from '../../forms/personalNumber/CandidateProfilePersonalNumberForm';
import { candidateProfileEditPersonalNumber } from '../../CandidateProfileActions';

class CandidateProfileEditDetailsPersonalNumberModalAware extends React.Component {
  handleSubmit = (value, { setErrors, setSubmitting }) => {
    const { candidateProfileEditPersonalNumber: editAction, closeModal } = this.props;
    editAction(value)
      .then((response) => {
        if (!response.isError) {
          closeModal();
        } else {
          setErrors(response.errors);
          setSubmitting(false);
        }
      });
  };

  openModal = () => {
    const {
      translate, personalNumber, closeModal,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.personalNumberLabel'),
      <ModalKeyboardAwareScrollView>
        <CandidateProfileEditDetailsFormWithFooter
          component={CandidateProfilePersonalNumberForm}
          closeModal={closeModal}
          personalNumber={personalNumber}
          handleSubmit={this.handleSubmit}
        />
      </ModalKeyboardAwareScrollView>,
    );
  };

  render() {
    const { translate, personalNumber } = this.props;

    return (
      <EditableField
        label={translate('candidateProfile.details.personalNumberLabel')}
        value={personalNumber}
        placeholder={translate('candidateProfile.details.personalNumberFieldPlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

CandidateProfileEditDetailsPersonalNumberModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  candidateProfileEditPersonalNumber: PropTypes.func.isRequired,
  personalNumber: PropTypes.string,
};

CandidateProfileEditDetailsPersonalNumberModalAware.defaultProps = {
  personalNumber: undefined,
};

const mapStateToProps = (state) => ({ personalNumber: state.candidate.get('profile').get('personalNumber') });

export default connect(mapStateToProps, { candidateProfileEditPersonalNumber })(withLocalize(CandidateProfileEditDetailsPersonalNumberModalAware));
