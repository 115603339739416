import React from 'react';
import { Linking, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../../core/routing/index';
import PaymentCardScreen from './PaymentCardScreen';
import { getBillingProfile, saveCard, subscribe, payWithCard } from '../companyPaymentsActions';
import { COMPANY_PAYMENTS_BILLING_PROFILE_NEW, COMPANY_PAYMENTS_CARD_NEW_ON_HIRE, COMPANY_GIG_MATCHING_HIRED } from '../../../constants/routes';
import { successNotification, infoNotification, errorNotification } from '../../../common/notifications_center/notificationCenterActions';
import { hireByLetsgig } from '../../gigs/gigDetails/candidates/companyGigCandidatesActions';

const { withRouter } = Routing;

class PaymentCardOnHireContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorCode: undefined, showActivityIndicator: false };
    this.newCard = this.props.location.pathname === COMPANY_PAYMENTS_CARD_NEW_ON_HIRE;
      }

  onSaveCardSuccess() {
    const { gigId, grandTotal, candidateId } = this.props.location.state;
    const data = {
      amount: grandTotal,
      gigId,
      candidateId,
      locale: this.props.locale
    };

    this.props.getBillingProfile().then(({ isError: billingError, payload: billingPayload }) => {
      if (!billingError) {
        if (!billingPayload || !billingPayload.address) {
          this.props.history.replace(COMPANY_PAYMENTS_BILLING_PROFILE_NEW, { isDirectHiring: true, data, candidateId });
        } else {
          this.props.payWithCard(data).then(async (response) => {
            const intentRes = response.payload;

            if (!response.isError) {
              if (intentRes.s === 'requires_action' && intentRes.ru) {
                Linking.openURL(intentRes.ru);
                this.props.history.replace(COMPANY_GIG_MATCHING_HIRED.replace(':id', gigId));
                return;
              }
              await this.props.hireByLetsgig(gigId, candidateId);
              this.props.successNotification('company.payments.directHiring.successPayTitle');
              this.setState({ showActivityIndicator: false });
              this.props.history.replace(COMPANY_GIG_MATCHING_HIRED.replace(':id', gigId));
            } else if (response.errors.type === 'stripe_error') {
              this.props.errorNotification(response.errors.message);
              this.setState({ errorCode: response.errors.message, showActivityIndicator: false });
            } else {
              this.setState({ showActivityIndicator: false });
            }
          });
        }
      }
    });
  }

  handleWebviewAction = (event) => {
    const { trigger, cardToken } = Platform.OS === 'web' ? event : JSON.parse(event.nativeEvent.data);

    if (trigger === 'BEFORE') {
      this.setState({ showActivityIndicator: true });
    } else if (trigger === 'ERROR') {
      this.setState({ showActivityIndicator: false });
    } else if (trigger === 'AFTER') {
      this.props.saveCard({ cardToken }).then((response) => {
        if (!response.isError) {
          this.onSaveCardSuccess();
        } else if (response.errors.type === 'stripe_error') {
          this.props.errorNotification(response.errors.message);
          this.setState({ errorCode: response.errors.message, showActivityIndicator: false });
        } else {
          this.setState({ showActivityIndicator: false });
        }
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <PaymentCardScreen
        handleBack={this.goBack}
        handleWebviewAction={this.handleWebviewAction}
        error={this.state.errorCode}
        cardNew={this.newCard}
        showActivityIndicator={this.state.showActivityIndicator}
      />
    );
  }
}

PaymentCardOnHireContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  saveCard: PropTypes.func.isRequired,
  getBillingProfile: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  errorNotification: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location,
  gigId: PropTypes.string.isRequired,
  payWithCard: PropTypes.func.isRequired,
  hireByLetsgig: PropTypes.func.isRequired,
  candidateId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

PaymentCardOnHireContainer.defaultProps = {
  location: undefined,
};

const mapStateToProps = (state) => ({
  selectedPricingPlanId: state.company.get('payments').get('selectedPricingPlanId'),
  locale: state.locale.get('locale')
});

export default withRouter(
  connect(mapStateToProps, {
    saveCard,
    getBillingProfile,
    subscribe,
    successNotification,
    infoNotification,
    payWithCard,
    hireByLetsgig,
    errorNotification,
  })(PaymentCardOnHireContainer)
);
