import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import ListItem from '../../../../common/components/ListItem';
import GigListItem from '../../my_gigs/GigListItem';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { candidateGetNotInterestingGigs, EXPIRED_GIG_VISIBILITY_THRESHOLD } from '../candidateFindGigsActions';
import FIND_GIGS_SINGLE_ITEM_DEFINITIONS from './CandidateFindGigsSingleItemDefinitions';
import { GIG_DETAILS_ORIGIN } from '../../../../constants/gigDetailsOrigin';
import { CLOSED } from '../../../../constants/gigStatus';

class CandidateFindGigsSingleNotInteresting extends React.Component {
  componentDidMount() {
    this.props.candidateGetNotInterestingGigs(moment.utc().subtract(EXPIRED_GIG_VISIBILITY_THRESHOLD, 'days').toDate());
  }

  render() {
    return (
      <PrimaryFlatList
        emptyListTextKey="candidateFindGigs.single.noNotInteresting"
        data={this.props.gigsNotInteresting}
        keyExtractor={(item) => item.gigId}
        renderItem={({ item: gig }) => (
          <ListItem
            renderContent={() => (
              <GigListItem
                greyOutClosedGigs
                gig={gig}
                itemDefinition={gig.status === CLOSED
                  ? FIND_GIGS_SINGLE_ITEM_DEFINITIONS.notInterestingClosed
                  : FIND_GIGS_SINGLE_ITEM_DEFINITIONS.notInteresting}
                origin={GIG_DETAILS_ORIGIN.NOT_INTERESTING}
              />
            )}
          />
        )}
      />
    );
  }
}

CandidateFindGigsSingleNotInteresting.propTypes = {
  candidateGetNotInterestingGigs: PropTypes.func.isRequired,
  gigsNotInteresting: PropTypes.arrayOf(matchedGigForCandidatePropType),
};

CandidateFindGigsSingleNotInteresting.defaultProps = {
  gigsNotInteresting: null,
};

const mapStateToProps = (state) => ({
  gigsNotInteresting: state.candidateFindGigs.get('gigsNotInteresting') ? state.candidateFindGigs.get('gigsNotInteresting').toJS() : null,
});

export default connect(mapStateToProps, { candidateGetNotInterestingGigs })(CandidateFindGigsSingleNotInteresting);
