import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import {
  View, Platform, StyleSheet, Text,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { getAvailableGigCategories } from '../../../../common/gig/gigCategoriesActions';
import { getAvailableGigCities } from '../../../../common/gig/city/gigCitiesActions';
import {
  adminFindGigs,
  adminFindGigsChangeProviderGigIdSearch,
} from '../../AdminGigsActions';
import {
  activeLanguagePropTypes, adminFindGigsFilterPropType, gigCategoryPropType, gigCityPropType,
} from '../../../../constants/propTypes';
import { windowWidth, spacings } from '../../../../common/styles/base.style';
import { Button } from '../../../../common/components';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';
import FormikFieldNumberInput from '../../../../common/components/form/FormikFieldNumberInput';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pagination: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationWrapper: {
    flexDirection: 'row',
  },
  column: {
    minWidth: 290,
    marginRight: 30,
  },
  page: {
    width: 50,
  },
  resultsWrapper: {
    justifyContent: 'center',
    marginBottom: spacings.sm,
    marginLeft: spacings.lg,
  },
});

class AdminFindGigsFilters extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.windowWidth = windowWidth;
  }

  componentDidMount() {
    this.props.getAvailableGigCategories();
    this.props.getAvailableGigCities();
  }

  doSearch = async (values, { navigate }) => {
    const modifiedValues = { ...values, limit: values.limit.toString(), page: values.page.toString() };
    if (!navigate) {
      modifiedValues.page = '1';
    }
    this.props.adminFindGigsChangeProviderGigIdSearch(modifiedValues);
    this.formik.current.setFieldValue('page', modifiedValues.page);

    const {
      gigId, providerGigId, gigProvider, city, category, companyName, limit, page, minCandidatesReactedNumber, minFavouriteForCandidateNumber,
    } = modifiedValues;

    this.props.adminFindGigs({
      gigId,
      providerGigId,
      gigProvider,
      cityKey: city,
      categories: [category],
      companyName,
      limit,
      page,
      minCandidatesReactedNumber,
      minFavouriteForCandidateNumber,
    });
  };

  getTranslatedCategories = (nullable) => {
    const categories = this.props.availableCategories.map((c) => ({
      value: c.key,
      label: c.translations.find((t) => t.code === this.props.activeLanguage.code).value,
    }));
    if (Platform.OS !== 'ios' && !this.props.availableCategories.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  getTranslatedCities = (nullable) => {
    const cities = this.props.availableCities.map((c) => ({
      value: c.key,
      label: c.translations.find((t) => t.code === this.props.activeLanguage.code).value,
    }));
    if (Platform.OS !== 'ios' && !this.props.availableCities.category && nullable) {
      cities.unshift({ key: 'unselectable', label: '', value: null });
    }
    return cities;
  };

  getProviders = () => {
    const providerNames = [
      'LETSGIG',
      'AMS',
      'SP',
      'WORKBUSTER',
      'ZEROLIME',
    ];

    const providers = providerNames.map((item) => ({ key: item, label: item, value: item }));
    providers.unshift({ key: 'unselectable', label: '', value: null });
    return providers;
  }

  changePage = (setFieldValue, values, difference) => {
    const newPage = (Number(values.page) + difference).toString();
    setFieldValue('page', newPage);
    return { ...values, page: newPage };
  };

  render() {
    const {
      translate,
    } = this.props;

    const t = (key) => translate(`adminFindGigsScreen.${key}`);

    return (
      <>
        <View style={{ paddingBottom: spacings.xs, paddingTop: spacings.xxxxl }}>
          <Formik validate={false} onSubmit={this.doSearch} initialValues={this.props.formValues} innerRef={this.formik}>
            {({
              handleSubmit, setFieldValue, values,
            }) => (
              <>
                <View style={styles.wrapper}>
                  <View style={styles.column}>
                    <FormikFieldPrimaryTextInput
                      name="gigId"
                      label={t('gigIdLabel')}
                      placeholder={t('gigIdPlaceholder')}
                      disabled={false}
                    />
                    <FormikFieldPrimaryTextInput
                      name="providerGigId"
                      label={t('providerGigIdLabel')}
                      placeholder={t('providerGigIdPlaceholder')}
                      disabled={false}
                    />
                    <FormikFieldPrimaryTextInput
                      name="companyName"
                      label={t('companyNameLabel')}
                      placeholder={t('companyNamePlaceholder')}
                      disabled={false}
                    />
                  </View>
                  <View style={styles.column}>
                    <FormikFieldPrimaryPicker
                      name="city"
                      items={this.getTranslatedCities(true)}
                      label={t('cityLabel')}
                      nullable
                    />
                    <FormikFieldPrimaryPicker
                      name="gigProvider"
                      items={this.getProviders()}
                      label={t('providerLabel')}
                      nullable
                    />
                    <FormikFieldPrimaryPicker
                      name="category"
                      items={this.getTranslatedCategories(true)}
                      label={t('categoryLabel')}
                      nullable
                    />
                  </View>
                  <View style={styles.column}>
                    <FormikFieldNumberInput
                      name="limit"
                      label={t('resultsLimitLabel')}
                    />
                    <FormikFieldNumberInput
                      name="minCandidatesReactedNumber"
                      label={t('minCandidatesReactedNumberLabel')}
                    />
                    <FormikFieldNumberInput
                      name="minFavouriteForCandidateNumber"
                      label={t('minFavouriteForCandidateNumber')}
                    />
                  </View>
                </View>
                <Button
                  size="md"
                  title={translate('generic.search')}
                  onPress={handleSubmit}
                />
                {(this.props.adminGigsPageInfo && this.props.adminGigsPageInfo.totalPages > 0
                  && (
                  <View style={styles.pagination}>
                    <View style={styles.paginationWrapper}>
                      <Button
                        type="text"
                        size="md"
                        title={t('previous')}
                        disabled={Number(values.page) === 1}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, -1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginRight: spacings.md } }}
                      />
                      <FormikFieldNumberInput
                        name="page"
                        label={t('page')}
                        rightLabel={`/${this.props.adminGigsPageInfo.totalPages}`}
                        onChange={() => this.doSearch(values, { navigate: true })}
                        style={styles.page}
                      />
                      <Button
                        type="text"
                        size="md"
                        title={translate('generic.next')}
                        disabled={Number(values.page) === this.props.adminGigsPageInfo.totalPages}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, 1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginLeft: spacings.md } }}
                      />
                      <View style={styles.resultsWrapper}>
                        <Text>{`${this.props.adminGigsPageInfo.totalDocs} results`}</Text>
                      </View>
                    </View>
                  </View>

                  ))}

              </>
            )}
          </Formik>

        </View>
      </>
    );
  }
}

AdminFindGigsFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType).isRequired,
  formValues: adminFindGigsFilterPropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  adminGigsPageInfo: PropTypes.any.isRequired,

  adminFindGigsChangeProviderGigIdSearch: PropTypes.func.isRequired,
  adminFindGigs: PropTypes.func.isRequired,
};

AdminFindGigsFilters.defaultProps = {
};

const mapStateToProps = (state) => {
  const formValues = state.admin.get('gigs').get('formValues').toJS();

  return {
    formValues,
    availableCategories: state.gigCategories.toJS(),
    availableCities: state.gigCities.toJS(),
    adminGigsPageInfo: state.admin.get('gigs').get('adminGigsPageInfo').toJS(),
  };
};

export default connect(mapStateToProps, {
  getAvailableGigCategories,
  getAvailableGigCities,
  adminFindGigsChangeProviderGigIdSearch,
  adminFindGigs,
})(withLocalize(AdminFindGigsFilters));
