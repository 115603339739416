import validator from 'validator';

export const MAX_USER_IDS_LENGTH = 90000;
export const MAX_TITLE_LENGTH = 65;
export const MAX_TEXT_LENGTH = 178;

function validateUserIds(userIdsArray) {
  if (!userIdsArray) {
    return true;
  }
  const regex = /^(?:[a-z0-9-]+\s*,\s*)*[a-z0-9-]+\s*$/;
  return regex.test(userIdsArray);
}

export default [
  {
    field: 'userIds',
    custom: (value) => {
      if (value) {
        return value.trim().length >= 1;
      }
      return true;
    },
    message: 'too_short',
  },
  {
    field: 'userIds',
    method: validator.isLength,
    args: [{ max: MAX_USER_IDS_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'userIds',
    method: validateUserIds,
    args: [],
    message: 'wrong_format',
  },
  {
    field: 'svTitle',
    method: validator.isLength,
    args: [{ min: 2 }],
    validWhen: true,
    message: 'too_short',
  },
  {
    field: 'svTitle',
    method: validator.isLength,
    args: [{ max: MAX_TITLE_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'svText',
    method: validator.isLength,
    args: [{ min: 5 }],
    validWhen: true,
    message: 'too_short',
  },
  {
    field: 'svText',
    method: validator.isLength,
    args: [{ max: MAX_TEXT_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'enTitle',
    method: validator.isLength,
    args: [{ min: 2 }],
    validWhen: true,
    message: 'too_short',
  },
  {
    field: 'enTitle',
    method: validator.isLength,
    args: [{ max: MAX_TITLE_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'enText',
    method: validator.isLength,
    args: [{ min: 5 }],
    validWhen: true,
    message: 'too_short',
  },
  {
    field: 'enText',
    method: validator.isLength,
    args: [{ max: MAX_TEXT_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'general',
    custom: (value, args, data) => {
      if (!data.receiversCsv && !data.userIds) {
        return false;
      }
      return true;
    },
    message: 'admin_no_users_or_file',
  },

];
