import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CompanyGigsList from './gigDetails/CompanyGigsList';
import { getOpenGigs, clearGigList } from './companyGigActions';
import { gigItemPropType } from '../../constants/propTypes';

class CompanyOpenGigsContainer extends Component {
  constructor(props) {
    super(props);
    this.refreshing = false;
  }
  componentDidMount() {
    this.props.gigGetOpenGigs();
  }
  componentDidUpdate() {
    this.refreshing = false;
  }
  componentWillUnmount() {
    this.props.clearGigList('open');
  }
  render() {
    return (
      <CompanyGigsList
        noGigsTextKey="companyGigs.noOpenGigs"
        gigs={this.props.openGigs}
        refreshing={this.refreshing}
        onRefresh={this.props.gigGetOpenGigs}
      />
    );
  }
}

CompanyOpenGigsContainer.propTypes = {
  gigGetOpenGigs: PropTypes.func.isRequired,
  clearGigList: PropTypes.func.isRequired,
  openGigs: PropTypes.arrayOf(PropTypes.shape(gigItemPropType)),
};

CompanyOpenGigsContainer.defaultProps = {
  openGigs: null,
};

const mapStateToProps = (state) => ({
  openGigs: state.companyGig.get('gigsList').get('open') ? state.companyGig.get('gigsList').get('open').toJS() : null,
});

export default connect(mapStateToProps, { gigGetOpenGigs: getOpenGigs, clearGigList })(CompanyOpenGigsContainer);
