import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import BillingProfileScreen from './BillingProfileScreen';
import Routing from '../../../core/routing/index';
import { getBillingProfile, saveBillingProfile, subscribe, payWithCard } from '../companyPaymentsActions';
import { goTo } from '../../../common/history/historyUtil';
import { showConfirmAlert } from '../../../common/components/alert/alerts';
import {
  COMPANY_PAYMENTS_BILLING_PROFILE_NEW,
  COMPANY_PAYMENTS_OVERVIEW,
  COMPANY_GIG_MATCHING_HIRED,
} from '../../../constants/routes';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../constants/subscriptionTypes';
import { successNotification, infoNotification } from '../../../common/notifications_center/notificationCenterActions';
import { hireByLetsgig } from '../../gigs/gigDetails/candidates/companyGigCandidatesActions';

const { withRouter } = Routing;

const INIT_BILLING_PROFILE = {
  address: {
    city: '',
    line1: '',
    line2: '',
    postal_code: '',
    country: 'Sverige',
  },
  email: '',
  invoiceReference: '',
  vatNumber: '',
};

class BillingProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.isFormChanged = false;
    this.state = { billingProfile: null };
    this.newProfile = this.props.location.pathname === COMPANY_PAYMENTS_BILLING_PROFILE_NEW;
  }

  componentDidMount() {
    this.props.getBillingProfile().then(({ payload, isError }) => {
      if (!isError) {
        this.setState({
          billingProfile: {
            ...payload,
            address: {
              ...payload.address,
              country: 'Sverige',
            }
          }
        });
      } else {
        this.setState({ billingProfile: INIT_BILLING_PROFILE });
      }
    });
  }

  onFormChange = (prevState, { values }) => {
    this.isFormChanged = JSON.stringify(values) !== JSON.stringify(this.state.billingProfile);
  };

  onSaveProfileSuccess = () => {
    const { isDirectHiring, data, candidateId } = this.props.location.state;
    if (isDirectHiring) {
      this.props.payWithCard(data).then(async () => {
        await this.props.hireByLetsgig(data.gigId, candidateId);
        this.props.successNotification('company.payments.directHiring.successPayTitle');
        goTo(this.props.history, COMPANY_GIG_MATCHING_HIRED.replace(':id', data.gigId), true);
      });
    } else if (this.newProfile) {
      this.props.subscribe(this.props.selectedPricingPlanId).then(({ isError, payload }) => {
        if (!isError) {
          if (payload.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE) {
            this.props.successNotification('company.payments.subscription.subscribe.successTitle');
          } else {
            this.props.infoNotification(
              'company.payments.subscription.subscribe.pendingTitle',
              'company.payments.subscription.subscribe.pendingText'
            );
          }
          goTo(this.props.history, COMPANY_PAYMENTS_OVERVIEW, true);
        }
      });
    } else {
      this.goBack();
    }
  };

  handleBack = () => {
    if (this.isFormChanged) {
      showConfirmAlert(
        this.props.translate,
        {
          confirmKey: 'generic.discard',
          cancelKey: 'generic.cancel',
          textKey: 'companyMyProfile.doYouWantToDiscard',
          titleKey: 'companyMyProfile.unsavedChanges',
        },
        () => this.goBack()
      );
      return;
    }
    this.goBack();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  saveProfile = (billingProfile, { setErrors, setSubmitting }) => {
    this.props.saveBillingProfile(billingProfile).then((response) => {
      if (!response.isError) {
        this.onSaveProfileSuccess();
      } else {
        setErrors(response.errors);
        setSubmitting(false);
      }
    });
  };

  render() {
    const { translate } = this.props;
    const t = (key) => translate(`company.payments.billingProfile.${key}`);
    return (
      <BillingProfileScreen
        handleBack={this.handleBack}
        profile={this.state.billingProfile}
        handleSave={this.saveProfile}
        onFormChange={this.onFormChange}
        headerTitle={this.newProfile ? t('headerCreate') : t('headerEdit')}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPricingPlanId: state.company.get('payments').get('selectedPricingPlanId'),
});

BillingProfileContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location,
  translate: PropTypes.func.isRequired,
  selectedPricingPlanId: PropTypes.string,
  getBillingProfile: PropTypes.func.isRequired,
  saveBillingProfile: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  infoNotification: PropTypes.func.isRequired,
  isDirectHiring: PropTypes.bool,
  gigId: PropTypes.string,
  payWithCard: PropTypes.func,
  data: PropTypes.object,
  hireByLetsgig: PropTypes.func,
};

BillingProfileContainer.defaultProps = {
  location: null,
  selectedPricingPlanId: undefined,
  isDirectHiring: undefined,
  gigId: null,
  payWithCard: undefined,
  data: undefined,
  hireByLetsgig: undefined
};

export default connect(mapStateToProps, {
  getBillingProfile,
  saveBillingProfile,
  subscribe,
  successNotification,
  infoNotification,
  payWithCard,
  hireByLetsgig,
})(withRouter(withLocalize(BillingProfileContainer)));
