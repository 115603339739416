import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAvailableGigCategories } from './gig/gigCategoriesActions';
import { getAvailableGigCities } from './gig/city/gigCitiesActions';
import { getEducationCategories } from '../candidate/profile/education/category/educationCategoriesActions';

class DataProvider extends Component {
  componentDidMount() {
    this.props.getAvailableGigCategories();
    this.props.getAvailableGigCities();
    this.props.getEducationCategories();
  }

  render() {
    return (null);
  }
}

DataProvider.propTypes = {
  getAvailableGigCategories: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  getEducationCategories: PropTypes.func.isRequired,
};

export default connect(null, { getAvailableGigCategories, getAvailableGigCities, getEducationCategories })(DataProvider);
