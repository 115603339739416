import React from 'react';
import PropTypes from 'prop-types';
import {
  Image, Platform, StyleSheet, TouchableOpacity,
} from 'react-native';
import { spacings } from '../../styles/base.style';
import ViewportController from '../ViewportController';

const leftArrowsIcon = require('../../../assets/icons/arrows/iconsArrowsLeft.png');

const styles = StyleSheet.create({
  wrapper: {
    zIndex: 100,
    paddingLeft: spacings.xs,
    paddingRight: spacings.sm,
    ...Platform.select({
      web: {
        paddingLeft: spacings.md,
        paddingRight: spacings.xl,
        paddingTop: spacings.xl,
      },
    }),
  },
  back: {
    height: 18,
    width: 16,
  },
  imageNoSafeArea: {
    paddingTop: 18,
  },
});

const RewindButton = ({ onPress, noSafeArea }) => (
  <TouchableOpacity
    style={styles.wrapper}
    onPress={onPress}
  >
    <ViewportController safeAreaTop={!noSafeArea} noFlex style={noSafeArea && Platform.OS !== 'web' ? styles.imageNoSafeArea : null}>
      <Image style={styles.back} source={leftArrowsIcon} resizeMode="contain" />
    </ViewportController>
  </TouchableOpacity>
);

RewindButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  noSafeArea: PropTypes.bool,
};

RewindButton.defaultProps = {
  noSafeArea: false,
};

export default RewindButton;
