import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileCreatorAddHomeLocation } from '../../profileCreatorActions';
import ProfileCreatorStepHomeLocationScreen from './ProfileCreatorStepHomeLocationScreen';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import { candidateHomeLocationPropType, candidateLocationPropType } from '../../../../../constants/propTypes';
import { getAvailableGigCities } from '../../../../../common/gig/city/gigCitiesActions';
import { getHomeLocationByCoordinates } from '../../../../../common/geolocation/geolocationActions';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepHomeLocationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: true,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.HOME_LOCATION);
    this.props.getAvailableGigCities();
  }

  handleSubmit = () => {
    const {
      geoLoc, suburb, village, hamlet, neighbourhood, town, city, county, state, locationName,
    } = this.state.homeLocation;
    this.props.profileCreatorAddHomeLocation(
      {
        geoLoc,
        address: {
          suburb, village, hamlet, neighbourhood, town, city, county, state,
        },
        locationName,
      },
    );
    this.setState({ redirect: true });
  };

  onRegionChange = (region) => {
    this.setState({ loading: true });
    this.props.getHomeLocationByCoordinates(region).then((res) => {
      if (res.payload && !res.payload.error && !res.payload.isError) {
        this.setState({
          homeLocation: { geoLoc: { lon: region.longitude, lat: region.latitude }, ...res.payload.address, locationName: res.payload.locationName },
          loading: false,
        });
      }
    });
  };

  render = () => (
    <ProfileCreatorStepHomeLocationScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.HOME_LOCATION, this.props.grantedFeatures)}
      homeLocation={this.state.homeLocation || this.props.homeLocation}
      location={this.props.location}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.HOME_LOCATION, this.props.grantedFeatures)}
      onRegionChange={this.onRegionChange}
      loading={this.state.loading}
    />
  );
}

ProfileCreatorStepHomeLocationContainer.propTypes = {
  profileCreatorAddHomeLocation: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  getHomeLocationByCoordinates: PropTypes.func.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  homeLocation: candidateHomeLocationPropType,
  location: candidateLocationPropType,
};

ProfileCreatorStepHomeLocationContainer.defaultProps = {
  homeLocation: undefined,
  location: undefined,
};

const mapStateToProps = (state) => {
  const {
    location,
    homeLocation,
  } = state.candidate.get('profileData').toJS();

  return ({
    location,
    homeLocation,
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
    availableCities: state.gigCities.toJS(),
  });
};

export default connect(mapStateToProps, {
  profileCreatorAddHomeLocation, getAvailableGigCities, getHomeLocationByCoordinates,
})(ProfileCreatorStepHomeLocationContainer);
