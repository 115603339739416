import React, { Component } from 'react';
import {
  Platform, View, StyleSheet, Modal, TouchableOpacity, TouchableWithoutFeedback, Text, Keyboard,
} from 'react-native';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Picker } from '@react-native-picker/picker';
import { fontSize, padding, standardColors } from '../../styles/base.style';
import FormFieldError from './FormFieldError';
import { PrimaryTextInput } from '../index';
import { formBorderBottomWidth, inputTextStyle, textInputStyle } from '../../styles/form.style';
import StaticFormLabel from './StaticFormLabel';

const styles = StyleSheet.create({
  iosPickerOverlay: {
    flex: 1,
  },
  iosPickerWrapper: {
    borderTopColor: standardColors.border,
    borderTopWidth: StyleSheet.hairlineWidth,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
  },
  label: {
    marginBottom: 0,
  },
  iosPicker: {
    width: '100%',
  },
  iosPickerDone: {
    textAlign: 'right',
    fontSize: fontSize.xl,
    paddingRight: padding.lg,
    paddingTop: padding.md,
  },
  androidAndWebWrapper: {
    borderBottomColor: standardColors.border,
    borderBottomWidth: formBorderBottomWidth,
    width: '100%',
  },
  androidPicker: {
    transform: [
      {
        translateX: -8, // android uses its own input field, let's hope this trick works for most devices
      },
    ],
  },
});

const iosTextInputStyle = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
});

class PrimaryPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIosPicker: false,
      iosPickerValue: props.selectedValue,
    };
  }

  componentDidMount() {
    this.setDefaultValueIfNotSet();
  }

  componentDidUpdate(prevProps) {
    if (this.props.items.length && this.props.items !== prevProps.items) {
      this.setDefaultValueIfNotSet();
    }
    if (this.props.selectedValue !== prevProps.selectedValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ iosPickerValue: this.props.selectedValue });
    }
  }

  setDefaultValueIfNotSet() {
    const {
      items, onValueChange, selectedValue, nullable,
    } = this.props;

    if (items.length && selectedValue == null && !nullable) {
      onValueChange(items[0].value);
    }
  }

  renderItems = () => this.props.items.map((item) => <Picker.Item key={item.value} label={item.label} value={item.value} />);

  renderIos = () => {
    const {
      onValueChange, selectedValue, testID, style, inputStyle, error, label, enabled, labelSize, items, defaultValue,
    } = this.props;

    return (
      <>
        <Modal
          animationType="slide"
          transparent
          visible={this.state.showIosPicker}
        >
          <TouchableOpacity
            activeOpacity={1}
            style={styles.iosPickerOverlay}
            onPress={() => { this.setState({ showIosPicker: false }); }}
          >
            <TouchableWithoutFeedback>
              <View style={styles.iosPickerWrapper}>
                <TouchableOpacity
                  onPress={() => {
                    onValueChange(this.state.iosPickerValue != null ? this.state.iosPickerValue : defaultValue || items[0].value);
                    this.setState({ showIosPicker: false });
                  }}
                >
                  <Text style={styles.iosPickerDone}><Translate id="generic.done" /></Text>
                </TouchableOpacity>
                <Picker
                  style={styles.iosPicker}
                  itemStyle={inputTextStyle.inputText}
                  selectedValue={this.state.iosPickerValue || defaultValue}
                  onValueChange={
                    (val) => {
                      this.setState({ iosPickerValue: val });
                    }
                  }
                  testID={testID}
                >
                  {this.renderItems()}
                </Picker>
              </View>
            </TouchableWithoutFeedback>
          </TouchableOpacity>
        </Modal>

        <View style={[textInputStyle.container, style && style.container]}>

          { label && <StaticFormLabel label={label} size={labelSize} style={[styles.label, style ? style.label : null]} /> }

          <PrimaryTextInput
            // we fallback to iosTextInputStyle in case inputStyle is not overwritten, as we don't need margin
            style={inputStyle || iosTextInputStyle}
            pointerEvents="none"
            editable={false}
            disabled={!enabled}
            error={error}
            value={selectedValue != null ? items.find((i) => i.value === selectedValue).label : undefined}
            onPress={() => {
              this.setState({ showIosPicker: true });
              Keyboard.dismiss();
            }}
          />
        </View>
      </>
    );
  };

  renderWebAndAndroid = () => {
    const {
      onValueChange, selectedValue, testID, label, enabled, labelSize, style, inputStyle, error,
    } = this.props;
    return (
      <View style={[textInputStyle.container, style && style.container]}>
        { label && <StaticFormLabel label={label} size={labelSize} style={[styles.label, style ? style.label : null]} /> }
        <View style={[styles.androidAndWebWrapper, style && style.androidAndWebWrapper]}>
          <Picker
            style={[
              Platform.OS === 'android' ? styles.androidPicker : null,
              textInputStyle.inputFieldStyle,
              inputStyle && inputStyle.input,
              error && textInputStyle.inputError,
              !enabled && textInputStyle.inputDisabled,
            ]}
            selectedValue={selectedValue}
            onValueChange={onValueChange}
            testID={testID}
            enabled={enabled}
          >
            {this.renderItems()}
          </Picker>
        </View>
        <FormFieldError error={error} />
      </View>
    );
  };

  render() {
    if (!this.props.items || !this.props.items.length) {
      return null;
    }
    if (Platform.OS === 'ios') {
      return this.renderIos();
    }
    return this.renderWebAndAndroid();
  }
}

PrimaryPicker.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  })).isRequired,
  onValueChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any,
  testID: PropTypes.string,
  label: PropTypes.string,
  enabled: PropTypes.bool,
  // {container: {}, label: {}}
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  // {container: {}, label: {}, input: {}}
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  error: PropTypes.string,
  labelSize: PropTypes.string,
  nullable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
};

PrimaryPicker.defaultProps = {
  selectedValue: undefined,
  testID: undefined,
  label: null,
  enabled: true,
  style: null,
  inputStyle: null,
  error: null,
  labelSize: 'sm',
  nullable: false,
  defaultValue: undefined,
};

export default PrimaryPicker;
