import { fromJS, List, Map } from 'immutable';
import {
  CLOSE_GIG_REQUEST_SUCCESS,
  COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST_SUCCESS,
  COMPANY_GIG_HIRE_BY_OWN_REQUEST_SUCCESS,
  COMPANY_GIG_REJECT_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIGS_CLEAR_LIST,
  COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST_SUCCESS,
  COMPANY_GIGS_GET_OPEN_GIGS_REQUEST_SUCCESS,
  COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_SET,
  COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_REMOVE,
  COMPANY_GIGS_STORE_GIG_DATA,
  GET_GIG_REQUEST_SUCCESS,
  GIG_CREATOR_UPDATE_GIG_SUCCESS,
} from '../../constants/actionTypes';

const updateList = (state, keyInState, action) => state.set(keyInState, fromJS(action.payload));

const saveGigDetails = (state, gigId) => {
  const openGigs = state.get('open') || [];
  const gig = openGigs.find((g) => g.get('gigId') === gigId)
    || state.get('closed').find((g) => g.get('gigId') === gigId);
  return state.set('gigDataToPreview', gig);
};

export default function companyGigReducer(state = Map({
  open: null,
  closed: null,
  gigDataToPreview: null,
  newContentIndicators: List(),
}), action) {
  switch (action.type) {
    case COMPANY_GIGS_GET_OPEN_GIGS_REQUEST_SUCCESS:
      return updateList(state, 'open', action);

    case COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST_SUCCESS:
      return updateList(state, 'closed', action);

    case COMPANY_GIGS_CLEAR_LIST:
      return state.set(action.listKey, null);
    case COMPANY_GIGS_STORE_GIG_DATA:
      return saveGigDetails(state, action.gigId);
    case CLOSE_GIG_REQUEST_SUCCESS:
    case GET_GIG_REQUEST_SUCCESS:
    case COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST_SUCCESS:
    case COMPANY_GIG_HIRE_BY_OWN_REQUEST_SUCCESS:
    case COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST_SUCCESS:
    case COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST_SUCCESS:
    case COMPANY_GIG_REJECT_CANDIDATE_REQUEST_SUCCESS:
    case COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST_SUCCESS:
      return state.set('gigDataToPreview', fromJS(action.payload));
    case COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_SET:
      return state.set('newContentIndicators', state.get('newContentIndicators').push(fromJS(action.payload)));
    case COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_REMOVE:
      return state.set(
        'newContentIndicators',
        fromJS(state
          .get('newContentIndicators').toJS()
          .filter(({ gigId, gigCandidateStatus }) => {
            if (gigId === action.payload.gigId) {
              return gigCandidateStatus !== action.payload.gigCandidateStatus;
            }
            return true;
          })),
      );
    case GIG_CREATOR_UPDATE_GIG_SUCCESS:
      return state.set('gigDataToPreview', fromJS(action.payload));
    default:
      return state;
  }
}
