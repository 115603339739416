const companyAppTerms = `
Bemanningsavtal

Mellan Letsgig AB 559114-4505, Wenner-Gren Center plan 20, 113 46, Stockholm, nedan kallad (”Letsgig AB”) och det företag som härmed registrerar sig som uppdragsgivare i tjänsten nedan kallad (”Beställaren”) har denna dag träffats följande anställningsavtal.

Bakgrund
Letsgig AB (”Leverantören”), orgnr : 559114-4505 är ett bemanningsbolag som hyr ut personal, så kallade Uppdragstagare. Beställaren önskar från tid till annan hyra in extra personal genom att anlita Uppdragstagare via Leverantören att tjänstgöra hos Beställaren. Parterna har därför kommit överens om följande som härmed regleras i detta avtal. 

Med ”Bemanningsuppdrag” avses ett affärsmässigt och rättsligt förhållande mellan Leverantör och Beställare under vilket Leverantören mot ersättning ställer egen för Bemanningsuppdraget anställd personal till Beställarens förfogande för att utföra arbete som hör till Beställarens verksamhet under Beställarens kontroll och arbetsledning.

Bemanningsuppdraget inkluderar sökandet och den urvalsprocess som Leverantören tillgängliggör enligt punkten ovan och därefter tillhandahållandet av Uppdragstagaren för uppdrag hos Beställaren.

Genom detta avtal benämns villkoren för Bemanningsuppdraget som leverantören avser att leverera till Beställaren.

Uppdraget
Leverantören har fått i uppdrag att leverera Uppdragstagare till Beställaren enligt nedan:

Beställaren kan när som helst via Leverantörens app lägga upp ett uppdrag som denne behöver personal till. Beställaren kan själv ange timlönen och komma överens med respektive Uppdragstagare om hur många timmar uppdraget ska bestå av, när uppdraget skall utföras samt vart och andra sakliga punkter som rör uppdragets omfattning. Beställaren har möjlighet att själv kommunicera med tillgängliga Uppdragstagare i Letsgig appen för att hitta kandidater som denne vill anlita. När Beställaren hittat en eller flera Uppdragstagare som denne vill erbjuda uppdraget så görs detta direkt i appen genom att Beställaren erbjuder Uppdragstagare uppdraget. När en uppdragstagare accepterat uppdraget och Beställaren erlagt betalning i appen via ett betalkort så anses uppdraget bokat. 

Ersättning
Den totala summan för uppdraget reserveras vid bokningstillfället på Beställarens kort via Stripe som Leverantören använder och inkluderar alla skatter, sociala avgifter och kostnader som rör uppdraget. Beställaren erhåller kvitto som underlag för sin bokföring för respektive bokat uppdrag.

Anställning och kommunikation utanför LetsGigLeverantören har investerat betydande resurser för att möjliggöra den tekniska lösning som man tillhandahåller åt marknaden och därför är det av yttersta vikt att Beställaren förstår vikten av och accepterar följande:
	•	All kommunikation som rör anställningsförhållanden mellan Beställaren och Uppdragstagare måste ske via Letsgig appen. Att dela eller försöka dela kontaktuppgifter och föra samtal som rör anställningsförhållande vid sidan av appen är strikt förbjudet och användare som bryter mot detta kan komma att stängas av från tjänsten utan förvarning samt beläggas med vite för orsakad skada i form av uteblivna intäkter enligt nedan.
	•	Om Beställaren inom 24 månader efter initial kontakt via Leverantören erbjuder eller försöker erbjuda anställning till någon Uppdragstagare introducerad av Leverantören, oavsett om detta sker direkt eller genom något företag där Beställaren har inflytande, har Leverantören rätt att fakturera Beställaren 19 500 kr plus moms. Faktura ställs ut med 10 dagars betalningsvillkor samt avtalad ränta vid sen inbetalning om gällande referensränta plus 12%. 
Leverantörens skyldigheter
	•	Leverantören ska i mån av tillgång till lediga kandidater se till att tillgängliggöra dessa i sin plattform åt Beställaren samt tillhandahålla Uppdragstagare under den tid som framgår av respektive Uppdragsbeskrivning.
	•	Leverantören äger rätt att, efter samråd med Kunden, byta ut tillhandahållen Uppdragstagare om det är möjligt.
	•	Vid oplanerad frånvaro, t.ex. sjukdom, hos Uppdragstagare äger Leverantören rätt att, i mån av tillgång på Uppdragstagare med rätt profil, så snart som möjligt tillhandahålla lämplig ersättare om det bedöms som lämpligt. 
	•	Leverantören ansvarar inte för eventuella skador, så som till exempel försening av arbete, som Kunden kan åsamkas i anledning av oplanerad frånvaro. 

Beställarens skyldigheter
	•	Beställare förbinder sig att behandla Uppdragstagaren rättvist och jämlikt i relation till sina egna arbetstagare och i enlighet med god sed på arbetsmarknaden.
	•	Tillhandahållen Uppdragstagare ska följa de generella arbetstider som gäller för motsvarande personal hos Beställare. 
	•	Det åligger Beställare att ansvara för arbetsledning av Uppdragstagaren, att ansvara för kontroll och uppföljning av Uppdragstagarens arbete samt att lämna erforderliga instruktioner och information om Bemanningsuppdraget. 
	•	Det åligger Beställare att iakttaga 3 kap 12 § Arbetsmiljölagen (1977:1160) genom att informera hos Beställare tillhandahållen Uppdragstagare om lagar och förordningar samt egna interna instruktioner och rutiner som är hänförliga till verksamheten hos Beställare. 
	•	Beställare ska meddela Leverantören om eventuellt övertidsarbete för anlitad Uppdragstagare med hänsyn till det ansvar som Leverantören har för uthyrd Uppdragstagares övertid enligt lag eller kollektivavtal.
	•	Beställare ska utan oskäligt dröjsmål lämna information till Leverantören om olycksfall eller tillbud som berör tillhandahållen Uppdragstagare enligt lag.
	•	Beställare ska även i övrigt vidta de skyddsåtgärder som behövs för att Uppdragstagaren inte ska utsättas för ohälsa eller olycksfall. Det åligger Beställare att tillhandahålla kläder och skyddsutrustning om inte annat särskilt avtalats. 
	•	Om en Uppdragstagare blir sjuk och inte kan arbeta så debiteras Beställaren 80% av den avtalade taxan som medarbetaren varit inbokad och skulle ha arbetat för att bl.a. täcka sjuklön, dock i högst 14 dagar under en sammanhängande period. Eventuell ersättare kan bokas av Beställare via appen.

Ansvar
	•	Ingen av parterna ansvarar i något fall för indirekt skada, såsom t.ex utebliven vinst, förlust eller annan följdskada, inklusive eventuella ersättningsskyldigheter gentemot tredje man eller förlust av information.
	•	Leverantören ska inte vara skyldig att betala vite eller annan ersättning till följd av uthyrd Uppdragstagares uteblivande men ska dock efter bästa förmåga göra det möjligt för Beställaren att söka efter och, i mån av tillgång på lämpliga kandidater, bemanna med annan motsvarande personal.
	•	Leverantören ansvarar inte för tekniska problem eller oförutsedda händelser som kan påverka Beställaren negativt men eftersträvar att erbjuda en smidig upplevelse för att kunna erbjuda personal till Beställare. 

Personuppgifter:
	•	Leverantören ska tillse att Uppdragstagaren har lämnat samtycke till Beställarens behandling av personuppgifter enligt Dataskyddsförordningen (EU) 2016/679, som kan anses nödvändig i anledning av Bemanningsuppdraget. Beställaren är personuppgiftsansvarig för dessa uppgifter.
	•	Om uthyrd Uppdragstagare vid arbete för Beställaren behandlar personuppgifter är Leverantören inte personuppgiftsbiträde för sådan behandling.

Avtalstid och ändringar
	•	Detta Avtal träder i kraft den dag då detta Avtal undertecknas av båda Parter och gäller därefter för en period om 12 månader från det datum som inträffar senast av
	•	(i) det datum då detta Avtal undertecknats,
	•	(ii) det sista datum då någon Uppdragstagare tjänstgör för Beställaren enligt upprättad Bokningsbekräftelse.
	•	Detta Avtal gäller framför eventuella tidigare avtal eller överenskommelser mellan Parterna.

Tvister:
	•	Tvister rörande tolkning och tillämpning av detta Avtal, Bemanningsplanen, Bemanningsuppdraget och därmed sammanhängande rättsförhållanden ska med nedan angivna undantag hänskjutas till avgörande av Stockholms Tingsrätt.
	•	Svensk rätt gäller för detta Avtal, övriga dokument samt i övrigt för hela samarbetet mellan Parterna.

Detta avtal är upprättat i två likalydande exemplar, av vilket parterna tagit var sitt.
`;

export default companyAppTerms;
