import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import getProfile, { candidateProfileEditAssociation } from '../../CandidateProfileActions';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import { getAssociations } from '../../../../association/AssociationActions';
import CandidateProfileAssociationForm from '../../forms/association/CandidateProfileAssociationForm';

class CandidateProfileEditDetailsAssociationModalAware extends React.Component {
  openModal = () => {
    const {
      translate, closeModal, association, associationList
    } = this.props;
    this.props.setModalContent(
      translate('adminPanel.associations'),
      <ModalKeyboardAwareScrollView>
        <CandidateProfileEditDetailsFormWithFooter
          component={CandidateProfileAssociationForm}
          closeModal={closeModal}
          association={association}
          items={associationList}
          handleSubmit={(value) => {
            this.props.candidateProfileEditAssociation(value)
              .then((response) => {
                if (!response.isError) {
                  this.props.getProfile();
                  closeModal();
                }
              });
          }}
        />
      </ModalKeyboardAwareScrollView>,
    );
  };

  componentDidMount = () => {
    this.props.getAssociations('Upplands Väsby');
  }

  render() {
    const { translate, association } = this.props;

    return (
      <EditableField
        label={translate('adminPanel.associations')}
        value={association}
        placeholder={translate('adminPanel.chooseAssociation')}
        onPress={this.openModal}
      />
    );
  }
}

CandidateProfileEditDetailsAssociationModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  candidateProfileEditAssociation: PropTypes.func.isRequired,
  association: PropTypes.string.isRequired,
  getAssociations: PropTypes.func.isRequired,
  associationList: PropTypes.arrayOf(PropTypes.any).isRequired,
  getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const profile = state.candidate.get('profile');
  return {
    association: profile.get('association') || '',
    location: profile.get('location').get('cityV2').get('key') || '',
    associationList: state.association.get('associationsList'),
  };
};

export default connect(mapStateToProps, {
  candidateProfileEditAssociation, getAssociations, getProfile })(withLocalize(CandidateProfileEditDetailsAssociationModalAware));
