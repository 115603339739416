import React from 'react';

import {
  COMPANY_CANDIDATES_POOL,
  COMPANY_CANDIDATES_POOL_MAIN,
  COMPANY_CANDIDATES_POOL_MY_POOL,
  COMPANY_CANDIDATES_POOL_GROUP_MAIN,
  COMPANY_CANDIDATES_POOL_DIRECT_CHAT,
  COMPANY_CANDIDATES_POOL_PROFILE_EDIT,
  COMPANY_CANDIDATES_POOL_PROFILE_VIEW,
  COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE,
} from '../../constants/routes';
import Routing from '../../core/routing';
import { COMPANY_USER } from '../../constants/userScopes';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import CompanyCandidatesPoolGroupsRouter from './groups/CompanyCandidatesPoolGroupsRouter';
import CompanyCandidatesPoolMyPoolRouter from './my_pool/CompanyCandidatesPoolMyPoolRouter';
import CandidatesPoolCandidateProfileContainer from './candidate/CandidatesPoolCandidateProfileContainer';
import CompanyCandidatesPoolProfileEditContainer from './profile/CompanyCandidatesPoolProfileEditContainer';
import CompanyCandidatesPoolProfileViewContainer from './profile/CompanyCandidatesPoolProfileDetailsContainer';
import CompanyPoolDirectChatContainer from './chat/CompanyPoolDirectChatContainer';

const { Redirect } = Routing;

const DEFAULT_SCREEN = COMPANY_CANDIDATES_POOL_MY_POOL;

const CompanyCandidatesPoolRouting = () => (
  <>
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL} render={() => <Redirect to={DEFAULT_SCREEN} />} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_MAIN} component={CompanyCandidatesPoolMyPoolRouter} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_GROUP_MAIN} component={CompanyCandidatesPoolGroupsRouter} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE} component={CandidatesPoolCandidateProfileContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_PROFILE_EDIT} component={CompanyCandidatesPoolProfileEditContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_PROFILE_VIEW} component={CompanyCandidatesPoolProfileViewContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_DIRECT_CHAT} component={CompanyPoolDirectChatContainer} />
  </>
);

export default CompanyCandidatesPoolRouting;
