import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

const EmptyElementForPadding = ({ padding }) => (
  <View style={{ paddingTop: padding }} />
);

EmptyElementForPadding.propTypes = {
  padding: PropTypes.number.isRequired,
};

export default EmptyElementForPadding;
