import React from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { fontFamily, fontSizes } from '../styles/base.style';
import * as propTypes from '../../constants/propTypes';
import { font } from '../styles/mixins';

export const TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const styles = StyleSheet.create({
  primary: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
  },
  secondary: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xxs),
  },
});

const PrimaryTextBolek = ({
  children, type, style, ...props
}) => (
  <Text style={[styles[type], style]} {...props}>{children}</Text>
);

PrimaryTextBolek.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(TYPES)),
  style: propTypes.stylePropTypes,
};

PrimaryTextBolek.defaultProps = {
  style: undefined,
  type: TYPES.PRIMARY,
};

export default PrimaryTextBolek;
