import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { shadowBottom, spacings } from '../../common/styles/base.style';
import Container from '../../common/components/Container';
import { SUPERADMIN_USER } from '../../constants/userScopes';
import { ADMIN_COMPANY_SIGN_UP_SUCCESS } from '../../constants/routes';
import SignupScreen from '../../common/screens/SignupScreen';
import { adminSignUpCompany } from './AdminCompanyActions';
import AdminCompanySignupValidation from './AdminCompanySignupValidation';

const styles = StyleSheet.create({
  container: {
    ...shadowBottom,
    marginBottom: 0,
    paddingHorizontal: spacings.sm,
  },
});

const AdminCompanySignUpContainer = (props) => {
  const [submitted, setSubmitted] = useState(false);

  const createCompanyAccount = (values, { setErrors, setSubmitting }) => {
    props.adminSignUpCompany(values).then((response) => {
      if (response.isError) {
        setErrors(response.errors);
        setSubmitting(false);
      } else {
        setSubmitted(true);
      }
    });
  };
  return (
    <Container>
      <View style={styles.container}>
        <SignupScreen
          userType={SUPERADMIN_USER}
          redirectPath={ADMIN_COMPANY_SIGN_UP_SUCCESS}
          validation={AdminCompanySignupValidation}
          onSubmit={createCompanyAccount}
          redirect={submitted}
          onOpenPrivacy={() => {}}
          onOpenTerms={() => {}}
          handleCloseModal={() => {}}
          termsModalVisible={false}
          privacyModalVisible={false}
        />
      </View>
    </Container>
  );
};

AdminCompanySignUpContainer.propTypes = {
  adminSignUpCompany: PropTypes.func.isRequired,
};

export default connect(null, { adminSignUpCompany })(AdminCompanySignUpContainer);
