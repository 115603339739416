import {
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_OFFSET,
  CANDIDATE_GIGS_FIND_GIGS_REQUEST,
  CANDIDATE_GIGS_FIND_GIGS_SET_SCROLL_POSITION,
  CANDIDATE_GIGS_FIND_GIGS_CLEAR_LIST,
  CANDIDATE_GIGS_FIND_GIGS_SET_CURRENT_INDEX,
  CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST,
  CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST,
  CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST,
  CANDIDATE_CHANGE_FAVOURITE_COUNTER,
  CANDIDATE_CHANGE_NOT_INTERESTING_COUNTER,
  CANDIDATE_GIGS_REMOVE_FAVOURITE_GIG_FROM_LIST,
  CANDIDATE_GIGS_REMOVE_NOT_INTERESTING_GIG_FROM_LIST,
  CANDIDATE_ADD_FAVOURITE_GIG,
  CANDIDATE_ADD_NOT_INTERESTING_GIG,
  CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_MODAL, CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_INFO_MODAL,
} from '../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';
import { FEATURE_TRACKING_FEATURES_SHOWN } from '../../../common/featureTracking/featureTrackingReducer';
import { setFeatureShown } from '../../../common/featureTracking/featureTrackingActions';

export const GIGS_LIMIT = 10;
export const EXPIRED_GIG_VISIBILITY_THRESHOLD = 10;

export function candidateFindGigsWithInterestingFilter(reload, offset, categories, cityKey, position, filterOutFavourites, limit) {
  if (!offset) {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.FIND_GIGS.FIND_GIGS);
  } else if (filterOutFavourites) {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.FIND_GIGS.FIND_GIGS_FAVOURITES);
  } else {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.FIND_GIGS.FIND_GIGS_FETCH_MORE);
  }
  if (position) {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.FIND_GIGS.FIND_GIGS_WITH_TEXT_SEARCH, position);
  }

  const payload = {
    request: {
      method: 'get',
      url: 'candidate/gig',
      params: {
        offset,
        limit: limit || GIGS_LIMIT,
        categories,
        cityKey,
        position,
      },
    },
  };

  if (filterOutFavourites) {
    payload.request.params.filterOutFavourites = true;
  }

  return {
    type: CANDIDATE_GIGS_FIND_GIGS_REQUEST,
    reload,
    payload,
  };
}

export function candidateGetFavouriteGigs(minClosedDate) {
  return {
    type: CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/gig/favourites',
        params: {
          minClosedDate,
        },
      },
    },
  };
}

export function candidateGetNotInterestingGigs(minClosedDate) {
  return {
    type: CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/gig/notInteresting',
        params: {
          minClosedDate,
        },
      },
    },
  };
}

export function candidateGetFavouritesAndNotInterestingCounters(minClosedDate) {
  return {
    type: CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/gig/favourites/count',
        params: {
          minClosedDate,
        },
      },
    },
  };
}

export function candidateFindGigs(reload, offset, categories, cityKey, position) {
  return candidateFindGigsWithInterestingFilter(reload, offset, categories, cityKey, position, false);
}

export function candidateFindGigsChangeOffset(offset) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_CHANGE_OFFSET,
    offset,
  };
}

export function candidateFindGigsSetScrollPosition(scrollPosition) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_SET_SCROLL_POSITION,
    scrollPosition,
  };
}

export function candidateFindGigsSetCurrentIndex(currentIndex) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_SET_CURRENT_INDEX,
    currentIndex,
  };
}

export function candidateSetShowFavouritesModal(shouldShow) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_MODAL,
    shouldShow,
  };
}

export function candidateSetShowInfoModal(shouldShow) {
  return async (dispatch) => {
    if (shouldShow === false) {
      dispatch(setFeatureShown(FEATURE_TRACKING_FEATURES_SHOWN.FIND_GIG_SWIPE_SHOWN));
    }
    return dispatch({
      type: CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_INFO_MODAL,
      shouldShow,
    });
  };
}

export function clearGigList() {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_CLEAR_LIST,
  };
}

export function candidateChangeFavouriteCounter(modifier) {
  return {
    type: CANDIDATE_CHANGE_FAVOURITE_COUNTER,
    modifier,
  };
}

export function candidateChangeNotInterestingCounter(modifier) {
  return {
    type: CANDIDATE_CHANGE_NOT_INTERESTING_COUNTER,
    modifier,
  };
}

export function removeFavouriteGigFromList(gigId) {
  return async (dispatch) => {
    dispatch(candidateChangeFavouriteCounter(-1));
    return dispatch({
      type: CANDIDATE_GIGS_REMOVE_FAVOURITE_GIG_FROM_LIST,
      gigId,
    });
  };
}

export function removeNotInterestingGigFromList(gigId) {
  return async (dispatch) => {
    dispatch(candidateChangeNotInterestingCounter(-1));
    return dispatch({
      type: CANDIDATE_GIGS_REMOVE_NOT_INTERESTING_GIG_FROM_LIST,
      gigId,
    });
  };
}

export function addFavouriteGig(gigId, fromSwipeScreen) {
  return async (dispatch, getState) => {
    // handling reprocessing after a rewind
    const lastAction = getState().candidateFindGigs.get('lastSingleAction');
    if (fromSwipeScreen && lastAction && lastAction.get('gigId') === gigId) {
      if (lastAction.get('action') === 'notInteresting') {
        await dispatch(candidateChangeNotInterestingCounter(-1));
        await dispatch(candidateChangeFavouriteCounter(1));
      }
      // if last action was favourite, don't bump the counter again
    } else {
      await dispatch(candidateChangeFavouriteCounter(1));
    }

    return dispatch({
      type: CANDIDATE_ADD_FAVOURITE_GIG,
      gigId,
      payload: {
        request: {
          method: 'post',
          url: `/candidate/gig/${gigId}/favourite`,
        },
      },
    });
  };
}

export function addNotInterestingGig(gigId, fromSwipeScreen) {
  return async (dispatch, getState) => {
    // handling reprocessing after a rewind
    const lastAction = getState().candidateFindGigs.get('lastSingleAction');
    if (fromSwipeScreen && lastAction && lastAction.get('gigId') === gigId) {
      if (lastAction.get('action') === 'favourite') {
        await dispatch(candidateChangeFavouriteCounter(-1));
        await dispatch(candidateChangeNotInterestingCounter(1));
      }
      // if last action was reject, don't bump the counter again
    } else {
      await dispatch(candidateChangeNotInterestingCounter(1));
    }

    return dispatch({
      type: CANDIDATE_ADD_NOT_INTERESTING_GIG,
      gigId,
      payload: {
        request: {
          method: 'post',
          url: `/candidate/gig/${gigId}/notinteresting`,
        },
      },
    });
  };
}
