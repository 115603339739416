import { Map, fromJS } from 'immutable';
import { COMPANY_STATUS_REQUEST_SUCCESS } from '../../constants/actionTypes';

export default function companyStatusReducer(state = Map({
  hasAnyGig: false,
}), action) {
  switch (action.type) {
    case COMPANY_STATUS_REQUEST_SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}
