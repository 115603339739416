import luhn from 'luhn';
import validator from 'validator';
import { candidateSignupValidation, termsValidator } from '../../common/auth/UserSignupValidation';
import { emailValidation } from '../../common/auth/LoginValidation';
import { phoneNumberValidation } from '../../common/msisdn/phoneNumberValidation';

export const COMPANY_NAME_MIN_LENGTH = 2;
export const COMPANY_NAME_MAX_LENGTH = 60;

const companyRegistrationNumberRegexp = /^(\d{10}|(\d{6}-\d{4}))$/;

function isNotDeathCertificateNumber(value) {
  return !value.startsWith(1);
}

const isSwedishCompanyRegistrationNumber = (value) => {
  if (!companyRegistrationNumberRegexp.test(value)) {
    return false;
  }
  if (!luhn.validate(value.replace('-', ''))) {
    return false;
  }
  return isNotDeathCertificateNumber(value);
};

export const getRegistrationNumberWithDash = (val) => {
  const plainValue = val.replace(/-/g, '');
  if (plainValue.length >= 6) {
    return `${plainValue.slice(0, 6)}-${plainValue.slice(6, plainValue.length)}`;
  }
  return plainValue;
};

// CompanySignupFormValidator inherits validators from UserSignupFormValidator
export const companyNameValidator = [
  // companyName
  {
    field: 'companyName',
    method: validator.isLength,
    args: [{ min: COMPANY_NAME_MIN_LENGTH }],
    message: 'too_short',
  },
  {
    field: 'companyName',
    method: validator.isLength,
    args: [{ max: COMPANY_NAME_MAX_LENGTH }],
    message: 'too_long',
  },
];

export const companyRegistrationNumberValidators = [
  {
    field: 'companyRegistrationNumber',
    custom: isSwedishCompanyRegistrationNumber,
    message: 'wrong_format',
  },
];

export default [
  termsValidator,
  ...emailValidation,
  ...candidateSignupValidation,
  ...phoneNumberValidation,
  ...companyNameValidator,
  ...companyRegistrationNumberValidators,
];
