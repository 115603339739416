import {
  SHIFT_CREATOR_CLEAR,
  SHIFT_CREATOR_SET_SALARY,
  SHIFT_CREATOR_USE_TEMPLATE,
  SHIFT_CREATOR_SET_POSITION,
  SHIFT_CREATOR_SET_LOCATION,
  SHIFT_CREATOR_SET_DESCRIPTION,
  SHIFT_CREATOR_SET_DATE_AND_TIMES,
  SHIFT_CREATOR_SET_BOOK_SEPARATELY,
  SHIFT_CREATOR_SET_ACCESS_ENTITIES,
  SHIFT_CREATOR_SET_SAVE_AS_TEMPLATE,
  SHIFT_CREATOR_SET_CANDIDATES_NUMBER,
} from '../../../constants/actionTypes';

export function shiftCreatorClear() {
  return {
    type: SHIFT_CREATOR_CLEAR,
  };
}

export function shiftCreatorUseTemplate(template) {
  return {
    type: SHIFT_CREATOR_USE_TEMPLATE,
    template,
  };
}

export function shiftCreatorSetPosition(position) {
  return {
    type: SHIFT_CREATOR_SET_POSITION,
    position,
  };
}

export function shiftCreatorSetDescription(description) {
  return {
    type: SHIFT_CREATOR_SET_DESCRIPTION,
    description,
  };
}

export function shiftCreatorSetCandidatesNumber(candidatesNumber) {
  return {
    type: SHIFT_CREATOR_SET_CANDIDATES_NUMBER,
    candidatesNumber,
  };
}

export function shiftCreatorSetLocation(location) {
  return {
    type: SHIFT_CREATOR_SET_LOCATION,
    location,
  };
}

export function shiftCreatorSetAccessEntities(accessEntities) {
  return {
    type: SHIFT_CREATOR_SET_ACCESS_ENTITIES,
    accessEntities,
  };
}

export function shiftCreatorSetSaveAsTemplate(saveAsTemplate) {
  return {
    type: SHIFT_CREATOR_SET_SAVE_AS_TEMPLATE,
    saveAsTemplate,
  };
}

export function shiftCreatorSetDatesAndTimes(shiftDatesAndTimes) {
  return {
    type: SHIFT_CREATOR_SET_DATE_AND_TIMES,
    shiftDatesAndTimes,
  };
}

export function shiftCreatorSetBookSeparately(bookSeparately) {
  return {
    type: SHIFT_CREATOR_SET_BOOK_SEPARATELY,
    bookSeparately,
  };
}

export function shiftCreatorSetShiftSalary(shiftSalary) {
  return {
    type: SHIFT_CREATOR_SET_SALARY,
    shiftSalary,
  };
}
