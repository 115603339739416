import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics.web';
import {
  COMPANY_SHIFTS_CREATE_REQUEST,
  COMPANY_SHIFTS_GET_SHIFT_REQUEST,
  COMPANY_SHIFTS_GET_SHIFTS_REQUEST,
  COMPANY_SHIFTS_STORE_SCROLL_POSITION,
  COMPANY_SHIFTS_GET_SHIFT_TEMPLATES_REQUEST,
  COMPANY_SHIFTS_REMOVE_SHIFT_TEMPLATE_REQUEST,
  COMPANY_SHIFTS_CLOSE_SHIFT_REQUEST,
  COMPANY_SHIFTS_DELETE_SHIFT_REQUEST,
} from '../../constants/actionTypes';

export function createShift(shift) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.SHIFTS.CREATE);
  return {
    type: COMPANY_SHIFTS_CREATE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/company/shift',
        data: shift,
      },
    },
  };
}

export function getShift(gigId) {
  return {
    type: COMPANY_SHIFTS_GET_SHIFT_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/company/shift/${gigId}`,
      },
    },
  };
}

export function getShiftTemplates() {
  return {
    type: COMPANY_SHIFTS_GET_SHIFT_TEMPLATES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/shiftTemplate',
      },
    },
  };
}

export function removeShiftTemplate(shiftTemplateId) {
  return {
    type: COMPANY_SHIFTS_REMOVE_SHIFT_TEMPLATE_REQUEST,
    payload: {
      request: {
        method: 'delete',
        url: `/company/shiftTemplate/${shiftTemplateId}`,
      },
    },
  };
}

export function getShifts({ closed, closedManually }) {
  let sortBy;
  let sortDirection;
  if (closedManually) {
    sortBy = 'closeDate';
    sortDirection = 'DESC';
  } else {
    sortBy = closed ? 'expiryDate' : 'startDate';
    sortDirection = closed ? 'DESC' : 'ASC';
  }
  return {
    type: COMPANY_SHIFTS_GET_SHIFTS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/shift',
        params: {
          closed, closedManually, sortBy, sortDirection,
        },
      },
    },
  };
}

export function storeShiftsListScrollPosition(scrollPosition) {
  return {
    type: COMPANY_SHIFTS_STORE_SCROLL_POSITION,
    scrollPosition,
  };
}

export function closeShift(gigId) {
  return {
    type: COMPANY_SHIFTS_CLOSE_SHIFT_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/company/shift/${gigId}/close`,
      },
    },
  };
}

export function deleteShift(gigId) {
  return {
    type: COMPANY_SHIFTS_DELETE_SHIFT_REQUEST,
    payload: {
      request: {
        method: 'delete',
        url: `/company/shift/${gigId}`,
      },
    },
  };
}
