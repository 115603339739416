import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Text, View, Image, Platform,
} from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import styles, { DESCRIPTION_LINE_HEIGHT, HEADER_FIRST_LINE_HEIGHT } from './single/SingleFindGigsItemStyles';
import GigSalary from '../GigSalary';
import { fingGigPropType, gigCategoryPropType } from '../../../constants/propTypes';
import { getWorkloadLabel } from '../../../constants/availabilityTypes';
import { toDate } from '../../../utilities/dateFormatter';
import { CANDIDATE_GIG_DETAILS } from '../../../constants/routes';
import Routing from '../../../core/routing';
import { GIG_DETAILS_ORIGIN } from '../../../constants/gigDetailsOrigin';
import { getImageSourceForGig } from '../../../common/components/image/imageHelper';
import { findCategoryByKey, sortCategoriesByOrder } from '../../../common/gig/gigCategoriesHelper';
import Touchable from '../../../common/components/Touchable';
import { spacings, windowHeight, windowWidth } from '../../../common/styles/base.style';

export const CALCULATED_IMAGE_WIDTH = (windowWidth - spacings.md * 4) / 3;
const locationIcon = require('../../../assets/icons/singlegigview/location.png');

const { withRouter } = Routing;

class SingleFindGigsComponent extends Component {
  static imageToSizeMap = {};

  redirectToGigDetails = (gig, history) => {
    const queryStr = queryString.stringify({ origin: GIG_DETAILS_ORIGIN.FIND_GIGS_SINGLE });
    history.push(`${CANDIDATE_GIG_DETAILS.replace(':id', gig.gigId)}?${queryStr}`);
  };

  renderNewHeader = (gig, translate) => {
    const { compensation } = gig;
    return (
      <>
        <View style={styles.headerContainer}>
          <View style={styles.headerFirstLine}>
            <View style={styles.positionContainer}>
              <Text numberOfLines={2} style={styles.position}>{gig.position}</Text>
            </View>
            {this.renderImage(gig, translate)}
          </View>
          <View>
            {!!gig.companyName
              && (
              <View>
                <Text numberOfLines={2} style={styles.companyName}>{gig.companyName}</Text>
              </View>
              )}
            <View style={styles.gigConditionsContainer}>
              { compensation
                && (
                <View>
                  <GigSalary
                    style={styles.compensation}
                    compensation={{ salary: compensation.salary, salaryByAgreement: compensation.salaryByAgreement }}
                  />
                </View>
                )}
              {gig.workload && (
              <View><Text style={styles.weeklyWorkload}>{getWorkloadLabel(gig.workload, translate, false)}</Text></View>
              )}
            </View>
          </View>
          <View />
        </View>
      </>
    );
  };

  calculateNumberOfLinesForDescription = (insets) => {
    const wh = windowHeight;
    const elements = {
      filters: 50,
      tabBar: 37,
      menu: 65,
      bottomButtons: 100,
      boxTop: 90,
      boxBottom: 15,
      paddings: spacings.sm * 4,
      notchTop: Platform.OS === 'web' ? 0 : insets.top,
      notchBottom: Platform.OS === 'web' ? 0 : insets.bottom,
      extraSafety: 120,
    };

    const total = Object.values(elements).reduce((sum, curr) => sum + curr, 0);
    return Math.max(((wh - total) / DESCRIPTION_LINE_HEIGHT), 2);
  }

  renderDescription = (gig) => (
    <>
      <View style={styles.strikethrough} />
      <SafeAreaInsetsContext.Consumer>
        {(insets) => <Text numberOfLines={this.calculateNumberOfLinesForDescription(insets)} style={styles.description}>{gig.description}</Text>}
      </SafeAreaInsetsContext.Consumer>
      <View style={styles.strikethrough} />

    </>
  );

  renderFooter = (gig, translate) => (
    <>
      <View style={styles.footerContainer}>
        <View style={styles.locationContainer}>
          <Image style={styles.locationIcon} source={locationIcon} />
          <Text style={styles.location}>
            {gig.location.city}
          </Text>
        </View>
        <View style={{ display: 'flex' }}>
          <Text style={styles.expiryDate}>
            {`${translate('companyGigCreator.preview.expiryDateShort')}: ${toDate(gig.expiryDate)}`}
          </Text>
        </View>
        <View />
      </View>
    </>
  );

  // we need exact dimensions so that the image is contained inside maximum height, and if smaller than suggested width, then is glued to the right side,
  // if we use flex with 'contain', then the image sometimes gets centered and does not stick to top and right
  // additionally, we cache the size in the static map, not to cause flickering when rendering the new card (while substituting gig + 1 with gig)
  renderImage = () => {
    function limitImageSizeToMaxHeight(imgHeight, imgWidth) {
      let precalculatedHeight = imgHeight / (imgWidth / CALCULATED_IMAGE_WIDTH);
      let precalculatedWidth = CALCULATED_IMAGE_WIDTH;
      if (precalculatedHeight > HEADER_FIRST_LINE_HEIGHT) {
        precalculatedHeight = HEADER_FIRST_LINE_HEIGHT;
        precalculatedWidth = imgWidth / (imgHeight / precalculatedHeight);
      }
      return { precalculatedHeight, precalculatedWidth };
    }

    const {
      gig, availableCategories,
    } = this.props;

    if (!availableCategories.length) {
      return null;
    }
    const imgSrc = getImageSourceForGig(gig.photoUri
      || gig.imageUrl
      || findCategoryByKey(availableCategories, sortCategoriesByOrder(gig.categories, availableCategories)[0]).image);

    const imgSize = SingleFindGigsComponent.imageToSizeMap[imgSrc.uri];
    if (!imgSize) {
      if (Platform.OS === 'web') {
        SingleFindGigsComponent.imageToSizeMap[imgSrc.uri] = {};
      } else {
        Image.getSize(Platform.OS === 'android' ? imgSrc.uri : imgSrc, (width, height) => {
          SingleFindGigsComponent.imageToSizeMap[imgSrc.uri] = { width, height };
          // force rerender
          this.setState({ });
        });
      }
      return null;
    }

    const { precalculatedHeight, precalculatedWidth } = limitImageSizeToMaxHeight(imgSize.height, imgSize.width);

    return (
      <Image
        source={
          imgSrc
        }
        style={[styles.headerGigImage, { width: precalculatedWidth, height: precalculatedHeight }]}
      />
    );
  }

  render() {
    const {
      gig, history, translate, onLayout,
    } = this.props;
    return (
      <Touchable
        style={[styles.box]}
        activeOpacity={0.7}
        onPress={() => this.redirectToGigDetails(gig, history)}
        onLayout={onLayout}
      >
        {this.renderNewHeader(gig, translate)}
        {this.renderDescription(gig, translate)}
        {this.renderFooter(gig, translate)}
      </Touchable>
    );
  }
}

SingleFindGigsComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  onLayout: PropTypes.func,
  gig: fingGigPropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
};
SingleFindGigsComponent.defaultProps = {
  onLayout: () => {},
};

const mapStateToProps = (state) => ({
  availableCategories: state.gigCategories.toJS(),
});

export default connect(mapStateToProps, {})(withRouter(SingleFindGigsComponent));
