import {
  GIG_CREATOR_ADD_AVAILABILITY,
  GIG_CREATOR_ADD_CANDIDATES_NUMBER,
  GIG_CREATOR_ADD_CATEGORIES,
  GIG_CREATOR_ADD_DATES,
  GIG_CREATOR_ADD_DESCRIPTION,
  GIG_CREATOR_ADD_EXPIRY_DATE,
  GIG_CREATOR_ADD_LOCATION,
  GIG_CREATOR_ADD_NAME,
  GIG_CREATOR_ADD_POSITION,
  GIG_CREATOR_ADD_REMOTE_WORK_ALLOWED,
  GIG_CREATOR_ADD_EXPERIENCE,
  GIG_CREATOR_ADD_LANGUAGES,
  GIG_CREATOR_CLEAR_ALL_DATA,
  GIG_CREATOR_SAVE_NEW_GIG,
  GIG_CREATOR_ADD_PHOTO,
  GIG_CREATOR_UPLOAD_PHOTO_REQUEST,
  GIG_CREATOR_ADD_COMPENSATION,
  GIG_CREATOR_INIT_EDIT_GIG,
  GIG_CREATOR_UPDATE_GIG,
  GIG_CREATOR_ADD_OCCUPATION,
  GIG_CREATOR_ADD_QUESTIONNAIRE,
  GIG_CREATOR_ADD_CAREER_URL,
  GIG_CREATOR_CHECK_GIG_LIMIT,
  GIG_ACTIVE_COUNT,
} from '../../constants/actionTypes';
import { uploadImage } from '../../core/client/imageUploadClient';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export function gigCreatorAddName(name) {
  return {
    type: GIG_CREATOR_ADD_NAME,
    name,
  };
}

export function gigCreatorAddExpiryDate(expiryDate) {
  return {
    type: GIG_CREATOR_ADD_EXPIRY_DATE,
    expiryDate,
  };
}

export function gigCreatorAddCategories(categories) {
  return {
    type: GIG_CREATOR_ADD_CATEGORIES,
    categories,
  };
}

export function gigCreatorAddPosition(position) {
  return {
    type: GIG_CREATOR_ADD_POSITION,
    position,
  };
}

export function gigCreatorAddDescription(description) {
  return {
    type: GIG_CREATOR_ADD_DESCRIPTION,
    description,
  };
}

export function gigCreatorAddCandidatesNumber(candidatesNumber) {
  return {
    type: GIG_CREATOR_ADD_CANDIDATES_NUMBER,
    candidatesNumber,
  };
}

export function gigCreatorAddCareerUrl(careerUrl) {
  return {
    type: GIG_CREATOR_ADD_CAREER_URL,
    careerUrl,
  };
}

export function gigCreatorAddLocation(location, street, info) {
  return {
    type: GIG_CREATOR_ADD_LOCATION,
    location: {
      ...location,
      street,
      info,
    },
  };
}

export function gigCreatorAddCompensation({
  min, max, salaryPer, salaryByAgreement, labourContract, volunteering, internshipTrainee,
}) {
  return {
    type: GIG_CREATOR_ADD_COMPENSATION,
    compensation: {
      salary: {
        min: parseInt(min, 10),
        max: parseInt(max, 10),
        per: salaryPer,
      },
      salaryByAgreement,
      labourContract,
      volunteering,
      internshipTrainee,
    },
  };
}

export function gigCreatorAddRemoteWorkAllowed(remoteWorkAllowed) {
  return {
    type: GIG_CREATOR_ADD_REMOTE_WORK_ALLOWED,
    remoteWorkAllowed,
  };
}

export function gigCreatorAddDates(dates) {
  return {
    type: GIG_CREATOR_ADD_DATES,
    dates,
  };
}

export function gigCreatorAddAvailability(workload, shifts) {
  return {
    type: GIG_CREATOR_ADD_AVAILABILITY,
    workload,
    shifts,
  };
}

export function gigCreatorAddExperience(experience) {
  return {
    type: GIG_CREATOR_ADD_EXPERIENCE,
    experience,
  };
}

export function gigCreatorAddLanguages(languages) {
  return {
    type: GIG_CREATOR_ADD_LANGUAGES,
    languages,
  };
}

export function gigCreatorAddPhoto(photoUri) {
  return {
    type: GIG_CREATOR_ADD_PHOTO,
    photoUri,
  };
}

export function gigCreatorAddPreferredOccupation(preferredOccupation) {
  return {
    type: GIG_CREATOR_ADD_OCCUPATION,
    preferredOccupation,
  };
}

export function gigCreatorAddQuestionnaire(questionnaire) {
  return {
    type: GIG_CREATOR_ADD_QUESTIONNAIRE,
    questionnaire,
  };
}

export const gigCreatorUploadGigPhoto = (imageUri) => uploadImage(imageUri, 'company/gig/photo', GIG_CREATOR_UPLOAD_PHOTO_REQUEST);

export function gigCreatorClearAllData() {
  return {
    type: GIG_CREATOR_CLEAR_ALL_DATA,
  };
}

export function gigCreatorInitEditing(gig) {
  return {
    type: GIG_CREATOR_INIT_EDIT_GIG,
    gig,
  };
}

export function gigCreatorSaveGig(gigData) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG);
  return {
    type: GIG_CREATOR_SAVE_NEW_GIG,
    payload: {
      request: {
        method: 'post',
        url: '/company/gig',
        data: gigData,
      },
    },
  };
}

export function gigCreatorUpdateGig(gigId, sectionName, gigData) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.UPDATE_GIG);
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.UPDATE);
  return {
    type: GIG_CREATOR_UPDATE_GIG,
    payload: {
      request: {
        method: 'post',
        url: `/company/gig/${gigId}/${sectionName}`,
        data: gigData,
      },
    },
  };
}

export function gigCreatorCheckGigLimit() {
  console.log('gigCreatorCheckGigLimitgigCreatorCheckGigLimit');
  // googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CHECK_GIG_LIMI);
  return {
    type: GIG_CREATOR_CHECK_GIG_LIMIT,
    payload: {
      request: {
        method: 'get',
        url: '/company/profile/check-limit',
        // data: gigData,
      },
    },
  };
}

export function gigActiveCount() {
  console.log('gigActiveCount');
  return {
    type: GIG_ACTIVE_COUNT,
    payload: {
      request: {
        method: 'get',
        url: '/user/account/check-active-gigs',
        // data: gigData,
      },
    },
  };
}
