import React from 'react';

import { Platform } from 'react-native';
import Routing from '../core/routing/index';
import AuthorizedRoute from '../common/components/navigation/AuthorizedRoute';
import {
  ADMIN,
  ADMIN_CANDIDATES,
  ADMIN_COMPANIES,
  ADMIN_COMPANY,
  ADMIN_FIND_GIGS,
  ADMIN_GIGS,
  ADMIN_NOTIFICATION,
  ADMIN_PROFILE,
  ADMIN_SETTINGS,
  ADMIN_FORTNOX,
  ADMIN_ASSOCIATIONS
} from '../constants/routes';
import Container from '../common/components/Container';
import ViewportController from '../common/components/ViewportController';
import AdminGigRouting from './gig/AdminGigRouting';
import { ADMIN_USER, SUPERADMIN_USER } from '../constants/userScopes';
import AdminProfileRouting from './profile/AdminProfileRouting';
import AdminNavigationBar from './menu/AdminNavigationBar';
import AdminCandidateRouting from './candidate/AdminCandidateRouting';
import AdminNotificationRouting from './notification/AdminNotificationRouting';
import AdminCompanyRouting from './company/AdminCompanyRouting';
import AdminFindCompanyRouting from './company/AdminFindCompanyRouting';
import AdminSettingsRouting from './settings/AdminSettingsRouting';
import AdminFortnoxRouting from './fortnox/AdminFortnoxRouting';
import AdminAssociationsRouting from './associations/AdminAssociationsRouting';

const { Route, Redirect, withRouter } = Routing;
const DEFAULT_SCREEN = ADMIN_FIND_GIGS;

const AdminRouting = () => (
  <ViewportController>
    <Container>
      <Route exact path={ADMIN} render={() => <Redirect to={DEFAULT_SCREEN} />} />
      <AuthorizedRoute only={[ADMIN_USER, SUPERADMIN_USER]} path={ADMIN_GIGS} component={AdminGigRouting} />
      <AuthorizedRoute only={[ADMIN_USER, SUPERADMIN_USER]} path={ADMIN_CANDIDATES} component={AdminCandidateRouting} />
      <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_COMPANIES} component={AdminCompanyRouting} />
      <AuthorizedRoute only={[ADMIN_USER, SUPERADMIN_USER]} path={ADMIN_PROFILE} component={AdminProfileRouting} />
      <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_NOTIFICATION} component={AdminNotificationRouting} />
      <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_COMPANY} component={AdminFindCompanyRouting} />
      <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_SETTINGS} component={AdminSettingsRouting} />
      <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_FORTNOX} component={AdminFortnoxRouting} />
      <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_ASSOCIATIONS} component={AdminAssociationsRouting} />
      {Platform.OS !== 'web' && <AdminNavigationBar />}
    </Container>
  </ViewportController>
);

export default withRouter(AdminRouting);
