import { Platform } from 'react-native';

import {
  CANDIDATE_CREATE_PROFILE_CATEGORY,
  CANDIDATE_CREATE_PROFILE_AVAILABILITY,
  CANDIDATE_CREATE_PROFILE_EXPERIENCE,
  CANDIDATE_CREATE_PROFILE_EDUCATION,
  CANDIDATE_CREATE_PROFILE_SUCCESS_REQUIRED_STEPS,
  CANDIDATE_CREATE_PROFILE_CURRENT_OCCUPATION,
  CANDIDATE_CREATE_PROFILE_LANGUAGES,
  CANDIDATE_CREATE_PROFILE_GIG_LOCATION,
  CANDIDATE_CREATE_PROFILE_HOME_LOCATION,
} from '../../../constants/routes';

export const PROFILE_CREATION_STEP = {
  ACCOUNT: 'account',
  CURRENT_OCCUPATION: 'currentOccupation',
  GIG_CATEGORIES: 'gigs',
  GIG_LOCATION: 'location',
  HOME_LOCATION: 'homeLocation',
  AVAILABILITY: 'availability',
  LANGUAGES: 'languages',
  EXPERIENCE: 'experience',
  EDUCATION: 'education',
};

export const PROFILE_CREATION_STEPS = [
  {
    titleKey: PROFILE_CREATION_STEP.ACCOUNT,
    done: 1,
  },
  {
    titleKey: PROFILE_CREATION_STEP.CURRENT_OCCUPATION,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_CURRENT_OCCUPATION,
    grantedByToggle: 'OCCUPATION',
  },
  {
    titleKey: PROFILE_CREATION_STEP.GIG_CATEGORIES,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_CATEGORY,
  },
  {
    titleKey: PROFILE_CREATION_STEP.GIG_LOCATION,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_GIG_LOCATION,
  },
  {
    titleKey: PROFILE_CREATION_STEP.HOME_LOCATION,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_HOME_LOCATION,
    grantedByToggle: 'HOME_LOCATION',
    notSupported: Platform.OS === 'web',
  },
  {
    titleKey: PROFILE_CREATION_STEP.AVAILABILITY,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_AVAILABILITY,
  },
  {
    titleKey: PROFILE_CREATION_STEP.LANGUAGES,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_LANGUAGES,
    grantedByToggle: 'PROFILE_DATA_EXTENSION',
  },
  {
    titleKey: PROFILE_CREATION_STEP.EXPERIENCE,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_EXPERIENCE,
  },
  {
    titleKey: PROFILE_CREATION_STEP.EDUCATION,
    required: 1,
    creatorPath: CANDIDATE_CREATE_PROFILE_EDUCATION,
  },
];

export const getGrantedProfileCreationSteps = (grantedFeatures) => PROFILE_CREATION_STEPS
  .filter((step) => {
    if (step.notSupported === true) {
      return false;
    }
    if (step.grantedByToggle) {
      return grantedFeatures.includes(step.grantedByToggle);
    }
    return true;
  });

export const getMaxGrantedStepsNumber = (grantedFeatures) => getGrantedProfileCreationSteps(grantedFeatures).length - 1;

export const findGrantedProfileCreationFirstStep = (grantedFeatures) => getGrantedProfileCreationSteps(grantedFeatures)[1].creatorPath;

export const getStepNumber = (titleKey, grantedFeatures) => getGrantedProfileCreationSteps(grantedFeatures).findIndex((step) => step.titleKey === titleKey);

export const getNextToggledStepRoute = (titleKey, grantedFeatures) => {
  const index = getStepNumber(titleKey, grantedFeatures);
  if (index === getMaxGrantedStepsNumber(grantedFeatures)) {
    return CANDIDATE_CREATE_PROFILE_SUCCESS_REQUIRED_STEPS;
  }
  return getGrantedProfileCreationSteps(grantedFeatures)[index + 1].creatorPath;
};
