import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { dynamicSpacings, spacings, modernColors } from '../styles/base.style';

const styles = StyleSheet.create({
  container: {
    backgroundColor: modernColors.lightGrey,
  },
  containerSpacings: {
    paddingVertical: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
  },
});

const HighlightedSection = ({ children, noSpacings }) => (
  <View style={[styles.container, !noSpacings && styles.containerSpacings]}>
    {children}
  </View>
);

HighlightedSection.propTypes = {
  children: PropTypes.node.isRequired,
  noSpacings: PropTypes.bool,
};

HighlightedSection.defaultProps = {
  noSpacings: false,
};

export default withLocalize(HighlightedSection);
