import {
  CHAT_CREDENTIALS_REQUEST,
  CHAT_SET_MESSAGES,
  CHAT_NEW_MESSAGE,
  CHAT_SUBSCRIPTIONS_RECEIVED,
  CHAT_SAVE_LAST_MESSAGE_READ,
  CHAT_UPDATED,
  USER_PRESENCE_UPDATED,
} from '../../constants/actionTypes';

export function getChatCredentials() {
  return {
    type: CHAT_CREDENTIALS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/chat/credentials',
      },
    },
  };
}

export function chatLoadMessages(chatId, messages) {
  return {
    type: CHAT_SET_MESSAGES,
    messages,
    chatId,
  };
}

export function chatNewMessage(chatId, message) {
  return {
    type: CHAT_NEW_MESSAGE,
    message,
    chatId,
  };
}

export function chatSubscriptionsReceived(subscriptions) {
  return {
    type: CHAT_SUBSCRIPTIONS_RECEIVED,
    subscriptions,
  };
}

export function chatSaveLastMessageRead(gigId, chatId, timestamp) {
  return {
    type: CHAT_SAVE_LAST_MESSAGE_READ,
    gigId,
    chatId,
    timestamp,
  };
}

export function chatUpdated(gigId, chatId, updatedDate, msg) {
  return {
    type: CHAT_UPDATED,
    gigId,
    chatId,
    updatedDate,
    msg,
  };
}

export function userPresenceUpdated(userId, online) {
  return {
    type: USER_PRESENCE_UPDATED,
    userId,
    online,
  };
}
