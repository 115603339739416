import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  container: { flex: 1 },
  row: { flexDirection: 'row' },
});

const Container = ({ children, style, row }) => (
  <View style={[styles.container, row ? styles.row : null, style]}>
    {children}
  </View>
);

Container.propTypes = {
  children: PropTypes.node,
  style: stylePropTypes,
  row: PropTypes.bool,
};
Container.defaultProps = {
  children: null,
  style: null,
  row: false,
};

export default Container;
