import React, { Component } from 'react';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, Alert } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import PrimaryModal from '../../../common/components/modal/PrimaryModal';
import ModalFooter from '../../../common/components/modal/ModalFooter';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import BaseListItem from '../../../common/components/BaseListItem';
import PaymentPlanItem from './PaymentPlanItem';
import { colors, spacings, standardColors } from '../../../common/styles/base.style';
import { getPricingPlans } from '../companyPaymentsActions';
import { getGigOffer } from '../../gigs/gigDetails/candidates/companyGigCandidatesActions';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: spacings.sm,
  },
  subheader: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacings.sm,
    marginHorizontal: spacings.xxl,
  },
  subheaderText: {
    textAlign: 'center',
  },
  scroll: {
    backgroundColor: standardColors.listBackground,
  },
  item: {
    marginBottom: spacings.sm,
    borderColor: 'transparent',
    borderWidth: 2,
  },
  selectedItem: {
    borderColor: colors.magenta,
    borderWidth: 2,
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const SUBSCRIPTION = 'subscription';
const PAY_PER_GIG_BASIC = 'payPerGig';
const PAY_PER_GIG_ENTERPRISE = 'payPerGigEnterprise';
const DIRECT_STAFFING = 'directHiring';
const DIRECT_HIRING_EXTERNAL = 'directHireExternal';

class PaymentOnHireModal extends Component {
  constructor(props) {
    super(props);
    this.state = { paymentPlans: null, offer: null };
  }

  componentDidMount() {
    this.getPricingPlans();
    this.getOffer();
  }

  getPricingPlans = () => {
    this.props.getPricingPlans().then(({ payload, isError }) => {
      if (!isError) {
        this.setState({ paymentPlans: payload.paymentPlans });
      }
    });
  };

  getOffer = () => {
    const { gigId, candidateId } = this.props;
    this.props.getGigOfferAction(gigId, candidateId).then(({ payload, isError }) => {
      if (!isError) {
        this.setState({ offer: payload });
      }
    });
  };

  onNext = (selectedPaymentPlanType) => {
    const { onRedirectToPricingPlan, redirectToBillingProfile, gigId, redirectToPaymentBreakdown, candidateId } = this.props;
    if (selectedPaymentPlanType === SUBSCRIPTION) {
      onRedirectToPricingPlan();
    }
    if ([PAY_PER_GIG_BASIC, PAY_PER_GIG_ENTERPRISE].includes(selectedPaymentPlanType)) {
      redirectToBillingProfile(gigId, selectedPaymentPlanType, this.state.offer);
    }
    if (selectedPaymentPlanType === DIRECT_STAFFING) {
      redirectToPaymentBreakdown(gigId, selectedPaymentPlanType, this.state.offer, candidateId);
    }
    if (selectedPaymentPlanType === DIRECT_HIRING_EXTERNAL) {
      // Display a "Coming Soon" alert
      Alert.alert(
        'Coming Soon',
        'This feature is coming soon. Stay tuned!',
        [
          {
            text: 'OK',
            onPress: () => {
              // You can add any additional action here if needed.
            },
          },
        ],
        { cancelable: false }
      );
    }
  };

  render() {
    const { gigId, visible, translate, closeModal, selectPaymentPlan, selectedPaymentPlanType } = this.props;

    return (
      <PrimaryModal
        visible={visible}
        handleCancel={closeModal}
        header={translate('company.payments.pricingPlans.hiringMessageBoxText')}
        headerProps={{
          shadow: true,
          containerStyle: { paddingTop: Platform.OS === 'web' ? '2%' : '16%' },
        }}
      >
        {!this.state.paymentPlans ? (
          <ActivityIndicator size="large" color={colors.magenta} style={styles.spinner} />
        ) : (
          <>
            <ScrollView contentContainerStyle={{ flex: 1 }} style={styles.scroll}>
              <PrimaryFlatList
                emptyListTextKey={translate('emptyList')}
                data={this.state.paymentPlans}
                keyExtractor={(paymentPlan) => paymentPlan.type}
                renderItem={({ item: paymentPlan }) => (
                  <BaseListItem
                    onPress={() => selectPaymentPlan(paymentPlan.type)}
                    style={[styles.item, selectedPaymentPlanType === paymentPlan.type && styles.selectedItem]}
                    hideArrow
                    renderMainContent={() => (
                      <PaymentPlanItem
                        key={paymentPlan.type}
                        paymentPlan={paymentPlan}
                        translate={translate}
                        isSelected={selectedPaymentPlanType === paymentPlan.type}
                      />
                    )}
                    key={paymentPlan.key}
                  />
                )}
              />
            </ScrollView>
            <ModalFooter>
              <Button size={BUTTON_SIZES.MD} type={BUTTON_TYPES.SECONDARY} title={this.props.translate('generic.cancel')} onPress={closeModal} />
              <Button
                size={BUTTON_SIZES.MD}
                type={BUTTON_TYPES.PRIMARY_RED}
                title={this.props.translate('generic.next')}
                disabled={!selectedPaymentPlanType || !gigId}
                onPress={() => this.onNext(selectedPaymentPlanType)}
              />
            </ModalFooter>
          </>
        )}
      </PrimaryModal>
    );
  }
}

PaymentOnHireModal.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectPaymentPlan: PropTypes.func.isRequired,
  getPricingPlans: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  selectedPaymentPlanType: PropTypes.string,
  onRedirectToPricingPlan: PropTypes.func.isRequired,
  redirectToBillingProfile: PropTypes.func.isRequired,
  redirectToPaymentBreakdown: PropTypes.func.isRequired,
  candidateId: PropTypes.string,
  getGigOfferAction: PropTypes.func.isRequired,
};

PaymentOnHireModal.defaultProps = {
  selectedPaymentPlanType: undefined,
  gigId: undefined,
  candidateId: undefined,
};

export default connect(null, { getPricingPlans, getGigOfferAction: getGigOffer })(withLocalize(PaymentOnHireModal));
