import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';

const CandidateProfileAssociationForm = ({ translate, renderFooter, handleSubmit, association, items }) => (
  <FormikWithValidation
    initialValues={{ association }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <KeyboardAwareFlexWrapper>
          <FormikFieldPrimaryPicker enabled={items.length > 1} name="association" items={items} label={translate('adminPanel.chooseAssociation')} nullable />
        </KeyboardAwareFlexWrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfileAssociationForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  association: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default withLocalize(CandidateProfileAssociationForm);
