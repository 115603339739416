import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEnabledFeatures } from './featureToggleActions';
import { APP_STATE_ACTIVE } from '../watchers/app_state/AppStateWatcher';

class AnonymousUserGrantedFeaturesUpdater extends Component {
  static GRANTED_FEATURES_CALL_INTERVAL = 30000;

  constructor(props) {
    super(props);
    this.lastUserStatusUpdateTimestamp = 0;
    this.fetchGrantedFeaturedIfNeeded();
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.authenticated !== this.props.authenticated || prevProps.appState !== this.props.appState;
  }

  componentDidUpdate() {
    if (!this.props.authenticated) {
      this.lastUserStatusUpdateTimestamp = 0;
    }
    this.fetchGrantedFeaturedIfNeeded();
  }

  fetchGrantedFeaturedIfNeeded() {
    if (this.shouldFetchGrantedFeatures()) {
      this.lastUserStatusUpdateTimestamp = Date.now();
      this.props.getEnabledFeatures();
      setTimeout(() => this.fetchGrantedFeaturedIfNeeded(), AnonymousUserGrantedFeaturesUpdater.GRANTED_FEATURES_CALL_INTERVAL);
    }
  }

  shouldFetchGrantedFeatures() {
    return !this.isAuthenticated() && this.isAppInForeground() && this.scheduledIntervalPassed();
  }

  isAuthenticated() {
    return this.props.authenticated;
  }

  isAppInForeground() {
    return this.props.appState === APP_STATE_ACTIVE;
  }

  scheduledIntervalPassed() {
    return Date.now() - this.lastUserStatusUpdateTimestamp > AnonymousUserGrantedFeaturesUpdater.GRANTED_FEATURES_CALL_INTERVAL;
  }

  render() {
    return null;
  }
}

AnonymousUserGrantedFeaturesUpdater.propTypes = {
  getEnabledFeatures: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  appState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  appState: state.appStateData.get('appState'),
});

export default connect(mapStateToProps, {
  getEnabledFeatures,
})(AnonymousUserGrantedFeaturesUpdater);
