import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import {
  View, Platform, StyleSheet, Text,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import { spacings, windowWidth } from '../../common/styles/base.style';
import FormikFieldPrimaryPicker from '../../common/components/form/FormikFieldPrimaryPicker';
import FormikFieldNumberInput from '../../common/components/form/FormikFieldNumberInput';
import { Button } from '../../common/components';
import { getAvailableGigCategories } from '../../common/gig/gigCategoriesActions';
import { getAvailableGigCities } from '../../common/gig/city/gigCitiesActions';
import { adminFindCandidates, adminFindCandidatesChangeForm } from './AdminCandidateActions';
import {
  activeLanguagePropTypes, gigCategoryPropType, gigCityPropType,
} from '../../constants/propTypes';
import FormikFieldDateTimePicker from '../../common/components/form/FormikFieldDateTimePicker';
import FormikFieldCheckBox from '../../common/components/form/checkbox/FormikFieldCheckBox';
import FormikFieldPrimaryTextInput from '../../common/components/form/FormikFieldPrimaryTextInput';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pagination: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // not to interfere with the datepicker
    zIndex: -100,
  },
  paginationWrapper: {
    flexDirection: 'row',
  },
  column: {
    minWidth: 290,
    marginRight: 30,
  },
  page: {
    width: 50,
  },
  resultsWrapper: {
    justifyContent: 'center',
    marginBottom: spacings.sm,
    marginLeft: spacings.lg,
  },
});

class AdminFindCandidatesFilters extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.windowWidth = windowWidth;
  }

  componentDidMount() {
    this.props.getAvailableGigCategories();
    this.props.getAvailableGigCities();
  }

  doSearch = async (values, { navigate }) => {
    const modifiedValues = { ...values, limit: values.limit.toString(), page: values.page.toString() };
    if (!navigate) {
      modifiedValues.page = '1';
    }
    this.props.adminFindCandidatesChangeForm(modifiedValues);
    this.formik.current.setFieldValue('page', modifiedValues.page);

    const {
      city, category, lastActiveAfterDate, ...rest
    } = modifiedValues;

    this.props.adminFindCandidates({
      cityKey: city,
      categories: [category],
      lastActiveAfterDate: lastActiveAfterDate ? moment(lastActiveAfterDate).startOf('day').toISOString() : null,
      ...rest,
    });
  };

  getTranslatedList = (nullable, list, activeLanguage) => {
    const categories = list.map((c) => ({
      value: c.key,
      label: c.translations.find((t) => t.code === activeLanguage.code).value,
    }));
    if (Platform.OS !== 'ios' && !list.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  changePage = (setFieldValue, values, difference) => {
    const newPage = (Number(values.page) + difference).toString();
    setFieldValue('page', newPage);
    return { ...values, page: newPage };
  };

  render() {
    const {
      translate, availableCities, availableCategories, activeLanguage, adminCandidatesPageInfo, formValues,
    } = this.props;

    const t = (key) => translate(`adminFindGigsScreen.${key}`);

    return (
      <>
        <View style={{ paddingBottom: spacings.xs }}>
          <Formik validate={false} onSubmit={this.doSearch} initialValues={formValues} innerRef={this.formik}>
            {({
              handleSubmit, setFieldValue, values,
            }) => (
              <>
                <View style={styles.wrapper}>
                  <View style={styles.column}>
                    <FormikFieldPrimaryPicker
                      name="city"
                      items={this.getTranslatedList(true, availableCities, activeLanguage)}
                      label={t('cityLabel')}
                      nullable
                    />
                    <FormikFieldPrimaryPicker
                      name="category"
                      items={this.getTranslatedList(true, availableCategories, activeLanguage)}
                      label={t('categoryLabel')}
                      nullable
                    />
                    <FormikFieldPrimaryPicker
                      name="limit"
                      items={[{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 100, label: '100' }, { value: 300, label: '300' }]}
                      label={t('resultsLimitLabel')}
                    />
                  </View>
                  <View style={styles.column}>
                    <FormikFieldDateTimePicker
                      name="lastActiveAfterDate"
                      mode="date"
                      minimumDateFunc={() => (new Date(2018, 1, 1))}
                      maximumDateFunc={() => (moment.utc())}
                      label={t('lastActiveAfterLabel')}
                    />
                    <FormikFieldCheckBox
                      name="availability"
                      items={[{ value: null, label: '' }, { value: true, label: 'only available' }]}
                      label={t('availabilityLabel')}
                      nullable
                    />
                    <FormikFieldPrimaryTextInput
                      name="email"
                      label={translate('generic.email')}
                      placeholder="lars@gmail.com"
                    />
                  </View>
                </View>
                <Button
                  size="md"
                  // button should be hidden under the calendar
                  style={{ wrapper: { zIndex: -100 } }}
                  title={translate('generic.search')}
                  onPress={handleSubmit}
                />
                {(adminCandidatesPageInfo && adminCandidatesPageInfo.totalPages > 0
                  && (
                  <View style={styles.pagination}>
                    <View style={styles.paginationWrapper}>
                      <Button
                        type="text"
                        size="md"
                        title={t('previous')}
                        disabled={Number(values.page) === 1}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, -1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginRight: spacings.md } }}
                      />
                      <FormikFieldNumberInput
                        name="page"
                        label={t('page')}
                        rightLabel={`/${adminCandidatesPageInfo.totalPages}`}
                        onChange={() => this.doSearch(values, { navigate: true })}
                        style={styles.page}
                      />
                      <Button
                        type="text"
                        size="md"
                        title={translate('generic.next')}
                        disabled={Number(values.page) === adminCandidatesPageInfo.totalPages}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, 1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginLeft: spacings.md } }}
                      />
                      <View style={styles.resultsWrapper}>
                        <Text>{`${adminCandidatesPageInfo.totalDocs} results`}</Text>
                      </View>
                    </View>
                  </View>

                  ))}

              </>
            )}
          </Formik>

        </View>
      </>
    );
  }
}

AdminFindCandidatesFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  adminCandidatesPageInfo: PropTypes.any.isRequired,

  adminFindCandidatesChangeForm: PropTypes.func.isRequired,
  adminFindCandidates: PropTypes.func.isRequired,
};

AdminFindCandidatesFilters.defaultProps = {
};

const mapStateToProps = (state) => {
  const formValues = state.admin.get('candidates').get('formValues').toJS();

  return {
    formValues,
    availableCategories: state.gigCategories.toJS(),
    availableCities: state.gigCities.toJS(),
    adminCandidatesPageInfo: state.admin.get('candidates').get('adminCandidatesPageInfo').toJS(),
  };
};

export default connect(mapStateToProps, {
  getAvailableGigCategories,
  getAvailableGigCities,
  adminFindCandidatesChangeForm,
  adminFindCandidates,
})(withLocalize(AdminFindCandidatesFilters));
