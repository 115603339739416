import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import DisplayField from '../../../../common/components/DisplayField';
import GigViewOfferModal from '../../../../common/gig/offer/GigViewOfferModal';
import { getGigOffer } from '../candidateMyGigsActions';
import { LinkButton } from '../../../../common/components/index';
import { BUTTON_SIZES } from '../../../../common/components/buttons/Button';
import { CANDIDATE_GIG_CHAT } from '../../../../constants/routes';

class CandidateViewOfferModal extends Component {
  chatButton = () => (
    <LinkButton
      size={BUTTON_SIZES.SM}
      to={CANDIDATE_GIG_CHAT.replace(':id', this.props.gigId)}
      title={this.props.translate('generic.chat')}
    />
  );

  renderFirstSection = (offer) => <DisplayField label={this.props.translate('candidate.gigOffer.companyLabel')} value={offer.company.name} />;

  render() {
    return (
      <GigViewOfferModal
        {...this.props}
        renderFirstSection={this.renderFirstSection}
        translationKey="candidate.gigOffer"
        chatButton={this.chatButton}
      />
    );
  }
}

CandidateViewOfferModal.propTypes = {
  translate: PropTypes.func.isRequired,
  getGigOfferAction: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

CandidateViewOfferModal.defaultProps = {
  gigId: undefined,
};

export default connect(null, { getGigOfferAction: getGigOffer })(withLocalize(CandidateViewOfferModal));
