import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import Routing from '../../../core/routing/index';
import { shiftCreatorClear } from './shiftCreatorActions';
import { COMPANY_SHIFT_CREATE_MAIN } from '../../../constants/routes';

const { withRouter } = Routing;

class CompanyShiftsMainStepEditableContainer extends React.Component {
  componentDidMount() {
    this.props.shiftCreatorClear();
    this.props.history.replace(COMPANY_SHIFT_CREATE_MAIN);
  }

  render() {
    return null;
  }
}

CompanyShiftsMainStepEditableContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  shiftCreatorClear: PropTypes.func.isRequired,
};

export default connect(null, { shiftCreatorClear })(withRouter(CompanyShiftsMainStepEditableContainer));
