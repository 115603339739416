import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as SplashScreen from 'expo-splash-screen';
import { Platform } from 'react-native';

class FeatureToggleSplashScreenGate extends Component {
  async componentDidUpdate(prevProps) {
    if (!prevProps.grantedFeatures && this.props.grantedFeatures && Platform.OS !== 'web') {
      await SplashScreen.hideAsync();
    }
  }

  render() {
    const { grantedFeatures, children } = this.props;
    if (!grantedFeatures) {
      return null;
    }
    return (
      children
    );
  }
}

FeatureToggleSplashScreenGate.propTypes = {
  children: PropTypes.node.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string),

};
FeatureToggleSplashScreenGate.defaultProps = {
  grantedFeatures: undefined,

};

const mapStateToProps = (state) => {
  const grantedFeaturesList = state.grantedFeatures.get('grantedFeatures');
  return ({
    grantedFeatures: grantedFeaturesList ? grantedFeaturesList.toJS() : undefined,
  });
};

export default connect(mapStateToProps, { })(FeatureToggleSplashScreenGate);
