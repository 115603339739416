export function getTranslationForCity(city, activeLanguage = { code: 'sv' }) {
  return (!activeLanguage || !city) ? '' : city.translations.filter((c) => c.code === activeLanguage.code)[0].value;
}

export function findCityByKey(cities, key) {
  return cities.find((c) => c.key === key);
}

export function getTranslationForCityKey(cities, cityKey, activeLanguage = { code: 'sv' }) {
  return getTranslationForCity(findCityByKey(cities, cityKey), activeLanguage);
}

export function sortCitiesByOrder(cities, availableCities) {
  if (!availableCities || !availableCities.length) {
    return [];
  }

  return cities.slice().sort((a, b) => {
    const aWithOrder = findCityByKey(availableCities, a);
    const bWithOrder = findCityByKey(availableCities, b);
    if (!aWithOrder && !bWithOrder) {
      return a > b;
    }
    if (!aWithOrder) {
      return 1;
    }
    if (!bWithOrder) {
      return -1;
    }
    return aWithOrder.order - bWithOrder.order;
  });
}
