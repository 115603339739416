import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';
import LottieView from 'lottie-react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { setNewPassword } from '../authActions';
import Container from '../../components/Container';
import ScreenHeader from '../../components/header/ScreenHeader';
import ResetPasswordScreen from '../../screens/ResetPasswordScreen';
import { font } from '../../styles/mixins';
import { fontFamily, fontSizes, colors } from '../../styles/base.style';

const checkAnimation = require('../../../assets/animations/check.json');

const styles = StyleSheet.create({
  textContainer: {
    alignItems: 'center',
    marginTop: 20,
    flex: 1,
  },
  textError: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0, colors.magenta),
  },
  text: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.lg),
  },
});

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showDefaultError: false, passwordChanged: false };
  }

  onSubmit = (values, { setErrors, setSubmitting }) => {
    this.props.setNewPassword(values, this.props.match.params.token)
      .then((response) => {
        if (response.isError && response.status === 404) {
          this.setState({ showDefaultError: true });
        } else if (response.isError) {
          setErrors(response.errors);
          setSubmitting(false);
        } else {
          this.setState({ passwordChanged: true });
        }
      });
  };
  render() {
    const { translate } = this.props;
    return (
      <Container>
        <ScreenHeader title={translate('loginScreen.resetPassword')} showBackArrow />
        <View style={styles.textContainer}>
          {this.state.passwordChanged
            ? (
              <>
                <LottieView
                  source={checkAnimation}
                  autoPlay
                  loop={false}
                  style={{ height: 60, width: 60 }}
                />
                <Text style={styles.text}>{translate('loginScreen.passwordChanged')}</Text>
              </>
            )
            : (
              <>
                {this.state.showDefaultError && <Text style={styles.textError}>{translate('loginScreen.tokenNotFoundError')}</Text>}
                <ResetPasswordScreen
                  onSubmit={this.onSubmit}
                />
              </>
            )}
        </View>
      </Container>
    );
  }
}

ResetPasswordContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default withLocalize(connect(null, { setNewPassword })(ResetPasswordContainer));
