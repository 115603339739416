import React, { PureComponent } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacings } from '../../../common/styles/base.style';
import CandidateProfileHistoryListItem, { profileHistoryListItemPropType } from './CandidateProfileHistoryListItem';
import { SwipeListView, SwipeRow } from '../../../common/components/SwipeListView';
import historyItemComparator from './candidateProfileHistoryListItemComparator';
import { gigCategoryPropType } from '../../../constants/propTypes';
import { getTranslationForCategoryKey } from '../../../common/gig/gigCategoriesHelper';
import Hr from '../../../common/components/Hr';
import { getAvailableGigCategories } from '../../../common/gig/gigCategoriesActions';

const rowBackWidth = 100;
const styles = StyleSheet.create({
  list: {
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  rowBack: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  rowBackContent: {
    width: rowBackWidth,
    height: '100%',
    backgroundColor: colors.magenta,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowBackText: {
    color: colors.white,
  },
  listRowLast: {
    paddingBottom: spacings.sm,
  },
  scrollRowDivider: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginBottom: 0,
  },
  scrollRowDividerViewCv: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginBottom: 0,
  },
});

class CandidateProfileHistoryList extends PureComponent {
  static translateCategories(sortedItems, availableCategories, activeLanguage) {
    return sortedItems.map((i) => ({
      ...i,
      category: i.category ? getTranslationForCategoryKey(availableCategories, i.category, activeLanguage) : undefined,
    }));
  }

  componentDidMount() {
    this.props.getAvailableGigCategories();
  }

  render() {
    const {
      items, handleOpen, handleRemove, keyExtractor, availableCategories, activeLanguage, noHorizontalPadding,
    } = this.props;
    let sortedItems = items.slice().sort(historyItemComparator);
    sortedItems = CandidateProfileHistoryList.translateCategories(sortedItems, availableCategories, activeLanguage);

    return (
      <View style={styles.list}>
        {sortedItems?.map((item, index) => {
          const isLast = index === sortedItems.length - 1;
          return (
            <SwipeRow
              key={index}
              item={item}
              style={isLast && styles.listRowLast}
              preview={handleRemove ? index === 0 : false}
              rightOpenValue={-rowBackWidth}
              disableRightSwipe
              disableLeftSwipe={!handleRemove}
              shouldItemUpdate={(currentItem, newItem) => !shallowequal(currentItem, newItem)}
            >
              {/* hidden layer */}
              <View style={styles.rowBack}>
                {handleRemove && (
                  <TouchableOpacity
                    style={[styles.rowBackContent]}
                    onPress={() => {
                      if (handleRemove) {
                        handleRemove(keyExtractor(item));
                      }
                    }}
                  >
                    <Text style={styles.rowBackText}>
                      <Translate id="generic.remove" />
                    </Text>
                  </TouchableOpacity>
                )}
                {!isLast && handleRemove ? <Hr style={styles.scrollRowDivider} /> : null}
              </View>
              {/* visible layer */}
              <>
                <CandidateProfileHistoryListItem
                  {...item}
                  key={keyExtractor(item)}
                  handleOpen={
                    handleOpen
                      ? () => {
                        handleOpen(keyExtractor(item));
                      }
                      : undefined
                  }
                  handleRemove={
                    handleRemove
                      ? () => {
                        handleRemove(keyExtractor(item));
                      }
                      : undefined
                  }
                  noHorizontalPadding={noHorizontalPadding}
                />
                {isLast ? null : <Hr style={!handleRemove ? styles.scrollRowDividerViewCv : styles.scrollRowDivider} />}
              </>
            </SwipeRow>
          );
        })}
      </View>
    );
  }
}

CandidateProfileHistoryList.propTypes = {
  getAvailableGigCategories: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(profileHistoryListItemPropType)).isRequired,
  handleOpen: PropTypes.func,
  handleRemove: PropTypes.func,
  keyExtractor: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  noHorizontalPadding: PropTypes.bool,
};

CandidateProfileHistoryList.defaultProps = {
  handleOpen: undefined,
  handleRemove: undefined,
  noHorizontalPadding: false,
};

const mapStateToProps = (state) => ({
  availableCategories: state.gigCategories.toJS(),
});

export default connect(mapStateToProps, { getAvailableGigCategories })(withLocalize(CandidateProfileHistoryList));
