import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../common/components/form/FormikWithValidation';
import FormikFieldPrimaryTextInput from '../../../common/components/form/FormikFieldPrimaryTextInput';
import { candidateSignupValidation, USER_FIRST_NAME_MAX_LENGTH, USER_LAST_NAME_MAX_LENGTH } from '../../../common/auth/UserSignupValidation';
import FormikFieldPrimaryInputTextNextSupport from '../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import KeyboardAwareFlexWrapper from '../../../common/components/KeyboardAwareFlexWrapper';

class CandidateProfileFullNameForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      translate, renderFooter, handleSubmit, firstName, lastName,
    } = this.props;

    return (
      <FormikWithValidation
        validation={candidateSignupValidation}
        initialValues={{ firstName, lastName }}
        onSubmit={handleSubmit}
        render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
          <>
            <KeyboardAwareFlexWrapper>
              <FormikFieldPrimaryInputTextNextSupport
                name="firstName"
                label={translate('candidateWelcomeScreen.signupScreen.firstNameLabel')}
                maxLength={USER_FIRST_NAME_MAX_LENGTH}
                nextComponent={this.state.lastNameInputRef}
              />

              <FormikFieldPrimaryTextInput
                innerRef={(ref) => !this.state.lastNameInputRef && this.setState({ lastNameInputRef: ref })}
                name="lastName"
                label={translate('candidateWelcomeScreen.signupScreen.lastNameLabel')}
                maxLength={USER_LAST_NAME_MAX_LENGTH}
                returnKeyType="done"
              />
            </KeyboardAwareFlexWrapper>
            {renderFooter({ handleSubmitFormik, isBtnDisabled })}
          </>
        )}
      />
    );
  }
}

CandidateProfileFullNameForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default withLocalize(CandidateProfileFullNameForm);
