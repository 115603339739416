import React, { Component } from 'react';
import {
  StyleSheet, Text, View, ScrollView, Image, Platform, Linking, TouchableOpacity,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

import {
  colors, fontSizes, fontFamily, spacings, dynamicSpacings, wp,
} from '../styles/base.style';
import { shiftPropType } from '../../constants/propTypes';
import { font } from '../styles/mixins';
import UserScope from '../auth/UserScope';
import { CANDIDATE_USER, COMPANY_USER } from '../../constants/userScopes';
import { LinkButton } from '../components';
import { CANDIDATE_MY_POOLS_SHIFTS_BOOKED } from '../../constants/routes';
import { BOOKED } from '../../constants/shiftCandidateStatus';
import Button, { BUTTON_TYPES } from '../components/buttons/Button';
import Touchable from '../components/Touchable';
import UserProfileImage from '../components/image/UserProfileImage';
import { toModernDateWithWeekDayName, toTime } from '../../utilities/dateFormatter';
import ScreenHeader, { RIGHT_BUTTONS } from '../components/header/ScreenHeader';
import ViewportController from '../components/ViewportController';
import LocationMap from './LocationMap';
import { calculateShiftTotalHours, calculateShiftTotalIncome, SALARY_COMPONENTS } from './shiftSalaryUtils';
import { getResizedImageFromDimensions } from '../components/image/imageHelper';
import { profileImagePercentageAspect } from '../shift/CandidatesPoolDetailsScreen';

const candidateIcon = require('../../assets/icons/person/person.png');
const calendarIcon = require('../../assets/icons/calendar/calendar.png');
const locationIcon = require('../../assets/icons/location/location.png');
const clockIcon = require('../../assets/icons/clock/clock.png');
const moneyIcon = require('../../assets/icons/money/money.png');

const ICON_WIDTH = Platform.OS === 'web' ? 45 : 30;

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: spacings.sm,
  },
  shiftInfoWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginVertical: spacings.lg,
  },
  position: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxxl, 0.5),
    textAlign: 'center',
    lineHeight: undefined,
    marginBottom: undefined,
    paddingHorizontal: spacings.xxl,
  },
  company: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0.3),
    textAlign: 'center',
  },
  profileImageContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  profileImage: {
    width: 108,
    height: 108,
    borderRadius: 54,
  },
  description: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xl, 0.3),
    marginVertical: spacings.md,
  },
  conditionsWrapper: {
    paddingVertical: spacings.xs,
  },
  conditionItemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopColor: colors.veryLightPinkTwo,
    borderTopWidth: 1,
    paddingVertical: spacings.sm,
  },
  hiddenConditionItemWrapper: {
    backgroundColor: colors.ultraLightGrey,
    paddingVertical: spacings.xs,
    paddingLeft: spacings.xxs,
  },
  conditionItemText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0.3, colors.greyishBrown),
    paddingLeft: spacings.xs,
  },
  conditionItemSubText: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, 0.3, colors.brownishGrey),
    paddingHorizontal: spacings.sm,
  },
  image: {
    width: ICON_WIDTH,
    resizeMode: 'contain',
  },
  bookSectionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: colors.smokeWhite,
    paddingVertical: spacings.sm,
    paddingHorizontal: dynamicSpacings.md,
    borderRadius: 15,
  },
  map: {
    height: 200,
    marginBottom: spacings.lg,
  },
  centeredRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  thinText: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm, -0.2, colors.greyishBrown),
  },
  text: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, -0.2, colors.greyishBrown),
  },
  footerFloatingButton: {
    position: 'absolute',
    bottom: 0,
    marginBottom: spacings.md,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  totalIncome: {
    flexDirection: 'column',
    marginBottom: spacings.md,
    borderTopWidth: 2,
    paddingTop: spacings.sm,
  },
  totalIncomeText: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md, 0.2, colors.greyishBrown),
  },
});

const btnStyle = StyleSheet.create({
  wrapper: {
    marginBottom: spacings.md,
  },
});

const latitudeDelta = 0.01;
const longitudeDelta = 0.01;

class ShiftDetails extends Component {
  openMaps = (place, street) => {
    Linking.openURL(`http://maps.google.com/?q=${place} ${street}`);
  };

  renderSubheader = () => {
    const { shift, translate } = this.props;
    if (shift.company && shift.company.name) {
      return (
        <Text style={styles.company}>{`${translate('shiftPreview.at')} ${shift.company.name}`}</Text>
      );
    }
    return null;
  };

  renderDatesAndTimes = (shiftDatesAndTimes) => (
    <View style={styles.conditionItemWrapper}>
      <Image style={styles.image} source={calendarIcon} />
      <View style={[styles.conditionItemText, { flexDirection: 'column' }]}>
        {shiftDatesAndTimes.map((item) => (
          <View style={styles.centeredRow} key={item.id}>
            <View style={{ width: wp(27) }}>
              <Text style={styles.thinText}>{toModernDateWithWeekDayName(item.startDate)}</Text>
            </View>
            <Text style={styles.text}>{`${toTime(item.startDate)} - ${toTime(item.endDate)}`}</Text>
          </View>
        ))}
      </View>
    </View>
  );

  renderShiftTotalHours = (shiftDatesAndTimes) => (
    <View style={styles.conditionItemWrapper}>
      <Image style={styles.image} source={clockIcon} />
      <Text style={styles.conditionItemText}>
        {`${calculateShiftTotalHours(shiftDatesAndTimes)} ${this.props.translate('shiftPreview.hours')}`}
      </Text>
    </View>
  );

  renderCandidatesAndBookings = (candidatesNumber, bookedCandidatesNumber) => {
    const { translate } = this.props;
    const freeVacancies = candidatesNumber - bookedCandidatesNumber;
    return (
      <View style={styles.conditionItemWrapper}>
        <Image style={styles.image} source={candidateIcon} />
        <UserScope allowedFor={[CANDIDATE_USER]}>
          <Text style={styles.conditionItemText}>
            {`${freeVacancies} ${translate(freeVacancies === 1 ? 'shiftPreview.candidate' : 'shiftPreview.candidates')}`}
          </Text>
        </UserScope>
        <UserScope allowedFor={[COMPANY_USER]}>
          <View style={[styles.conditionItemText, { flexDirection: 'column' }]}>
            <Text style={styles.text}>
              {`${candidatesNumber} ${translate(candidatesNumber === 1 ? 'shiftPreview.candidate' : 'shiftPreview.candidates')}`}
            </Text>
            <Text style={styles.text}>
              {`${bookedCandidatesNumber} ${translate(bookedCandidatesNumber === 1 ? 'shiftPreview.booking' : 'shiftPreview.bookings')}`}
            </Text>
          </View>
        </UserScope>
      </View>
    );
  };

  renderSalaryInfo = (shiftSalary) => {
    const { translate } = this.props;
    let regularSalary = `${shiftSalary.salary} ${translate('shiftPreview.salaryUnit')}`;
    if (shiftSalary.holidayPay) {
      regularSalary = regularSalary.concat(` + ${SALARY_COMPONENTS.holidayPay}% ${translate('shiftPreview.holidayPay')}`);
    }
    return (
      <View style={styles.conditionItemWrapper}>
        <Image source={moneyIcon} style={styles.image} />
        <View style={{ flexDirection: 'column' }}>
          <Text style={styles.conditionItemText}>{regularSalary}</Text>
          {shiftSalary.obPayment.applicable && (
            <View style={{ marginLeft: spacings.xs, marginTop: spacings.xs }}>
              {shiftSalary.obPayment.periods.map((item) => (
                <View style={styles.centeredRow} key={item.id}>
                  <View style={{ width: wp(27) }}>
                    <Text style={styles.thinText}>{`${toTime(item.startTime)} - ${toTime(item.endTime)}`}</Text>
                  </View>
                  <Text style={styles.text}>{translate('shiftPreview.obPaymentItem', { amount: item.salaryBonus })}</Text>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    );
  };

  renderAccessInfoForCompany = (accessEntities) => (
    <UserScope allowedFor={[COMPANY_USER]}>
      <View style={styles.hiddenConditionItemWrapper}>
        <Text style={styles.conditionItemText}>{this.props.translate('shiftPreview.availableFor')}</Text>
        {accessEntities && accessEntities.teams.map((team) => (
          <Text style={styles.conditionItemSubText} key={team.name}>{team.name}</Text>
        ))}
      </View>
    </UserScope>
  );

  renderLocationInfo = (location) => {
    const region = {
      longitude: location.geoLoc.lon,
      latitude: location.geoLoc.lat,
      latitudeDelta,
      longitudeDelta,
    };
    return (
      <>
        <View style={styles.conditionItemWrapper}>
          <Image style={styles.image} source={locationIcon} />
          <Touchable onPress={() => this.openMaps(location.place, location.street)}>
            <Text style={[styles.conditionItemText, { color: colors.seablue }]}>{`${location.street}, ${location.place}`}</Text>
          </Touchable>
        </View>
        {Platform.OS !== 'web' && (
          <View style={styles.map}>
            <LocationMap ref={(component) => { this.map = component; }} style={styles.map} initialRegion={region} />
          </View>
        )}
      </>
    );
  };

  renderTotalIncomeInfo = (shift) => {
    const { translate } = this.props;
    const totalIncomeText = translate('shiftPreview.totalIncome', {
      amount: calculateShiftTotalIncome(shift.shiftDatesAndTimes, shift.shiftSalary),
      extra: shift.shiftSalary.obPayment.applicable ? translate('shiftPreview.OBPayment') : '',
    });
    return (
      <View style={styles.totalIncome}>
        <Text style={styles.totalIncomeText}>{totalIncomeText}</Text>
        <Text style={[styles.thinText, { textAlign: 'center' }]}>{this.props.translate('shiftPreview.netto')}</Text>
      </View>
    );
  };

  renderPoolImage = () => {
    const { shift, onOpenPoolDetails } = this.props;
    return (
      <TouchableOpacity onPress={() => onOpenPoolDetails && onOpenPoolDetails(shift.company.companyId)} activeOpacity={onOpenPoolDetails ? 0.7 : 1}>
        {!!shift.company.poolProfileImageUri && (
          <Image
            source={{ uri: getResizedImageFromDimensions(shift.company.poolProfileImageUri, wp(100), wp(profileImagePercentageAspect)) }}
            style={{ width: wp(100), height: wp(profileImagePercentageAspect) }}
            fadeDuration={0}
          />
        )}
        {!shift.company.poolProfileImageUri && !!shift.companyUser.profileImageUri && (
          <View style={styles.profileImageContainer}>
            <UserProfileImage profileImageUri={shift.companyUser.profileImageUri} style={styles.profileImage} />
          </View>
        )}
      </TouchableOpacity>
    );
  };

  render() {
    const {
      shift, translate, bookShiftWithCallback, actionSheet,
    } = this.props;

    return (
      <ViewportController safeAreaBottom>
        <ScreenHeader
          title={shift.position}
          showBackArrow
          headerStyle={styles.position}
          containerStyle={styles.headerContainer}
          subheaderFunc={this.renderSubheader}
          rightButton={actionSheet && RIGHT_BUTTONS.MENU}
          handlerForRightButton={actionSheet}
        />
        <ScrollView>
          {this.renderPoolImage()}
          <View style={{ marginHorizontal: spacings.md }}>
            <Text style={styles.description}>{shift.description}</Text>
            <View style={styles.conditionsWrapper}>
              {this.renderDatesAndTimes(shift.shiftDatesAndTimes)}
              {this.renderShiftTotalHours(shift.shiftDatesAndTimes)}
              {this.renderSalaryInfo(shift.shiftSalary)}
              {this.renderCandidatesAndBookings(shift.candidatesNumber, shift.bookedCandidatesNumber)}
              {this.renderAccessInfoForCompany(shift.accessEntities)}
              {this.renderLocationInfo(shift.location)}
            </View>
            {this.renderTotalIncomeInfo(shift)}
          </View>
          <UserScope allowedFor={[CANDIDATE_USER]}>
            {shift.shiftCandidateStatus !== BOOKED && (
              <LinkButton
                style={btnStyle}
                to={CANDIDATE_MY_POOLS_SHIFTS_BOOKED}
                title={translate('shiftPreview.book')}
                onPress={(callback) => bookShiftWithCallback(shift.gigId, callback)}
                type={BUTTON_TYPES.PRIMARY_RED}
              />
            )}
            {shift.shiftCandidateStatus === BOOKED && (
              <Button
                style={btnStyle}
                title={translate('shiftPreview.booked')}
                type={BUTTON_TYPES.PRIMARY_RED}
                onPress={() => {}}
                disabled
              />
            )}
          </UserScope>
        </ScrollView>
      </ViewportController>
    );
  }
}

ShiftDetails.propTypes = {
  translate: PropTypes.func.isRequired,
  actionSheet: PropTypes.func,
  bookShiftWithCallback: PropTypes.func,
  onOpenPoolDetails: PropTypes.func,
  shift: shiftPropType.isRequired,
};

ShiftDetails.defaultProps = {
  bookShiftWithCallback: undefined,
  onOpenPoolDetails: undefined,
  actionSheet: undefined,
};

export default withLocalize(ShiftDetails);
