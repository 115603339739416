import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import CompanyShiftsSalaryStepEditableScreen from './CompanyShiftsSalaryStepEditableScreen';
import { COMPANY_SHIFT_CREATE_ACCESS_ENTITIES } from '../../../../constants/routes';
import { shiftSalaryPropType } from '../../../../constants/propTypes';
import { shiftCreatorSetShiftSalary } from '../shiftCreatorActions';
import Routing from '../../../../core/routing/index';
import { buildRange, getMinutesSinceDayStart } from '../../../../utilities/dateUtils';

const moment = extendMoment(Moment);
const { withRouter } = Routing;

class CompanyShiftsSalaryStepEditableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftSalary: props.shiftSalary,
      obPaymentPeriods: props.shiftSalary.obPayment.periods,
      error: null,
    };
  }

  checkNewRangeOverlapsAnyOther = (start, end) => {
    if (this.state.obPaymentPeriods && this.state.obPaymentPeriods.length === 0) {
      return false;
    }
    const currentSegments = this.state.obPaymentPeriods.map((item) => ({
      start: moment(item.startTime).hours() * 60 + moment(item.startTime).minutes(),
      end: moment(item.endTime).hours() * 60 + moment(item.endTime).minutes(),
    }));
    const newRange = buildRange(start, end);
    const newRangeShifted = start > end && end > 0 ? buildRange(0, end) : null;
    for (let i = 0; i < currentSegments.length; i += 1) {
      const existingRange = buildRange(currentSegments[i].start, currentSegments[i].end);
      const existingRangeShifted = currentSegments[i].start > currentSegments[i].end && currentSegments[i].end > 0
        ? buildRange(0, currentSegments[i].end)
        : null;
      if (newRange.overlaps(existingRange)
        || (existingRangeShifted && newRange.overlaps(existingRangeShifted))
        || (newRangeShifted && (newRangeShifted.overlaps(existingRange)))) {
        return true;
      }
    }
    return false;
  };

  onAddOBPayment = (obPayment, { resetForm, setStatus }) => {
    const minsIn8H = 8 * 60;
    const minsInDay = 24 * 60;
    setStatus({ forceEnabled: false });
    const start = getMinutesSinceDayStart(obPayment.startTime);
    const end = getMinutesSinceDayStart(obPayment.endTime);
    if (start === end) {
      this.setState({ error: 'emptyPeriod' });
      return;
    }
    if (this.checkNewRangeOverlapsAnyOther(start, end)) {
      this.setState({ error: 'cannotIntersect' });
      return;
    }
    const id = `${(Math.ceil(Math.random() * (10 ** 16)))}`;
    this.setState({
      obPaymentPeriods: this.state.obPaymentPeriods.concat({ ...obPayment, id })
        .sort((item1, item2) => ((getMinutesSinceDayStart(item1.startTime) + minsIn8H) % minsInDay)
          - ((getMinutesSinceDayStart(item2.startTime) + minsIn8H) % minsInDay)),
      error: null,
    });
    resetForm();
  };

  resetFormikField = (setStatus) => {
    setStatus({ forceEnabled: true });
    this.setState({ error: null });
  };

  onRemoveOBPayment = (id) => {
    this.setState({ obPaymentPeriods: this.state.obPaymentPeriods.filter((item) => item.id !== id) });
  };

  handleNext = ({ salary, holidayPay, obPayment }) => {
    this.props.shiftCreatorSetShiftSalary({
      salary: parseInt(salary, 10),
      holidayPay,
      obPayment: {
        applicable: obPayment && this.state.obPaymentPeriods.length > 0,
        periods: obPayment ? this.state.obPaymentPeriods : [],
      },
    });
    this.props.history.push(COMPANY_SHIFT_CREATE_ACCESS_ENTITIES);
  };

  render() {
    const {
      shiftSalary, obPaymentPeriods, error,
    } = this.state;

    return (
      <CompanyShiftsSalaryStepEditableScreen
        shiftSalary={shiftSalary}
        obPaymentPeriods={obPaymentPeriods}
        handleNext={this.handleNext}
        onAddOBPayment={this.onAddOBPayment}
        onRemoveOBPayment={this.onRemoveOBPayment}
        resetOBPaymentFormikField={this.resetFormikField}
        error={error}
      />
    );
  }
}

CompanyShiftsSalaryStepEditableContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  shiftSalary: shiftSalaryPropType,
  shiftCreatorSetShiftSalary: PropTypes.func.isRequired,
};

CompanyShiftsSalaryStepEditableContainer.defaultProps = {
  shiftSalary: undefined,
};

const mapStateToProps = (state) => {
  const shiftSalary = state.shiftCreator.get('shift').get('shiftSalary').toJS();
  return {
    shiftSalary: {
      ...shiftSalary,
      salary: shiftSalary.salary || shiftSalary.salary === 0 ? shiftSalary.salary.toString() : '',
    },
  };
};

export default connect(mapStateToProps, { shiftCreatorSetShiftSalary })(withRouter(CompanyShiftsSalaryStepEditableContainer));
