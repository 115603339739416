import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { Row, Rows, Table } from 'react-native-table-component';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { fontSizes, spacings, standardColors } from '../../../common/styles/base.style';
import Routing from '../../../core/routing/index';
import { toDate } from '../../../utilities/dateFormatter';
import ExternalLink from '../../../common/components/buttons/ExternalLink';
import { LinkButton } from '../../../common/components';
import { ADMIN_GIG_DETAILS } from '../../../constants/routes';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import FormikFieldPrimarySwitch from '../../../common/components/form/switch/FormikFieldPrimarySwitch';
import { showAlertMessage } from '../../../common/components/alert/alerts';
import { adminPauseGig } from '../AdminGigsActions';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },

  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});

class AdminFindGigsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHead: [
        'ID',
        'Company',
        'Position',
        'Categories',
        'City',
        'Created',
        'Close date',
        'Cand',
        'Like',
        'Like not',
        'Prov',
        'Provider Id',
        'Details',
        'Paused',
      ],
      flexArr: [2, 2, 3, 2, 2, 2, 2, 1, 1, 1, 1, 2, 2, 3],
    };
  }

  pauseGig(gig, isEnabled) {
    console.log(`gig  ${JSON.stringify(gig)}`);
    console.log(`isEnabled  ${isEnabled}`);

    this.props.adminPauseGig(gig.gigId).then((response) => {
      console.log(`pauseGig ${JSON.stringify(response)}`);
      if (!response.isError) {
        showAlertMessage(this.props.translate, 'generic.ok', 'companyGigCreator.edit.notification.success.text', 'adminSettings.success', () => {});
      } else {
        showAlertMessage(this.props.translate, 'generic.ok', 'adminSettings.errorUpdateCity', 'errors.error', () => {});
      }
    });
  }

  renderLinks = (gig) => {
    const externalLink = gig.applyUrl ? (
      <ExternalLink linkUrl={gig.applyUrl} title="Link" style={{ text: { fontSize: fontSizes.md }, wrapper: { marginBottom: 0 } }} newTab />
    ) : null;

    const detailsLink = (
      <LinkButton
        title="Details"
        to={ADMIN_GIG_DETAILS}
        replacePathValue={gig.gigId}
        type={BUTTON_TYPES.TEXT}
        size={BUTTON_SIZES.MD}
        style={{ wrapper: { marginBottom: 0, alignSelf: 'flex-start' } }}
      />
    );

    return (
      <View>
        {externalLink}
        {detailsLink}
      </View>
    );
  };

  renderPauseGigView = (gig) => {
    const pausedToggleButton = (
      <View style={{ margin: 5, justifyContent: 'center', alignContent: 'center', alignSelf: 'center' }}>
        <Formik validate={false} initialValues={{ enabled: gig.paused }} innerRef={this.formik}>
          {({ setFieldValue }) => (
            <>
              <FormikFieldPrimarySwitch
                name="enabled"
                onValueChange={(value) => {
                  console.log(`enabled${value}`);
                  setFieldValue('enabled', value);
                  this.pauseGig(gig, value);
                }}
                enabled={gig.paused}
              />
            </>
          )}
        </Formik>
      </View>
    );

    return <View>{pausedToggleButton}</View>;
  };

  render() {
    const { gigsList } = this.props;
    const itemsData = gigsList.map((g) => [
      g.gigId,
      g.companyName,
      g.position,
      g.categories.join(','),
      g.cityName,
      toDate(g.createdAt),
      toDate(g.closeDate),
      g.candidatesReactedNumber ? g.candidatesReactedNumber : 0,
      g.inCandidatesFavouritesNumber,
      g.inCandidatesNotInterestingNumber,
      g.gigProvider,
      g.providerGigId,
      this.renderLinks(g),
      this.renderPauseGigView(g),
    ]);
    const { state } = this;
    return (
      <>
        <View style={styles.container}>
          <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
            <Row data={state.tableHead} style={styles.head} textStyle={styles.text} flexArr={state.flexArr} />
            <Rows data={itemsData} textStyle={styles.text} flexArr={state.flexArr} />
          </Table>
        </View>
      </>
    );
  }
}

AdminFindGigsList.propTypes = {
  gigsList: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminPauseGig: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  gigsList: state.admin.get('gigs').get('adminGigsList').toJS(),
});

export default withRouter(
  connect(mapStateToProps, {
    adminPauseGig,
  })(withLocalize(AdminFindGigsList))
);
