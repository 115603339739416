import validator from 'validator';

export const MAX_PERSONAL_NUMBER_LENGTH = 25;

export const bankAccountNorValidation = [
  {
    field: 'bankAccountNo',
    method: validator.isLength,
    args: [{ max: MAX_PERSONAL_NUMBER_LENGTH }],
    message: 'too_long',
  },
];
