import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { ScrollView } from 'react-native';
import validation from './CandidateProfileLocationFormValidation';
import FormikFieldSelectList from '../../../../common/components/form/FormikSelectList';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import Wrapper from '../../../../common/components/Wrapper';
import { spacings } from '../../../../common/styles/base.style';
import { candidateLocationPropType } from '../../../../constants/propTypes';

class CandidateProfileLocationForm extends Component {
  render() {
    const {
      translate, renderFooter, cities, location, handleSubmit,
    } = this.props;
    this.t = (key) => translate(`candidateProfileCreator.location.${key}`);

    return (
      <FormikWithValidation
        validation={validation}
        initialValues={{ cityKey: location ? location.cityV2.key : null }}
        onSubmit={handleSubmit}
        render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
          <>
            <ScrollView style={{ paddingTop: spacings.md }}>
              <Wrapper>
                <FormikFieldSelectList
                  name="cityKey"
                  items={cities}
                />
              </Wrapper>
            </ScrollView>
            {renderFooter({ handleSubmitFormik, isBtnDisabled })}
          </>
        )}
      />
    );
  }
}

CandidateProfileLocationForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  location: candidateLocationPropType,
  cities: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

CandidateProfileLocationForm.defaultProps = {
  location: undefined,
};

export default withLocalize(CandidateProfileLocationForm);
