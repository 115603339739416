import { fromJS, List, Map } from 'immutable';
import {
  ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST_SUCCESS,
  ADMIN_CANDIDATES_FIND_FILTER_FORM_SUBMIT,
} from '../../constants/actionTypes';

const INITIAL_ADMIN_CANDIDATES_FILTERS = {
  formValues: fromJS({
    category: undefined, city: undefined, availability: undefined, limit: '100', page: '1',
  }),
  adminCandidatesList: List(),
  adminCandidatesPageInfo: Map(),
};

export default function adminFindCandidatesReducer(state = new Map(INITIAL_ADMIN_CANDIDATES_FILTERS), action) {
  switch (action.type) {
    case ADMIN_CANDIDATES_FIND_FILTER_FORM_SUBMIT:
      return state.set('formValues', fromJS(action.formValues));
    case ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST_SUCCESS:
      return state.set('adminCandidatesList', fromJS(action.payload.docs))
        .set('adminCandidatesPageInfo', fromJS({
          ...action.payload, docs: undefined,
        }));
    default:
      return state;
  }
}
