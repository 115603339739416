import getKeyByValue from '../utilities/objectUtils';

export const GIG_WORKLOAD = {
  _24_40: { min: 24, max: 40 },
  _8_24: { min: 8, max: 24 },
  _1_8: { min: 1, max: 8 },
};

export const GIG_SHIFTS = {
  DAYTIME: 1,
  EVENINGS: 2,
  NIGHTS: 3,
  WEEKDAYS: 4,
  WEEKENDS: 5,
};

export const getWorkloadLabel = (workload, translate, withPerWeekSuffix) => {
  if (workload.type) {
    return translate(`gigAvailability.${workload.type}`);
  }
  return `${workload.min} - ${workload.max} ${translate('gigAvailability.hours')} ${withPerWeekSuffix ? translate('generic.perWeek') : ''}`;
};

export const getShiftKey = (shift) => getKeyByValue(GIG_SHIFTS, shift);
