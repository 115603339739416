import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { StyleSheet, Text, View } from 'react-native';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../../common/styles/base.style';
import Touchable from '../../../../common/components/Touchable';
import RightArrow from '../../../../common/components/RightArrow';
import SelectList from '../../../../common/components/form/SelectList';
import EditModalFooter from '../../../../common/components/modal/EditModalFooter';
import PrimaryModal from '../../../../common/components/modal/PrimaryModal';
import Container from '../../../../common/components/Container';
import { CITY_OPTION_GIGS_ANYWHERE } from './candidatesFindGigsFiltersHelpers';
import { getTranslationForCity } from '../../../../common/gig/city/gigCitiesHelper';

const styles = StyleSheet.create({
  container: {
    marginBottom: spacings.md,
  },
  label: {
    color: colors.greyishBrown,
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginBottom: 3,
  },
  valueWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  value: {
    color: colors.greyishBrown,
    fontSize: fontSizes.md,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    marginRight: spacings.xs,
    textAlign: 'center',
  },
});

class FindGigsSelectCityField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedCity: undefined,
    };
  }

  getCityName = (cities) => {
    const selectedCity = this.state.selectedCity ? this.state.selectedCity : this.props.selectedCity;
    if (selectedCity === CITY_OPTION_GIGS_ANYWHERE) {
      return this.props.translate('candidateFindGigs.gigsAnywhereLabel');
    }
    const selectedGigCity = cities.find((gigCity) => gigCity.key === selectedCity);
    if (!selectedGigCity) {
      return null;
    }
    return getTranslationForCity(selectedGigCity);
  };

  getCityOptions(cities) {
    const cityOptions = [
      { label: this.props.translate('candidateFindGigs.gigsAnywhereLabel'), value: CITY_OPTION_GIGS_ANYWHERE },
    ];
    if (cities) {
      cityOptions.push(...cities.map((gigCity) => ({ label: getTranslationForCity(gigCity), value: gigCity.key })));
    }
    return cityOptions;
  }

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  openModal = () => {
    this.setState({ modalVisible: true });
  };

  render() {
    const {
      translate, cities,
    } = this.props;
    const cityOptions = this.getCityOptions(cities);
    return (
      <>
        <Touchable style={styles.container} onPress={this.openModal}>
          <Text style={styles.label}>{translate('candidateFindGigs.cityLabel')}</Text>
          <View style={styles.valueWrapper}>
            <Text style={styles.value}>{this.getCityName(cities)}</Text>
            <RightArrow />
          </View>
        </Touchable>

        <PrimaryModal
          visible={this.state.modalVisible}
          handleCancel={this.closeModal}
          header={translate('candidateFindGigs.cityLabel')}
        >
          <Container>
            <SelectList
              value={this.state.selectedCity || this.props.selectedCity}
              items={cityOptions}
              onValueChange={(value) => { this.setState({ selectedCity: value }); }}
              scroll
            />
          </Container>
          <EditModalFooter
            handleCancel={() => {
              this.setState({ selectedCity: this.props.selectedCity });
              this.closeModal();
            }}
            handleSubmitFormik={() => {
              this.props.handleSubmit(this.state.selectedCity || this.props.selectedCity);
              this.closeModal();
            }}
            isBtnDisabled={false}
            confirmLabel={translate('generic.choose')}
          />
        </PrimaryModal>
      </>

    );
  }
}

FindGigsSelectCityField.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCity: PropTypes.string.isRequired,
};
export default withLocalize(FindGigsSelectCityField);
