export const goTo = (history, screenPath, replace) => {
  const paths = history.entries ? history.entries.map((entry) => entry.pathname) : [];
  if (paths.includes(screenPath)) {
    history.go(paths.indexOf(screenPath) - history.index);
  } else if (replace) {
    history.replace(screenPath);
  } else {
    history.push(screenPath);
  }
};
