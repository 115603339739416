import {
  addFavouriteGig, addNotInterestingGig, removeFavouriteGigFromList, removeNotInterestingGigFromList,
} from '../candidateFindGigsActions';
import { colors } from '../../../../common/styles/base.style';

const favouriteIcon = require('../../../../assets/icons/singlegigview/btn_shortlist.png');
const rejectIcon = require('../../../../assets/icons/singlegigview/Reject_active.png');

const FIND_GIGS_SINGLE_ITEM_DEFINITIONS = {
  favourite: {
    actionsForAllProviders: true,
    middleText: 'favouriteGig',
    middleColor: colors.magenta,
    actions: [
      {
        label: 'candidateFindGigs.single.favouriteToNotInteresting',
        icon: rejectIcon,
        getAction: (gigId, { dispatch }) => () => {
          dispatch(addNotInterestingGig(gigId));
          dispatch(removeFavouriteGigFromList(gigId));
        },
      },
    ],
  },
  favouriteClosed: {
    actionsForAllProviders: true,
    middleText: 'closedFavouriteGig',
    middleColor: colors.brownGrey,
  },
  notInteresting: {
    actionsForAllProviders: true,
    middleText: 'notInterestingGig',
    middleColor: colors.gondola,
    actions: [
      {
        label: 'candidateFindGigs.single.notInterestingToFavourite',
        icon: favouriteIcon,
        getAction: (gigId, { dispatch }) => () => {
          dispatch(addFavouriteGig(gigId));
          dispatch(removeNotInterestingGigFromList(gigId));
        },
      },
    ],
  },
  notInterestingClosed: {
    actionsForAllProviders: true,
    middleText: 'closedNotInterestingGig',
    middleColor: colors.brownGrey,
  },
};

export default FIND_GIGS_SINGLE_ITEM_DEFINITIONS;
