import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { SUPERADMIN_USER } from '../../constants/userScopes';
import { ADMIN_COMPANY, ADMIN_FIND_COMPANY } from '../../constants/routes';
import AdminFindCompanyContainer from './find_company/AdminFindCompanyContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_FIND_COMPANY;

const AdminFindCompanyRouting = () => (
  <>
    <Route exact path={ADMIN_COMPANY} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_FIND_COMPANY} component={AdminFindCompanyContainer} />
  </>
);

export default AdminFindCompanyRouting;
