import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { SUPERADMIN_USER } from '../../constants/userScopes';
import {
  ADMIN_NOTIFICATION, ADMIN_NOTIFICATION_LIST, ADMIN_NOTIFICATION_NEW,
} from '../../constants/routes';
import AdminNotificationContainer from './AdminNotificationContainer';
import AdminNewNotificationContainer from './AdminNewNotificationContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_NOTIFICATION_NEW;

const AdminNotificationRouting = () => (
  <>
    <Route exact path={ADMIN_NOTIFICATION} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_NOTIFICATION_LIST} component={AdminNotificationContainer} />
    <AuthorizedRoute only={SUPERADMIN_USER} path={ADMIN_NOTIFICATION_NEW} component={AdminNewNotificationContainer} />
  </>
);

export default AdminNotificationRouting;
