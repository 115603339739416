import { Map } from 'immutable';

const INITIAL_MAP = {
};

export default function getUserSettingsReducer(state = Map(INITIAL_MAP), action) {
  switch (action.type) {
    default:
      return state;
  }
}
