import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import DateTimePicker from './DateTimePicker';
import { getValueByPath } from '../../../utilities/objectUtils';

const FormikDatePicker = ({
  field: { name, value },
  form: {
    setFieldValue, setFieldTouched, touched, errors,
  },
  onDateChange,
  ...props
}) => (
  <DateTimePicker
    {...props}
    onDateChange={(val) => {
      setFieldValue(name, val);
      setFieldTouched(name, true);
      if (onDateChange) {
        onDateChange(val);
      }
    }}
    date={value}
    error={(getValueByPath(touched, name) && errors[name]) || null}
  />
);

FormikDatePicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onDateChange: PropTypes.func,
};

FormikDatePicker.defaultProps = {
  onDateChange: undefined,
};

const FormikFieldDateTimePicker = (props) => (
  <Field
    component={FormikDatePicker}
    {...props}
  />
);

export default FormikFieldDateTimePicker;
