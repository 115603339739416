import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gigCreatorAddExperience } from '../../gigCreatorActions';
import GigCreatorStepExperienceScreen from './GigCreatorStepExperienceScreen';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepExperienceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.EXPERIENCE));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.EXPERIENCE);
  }

  handleSubmit = ({ experience }, { setSubmitting }) => {
    this.props.gigCreatorAddExperience(experience);

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.EXPERIENCE, { experience })
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  handleSkip = () => {
    this.props.gigCreatorAddExperience(null);
    this.setState({ redirect: true });
  };

  render = () => (
    <GigCreatorStepExperienceScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      experience={this.props.experience}
      handleSubmit={this.handleSubmit}
      handleSkip={this.handleSkip}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  );
}

GigCreatorStepExperienceContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  gigCreatorAddExperience: PropTypes.func.isRequired,
  experience: PropTypes.number,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCreatorStepExperienceContainer.defaultProps = {
  experience: null,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      experience,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { experience, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddExperience })(GigCreatorStepExperienceContainer));
