import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Keyboard, StyleSheet, Switch, Text, View,
} from 'react-native';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../styles/base.style';
import FormFieldError from '../FormFieldError';
import { stylePropTypes } from '../../../../constants/propTypes';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  switchText: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
    alignSelf: 'center',
  },
  switch: {
    marginLeft: spacings.lg,
  },
});

class PrimarySwitch extends PureComponent {
  render() {
    const {
      label,
      children,
      value,
      onValueChange,
      error,
      style,
      ...props
    } = this.props;

    return (
      <View>
        <View style={styles.container}>
          {
            children || <Text style={[styles.switchText, style.label]}>{label}</Text>
          }

          <Switch
            style={[styles.switch, style.switch]}
            value={value}
            onValueChange={(val) => {
              Keyboard.dismiss();
              onValueChange(val);
            }}
            {...props}
          />
        </View>
        <FormFieldError error={error} />
      </View>
    );
  }
}

PrimarySwitch.propTypes = {
  value: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
  style: PropTypes.shape({
    container: stylePropTypes,
    label: stylePropTypes,
    switch: stylePropTypes,
  }),
};
PrimarySwitch.defaultProps = {
  label: undefined,
  children: null,
  error: null,
  value: false,
  style: {},
};

export default PrimarySwitch;
