import {
  COMPANY_GET_PAYMENTS_OVERVIEW_REQUEST,
  COMPANY_GET_PRICING_PLANS_REQUEST,
  COMPANY_PAYMENTS_GET_BILLING_PROFILE_REQUEST,
  COMPANY_PAYMENTS_SAVE_BILLING_PROFILE_REQUEST,
  COMPANY_PAYMENTS_GET_CURRENT_SUBSCRIPTION_REQUEST,
  COMPANY_PAYMENTS_SUBSCRIBE_REQUEST,
  COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST,
  COMPANY_GET_CARD_REQUEST,
  COMPANY_SAVE_CARD_REQUEST,
  COMPANY_SAVE_SELECTED_PRICING_PLAN,
  COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST,
  COMPANY_GIG_PAY_REQUEST,
  COMPANY_PAYMENTS_CARD_REQUEST,
  COMPANY_GET_PROFIT_MARGIN_REQUEST,
} from '../../constants/actionTypes';

export const getBillingProfile = () => ({
  type: COMPANY_PAYMENTS_GET_BILLING_PROFILE_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/payments/billingProfile',
    },
  },
});

export const saveBillingProfile = (billingProfile) => ({
  type: COMPANY_PAYMENTS_SAVE_BILLING_PROFILE_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/billingProfile',
      data: billingProfile,
    },
  },
});

export const getCardData = () => ({
  type: COMPANY_GET_CARD_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/payments/card',
    },
  },
});

export const saveCard = (data) => ({
  type: COMPANY_SAVE_CARD_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/card',
      data,
    },
  },
});

export const getPaymentsOverview = () => ({
  type: COMPANY_GET_PAYMENTS_OVERVIEW_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/payments/overview',
    },
  },
});

export const getProfitMargin = () => ({
  type: COMPANY_GET_PROFIT_MARGIN_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/payments/margin',
    },
  },
});

export const getPricingPlans = () => ({
  type: COMPANY_GET_PRICING_PLANS_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/payments/plan',
    },
  },
});

export const getCurrentSubscription = () => ({
  type: COMPANY_PAYMENTS_GET_CURRENT_SUBSCRIPTION_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/payments/subscription',
    },
  },
});

export const subscribe = (planId) => ({
  type: COMPANY_PAYMENTS_SUBSCRIBE_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/subscription',
      data: {
        planId,
      },
    },
  },
});

export const changePricingPlan = (planId) => ({
  type: COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/subscription/changePlan',
      data: {
        planId,
      },
    },
  },
});

export const payForGig = (gigId, paymentPlanType, amount = 0) => ({
  type: COMPANY_GIG_PAY_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: `company/payments/gigPayment/${gigId}`,
      data: {
        paymentPlanType,
        amount,
      },
    },
  },
});

export const cancelSubscription = () => ({
  type: COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/subscription/cancel',
    },
  },
});

export const saveSelectedPricingPlan = (planId) => ({
  type: COMPANY_SAVE_SELECTED_PRICING_PLAN,
  selectedPricingPlanId: planId,
});

export const payWithCard = (data) => ({
  type: COMPANY_PAYMENTS_CARD_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/card/hire',
      data,
    },
  },
});

export const checkPaymentIntent = (data) => ({
  type: COMPANY_PAYMENTS_CARD_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: 'company/payments/card/checkIntent',
      data,
    },
  },
});
