export const GIG_DETAILS_ORIGIN = {
  DEEPLINK: 'DEEPLINK',
  FEED: 'FEED',
  FIND_GIGS: 'FIND_GIGS',
  FIND_GIGS_SINGLE: 'FIND_GIGS_SINGLE',
  FAVOURITES: 'FAVOURITES',
  NOT_INTERESTING: 'NOT_INTERESTING',
  MY_GIGS: 'MY_GIGS',
  SIMILAR_GIGS: 'SIMILAR_GIGS',
};
