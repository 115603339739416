import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import CandidateProfileCurrentOccupationForm from '../../forms/currentOccupation/CandidateProfileCurrentOccupationForm';
import CandidateProfileCurrentOccupationFormValidation from '../../forms/currentOccupation/CandidateProfileCurrentOccupationFormValidation';
import { candidateProfileSetCurrentOccupation } from '../../CandidateProfileActions';

class EditableFieldCurrentOccupationModalAware extends React.Component {
  getSelectedCurrentOccupationsForCandidate = () => {
    const {
      translate, selectedCurrentOccupation,
    } = this.props;

    return selectedCurrentOccupation
      .map((value) => translate(`candidateProfile.currentOccupation.occupation.${value}`))
      .join(', ');
  };

  openModal = () => {
    const {
      translate, closeModal, selectedCurrentOccupation,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.currentOccupationLabel'),

      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileCurrentOccupationForm}
        closeModal={closeModal}
        selectedCurrentOccupation={selectedCurrentOccupation}
        validation={CandidateProfileCurrentOccupationFormValidation}
        handleSubmit={(values) => {
          this.props.candidateProfileSetCurrentOccupation(values)
            .then((response) => {
              if (!response.isError) {
                closeModal();
              }
            });
        }}
      />,
    );
  };

  render() {
    const {
      translate,
    } = this.props;
    return (
      <EditableField
        label={translate('candidateProfile.currentOccupation.currentOccupationLabel')}
        value={this.getSelectedCurrentOccupationsForCandidate()}
        placeholder={translate('candidateProfile.details.currentOccupationPlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

EditableFieldCurrentOccupationModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateProfileSetCurrentOccupation: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedCurrentOccupation: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  selectedCurrentOccupation: state.candidate.get('profile').get('currentOccupation').toJS(),
});

export default connect(mapStateToProps, { candidateProfileSetCurrentOccupation })(withLocalize(EditableFieldCurrentOccupationModalAware));
