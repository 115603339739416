import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Platform, StyleSheet, Text, View,
} from 'react-native';
import { fontFamily, fontSizes, spacings } from '../styles/base.style';
import ClickableListItem from './ClickableListItem';
import StaticFormLabel from './form/StaticFormLabel';
import { stylePropTypes } from '../../constants/propTypes';
import { font } from '../styles/mixins';
import WarningIcon from './image/WarningIcon';

const styles = StyleSheet.create({
  labelWrapper: {
    flexDirection: 'row',
  },
  label: {
    marginBottom: 1,
    ...font(fontFamily.SFProDisplayMedium, fontSizes.sm),
  },
  text: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.xl),
    marginBottom: spacings.xxs,
  },
  placeholder: {
    ...font(fontFamily.SFProDisplayItalic, fontSizes.lg),
    marginBottom: spacings.xxs,
  },
  warningIconWrapper: {
    justifyContent: 'flex-start',
    marginRight: 10,
    marginBottom: 10,
  },
});

class EditableField extends PureComponent {
  renderContent = () => {
    const {
      label, value, style, placeholder, showWarning,
    } = this.props;
    return (
      <>
        <View style={styles.labelWrapper}>
          {(showWarning || !value) && <WarningIcon style={{ wrapper: styles.warningIconWrapper }} />}
          <StaticFormLabel label={label} size="sm" style={[styles.label, style.label]} />
        </View>
        {value ? (
          <Text style={[styles.text, style.text]}>{value}</Text>
        ) : (
          <Text style={styles.placeholder}>{placeholder}</Text>
        )}
      </>
    );
  };

  render() {
    const { onPress } = this.props;
    return (
      <ClickableListItem
        renderContent={this.renderContent}
        onPress={onPress}
        style={{ iconWrapper: { paddingTop: Platform.OS === 'ios' ? 25 : 30 } }}
      />
    );
  }
}

EditableField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  showWarning: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.shape({
    label: stylePropTypes,
    text: stylePropTypes,
  }),
  placeholder: PropTypes.string,
};

EditableField.defaultProps = {
  style: {
    label: null,
    text: null,
  },
  showWarning: null,
  value: '',
  placeholder: undefined,
};

export default EditableField;
