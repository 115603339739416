import validator from 'validator';

export const MAX_DESCRIPTION_LENGTH = 1000;
export const MAX_STREET_LENGTH = 100;
export const MAX_CITY_LENGTH = 100;

const msisdnRegexp = /^(\+)?[\d\s]{7,15}$/;

export default
[
  {
    field: 'description',
    method: validator.isLength,
    args: [{ max: MAX_DESCRIPTION_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'contactInfo.street',
    method: validator.isLength,
    args: [{ max: MAX_STREET_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'contactInfo.city',
    method: validator.isLength,
    args: [{ max: MAX_CITY_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'contactInfo.phoneNumber',
    custom: (value) => ((value && value.length > 0) ? msisdnRegexp.test(value) : true),
    message: 'wrong_format',
  },
  {
    field: 'contactInfo.email',
    custom: (value) => ((value && value.length > 0) ? validator.isEmail(value) : true),
    message: 'wrong_format',
  },
  {
    field: 'contactInfo.website',
    custom: (value) => !value || validator.isURL(value, { require_protocol: false }),
    message: 'wrong_format',
  },
];
