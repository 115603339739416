import React from 'react';
import {
  COMPANY_GIGS_LIST,
  COMPANY_GIG_MATCHING,
  COMPANY_GIG_DETAILS,
  COMPANY_GIG_EDIT,
  COMPANY_GIG_CANDIDATE_VIEW,
  COMPANY_GIG_PAYMENT_BILLING_PROFILE,
} from '../../constants/routes';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../constants/userScopes';
import CompanyGigsContainer from './CompanyGigsContainer';
import CompanyGigMatching from './gigDetails/CompanyGigMatching';
import CompanyGigDetailsContainer from './gigDetails/CompanyGigDetailsContainer';
import GigEditRouting from '../gig_creator/GigEditRouting';
import GigCandidateProfileContainer from './gigDetails/candidates/GigCandidateProfileContainer';
import PayForGigBillingProfileContainer from '../payments/gigPayment/billingProfile/PayForGigBillingProfileContainer';

const CompanyGigsRouting = () => (
  <>
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_GIG_DETAILS} component={CompanyGigDetailsContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_GIG_CANDIDATE_VIEW} component={GigCandidateProfileContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_GIG_PAYMENT_BILLING_PROFILE} component={PayForGigBillingProfileContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_EDIT} component={GigEditRouting} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIGS_LIST} component={CompanyGigsContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIG_MATCHING} component={CompanyGigMatching} />
  </>
);

export default CompanyGigsRouting;
