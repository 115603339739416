import { CANDIDATE_SIGNUP_REQUEST } from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export default function signupCandidate(signupData) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.SIGN_UP);
  return {
    type: CANDIDATE_SIGNUP_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'auth/candidate/signup',
        data: signupData,
      },
    },
  };
}
