import { CANDIDATE_USER, COMPANY_USER } from './userScopes';
import {
  CANDIDATE_MY_GIGS_APPLIED,
  CANDIDATE_MY_GIGS_HIRED,
  CANDIDATE_MY_GIGS_MATCHED,
  CANDIDATE_MY_GIGS_OFFERS,
  CANDIDATE_MY_GIGS_SHORTLISTED,
  COMPANY_GIG_MATCHING_SHORTLISTED,
  COMPANY_GIG_MATCHING_MATCHED,
  COMPANY_GIG_MATCHING_OFFERS,
  COMPANY_GIG_MATCHING_HIRED,
  COMPANY_GIG_MATCHING_REVIEW,
} from './routes';
import { GIG_CANDIDATE_STATUS } from './matchingStatus';

export const TAB_NAMES = {
  REVIEW: 'REVIEW',
};

export const GIG_CANDIDATE_STATUS_CONFIG = {
  [GIG_CANDIDATE_STATUS.APPLIED]: {
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 1,
      route: COMPANY_GIG_MATCHING_REVIEW.replace(':id', gigId),
      mapStateTab: TAB_NAMES.REVIEW,
    }),
    [CANDIDATE_USER]: () => ({
      tabIndex: 1,
      route: CANDIDATE_MY_GIGS_APPLIED,
    }),
  },
  [GIG_CANDIDATE_STATUS.SUGGESTED]: {
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 1,
      route: COMPANY_GIG_MATCHING_REVIEW.replace(':id', gigId),
      mapStateTab: TAB_NAMES.REVIEW,
    }),
  },
  [GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL]: {
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 2,
      route: COMPANY_GIG_MATCHING_SHORTLISTED.replace(':id', gigId),
    }),
    [CANDIDATE_USER]: () => ({
      tabIndex: 2,
      route: CANDIDATE_MY_GIGS_SHORTLISTED,
    }),
  },
  [GIG_CANDIDATE_STATUS.SHORTLISTED]: {
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 3,
      route: COMPANY_GIG_MATCHING_MATCHED.replace(':id', gigId),
    }),
    [CANDIDATE_USER]: () => ({
      tabIndex: 3,
      route: CANDIDATE_MY_GIGS_MATCHED,
    }),
  },
  [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER]: {
    [CANDIDATE_USER]: () => ({
      tabIndex: 4,
      route: CANDIDATE_MY_GIGS_OFFERS,
    }),
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 4,
      route: COMPANY_GIG_MATCHING_OFFERS.replace(':id', gigId),
    }),
  },
  [GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED]: {
    [CANDIDATE_USER]: () => ({
      tabIndex: 4,
      route: CANDIDATE_MY_GIGS_OFFERS,
    }),
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 4,
      route: COMPANY_GIG_MATCHING_OFFERS.replace(':id', gigId),
    }),
  },
  [GIG_CANDIDATE_STATUS.HIRED]: {
    [CANDIDATE_USER]: () => ({
      tabIndex: 5,
      route: CANDIDATE_MY_GIGS_HIRED,
    }),
    [COMPANY_USER]: (gigId) => ({
      tabIndex: 5,
      route: COMPANY_GIG_MATCHING_HIRED.replace(':id', gigId),
    }),
  },
};
