import {
  StyleSheet, Text, View, ScrollView,
} from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';

import Routing from '../../../../core/routing/index';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import validation from './CompanyShiftsDatesAndTimesStepEditableValidation';
import ScreenHeader from '../../../../common/components/header/ScreenHeader';
import {
  dynamicSpacings, fontFamily, fontSizes, modernColors, shadowTop, spacings, wp,
} from '../../../../common/styles/base.style';
import { shiftDateAndTimePropType } from '../../../../constants/propTypes';
import ViewportController from '../../../../common/components/ViewportController';
import Container from '../../../../common/components/Container';
import HorizontalWrapper from '../../../../common/components/HorizontalWrapper';
import FormikFieldDateTimePicker from '../../../../common/components/form/FormikFieldDateTimePicker';
import { getDefaultDate, startOfToday } from '../../../../utilities/dateUtils';
import Button, { BUTTON_SIZES } from '../../../../common/components/buttons/Button';
import { font } from '../../../../common/styles/mixins';
import DateAndTimeRow from './DateAndTimeRow';
import PrimarySwitch from '../../../../common/components/form/switch/PrimarySwitch';

const { withRouter } = Routing;

const deleteIcon = require('../../../../assets/icons/removeBtn.png');

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
  newShiftsTitle: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl),
    marginTop: spacings.md,
  },
  definedShiftsTitle: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl),
    marginBottom: spacings.sm,
  },
  dateAndTimeWrapper: {
    display: 'flex',
    backgroundColor: modernColors.lightGrey,
    paddingVertical: spacings.md,
    paddingHorizontal: dynamicSpacings.sm,
    flexDirection: 'column',
  },
  dateInputsWrapper: {
    zIndex: 1,
    marginHorizontal: spacings.md,
    justifyContent: 'space-between',
  },
  switchContainer: {
    paddingTop: spacings.md,
  },
  switchLabel: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl),
  },
  headersWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: wp(76),
  },
  headerText: {
    flexDirection: 'row',
    paddingHorizontal: spacings.sm,
    width: wp(39),
  },
});

const startDateStyle = StyleSheet.create({
  container: {
    flex: 1,
    marginRight: spacings.xs,
    marginTop: spacings.lg,
  },
  input: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
  },
  label: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
  },
});

const endDateStyle = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: spacings.xs,
    marginTop: spacings.lg,
  },
  input: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
  },
  label: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
  },
});

function CompanyShiftsDatesAndTimesStepEditableScreen(props) {
  const {
    translate, handleNext, shiftDatesAndTimes, onRemoveDateAndTime, onAddDateAndTime, setBookSeparately, bookSeparately,
  } = props;

  const shiftDateAndTimesExist = shiftDatesAndTimes && shiftDatesAndTimes.length > 0;
  const translateByKey = (key) => translate(`company.shifts.shift.${key}`);

  return (
    <ViewportController safeAreaBottom>
      <ScreenHeader
        title={translateByKey('datesAndTimes.datesAndTimesHeader')}
        showBackArrow
        shadow
      />
      <Container>
        <ScrollView bounces={false}>
          {shiftDateAndTimesExist && (
            <View style={styles.dateAndTimeWrapper}>
              <Text style={styles.definedShiftsTitle}>{translateByKey('datesAndTimes.definedShiftsTitle')}</Text>
              <View style={styles.headersWrapper}>
                <View style={styles.headerText}>
                  <Text>{translateByKey('datesAndTimes.in')}</Text>
                </View>
                <View style={styles.headerText}>
                  <Text>{translateByKey('datesAndTimes.out')}</Text>
                </View>
              </View>
              {shiftDatesAndTimes.map((item) => (
                <DateAndTimeRow
                  itemId={item.id}
                  key={item.id}
                  leftValue={item.startDate}
                  rightValue={item.endDate}
                  onActionPress={onRemoveDateAndTime}
                  actionImg={deleteIcon}
                />
              ))}
              <View style={styles.switchContainer}>
                <PrimarySwitch
                  label={translateByKey('datesAndTimes.bookSeparately')}
                  value={shiftDatesAndTimes.length < 2 ? false : bookSeparately}
                  onValueChange={(value) => setBookSeparately(value)}
                  style={{ label: styles.switchLabel }}
                  disabled={shiftDatesAndTimes.length < 2}
                />
              </View>
            </View>
          )}
          <FormikWithValidation
            validation={validation}
            initialValues={{}}
            onSubmit={onAddDateAndTime}
            render={({ handleSubmit, isBtnDisabled, values }) => (
              <Container>
                <Text style={styles.newShiftsTitle}>{translateByKey('datesAndTimes.newShift')}</Text>
                <HorizontalWrapper style={styles.dateInputsWrapper} noFlex>
                  <View style={{ width: wp(39) }}>
                    <FormikFieldDateTimePicker
                      name="startDate"
                      label={translateByKey('datesAndTimes.startDate')}
                      style={startDateStyle}
                      defaultDateFunc={getDefaultDate}
                      minimumDateFunc={() => new Date().getTime()}
                    />
                  </View>
                  <View style={{ width: wp(39) }}>
                    <FormikFieldDateTimePicker
                      name="endDate"
                      label={translateByKey('datesAndTimes.endDate')}
                      style={endDateStyle}
                      minimumDateFunc={() => (values.startDate ? moment(values.startDate).add(1, 'h').startOf('hour') : startOfToday())}
                      maximumDateFunc={() => (values.startDate ? moment(values.startDate).add(24, 'h') : startOfToday())}
                    />
                  </View>
                </HorizontalWrapper>
                <Button
                  title={translateByKey('add')}
                  disabled={isBtnDisabled || (!values.startDate || !values.endDate)}
                  onPress={handleSubmit}
                  size={BUTTON_SIZES.MD}
                />
              </Container>
            )}
          />
        </ScrollView>
      </Container>
      <View style={styles.button}>
        <Button
          title={translate('generic.next')}
          disabled={!shiftDatesAndTimes.length}
          onPress={handleNext}
        />
      </View>
    </ViewportController>
  );
}

CompanyShiftsDatesAndTimesStepEditableScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  onAddDateAndTime: PropTypes.func.isRequired,
  onRemoveDateAndTime: PropTypes.func.isRequired,
  shiftDatesAndTimes: PropTypes.arrayOf(shiftDateAndTimePropType),
  bookSeparately: PropTypes.bool.isRequired,
  setBookSeparately: PropTypes.func.isRequired,
};

CompanyShiftsDatesAndTimesStepEditableScreen.defaultProps = {
  shiftDatesAndTimes: [],
};

export default withLocalize(withRouter(CompanyShiftsDatesAndTimesStepEditableScreen));
