import React from 'react';
import PropTypes from 'prop-types';
import WebImageWithFallback from './WebImageWithFallback';
import * as propTypes from '../../../constants/propTypes';

const userProfile = require('../../../assets/icons/user_profile.png');

const UserProfileImage = ({ profileImageUri, style }) => (
  <WebImageWithFallback fallbackImage={userProfile} uri={profileImageUri} style={style} />
);

UserProfileImage.propTypes = {
  profileImageUri: PropTypes.string,
  style: propTypes.stylePropTypes.isRequired,
};

UserProfileImage.defaultProps = {
  profileImageUri: undefined,
};

export default UserProfileImage;
