import { StyleSheet } from 'react-native';
import { spacings } from '../../../../../common/styles/base.style';

const candidatesListStyles = StyleSheet.create({
  contentContainerStyle: {
    paddingVertical: spacings.xs,
  },
});

export default candidatesListStyles;
