import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { colors, fontFamily, fontSizes, spacings } from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import Container from '../../../common/components/Container';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: spacings.xxs,
  },
  infoLine: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  readMoreContainer: {
    alignItems: 'flex-end',
  },
  readMoreLink: {
    ...font(fontFamily.SFProTextSemiBold, fontSizes.sm, null, colors.magenta),
  },
  name: {
    marginBottom: spacings.xs,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xl, null, colors.greyishBrown),
  },
  price: {
    marginBottom: spacings.xs,
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, null, colors.greyishBrown),
  },
  description: {
    flex: 1,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, null, colors.brownishGrey),
  },
  selectedMsg: {
    color: colors.black,
  },
  selected: {
    color: colors.magenta,
  },
});

class PaymentPlanItem extends PureComponent {
  onReadMore = () => {
    Linking.openURL(this.props.paymentPlan.readMoreUrl);
  };

  getPaymentPlanName = (paymentPlan) => this.props.translate(`company.payments.gig.paymentPlans.${paymentPlan.type}.name`);

  getPaymentPlanDescription = (paymentPlan) => {
    if (paymentPlan.period && paymentPlan.periodUnit) {
      const periodUnitTranslation = this.props.translate(`company.payments.gig.paymentPlans.${paymentPlan.type}.${paymentPlan.periodUnit}`);
      return this.props.translate(`company.payments.gig.paymentPlans.${paymentPlan.type}.descriptionParametrized`, {
        period: paymentPlan.period,
        periodUnitTranslation,
      });
    }
    return this.props.translate(`company.payments.gig.paymentPlans.${paymentPlan.type}.descriptionDefault`);
  };

  render() {
    const { paymentPlan, translate, isSelected } = this.props;

    return (
      <View style={styles.container}>
        <Container>
          <View style={styles.infoLine}>
            <Text style={[styles.name, isSelected && styles.selected]}>{this.getPaymentPlanName(paymentPlan)}</Text>
            {!!paymentPlan.price && (
              <Text style={[styles.price, isSelected && styles.selected]}>{`${paymentPlan.price} ${paymentPlan.currency}`}</Text>
            )}
          </View>
          <View style={styles.infoLine}>
            <Text style={styles.description}>{this.getPaymentPlanDescription(paymentPlan)}</Text>
          </View>
          {!!paymentPlan.readMoreUrl && (
            <TouchableOpacity
              onPress={this.onReadMore}
              style={styles.readMoreContainer}
              hitSlop={{
                top: 15,
                bottom: 15,
                left: 15,
                right: 15,
              }}
            >
              <Text style={styles.readMoreLink}>{translate('company.payments.gig.readMore')}</Text>
            </TouchableOpacity>
          )}
        </Container>
      </View>
    );
  }
}

PaymentPlanItem.propTypes = {
  translate: PropTypes.func.isRequired,
  paymentPlan: PropTypes.shape({
    type: PropTypes.string.isRequired,
    price: PropTypes.number,
    currency: PropTypes.string,
    period: PropTypes.number,
    periodUnit: PropTypes.string,
    readMoreUrl: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default PaymentPlanItem;
