import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import SectionHeader from '../../../common/components/header/SectionHeader';
import EditableFieldFullNameModalAware from './fields/EditableFieldFullNameModalAware';
import EditableFieldEmailModalAware from './fields/EditableFieldEmailModalAware';
import EditableFieldAvailabilityModalAware from './fields/EditableFieldAvailabilityModalAware';
import CandidateProfileEditDetailsFieldModal from './CandidateProfileEditDetailsFieldModal';
import EditableFieldLocationModalAware from './fields/EditableFieldLocationModalAware';
import EditableFieldCategoryModalAware from './fields/EditableFieldCategoryModalAware';
import CandidateProfileEditDetailsEducationModalAware from './fields/CandidateProfileEditDetailsEducationModalAware';
import CandidateProfileEditDetailsExperienceModalAware from './fields/CandidateProfileEditDetailsExperienceModalAware';
import CandidateProfileEditDetailsAboutModalAware from './fields/CandidateProfileEditDetailsAboutModalAware';
import Wrapper from '../../../common/components/Wrapper';
import { colors, spacings } from '../../../common/styles/base.style';
import Hr from '../../../common/components/Hr';
import CandidateProfileEditDetailsPhoneModalAware from './fields/CandidateProfileEditDetailsPhoneModalAware';
import { candidateProfilePropType } from '../../../constants/propTypes';
import { MessageBox } from '../../../common/components/MessageBox';
import CandidateProfileEditDetailsPersonalNumberModalAware from './fields/CandidateProfileEditDetailsPersonalNumberModalAware';
import CandidateProfileEditDetailsClearingNumberModalAware from './fields/CandidateProfileEditDetailsClearingNumberModalAware';
import CandidateProfileEditDetailsBankAccountNumberModalAware from './fields/CandidateProfileEditDetailsBankAccountNumberModalAware';
import EditableFieldCurrentOccupationModalAware from './fields/EditableFieldCurrentOccupationModalAware';
import FeatureToggle from '../../../common/components/FeatureToggle';
import EditableFieldLanguagesModalAware from './fields/EditableFieldLanguagesModalAware';
import Container from '../../../common/components/Container';
import ViewportController from '../../../common/components/ViewportController';
import EditableFieldHomeLocationModalAware from './fields/EditableFieldHomeLocationModalAware';
import WarningIcon from '../../../common/components/image/WarningIcon';
import { getMissingProfilePropsNumber } from '../profileHelper';
import CandidateProfileEditDetailsAssociationModalAware from './fields/CandidateProfileEditDetailsAssociationModalAware';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: colors.veryLightGrey,
  },
  hr: {
    marginTop: -15,
  },
  sectionHeaderWrapper: {
    marginBottom: 0,
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  sectionHeaderText: {
    flex: 1,
  },
  warningIconWrapper: {
    marginRight: spacings.sm,
    justifyContent: 'center',
  },
});

const CandidateProfileEditDetailsScreen = ({ translate, setModalContent, closeModal, modalVisible, modalHeader, modalBody, profile }) => {
  const t = (key) => translate(`candidateProfile.details.${key}`);

  const renderField = (Component) => <Component setModalContent={setModalContent} closeModal={closeModal} style={styles.borderBottom} />;

  const showWarningIncompleteProfile = () => {
    const missingProfilePropsNumber = getMissingProfilePropsNumber(profile);
    const isVisible = missingProfilePropsNumber > 1;
    return isVisible && <MessageBox title={t('warningBoxText')} />;
  };

  return (
    <ViewportController safeAreaBottom>
      <Container>
        <ScreenHeader title={t('edit')} showBackArrow />
        <ScrollView>
          <View style={[styles.innerContainer]}>
            {showWarningIncompleteProfile()}
            <SectionHeader title={t('personalInformationHeader')} />
            <View>
              <Wrapper>
                {renderField(EditableFieldFullNameModalAware)}
                <Hr style={styles.hr} />
                {renderField(EditableFieldEmailModalAware)}
                <Hr style={styles.hr} />
                {renderField(CandidateProfileEditDetailsAboutModalAware)}
                <Hr style={styles.hr} />
                {renderField(CandidateProfileEditDetailsPersonalNumberModalAware)}
                <Hr style={styles.hr} />
                {renderField(CandidateProfileEditDetailsClearingNumberModalAware)}
                <Hr style={styles.hr} />
                {renderField(CandidateProfileEditDetailsBankAccountNumberModalAware)}
                <Hr style={styles.hr} />
                {renderField(CandidateProfileEditDetailsPhoneModalAware)}
                <Hr style={styles.hr} />
                {renderField(CandidateProfileEditDetailsAssociationModalAware)}
                <FeatureToggle requestedFeature="OCCUPATION">
                  <Hr style={styles.hr} />
                  {renderField(EditableFieldCurrentOccupationModalAware)}
                </FeatureToggle>
                <FeatureToggle requestedFeature="PROFILE_DATA_EXTENSION">
                  <Hr style={styles.hr} />
                  {renderField(EditableFieldLanguagesModalAware)}
                </FeatureToggle>
                <FeatureToggle requestedFeature="HOME_LOCATION">
                  <Hr style={styles.hr} />
                  {renderField(EditableFieldHomeLocationModalAware)}
                </FeatureToggle>
              </Wrapper>
            </View>
            <SectionHeader title={t('gigPreferences')} />
            <View>
              <Wrapper>
                {renderField(EditableFieldAvailabilityModalAware)}
                <Hr style={styles.hr} />
                {renderField(EditableFieldLocationModalAware)}
                <Hr style={styles.hr} />
                {renderField(EditableFieldCategoryModalAware)}
              </Wrapper>
            </View>
            <SectionHeader title={t('experienceHeader')} style={{ wrapper: styles.sectionHeaderWrapper, header: styles.sectionHeaderText }}>
              {(!profile.experience || profile.experience.length === 0) && <WarningIcon style={{ wrapper: styles.warningIconWrapper }} />}
            </SectionHeader>
            <View>
              <Wrapper>{renderField(CandidateProfileEditDetailsExperienceModalAware)}</Wrapper>
            </View>
            <SectionHeader title={t('educationHeader')} style={{ wrapper: styles.sectionHeaderWrapper, header: styles.sectionHeaderText }}>
              {(!profile.education || profile.education.length === 0) && <WarningIcon style={{ wrapper: styles.warningIconWrapper }} />}
            </SectionHeader>
            <View>
              <Wrapper>{renderField(CandidateProfileEditDetailsEducationModalAware)}</Wrapper>
            </View>
          </View>
        </ScrollView>
        <CandidateProfileEditDetailsFieldModal header={modalHeader} visible={modalVisible} body={modalBody} closeModal={closeModal} />
      </Container>
    </ViewportController>
  );
};

CandidateProfileEditDetailsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  modalHeader: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  modalBody: PropTypes.object,
  profile: candidateProfilePropType.isRequired,
};

CandidateProfileEditDetailsScreen.defaultProps = {
  modalBody: undefined,
};

export default withLocalize(CandidateProfileEditDetailsScreen);
