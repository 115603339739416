import React, { Component } from 'react';
import {
  StyleSheet, Text, View, Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import LottieView from 'lottie-react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { resetPassword } from '../authActions';
import Container from '../../components/Container';
import ForgotPasswordScreen from '../../screens/ForgotPasswordScreen';
import ScreenHeader from '../../components/header/ScreenHeader';
import { fontFamily, fontSizes } from '../../styles/base.style';
import { font } from '../../styles/mixins';

const checkAnimation = require('../../../assets/animations/check.json');

const styles = StyleSheet.create({
  textContainer: {
    alignItems: 'center',
    marginTop: 40,
    paddingHorizontal: 5,
  },
  text: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.lg),
    width: '100%',
    textAlign: 'center',
  },
});

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  onSubmit = (values, { setErrors }) => {
    this.props.resetPassword(values)
      .then((response) => {
        if (response.isError) {
          setErrors(response.errors);
        } else {
          this.setState({ submitted: true });
        }
      });
  };

  render() {
    const { translate } = this.props;
    return (
      this.state.submitted
        ? (
          <Container>
            <ScreenHeader showBackArrow title={translate('loginScreen.resetPassword')} />
            <View style={styles.textContainer}>
              {
              Platform.OS !== 'web'
              && (
              <LottieView
                source={checkAnimation}
                autoPlay
                loop={false}
                style={{ height: 60, width: 60 }}
              />
              )
            }
              <Text style={styles.text}>{translate('loginScreen.requestEmailUrl')}</Text>
            </View>
          </Container>
        )
        : <ForgotPasswordScreen onSubmit={this.onSubmit} />
    );
  }
}

ForgotPasswordContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default withLocalize(connect(null, { resetPassword })(ForgotPasswordContainer));
