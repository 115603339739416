import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ScrollView } from 'react-native';
import Routing from '../../core/routing/index';

import NavigationBar from '../../common/components/navigation/NavigationBar';
import NavItem from '../../common/components/navigation/NavItem';
import {
  ADMIN_CANDIDATES,
  ADMIN_GIGS,
  ADMIN_NOTIFICATION,
  ADMIN_PROFILE,
  ADMIN_SETTINGS,
  ADMIN_FORTNOX,
  ADMIN_COMPANY,
  ADMIN_ASSOCIATIONS
} from '../../constants/routes';
import renderMenu from './appMenuConfig';
import { SUPERADMIN_USER } from '../../constants/userScopes';
import UserScope from '../../common/auth/UserScope';

const { withRouter } = Routing;

const searchIconOn = require('../../assets/icons/menu/iconLoupeOnFull.png');
const searchIconOff = require('../../assets/icons/menu/iconLoupeOffFull.png');

const profileIconOn = require('../../assets/icons/menu/iconMyProfileOn.png');
const profileIconOff = require('../../assets/icons/menu/iconMyProfileOff.png');
const settingsIconOn = require('../../assets/icons/menu/iconSettingsOn.png');
const settingsIconOff = require('../../assets/icons/menu/iconSettingsOff.png');

const AdminNavigationBar = ({ translate, location, history }) => renderMenu(location.pathname) && (
<ScrollView>
  <NavigationBar>
    <NavItem iconOn={searchIconOn} iconOff={searchIconOff} text={translate('adminFindGigsScreen.findGigs')} link={ADMIN_GIGS} />
    <NavItem iconOn={searchIconOn} iconOff={searchIconOff} text={translate('adminFindGigsScreen.findCandidates')} link={ADMIN_CANDIDATES} />
    <UserScope allowedFor={[SUPERADMIN_USER]}>
      <NavItem
        iconOn={searchIconOn}
        iconOff={searchIconOff}
        text={translate('adminPanel.company')}
        link={ADMIN_COMPANY}
        location={location}
        history={history}
      />
      <NavItem
        iconOn={searchIconOn}
        iconOff={searchIconOff}
        text={translate('adminPanel.associations')}
        link={ADMIN_ASSOCIATIONS}
        location={location}
        history={history}
      />
      <NavItem
        iconOn={profileIconOn}
        iconOff={profileIconOff}
        text={translate('adminPanel.notification')}
        link={ADMIN_NOTIFICATION}
        location={location}
        history={history}
      />
    </UserScope>

    <NavItem iconOn={profileIconOn} iconOff={profileIconOff} text={translate('generic.myProfile')} link={ADMIN_PROFILE} />
    <UserScope allowedFor={[SUPERADMIN_USER]}>
      <NavItem
        iconOn={profileIconOn}
        iconOff={profileIconOff}
        text={translate('adminPanel.fortnox')}
        link={ADMIN_FORTNOX}
        location={location}
        history={history}
      />
    </UserScope>
    <UserScope allowedFor={[SUPERADMIN_USER]}>
      <NavItem
        iconOn={settingsIconOn}
        iconOff={settingsIconOff}
        text={translate('adminPanel.settings')}
        link={ADMIN_SETTINGS}
        location={location}
        history={history}
      />
    </UserScope>
  </NavigationBar>
</ScrollView>
  );

AdminNavigationBar.propTypes = {
  translate: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, null)(withLocalize(withRouter(AdminNavigationBar)));
