import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import CandidateProfileBankAccountNumberForm from '../../forms/bankAccountNo/CandidateProfileBankAccountNumberForm';
import { candidateProfileEditBankAccountNumber } from '../../CandidateProfileActions';

class CandidateProfileEditDetailsBankAccountNumberModalAware extends React.Component {
  handleSubmit = (value, { setErrors, setSubmitting }) => {
    const { candidateProfileEditBankAccountNumber: editAction, closeModal } = this.props;
    editAction(value)
      .then((response) => {
        if (!response.isError) {
          closeModal();
        } else {
          setErrors(response.errors);
          setSubmitting(false);
        }
      });
  };

  openModal = () => {
    const {
      translate, bankAccountNo, closeModal,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.bankAccountNo'),
      <ModalKeyboardAwareScrollView>
        <CandidateProfileEditDetailsFormWithFooter
          component={CandidateProfileBankAccountNumberForm}
          closeModal={closeModal}
          bankAccountNo={bankAccountNo}
          handleSubmit={this.handleSubmit}
        />
      </ModalKeyboardAwareScrollView>,
    );
  };

  render() {
    const { translate, bankAccountNo } = this.props;

    return (
      <EditableField
        label={translate('candidateProfile.details.bankAccountNo')}
        value={bankAccountNo}
        placeholder={translate('candidateProfile.details.bankAccountNoFieldPlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

CandidateProfileEditDetailsBankAccountNumberModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  candidateProfileEditBankAccountNumber: PropTypes.func.isRequired,
  bankAccountNo: PropTypes.string,
};

CandidateProfileEditDetailsBankAccountNumberModalAware.defaultProps = {
  bankAccountNo: undefined,
};

const mapStateToProps = (state) => ({ bankAccountNo: state.candidate.get('profile').get('bankAccountNo') });

export default connect(mapStateToProps, { candidateProfileEditBankAccountNumber })(withLocalize(CandidateProfileEditDetailsBankAccountNumberModalAware));
