import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import Routing from '../../../core/routing/index';
import {
  get, accept, reject, signOff,
} from '../candidateMyPoolsActions';
import CandidateMyPoolsScreen from './CandidateMyPoolsScreen';
import { CANDIDATE_MY_POOLS_DIRECT_CHAT, CANDIDATE_MY_POOLS_POOL_PROFILE } from '../../../constants/routes';

const { withRouter } = Routing;

class CandidateMyPoolsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getMyPools().then((res) => {
      this.setState({ pools: res.payload, loading: false });
    });
  }

  onAccept(poolCandidateId) {
    this.setState({ loading: true });
    this.props.accept(poolCandidateId).then((res) => {
      const pools = this.state.pools.map((pool) => {
        if (pool.poolCandidateId === poolCandidateId) {
          return res.payload;
        }
        return pool;
      });
      this.setState({ loading: false, pools });
    });
  }

  onReject(poolCandidateId) {
    this.setState({ loading: true });
    this.props.reject(poolCandidateId).then(() => {
      const pools = this.state.pools.filter((pool) => pool.poolCandidateId !== poolCandidateId);
      this.setState({ loading: false, pools });
    });
  }

  onSignOff(poolCandidateId) {
    this.setState({ loading: true });
    this.props.signOff(poolCandidateId).then(() => {
      const pools = this.state.pools.filter((pool) => pool.poolCandidateId !== poolCandidateId);
      this.setState({ loading: false, pools });
    });
  }

  onOpenPoolProfile = (companyId) => {
    this.props.history.push(CANDIDATE_MY_POOLS_POOL_PROFILE.replace(':companyId', companyId));
  };

  onOpenDirectChat = (poolCandidateId) => {
    this.props.history.push(CANDIDATE_MY_POOLS_DIRECT_CHAT.replace(':poolCandidateId', poolCandidateId));
  };

  render() {
    return (
      <CandidateMyPoolsScreen
        loading={this.state.loading}
        pools={this.state.pools}
        onAccept={(poolCandidateId) => this.onAccept(poolCandidateId)}
        onReject={(poolCandidateId) => this.onReject(poolCandidateId)}
        onSignOff={(poolCandidateId) => this.onSignOff(poolCandidateId)}
        onOpenPoolProfile={(companyId) => this.onOpenPoolProfile(companyId)}
        onOpenDirectChat={(poolCandidateId) => this.onOpenDirectChat(poolCandidateId)}
      />
    );
  }
}

CandidateMyPoolsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getMyPools: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  signOff: PropTypes.func.isRequired,
};

export default connect(null, {
  getMyPools: get,
  accept,
  reject,
  signOff,
})(withRouter(CandidateMyPoolsContainer));
