import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import PropTypes from 'prop-types';
import React from 'react';
import {
  fontSizes, spacings, colors, fontFamily,
} from '../../styles/base.style';
import TabCounter from './TabCounter';

const styles = StyleSheet.create({
  navItem: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    flexDirection: 'row',
    borderBottomWidth: 4,
  },
  activeTab: {
    borderBottomColor: colors.magenta,
  },
  notActiveTab: {
    borderBottomColor: colors.white,
  },
  text: {
    paddingVertical: spacings.xs,
    fontSize: fontSizes.md,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayBold,
    letterSpacing: 0.2,
  },
  verticalBorder: {
    borderRightWidth: 1,
    borderRightColor: colors.veryLightPink,
    height: 14,
    width: 0,
    alignSelf: 'center',
    position: 'absolute',
    right: 0,
    bottom: 10,
  },
  newContentColor: {
    color: colors.seablue,
  },
});

const TabBarItem = ({
  text, active, onPress, showCounter, counterValue, counterHighlighted, width, scrollView, isLastInTabBar, isNewContent,
}) => (
  <TouchableOpacity
    style={[styles.navItem, active ? styles.activeTab : styles.notActiveTab, { width }]}
    onPress={onPress}
  >
    <Text style={[styles.text, isNewContent && styles.newContentColor]}>{text}</Text>
    {showCounter && <TabCounter value={counterValue} highlighted={counterHighlighted} />}
    {scrollView && !isLastInTabBar && <View style={styles.verticalBorder} />}
  </TouchableOpacity>
);

TabBarItem.propTypes = {
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  counterValue: PropTypes.number,
  showCounter: PropTypes.bool,
  counterHighlighted: PropTypes.bool,
  width: PropTypes.number.isRequired,
  scrollView: PropTypes.bool.isRequired,
  isLastInTabBar: PropTypes.bool.isRequired,
  isNewContent: PropTypes.bool,
};
TabBarItem.defaultProps = {
  counterValue: 0,
  showCounter: false,
  counterHighlighted: false,
  isNewContent: false,
};

export default TabBarItem;
