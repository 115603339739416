import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';

import {
  fontFamily, fontSizes, spacings, wp,
} from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import EntryRow from '../../../../common/components/EntryRow';
import Container from '../../../../common/components/Container';
import IconButtonBolek from '../../../../common/components/buttons/IconButtonBolek';
import { imagePropType } from '../../../../constants/propTypes';

const styles = StyleSheet.create({
  textEntry: {
    flexDirection: 'row',
    paddingHorizontal: spacings.sm,
    width: wp(39),
  },
  text: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
  },
  image: {
    width: 30,
    height: 32,
    paddingLeft: spacings.sm,
  },
});

const OBPaymentRow = ({
  itemId, leftValue, rightValue, actionImg, onActionPress,
}) => (
  <Container row style={{ alignItems: 'center' }}>
    <EntryRow style={{ marginRight: spacings.sm }}>
      <View style={styles.textEntry}>
        <Text style={styles.text}>{leftValue}</Text>
      </View>
      <View style={styles.textEntry}>
        <Text style={[styles.text]}>{rightValue}</Text>
      </View>
    </EntryRow>
    <IconButtonBolek onPress={() => onActionPress(itemId)} source={actionImg} />
  </Container>
);

OBPaymentRow.propTypes = {
  itemId: PropTypes.string.isRequired,
  leftValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  actionImg: imagePropType.isRequired,
  onActionPress: PropTypes.func.isRequired,
};

export default OBPaymentRow;
