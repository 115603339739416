import React from 'react';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../constants/userScopes';
import {
  COMPANY_SHIFT_EDIT,
  COMPANY_SHIFT_VIEW,
  COMPANY_SHIFT_CREATE,
  COMPANY_SHIFT_CANDIDATES,
} from '../../constants/routes';
import CompanyShiftsMainStepEditableContainer from './steps/main/CompanyShiftsMainStepEditableContainer';
import CompanyShiftCandidatesContainer from './candidates/CompanyShiftCandidatesContainer';
import CompanyShiftViewContainer from './view/CompanyShiftViewContainer';
import CompanyShiftsCreationRouting from './steps/CompanyShiftsCreationRouting';

const CompanyShiftsRouting = () => (
  <>
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_SHIFT_CREATE} component={CompanyShiftsCreationRouting} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_CANDIDATES} component={CompanyShiftCandidatesContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_VIEW} component={CompanyShiftViewContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_EDIT} component={CompanyShiftsMainStepEditableContainer} />
  </>
);

export default CompanyShiftsRouting;
