import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { SUPERADMIN_USER } from '../../constants/userScopes';
import { ADMIN_FORTNOX, ADMIN_FORTNOX_CANDIDATES } from '../../constants/routes';
import AdminFortnoxCandidateList from './AdminFortnoxCandidateList';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_FORTNOX_CANDIDATES;

const AdminCompanyRouting = () => (
  <>
    <Route exact path={ADMIN_FORTNOX} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_FORTNOX_CANDIDATES} component={AdminFortnoxCandidateList} />
  </>
);

export default AdminCompanyRouting;
