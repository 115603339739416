/* eslint-disable global-require,no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Image, TouchableOpacity, StyleSheet, Text, View, LayoutAnimation, UIManager, Platform,
} from 'react-native';
import {
  colors, fontFamily, fontSizes, spacings, wp,
} from '../styles/base.style';
import { font } from '../styles/mixins';
import notificationTypes from '../../constants/notificationsTypes';

const closeIcon = require('../../assets/icons/notification/close.png');

const ICONS = {
  [notificationTypes.ERROR]: require('../../assets/icons/notification/error.png'),
  [notificationTypes.INFO]: require('../../assets/icons/notification/info.png'),
  [notificationTypes.SUCCESS]: require('../../assets/icons/notification/success.png'),
};

const OPACITY = 0.95;
const CLOSE_HIT_SLOP = 10;

const ICON_ONLY_CONTAINER_SIZE = 30;
const ICON_WIDTH = 19;
const ICON_HEIGHT = 17;
const styles = StyleSheet.create({
  container: {
    marginTop: spacings.xs,
    marginHorizontal: spacings.xs,
    padding: spacings.sm,
    flex: 1,
    borderRadius: 15,
    ...Platform.select({
      web: {
        width: wp(98),
        marginHorizontal: 'auto',
      },
    }),
  },
  wrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  titleWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  textWrapper: {
    flex: 1,
  },
  title: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm, 0.2, colors.white),
    paddingRight: spacings.sm,
    flex: 1,
  },
  text: {
    marginTop: spacings.xs,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, 0, colors.white),
  },
  [`bg-${notificationTypes.ERROR}`]: {
    backgroundColor: `rgba(255, 148, 69, ${OPACITY})`,
  },
  [`bg-${notificationTypes.SUCCESS}`]: {
    backgroundColor: `rgba(71, 181, 146, ${OPACITY})`,
  },
  [`bg-${notificationTypes.INFO}`]: {
    backgroundColor: `rgba(229, 180, 19, ${OPACITY})`,
  },
  iconWrapper: {
    marginRight: spacings.sm,
  },
  iconOnlyContainer: {
    marginTop: spacings.xs + spacings.sm - ((ICON_ONLY_CONTAINER_SIZE - ICON_HEIGHT) / 2),
    marginLeft: spacings.xs + spacings.sm - ((ICON_ONLY_CONTAINER_SIZE - ICON_WIDTH) / 2),
    width: ICON_ONLY_CONTAINER_SIZE,
    height: ICON_ONLY_CONTAINER_SIZE,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    width: 13,
    height: 13,
  },
});

// PureComponent prevents the animation
class NotificationBox extends Component {
  customLayout = {
    duration: 200,
    delete: {
      type: LayoutAnimation.Types.linear,
      property: LayoutAnimation.Properties.opacity,
    },
  };

  constructor(props) {
    super(props);
    // eslint-disable-next-line no-unused-expressions
    UIManager.setLayoutAnimationEnabledExperimental
    && UIManager.setLayoutAnimationEnabledExperimental(true);
  }

  componentDidUpdate() {
    LayoutAnimation.configureNext(this.customLayout);
  }

  renderIcon = (type) => <Image source={ICONS[type]} style={styles.icon} fadeDuration={0} />;

  renderFullNotification = (type, title, closeHandler, id, text) => (
    <View style={[styles.container, styles[`bg-${type}`]]}>
      <View style={[styles.wrapper]}>
        <View style={[styles.iconWrapper]}>
          {this.renderIcon(type)}
        </View>

        <View style={[styles.textWrapper]}>
          <View style={[styles.titleWrapper]}>
            <Text style={styles.title}>{title}</Text>
            <TouchableOpacity
              activeOpacity={0.9}
              style={styles.closeWrapper}
              onPress={() => {
                closeHandler(id);
              }}
              hitSlop={{
                top: CLOSE_HIT_SLOP, bottom: CLOSE_HIT_SLOP, left: CLOSE_HIT_SLOP, right: CLOSE_HIT_SLOP,
              }}
            >
              <Image source={closeIcon} style={styles.close} fadeDuration={0} />
            </TouchableOpacity>
          </View>
          {!!text && <Text style={styles.text}>{text}</Text>}
        </View>
      </View>

    </View>
  );

  renderIconNotification = (type, id, reviveHandler) => (
    <TouchableOpacity
      activeOpacity={0.9}
      style={[styles.iconOnlyContainer, styles[`bg-${type}`]]}
      onPress={() => {
        reviveHandler(id);
      }}
      hitSlop={{
        top: CLOSE_HIT_SLOP, bottom: CLOSE_HIT_SLOP, left: CLOSE_HIT_SLOP, right: CLOSE_HIT_SLOP,
      }}
    >
      {this.renderIcon(type)}
    </TouchableOpacity>
  );

  render() {
    const {
      id, type, title, text, minimized, closeHandler, reviveHandler,
    } = this.props;
    return minimized ? this.renderIconNotification(type, id, reviveHandler)
      : this.renderFullNotification(type, title, closeHandler, id, text);
  }
}

NotificationBox.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  reviveHandler: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(notificationTypes)).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  minimized: PropTypes.bool.isRequired,
};

NotificationBox.defaultProps = {
  text: '',
};

export { NotificationBox };
