import React from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet } from 'react-native';
import StickyButtonsWrapper from '../StickyButtonsWrapper';
import ViewportController from '../ViewportController';

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ModalFooter = ({
  children,
}) => (
  <StickyButtonsWrapper>
    <ViewportController safeAreaBottom={Platform.OS === 'ios'} noFlex style={styles.wrapper}>
      {children}
    </ViewportController>
  </StickyButtonsWrapper>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;
