import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import svMomentLocale from 'moment/locale/sv';

// this is needed by moment-duration-format plugin
const svDurationLocale = {
  durationLabelsStandard: {
    s: 'sekunder',
    ss: 'sekunder',
    m: 'minut',
    mm: 'minuter',
    h: 'timme',
    hh: 'timmar',
    d: 'dag',
    dd: 'dagar',
    w: 'week',
    ww: 'weeks',
    M: 'månad',
    MM: 'månader',
    y: 'år',
    yy: 'år',
  },
};

moment.updateLocale('sv', svDurationLocale);

export default {};
