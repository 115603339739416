/* eslint-disable no-param-reassign */
import { fromJS, List, Map } from 'immutable';
import {
  CANDIDATE_CHAT_DATA_REQUEST_SUCCESS,
  CANDIDATE_CHATS_INFO_REQUEST_SUCCESS,
  CHAT_NEW_MESSAGE,
  CHAT_SAVE_LAST_MESSAGE_READ,
  CHAT_SET_MESSAGES,
  CHAT_SUBSCRIPTIONS_RECEIVED,
  CHAT_UPDATED,
  COMPANY_CHAT_DATA_REQUEST_SUCCESS,
  COMPANY_CHATS_REQUEST_SUCCESS,
  COMPANY_GIG_CHATS_DETAILS_REQUEST_SUCCESS,
  USER_PRESENCE_UPDATED,
} from '../../constants/actionTypes';
import {
  reduceCandidateChats, sortCandidateChats, updateLastMessageOnCandidateChatList, updateUnreadOnCandidateChatList,
} from './candidateChatReducerHelper';
import {
  reduceCandidates,
  sortGigChats,
  sortGigList,
  updateLastMessageOnGigChat,
  updateLastMessageOnGigList,
  updateUnreadOnGigAndGigCandidateChat,
  updateUnreadOnGigChat,
  updateUnreadOnGigChatList,
  updateUnreadOnGigChats, updateUnreadOnGigChatsMap,
} from './companyChatReducerHelper';

export const INITIAL_CHAT_STATE = {
  // { gigId: {candidateId: {chatId, gigName, gigPosition}}} - data needed to display a single chat
  gigsChatData: Map(),
  // {chatId: [messages]} - messages of single chat
  chatsMessages: Map(),
  // {chatId: timestamp} - stores local interactions with the chat
  chatsLastMessageReadTimestamps: Map(),
  // [{gigData, candidateChats: [] }] - list of company gigs with basic chats data inside
  companyGigs: List(),
  // { gigId: { candidateChats: [] }} - gig's chats with full candidate information
  companyGigsChats: Map(),
  // {chatId: bool} - map of unread chats. some of them might not be present on gigs list
  chatsUnread: Map(),
  // [{candidateChat}] - list of candidate chats inside
  candidateChats: List(),
  usersPresence: Map(),
};

/**
 * find unread messages on startup based on subscriptions taken directly from chat;
 * we don't know the number of messages in chat so we may indicate chats with 0 messages as unread in this map
 */
function mapSubscriptionsToUnread(subscriptions) {
  return subscriptions.reduce((map, subscription) => {
    if (subscription.ls) {
      map[subscription.rid] = subscription.alert;
    } else {
      // must be a chat user never opened
      map[subscription.rid] = true;
    }
    return map;
  }, {});
}

/**
 * Compares update date from chat server with the locally stored date
 */
function isChatUnreadAfterUpdate(updateDate, lastMessageReadTimestamp) {
  if (!lastMessageReadTimestamp) {
    return true;
  }
  return lastMessageReadTimestamp < updateDate;
}

function insertOrUpdate(list, value, keyProperty) {
  const valueIndex = list.findIndex((msg) => msg.get(keyProperty) === value[keyProperty]);
  return valueIndex > -1 ? list.set(valueIndex, fromJS(value)) : list.insert(0, fromJS(value));
}

function findGigIndex(state, gigId) {
  const companyGigs = state.get('companyGigs');
  return companyGigs.findIndex((gig) => gig.get('gigId') === gigId);
}

function findGigChatsCandidateIndex(state, gigId, candidateId) {
  const gigChats = state.get('companyGigsChats').get(gigId);
  if (!gigChats) {
    return -1;
  }
  return gigChats.findIndex((chat) => chat.get('candidateId') === candidateId);
}

export default function chatReducer(state = new Map(INITIAL_CHAT_STATE), action) {
  switch (action.type) {
    case CANDIDATE_CHAT_DATA_REQUEST_SUCCESS:
      return state.setIn(['gigsChatData', action.meta.previousAction.gigId], fromJS(action.payload));
    case COMPANY_CHAT_DATA_REQUEST_SUCCESS: {
      const { gigId, candidateId } = action.meta.previousAction;
      const updatedState = state.setIn(['gigsChatData', gigId, candidateId], fromJS(action.payload));
      const gigChatsCandidateIndex = findGigChatsCandidateIndex(state, gigId, candidateId);
      if (gigChatsCandidateIndex === -1) {
        return updatedState;
      }
      return updatedState.setIn(['companyGigsChats', gigId, gigChatsCandidateIndex, 'companyHasAccess'], action.payload.companyHasAccess);
    }
    case COMPANY_CHATS_REQUEST_SUCCESS: {
      const gigsChats = fromJS(action.payload.chats);
      const sortEnrichedGigList = sortGigList(updateUnreadOnGigChatList(gigsChats, state.get('chatsUnread')));
      return state.set('companyGigs', sortEnrichedGigList);
    }
    case COMPANY_GIG_CHATS_DETAILS_REQUEST_SUCCESS: {
      const { chat: { gig, candidates } } = action.payload;
      const { gigId } = action.meta.previousAction;
      const gigIndex = findGigIndex(state, gigId);

      if (gig === undefined) {
        return state
          .deleteIn(['companyGigs', gigIndex])
          .deleteIn(['companyGigsChats', gigId])
          .deleteIn(['gigsChatData', gigId])
          .set('usersPresence', fromJS(action.payload.usersPresence));
      }

      const chatsUnread = state.get('chatsUnread');
      const updatedGigChat = updateUnreadOnGigAndGigCandidateChat(fromJS(gig), chatsUnread);

      let companyGigs = state.get('companyGigs');
      if (gigIndex !== -1) {
        companyGigs = companyGigs.set(gigIndex, updatedGigChat);
      } else {
        companyGigs = companyGigs.push(updatedGigChat);
      }

      const gigChats = updateUnreadOnGigChats(fromJS(candidates), chatsUnread);
      const updatedGigChatsData = reduceCandidates(candidates, gig.name, gig.position);

      return state
        .set('companyGigs', sortGigList(companyGigs))
        .setIn(['companyGigsChats', gigId], sortGigChats(gigChats))
        .setIn(['gigsChatData', gigId], fromJS(updatedGigChatsData))
        .set('usersPresence', fromJS(action.payload.usersPresence));
    }
    case CANDIDATE_CHATS_INFO_REQUEST_SUCCESS: {
      const chats = fromJS(action.payload.chats);
      return state
        .set('candidateChats', sortCandidateChats(updateUnreadOnCandidateChatList(chats, state.get('chatsUnread'))))
        .set('gigsChatData', fromJS(reduceCandidateChats(action.payload.chats)))
        .set('usersPresence', fromJS(action.payload.usersPresence));
    }
    case CHAT_SET_MESSAGES:
      return state.setIn(['chatsMessages', action.chatId], fromJS(action.messages));
    case CHAT_NEW_MESSAGE:
      return state.setIn(['chatsMessages', action.chatId], insertOrUpdate(state.get('chatsMessages').get(action.chatId), action.message, '_id'));
    case CHAT_SUBSCRIPTIONS_RECEIVED: {
      const chatsUnread = fromJS(mapSubscriptionsToUnread(action.subscriptions));
      return state
        .set('chatsUnread', chatsUnread)
        .set('companyGigs', updateUnreadOnGigChatList(state.get('companyGigs'), chatsUnread))
        .set('companyGigsChats', updateUnreadOnGigChatsMap(state.get('companyGigsChats'), chatsUnread))
        .set('candidateChats', updateUnreadOnCandidateChatList(state.get('candidateChats'), chatsUnread));
    }
    case CHAT_SAVE_LAST_MESSAGE_READ: {
      const { gigId, chatId, timestamp } = action;

      const updatedChatsUnread = state.get('chatsUnread').set(chatId, false);

      return state.setIn(['chatsLastMessageReadTimestamps', chatId], timestamp)
        .set('chatsUnread', updatedChatsUnread)
        .set('companyGigs', updateUnreadOnGigChat(state.get('companyGigs'), updatedChatsUnread, gigId))
        .updateIn(['companyGigsChats', gigId],
          (companyGigChats) => (companyGigChats ? sortGigChats(updateUnreadOnGigChats(companyGigChats, updatedChatsUnread)) : undefined))
        .set('candidateChats', updateUnreadOnCandidateChatList(state.get('candidateChats'), updatedChatsUnread));
    }
    case CHAT_UPDATED: {
      const {
        gigId, chatId, updatedDate, msg,
      } = action;

      const chatUnreadAfterUpdate = isChatUnreadAfterUpdate(updatedDate, state.get('chatsLastMessageReadTimestamps').get(chatId));
      const updatedChatsUnread = state.get('chatsUnread').set(chatId, chatUnreadAfterUpdate);

      let companyGigs = state.get('companyGigs');
      companyGigs = updateUnreadOnGigChat(companyGigs, updatedChatsUnread, gigId);
      companyGigs = updateLastMessageOnGigList(companyGigs, gigId, chatId, msg, updatedDate);
      companyGigs = sortGigList(companyGigs);

      let companyGigChats = state.get('companyGigsChats').get(gigId);
      if (companyGigChats) {
        companyGigChats = updateUnreadOnGigChats(companyGigChats, updatedChatsUnread, gigId);
        companyGigChats = updateLastMessageOnGigChat(companyGigChats, chatId, msg, updatedDate);
        companyGigChats = sortGigChats(companyGigChats);
      }

      let candidateChats = state.get('candidateChats');
      candidateChats = updateUnreadOnCandidateChatList(candidateChats, updatedChatsUnread);
      candidateChats = updateLastMessageOnCandidateChatList(candidateChats, chatId, msg, updatedDate);
      candidateChats = sortCandidateChats(candidateChats);

      let updatedState = state
        .set('chatsUnread', updatedChatsUnread)
        .set('companyGigs', companyGigs)
        .set('candidateChats', candidateChats);

      if (companyGigChats) {
        updatedState = updatedState.setIn(['companyGigsChats', gigId], companyGigChats);
      }
      return updatedState;
    }
    case USER_PRESENCE_UPDATED: {
      const {
        userId, online,
      } = action;
      return state.set('usersPresence', state.get('usersPresence').set(userId, online));
    }
    default:
      return state;
  }
}
