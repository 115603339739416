import React from 'react';
import { Text } from 'react-native';
import Container from '../../common/components/Container';
import { LinkButton } from '../../common/components';
import { ADMIN_NOTIFICATION_NEW } from '../../constants/routes';

function AdminFindCandidatesContainer() {
  return (
    <Container>
      <LinkButton to={ADMIN_NOTIFICATION_NEW} title="New push" />
      <Text>Notifications history TODO</Text>
    </Container>
  );
}

export default AdminFindCandidatesContainer;
