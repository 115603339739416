import { Map } from 'immutable';
import { CHAT_CREDENTIALS_REQUEST_SUCCESS } from '../../constants/actionTypes';

const INITIAL_CHAT_STATE = {
  chatUserId: undefined,
  chatToken: undefined,
};

export default function chatAuthReducer(state = new Map(INITIAL_CHAT_STATE), action) {
  switch (action.type) {
    case CHAT_CREDENTIALS_REQUEST_SUCCESS: {
      const { authToken, userId } = action.payload;
      return state.set('chatUserId', userId).set('chatToken', authToken);
    }
    default:
      return state;
  }
}
