import queryString from 'query-string';
import { ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST, ADMIN_CANDIDATES_FIND_FILTER_FORM_SUBMIT } from '../../constants/actionTypes';

export const CANDIDATES_LIMIT = 10;

export function adminFindCandidatesChangeForm(formValues) {
  return {
    type: ADMIN_CANDIDATES_FIND_FILTER_FORM_SUBMIT,
    formValues,
  };
}

export function adminFindCandidates(params) {
  return {
    type: ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/candidate',
        params: {
          offset: 0,
          limit: CANDIDATES_LIMIT,
          ...params,
        },
        paramsSerializer: (p) => queryString.stringify(p),
      },
    },
  };
}
