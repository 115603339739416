import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../../core/routing/index';
import PaymentCardScreen from './PaymentCardScreen';
import { getBillingProfile, saveCard, subscribe } from '../companyPaymentsActions';
import {
  COMPANY_PAYMENTS_BILLING_PROFILE_NEW,
  COMPANY_PAYMENTS_CARD_NEW,
  COMPANY_PAYMENTS_OVERVIEW,
  COMPANY_PAYMENTS_PRICING_PLANS_INITIAL,
} from '../../../constants/routes';
import { goTo } from '../../../common/history/historyUtil';
import { successNotification, infoNotification } from '../../../common/notifications_center/notificationCenterActions';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../constants/subscriptionTypes';

const { withRouter } = Routing;

class PaymentCardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorCode: undefined, showActivityIndicator: false };
    this.newCard = this.props.location.pathname === COMPANY_PAYMENTS_CARD_NEW;
  }

  onSaveCardSuccess() {
    if (this.newCard) {
      this.props.getBillingProfile().then(({ isError: billingError, payload: billingPayload }) => {
        if (!billingError) {
          if (!billingPayload || !billingPayload.address) {
            this.props.history.replace(COMPANY_PAYMENTS_BILLING_PROFILE_NEW);
          } else {
            this.props.subscribe(this.props.selectedPricingPlanId).then(({ isError, payload }) => {
              if (!isError) {
                if (payload.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE) {
                  this.props.successNotification('company.payments.subscription.subscribe.successTitle');
                } else {
                  this.props.infoNotification(
                    'company.payments.subscription.subscribe.pendingTitle',
                    'company.payments.subscription.subscribe.pendingText',
                  );
                }
                goTo(this.props.history, COMPANY_PAYMENTS_OVERVIEW, true);
              }
            });
          }
        }
      });
    } else {
      goTo(this.props.history, COMPANY_PAYMENTS_OVERVIEW, true);
    }
  }

  handleWebviewAction = (event) => {
    const { trigger, cardToken } = Platform.OS === 'web' ? event : JSON.parse(event.nativeEvent.data);
    if (trigger === 'BEFORE') {
      this.setState({ showActivityIndicator: true });
    } else if (trigger === 'ERROR') {
      this.setState({ showActivityIndicator: false });
    } else if (trigger === 'AFTER') {
      this.props.saveCard({ cardToken }).then((response) => {
        if (!response.isError) {
          this.onSaveCardSuccess();
        } else if (response.errors.type === 'stripe_error') {
          this.setState({ errorCode: response.errors.message, showActivityIndicator: false });
        } else {
          this.setState({ showActivityIndicator: false });
        }
      });
    }
  };

  goBack = () => {
    if (this.newCard) {
      this.props.history.replace(COMPANY_PAYMENTS_PRICING_PLANS_INITIAL);
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <PaymentCardScreen
        handleBack={this.goBack}
        handleWebviewAction={this.handleWebviewAction}
        error={this.state.errorCode}
        cardNew={this.newCard}
        showActivityIndicator={this.state.showActivityIndicator}
      />
    );
  }
}

PaymentCardContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  saveCard: PropTypes.func.isRequired,
  getBillingProfile: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
  selectedPricingPlanId: PropTypes.string.isRequired,
  successNotification: PropTypes.func.isRequired,
  infoNotification: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PaymentCardContainer.defaultProps = {
  location: undefined,
};

const mapStateToProps = (state) => ({
  selectedPricingPlanId: state.company.get('payments').get('selectedPricingPlanId'),
});

export default withRouter(connect(mapStateToProps, {
  saveCard,
  getBillingProfile,
  subscribe,
  successNotification,
  infoNotification,
})(PaymentCardContainer));
