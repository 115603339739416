import { StyleSheet } from 'react-native';
import { font } from '../../../../common/styles/mixins';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../../common/styles/base.style';

export const HEADER_FIRST_LINE_HEIGHT = fontSizes.xl * 2 + spacings.md;
export const DESCRIPTION_LINE_HEIGHT = 20;
export const BOX_BORDER_RADIUS = 17;
export const BOX_MARGIN_TOP = spacings.sm;
export const BOX_MARGIN_HORIZONTAL = spacings.md;
const styles = StyleSheet.create({
  box: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: BOX_MARGIN_TOP,
    paddingVertical: spacings.sm,
    marginHorizontal: spacings.md,
    paddingHorizontal: spacings.md,
    borderRadius: BOX_BORDER_RADIUS,
    backgroundColor: colors.white,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerFirstLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: HEADER_FIRST_LINE_HEIGHT,
  },
  positionContainer: {
    flex: 1,
    paddingRight: spacings.md,
  },
  position: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, undefined, colors.greyishBrown),
    textAlign: 'left',
  },
  headerGigImage: {
    resizeMode: 'contain',
    maxHeight: HEADER_FIRST_LINE_HEIGHT,
    top: 1,
  },
  companyName: {
    color: colors.gondola,
    fontSize: fontSizes.xs,
    fontFamily: fontFamily.SFProDisplayBold,
  },
  gigConditionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  compensation: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.xs, undefined, colors.magenta),
  },
  weeklyWorkload: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.xs, undefined, colors.gondola),
  },
  footerContainer: {
    marginTop: spacings.sm,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  description: {
    fontSize: fontSizes.sm,
    color: colors.gondola,
    fontFamily: fontFamily.SFProDisplayMedium,
    marginTop: spacings.xs,
    lineHeight: DESCRIPTION_LINE_HEIGHT,
  },
  locationContainer: {
    flexDirection: 'row',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  locationIcon: {
    width: 10,
    height: 15,
    marginRight: spacings.xxs,
  },
  location: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.xxxs, undefined, colors.gondola),
  },
  expiryDate: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.xxxs, undefined, colors.gondola),
  },
  strikethrough: {
    borderColor: colors.seashell,
    borderWidth: StyleSheet.hairlineWidth,
    marginTop: spacings.sm,
  },

});

export default styles;
