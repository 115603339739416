import React from 'react';
import Routing from '../../../core/routing/index';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';
import {
  CANDIDATE_CREATE_PROFILE,
  CANDIDATE_CREATE_PROFILE_CATEGORY,
  CANDIDATE_CREATE_PROFILE_WELCOME,
  CANDIDATE_CREATE_PROFILE_AVAILABILITY,
  CANDIDATE_CREATE_PROFILE_EXPERIENCE,
  CANDIDATE_CREATE_PROFILE_EDUCATION,
  CANDIDATE_CREATE_PROFILE_SUCCESS_REQUIRED_STEPS,
  CANDIDATE_CREATE_PROFILE_CURRENT_OCCUPATION,
  CANDIDATE_CREATE_PROFILE_LANGUAGES,
  CANDIDATE_CREATE_PROFILE_GIG_LOCATION,
  CANDIDATE_CREATE_PROFILE_HOME_LOCATION,
} from '../../../constants/routes';
import { CANDIDATE_USER } from '../../../constants/userScopes';
import ProfileCreatorWelcome from './ProfileCreatorWelcome';
import ProfileCreatorStepAvailabilityContainer from './steps/availability/ProfileCreatorStepAvailabilityContainer';
import ProfileCreatorStepExperienceContainer from './steps/experience/ProfileCreatorStepExperienceContainer';
import ProfileCreatorStepEducationContainer from './steps/education/ProfileCreatorStepEducationContainer';
import ProfileCreatorSuccessRequiredSteps from './ProfileCreatorSuccessRequiredSteps';
import ProfileCreatorStepLocationContainer from './steps/location/ProfileCreatorStepLocationContainer';
import ProfileCreatorStepCategoryContainer from './steps/category/ProfileCreatorStepCategoryContainer';
import ProfileCreatorStepLanguagesContainer from './steps/languages/ProfileCreatorStepLanguagesContainer';
import ViewportController from '../../../common/components/ViewportController';
import ProfileCreatorStepCurrentOccupationContainer from './steps/currentOccupation/ProfileCreatorStepCurrentOccupationContainer';
import ProfileCreatorStepHomeLocationContainer from './steps/homeLocation/ProfileCreatorStepHomeLocationContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = CANDIDATE_CREATE_PROFILE_WELCOME;

const ProfileCreatorRouting = () => (
  <ViewportController safeAreaBottom>
    <Route exact path={CANDIDATE_CREATE_PROFILE} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_WELCOME} component={ProfileCreatorWelcome} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_CURRENT_OCCUPATION} component={ProfileCreatorStepCurrentOccupationContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_CATEGORY} component={ProfileCreatorStepCategoryContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_GIG_LOCATION} component={ProfileCreatorStepLocationContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_HOME_LOCATION} component={ProfileCreatorStepHomeLocationContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_AVAILABILITY} component={ProfileCreatorStepAvailabilityContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_LANGUAGES} component={ProfileCreatorStepLanguagesContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_EXPERIENCE} component={ProfileCreatorStepExperienceContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_EDUCATION} component={ProfileCreatorStepEducationContainer} />
    <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CREATE_PROFILE_SUCCESS_REQUIRED_STEPS} component={ProfileCreatorSuccessRequiredSteps} />
  </ViewportController>
);

export default ProfileCreatorRouting;
