import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { USER_EMAIL_MAX_LENGTH, USER_PASSWORD_MAX_LENGTH, loginValidation } from './LoginValidation';
import FormikWithValidation from '../components/form/FormikWithValidation';
import { LinkButton } from '../components/index';
import FormikFieldPrimaryTextInput from '../components/form/FormikFieldPrimaryTextInput';
import GeneralFormError from '../components/form/GeneralFormError';
import { FORGOT_PASSWORD } from '../../constants/routes';
import Wrapper from '../components/Wrapper';
import SessionTimedOutText from './SessionTimedOutText';
import AutoScrollableKeyboardAwareScrollView from '../keyboard/AutoScrollableKeyboardAwareScrollView';
import FormikFieldPrimaryInputTextNextSupport from '../components/form/FormikFieldPrimaryInputTextNextSupport';
import Routing from '../../core/routing/index';
import { colors } from '../styles/base.style';
import ScreenHeader from '../components/header/ScreenHeader';
import Container from '../components/Container';

const { withRouter } = Routing;

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      translate, onSubmit, onBack, sessionTimedOut, redirect,
    } = this.props;

    return (
      <Container>
        <ScreenHeader showBackArrow title={translate('loginScreen.header')} handleBack={onBack} />
        <FormikWithValidation
          validation={loginValidation}
          initialValues={{ email: '', password: '' }}
          onSubmit={onSubmit}
          render={({ handleSubmit, isBtnDisabled, errors }) => (
            <AutoScrollableKeyboardAwareScrollView>
              <Wrapper style={{ marginTop: 20 }}>
                {sessionTimedOut && <SessionTimedOutText />}
                <FormikFieldPrimaryInputTextNextSupport
                  innerRef={(ref) => !this.state.emailInputRef && this.setState({ emailInputRef: ref })}
                  name="email"
                  autoCapitalize="none"
                  label={translate('generic.emailLabel')}
                  keyboardType="email-address"
                  maxLength={USER_EMAIL_MAX_LENGTH}
                  nextComponent={this.state.passwordInputRef}
                  autoscroll
                  autoFocus
                />
                <FormikFieldPrimaryTextInput
                  innerRef={(ref) => !this.state.passwordInputRef && this.setState({ passwordInputRef: ref })}
                  name="password"
                  label={translate('generic.passwordLabel')}
                  secureTextEntry
                  showPasswordBtn
                  autoCapitalize="none"
                  maxLength={USER_PASSWORD_MAX_LENGTH}
                  returnKeyType="go"
                  onSubmitEditing={handleSubmit}
                  autoscroll
                />
                <GeneralFormError errors={errors} />
                <LinkButton
                  to={this.props.redirectTo}
                  title={translate('generic.loginButton')}
                  doRedirect={redirect}
                  disabled={isBtnDisabled}
                  onPress={handleSubmit}
                  margin={false}
                  style={{ wrapper: { marginBottom: 8 } }}
                />
                <LinkButton
                  to={FORGOT_PASSWORD}
                  type="text"
                  size="md"
                  title={translate('loginScreen.forgottenPassword')}
                  style={{ text: { color: colors.brownishGrey } }}
                />
              </Wrapper>
            </AutoScrollableKeyboardAwareScrollView>
          )}
        />
      </Container>
    );
  }
}

LoginScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  sessionTimedOut: PropTypes.bool.isRequired,
  redirect: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string,
};

LoginScreen.defaultProps = {
  redirectTo: '',
};

export default withLocalize(withRouter(LoginScreen));
