import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ScrollView, StyleSheet, View,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ProfileInfo, { renderAdminInfo } from '../../../common/components/ProfileInfo';
import { activeLanguagePropTypes, candidateProfilePropType } from '../../../constants/propTypes';
import Wrapper from '../../../common/components/Wrapper';
import Container from '../../../common/components/Container';
import { spacings } from '../../../common/styles/base.style';

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: spacings.md,
  },
  container: {
    display: 'flex', alignItems: 'center',
  },
});

const AdminProfileDetailsView = (props) => {
  const {
    profile, profileInfoProps, stickyFooter, online, children,
  } = props;

  return (
    <Container>
      <ScrollView style={{ flex: 1 }}>
        <View style={[styles.container]}>
          <Wrapper noFlex>
            <ProfileInfo
              profile={profile}
              lastNameOneLetter={false}
              renderExtraInfo={renderAdminInfo}
              online={online}
              {...profileInfoProps}
            />
          </Wrapper>

        </View>
        {children}
      </ScrollView>
      { stickyFooter && stickyFooter() }
    </Container>
  );
};

AdminProfileDetailsView.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  userScope: PropTypes.string,
  profile: candidateProfilePropType,
  renderExtraInfo: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  profileInfoProps: PropTypes.object,
  stickyFooter: PropTypes.func,
  online: PropTypes.bool,
  children: PropTypes.node,
  availability: PropTypes.node,
};

AdminProfileDetailsView.defaultProps = {
  profile: undefined,
  renderExtraInfo: undefined,
  profileInfoProps: {},
  stickyFooter: undefined,
  userScope: undefined,
  online: null,
  children: null,
  availability: null,
};

const mapStateToProps = (state) => ({
  userScope: state.auth.get('userScope'),
});

export default connect(mapStateToProps, null)(withLocalize(AdminProfileDetailsView));
