import { Map } from 'immutable';
import { CONFIG_GET_VALUE_REQUEST_SUCCESS } from '../../constants/actionTypes';

export default function gigReducer(state = Map({}), action) {
  switch (action.type) {
    case CONFIG_GET_VALUE_REQUEST_SUCCESS:
      return state.set(action.meta.previousAction.payload.key, action.payload);
    default:
      return state;
  }
}
