import {
  CANDIDATE_CHAT_DATA_REQUEST,
  CANDIDATE_CHATS_INFO_REQUEST,
} from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export function createOrGetCandidateChat(gigId) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.CANDIDATE_OPEN_CHAT);
  return {
    type: CANDIDATE_CHAT_DATA_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/candidate/chat',
        data: { gigId },
      },
    },
    gigId,
  };
}

export function getCandidateChatsInfo(handleInternalError = true) {
  return {
    type: CANDIDATE_CHATS_INFO_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/candidate/chat/info',
      },
    },
    handleInternalError,
  };
}
