import React from 'react';
import PropTypes from 'prop-types';
import { Linking, Platform, StyleSheet } from 'react-native';
import { Button } from '../index';
import { BUTTON_ALIGN, BUTTON_TYPES } from './Button';
import { colors } from '../../styles/base.style';
import { stylePropTypes } from '../../../constants/propTypes';
import { Alert } from '../alert/Alert';

const styles = StyleSheet.create({
  linkColor: {
    color: colors.greyishBrown,
  },
});

function ExternalLink({
  title, linkUrl, style, newTab, fallbackAlertText,
}) {
  const openLink = () => {
    if (Platform.OS === 'web' && newTab) {
      window.open(linkUrl, '_blank');
    } else {
      const result = Linking.openURL(linkUrl);
      result.catch(() => {
        Alert.alert('', fallbackAlertText || linkUrl);
      });
    }
  };

  return (
    <Button
      type={BUTTON_TYPES.TEXT}
      alignText={BUTTON_ALIGN.LEFT}
      style={{ text: [styles.linkColor, style.text], wrapper: style.wrapper }}
      title={title}
      onPress={openLink}
    />
  );
}

ExternalLink.propTypes = {
  title: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  style: PropTypes.shape({
    wrapper: stylePropTypes,
    text: stylePropTypes,
  }),
  newTab: PropTypes.bool,
  fallbackAlertText: PropTypes.string,
};

ExternalLink.defaultProps = {
  style: {
    wrapper: null,
    text: null,
  },
  newTab: false,
  fallbackAlertText: null,
};

export default ExternalLink;
