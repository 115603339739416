import { isFutureDateIgnoreTime } from '../../../../core/validation/validators';
import { getUtcOffsetForGigLocation } from '../../../../utilities/dateFormatter';

export default [
  {
    field: 'startDate',
    custom: (value, args, data) => {
      if (data.noStartDate || !value) {
        return true;
      }
      return isFutureDateIgnoreTime(value, getUtcOffsetForGigLocation());
    },
    message: 'past_date',
  },
  {
    field: 'endDate',
    custom: (value, args, data) => {
      if (data.noEndDate || !value) {
        return true;
      }
      return isFutureDateIgnoreTime(value, getUtcOffsetForGigLocation());
    },
    message: 'past_date',
  },
  {
    field: 'endDate',
    custom: (value, args, data) => {
      if (data.startDate && data.endDate) {
        return (data.endDate < data.startDate);
      }
      return false;
    },
    validWhen: false,
    message: 'before_start_date',
  },
  {
    field: 'general',
    custom: (value, args, data) => !!((data.startDate || data.noStartDate) && (data.endDate || data.noEndDate)),

    validWhen: true,
    message: 'no_date',
  },
];
