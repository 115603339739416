import { List, Map } from 'immutable';
import {
  CANDIDATE_POOLS_SET_NEW_CONTENT,
  CANDIDATE_POOLS_CLEAR_NEW_CONTENT,
  CANDIDATE_POOLS_STORE_SHIFTS_SCROLL_POSITION,
} from '../../constants/actionTypes';

export const CANDIDATES_POOLS_CONTENT_FIELDS = {
  MY_POOLS: 'MY_POOLS',
  GROUPS: 'GROUPS',
  SHIFTS: 'SHIFTS',
};

const INITIAL_MAP = {
  shiftsScrollPosition: 0,
  newContent: Map({
    [CANDIDATES_POOLS_CONTENT_FIELDS.GROUPS]: List(),
    [CANDIDATES_POOLS_CONTENT_FIELDS.MY_POOLS]: List(),
    [CANDIDATES_POOLS_CONTENT_FIELDS.SHIFTS]: List(),
  }),
};

export default function candidateMyPoolsReducer(state = new Map(INITIAL_MAP), action) {
  switch (action.type) {
    case CANDIDATE_POOLS_SET_NEW_CONTENT: {
      const { field, value } = action;
      return state.setIn(['newContent', field], state.get('newContent').get(field).concat(value));
    }
    case CANDIDATE_POOLS_CLEAR_NEW_CONTENT:
      return state.setIn(['newContent', action.field], List());
    case CANDIDATE_POOLS_STORE_SHIFTS_SCROLL_POSITION:
      return state.set('shiftsScrollPosition', action.scrollPosition);
    default:
      return state;
  }
}
