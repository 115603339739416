import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import validation from './CandidateProfileCurrentOccupationFormValidation';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import { OCCUPATION } from '../../../../constants/occupation';
import FormikFieldSelectList from '../../../../common/components/form/FormikSelectList';
import Wrapper from '../../../../common/components/Wrapper';

const getCurrentOccupationsForCandidate = (translate) => Object.values(OCCUPATION)
  .filter((item) => item !== OCCUPATION.ALL)
  .map((val) => ({
    label: translate(`candidateProfile.currentOccupation.occupation.${val}`),
    value: val,
  }));

const validateCurrentOccupation = (selectedOccupations, setFieldValue) => {
  if (selectedOccupations.indexOf(OCCUPATION.SCHOOL_STUDENT) === selectedOccupations.length - 1) {
    setFieldValue('currentOccupation', selectedOccupations.filter((name) => name !== OCCUPATION.UNIVERSITY_STUDENT));
  } else if (selectedOccupations.indexOf(OCCUPATION.UNIVERSITY_STUDENT) === selectedOccupations.length - 1) {
    setFieldValue('currentOccupation', selectedOccupations.filter((name) => name !== OCCUPATION.SCHOOL_STUDENT));
  }
  if (selectedOccupations.indexOf(OCCUPATION.PART_TIME_WORKER) === selectedOccupations.length - 1) {
    setFieldValue('currentOccupation', selectedOccupations.filter((name) => name !== OCCUPATION.FULL_TIME_WORKER));
  } else if (selectedOccupations.indexOf(OCCUPATION.FULL_TIME_WORKER) === selectedOccupations.length - 1) {
    setFieldValue('currentOccupation', selectedOccupations.filter((name) => name !== OCCUPATION.PART_TIME_WORKER));
  }
};

const CandidateProfileCurrentOccupationForm = ({
  translate, renderFooter, selectedCurrentOccupation, handleSubmit,
}) => (
  <FormikWithValidation
    validation={validation}
    initialValues={{ currentOccupation: selectedCurrentOccupation }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, setFieldValue, isBtnDisabled }) => (
      <>
        <Wrapper>
          <FormikFieldSelectList
            items={getCurrentOccupationsForCandidate(translate)}
            name="currentOccupation"
            multiple
            scroll
            iconPosition="right"
            onValueChange={
              (val) => {
                validateCurrentOccupation(val, setFieldValue);
              }
            }
          />
        </Wrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfileCurrentOccupationForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  selectedCurrentOccupation: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withLocalize(CandidateProfileCurrentOccupationForm);
