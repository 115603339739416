import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import Routing from '../../../../core/routing/index';
import { gigPropType, subscriptionPropTypes } from '../../../../constants/propTypes';
import {
  COMPANY_GIG_PAYMENT_BILLING_PROFILE,
  COMPANY_PAYMENTS_PRICING_PLANS_INITIAL,
  COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE,
} from '../../../../constants/routes';
import { showConfirmAlert, showInformationAlert } from '../../../../common/components/alert/alerts';
import { infoNotification } from '../../../../common/notifications_center/notificationCenterActions';
import { SUBSCRIPTION_BILLING_ENUM, SUBSCRIPTION_STATUS_ENUM } from '../../../../constants/subscriptionTypes';
import { getGigCandidateProfile } from './companyGigCandidatesActions';
import PaymentPlansModal from '../../../payments/gigPayment/PaymentPlansModal';
import { getGig } from '../../companyGigActions';

const { withRouter } = Routing;

export const withGigCandidateAccessHandle = (component, replacePathOnRedirectToPricingPlans = false) => {
  class DecoratingComponent extends React.Component {
    constructor(props) {
      super(props);
      this.wrappedComponent = component;
      this.replacePathOnRedirectToPricingPlans = replacePathOnRedirectToPricingPlans;
      this.state = { showPaymentModal: false, selectedPaymentPlanType: undefined };
    }

    componentDidMount() {
      if (!this.props.gig) { this.props.getGig(this.props.match.params.gigId); }
    }

    billConfirmation = (onConfirm, onCancel) => {
      showConfirmAlert(
        this.props.translate,
        {
          confirmKey: 'companyGigs.candidates.billingContinue',
          cancelKey: 'generic.cancel',
          titleKey: 'companyGigs.candidates.billingMessageBoxTitle',
          textKey: 'companyGigs.candidates.billingMessageBoxText',
        },
        onConfirm,
        onCancel,
      );
    };

    redirectToPricingPlans = () => {
      let redirectFunc = this.props.history.push;
      if (this.replacePathOnRedirectToPricingPlans) {
        redirectFunc = this.props.history.replace;
      }
      if (this.props.companySubscription) {
        redirectFunc(COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE, { goBackTo: this.props.history.location });
      } else {
        redirectFunc(COMPANY_PAYMENTS_PRICING_PLANS_INITIAL, { goBackTo: this.props.history.location });
      }
    };

    redirectToBillingProfile = (gigId, paymentPlanType) => {
      let redirectFunc = this.props.history.push;
      if (this.replacePathOnRedirectToPricingPlans) {
        redirectFunc = this.props.history.replace;
      }
      redirectFunc(
        COMPANY_GIG_PAYMENT_BILLING_PROFILE.replace(':gigId', gigId).replace(':paymentPlanType', paymentPlanType),
        { goBackTo: this.props.history.location },
      );
    };

    selectPaymentPlan = (paymentPlanType) => {
      this.setState({ selectedPaymentPlanType: paymentPlanType });
    };

    handleBilling = async (gigId, candidateId, onSuccess, onCancel) => {
      const {
        grantedFeatures,
        companySubscription,
        gig,
      } = this.props;

      this.setState({ onCancel, gigId });

      if (!grantedFeatures.includes('PAYMENTS')) {
        onSuccess();
        return;
      }

      const candidateProfile = await this.props.getGigCandidateProfile(gigId, candidateId);
      const { companyHasAccess } = candidateProfile.payload;

      if (companyHasAccess === true) {
        onSuccess();
        return;
      }

      if (!gig) {
        return;
      }

      if (gig.gigId !== gigId) {
        infoNotification('generic.warning', 'generic.sww');
        return;
      }
      if (companySubscription && companySubscription.trial === true) {
        onSuccess();
        return;
      }
      if (!companySubscription
        || !companySubscription.status
        || [SUBSCRIPTION_STATUS_ENUM.CANCELED, SUBSCRIPTION_STATUS_ENUM.INCOMPLETE_EXPIRED].includes(companySubscription.status)) {
        if ((gig.payments.accessUntilDate && moment.utc(gig.payments.accessUntilDate).isAfter(moment().utc())) || gig.payments.unlimitedAccess) {
          onSuccess();
          return;
        }
        if (grantedFeatures.includes('PAYMENTS_PAY_FOR_GIG')) {
          this.showPaymentModal();
        } else {
          this.redirectToPricingPlans();
        }
        return;
      }

      if ([SUBSCRIPTION_STATUS_ENUM.INCOMPLETE, SUBSCRIPTION_STATUS_ENUM.UNPAID].includes(companySubscription.status)) {
        showInformationAlert(this.props.translate, {
          confirmKey: 'generic.close',
          titleKey: 'company.payments.subscription.paymentIncomplete.informationTitle',
          textKey: 'company.payments.subscription.paymentIncomplete.informationText',
        });
        onCancel();
        return;
      }
      if (companySubscription.billingType === SUBSCRIPTION_BILLING_ENUM.PAY_PER_USE) {
        this.billConfirmation(onSuccess, onCancel);
        return;
      }
      onSuccess();
    };

    showPaymentModal = () => {
      this.setState({ showPaymentModal: true });
    };

    closePaymentModal = () => {
      this.state.onCancel();
      this.setState({ showPaymentModal: false });
    };

    render = () => {
      const WrappedComponent = this.wrappedComponent;
      return (
        <>
          <WrappedComponent
            handleBilling={this.handleBilling}
            {...this.props}
          />
          <PaymentPlansModal
            visible={this.state.showPaymentModal}
            gigId={this.state.gigId}
            selectedPaymentPlanType={this.state.selectedPaymentPlanType}
            selectPaymentPlan={this.selectPaymentPlan}
            closeModal={this.closePaymentModal}
            onRedirectToPricingPlan={this.redirectToPricingPlans}
            redirectToBillingProfile={this.redirectToBillingProfile}
          />
        </>
      );
    }
  }

  DecoratingComponent.propTypes = {
    getGigCandidateProfile: PropTypes.func.isRequired,
    getGig: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    companySubscription: subscriptionPropTypes,
    grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    candidateId: PropTypes.string,
    gig: gigPropType,
    match: ReactRouterPropTypes.match.isRequired,
  };

  DecoratingComponent.defaultProps = {
    candidateId: undefined,
    companySubscription: null,
    gig: null,
  };

  const mapStateToProps = (state) => {
    const gigDataToPreview = state.companyGig.get('gigsList').get('gigDataToPreview');

    return ({
      companySubscription: state.userStatus.get('subscription'),
      grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
      gig: gigDataToPreview ? gigDataToPreview.toJS() : null,
    });
  };

  return withRouter(withLocalize(connect(mapStateToProps, { getGigCandidateProfile, getGig })(DecoratingComponent)));
};
