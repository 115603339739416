import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  View, TextInput, Text, TouchableWithoutFeedback, TouchableOpacity,
} from 'react-native';
import { Translate } from 'react-localize-redux';
import { color, colors } from '../../styles/base.style';
import { textInputStyle } from '../../styles/form.style';
import FormFieldError from './FormFieldError';
import FloatingFormLabel from './FloatingFormLabel';

// default fallback max length
const DEFAULT_MAX_LENGTH = 999;

class PrimaryTextInput extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hasFocus: false,
      showPassword: false,
    };
  }

  onChangeText = (val) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(val);
    }
  };

  onFocus = (event) => {
    if (!this.props.disabled) {
      this.setState({ hasFocus: true });
      if (this.props.onFocus) {
        this.props.onFocus(event);
      }
    }
  };

  onBlur = (event) => {
    this.setState({ hasFocus: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  getLength = () => (this.props.value ? this.props.value.toString().length : 0);

  countText = () => `${this.getLength()}/${this.props.maxLength}`;

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  renderShowPasswordBtn = () => {
    const { showPasswordBtn } = this.props;
    if (!showPasswordBtn) {
      return null;
    }

    return (
      <TouchableOpacity
        activeOpacity={1.0}
        style={textInputStyle.showPasswordBtn}
        onPress={this.togglePasswordVisibility}
        hitSlop={{
          top: 10, bottom: 10, left: 10, right: 10,
        }}
      >
        <Text style={textInputStyle.showPasswordBtnText}><Translate id={this.state.showPassword ? 'generic.hide' : 'generic.show'} /></Text>
      </TouchableOpacity>
    );
  };

  render = () => {
    const {
      counter, style, label, onPress, error, disabled, editable, innerRef, showPasswordBtn, ...props
    } = this.props;

    const textInputComponent = (
      <View style={[textInputStyle.inputBackgroundWrapper, style && style.inputBackgroundWrapper]}>
        { this.renderShowPasswordBtn() }
        <TextInput
          {...props}
          secureTextEntry={showPasswordBtn ? !!props.secureTextEntry && !this.state.showPassword : !!props.secureTextEntry}
          ref={innerRef}
          onChangeText={this.onChangeText}
          editable={editable !== undefined ? editable : !disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          style={[
            textInputStyle.input,
            style && style.input,
            this.state.hasFocus && textInputStyle.inputFocus,
            disabled && textInputStyle.inputDisabled,
          ]}
          numberOfLines={this.props.multiline ? this.props.numberOfLines : 1}
          placeholderTextColor={this.props.placeholderTextColor || colors.lightPeach}
          underlineColorAndroid="transparent"
        />
      </View>
    );

    const length = this.getLength();

    const labelComponent = label ? (
      <FloatingFormLabel
        label={label}
        style={[textInputStyle.label, style ? style.label : null, disabled && textInputStyle.inputDisabledLabel]}
        hasFocus={this.state.hasFocus}
        hasValue={!!(length > 0 || (this.props.placeholder && this.props.placeholder.length > 0))}
      />
    ) : null;

    return (
      <View style={[textInputStyle.container, label ? textInputStyle.containerForFloatingLabel : null, style && style.container]}>
        {labelComponent}
        {onPress
          ? (
            <TouchableWithoutFeedback
              style={{ display: 'flex' }}
              onPress={() => {
                if (onPress && !disabled) {
                  onPress();
                }
              }}
              testID={this.props.testID ? `${this.props.testID}Btn` : undefined}
            >
              {/* View is required only by TouchableWithoutFeedback */}
              <View style={textInputStyle.inputTouchableWrapper}>
                {textInputComponent}
              </View>
            </TouchableWithoutFeedback>
          )
          : textInputComponent}
        {counter
        && (
        <Text style={[textInputStyle.counter, style && style.counter]}>
          {this.countText()}
        </Text>
        )}
        <FormFieldError error={error} />
      </View>
    );
  };
}

PrimaryTextInput.propTypes = {
  counter: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  label: PropTypes.string,
  // {container: {}, label: {}, input: {}, counter: {}}
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  placeholder: PropTypes.string,
  placeholderTextColor: PropTypes.string,
  onChangeText: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  innerRef: PropTypes.func,
  testID: PropTypes.string,
  multiline: PropTypes.bool,
  numberOfLines: PropTypes.number,
  showPasswordBtn: PropTypes.bool,
};

PrimaryTextInput.defaultProps = {
  style: null,
  counter: false,
  disabled: false,
  editable: true,
  placeholder: color.inputPlaceholderText,
  placeholderTextColor: color.inputPlaceholderText,
  maxLength: DEFAULT_MAX_LENGTH,
  onChangeText: null,
  label: null,
  value: undefined,
  onPress: null,
  onFocus: null,
  onBlur: undefined,
  error: null,
  innerRef: undefined,
  testID: undefined,
  multiline: false,
  numberOfLines: 4,
  showPasswordBtn: false,
};

export default PrimaryTextInput;
