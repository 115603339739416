/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, Platform, ScrollView, KeyboardAvoidingView } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiftedChat, Bubble, MessageText } from 'react-native-gifted-chat';
import { List } from 'immutable';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { chatLoadMessages, chatNewMessage, chatSaveLastMessageRead, getChatCredentials } from './chatActions';
import * as chatClient from './chatClient';
import ScreenHeader from '../components/header/ScreenHeader';
import ImageComponent from '../components/image/ImageComponent';
import { colors, spacings, fontFamily, fontSizes, shadowBottom } from '../styles/base.style';
import Container from '../components/Container';
import { font } from '../styles/mixins';
import Wrapper from '../components/Wrapper';
import PrimaryText from '../components/PrimaryText';
import { activeLanguagePropTypes } from '../../constants/propTypes';
import ChatListItemProfileImage from '../components/chat/ChatListItemProfileImage';
import ViewportController from '../components/ViewportController';
import { Button } from '../components';
import PrimaryModal from '../components/modal/PrimaryModal';

const sendIcon = require('../../assets/icons/send/send.png');
const sendInactiveIcon = require('../../assets/icons/send/sendInactive.png');

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'flex-start',
    paddingLeft: spacings.xxxl,
    paddingBottom: spacings.sm,
    ...Platform.select({
      web: {
        paddingLeft: spacings.xxxxl,
        paddingBottom: spacings.lg,
      },
    }),
  },
  header: {
    marginBottom: 0,
    marginRight: 72,
  },
  subheader: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xs, 0.2, colors.brownGrey),
  },
  profileImageWrapper: {
    position: 'absolute',
    top: 4,
    right: 0,
    paddingTop: spacings.sm,
    zIndex: 100,
    paddingRight: spacings.sm,
    paddingBottom: 1,
    ...Platform.select({
      web: {
        paddingRight: spacings.lg,
        paddingTop: spacings.lg,
      },
    }),
  },
  profileImage: {
    height: 37,
    width: 37,
    borderRadius: 18,
  },
  rightBubble: {
    backgroundColor: colors.seablue,
  },
  leftMessageStyle: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.md, null, colors.greyishBrown),
  },
  rightMessageStyle: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.md, null, colors.white),
  },
  chatFooter: {
    flexDirection: 'row',
    backgroundColor: colors.smokeWhite,
    paddingHorizontal: spacings.sm,
    borderTopWidth: 1,
    borderTopColor: colors.veryLightGrey,
  },
  textInput: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.lg, null, colors.greyishBrown),
    flex: 1,
    backgroundColor: colors.smokeWhite,
    paddingLeft: spacings.xxs,
    paddingVertical: spacings.xxs,
    paddingBottom: 18,
    maxHeight: 150,
  },
});

const sendImageStyles = StyleSheet.create({
  wrapper: {
    paddingLeft: spacings.xs,
    paddingTop: spacings.xs,
    paddingBottom: spacings.sm,
  },
  image: {
    width: 24,
    height: 22,
  },
});

/* const modalStyles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
    alignItems: 'center',
  },
  modalText: {
    fontSize: 18,
    marginBottom: 20,
  },
  closeModalButton: {
    marginTop: 10,
    backgroundColor: 'blue',
    padding: 10,
    borderRadius: 5,
  },
  closeModalButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
}); */

// class CustomGiftedChat extends GiftedChat {
//   renderMessages() {
//     return (
//       <Container>
//         <MessageContainer
//           {...this.props}
//           invertibleScrollViewProps={this.invertibleScrollViewProps}
//           messages={this.getMessages()}
//           // eslint-disable-next-line no-underscore-dangle, no-return-assign
//           ref={(component) => this._messageContainerRef = component}
//         />
//         {this.renderChatFooter()}
//       </Container>
//     );
//   }
// }

const emailRegex = /[a-zA-Z0-9._%+-]*\s*@\s*[a-zA-Z0-9.-]*\s*\.\s*[a-zA-Z]{2,3}/g;

// const phoneRegex =
//   /(\+\d{0,2}\s?\d{1,4}[-\s]?)?(\(\d{1,4}\)|\d{1,4})\s?[-\s]?(\d{1,4})\s?[-\s]?(\d{1,4})/g;

const phoneRegex = /\(?\d{3}\)?[. -]? *\d{3}[. -]? *[. -]?\d{4}/g;

class GigChatView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',
      modalVisible: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    // chatClient.disconnect();
    // chatClient.connect();

    this.loginHandlerId = chatClient.addLoginHandler(this.onLogin);

    // if (!this.props.chatUserId) {
    this.props.getChatCredentials();
    // }
  }

  componentWillUnmount() {
    chatClient.removeLoginHandler(this.loginHandlerId);
    chatClient.unsubscribeChatMessages(this.chatMessagesSubscriptionId);
  }

  static getMessageDate(message) {
    return message._updatedAt.$date;
  }

  onLogin = () => {
    const { gigId, chatId } = this.props;

    this.chatMessagesSubscriptionId = chatClient.subscribeToChatMessages(chatId, this.onNewMessageListener);

    chatClient.loadChatHistory(chatId, (messages) => {
      chatClient.markChatAsRead(chatId);
      this.props.chatLoadMessages(chatId, messages);
      const lastMessage = messages[0];
      if (lastMessage) {
        this.props.chatSaveLastMessageRead(gigId, chatId, GigChatView.getMessageDate(lastMessage));
      }
    });
  };

  onNewMessageListener = (message) => {
    const { gigId, chatId } = this.props;
    this.props.chatNewMessage(chatId, message);
    this.props.chatSaveLastMessageRead(gigId, chatId, GigChatView.getMessageDate(message));
  };

  onSend = (messages = []) => {
    const { chatId } = this.props;
    messages.forEach((message) => {
      chatClient.sendMessage(message._id, chatId, message.text);
    });
    this.setState({ isLoading: true, inputText: '' });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 100);
  };

  // handleInputChange = (text) => {
  //   const sanitizedText = this.replaceMatches(text);
  //   this.setState({ inputText: sanitizedText });
  // };

  // replaceMatches = (text) => {
  //   // Replace email addresses with asterisks
  //   const textWithEmailsReplaced = text.replace(emailRegex, '*******');

  //   // Replace phone numbers with asterisks
  //   const textWithPhoneNumbersReplaced = textWithEmailsReplaced.replace(phoneRegex, '*******');

  //   return textWithPhoneNumbersReplaced;
  // };

  handleInputChange = (text) => {
    const sanitizedText = this.replaceMatches(text);

    this.setState({ inputText: sanitizedText }, () => {
      if (sanitizedText !== text) {
        // Show the modal when replacements are made
        this.showModal();
      }
    });

    return sanitizedText;
  };

  getPlaceholderMessage() {
    return {
      _id: 1,
      text: this.props.emptyChatPlaceholder,
      createdAt: new Date(),
      system: true,
    };
  }

  // Function to replace email and phone numbers
  replaceMatches = (text) => {
    // Replace email addresses with asterisks
    const textWithEmailsReplaced = text.replace(emailRegex, '*******');

    // Replace phone numbers with asterisks
    const textWithPhoneNumbersReplaced = textWithEmailsReplaced.replace(phoneRegex, '*******');

    return textWithPhoneNumbersReplaced;
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  openModal = () => {
    this.setState({ modalVisible: true });
  };

  // Function to show the modal
  showModal = () => {
    this.setState({ modalVisible: true });
  };

  // Function to hide the modal
  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  getChatMessages = () => {
    // when no loaded
    if (this.props.chatMessages == null) {
      return []; // TODO some spinner?
      // when loaded and no messages
    }
    if (this.props.chatMessages != null && !this.props.chatMessages.size) {
      return [this.getPlaceholderMessage()];
    }
    return this.props.chatMessages.map(this.mapMessage.bind(this)).toArray();
  };

  mapMessage = (message) => {
    const userId = message.get('u').get('_id');

    return {
      _id: message.get('_id'),
      text: message.get('msg'),
      createdAt: message.get('_updatedAt').get('$date'),
      user: {
        _id: userId,
        name: message.get('u').get('name'),
        avatar: this.props.chatUserId === userId ? null : this.props.otherUserProfileImageUri,
      },
    };
  };

  static renderNoMessages({ currentMessage }) {
    return (
      <Wrapper noFlex>
        <PrimaryText>{currentMessage.text}</PrimaryText>
      </Wrapper>
    );
  }

  static renderBubble(props) {
    return <Bubble {...props} wrapperStyle={{ right: styles.rightBubble }} />;
  }

  static renderMessageText(props) {
    return <MessageText {...props} textStyle={{ left: styles.leftMessageStyle, right: styles.rightMessageStyle }} />;
  }

  static renderInputToolbar(props) {
    return (
      <View style={styles.chatFooter}>
        {props.renderComposer(props)}
        {props.renderSend(props)}
      </View>
    );
  }

  // cham

  /* isEmail(input) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  }

  isMobileNumber(input) {
    const mobileRegex = /^[0-9]{10}$/; // Adjust the regex pattern as needed
    return mobileRegex.test(input);
  } */

  static renderComposer(props, text, handleInputChange, isVisible, closeModal, translate) {
    return (
      <ScrollView>
        <TextInput
          // value={props.text}
          // onChangeText={(text) => props.onTextChanged(text)}
          value={text}
          onChangeText={(e) => {
            const sanitized = handleInputChange(e);
            props.onTextChanged(sanitized);
          }}
          multiline
          style={[styles.textInput]}
          scrollEnabled
          placeholder={props.placeholder}
          placeholderTextColor={colors.lightPeach}
          enablesReturnKeyAutomatically
          underlineColorAndroid="transparent"
          // scrollEnabled={false}
          onKeyPress={({ key, shiftKey }) => {
            if (key === 'Enter' && !shiftKey && props?.text?.trim().length > 0) props.onSend({ text: props?.text?.trim() }, true);
          }}
        />
        <PrimaryModal visible={isVisible} onClose={closeModal}>
          {/* Modal Content */}
          <Container style={{ padding: 30, justifyContent: 'space-between' }}>
            <PrimaryText style={styles.regularText}>{translate('candidateChat.chatRestriction')}</PrimaryText>
            <Button title="OK" onPress={closeModal} />
          </Container>
        </PrimaryModal>
      </ScrollView>
    );
  }

  // static renderComposer(props) {
  //   const { text } = props;
  //   const isInputEmail = this.isEmail(text);
  //   const isInputMobile = this.isMobileNumber(text);

  //   let warningText = '';
  //   if (isInputEmail || isInputMobile) {
  //     warningText = "Warning: Do not exchange sensitive information in the chat!";
  //   }

  //   return (
  //     <ScrollView>
  //       <TextInput
  //         value={text}
  //         onChangeText={(newText) => props.onTextChanged(newText)}
  //         multiline
  //         style={styles.textInput}
  //         placeholder={props.placeholder}
  //         placeholderTextColor={colors.lightPeach}
  //         enablesReturnKeyAutomatically
  //         underlineColorAndroid="transparent"
  //         scrollEnabled={false}
  //         onKeyPress={({ key, shiftKey }) => {
  //           if (key === 'Enter' && !shiftKey && text.trim().length > 0) {
  //             props.onSend({ text: text.trim() }, true);
  //           }
  //         }}
  //       />
  //       <Text style={{ color: 'red' }}>{warningText}</Text>
  //     </ScrollView>
  //   );
  // }

  static renderSend(props) {
    return (
      <TouchableOpacity
        // style={{ paddingTop: 7 }}
        onPress={() => {
          if (props?.text?.trim().length > 0) props.onSend({ text: props?.text?.trim() }, true);
        }}
        accessibilityTraits="button"
      >
        <ImageComponent image={props?.text?.trim().length > 0 ? sendIcon : sendInactiveIcon} style={sendImageStyles} />
      </TouchableOpacity>
    );
  }

  renderSubHeader() {
    const { chatSubheader } = this.props;
    return <Text style={styles.subheader}>{chatSubheader}</Text>;
  }

  renderProfileImage() {
    const { profileImageOnPressHandler, otherUserProfileImageUri, usersPresence, otherUserId } = this.props;
    const WrapperComponent = profileImageOnPressHandler ? TouchableOpacity : View;
    return (
      <WrapperComponent
        style={styles.profileImageWrapper}
        onPress={profileImageOnPressHandler}
        hitSlop={{
          top: 10,
          bottom: 10,
          left: 20,
          right: 20,
        }}
      >
        <ChatListItemProfileImage
          profileImageUri={otherUserProfileImageUri}
          online={usersPresence[otherUserId]}
          style={{ profileImage: styles.profileImage, indicator: { radius: 12, bottom: 0, right: 0 } }}
        />
      </WrapperComponent>
    );
  }

  render() {
    const { translate, chatHeader, chatUserId, activeLanguage } = this.props;
    return (
      <ViewportController safeAreaBottom style={{ backgroundColor: colors.smokeWhite }}>
        <View style={shadowBottom}>
          <ScreenHeader
            title={chatHeader}
            showBackArrow
            subheaderFunc={() => this.renderSubHeader()}
            containerStyle={styles.headerContainer}
            headerStyle={styles.header}
            rightButton={() => this.renderProfileImage()}
            headerProps={{ numberOfLines: 1 }}
          />
        </View>
        <Container>
          {!this.state.isLoading && (
            <>
              <GiftedChat
                placeholder={translate('candidateChat.sendInputPlaceholder')}
                locale={activeLanguage.code}
                messages={this.getChatMessages()}
                maxInputLength={400}
                messagesContainerStyle={Platform.OS === 'web' && { height: '78vh' }}
                onSend={(messages) => this.onSend(messages)}
                alwaysShowSend
                renderBubble={GigChatView.renderBubble}
                renderMessageText={GigChatView.renderMessageText}
                minInputToolbarHeight={49}
                renderAvatarOnTop
                forceGetKeyboardHeight
                renderInputToolbar={GigChatView.renderInputToolbar}
                renderComposer={(e) => GigChatView.renderComposer(
                    e,
                    this.state.inputText,
                    this.handleInputChange,
                    this.state.modalVisible,
                    this.closeModal,
                    translate
                  )}
                renderSend={GigChatView.renderSend}
                renderSystemMessage={GigChatView.renderNoMessages}
                user={{
                  _id: chatUserId,
                }}
              />
              {(Platform.OS === 'android' || Platform.OS === 'ios') && <KeyboardAvoidingView behavior="padding" />}
            </>
          )}
        </Container>
        {/* {Platform.OS === 'ios' && <KeyboardAvoidingView enabled behavior="position" />} */}
      </ViewportController>
    );
  }
}

GigChatView.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  getChatCredentials: PropTypes.func.isRequired,
  chatLoadMessages: PropTypes.func.isRequired,
  chatNewMessage: PropTypes.func.isRequired,
  chatSaveLastMessageRead: PropTypes.func.isRequired,

  chatMessages: PropTypes.instanceOf(List),
  chatUserId: PropTypes.string,
  chatHeader: PropTypes.string.isRequired,
  chatSubheader: PropTypes.string,
  gigId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
  otherUserProfileImageUri: PropTypes.string,
  otherUserId: PropTypes.string.isRequired,
  profileImageOnPressHandler: PropTypes.func,

  emptyChatPlaceholder: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

GigChatView.defaultProps = {
  chatMessages: null,
  chatUserId: undefined,
  chatSubheader: '',
  emptyChatPlaceholder: '',
  otherUserProfileImageUri: undefined,
  profileImageOnPressHandler: undefined,
};

const mapStateToProps = (state, props) => ({
  chatMessages: state.chat.get('chatsMessages').get(props.chatId),
  chatUserId: state.chatAuth.get('chatUserId'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, {
  getChatCredentials,
  chatLoadMessages,
  chatNewMessage,
  chatSaveLastMessageRead,
})(withLocalize(GigChatView));
