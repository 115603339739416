import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Routing from '../../core/routing/index';
import {
  CANDIDATE_USER, COMPANY_USER, ADMIN_USER, SUPERADMIN_USER,
} from '../../constants/userScopes';
import {
  COMPANY_POSTGIG,
  COMPANY_GIGS_LIST,
  CANDIDATE_SPLASH,
  CANDIDATE_PROFILE,
  CANDIDATE_GIGS,
  ADMIN_FIND_GIGS,
} from '../../constants/routes';
import { getCompanyStatus } from '../../company/status/companyStatusActions';
import CheckUserStatusScreen from './CheckUserStatusScreen';
import getProfile from '../../candidate/profile/CandidateProfileActions';
import { isCompletedOrRequiredCompleted } from '../../constants/profileCompletenessStatus';

const { Redirect } = Routing;

class CheckUserStatusContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { inProgress: true };
  }

  componentDidMount() {
    if (this.props.userScope === CANDIDATE_USER) {
      this.props.getProfile().then(() => {
        this.setState({ inProgress: false });
      });
    } else if (this.props.userScope === COMPANY_USER) {
      this.props.getCompanyStatus().then(() => {
        this.setState({ inProgress: false });
      });
    } else if ([ADMIN_USER, SUPERADMIN_USER].includes(this.props.userScope)) {
      this.setState({ inProgress: false });
    }
  }

  firstAdminScreen = () => ADMIN_FIND_GIGS;

  firstCompanyScreen() {
    if (this.props.hasAnyGig) {
      return COMPANY_GIGS_LIST;
    }
    return COMPANY_POSTGIG;
  }

  firstCandidateScreen() {
    if (this.props.profileCompleted) {
      return CANDIDATE_GIGS;
    }
    return CANDIDATE_PROFILE;
  }

  render() {
    if (this.state.inProgress) {
      return <CheckUserStatusScreen />;
    }
    if (this.props.userScope === CANDIDATE_USER) {
      if (this.props.firstLogin) {
        return <Redirect to={CANDIDATE_SPLASH} />;
      }
      return <Redirect to={this.firstCandidateScreen()} />;
    }
    if (this.props.userScope === COMPANY_USER) {
      return <Redirect to={this.firstCompanyScreen()} />;
    }
    return <Redirect to={this.firstAdminScreen()} />;
  }
}

CheckUserStatusContainer.propTypes = {
  userScope: PropTypes.string.isRequired,
  firstLogin: PropTypes.bool.isRequired,
  getProfile: PropTypes.func.isRequired,
  getCompanyStatus: PropTypes.func.isRequired,
  hasAnyGig: PropTypes.bool,
  profileCompleted: PropTypes.bool,
};

CheckUserStatusContainer.defaultProps = {
  hasAnyGig: undefined,
  profileCompleted: undefined,
};

const mapStateToProps = (state) => ({
  userId: state.auth.get('userId'),
  userScope: state.auth.get('userScope'),
  firstLogin: state.auth.get('firstLogin'),
  hasAnyGig: state.company.get('status').get('hasAnyGig'),
  profileCompleted: isCompletedOrRequiredCompleted(state.candidate.get('profile').get('profileCompleteness')),
});

export default connect(mapStateToProps, { getCompanyStatus, getProfile })(CheckUserStatusContainer);
