import React from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { toDate } from '../../utilities/dateFormatter';
import { getShiftKey, getWorkloadLabel } from '../../constants/availabilityTypes';
import { getExperienceKey } from '../../constants/experienceTypes';
import {
  colors, fontFamily, spacings, fontSizes,
} from '../styles/base.style';
import { gigPropType, activeLanguagePropTypes } from '../../constants/propTypes';
import PrimaryText from '../components/PrimaryText';
import Header from '../components/header/Header';
import { getLanguageNameFor } from '../../core/localization/languagesListProvider';
import { getSalaryText } from './salaryFormatter';
import FeatureToggle from '../components/FeatureToggle';
import { COMPANY_USER } from '../../constants/userScopes';
import UserScope from '../auth/UserScope';
import Touchable from '../components/Touchable';

const styles = StyleSheet.create({
  conditions: {
    marginBottom: 8,
    textAlign: 'left',
    marginHorizontal: spacings.md,
  },
  conditionsRow: {
    paddingVertical: 8,
    marginHorizontal: spacings.md,
    paddingHorizontal: spacings.xxs,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: colors.veryLightPink,
    borderBottomWidth: 1,
  },
  conditionsKey: {
    marginBottom: 0,
  },
  conditionsValue: {
    fontFamily: fontFamily.SFProDisplayBold,
    textAlign: 'right',
    marginBottom: 0,
  },
  internalUseHeader: {
    fontFamily: fontFamily.SFProDisplayBold,
    textAlign: 'center',
    fontSize: fontSizes.md,
    paddingBottom: 0,
  },
  internalUse: {
    backgroundColor: colors.veryLightGrey,
    paddingHorizontal: spacings.lg,
    paddingTop: spacings.sm,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderBottomColor: colors.veryLightGrey,
  },
});

const internalUseRowStyles = StyleSheet.create({
  rowStyles: {
    backgroundColor: colors.veryLightGrey,
    marginHorizontal: spacings.md,
    paddingVertical: spacings.sm,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderBottomColor: colors.veryLightGrey,
  },
  keyStyles: {
    fontFamily: fontFamily.SFProDisplayRegular,
  },
  valueStyles: {
    paddingTop: spacings.sm,
    fontFamily: fontFamily.SFProDisplaySemiBold,
  },
});

const renderConditionRow = (key, value, { rowStyles, keyStyles, valueStyles } = {}, onPress) => (onPress
  ? (<Touchable onPress={onPress}>{renderConditionRow(key, value, { rowStyles, keyStyles, valueStyles })}</Touchable>)
  : (
    <View style={[styles.conditionsRow, rowStyles]}>
      <PrimaryText style={[styles.conditionsKey, keyStyles]}>{`${key}:`}</PrimaryText>
      <PrimaryText style={[styles.conditionsValue, valueStyles]}>{value}</PrimaryText>
    </View>
  ));

function renderSalary(translate, translationKey, compensation, activeLanguage) {
  const { salary } = compensation;

  const t = (key, args) => translate(`${translationKey}.${key}`, args);
  const text = getSalaryText(compensation, null, t, activeLanguage.code);

  let label;
  if (compensation.salaryByAgreement) {
    label = t('salary');
  } else {
    label = t('salaryPer', { per: translate(`gigSalary.per.${salary.per}`) });
  }

  return (
    renderConditionRow(label, text)
  );
}

const GigDetailsConditions = ({
  translate,
  translationKey,
  gig,
  activeLanguage,
}) => (
  <>
    <Header style={styles.conditions}>{translate('gigPreview.conditions')}</Header>
    <View style={styles.propertiesWrapper}>
      {renderConditionRow(translate('companyGigCreator.preview.startDateLabel'), gig.noStartDate
        ? translate('companyGigCreator.preview.immediateStart')
        : toDate(gig.startDate))}
      {renderConditionRow(translate('companyGigCreator.preview.endDateLabel'), gig.noEndDate
        ? translate('companyGigCreator.preview.noEndDate')
        : toDate(gig.endDate))}
      {
        gig.compensation
          ? renderSalary(translate, translationKey, gig.compensation, activeLanguage)
          : null
      }
      {
        gig.workload ? (
          renderConditionRow(
            translate('companyGigCreator.preview.workload'),
            getWorkloadLabel(gig.workload, translate),
          )
        ) : null
      }
      {
        gig.shifts && gig.shifts.length
          ? renderConditionRow(
            translate('companyGigCreator.preview.shifts'),
            gig.shifts.map((t) => `${translate(`gigAvailability.${getShiftKey(t)}`)}`).join('\n'),
          ) : null
      }
      {
        gig.remoteWorkAllowed && renderConditionRow(translate('companyGigCreator.preview.remoteWork'), translate('generic.yes'))
      }
      {
        gig.experience ? (
          renderConditionRow(
            translate('companyGigCreator.preview.experience'),
            translate(`gigExperience.${getExperienceKey(gig.experience)}`),
          )
        ) : null
      }
      {
        gig.languages && gig.languages.length ? (
          renderConditionRow(
            translate('companyGigCreator.preview.languages'),
            gig.languages.map((l) => `${getLanguageNameFor(l.code, activeLanguage.code)} (${translate(`languageLevel.${l.level}`)})`).join('\n'),
          )
        ) : null
      }
      <UserScope allowedFor={[COMPANY_USER]}>
        {
          gig.questionnaire && gig.questionnaire.length ? (
            renderConditionRow(
              translate('companyGigCreator.preview.questionnaire'),
              gig.questionnaire.map((questionItem) => `• ${questionItem.text}`).join('\n'),
              { rowStyles: { flexDirection: 'column' } },
            )
          ) : null
        }
      </UserScope>
      {renderConditionRow(translate('companyGigCreator.preview.candidatesNumberLabel'), gig.candidatesNumber)}
      {(gig.preferredOccupation || !!gig.careerUrl) && (
        <UserScope allowedFor={[COMPANY_USER]}>
          <Header style={[styles.internalUse, styles.internalUseHeader]}>{translate('gigPreview.internalUse')}</Header>
          <FeatureToggle requestedFeature="OCCUPATION">
            {gig.preferredOccupation && renderConditionRow(
              translate('companyGigCreator.preferredOccupation.header'),
              gig.preferredOccupation.map((o) => translate(`companyGigCreator.preferredOccupation.occupation.${o}`)).join('\n'),
              {
                rowStyles: { ...internalUseRowStyles.rowStyles, flexDirection: undefined },
                keyStyles: internalUseRowStyles.keyStyles,
                valueStyles: { ...internalUseRowStyles.valueStyles, textAlign: 'left' },
              },
            )}
          </FeatureToggle>
          <FeatureToggle requestedFeature="CAREER_URL">
            {!!gig.careerUrl && renderConditionRow(
              translate('companyGigCreator.description.careerPageUrl'),
              gig.careerUrl,
              {
                rowStyles: { ...internalUseRowStyles.rowStyles, flexDirection: undefined },
                keyStyles: internalUseRowStyles.keyStyles,
                valueStyles: { ...internalUseRowStyles.valueStyles, textAlign: 'left' },
              },
              () => Linking.openURL(gig.careerUrl),
            )}
          </FeatureToggle>
        </UserScope>
      )}
    </View>
  </>
);

GigDetailsConditions.propTypes = {
  translate: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  gig: gigPropType.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
};

export default withLocalize(GigDetailsConditions);
