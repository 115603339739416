import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Redirect, Route } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as queryString from 'query-string';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import CandidateFindGigsFilters from './filters/CandidateFindGigsFilters';
import Container from '../../../common/components/Container';
import { colors, shadowBottom, spacings } from '../../../common/styles/base.style';
import SingleFindGigsScreen from './SingleFindGigsScreen';
import {
  CANDIDATE_FIND_GIGS_SINGLE, CANDIDATE_FIND_GIGS_SINGLE_FAVOURITES, CANDIDATE_FIND_GIGS_SINGLE_REJECTED, CANDIDATE_FIND_GIGS_SINGLE_VIEW,
} from '../../../constants/routes';
import TabBar from '../../../common/components/tabs/TabBar';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../../../constants/userScopes';
import CandidateFindGigsSingleFavourites from './single/CandidateFindGigsSingleFavourites';
import CandidateFindGigsSingleNotInteresting from './single/CandidateFindGigsSingleNotInteresting';
import { candidateGetFavouritesAndNotInterestingCounters, EXPIRED_GIG_VISIBILITY_THRESHOLD } from './candidateFindGigsActions';

const FIRST_SCREEN = CANDIDATE_FIND_GIGS_SINGLE_VIEW;

const styles = StyleSheet.create({
  filterContainer: {
    ...shadowBottom,
    marginBottom: 0,
    paddingHorizontal: spacings.sm,
  },
  filterContainerDisabled: {
    backgroundColor: colors.veryLightGrey,
  },
  shadow: shadowBottom,
  tabContainer: {
    flex: 1,
    marginTop: 0,
  },
});

class CandidateFindGigsSingleContainer extends React.Component {
  componentDidMount() {
    this.props.candidateGetFavouritesAndNotInterestingCounters(moment.utc().subtract(EXPIRED_GIG_VISIBILITY_THRESHOLD, 'days').toDate());
  }

  render() {
    const { location, favouritesCount, notInterestingCount } = this.props;
    const tabIndex = parseInt(queryString.parse(location.search).tabIndex, 10);
    const disabled = !!(tabIndex && tabIndex !== 1);
    return (
      <Container>
        <View style={[styles.filterContainer, disabled && styles.filterContainerDisabled]}>
          <CandidateFindGigsFilters disabled={disabled} />
        </View>
        <View style={styles.shadow}>
          <Route exact path={CANDIDATE_FIND_GIGS_SINGLE} render={() => <Redirect to={FIRST_SCREEN} />} />

          <TabBar
            items={[
              {
                textKey: 'candidateFindGigs.single.tabAll',
                link: CANDIDATE_FIND_GIGS_SINGLE_VIEW,
                tabIndex: 1,
              },
              {
                textKey: 'candidateFindGigs.single.tabFavourites',
                link: CANDIDATE_FIND_GIGS_SINGLE_FAVOURITES,
                tabIndex: 2,
                counterValue: favouritesCount,
                showCounter: favouritesCount !== undefined,
              },
              {
                textKey: 'candidateFindGigs.single.tabRejected',
                link: CANDIDATE_FIND_GIGS_SINGLE_REJECTED,
                tabIndex: 3,
                counterValue: notInterestingCount,
                showCounter: notInterestingCount !== undefined,
              },
            ]}
          />
        </View>
        <View style={[styles.tabContainer]}>
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_FIND_GIGS_SINGLE_VIEW}
            component={SingleFindGigsScreen}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_FIND_GIGS_SINGLE_FAVOURITES}
            component={CandidateFindGigsSingleFavourites}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_FIND_GIGS_SINGLE_REJECTED}
            component={CandidateFindGigsSingleNotInteresting}
          />
        </View>

      </Container>
    );
  }
}

CandidateFindGigsSingleContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  candidateGetFavouritesAndNotInterestingCounters: PropTypes.func.isRequired,
  favouritesCount: PropTypes.number,
  notInterestingCount: PropTypes.number,
};

CandidateFindGigsSingleContainer.defaultProps = {
  favouritesCount: undefined,
  notInterestingCount: undefined,
};

const mapStateToProps = (state) => ({
  favouritesCount: state.candidateFindGigs.get('favouritesCount'),
  notInterestingCount: state.candidateFindGigs.get('notInterestingCount'),
});

export default connect(mapStateToProps, { candidateGetFavouritesAndNotInterestingCounters })(CandidateFindGigsSingleContainer);
