import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { SUPERADMIN_USER } from '../../constants/userScopes';
import {
  ADMIN_SETTINGS, ADMIN_SETTINGS_SET_GIG_LIMIT_SUCCESS, ADMIN_SETTINGS_GET_CITY_LIST_SUCCESS, ADMIN_SETTINGS_UPDATE_CITY_SUCCESS,
} from '../../constants/routes';
import AdminSettingsContainer from './AdminSettingsContainer';
import AdminSettingsCityList from './AdminSettingsCityList';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_SETTINGS;

const AdminSettingsRouting = () => (
  <>
    <Route exact path={ADMIN_SETTINGS} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_SETTINGS} component={AdminSettingsContainer} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_SETTINGS_SET_GIG_LIMIT_SUCCESS} component={AdminSettingsContainer} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_SETTINGS_GET_CITY_LIST_SUCCESS} component={AdminSettingsCityList} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_SETTINGS_UPDATE_CITY_SUCCESS} component={AdminSettingsCityList} />
  </>
);

export default AdminSettingsRouting;
