import { PixelRatio, StyleSheet } from 'react-native';
import { wp } from '../../styles/base.style';

const URI_REPLACE_PART = 'letsgig.com/';

export function getResizedImageFromDimensions(uri, width, height) {
  return uri.replace(URI_REPLACE_PART, `${URI_REPLACE_PART}fit-in/${PixelRatio.getPixelSizeForLayoutSize(width)}x\
${PixelRatio.getPixelSizeForLayoutSize(height)}/`);
}

export function getResizedImageUriFromStyle(uri, style) {
  const flattenedStyle = StyleSheet.flatten(style);
  return getResizedImageFromDimensions(uri, flattenedStyle.width, flattenedStyle.height);
}

export function getImageSourceForGig(imageUri) {
  return { uri: getResizedImageFromDimensions(imageUri, wp(100), 200) };
}
