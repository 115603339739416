import React from 'react';
import {
  View, Image, StyleSheet, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import { modernColors, widthSpacingsBolek } from '../../styles/base.style';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  iconBackground: {
    backgroundColor: modernColors.grey,
    width: widthSpacingsBolek.p80,
    height: widthSpacingsBolek.p80,
    borderRadius: widthSpacingsBolek.p40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '70%',
    height: '70%',
  },
});

const IconButtonBolek = ({
  onPress, source, style,
}) => (
  <TouchableOpacity
    style={style}
    onPress={() => { if (onPress) onPress(); }}
  >
    <View style={styles.iconBackground}>
      <Image style={styles.icon} source={source} fadeDuration={0} resizeMode="contain" />
    </View>
  </TouchableOpacity>
);

IconButtonBolek.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onPress: PropTypes.func,
  style: stylePropTypes,
};

IconButtonBolek.defaultProps = {
  onPress: null,
  style: null,
};

export default IconButtonBolek;
