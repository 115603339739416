import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import validation, { GIG_MAX_CATEGORIES } from './GigCreatorStepCategoriesValidation';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorHeader from '../../GigCreatorHeader';
import { LinkButton } from '../../../../common/components';
import Container from '../../../../common/components/Container';
import { shadowTop, spacings } from '../../../../common/styles/base.style';
import Button from '../../../../common/components/buttons/Button';
import GigCategorySelectListContainer from '../../../../common/gig/GigCategorySelectListContainer';

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
});

const GigCreatorStepCategoryScreen = ({
  stepNumber, translate, handleSubmit, nextScreen, redirect, isEdit, selectedCategories, maxStepsNumber,
}) => (
  <Container>
    <FormikWithValidation
      validation={validation}
      initialValues={{ categories: selectedCategories }}
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
        <Container>
          <GigCreatorHeader
            isEdit={isEdit}
            stepNo={stepNumber}
            maxStepsNumber={maxStepsNumber}
            title={translate('companyGigCreator.category.header')}
            onRightButtonPress={handleSubmitFormik}
            isRightButtonDisabled={isBtnDisabled}
          />
          <GigCategorySelectListContainer
            name="categories"
            maxSelect={GIG_MAX_CATEGORIES}
          />
          <View style={styles.button}>
            {isEdit ? (
              <Button
                title={translate('generic.save')}
                disabled={isBtnDisabled}
                onPress={handleSubmitFormik}
              />
            ) : (
              <LinkButton
                to={nextScreen}
                title={translate('generic.next')}
                onPress={handleSubmitFormik}
                doRedirect={redirect}
                disabled={isBtnDisabled}
              />
            )}
          </View>
        </Container>
      )}
    />
  </Container>
);

GigCreatorStepCategoryScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepCategoryScreen);
