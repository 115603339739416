import React, { Component } from 'react';
import { ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GigChatView from '../../common/chat/GigChatView';
import { createOrGetCompanyChat } from './companyChatActions';
import { COMPANY_CHAT_GIG_CANDIDATES_LIST, COMPANY_GIG_CANDIDATE_VIEW, redirectToErrorScreen } from '../../constants/routes';
import { getChatCredentials } from '../../common/chat/chatActions';
import { withGigCandidateAccessHandle } from '../gigs/gigDetails/candidates/withGigCandidateAccessHandle';
import { withGigCandidateAccessHandlePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class CompanyGigChatContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accessToChatScreen: false,
      chatId: null,
      gigName: null,
      userName: null,
    };
  }

  componentDidMount() {
    const { gigId, candidateId } = this.props;
    this.props.handleBilling(gigId, candidateId, this.openChat, this.redirectToPreviousPageOrChatList);
  }

  getChatView = () => {
    const { translate, gigId, gigCandidateChatData, candidateId } = this.props;

    return (
      <GigChatView
        gigId={gigId}
        chatId={this.state.chatId}
        chatHeader={this.state.userName}
        chatSubheader={this.state.gigName}
        otherUserProfileImageUri={gigCandidateChatData?.userProfileImageUri}
        otherUserId={candidateId}
        emptyChatPlaceholder={translate('companyChat.emptyChatPlaceholder')}
        profileImageOnPressHandler={this.profileImageOnPressHandler}
      />
    );
  };

  getChatToken() {
    if (this.props.chatToken) {
      return Promise.resolve();
    }
    return this.props.getChatCredentials();
  }

  openChat = () => {
    this.getChatToken()
      .then(this.fetchChatData)
      .then((response) => {
        if (response.isError) {
          this.handleError();
        } else {
          const { chatId, gigName, userName } = response.payload;
          this.setState({ chatId, gigName, userName });
          this.gainAccessToChatScreen();
        }
      });
  };

  fetchChatData = () => this.props.createOrGetCompanyChat(this.props.gigId, this.props.candidateId);

  handleError = () => {
    redirectToErrorScreen(this.props.history, 'errors.chatUnavailableTitle', 'errors.chatUnavailableText');
  };

  profileImageOnPressHandler = () => {
    this.props.history.push(COMPANY_GIG_CANDIDATE_VIEW.replace(':gigId', this.props.gigId).replace(':candidateId', this.props.candidateId));
  };

  redirectToPreviousPageOrChatList = () => {
    const { history } = this.props;
    if ((Platform.OS === 'web' && history.action === 'PUSH') || (Platform.OS !== 'web' && history.entries.length >= 2)) {
      history.goBack();
    } else {
      history.push(COMPANY_CHAT_GIG_CANDIDATES_LIST.replace(':id', this.props.gigId));
    }
  };

  gainAccessToChatScreen() {
    this.setState({ accessToChatScreen: true });
  }

  render() {
    if (!this.state.accessToChatScreen) {
      return <ActivityIndicator size="large" style={styles.spinner} />;
    }
    return <>{this.getChatView()}</>;
  }
}

CompanyGigChatContainer.propTypes = {
  createOrGetCompanyChat: PropTypes.func.isRequired,
  getChatCredentials: PropTypes.func.isRequired,
  chatToken: PropTypes.string,
  gigId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  gigCandidateChatData: PropTypes.shape({
    chatId: PropTypes.string.isRequired,
    gigPosition: PropTypes.string.isRequired,
    gigName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userProfileImageUri: PropTypes.string,
  }),
  ...withGigCandidateAccessHandlePropTypes,
};

CompanyGigChatContainer.defaultProps = {
  gigCandidateChatData: undefined,
  chatToken: undefined,
};

const mapStateToProps = (state, props) => {
  const { gigId, candidateId } = props.match.params;
  const gigChatData = state.chat.get('gigsChatData').get(gigId);
  let gigCandidateChatData;
  if (gigChatData) {
    gigCandidateChatData = gigChatData.get(candidateId);
  }
  gigCandidateChatData = gigCandidateChatData ? gigCandidateChatData.toJS() : undefined;

  return {
    gigId,
    candidateId,
    gigCandidateChatData,
    chatToken: state.chatAuth.get('chatToken'),
  };
};

export default withGigCandidateAccessHandle(connect(mapStateToProps, { createOrGetCompanyChat, getChatCredentials })(CompanyGigChatContainer), true);
