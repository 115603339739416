import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../common/components/index';
import GigCreatorHeader from '../GigCreatorHeader';
import GeneralFormError from '../../../common/components/form/GeneralFormError';
import { gigPropType } from '../../../constants/propTypes';
import { COMPANY_GIG_MATCHING_REVIEW } from '../../../constants/routes';
import Container from '../../../common/components/Container';
import GigDetails from '../../../common/gig/GigDetails';
import { shadowTop, spacings, standardSpacings } from '../../../common/styles/base.style';
import Wrapper from '../../../common/components/Wrapper';

const NEXT_SCREEN = COMPANY_GIG_MATCHING_REVIEW;

const buttonStyle = StyleSheet.create({
  wrapper: {
    marginTop: spacings.md,
  },
});

const errorStyles = StyleSheet.create({
  wrapper: {
    marginVertical: standardSpacings.formVertical,
  },
});

const GigCreatorPreviewScreen = ({
  gig, translate, onSaveGig, errors,
}) => (
  <Container>
    <GigCreatorHeader title="Preview your gig" />
    <GigDetails isCompany gig={gig} translationKey="company.gigPreview" />
    <Wrapper noFlex>
      <GeneralFormError all errors={errors} style={errorStyles} />
    </Wrapper>
    <View style={{ ...shadowTop }}>
      <LinkButton to={NEXT_SCREEN} onPress={onSaveGig} style={buttonStyle} title={translate('companyGigCreator.preview.publish')} />
    </View>
  </Container>
);

GigCreatorPreviewScreen.propTypes = {
  gig: gigPropType.isRequired,
  translate: PropTypes.func.isRequired,
  onSaveGig: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any,
};

GigCreatorPreviewScreen.defaultProps = {
  errors: null,
};

export default withLocalize(GigCreatorPreviewScreen);
