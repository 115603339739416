import { EMPLOYMENT_CHANNEL, GIG_CANDIDATE_STATUS, MATCHING_SOURCE } from '../../../constants/matchingStatus';
import { colors } from '../../../common/styles/base.style';
import rejectImg from '../../../assets/icons/buttonRejectGray.png';
import { rejectGigApplicationAlertParams } from '../../../common/components/alert/alerts';
import {
  rejectGig,
  countCandidateGigs,
} from './candidateMyGigsActions';
import { MY_GIGS_CONTEXT } from '../../../constants/actionContext';
import { APPLIED_LIST_KEY, SHORTLISTED_LIST_KEY, MATCHED_LIST_KEY } from './candidateMyGigsReducer';
import acceptImg from '../../../assets/icons/buttonAccept.png';
import arrowRight from '../../../assets/icons/arrows/buttonArrowRightMagenta.png';
import chatBtn from '../../../assets/icons/chat/startChat.png';

export const dispatchActionAndRefreshCounter = (dispatch, action) => {
  dispatch(action).then(() => dispatch(countCandidateGigs()));
};

const definitions = {
  [GIG_CANDIDATE_STATUS.APPLIED]: {
    middleColor: colors.bluePurpleTwo,
    middleText: 'applied',
    actions: [
      {
        label: 'myGigsActions.removeApplication',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectGigApplicationAlertParams,
        getAction: (gigId, { dispatch }) => () => {
          dispatchActionAndRefreshCounter(dispatch, rejectGig(gigId, { context: MY_GIGS_CONTEXT, listKey: APPLIED_LIST_KEY }));
        },
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL]: {
    middleColor: colors.blueChill,
    middleText: 'pendingShortlisting',
    actions: [
      {
        label: 'myGigsActions.reject',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectGigApplicationAlertParams,
        getAction: (gigId, { dispatch }) => () => {
          dispatchActionAndRefreshCounter(dispatch, rejectGig(gigId, { context: MY_GIGS_CONTEXT, listKey: SHORTLISTED_LIST_KEY }));
        },
      },
      {
        label: 'myGigsActions.match',
        icon: acceptImg,
        confirmWithAlert: false,
        getAction: (gigId, { approveShortlisting }) => () => approveShortlisting(),
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.SHORTLISTED]: {
    matchingCriteria: ['source'],
    [MATCHING_SOURCE.CANDIDATE_APPLY]: {
      middleColor: colors.brightOrange,
      middleText: 'matchedFromApply',
      actions: [
        {
          label: 'myGigsActions.rejectApplication',
          icon: rejectImg,
          confirmWithAlert: true,
          alertParams: rejectGigApplicationAlertParams,
          getAction: (gigId, { dispatch }) => () => {
            dispatchActionAndRefreshCounter(dispatch, rejectGig(gigId, { context: MY_GIGS_CONTEXT, listKey: MATCHED_LIST_KEY }));
          },
        },
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction: (gigId, { startChat }) => () => startChat(),
        },
      ],
    },
    [MATCHING_SOURCE.SUGGESTED_CANDIDATE]: {
      middleColor: colors.brightOrange,
      middleText: 'matchedFromSuggested',
      actions: [
        {
          label: 'myGigsActions.rejectApplication',
          icon: rejectImg,
          confirmWithAlert: true,
          alertParams: rejectGigApplicationAlertParams,
          getAction: (gigId, { dispatch }) => () => {
            dispatchActionAndRefreshCounter(dispatch, rejectGig(gigId, { context: MY_GIGS_CONTEXT, listKey: MATCHED_LIST_KEY }));
          },
        },
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction: (gigId, { startChat }) => () => startChat(),
        },
      ],
    },
  },
  [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER]: {
    middleColor: colors.seablue,
    middleText: 'pendingOffer',
    actions: [
      {
        label: 'myGigsActions.startChat',
        icon: chatBtn,
        confirmWithAlert: false,
        getAction: (gigId, { startChat }) => () => startChat(),
      },
      {
        label: 'myGigsActions.viewOffer',
        icon: arrowRight,
        confirmWithAlert: false,
        getAction: (gigId, { openModal }) => () => openModal(gigId, true),
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED]: {
    middleColor: colors.brightOrange,
    middleText: 'acceptedOffer',
    actions: [
      {
        label: 'myGigsActions.startChat',
        icon: chatBtn,
        confirmWithAlert: false,
        getAction: (gigId, { startChat }) => () => startChat(),
      },
      {
        label: 'myGigsActions.viewAgreement',
        icon: arrowRight,
        confirmWithAlert: false,
        getAction: (gigId, { openModal }) => () => openModal(gigId, false),
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.HIRED]: {
    matchingCriteria: ['employmentChannel'],
    [EMPLOYMENT_CHANNEL.COMPANY]: {
      middleColor: colors.magenta,
      middleText: 'hiredByCompany',
      actions: [
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction: (gigId, { startChat }) => () => startChat(),
        },
        {
          label: 'myGigsActions.viewAgreement',
          icon: arrowRight,
          confirmWithAlert: false,
          getAction: (gigId, { openModal }) => () => openModal(gigId),
        },
      ],
    },
    [EMPLOYMENT_CHANNEL.LETSGIG]: {
      middleColor: colors.magenta,
      middleText: 'hiredByLetsgig',
      actions: [
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction: (gigId, { startChat }) => () => startChat(),
        },
        {
          label: 'myGigsActions.viewAgreement',
          icon: arrowRight,
          confirmWithAlert: false,
          getAction: (gigId, { openModal }) => () => openModal(gigId),
        },
      ],
    },
  },
  [GIG_CANDIDATE_STATUS.REJECTED_BY_COMPANY]: {
    middleColor: colors.brownGrey,
    middleText: 'rejectedByCompany',
  },
  [GIG_CANDIDATE_STATUS.REJECTED_BY_CANDIDATE]: {
    middleColor: colors.brownGrey,
    middleText: 'rejectedByCandidate',
  },
};

export default function getDefinition(matching) {
  const definition = definitions[matching.status];
  if (definition.matchingCriteria) {
    return definition[matching[definition.matchingCriteria]];
  }
  return definition;
}
