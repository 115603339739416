import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gigCreatorAddPreferredOccupation } from '../../gigCreatorActions';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import GigCreatorStepOccupationScreen from './GigCreatorStepOccupationScreen';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepOccupationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.OCCUPATION));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.PREFERRED_OCCUPATION);
  }

  handleSubmit = ({ preferredOccupation }, { setSubmitting }) => {
    this.props.gigCreatorAddPreferredOccupation(preferredOccupation);
    if (this.props.isEdit) {
      this.props
        .updateGig(GIG_EDIT_SECTIONS.PREFERRED_OCCUPATION, { preferredOccupation })
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      this.doRedirect();
    }
  };

  doRedirect = () => this.setState({ redirect: true });

  render = () => (
    <GigCreatorStepOccupationScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      preferredOccupation={this.props.preferredOccupation}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  );
}

GigCreatorStepOccupationContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  preferredOccupation: PropTypes.arrayOf(PropTypes.string).isRequired,
  gigCreatorAddPreferredOccupation: PropTypes.func.isRequired,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const {
    gig: { preferredOccupation },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { preferredOccupation, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddPreferredOccupation })(GigCreatorStepOccupationContainer));
