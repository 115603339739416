import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import Routing from '../../../../core/routing/index';
import { COMPANY_CANDIDATES_POOL_SHIFTS, redirectToErrorScreen } from '../../../../constants/routes';
import ShiftAccessEntitiesStepEditableScreen from './ShiftAccessEntitiesStepEditableScreen';
import { getAvailableGroups } from '../../../candidatesPool/groups/candidatesPoolGroupsActions';
import { createShift } from '../../companyShiftsActions';
import { shiftCreatorPropType, shiftCreatorAccessEntitiesPropType } from '../../../../constants/propTypes';
import { shiftCreatorSetAccessEntities, shiftCreatorSetSaveAsTemplate } from '../shiftCreatorActions';

const { withRouter } = Routing;

class ShiftAccessEntitiesStepEditableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: null,
    };
  }

  componentDidMount() {
    this.props.getAvailableGroups({ privateOnly: true }).then((res) => {
      if (!res.isError) {
        this.setState({ teams: res.payload.map(((team) => ({ value: team.groupId, label: team.name }))) });
      } else if (res && res.status === 404) {
        redirectToErrorScreen(this.props.history, 'errors.notFoundTitle', 'errors.notFoundText');
      }
    });
  }

  handleSubmit = ({ teams, saveAsTemplate }) => {
    this.props.shiftCreatorSetAccessEntities({ teams: teams.map((team) => ({ teamId: team })) });
    this.props.shiftCreatorSetSaveAsTemplate(saveAsTemplate);
    this.props.createShift(this.props.shift).then((response) => {
      if (!response.isError) {
        this.props.history.push(COMPANY_CANDIDATES_POOL_SHIFTS);
      } else {
        this.setState({ errors: response.errors });
      }
    });
  };

  render() {
    const { accessEntities, saveAsTemplate } = this.props;
    const { errors } = this.state;
    const initialValues = { teams: accessEntities.teams.map((team) => team.groupId || team.teamId), saveAsTemplate };

    return (
      <ShiftAccessEntitiesStepEditableScreen
        handleSubmit={this.handleSubmit}
        teams={this.state.teams}
        initialValues={initialValues}
        errors={errors}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    accessEntities,
    saveAsTemplate,
  } = state.shiftCreator.get('shift').toJS();

  return {
    accessEntities,
    saveAsTemplate,
    shift: state.shiftCreator.get('shift').toJS(),
  };
};

ShiftAccessEntitiesStepEditableContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getAvailableGroups: PropTypes.func.isRequired,
  accessEntities: shiftCreatorAccessEntitiesPropType.isRequired,
  saveAsTemplate: PropTypes.bool.isRequired,
  shiftCreatorSetAccessEntities: PropTypes.func.isRequired,
  shiftCreatorSetSaveAsTemplate: PropTypes.func.isRequired,
  createShift: PropTypes.func.isRequired,
  shift: shiftCreatorPropType.isRequired,
};

export default connect(mapStateToProps, {
  getAvailableGroups,
  shiftCreatorSetAccessEntities,
  shiftCreatorSetSaveAsTemplate,
  createShift,
})(withRouter(ShiftAccessEntitiesStepEditableContainer));
