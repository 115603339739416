import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileCreatorStepCategoryScreen from './ProfileCreatorStepCategoryScreen';
import { profileCreatorAddCategories } from '../../profileCreatorActions';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import { getProfileCategoryValidationParams } from '../../../CandidateProfileActions';
import { candidateCategoryValidationParams } from '../../../../../constants/propTypes';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepCategoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.CATEGORY);
    this.props.getProfileCategoryValidationParams()
      .then((response) => {
        if (!response.isError) {
          this.setState({ validationParamsFetched: true });
        }
      });
  }

  handleSubmit = ({ categories }) => {
    this.props.profileCreatorAddCategories(categories);
    this.doRedirect();
  };

  doRedirect = () => this.setState({ redirect: true });

  render = () => (
    <ProfileCreatorStepCategoryScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.GIG_CATEGORIES, this.props.grantedFeatures)}
      selectedCategories={this.props.selectedCategories}
      candidateCategoriesValidationParams={this.props.categoryValidationParams}
      validationParamsFetched={this.state.validationParamsFetched}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.GIG_CATEGORIES, this.props.grantedFeatures)}
    />
  );
}

ProfileCreatorStepCategoryContainer.propTypes = {
  getProfileCategoryValidationParams: PropTypes.func.isRequired,
  categoryValidationParams: candidateCategoryValidationParams.isRequired,
  profileCreatorAddCategories: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  categoryValidationParams: state.candidate.get('profile').get('categoryValidationParams').toJS(),
  selectedCategories: state.candidate.get('profileData').get('categories').toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { profileCreatorAddCategories, getProfileCategoryValidationParams })(ProfileCreatorStepCategoryContainer);
