import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import {
  ActivityIndicator, StyleSheet, Text, View,
} from 'react-native';
import { activeLanguagePropTypes, pricingPlanPropTypes } from '../../../constants/propTypes';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import PricingPlanItem from './PricingPlanItem';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import BaseListItem from '../../../common/components/BaseListItem';
import Button from '../../../common/components/buttons/Button';
import {
  colors, fontFamily, fontSizes, shadowTop, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import ViewportController from '../../../common/components/ViewportController';

const styles = StyleSheet.create({
  item: {
    marginBottom: spacings.sm,
    borderColor: 'transparent',
    borderWidth: 2,
  },
  selectedItem: {
    borderColor: colors.magenta,
    borderWidth: 2,
  },
  subHeader: {
    textAlign: 'center',
    marginBottom: spacings.sm,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, null, colors.greyishBrown),
  },
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class PricingPlansScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedPlanId: this.props.selectedPlanId, btnPressed: false };
  }

  onSelect = (planId) => {
    this.setState({ selectedPlanId: planId });
  };

  onSave = () => {
    const selectedPricingPlanId = this.state.selectedPlanId;
    this.props.onChoosePlan(selectedPricingPlanId);
  };

  isPlanSelected = (planId) => planId === this.state.selectedPlanId;

  render() {
    const {
      translate,
      activeLanguage,
      handleBack,
      plans,
      chooseButtonLabel,
    } = this.props;
    const orderedPlans = plans ? plans.sort((plan1, plan2) => plan1.metadata.order - plan2.metadata.order) : null;
    const t = (key, args) => translate(`company.payments.pricingPlans.${key}`, args);

    return (
      <>
        <ScreenHeader
          title={t('header')}
          showBackArrow
          handleBack={handleBack}
          subheaderFunc={() => <Text style={styles.subHeader}>{t('subheader')}</Text>}
          shadow
        />
        {plans
          ? (
            <ViewportController safeAreaBottom>
              <PrimaryFlatList
                emptyListTextKey={t('emptyList')}
                data={orderedPlans}
                keyExtractor={(plan) => plan.planId}
                renderItem={({ item: plan }) => (
                  <BaseListItem
                    onPress={() => this.onSelect(plan.planId)}
                    style={[styles.item, this.isPlanSelected(plan.planId) && styles.selectedItem]}
                    hideArrow
                    renderMainContent={() => (
                      <PricingPlanItem
                        key={plan.planId}
                        plan={plan}
                        translate={t}
                        locale={activeLanguage.code}
                        isSelected={this.isPlanSelected(plan.planId)}
                      />
                    )}
                    key={plan.planId}
                  />
                )}
              />
              <View style={styles.button}>
                <Button
                  disabled={this.state.btnPressed || !this.state.selectedPlanId}
                  onPress={() => {
                    this.setState({ btnPressed: true });
                    this.onSave();
                  }}
                  title={t(chooseButtonLabel)}
                  showLoaderOnPress
                />
              </View>
            </ViewportController>
          )
          : <ActivityIndicator size="large" color={colors.magenta} style={styles.spinner} />}
      </>
    );
  }
}

PricingPlansScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  handleBack: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(pricingPlanPropTypes),
  selectedPlanId: PropTypes.string,
  onChoosePlan: PropTypes.func.isRequired,
  chooseButtonLabel: PropTypes.string,
};

PricingPlansScreen.defaultProps = {
  plans: null,
  selectedPlanId: null,
  chooseButtonLabel: 'choosePlanButton',
};

export default withLocalize(PricingPlansScreen);
