import React, { Component } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PrimaryTextInput } from '../index';
import { toDate, toDatetime, toTime } from '../../../utilities/dateFormatter';

class DateTimePicker extends Component {
  static printDate(mode, date) {
    if (!date) {
      return '';
    }
    if (mode === 'datetime') {
      toDatetime(date);
    }
    if (mode === 'time') {
      toTime(date);
    }
    return toDate(date);
  }

  onConfirm = (momentDate) => {
    const dateWithoutSecs = momentDate.milliseconds(0).seconds(0);
    this.props.onDateChange(dateWithoutSecs.valueOf());
  };

  calculateDatePickerDate = () => {
    const {
      defaultDateFunc, date,
    } = this.props;

    // if some date is provided (user chose a date at least once or it was prefilled), just use it
    if (date) {
      return date;
    }

    let initialDatePickerDate;
    if (defaultDateFunc) {
      initialDatePickerDate = defaultDateFunc();
    } else {
      initialDatePickerDate = new Date().getTime();
    }

    return initialDatePickerDate;
  };

  validateDate = (currentDate) => {
    if (this.props.minimumDateFunc && moment(currentDate).isBefore(this.props.minimumDateFunc())) {
      return false;
    }

    if (this.props.maximumDateFunc && moment(currentDate).isAfter(this.props.maximumDateFunc())) {
      return false;
    }

    return true;
  }

  render() {
    const { date } = this.props;

    return (
      <Datetime
        value={new Date(this.calculateDatePickerDate())}
        onChange={this.onConfirm}
        timeFormat={this.props.mode !== 'date'}
        dateFormat={this.props.mode !== 'time'}
        isValidDate={this.validateDate}
        renderInput={(props, openCalendar) => (
          <PrimaryTextInput
            style={this.props.style}
            label={this.props.label}
            error={this.props.error}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            onFocus={openCalendar}
            editable={false}
            value={DateTimePicker.printDate(this.props.mode, date)}
          />
        )}
      />
    );
  }
}

DateTimePicker.propTypes = {
  date: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  defaultDateFunc: PropTypes.func,
  minimumDateFunc: PropTypes.func,
  maximumDateFunc: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  mode: PropTypes.string,
};

DateTimePicker.defaultProps = {
  date: null,
  defaultDateFunc: null,
  placeholder: '',
  disabled: false,
  error: null,
  style: null,
  mode: 'datetime',
  label: '',
  minimumDateFunc: undefined,
  maximumDateFunc: undefined,
};

export default DateTimePicker;
