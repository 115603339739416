import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import SignupScreen from '../../common/screens/SignupScreen';
import CandidateSignupValidation from './CandidateSignupValidation';
import signupCandidate from './CandidateSignupActions';
import { CANDIDATE_USER } from '../../constants/userScopes';
import { CANDIDATE_WELCOME } from '../../constants/routes';
import { resetSignUpSuccess } from '../../common/auth/authActions';

class CandidateSignupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsModalVisible: false,
      privacyModalVisible: false,
      signUpSuccess: false,
    };
  }

  onSubmit = (values, { setErrors, setSubmitting }) => {
    if (values.city && !values.association) {
      alert('Välj en förening');
      setSubmitting(false);
      return;
    }
    this.props.signupCandidate({ ...values, locale: this.props.locale }).then((response) => {
      if (response.isError) {
        setErrors(response.errors);
        setSubmitting(false);
        return;
      }
      this.onSignUpSuccess();
    });
  };

  onOpenPrivacy = () => {
    this.setState({ privacyModalVisible: true });
  };
  onOpenTerms = () => {
    this.setState({ termsModalVisible: true });
  };
  handleCloseModal = () => {
    this.setState({ privacyModalVisible: false });
    this.setState({ termsModalVisible: false });
  };

  handleCloseSignup = () => {
    this.props.resetSignUpSuccess();
    // this.props.history.goBack();
    this.props.history.replace(CANDIDATE_WELCOME);
  };

  onSignUpSuccess = () => {
    this.setState({ signUpSuccess: true });
  }

  render() {
    return (
      <SignupScreen
        userType={CANDIDATE_USER}
        redirectPath={CANDIDATE_WELCOME}
        validation={CandidateSignupValidation}
        onSubmit={this.onSubmit}
        redirect={false} // prevent redirect upon signup success
        signUpSuccess={this.state.signUpSuccess}
        onOpenPrivacy={this.onOpenPrivacy}
        onOpenTerms={this.onOpenTerms}
        handleCloseModal={this.handleCloseModal}
        termsModalVisible={this.state.termsModalVisible}
        privacyModalVisible={this.state.privacyModalVisible}
        handleCloseSignup={this.handleCloseSignup}
      />
    );
  }
}

CandidateSignupContainer.propTypes = {
  signupCandidate: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  resetSignUpSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  locale: state.locale.get('locale'),
});

export default connect(mapStateToProps, { signupCandidate, resetSignUpSuccess })(CandidateSignupContainer);
