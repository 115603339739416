import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { CANDIDATE_CREATE_PROFILE } from '../../../constants/routes';
import Routing from '../../../core/routing/index';
import Touchable from '../../../common/components/Touchable';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import ArrowRight from '../../../common/components/Arrow';
import { isCompletedOrRequiredCompleted } from '../../../constants/profileCompletenessStatus';

const { Redirect } = Routing;

const styles = StyleSheet.create({
  touchable: {
    backgroundColor: colors.gold,
    borderRadius: 7,
    paddingVertical: spacings.sm,
    paddingHorizontal: spacings.md,
    marginBottom: spacings.lg,
  },
  textTitle: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.lg,
    color: colors.greyishBrown,
    marginBottom: spacings.xxs,
  },
  textBody: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.md,
    color: colors.greyishBrown,
  },
  iconWrapper: {
    position: 'absolute',
    top: spacings.md,
    right: spacings.sm,
  },
});

class CompleteProfileButton extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };
  }

  handleOnPress = () => {
    this.setState({ redirect: true });
  };

  isProfileEmpty() {
    return this.props.profile
    && this.props.profile.profileCompleteness
    && !isCompletedOrRequiredCompleted(this.props.profile.profileCompleteness);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={CANDIDATE_CREATE_PROFILE} />;
    }

    if (this.isProfileEmpty()) {
      return (
        <Touchable style={styles.touchable} onPress={this.handleOnPress}>
          <Text style={styles.textTitle}>{this.props.translate('candidateProfile.completeProfileBox.header')}</Text>
          <Text style={styles.textBody}>{this.props.translate('candidateProfile.completeProfileBox.text')}</Text>
          <View style={styles.iconWrapper}>
            <ArrowRight />
          </View>
        </Touchable>
      );
    }
    return (null);
  }
}

CompleteProfileButton.propTypes = {
  translate: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    profileCompleteness: PropTypes.string,
  }),
};

CompleteProfileButton.defaultProps = {
  profile: {
    profileCompleteness: undefined,
  },
};

export default withLocalize(CompleteProfileButton);
