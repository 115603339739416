import { LOGOUT_REQUEST_SUCCESS, SESSION_TIMEOUT, DELETE_ACCOUNT_REQUEST_SUCCESS } from '../../constants/actionTypes';
import appReducer, { appReducers } from './appReducer';

const getReducersToClear = (allStateKeys, clearWhitelist) => {
  const reducersToClear = {};
  allStateKeys.forEach((key) => {
    if (!clearWhitelist.includes(key)) {
      reducersToClear[key] = undefined;
    }
  });
  return reducersToClear;
};

const rootClearingReducer = (clearWhitelist) => {
  const reducersToClear = getReducersToClear(Object.keys(appReducers), clearWhitelist);

  return (state, action) => {
    let newState = state;
    if (action.type === SESSION_TIMEOUT || action.type === LOGOUT_REQUEST_SUCCESS || action.type === DELETE_ACCOUNT_REQUEST_SUCCESS) {
      newState = {
        ...state,
        ...reducersToClear,
      };
    }

    return appReducer(newState, action);
  };
};

export default rootClearingReducer;
