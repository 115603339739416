import React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { stylePropTypes } from '../../../constants/propTypes';

export const ICON_SIZE = 24;
const btnIconStyle = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  disabled: {
    backgroundColor: '#9a9a9a',
  },
});

const IconButton = ({
  onPress, label, source, disabled, style,
}) => {
  const disabledStyle = disabled ? btnIconStyle.disabled : undefined;
  return (
    <TouchableOpacity
      style={[btnIconStyle.wrapper, style, disabledStyle]}
      onPress={() => { if (!disabled) onPress(); }}
    >
      <Image accessibilityLabel={label} style={btnIconStyle.icon} source={source} fadeDuration={0} resizeMode="contain" />
    </TouchableOpacity>
  );
};

IconButton.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: stylePropTypes,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  style: null,
  disabled: false,
};

export default IconButton;
