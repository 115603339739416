import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { LANGUAGE_LEVEL } from '../../constants/languageTypes';
import { activeLanguagePropTypes, languagePropType } from '../../constants/propTypes';
import IconButton, { ICON_SIZE } from '../components/buttons/IconButton';
import PrimaryPicker from '../components/form/PrimaryPicker';
import getWorldLanguagesListFor from '../../core/localization/languagesListProvider';
import { spacings, wp } from '../styles/base.style';

const rejectImg = require('../../assets/icons/buttonReject.png');

const styles = StyleSheet.create({
  pickersContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: spacings.sm,
  },
  iconButtonWrapper: {
    alignSelf: 'flex-start',
    paddingTop: Platform.select({
      android: 8,
      ios: 5,
      web: 0,
    }),
  },
});

const inputStyle = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
  input: {
    marginBottom: 0,
    paddingBottom: 0,
    width: Platform.select({
      android: wp(42) - (ICON_SIZE / 2),
      ios: wp(40) - (ICON_SIZE / 2),
      web: wp(32) - (ICON_SIZE / 2),
    }),
  },
});

const pickerStyle = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  androidAndWebWrapper: {
    borderBottomWidth: 0,
  },
});

const LanguagesFormRow = ({
  item, usedLanguages, translate, handleRemove, handleOnChange, activeLanguage,
}) => {
  const getLanguagesItemList = () => {
    const worldLanguagesList = getWorldLanguagesListFor(activeLanguage.code);
    return worldLanguagesList
      .filter((langItem) => langItem.code === item.code || !usedLanguages.includes(langItem.code))
      .map((langItem) => ({ value: langItem.code, label: langItem.name }));
  };

  return (
    <>
      <View style={styles.pickersContainer}>
        <PrimaryPicker
          name="language"
          inputStyle={inputStyle}
          style={pickerStyle}
          items={getLanguagesItemList()}
          onValueChange={(newValue) => {
            const changedItem = { code: newValue, level: item.level };
            handleOnChange(item, changedItem);
          }}
          selectedValue={item.code}
        />
        <PrimaryPicker
          name="level"
          inputStyle={inputStyle}
          style={pickerStyle}
          items={Object.keys(LANGUAGE_LEVEL).map((key) => ({ value: LANGUAGE_LEVEL[key], label: translate(`languageLevel.${key}`) }))}
          onValueChange={(newValue) => {
            const changedItem = { code: item.code, level: newValue };
            handleOnChange(item, changedItem);
          }}
          selectedValue={item.level}
        />
        <IconButton
          style={styles.iconButtonWrapper}
          onPress={() => handleRemove(item.code)}
          label={translate('companyGigCreator.language.remove')}
          source={rejectImg}
        />
      </View>
    </>
  );
};

LanguagesFormRow.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  item: languagePropType.isRequired,
  usedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default withLocalize(LanguagesFormRow);
