import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import queryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { Button } from '../../../../common/components';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import {
  fontFamily, fontSizes, shadowTop, spacings,
} from '../../../../common/styles/base.style';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import { font } from '../../../../common/styles/mixins';
import { approveShortlisting, rejectGig } from '../../my_gigs/candidateMyGigsActions';
import {
  CANDIDATE_MY_GIGS_HISTORY,
  CANDIDATE_MY_GIGS_OFFERS,
  CANDIDATE_MY_GIGS_APPLIED,
  CANDIDATE_MY_GIGS_MATCHED,
} from '../../../../constants/routes';
import Routing from '../../../../core/routing/index';

const { withRouter } = Routing;

const {
  SHORTLISTED,
  REJECTED_BY_CANDIDATE,
  REJECTED_BY_COMPANY,
  APPLIED,
  PENDING_GIG_OFFER,
  GIG_OFFER_ACCEPTED,
  HIRED,
} = GIG_CANDIDATE_STATUS;

const styles = StyleSheet.create({
  pendingShortlistingText: {
    textAlign: 'center',
    marginVertical: spacings.sm,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
  },
});

const redirect = (history, { redirectPath, tabIndex }) => {
  history.push(`${redirectPath}?${queryString.stringify({ tabIndex })}`);
};

const prepareTextAndRedirectDataBasedOnStatus = (translate, status) => {
  switch (status) {
    case APPLIED:
      return { text: translate('candidateMyGigs.textsForStatuses.applied'), redirectPath: CANDIDATE_MY_GIGS_APPLIED, tabIndex: 1 };
    case REJECTED_BY_COMPANY:
      return { text: translate('candidateMyGigs.textsForStatuses.rejectedByCompany'), redirectPath: CANDIDATE_MY_GIGS_HISTORY, tabIndex: 6 };
    case REJECTED_BY_CANDIDATE:
      return { text: translate('candidateMyGigs.textsForStatuses.rejectedByCandidate'), redirectPath: CANDIDATE_MY_GIGS_HISTORY, tabIndex: 6 };
    case SHORTLISTED:
      return { text: translate('candidateMyGigs.textsForStatuses.shortlisted'), redirectPath: CANDIDATE_MY_GIGS_MATCHED, tabIndex: 3 };
    case HIRED:
      return { text: translate('candidateMyGigs.textsForStatuses.hired'), redirectPath: CANDIDATE_MY_GIGS_OFFERS, tabIndex: 5 };
    case PENDING_GIG_OFFER:
    case GIG_OFFER_ACCEPTED:
      return { text: translate('candidateMyGigs.textsForStatuses.gigOfferAccepted'), redirectPath: CANDIDATE_MY_GIGS_OFFERS, tabIndex: 4 };
    default:
      return {};
  }
};

const MyGigsRedirectButton = ({
  translate,
  matchingStatus,
  history,
  match,
}) => {
  const renderData = prepareTextAndRedirectDataBasedOnStatus(translate, matchingStatus, match.params.id);
  return (
    <View style={{ paddingHorizontal: spacings.md, ...shadowTop }}>
      <Text style={styles.pendingShortlistingText}>{renderData.text}</Text>
      <View>
        <Button
          size={BUTTON_SIZES.LG}
          type={BUTTON_TYPES.SECONDARY}
          title={translate('candidateMyGigs.seeInMyGigs')}
          onPress={() => { redirect(history, renderData); }}
        />
      </View>
    </View>
  );
};

MyGigsRedirectButton.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  matchingStatus: PropTypes.string.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(
  withLocalize(connect(null, { rejectShortlistingAction: rejectGig, approveShortlistingAction: approveShortlisting })(MyGigsRedirectButton)),
);
