import React from 'react';
import { Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '../../styles/base.style';

const styles = StyleSheet.create({
  text: {
    color: colors.black,
    textDecorationLine: 'underline',
  },
});

const InlineButton = ({ text, onPress }) => (
  <Text
    style={styles.text}
    onPress={onPress}
  >
    {text}
  </Text>
);

InlineButton.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default InlineButton;
