import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { StyleSheet, View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Routing from '../../../core/routing/index';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../../../constants/userScopes';
import TabBar from '../../../common/components/tabs/TabBar';
import {
  CANDIDATE_MY_GIGS,
  CANDIDATE_MY_GIGS_SHORTLISTED,
  CANDIDATE_MY_GIGS_OFFERS,
  CANDIDATE_MY_GIGS_HISTORY,
  CANDIDATE_MY_GIGS_APPLIED,
  CANDIDATE_MY_GIGS_MATCHED,
  CANDIDATE_MY_GIGS_HIRED,
} from '../../../constants/routes';
import CandidateMyGigsSuggestedView from './shortlisted/CandidateMyGigsShortlistedView';
import CandidateMyGigsHistoryView from './history/CandidateMyGigsHistoryView';
import CandidateMyGigsOffersView from './offers/CandidateMyGigsOffersView';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import { shadowBottom } from '../../../common/styles/base.style';
import CandidateMyGigsAppliedView from './applied/CandidateMyGigsAppliedView';
import CandidateMyGigsMatchedView from './matched/CandidateMyGigsMatchedView';
import CandidateMyGigsHiredView from './hired/CandidateMyGigsHiredView';
import { GIG_CANDIDATE_STATUS } from '../../../constants/matchingStatus';
import { countCandidateGigs } from './candidateMyGigsActions';

const {
  APPLIED,
  PENDING_SHORTLIST_APPROVAL,
  PENDING_GIG_OFFER,
  GIG_OFFER_ACCEPTED,
  SHORTLISTED,
  HIRED,
} = GIG_CANDIDATE_STATUS;

const { Route, Redirect, withRouter } = Routing;

const FIRST_SCREEN = `${CANDIDATE_MY_GIGS_SHORTLISTED}?${queryString.stringify({ tabIndex: 2 })}`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 0,
  },
  shadow: shadowBottom,
});

class CandidateMyGigsRouting extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    this.shouldBackArrowBeVisible = Platform.OS !== 'web' && history.entries.length >= 2
      ? history.entries[history.entries.length - 2].pathname.match('/candidate/gigs/.*/details') !== null : false;
    this.props.countCandidateGigs();
  }

  isNewContentInTab = (status) => this.props.newContentIndicators.includes(status);

  render() {
    const tabIndex = parseInt(queryString.parse(this.props.location.search).tabIndex, 10);
    const {
      translate,
      gigsCount,
    } = this.props;
    return (
      <>
        <View style={styles.shadow}>
          <ScreenHeader title={translate('candidateMyGigs.title')} showBackArrow={this.shouldBackArrowBeVisible} />
          <Route exact path={CANDIDATE_MY_GIGS} render={() => <Redirect to={FIRST_SCREEN} />} />

          <TabBar
            items={[
              {
                textKey: 'candidateMyGigs.appliedLabel',
                link: CANDIDATE_MY_GIGS_APPLIED,
                showCounter: !!gigsCount,
                counterValue: gigsCount ? gigsCount[APPLIED] : undefined,
                tabIndex: 1,
              },
              {
                textKey: 'candidateMyGigs.shortlistedLabel',
                link: CANDIDATE_MY_GIGS_SHORTLISTED,
                showCounter: !!gigsCount,
                counterValue: gigsCount ? gigsCount[PENDING_SHORTLIST_APPROVAL] : undefined,
                isNewContent: this.isNewContentInTab(GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL),
                tabIndex: 2,
              },
              {
                textKey: 'candidateMyGigs.matchedLabel',
                link: CANDIDATE_MY_GIGS_MATCHED,
                showCounter: !!gigsCount,
                counterValue: gigsCount ? gigsCount[SHORTLISTED] : undefined,
                isNewContent: this.isNewContentInTab(GIG_CANDIDATE_STATUS.SHORTLISTED),
                tabIndex: 3,
              },
              {
                textKey: 'candidateMyGigs.offersLabel',
                link: CANDIDATE_MY_GIGS_OFFERS,
                showCounter: !!gigsCount,
                counterValue: gigsCount ? gigsCount[PENDING_GIG_OFFER] + gigsCount[GIG_OFFER_ACCEPTED] : undefined,
                isNewContent: this.isNewContentInTab(GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER),
                tabIndex: 4,
              },
              {
                textKey: 'candidateMyGigs.hiredLabel',
                link: CANDIDATE_MY_GIGS_HIRED,
                showCounter: !!gigsCount,
                counterValue: gigsCount ? gigsCount[HIRED] : undefined,
                isNewContent: this.isNewContentInTab(GIG_CANDIDATE_STATUS.HIRED),
                tabIndex: 5,
              },
              {
                textKey: 'candidateMyGigs.historyLabel',
                link: CANDIDATE_MY_GIGS_HISTORY,
                tabIndex: 6,
              },
            ]}
            onTabPressCallback={() => { this.props.countCandidateGigs(); }}
            scrollToTabIndex={tabIndex}
          />
        </View>
        <View style={[styles.container]}>
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_MY_GIGS_APPLIED}
            component={CandidateMyGigsAppliedView}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_MY_GIGS_SHORTLISTED}
            component={CandidateMyGigsSuggestedView}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_MY_GIGS_MATCHED}
            component={CandidateMyGigsMatchedView}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_MY_GIGS_OFFERS}
            component={CandidateMyGigsOffersView}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_MY_GIGS_HIRED}
            component={CandidateMyGigsHiredView}
          />
          <AuthorizedRoute
            only={CANDIDATE_USER}
            path={CANDIDATE_MY_GIGS_HISTORY}
            component={CandidateMyGigsHistoryView}
          />
        </View>
      </>
    );
  }
}

CandidateMyGigsRouting.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  translate: PropTypes.func.isRequired,
  countCandidateGigs: PropTypes.func.isRequired,
  gigsCount: PropTypes.objectOf(PropTypes.number),
  newContentIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CandidateMyGigsRouting.defaultProps = {
  gigsCount: null,
};

const mapStateToProps = (state) => {
  const gigCount = state.candidateMyGigs.get('count');
  return ({
    gigsCount: gigCount ? gigCount.toJS() : null,
    newContentIndicators: state.candidateMyGigs.get('newContentIndicators'),
  });
};

export default connect(mapStateToProps, { countCandidateGigs })(withRouter(withLocalize(CandidateMyGigsRouting)));
