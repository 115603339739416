import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import GigCreatorWelcome from './GigCreatorWelcome';
import GigCreatorStepInfoContainer from './steps/info/GigCreatorStepInfoContainer';
import GigCreatorStepCategoryContainer from './steps/categories/GigCreatorStepCategoryContainer';
import GigCreatorPreviewContainer from './preview/GigCreatorPreviewContainer';
import GigCreatorStepCompensationContainer from './steps/compensation/GigCreatorStepCompensationContainer';
import GigCreatorStepDescriptionContainer from './steps/description/GigCreatorStepDescriptionContainer';
import GigCreatorStepDatesContainer from './steps/dates/GigCreatorStepDatesContainer';
import GigCreatorStepLocationContainer from './steps/location/GigCreatorStepLocationContainer';
import GigCreatorStepAvailabilityContainer from './steps/availability/GigCreatorStepAvailabilityContainer';
import GigCreatorStepExperienceContainer from './steps/experience/GigCreatorStepExperienceContainer';
import GigCreatorStepLanguageContainer from './steps/languages/GigCreatorStepLanguageContainer';
import GigCreatorStepQuestionnaireContainer from './steps/questionnaire/GigCreatorStepQuestionnaireContainer';
import { COMPANY_USER } from '../../constants/userScopes';
import {
  COMPANY_CREATE_GIG,
  COMPANY_CREATE_GIG_WELCOME,
  COMPANY_CREATE_GIG_INFO,
  COMPANY_CREATE_GIG_DESCRIPTION,
  COMPANY_CREATE_GIG_CATEGORY,
  COMPANY_CREATE_GIG_LOCATION,
  COMPANY_CREATE_GIG_DATES,
  COMPANY_CREATE_GIG_COMPENSATION,
  COMPANY_CREATE_GIG_PREVIEW,
  COMPANY_CREATE_GIG_AVAILABILITY,
  COMPANY_CREATE_GIG_EXPERIENCE,
  COMPANY_CREATE_GIG_LANGUAGE,
  COMPANY_CREATE_GIG_PHOTO,
  COMPANY_CREATE_GIG_OCCUPATION,
  COMPANY_POSTGIG,
  COMPANY_CREATE_GIG_QUESTIONNAIRE,
} from '../../constants/routes';
import GigCreatorStepPhotoContainer from './steps/photo/GigCreatorStepPhotoContainer';
import GigCreatorStepOccupationContainer from './steps/occupation/GigCreatorStepOccupationContainer';
import CompanyPostGigContainer from './CompanyPostGigContainer';
import ViewportController from '../../common/components/ViewportController';

const { Redirect } = Routing;

const FIRST_SCREEN = COMPANY_CREATE_GIG_WELCOME;

const GigCreatorRouting = () => (
  <ViewportController safeAreaBottom>
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_POSTGIG} component={CompanyPostGigContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CREATE_GIG} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_WELCOME} component={GigCreatorWelcome} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_INFO} component={GigCreatorStepInfoContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_OCCUPATION} component={GigCreatorStepOccupationContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_CATEGORY} component={GigCreatorStepCategoryContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_DESCRIPTION} component={GigCreatorStepDescriptionContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_LOCATION} component={GigCreatorStepLocationContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_DATES} component={GigCreatorStepDatesContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_COMPENSATION} component={GigCreatorStepCompensationContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_AVAILABILITY} component={GigCreatorStepAvailabilityContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_EXPERIENCE} component={GigCreatorStepExperienceContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_LANGUAGE} component={GigCreatorStepLanguageContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_QUESTIONNAIRE} component={GigCreatorStepQuestionnaireContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_PHOTO} component={GigCreatorStepPhotoContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CREATE_GIG_PREVIEW} component={GigCreatorPreviewContainer} />
  </ViewportController>
);

export default GigCreatorRouting;
