import validator from 'validator';
import { loginValidation } from './LoginValidation';

export const USER_FIRST_NAME_MIN_LENGTH = 2;
export const USER_FIRST_NAME_MAX_LENGTH = 40;
export const USER_LAST_NAME_MIN_LENGTH = 2;
export const USER_LAST_NAME_MAX_LENGTH = 40;

export const candidateSignupValidation = [
  // firstName
  {
    field: 'firstName',
    method: validator.isLength,
    args: [{ min: USER_FIRST_NAME_MIN_LENGTH }],
    message: 'too_short',
  },
  {
    field: 'firstName',
    method: validator.isLength,
    args: [{ max: USER_FIRST_NAME_MAX_LENGTH }],
    message: 'too_long',
  },

  // lastName
  {
    field: 'lastName',
    method: validator.isLength,
    args: [{ min: USER_LAST_NAME_MIN_LENGTH }],
    message: 'too_short',
  },
  {
    field: 'lastName',
    method: validator.isLength,
    args: [{ max: USER_LAST_NAME_MAX_LENGTH }],
    message: 'too_long',
  },

];

export const termsValidator = {
  field: 'termsAndPrivacy',
  method: validator.equals,
  args: ['true'],
  message: 'no_terms',
};

export default [
  ...loginValidation,
  ...candidateSignupValidation,
  termsValidator,
];
