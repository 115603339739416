import { colors } from '../../../../common/styles/base.style';
import { GIG_CANDIDATE_STATUS, MATCHING_SOURCE, EMPLOYMENT_CHANNEL } from '../../../../constants/matchingStatus';
import {
  APPLIED_LIST_KEY,
  MATCHED_LIST_KEY,
  OFFERS_LIST_KEY,
  HIRED_LIST_KEY,
  SHORTLISTED_LIST_KEY,
  SUGGESTED_LIST_KEY,
} from './companyGigCandidatesReducer';
import {
  rejectCandidate,
  shortlistCandidate,
  excludeFromSuggested,
  getGigCandidates,
} from './companyGigCandidatesActions';
import { invite } from '../../../candidatesPool/companyCandidatesPoolActions';
import rejectImg from '../../../../assets/icons/buttonRejectGray.png';
import acceptImg from '../../../../assets/icons/buttonAccept.png';
import arrowRight from '../../../../assets/icons/arrows/buttonArrowRightMagenta.png';
import chatBtn from '../../../../assets/icons/chat/startChat.png';
import editBtn from '../../../../assets/icons/edit/editIcon.png';
import inviteBtn from '../../../../assets/icons/menu/iconMyPoolsOn.png';
import { rejectCandidateAlertParams } from '../../../../common/components/alert/alerts';
import { successNotification } from '../../../../common/notifications_center/notificationCenterActions';

const showQuestionnaireAction = {
  label: 'myGigsActions.questionnaire',
  icon: editBtn,
  getAction: (
    {
      candidate: {
        matching: { questionnaire },
      },
    },
    { showQuestionnaire }
  ) => (questionnaire != null && questionnaire.length > 0 ? () => showQuestionnaire() : null),
  supportedContexts: ['GIG_CANDIDATES_LIST'],
};

const definitions = {
  [GIG_CANDIDATE_STATUS.SUGGESTED]: {
    middleColor: colors.bluePurpleTwo,
    middleText: 'suggestedText',
    actions: [
      {
        label: 'myGigsActions.reject',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectCandidateAlertParams,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(excludeFromSuggested(SUGGESTED_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
      {
        label: 'myGigsActions.shortlist',
        icon: acceptImg,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(shortlistCandidate(SUGGESTED_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateShortlisted'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.APPLIED]: {
    middleColor: colors.bluePurpleTwo,
    middleText: 'appliedText',
    actions: [
      {
        label: 'myGigsActions.reject',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectCandidateAlertParams,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(rejectCandidate(APPLIED_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
      {
        label: 'myGigsActions.match',
        icon: acceptImg,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(shortlistCandidate(APPLIED_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateMatched'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
      showQuestionnaireAction,
      {
        label: 'myGigsActions.invite',
        icon: inviteBtn,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
              .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.APPLIED], APPLIED_LIST_KEY)))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL]: {
    middleColor: colors.blueChill,
    middleText: 'pendingShortlistApprovalText',
    actions: [
      {
        label: 'myGigsActions.reject',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectCandidateAlertParams,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(rejectCandidate(SHORTLISTED_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
      {
        label: 'myGigsActions.invite',
        icon: inviteBtn,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
              .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL], SHORTLISTED_LIST_KEY)))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.SHORTLISTED]: {
    matchingCriteria: ['source'],
    [MATCHING_SOURCE.CANDIDATE_APPLY]: {
      middleColor: colors.brightOrange,
      middleText: 'shortlistedAppliedText',
      actions: [
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction:
            (params, { startChat }) => () => startChat(),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        },
        {
          label: 'myGigsActions.sendOffer',
          icon: arrowRight,
          confirmWithAlert: false,
          getAction:
            (params, { openModal }) => () => openModal(),
          supportedContexts: ['GIG_CANDIDATES_LIST'],
        },
        showQuestionnaireAction,
        {
          label: 'myGigsActions.invite',
          icon: inviteBtn,
          confirmWithAlert: false,
          getAction:
            ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
                .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
                .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.SHORTLISTED], MATCHED_LIST_KEY)))
                .then(() => postAction && postAction()),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
          isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
        },
        {
          label: 'myGigsActions.reject',
          icon: rejectImg,
          confirmWithAlert: true,
          alertParams: rejectCandidateAlertParams,
          getAction:
            ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(rejectCandidate(MATCHED_LIST_KEY, gigId, candidateId))
                .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
                .then(() => postAction && postAction()),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        },
      ],
    },
    [MATCHING_SOURCE.SUGGESTED_CANDIDATE]: {
      middleColor: colors.brightOrange,
      middleText: 'shortlistedSuggestedText',
      actions: [
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction:
            (params, { startChat }) => () => startChat(),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        },
        {
          label: 'myGigsActions.sendOffer',
          icon: arrowRight,
          confirmWithAlert: false,
          getAction:
            (params, { openModal }) => () => openModal(),
          supportedContexts: ['GIG_CANDIDATES_LIST'],
        },
        showQuestionnaireAction,
        {
          label: 'myGigsActions.invite',
          icon: inviteBtn,
          confirmWithAlert: false,
          getAction:
            ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
                .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
                .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.SHORTLISTED], MATCHED_LIST_KEY)))
                .then(() => postAction && postAction()),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
          isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
        },
        {
          label: 'myGigsActions.reject',
          icon: rejectImg,
          confirmWithAlert: true,
          alertParams: rejectCandidateAlertParams,
          getAction:
            ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(rejectCandidate(MATCHED_LIST_KEY, gigId, candidateId))
                .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
                .then(() => postAction && postAction()),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        },
      ],
    },
  },
  [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER]: {
    middleColor: colors.seablue,
    middleText: 'pendingGigOfferText',
    actions: [
      {
        label: 'myGigsActions.startChat',
        icon: chatBtn,
        confirmWithAlert: false,
        getAction:
          (params, { startChat }) => () => startChat(),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
      {
        label: 'myGigsActions.viewOffer',
        icon: arrowRight,
        confirmWithAlert: false,
        getAction:
          (params, { openModal }) => () => openModal(true),
        supportedContexts: ['GIG_CANDIDATES_LIST'],
      },
      showQuestionnaireAction,
      {
        label: 'myGigsActions.invite',
        icon: inviteBtn,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
              .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER], OFFERS_LIST_KEY)))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
      },
      {
        label: 'myGigsActions.reject',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectCandidateAlertParams,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(rejectCandidate(OFFERS_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED]: {
    middleColor: colors.brightOrange,
    middleText: 'gigOfferAccepted',
    actions: [
      {
        label: 'myGigsActions.startChat',
        icon: chatBtn,
        confirmWithAlert: false,
        getAction:
          (params, { startChat }) => () => startChat(),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
      {
        label: 'myGigsActions.viewOffer',
        icon: arrowRight,
        confirmWithAlert: false,
        getAction:
          (params, { openModal }) => () => openModal(false),
        supportedContexts: ['GIG_CANDIDATES_LIST'],
      },
      {
        label: 'myGigsActions.hire',
        icon: acceptImg,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { handleBillingOnHire, goToPricingPlan }) => () => {
            const handleHireActions = () => {
              goToPricingPlan(gigId, candidateId);
            };
            handleBillingOnHire(gigId, handleHireActions);
          },
        supportedContexts: ['GIG_CANDIDATES_LIST'],
      },
      showQuestionnaireAction,
      {
        label: 'myGigsActions.invite',
        icon: inviteBtn,
        confirmWithAlert: false,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
              .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED], OFFERS_LIST_KEY)))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
      },
      {
        label: 'myGigsActions.reject',
        icon: rejectImg,
        confirmWithAlert: true,
        alertParams: rejectCandidateAlertParams,
        getAction:
          ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(rejectCandidate(OFFERS_LIST_KEY, gigId, candidateId))
              .then(dispatch(successNotification(translate('myGigsActions.candidateRejected'))))
              .then(() => postAction && postAction()),
        supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
      },
    ],
  },
  [GIG_CANDIDATE_STATUS.HIRED]: {
    matchingCriteria: ['employmentChannel'],
    [EMPLOYMENT_CHANNEL.COMPANY]: {
      middleColor: colors.magenta,
      middleText: 'hiredCompanyText',
      middleSubText: 'hiredCompanySubText',
      actions: [
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction:
            (params, { startChat }) => () => startChat(),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        },
        {
          label: 'myGigsActions.viewOffer',
          icon: arrowRight,
          confirmWithAlert: false,
          getAction:
            (params, { openModal }) => () => openModal(false),
          supportedContexts: ['GIG_CANDIDATES_LIST'],
        },
        showQuestionnaireAction,
        {
          label: 'myGigsActions.invite',
          icon: inviteBtn,
          confirmWithAlert: false,
          getAction:
            ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
                .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
                .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.HIRED], HIRED_LIST_KEY)))
                .then(() => postAction && postAction()),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
          isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
        },
      ],
    },
    [EMPLOYMENT_CHANNEL.LETSGIG]: {
      middleColor: colors.magenta,
      middleText: 'hiredLetsgigText',
      middleSubText: 'hiredLetsgigSubText',
      actions: [
        {
          label: 'myGigsActions.startChat',
          icon: chatBtn,
          confirmWithAlert: false,
          getAction:
            (params, { startChat }) => () => startChat(),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
        },
        {
          label: 'myGigsActions.viewOffer',
          icon: arrowRight,
          confirmWithAlert: false,
          getAction:
            (params, { openModal }) => () => openModal(true),
          supportedContexts: ['GIG_CANDIDATES_LIST'],
        },
        showQuestionnaireAction,
        {
          label: 'myGigsActions.invite',
          icon: inviteBtn,
          confirmWithAlert: false,
          getAction:
            ({ gigId, candidateId }, { dispatch, translate, postAction }) => () => dispatch(invite(candidateId))
                .then(dispatch(successNotification(translate('myGigsActions.candidateInvitedToPool'))))
                .then(dispatch(getGigCandidates(gigId, [GIG_CANDIDATE_STATUS.HIRED], HIRED_LIST_KEY)))
                .then(() => postAction && postAction()),
          supportedContexts: ['GIG_CANDIDATES_LIST', 'CANDIDATE_PROFILE'],
          isActive: (candidate, grantedFeatures) => grantedFeatures.includes('CANDIDATES_POOL') && candidate.poolCandidate == null,
        },
      ],
    },
  },
  [GIG_CANDIDATE_STATUS.REJECTED_BY_CANDIDATE]: {
    middleColor: colors.brownGrey,
    middleText: 'rejectedByCandidate',
  },
  [GIG_CANDIDATE_STATUS.REJECTED_BY_COMPANY]: {
    middleColor: colors.brownGrey,
    middleText: 'rejectedByCompany',
  },
};

export default function getDefinition(matching) {
  const definition = definitions[matching.status];
  if (definition.matchingCriteria) {
    return definition[matching[definition.matchingCriteria]];
  }
  return definition;
}
