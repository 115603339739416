import { matchPath } from 'react-router';
import {
  ROOT,
  WELCOME,
  COMPANY_WELCOME,
  COMPANY_AUTH,
  COMPANY_AUTOCREATE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CANDIDATE_AUTH,
  CANDIDATE_WELCOME,
  ERROR,
  DEEP_LINK_REDIRECT
} from '../../constants/routes';

const displayMenuItemsPathsBlacklist = [
  ROOT,
  WELCOME,
  COMPANY_WELCOME,
  COMPANY_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CANDIDATE_AUTH,
  CANDIDATE_WELCOME,
  ERROR,
  DEEP_LINK_REDIRECT,
];

const renderMenu = (pathname) => !displayMenuItemsPathsBlacklist.includes(pathname) && !matchPath(pathname, COMPANY_AUTOCREATE);
export default renderMenu;
