import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import {
  spacings, colors, fontFamily, fontSizes,
} from '../../../styles/base.style';
import ImageComponent from '../../image/ImageComponent';

import availableIcon from '../../../../assets/icons/profile/statusAvailable.png';
import unAvailableIcon from '../../../../assets/icons/profile/statusUnavailable.png';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 24,
    overflow: 'hidden', // to have radius on ios
    backgroundColor: colors.veryLightPinkTwo,
    paddingHorizontal: spacings.md,
    paddingVertical: 12,
    marginBottom: spacings.lg,
    alignItems: 'center',
  },
  text: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.lg,
    color: colors.brownishGrey,
  },
  image: {
    width: 22,
    height: 22,
  },
  imageWrapper: {
    position: 'absolute',
    left: 15,
    top: 12,
  },
  available: {},
  notAvailable: {},
});

const renderContent = (icon, text, style) => (
  <>
    <ImageComponent style={{ wrapper: styles.imageWrapper, image: styles.image }} image={icon} />
    <Text style={[styles.text, style]}>{text}</Text>
  </>
);

const CandidateProfileAvailabilityInfo = ({ translate, available }) => (
  <View style={styles.container}>
    {available ? (
      renderContent(availableIcon, translate('candidateProfile.details.available'), styles.available)
    ) : (
      renderContent(unAvailableIcon, translate('candidateProfile.details.notAvailable'), styles.notAvailable)
    )}
  </View>
);

CandidateProfileAvailabilityInfo.propTypes = {
  translate: PropTypes.func.isRequired,
  available: PropTypes.bool.isRequired,
};

export default withLocalize(CandidateProfileAvailabilityInfo);
