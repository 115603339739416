import { Platform } from 'react-native';
import { Alert } from './Alert';

export function showConfirmAlert(translate, {
  confirmKey, cancelKey, cancelStyle = true, titleKey, titleArgs, textKey, textArgs, confirmStyle,
}, confirmHandler, cancelHandler = () => { }) {
  Alert.alert(
    translate(titleKey, titleArgs),
    translate(textKey, textArgs),
    [
      { text: translate(cancelKey), style: cancelStyle ? 'cancel' : 'default', onPress: cancelHandler },
      { text: translate(confirmKey), style: confirmStyle || 'default', onPress: confirmHandler },
    ],
    { cancelable: true },
  );
}

export function showAlert(translate, {
  cancelKey, cancelStyle = true, titleKey, titleArgs, textKey, textArgs, confirmStyle,
}, cancelHandler = () => { }) {
  Alert.alert(
    translate(titleKey, titleArgs),
    translate(textKey, textArgs),
    [
      { text: translate(cancelKey), style: cancelStyle ? 'cancel' : 'default', onPress: cancelHandler },
    ],
    { cancelable: true },
  );
}

export function showOptionsAlert(translate, {
  cancelKey, cancelStyle = true, titleKey, textKey, cancelOnPress,
}, options) {
  Alert.alert(
    translate(titleKey),
    translate(textKey),
    [
      { text: translate(cancelKey), style: cancelStyle ? 'cancel' : 'default', onPress: cancelOnPress },
      ...options.map((option) => ({ text: translate(option.optionKey), onPress: option.onPress })),
    ],
    { cancelable: true },
  );
}

export function showInformationAlert(translate, {
  confirmKey, titleKey, titleArgs, textKey, textArgs,
}, confirmHandler) {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line no-alert
    const r = window.alert(translate(textKey));
    if (r === true) {
      confirmHandler();
    }
  } else {
    Alert.alert(
      translate(titleKey, titleArgs),
      translate(textKey, textArgs),
      [
        { text: translate(confirmKey), onPress: confirmHandler },
      ],
      { cancelable: false },
    );
  }
}

export function showAlertMessage(translate, cancelText, text, title, handleClose) {
  showAlert(translate, {
    cancelKey: cancelText,
    textKey: text,
    titleKey: title,
  }, handleClose);
}

export function showConfirmRemoveAlert(translate, handleRemove) {
  showConfirmAlert(translate, {
    confirmKey: 'generic.yes',
    cancelKey: 'generic.cancel',
    textKey: 'generic.alert.remove.text',
    titleKey: 'generic.alert.remove.title',
  }, handleRemove);
}

export function showConfirmGigArchiveAlert(translate, handleArchive) {
  showConfirmAlert(translate, {
    confirmKey: 'candidateMyGigs.actions.archiveTitle',
    cancelKey: 'generic.cancel',
    textKey: 'candidateMyGigs.actions.archiveText',
    titleKey: 'candidateMyGigs.actions.archiveTitle',
  }, handleArchive);
}

export function showConfirmSendOfferAlert(translate, handleSubmit) {
  showConfirmAlert(translate, {
    confirmKey: 'actions.confirmSendOfferBtn',
    cancelKey: 'actions.confirmSendOfferBtnBack',
    titleKey: 'actions.confirmSendOfferTitle',
    textKey: 'actions.confirmSendOfferText',
  }, handleSubmit);
}

export function showConfirmResendOfferAlert(translate, handleSubmit) {
  showConfirmAlert(translate, {
    confirmKey: 'actions.confirmSendOfferBtn',
    cancelKey: 'actions.confirmSendOfferBtnBack',
    titleKey: 'actions.confirmSendOfferTitle',
    textKey: 'actions.confirmSendOfferText',
  }, handleSubmit);
}

export function showCancelSendOfferAlert(translate, handleConfirm) {
  showConfirmAlert(translate, {
    confirmKey: 'actions.confirmBtnYes',
    cancelKey: 'actions.cancelBtnIgnore',
    titleKey: 'actions.cancelSendOfferTitle',
    textKey: 'actions.cancelSendOfferText',
  }, handleConfirm);
}

export function showCancelResendOfferAlert(translate, handleConfirm) {
  showConfirmAlert(translate, {
    confirmKey: 'actions.confirmBtnYes',
    cancelKey: 'actions.cancelBtnIgnore',
    titleKey: 'actions.cancelResendOfferTitle',
    textKey: 'actions.cancelResendOfferText',
  }, handleConfirm);
}

export const rejectCandidateAlertParams = {
  confirmKey: 'generic.reject',
  cancelKey: 'generic.cancel',
  textKey: 'companyGigs.candidates.actions.rejectText',
  titleKey: 'generic.reject',
};

export const rejectGigOfferAlertParams = {
  confirmKey: 'generic.reject',
  cancelKey: 'generic.cancel',
  textKey: 'candidateMyGigs.actions.rejectText',
  titleKey: 'generic.reject',
};

export const rejectGigApplicationAlertParams = {
  confirmKey: 'candidateMyGigs.actions.removeTitle',
  cancelKey: 'generic.cancel',
  textKey: 'candidateMyGigs.actions.removeText',
  titleKey: 'candidateMyGigs.actions.removeTitle',
};
