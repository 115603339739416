// https://github.com/svrcekmichal/redux-axios-middleware
// https://github.com/axios/axios

import axiosMiddleware, { getActionTypes } from 'redux-axios-middleware';
import { sessionTimeout } from '../../common/auth/authActions';
import axiosClient from './axiosClient';
import { connectionErrorNotification, serverErrorNotification, errorNotification } from '../../common/notifications_center/notificationCenterActions';
import { log } from '../../common/log/logActions';

export const ERROR_KEY_GENERAL = 'general';
export const ERROR_KEY_FATAL = 'fatal';

function handleUnauthorized(dispatch, status) {
  if (status === 401) {
    dispatch(sessionTimeout());
  }
}

function handleInternalErrors(dispatch, status, sourceAction) {
  function statusCodeInGroup(groupFirstNumber) {
    return Math.floor(status / 100) === groupFirstNumber;
  }

  if (sourceAction.handleInternalError === false) {
    return;
  }

  if (!statusCodeInGroup(2) && !statusCodeInGroup(3) && !statusCodeInGroup(4)) {
    console.warn('Internal server error', status, sourceAction);
    dispatch(serverErrorNotification());
  }
}

function handleValidationErrors(dispatch, status, sourceAction) {
  const { handleValidationError } = sourceAction;
  if (handleValidationError && status === 400) {
    dispatch(errorNotification(handleValidationError.titleKey, handleValidationError.textKey));
  }
}

export function createErrorResponse(status, errors, message) {
  if (status === 400 || status === 402) {
    return {
      isError: true,
      status,
      errors,
      message,
    };
  }
  return {
    isError: true,
    status,
    errors: { [ERROR_KEY_FATAL]: 'fatal_error' },
    message,
  };
}

function mapBackendErrors(error) {
  const errors = {};
  error.response.data.errors.forEach((err) => {
    if (!err.param) {
      errors[ERROR_KEY_GENERAL] = err.msg;
    } else {
      errors[err.param] = err.msg;
    }
  });
  return errors;
}

const options = {
  interceptors: {
    request: [{
      success: ({ getState }, req) => {
        const { auth } = getState();
        if (auth.get('authenticated')) {
          req.headers.common.Authorization = `Bearer ${auth.get('token')}`;
        }
        // make a deep copy of data, otherwise we might transform some data and have other flows influenced
        if (req.data && !(req.data instanceof FormData)) {
          req.data = JSON.parse(JSON.stringify(req.data));
        }
        return req;
      },
    },
    ],
    response: [{
      success: (utilityFunctions, res) => res.data,
      error: ({ dispatch, getSourceAction }, error) => {
        let response;
        if (!error.response) {
          response = createErrorResponse(null, { [ERROR_KEY_FATAL]: 'fatal_error' }, error.message);
          console.warn('unexpected connection error', error);
          dispatch(connectionErrorNotification());
          dispatch(log(error));
          dispatch(log(error.name));
          dispatch(log(error.stack));
          dispatch(log(error.code));
          dispatch(log(error.message));
        } else {
          const { status } = error.response;
          handleUnauthorized(dispatch, status);
          handleInternalErrors(dispatch, status, getSourceAction(error.config));
          handleValidationErrors(dispatch, status, getSourceAction(error.config));

          response = createErrorResponse(
            status,
            error.response.data ? mapBackendErrors(error) : [],
            error.message,
          );
        }

        return Promise.reject(response);
      },
    },
    ],
  },
  returnRejectedPromiseOnError: false,
  onError: ({ action, next, error }, passedOptions) => {
    // based on default implementation of the middleware, we simplify passed error
    const nextAction = {
      type: getActionTypes(action, passedOptions)[2],
      error,
      meta: {
        previousAction: action,
      },
    };

    next(nextAction);
    return error;
  },
};
export default axiosMiddleware(axiosClient, options);
