import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gigCreatorAddLanguages } from '../../gigCreatorActions';
import GigCreatorStepLanguageScreen from './GigCreatorStepLanguageScreen';
import { getLanguageKey } from './gigLanguageHelper';
import { languagePropType, activeLanguagePropTypes, withGigEditPropTypes } from '../../../../constants/propTypes';
import { LANGUAGE_LEVEL } from '../../../../constants/languageTypes';
import getWorldLanguagesListFor from '../../../../core/localization/languagesListProvider';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepLanguageContainer extends Component {
  constructor(props) {
    super(props);
    this.worldLanguagesList = getWorldLanguagesListFor(this.props.activeLanguage.code);
    this.state = {
      languages: props.languages,
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.LANGUAGE));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.LANGUAGES);
  }

  getFirstNotUsedLanguage = () => {
    const usedLanguagesCode = this.state.languages.map((l) => l.code);
    return this.worldLanguagesList.find((i) => !usedLanguagesCode.includes(i.code));
  };

  handleAddLanguage = () => {
    const firstNotUsedLanguage = this.getFirstNotUsedLanguage();
    const newLanguage = {
      code: (firstNotUsedLanguage && firstNotUsedLanguage.code) || '',
      level: LANGUAGE_LEVEL.NATIVE,
    };
    this.setState({ languages: this.state.languages.concat(newLanguage) });
  };

  handleRemoveLanguage = (key) => {
    this.setState({ languages: this.state.languages.filter((e) => getLanguageKey(e) !== key) });
  };

  handleUpdateLanguage = (oldItem, changedLanguage) => {
    const oldLanguageKey = getLanguageKey(oldItem);
    const currentLanguages = this.state.languages;
    for (let i = 0; i < currentLanguages.length; i += 1) {
      if (getLanguageKey(currentLanguages[i]) === oldLanguageKey) {
        currentLanguages[i] = changedLanguage;
        this.setState({ languages: currentLanguages });
        break;
      }
    }
  };

  handleSubmit = () => {
    this.props.gigCreatorAddLanguages(this.state.languages);

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.LANGUAGES, { languages: this.state.languages })
        .then(() => this.props.history.goBack());
    } else {
      this.setState({ redirect: true });
    }
  };

  handleSkip = () => {
    this.props.gigCreatorAddLanguages([]);
    this.setState({ languages: [], redirect: true });
  };

  render = () => (
    <GigCreatorStepLanguageScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      languages={this.state.languages}
      handleSubmit={this.handleSubmit}
      handleSkip={this.handleSkip}
      handleAddLanguage={this.handleAddLanguage}
      handleRemoveLanguage={this.handleRemoveLanguage}
      handleUpdateLanguage={this.handleUpdateLanguage}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  );
}

GigCreatorStepLanguageContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  gigCreatorAddLanguages: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(languagePropType).isRequired,
  ...withGigEditPropTypes,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      languages,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { languages, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddLanguages })(withLocalize(GigCreatorStepLanguageContainer)));
