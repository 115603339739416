import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { StyleSheet } from 'react-native';
import { LinkButton } from '../../../../common/components/index';
import { spacings } from '../../../../common/styles/base.style';
import GigCreatorHeader from '../../GigCreatorHeader';
import FormikFieldDateTimePicker from '../../../../common/components/form/FormikFieldDateTimePicker';
import FormikFieldCheckBox from '../../../../common/components/form/checkbox/FormikFieldCheckBox';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorStepDatesValidation from './GigCreatorStepDatesValidation';
import Container from '../../../../common/components/Container';
import Wrapper from '../../../../common/components/Wrapper';
import HorizontalWrapper from '../../../../common/components/HorizontalWrapper';
import { startOfToday } from '../../../../utilities/dateUtils';
import Button from '../../../../common/components/buttons/Button';

const dateInputsWrapper = StyleSheet.create({
  dateInputsWrapper: {
    zIndex: 1,
  },
});

const startDateStyle = StyleSheet.create({
  container: {
    flex: 1,
    marginRight: spacings.xs,
    marginTop: spacings.lg,
  },
});

const endDateStyle = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: spacings.xs,
    marginTop: spacings.lg,
  },
});

const clearDateIfChecked = (setFieldValue, dateToClear, val) => {
  if (val) {
    setFieldValue(dateToClear, null);
  }
};

const GigCreatorStepDatesScreen = ({
  translate, initialValues, onSubmit, stepNumber, isEdit, nextScreen, maxStepsNumber,
}) => (
  <Container>
    <FormikWithValidation
      validation={GigCreatorStepDatesValidation}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        status,
        isBtnDisabled,
        setFieldValue,
        values,
      }) => (
        <>
          <GigCreatorHeader
            isEdit={isEdit}
            stepNo={stepNumber}
            maxStepsNumber={maxStepsNumber}
            title={translate('companyGigCreator.dates.header')}
            onRightButtonPress={handleSubmit}
            isRightButtonDisabled={isBtnDisabled}
          />
          <Wrapper>
            <HorizontalWrapper style={dateInputsWrapper} noFlex>
              <FormikFieldDateTimePicker
                name="startDate"
                mode="date"
                disabled={values.noStartDate}
                minimumDateFunc={() => startOfToday()}
                label={translate('companyGigCreator.dates.startDateLabel')}
                style={startDateStyle}
              />
              <FormikFieldDateTimePicker
                name="endDate"
                mode="date"
                disabled={values.noEndDate}
                minimumDateFunc={() => (values.startDate ? values.startDate : startOfToday())}
                label={translate('companyGigCreator.dates.endDateLabel')}
                style={endDateStyle}
              />
            </HorizontalWrapper>
            <FormikFieldCheckBox
              name="noStartDate"
              onPress={(val) => clearDateIfChecked(setFieldValue, 'startDate', val)}
              label={translate('companyGigCreator.dates.noStartDate')}
            />
            <FormikFieldCheckBox
              name="noEndDate"
              onPress={(val) => clearDateIfChecked(setFieldValue, 'endDate', val)}
              label={translate('companyGigCreator.dates.noEndDate')}
            />
          </Wrapper>
          {isEdit ? (
            <Button
              title={translate('generic.save')}
              disabled={isBtnDisabled}
              onPress={handleSubmit}
            />
          ) : (
            <LinkButton
              to={nextScreen}
              title={translate('generic.next')}
              doRedirect={status && status.redirect}
              disabled={isBtnDisabled}
              onPress={handleSubmit}
            />
          )}
        </>
      )}
    />
  </Container>
);

GigCreatorStepDatesScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    startDate: PropTypes.number,
    noStartDate: PropTypes.bool.isRequired,
    endDate: PropTypes.number,
    noEndDate: PropTypes.bool.isRequired,
  }).isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepDatesScreen);
