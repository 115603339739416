import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { dynamicSpacings, shadowTop, spacings } from '../styles/base.style';

const styles = StyleSheet.create({
  buttonsContainer: {
    paddingTop: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
    ...shadowTop,
  },
});

const StickyButtonsWrapper = ({ children, style }) => (
  <View style={[styles.buttonsContainer, style]}>{children}</View>
);

StickyButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

StickyButtonsWrapper.defaultProps = {
  style: null,
};

export default StickyButtonsWrapper;
