export default function getPoolGroupActionSheet({ translate, onDelete, onModify }) {
  const actionSheetParams = { options: [] };
  const actionHandlers = [];
  let optionsSize;

  optionsSize = actionSheetParams.options.push(translate('company.candidatesPool.group.groupModifyAction'));
  actionHandlers[optionsSize - 1] = async () => {
    onModify();
  };

  optionsSize = actionSheetParams.options.push(translate('company.candidatesPool.group.groupDeleteAction'));
  actionSheetParams.destructiveButtonIndex = optionsSize - 1;
  actionHandlers[optionsSize - 1] = async () => {
    onDelete();
  };

  optionsSize = actionSheetParams.options.push(translate('generic.cancel'));
  actionSheetParams.cancelButtonIndex = optionsSize - 1;
  actionHandlers[optionsSize - 1] = () => {};

  return {
    options: {
      ...actionSheetParams,
      showSeparators: true,
    },
    handlers: (buttonIndex) => {
      actionHandlers[buttonIndex]();
    },
  };
}
