import React from 'react';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

import {
  colors, fontFamily, fontSizes, shadowBottom,
} from '../../common/styles/base.style';
import Container from '../../common/components/Container';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import { COMPANY_OPEN_CHAT_GIG_LIST, COMPANY_CHAT_GIG_LIST, COMPANY_CLOSED_CHAT_GIG_LIST } from '../../constants/routes';
import TabBar from '../../common/components/tabs/TabBar';
import { COMPANY_USER } from '../../constants/userScopes';

import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import CompanyActiveChatGigListContainer from './CompanyActiveChatGigListContainer';
import CompanyExpiredChatGigListContainer from './CompanyExpiredChatGigListContainer';
import { font } from '../../common/styles/mixins';
import Routing from '../../core/routing/index';

const { Route, Redirect } = Routing;

const styles = StyleSheet.create({
  header: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxxl, null, colors.greyishBrown),
  },
});

const CompanyChatGigListContainer = ({ translate }) => (
  <>
    <View style={shadowBottom}>
      <ScreenHeader title={translate('companyChat.gigBadgeChats')} style={styles.header} />
      <Route exact path={COMPANY_CHAT_GIG_LIST} render={() => <Redirect to={COMPANY_OPEN_CHAT_GIG_LIST} />} />
      <TabBar items={[
        { textKey: 'companyChat.openHeader', link: COMPANY_OPEN_CHAT_GIG_LIST },
        { textKey: 'companyChat.closedHeader', link: COMPANY_CLOSED_CHAT_GIG_LIST },
      ]}
      />
    </View>
    <Container>
      <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_OPEN_CHAT_GIG_LIST} component={CompanyActiveChatGigListContainer} />
      <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CLOSED_CHAT_GIG_LIST} component={CompanyExpiredChatGigListContainer} />
    </Container>
  </>
);

CompanyChatGigListContainer.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withLocalize(CompanyChatGigListContainer);
