import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { candidateEducationPropType } from '../../../../constants/propTypes';
import { getEducationByKey, getEducationKey, getNewEducation } from '../../profile_creator/steps/education/profileEducationHelper';
import CandidateProfileHistoryList from '../../history_list/CandidateProfileHistoryList';
import Button from '../../../../common/components/buttons/Button';
import { candidateProfileAddEducation, candidateProfileEditEducation, candidateProfileRemoveEducation } from '../../CandidateProfileActions';
import { showConfirmRemoveAlert } from '../../../../common/components/alert/alerts';
import CandidateProfileEducationForm from '../../forms/education/CandidateProfileEducationForm';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import { spacings } from '../../../../common/styles/base.style';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

class CandidateProfileEditDetailsEducationModalAware extends Component {
  openModal = (education) => {
    const {
      translate, setModalContent, closeModal,
    } = this.props;

    setModalContent(
      translate('candidateProfile.details.educationHeader'),
      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileEducationForm}
        closeModal={closeModal}
        education={education}
        handleSubmit={this.handleSaveEducation}
      />,
    );
  };

  handleOpenExistingEducation = (key) => {
    this.openModal(getEducationByKey(this.props.education, key));
  };

  openEmptyModal = () => {
    this.openModal(getNewEducation());
  };

  handleSaveEducation = (educationToSave) => {
    if (educationToSave.tmpEducationId != null) {
      this.props.candidateProfileAddEducation(educationToSave)
        .then((response) => {
          if (!response.isError) {
            this.props.closeModal();
          }
        });
    } else {
      this.props.candidateProfileEditEducation(educationToSave)
        .then((response) => {
          if (!response.isError) {
            this.props.closeModal();
          }
        });
    }
  };

  handleRemoveEducation = (key) => {
    showConfirmRemoveAlert(this.props.translate, () => {
      this.props.candidateProfileRemoveEducation(getEducationByKey(this.props.education, key));
    });
  };

  render() {
    const { translate, education } = this.props;
    return (
      <View style={styles.container}>
        <CandidateProfileHistoryList
          items={education.map((e) => (
            {
              title: e.school,
              subtitle: e.course,
              ...e,
            }
          ))}
          handleRemove={this.handleRemoveEducation}
          handleOpen={this.handleOpenExistingEducation}
          keyExtractor={getEducationKey}
          noHorizontalPadding
        />
        <View style={education.length ? { paddingTop: spacings.xs } : { paddingTop: spacings.md }}>
          <Button
            title={translate('candidateProfileCreator.education.addEducationBtn')}
            onPress={this.openEmptyModal}
          />
        </View>
      </View>
    );
  }
}

CandidateProfileEditDetailsEducationModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateProfileAddEducation: PropTypes.func.isRequired,
  candidateProfileEditEducation: PropTypes.func.isRequired,
  candidateProfileRemoveEducation: PropTypes.func.isRequired,
  education: PropTypes.arrayOf(candidateEducationPropType.isRequired).isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  education: state.candidate.get('profile').get('education').toJS(),
});

export default connect(mapStateToProps, {
  candidateProfileAddEducation,
  candidateProfileEditEducation,
  candidateProfileRemoveEducation,
})(withLocalize(CandidateProfileEditDetailsEducationModalAware));
