import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../common/components/index';
import {
  colors, fontFamily, fontSizes, spacings, standardSpacings,
} from '../../common/styles/base.style';
import StepComponent from '../../common/components/StepComponent';
import { COMPANY_CREATE_GIG_INFO } from '../../constants/routes';
import Wrapper from '../../common/components/Wrapper';
import Container from '../../common/components/Container';
import StickyButtonsWrapper from '../../common/components/StickyButtonsWrapper';
import GigCreatorHeader from './GigCreatorHeader';
import { getStepConfig } from './stepsConfig';
import { withGigEdit } from './withGigEdit';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: standardSpacings.formVertical,
  },
  logo: {
    marginBottom: spacings.sm,
  },
  headerText: {
    width: 196,
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.xl,
    letterSpacing: 0.4,
    color: colors.greyishBrown,
    textAlign: 'center',
    marginBottom: spacings.sm,
  },
  headerOptionalText: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.lg,
    color: colors.greyishBrown,
    textAlign: 'center',
    marginTop: spacings.sm,
    marginBottom: spacings.xxs,
  },
});

class GigCreatorWelcome extends Component {
  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.STEPS);
  }

  render() {
    const {
      isEdit, translate, gigId, grantedFeatures,
    } = this.props;
    const stepConfig = getStepConfig(grantedFeatures);

    return (
      <Container>
        <GigCreatorHeader isEdit={isEdit} mainView />

        <ScrollView>
          {isEdit ? (
            <Wrapper style={styles.container}>
              <StepComponent isEdit={isEdit} gigId={gigId} steps={stepConfig.filter((s) => s.isEditable)} translationPrefix="companyGigCreator.welcome" />
            </Wrapper>
          ) : (
            <Wrapper style={styles.container}>
              <Text style={styles.headerText}>{translate('companyGigCreator.welcome.subheader')}</Text>
              <StepComponent steps={stepConfig.filter((s) => s.required)} translationPrefix="companyGigCreator.welcome" />
              <Text style={styles.headerOptionalText}>{translate('companyGigCreator.welcome.headerOptional')}</Text>
              <StepComponent steps={stepConfig.filter((s) => !s.required)} translationPrefix="companyGigCreator.welcome" />
            </Wrapper>
          )}

        </ScrollView>

        {isEdit ? null
          : (
            <StickyButtonsWrapper>
              <LinkButton to={COMPANY_CREATE_GIG_INFO} title={translate('companyGigCreator.welcome.button')} />
            </StickyButtonsWrapper>
          )}
      </Container>
    );
  }
}

GigCreatorWelcome.propTypes = {
  translate: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  gigId: PropTypes.string,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCreatorWelcome.defaultProps = {
  gigId: '',
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default withGigEdit(mapStateToProps, null)(withLocalize(GigCreatorWelcome));
