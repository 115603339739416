import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../common/components/index';
import { spacings } from '../../common/styles/base.style';
import { CANDIDATE_CREATE_PROFILE, CANDIDATE_PROFILE } from '../../constants/routes';
import BackgroundImageScreen from '../../common/screens/BackgroundImageScreen';
import { BUTTON_TYPES } from '../../common/components/buttons/Button';
import Header from '../../common/components/header/Header';
import PrimaryText from '../../common/components/PrimaryText';
import ViewportController from '../../common/components/ViewportController';
import { setSplashScreenVisited } from '../../common/auth/authActions';

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: spacings.md,
  },
  buttonsContainer: {
    flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    maxWidth: 320,
  },
});

const backgroundSrc = require('../../assets/photos/afterLogin/afterLogin.jpg');

class CandidateWelcomeSplashScreen extends Component {
  componentWillUnmount() {
    this.props.setSplashScreenVisited();
  }

  render() {
    const { translate } = this.props;
    return (
      <ViewportController safeAreaBottom statusBarHidden>
        <BackgroundImageScreen containerHeight={300} img={backgroundSrc}>
          <View style={styles.buttonsContainer}>
            <Header>{translate('candidateWelcomeScreen.splashScreen.header')}</Header>
            <Header style={[styles.marginBottom]}>{translate('candidateWelcomeScreen.splashScreen.text1')}</Header>
            <PrimaryText style={[styles.text]}>{translate('candidateWelcomeScreen.splashScreen.text2')}</PrimaryText>
            <LinkButton to={CANDIDATE_CREATE_PROFILE} title={translate('candidateWelcomeScreen.splashScreen.button1')} />
            <LinkButton to={CANDIDATE_PROFILE} type={BUTTON_TYPES.PRIMARY_BLUE} title={translate('candidateWelcomeScreen.splashScreen.button2')} />
          </View>
        </BackgroundImageScreen>
      </ViewportController>
    );
  }
}

CandidateWelcomeSplashScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  setSplashScreenVisited: PropTypes.func.isRequired,
};

export default connect(null, { setSplashScreenVisited })(withLocalize(CandidateWelcomeSplashScreen));
