import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CompanyGigsListItem from '../gigs/components/CompanyGigsListItem';
import { gigItemPropType } from '../../constants/propTypes';

class CompanyChatGigListItem extends PureComponent {
  render() {
    const unreadChats = this.props.candidateChats.filter((c) => c.unread).length;

    return (
      <CompanyGigsListItem
        {...this.props}
        grayOutName={!unreadChats}
      />
    );
  }
}

CompanyChatGigListItem.propTypes = {
  translate: PropTypes.func.isRequired,
  ...gigItemPropType,
};

export default CompanyChatGigListItem;
