import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import CompanyGigsListItem from '../components/CompanyGigsListItem';
import Routing from '../../../core/routing/index';
import { COMPANY_GIG_MATCHING } from '../../../constants/routes';
import { prepareGigDataToPreview } from '../companyGigActions';
import BaseListItem from '../../../common/components/BaseListItem';
import ListItemBadge from '../components/ListItemBadge';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import { gigItemPropType } from '../../../constants/propTypes';
import { GIG_CANDIDATE_STATUS } from '../../../constants/matchingStatus';
import { colors, spacings } from '../../../common/styles/base.style';
import { GIG_CANDIDATE_STATUS_CONFIG } from '../../../constants/gigCandidateStatusConfig';
import { COMPANY_USER } from '../../../constants/userScopes';

const { withRouter } = Routing;

const {
  APPLIED,
  PENDING_SHORTLIST_APPROVAL,
  SHORTLISTED,
  GIG_OFFER_ACCEPTED,
  HIRED,
} = GIG_CANDIDATE_STATUS;

class CompanyGigsList extends Component {
  onPressItem = (gigId) => {
    this.props.prepareGigDataToPreview(gigId);
    this.props.history.push(COMPANY_GIG_MATCHING.replace(':id', gigId));
  };

  render() {
    const {
      translate, gigs, refreshing, onRefresh, noGigsTextKey, newContentIndicators,
    } = this.props;

    return (
      <PrimaryFlatList
        emptyListTextKey={noGigsTextKey}
        data={gigs}
        renderItem={({ item: gig }) => (
          <BaseListItem
            renderMainContent={() => (
              <CompanyGigsListItem
                key={gig.gigId}
                {...gig}
                translate={translate}
                isNewContent={!!newContentIndicators.find(({ gigId }) => gigId === gig.gigId)}
              />
            )}
            renderFooter={() => (
              <View style={{ borderTopWidth: 1, borderTopColor: colors.veryLightGrey, paddingTop: spacings.xs }}>
                <ListItemBadge
                  value={gig.candidatesCount[APPLIED]}
                  label={translate('statuses.applied')}
                  routeConfig={GIG_CANDIDATE_STATUS_CONFIG[APPLIED][COMPANY_USER](gig.gigId)}
                />
                <ListItemBadge
                  value={gig.candidatesCount[PENDING_SHORTLIST_APPROVAL]}
                  label={translate('statuses.companyPendingShortlisting')}
                  routeConfig={GIG_CANDIDATE_STATUS_CONFIG[PENDING_SHORTLIST_APPROVAL][COMPANY_USER](gig.gigId)}
                />
                <ListItemBadge
                  value={gig.candidatesCount[SHORTLISTED]}
                  label={translate('statuses.shortlisted')}
                  routeConfig={GIG_CANDIDATE_STATUS_CONFIG[SHORTLISTED][COMPANY_USER](gig.gigId)}
                />
                <ListItemBadge
                  value={gig.candidatesCount[GIG_OFFER_ACCEPTED]}
                  label={translate('statuses.gigOfferAccepted')}
                  routeConfig={GIG_CANDIDATE_STATUS_CONFIG[GIG_OFFER_ACCEPTED][COMPANY_USER](gig.gigId)}
                />
                <ListItemBadge
                  value={gig.candidatesCount[HIRED]}
                  label={translate('statuses.hired')}
                  routeConfig={GIG_CANDIDATE_STATUS_CONFIG[HIRED][COMPANY_USER](gig.gigId)}
                />
              </View>
            )}
            onPress={() => this.onPressItem(gig.gigId)}
          />
        )}
        keyExtractor={(item) => item.gigId}
        onRefresh={onRefresh}
        refreshing={refreshing}
      />
    );
  }
}

CompanyGigsList.propTypes = {
  noGigsTextKey: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  gigs: PropTypes.arrayOf(PropTypes.shape(gigItemPropType)),
  history: ReactRouterPropTypes.history.isRequired,
  onRefresh: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  prepareGigDataToPreview: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.shape({
    gigId: PropTypes.string.isRequired,
    gigCandidateStatus: PropTypes.string.isRequired,
  })).isRequired,
};

CompanyGigsList.defaultProps = {
  gigs: null,
};

const mapStateToProps = (state) => ({
  prepareGigDataToPreview,
  newContentIndicators: state.companyGig.get('gigsList').get('newContentIndicators').toJS(),
});

export default withRouter(connect(mapStateToProps, { prepareGigDataToPreview })(withLocalize(CompanyGigsList)));
