import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import Wrapper from '../../../../common/components/Wrapper';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import { MAX_PERSONAL_NUMBER_LENGTH, personalNumberValidation } from './personalNumberValidation';

const CandidateProfilePersonalNumberForm = ({
  translate, renderFooter, handleSubmit, personalNumber,
}) => (
  <FormikWithValidation
    validation={personalNumberValidation}
    initialValues={{ personalNumber }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <KeyboardAwareFlexWrapper>
          <Wrapper>
            <FormikFieldPrimaryTextInput
              name="personalNumber"
              label={translate('candidateProfile.details.personalNumberLabel')}
              placeholder={translate('candidateProfile.details.personalNumberPlaceholder')}
              maxLength={MAX_PERSONAL_NUMBER_LENGTH}
              returnKeyType="done"
              onSubmitEditing={handleSubmitFormik}
            />
            <Text>{translate('candidateProfile.details.personalNumberPlaceholder')}</Text>
          </Wrapper>
        </KeyboardAwareFlexWrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfilePersonalNumberForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  personalNumber: PropTypes.string,
};

CandidateProfilePersonalNumberForm.defaultProps = {
  personalNumber: undefined,
};

export default withLocalize(CandidateProfilePersonalNumberForm);
