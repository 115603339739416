import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import GigCreatorHeader from '../../GigCreatorHeader';
import Button, { BUTTON_SIZES } from '../../../../common/components/buttons/Button';
import LinkButton from '../../../../common/components/buttons/LinkButton';
import { dynamicSpacings, shadowTop, spacings } from '../../../../common/styles/base.style';
import Container from '../../../../common/components/Container';
import { questionnairePropType } from '../../../../constants/propTypes';
import QuestionnaireForm from '../../../../common/questionnaire/QuestionnaireForm';
import { isQuestionnaireValid } from './GigCreatorStepQuestionnaireValidation';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import PrimaryKeyboardAwareScrollView from '../../../../common/keyboard/PrimaryKeyboardAwareScrollView';

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    paddingTop: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
    ...shadowTop,
  },
});

const GigCreatorStepQuestionnaireScreen = ({
  translate,
  questionnaire,
  handleSubmit,
  handleSkip,
  handleAddQuestion,
  handleRemoveQuestion,
  handleUpdateQuestion,
  stepNumber,
  nextScreen,
  redirect,
  isEdit,
  maxStepsNumber,
}) => (
  <Container>
    <GigCreatorHeader
      isEdit={isEdit}
      stepNo={stepNumber}
      maxStepsNumber={maxStepsNumber}
      title={translate('companyGigCreator.questionnaire.header')}
      optional
      onRightButtonPress={handleSubmit}
      isRightButtonDisabled={!isQuestionnaireValid(questionnaire)}
    />
    <PrimaryKeyboardAwareScrollView>
      <KeyboardAwareFlexWrapper>
        <QuestionnaireForm
          questionnaire={questionnaire}
          handleAddQuestion={handleAddQuestion}
          handleRemoveQuestion={handleRemoveQuestion}
          handleUpdateQuestion={handleUpdateQuestion}
          formDescription={translate('companyGigCreator.questionnaire.stepDescription')}
          btnTitle={translate('companyGigCreator.questionnaire.addQuestionBtn')}
        />
      </KeyboardAwareFlexWrapper>
      <View style={[styles.buttonsContainer, isEdit ? { justifyContent: 'center' } : null]}>
        {isEdit ? (<Button title={translate('generic.save')} onPress={handleSubmit} disabled={!isQuestionnaireValid(questionnaire)} />)
          : (
            <>
              <LinkButton
                size={BUTTON_SIZES.FLEX_MD}
                type="secondary"
                to={nextScreen}
                title={translate('generic.skip')}
                onPress={handleSkip}
              />
              <LinkButton
                size={BUTTON_SIZES.FLEX_MD}
                to={nextScreen}
                title={translate('generic.next')}
                doRedirect={redirect}
                disabled={!questionnaire || questionnaire.length === 0 || !isQuestionnaireValid(questionnaire)}
                onPress={handleSubmit}
              />
            </>
          )}
      </View>
    </PrimaryKeyboardAwareScrollView>
  </Container>
);

GigCreatorStepQuestionnaireScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  questionnaire: PropTypes.arrayOf(questionnairePropType).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  handleUpdateQuestion: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepQuestionnaireScreen);
