import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { colors, fontFamily, fontSizes } from '../../common/styles/base.style';
import { activeLanguagePropTypes, gigCompensation, stylePropTypes } from '../../constants/propTypes';
import { font } from '../../common/styles/mixins';
import { getSalaryText } from '../../common/gig/salaryFormatter';

const styles = StyleSheet.create({
  salary: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.md, 0.4, colors.greenishTeal),
  },
});

function renderSalary(translate, compensation, activeLanguage) {
  const t = (key) => translate(`candidate.gigOverview.salary.${key}`);
  return getSalaryText(compensation, translate(`gigSalary.per.${compensation.salary.per}`), t, activeLanguage.code);
}

const GigSalary = ({
  compensation,
  style,
  translate,
  activeLanguage,
  ...props
}) => (
  <Text style={[styles.salary, style]} {...props}>{renderSalary(translate, compensation, activeLanguage)}</Text>
);

GigSalary.propTypes = {
  translate: PropTypes.func.isRequired,
  compensation: gigCompensation.isRequired,
  style: stylePropTypes,
  activeLanguage: activeLanguagePropTypes.isRequired,
};
GigSalary.defaultProps = {
  style: undefined,
};

export default withLocalize(GigSalary);
