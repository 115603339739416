import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import CandidateChatGigListItem from './CandidateChatGigListItem';
import { candidateChatGigItemPropType } from '../../constants/propTypes';
import Container from '../../common/components/Container';
import PrimaryFlatList from '../../common/components/PrimaryFlatList';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import { shadowBottom } from '../../common/styles/base.style';
import ChatUnavailableMessage from '../../common/chat/ChatUnavailableMessage';

const CandidateChatGigListScreen = ({
  chats, usersPresence, onPress, translate, error, refreshing, onRefresh,
}) => {
  const renderList = () => (
    <PrimaryFlatList
      emptyListTextKey="candidateChat.noChats"
      data={chats}
      refreshing={refreshing}
      onRefresh={onRefresh}
      renderItem={
        ({ item: gig }) => (
          <CandidateChatGigListItem
            key={gig.gigId}
            {...gig}
            onPress={onPress}
            translate={translate}
            online={usersPresence[gig.companyUserId]}
          />
        )
      }
      keyExtractor={(item) => item.gigId}
    />
  );

  return (
    <Container>
      <View style={shadowBottom}>
        <ScreenHeader title={translate('candidateChat.chatListHeader')} />
      </View>
      <Container>
        {error ? <ChatUnavailableMessage /> : renderList()}
      </Container>
    </Container>
  );
};

CandidateChatGigListScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  chats: PropTypes.arrayOf(candidateChatGigItemPropType).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,

};

export default withLocalize(CandidateChatGigListScreen);
