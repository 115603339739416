import React from 'react';
import PropTypes from 'prop-types';

const AppearanceProviderForMobiles = ({ children }) => (
  <>
    {children}
  </>
);

AppearanceProviderForMobiles.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

export default AppearanceProviderForMobiles;
