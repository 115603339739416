import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { ActivityIndicator } from 'react-native';

import { getConfigurationByKey } from '../configuration/configurationActions';
import { WELCOME_CAROUSEL } from '../../constants/configurationTypes';
import { WELCOME } from '../../constants/routes';
import Routing from '../../core/routing/index';
import { activityIndicator } from '../styles/base.style';
import googleAnalytics, { ACTIONS } from '../analytics/googleAnalytics';
import WelcomeCarouselScreen from './WelcomeCarouselScreen';
import WelcomeCarouselSlide from './WelcomeCarouselSlide';

const { withRouter } = Routing;

class WelcomeCarouselContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      screens: [],
      inProgress: true,
    };
  }

  componentDidMount() {
    this.props.getConfigurationByKey(WELCOME_CAROUSEL).then((res) => {
      if (!res.isError && Array.isArray(res.payload) && res.payload.length > 0) {
        googleAnalytics.trackUserEvent(ACTIONS.USER.WELCOME_CAROUSEL_SHOW);
        this.setState({ screens: res.payload, inProgress: false });
      } else {
        this.quit();
      }
    });
  }

  quit() {
    this.props.history.replace(WELCOME);
  }

  render() {
    const { inProgress, screens } = this.state;

    if (inProgress) {
      return (<ActivityIndicator size="large" style={activityIndicator} />);
    }

    return (
      <WelcomeCarouselScreen onQuit={() => this.quit()} screens={screens}>
        {screens.map((screen, index) => (
          <WelcomeCarouselSlide
            key={index + 1}
            screenNumber={index + 1}
            screen={screen}
          />
        ))}
      </WelcomeCarouselScreen>
    );
  }
}

WelcomeCarouselContainer.propTypes = {
  getConfigurationByKey: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default connect(null, { getConfigurationByKey })(withRouter(WelcomeCarouselContainer));
