import validator from 'validator';
import { companyNameValidator, companyRegistrationNumberValidators } from '../../auth/CompanySignupValidation';
import { candidateSignupValidation } from '../../../common/auth/UserSignupValidation';
import { phoneNumberValidation } from '../../../common/msisdn/phoneNumberValidation';

export const COMPANY_DESCRIPTION_MAX_LENGTH = 500;

export const COMPANY_DESCRIPTION_MIN_LENGTH = 2;

export const companyProfileValidation = [
  ...candidateSignupValidation,
  ...companyNameValidator,
  ...companyRegistrationNumberValidators,
  ...phoneNumberValidation,
  {
    field: 'companyDescription',
    method: validator.isLength,
    args: [{ max: COMPANY_DESCRIPTION_MAX_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'companyDescription',
    custom: (value) => (value ? value.length >= COMPANY_DESCRIPTION_MIN_LENGTH : true),
    message: 'too_short',
  },
];
