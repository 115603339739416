import { Platform, Share } from 'react-native';
import config from '../../config/config';
import googleAnalytics, { ACTIONS } from '../analytics/googleAnalytics';

function prepareShareData(shortId, platform) {
  const url = `${config.mainPageUrl}/gig/${shortId}`;
  const message = 'Check this offer!';
  switch (platform) {
    case 'ios':
      return { message, url };
    case 'android':
      return { message: `${message} ${url}` };
    default:
      return { message, url };
  }
}

export const shareGig = async (shortId) => {
  googleAnalytics.trackGigEvent(shortId, ACTIONS.GIG.SHARE);
  await Share.share(prepareShareData(shortId, Platform.OS));
};
