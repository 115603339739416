import React from 'react';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { spacings } from '../../styles/base.style';
import PrimaryText from '../../components/PrimaryText';
import Wrapper from '../../components/Wrapper';
import { filledOutQuestionnairePropType } from '../../../constants/propTypes';
import SectionHeader from '../../components/header/SectionHeader';
import { PrimaryTextInput } from '../../components';

const styles = StyleSheet.create({
  answer: {
    marginHorizontal: spacings.lg,
    marginBottom: spacings.lg,
  },
});

const inputStyle = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
  input: {
    marginRight: spacings.xs,
  },
});

const MAX_ANSWER_LENGTH = 1200;

const FilledOutQuestionnaireScreen = (props) => {
  const {
    questionnaire,
    isEdit,
    handleUpdateAnswer,
    translate,
  } = props;

  return (
    <>
      {questionnaire.map((entry) => (
        <View key={entry.question.questionId}>
          <SectionHeader title={entry.question.text} />
          { !isEdit && <PrimaryText style={styles.answer}>{entry.answer.text}</PrimaryText> }
          { isEdit && handleUpdateAnswer
            && (
            <Wrapper>
              <PrimaryTextInput
                value={entry.answer.text}
                style={inputStyle.input}
                onChangeText={(answer) => {
                  handleUpdateAnswer(entry.question.questionId, answer);
                }}
                multiline
                numberOfLines={1}
                placeholder={translate('candidate.gigDetails.questionnaire.answerPlaceholder')}
                maxLength={MAX_ANSWER_LENGTH}
              />
            </Wrapper>
            )}
        </View>
      ))}
    </>
  );
};

FilledOutQuestionnaireScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  questionnaire: PropTypes.arrayOf(filledOutQuestionnairePropType).isRequired,
  handleUpdateAnswer: PropTypes.func,
  isEdit: PropTypes.bool,
};

FilledOutQuestionnaireScreen.defaultProps = {
  isEdit: false,
  handleUpdateAnswer: undefined,
};

export default withLocalize(FilledOutQuestionnaireScreen);
