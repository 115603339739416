import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CompanyGigsList from './gigDetails/CompanyGigsList';
import { getClosedGigs, clearGigList } from './companyGigActions';
import { gigItemPropType } from '../../constants/propTypes';

class CompanyClosedGigsContainer extends Component {
  constructor(props) {
    super(props);
    this.refreshing = false;
  }
  componentDidMount() {
    this.props.gigGetClosedGigs();
  }
  componentDidUpdate() {
    this.refreshing = false;
  }
  componentWillUnmount() {
    this.props.clearGigList('closed');
  }
  render() {
    return (
      <CompanyGigsList
        noGigsTextKey="companyGigs.noClosedGigs"
        gigs={this.props.closedGigs}
        refreshing={this.refreshing}
        onRefresh={this.props.gigGetClosedGigs}
      />
    );
  }
}

CompanyClosedGigsContainer.propTypes = {
  gigGetClosedGigs: PropTypes.func.isRequired,
  clearGigList: PropTypes.func.isRequired,
  closedGigs: PropTypes.arrayOf(PropTypes.shape(gigItemPropType)),
};

CompanyClosedGigsContainer.defaultProps = {
  closedGigs: null,
};

const mapStateToProps = (state) => ({
  closedGigs: state.companyGig.get('gigsList').get('closed') ? state.companyGig.get('gigsList').get('closed').toJS() : null,
});

export default connect(mapStateToProps, { gigGetClosedGigs: getClosedGigs, clearGigList })(CompanyClosedGigsContainer);
