import { Map } from 'immutable';
import { SET_RATE_APP_POPUP_TIMESTAMP } from '../../constants/actionTypes';

const INITIAL_APP_STATE = {
  lastRateAppPopupTimestamp: 0,
};

export default function updateReducer(state = Map(INITIAL_APP_STATE), action) {
  switch (action.type) {
    case SET_RATE_APP_POPUP_TIMESTAMP:
      return state.set('lastRateAppPopupTimestamp', action.lastRateAppTimestamp);
    default:
      return state;
  }
}
