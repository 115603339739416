import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { adminFindCompany, adminFindCompanyChangeProviderCompanyIdSearch } from '../../AdminCompanyActions';
import { adminFindGigsFilterPropType } from '../../../../constants/propTypes';
import { windowWidth, spacings } from '../../../../common/styles/base.style';
import { Button } from '../../../../common/components';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikFieldNumberInput from '../../../../common/components/form/FormikFieldNumberInput';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pagination: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationWrapper: {
    flexDirection: 'row',
  },
  column: {
    minWidth: 290,
    marginRight: 30,
  },
  page: {
    width: 50,
  },
  resultsWrapper: {
    justifyContent: 'center',
    marginBottom: spacings.sm,
    marginLeft: spacings.lg,
  },
});

class AdminFindCompanyFilters extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.windowWidth = windowWidth;
  }

  doSearch = async (values, { navigate }) => {
    const modifiedValues = { ...values, limit: values.limit.toString(), page: values.page.toString() };
    if (!navigate) {
      modifiedValues.page = '1';
    }
    this.props.adminFindCompanyChangeProviderCompanyIdSearch(modifiedValues);
    this.formik.current.setFieldValue('page', modifiedValues.page);

    const {
      registerNumber,
      companyName,
      limit,
      page,
    } = modifiedValues;

    this.props.adminFindCompany({
      registerNumber,
      companyName,
      limit,
      page
    });
  };

  changePage = (setFieldValue, values, difference) => {
    const newPage = (Number(values.page) + difference).toString();
    setFieldValue('page', newPage);
    return { ...values, page: newPage };
  };

  render() {
    const { translate } = this.props;

    const t = (key) => translate(`adminFindCompanyScreen.${key}`);

    return (
      <>
        <View style={{ paddingBottom: spacings.xs, paddingTop: spacings.xxxxl }}>
          <Formik validate={false} onSubmit={this.doSearch} initialValues={this.props.formValues} innerRef={this.formik}>
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                <View style={styles.wrapper}>
                  <View style={styles.column}>
                    <FormikFieldPrimaryTextInput
                      name="registerNumber"
                      label={t('registerNumberLabel')}
                      placeholder={t('registerNumberLabel')}
                      disabled={false}
                    />
                    <FormikFieldPrimaryTextInput
                      name="companyName"
                      label={t('companyNameLabel')}
                      placeholder={t('companyNameLabel')}
                      disabled={false}
                    />
                  </View>
                </View>
                <Button size="md" title={translate('generic.search')} onPress={handleSubmit} />
                {this.props.adminCompanyPageInfo && this.props.adminCompanyPageInfo.totalPages > 0 && (
                  <View style={styles.pagination}>
                    <View style={styles.paginationWrapper}>
                      <Button
                        type="text"
                        size="md"
                        title={t('previous')}
                        disabled={Number(values.page) === 1}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, -1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginRight: spacings.md } }}
                      />
                      <FormikFieldNumberInput
                        name="page"
                        label={t('page')}
                        rightLabel={`/${this.props.adminCompanyPageInfo.totalPages}`}
                        onChange={() => this.doSearch(values, { navigate: true })}
                        style={styles.page}
                      />
                      <Button
                        type="text"
                        size="md"
                        title={translate('generic.next')}
                        disabled={Number(values.page) === this.props.adminCompanyPageInfo.totalPages}
                        onPress={() => {
                          const vs = this.changePage(setFieldValue, values, 1);
                          this.doSearch(vs, { navigate: true });
                        }}
                        style={{ wrapper: { marginLeft: spacings.md } }}
                      />
                      <View style={styles.resultsWrapper}>
                        <Text>{`${this.props.adminCompanyPageInfo.totalDocs} results`}</Text>
                      </View>
                    </View>
                  </View>
                )}
              </>
            )}
          </Formik>
        </View>
      </>
    );
  }
}

AdminFindCompanyFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  formValues: adminFindGigsFilterPropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  adminCompanyPageInfo: PropTypes.any.isRequired,

  adminFindCompanyChangeProviderCompanyIdSearch: PropTypes.func.isRequired,
  adminFindCompany: PropTypes.func.isRequired,
};

AdminFindCompanyFilters.defaultProps = {};

const mapStateToProps = (state) => {
  const formValues = state.admin.get('companies').get('formValues').toJS();

  return {
    formValues,
    availableCategories: state.gigCategories.toJS(),
    availableCities: state.gigCities.toJS(),
    adminCompanyPageInfo: state.admin.get('companies').get('adminCompanyPageInfo').toJS(),
  };
};

export default connect(mapStateToProps, {
  adminFindCompanyChangeProviderCompanyIdSearch,
  adminFindCompany,
})(withLocalize(AdminFindCompanyFilters));
