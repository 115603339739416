import config from '../../config/config';
import { createErrorResponse } from './axiosRequestsMiddleware';

/*
We need to use fetch in this case because of axios bug: https://github.com/axios/axios/issues/1321
 */
export function uploadImage(imageUri, url, action) {
  return (dispatch, getState) => {
    dispatch({ type: action });

    const formData = new FormData();
    formData.append('img', { uri: imageUri, type: 'image/jpeg', name: 'img' });

    return fetch(`${config.baseUrl}/${url}`, {
      method: 'post',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${getState().auth.get('token')}`,
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      const errorResponse = createErrorResponse(response.status, []);
      dispatch({ type: `${action}_FAIL`, error: errorResponse });
      return errorResponse;
    })
      .then((responseJson) => {
        dispatch({ type: `${action}_SUCCESS`, payload: responseJson });
        return responseJson;
      })
      .catch((error) => {
        const errorResponse = createErrorResponse(null, [], error);
        dispatch({ type: `${action}_FAIL`, error: errorResponse });
        return errorResponse;
      });
  };
}
