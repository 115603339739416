import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CheckBox as DefaultCheckBox } from '../../index';
import { getValueByPath } from '../../../../utilities/objectUtils';

class FormikCheckBox extends PureComponent {
  handleOnPress = () => {
    const {
      field: { name, value }, form: {
        setFieldValue, setFieldTouched,
      }, ...props
    } = this.props;

    const newValue = !value;
    setFieldValue(name, newValue);
    setFieldTouched(name, true);
    if (props.onPress) {
      props.onPress(newValue);
    }
  };

  render() {
    const {
      field: { name, value }, form: {
        touched, errors,
      },
      CheckBox,
      ...props
    } = this.props;

    return (
      <CheckBox
        {...props}
        onPress={this.handleOnPress}
        value={value}
        error={(getValueByPath(touched, name) && errors[name]) || null}
      />
    );
  }
}

FormikCheckBox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onPress: PropTypes.func,
  CheckBox: PropTypes.func,
};

FormikCheckBox.defaultProps = {
  onPress: null,
  CheckBox: DefaultCheckBox,
};

const FormikFieldCheckBox = (props) => (
  <Field
    component={FormikCheckBox}
    {...props}
  />
);

FormikFieldCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

FormikFieldCheckBox.defaultProps = {
  onPress: undefined,
  disabled: false,
  iconPosition: 'left',
};

export default FormikFieldCheckBox;
