import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Platform, StyleSheet, Text, View,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { colors, fontFamily, spacings } from '../../../common/styles/base.style';
import { formatDuration } from '../../../utilities/dateFormatter';
import Touchable from '../../../common/components/Touchable';
import ClickableListItem from '../../../common/components/ClickableListItem';
import UserScope from '../../../common/auth/UserScope';
import { CANDIDATE_USER } from '../../../constants/userScopes';

const DATE_FORMAT = 'MMM YYYY';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  contentWrapperEditRow: {
    display: 'flex',
    backgroundColor: colors.white,
    paddingHorizontal: spacings.md,
    paddingVertical: spacings.sm,
  },
  contentWrapperViewRow: {
    flex: 1,
    flexDirection: 'column',
    paddingVertical: spacings.sm,
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: spacings.sm,
  },
  flexDirectionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: spacings.xxs,
  },
  datesFromToWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  datesFromTo: {
    fontSize: 15,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayRegular,
    alignItems: 'flex-start',
  },
  datesOngoing: {
    fontSize: 12,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayBold,
    backgroundColor: colors.veryLightPinkTwo,
    paddingHorizontal: 10,
    paddingVertical: spacings.xxs,
    borderRadius: 13,
    overflow: 'hidden',
  },
  datesDuration: {
    fontSize: 15,
    color: colors.brownGrey,
    fontFamily: fontFamily.SFProDisplayBold,
    flex: 1,
    alignSelf: 'center',
    textAlign: 'right',
  },
  needsHandlingBadge: {
    fontSize: 13,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayBold,
    backgroundColor: colors.gold,
    paddingHorizontal: 12,
    paddingVertical: spacings.xxs,
    borderRadius: 13,
    overflow: 'hidden',
    marginLeft: spacings.xs,
  },
  title: {
    fontSize: 19,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayBold,
  },
  subtitle: {
    fontSize: 15,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayBold,
    marginBottom: spacings.xs,
  },
  category: {
    fontSize: 15,
    color: colors.brownGrey,
    fontFamily: fontFamily.SFProDisplayBold,
    marginBottom: spacings.xs,
  },
  description: {
    fontSize: 15,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayRegular,
    paddingBottom: spacings.xs,
  },
  remove: {
    color: colors.magenta,
  },
  graduationInfo: {
    fontSize: 15,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayBold,
    paddingBottom: spacings.xs,
  },
});

// PureComponent will only work if there are no open or remove handlers. ProfileHistoryList has some inline functions right now for handlers...
class CandidateProfileHistoryListItem extends PureComponent {
  getFormattedDates = (from, toDate, ongoing) => {
    const fromStr = moment.utc(from).format(DATE_FORMAT);
    const toStr = ongoing ? this.props.translate('candidateProfile.experience.ongoing') : moment.utc(toDate).format(DATE_FORMAT);
    return (
      <View style={styles.datesFromToWrapper}>
        <Text style={styles.datesFromTo}>{fromStr}</Text>
        <Text> - </Text>
        {ongoing
          ? <Text style={styles.datesOngoing}>{toStr.toUpperCase()}</Text>
          : <Text style={styles.datesFromTo}>{toStr}</Text>}
      </View>
    );
  };

  getDatesDuration = (from, to, ongoing) => {
    const fromDate = moment.utc(from);
    const toDate = ongoing ? moment().utc() : moment.utc(to);

    return formatDuration(fromDate, toDate);
  };

  getGraduationInfo = (graduation) => {
    const graduationDate = moment.utc(graduation).format(DATE_FORMAT);

    return (
      <Text style={styles.graduationInfo}>{`${this.props.translate('candidateProfile.details.graduation')} ${graduationDate}`}</Text>
    );
  };

  itemIncomplete() {
    const { category, graduationDate, grantedFeatures } = this.props;
    return grantedFeatures.includes('PROFILE_DATA_EXTENSION') && category == null && graduationDate == null;
  }

  renderContent = () => {
    const {
      title, subtitle, category, fromDate, toDate, ongoing, handleRemove, description, graduationDate,
    } = this.props;

    const remove = Platform.OS === 'web' && handleRemove ? (
      <Touchable>
        <Text style={styles.remove} onPress={handleRemove}>Remove</Text>
      </Touchable>
    ) : null;

    return (
      <>
        <View style={styles.flexDirectionRow}>
          {this.getFormattedDates(fromDate, toDate, ongoing)}
          <Text style={styles.datesDuration}>{this.getDatesDuration(fromDate, toDate, ongoing)}</Text>
        </View>
        <View style={styles.flexDirectionRow}>
          <Text style={styles.title}>{title}</Text>
          <UserScope allowedFor={[CANDIDATE_USER]}>
            {this.itemIncomplete() && <Text style={styles.needsHandlingBadge}>{this.props.translate('generic.incomplete').toUpperCase()}</Text>}
          </UserScope>
        </View>
        <Text style={styles.subtitle}>{subtitle}</Text>
        {graduationDate && this.getGraduationInfo(graduationDate)}
        {!!category && <Text style={styles.category}>{category}</Text>}
        {description ? (<Text style={styles.description}>{description}</Text>) : null }
        {remove}
      </>
    );
  };

  render() {
    if (this.props.handleOpen) {
      return (
        // we wrap it in extra view so that Remove button underneath isn't visible
        <View style={[styles.contentWrapperEditRow, this.props.noHorizontalPadding && { paddingHorizontal: 0 }]}>
          <ClickableListItem
            style={{ iconWrapper: { paddingTop: spacings.xxl }, container: { marginBottom: 0 } }}
            renderContent={this.renderContent}
            onPress={this.props.handleOpen}
          />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <View style={styles.contentWrapperViewRow}>
          {this.renderContent()}
        </View>
      </View>
    );
  }
}

export const profileHistoryListItemPropType = {
  category: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  fromDate: PropTypes.number.isRequired,
  toDate: PropTypes.number,
  graduationDate: PropTypes.number,
  ongoing: PropTypes.bool.isRequired,
};

CandidateProfileHistoryListItem.propTypes = {
  ...profileHistoryListItemPropType,
  translate: PropTypes.func.isRequired,
  handleOpen: PropTypes.func,
  handleRemove: PropTypes.func,
  noHorizontalPadding: PropTypes.bool,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CandidateProfileHistoryListItem.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  category: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  to: null,
  handleOpen: undefined,
  handleRemove: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  description: undefined,
  noHorizontalPadding: false,
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, null)(withLocalize(CandidateProfileHistoryListItem));
