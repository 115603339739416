import React, { Component } from 'react';
import {
  Linking, StyleSheet, Text, View,
} from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import { FIND_GIGS_CONTEXT } from '../../../../constants/actionContext';
import ApplyAction from './ApplyAction';
import ApproveRejectAction from './ApproveRejectAction';
import {
  colors, fontFamily, fontSizes, shadowTop, spacings,
} from '../../../../common/styles/base.style';
import PrimaryText from '../../../../common/components/PrimaryText';
import { font } from '../../../../common/styles/mixins';
import MyGigsRedirectButton from './MyGigsRedirectButton';
import Touchable from '../../../../common/components/Touchable';
import Routing from '../../../../core/routing/index';
import { CANDIDATE_PROFILE } from '../../../../constants/routes';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { PROVIDER_ENUM } from '../../../../constants/providerTypes';
import ApplyExternalAction from './ApplyExternalAction';
import { applyForGig } from '../../my_gigs/candidateMyGigsActions';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';
import ViewportController from '../../../../common/components/ViewportController';
import { addFavouriteGig, removeNotInterestingGigFromList } from '../candidateFindGigsActions';

const { withRouter } = Routing;

const { PENDING_SHORTLIST_APPROVAL, APPLIED } = GIG_CANDIDATE_STATUS;

const styles = StyleSheet.create({
  base: {
    paddingTop: spacings.md,
    ...shadowTop,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: colors.gold,
  },
  myProfileFont: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
  },
  externalApplicationBox: {
    ...shadowTop,
    paddingVertical: spacings.md,
  },
  externalApplicationText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
    textAlign: 'center',
  },
});

class CandidateGigDetailsActionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, refreshing: true };
  }

  onApplyExternal = () => {
    const {
      gig: { gigId },
    } = this.props;
    // this.props.addFavouriteGig(gigId, true);
    this.props.removeNotInterestingGigFromList(gigId);
    this.props.applyForGig({ gigId }, FIND_GIGS_CONTEXT, { questionnaire: null });
    this.goBack();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  isCareerUrlApplicable() {
    const {
      gig: { careerUrl },
      grantedFeatures,
    } = this.props;
    return grantedFeatures.includes('CAREER_URL') && careerUrl;
  }

  renderCandidateCannotApplyWarning(messageKey) {
    const t = (key) => this.props.translate(`candidateMyGigs.actions.${key}`);
    return (
      <View style={styles.base}>
        <Touchable onPress={() => this.props.history.replace(CANDIDATE_PROFILE)}>
          <PrimaryText style={{ textAlign: 'center' }}>
            {t(messageKey)}
            <Text style={styles.myProfileFont}>{t('myProfile')}</Text>
          </PrimaryText>
        </Touchable>
      </View>
    );
  }

  renderApplicationInfo() {
    return (
      <ViewportController safeAreaBottom noFlex>
        <View style={styles.externalApplicationBox}>
          <Text style={styles.externalApplicationText}>{this.props.translate('candidateMyGigs.textsForStatuses.externalGigApplied')}</Text>
        </View>
      </ViewportController>
    );
  }

  render() {
    const {
      gig: {
        gigId, gigProvider, isApplicable, matchingStatus, questionnaire,
      },
      profileRequiredCompleted,
      isCandidateAvailable,
      updateGigDataOnPreviewScreen,
    } = this.props;

    if (!isApplicable || this.isCareerUrlApplicable()) {
      return <ApplyExternalAction onApply={this.onApplyExternal} />;
    }

    switch (matchingStatus) {
      case null: {
        if (!profileRequiredCompleted) {
          return this.renderCandidateCannotApplyWarning('incompleteCannotApplyWarning');
        }
        if (!isCandidateAvailable) {
          return this.renderCandidateCannotApplyWarning('unavailableCannotApplyWarning');
        }
        return <ApplyAction gigId={gigId} questionnaire={questionnaire} updateGigDataOnPreviewScreen={updateGigDataOnPreviewScreen} />;
      }
      case PENDING_SHORTLIST_APPROVAL:
        return <ApproveRejectAction gigId={gigId} questionnaire={questionnaire} updateGigDataOnPreviewScreen={updateGigDataOnPreviewScreen} />;
      case APPLIED: {
        if (gigProvider === PROVIDER_ENUM.LETSGIG) {
          return <MyGigsRedirectButton matchingStatus={matchingStatus} />;
        }
        return this.renderApplicationInfo();
      }
      default:
        return null;
    }
  }
}

CandidateGigDetailsActionsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  gig: matchedGigForCandidatePropType.isRequired,
  profileRequiredCompleted: PropTypes.bool,
  isCandidateAvailable: PropTypes.bool,
  applyForGig: PropTypes.func.isRequired,
  updateGigDataOnPreviewScreen: PropTypes.func,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  addFavouriteGig: PropTypes.func.isRequired,
  removeNotInterestingGigFromList: PropTypes.func.isRequired,
  candidateFindGigsSetCurrentIndex: PropTypes.func.isRequired,
};

CandidateGigDetailsActionsContainer.defaultProps = {
  isCandidateAvailable: null,
  profileRequiredCompleted: null,
  updateGigDataOnPreviewScreen: null,
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, {
  applyForGig,
  addFavouriteGig,
  removeNotInterestingGigFromList,
})(withRouter(withLocalize(CandidateGigDetailsActionsContainer)));
