import React from 'react';
import { View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import PrimaryModal from '../components/modal/PrimaryModal';
import Button from '../components/buttons/Button';
import modalStyle from '../styles/modal.style';
import Container from '../components/Container';
import ScreenHeader from '../components/header/ScreenHeader';

const SignupDocumentModal = ({
  translate, visible, handleCloseModal, header, children, disabled = false, buttonTitle = translate('generic.close')
}) => (
  <PrimaryModal
    visible={visible}
    handleCancel={handleCloseModal}
    header=""
  >
    <ScreenHeader title={translate(header)} />

    <Container>
      {children || null}
    </Container>
    <View style={modalStyle.btnWrapper}>
      <Button
        title={buttonTitle}
        style={modalStyle.btnConfirm}
        onPress={handleCloseModal}
        disabled={disabled}
      />
    </View>
  </PrimaryModal>
);

SignupDocumentModal.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withLocalize(SignupDocumentModal);
