import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ReactRouterPropTypes from 'react-router-prop-types';
import { LinkButton } from '../../../common/components/index';
import { spacings, wp } from '../../../common/styles/base.style';
import StepComponent from '../../../common/components/StepComponent';
import { profileCreatorCopyDataFromProfile } from './profileCreatorActions';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import Wrapper from '../../../common/components/Wrapper';
import Container from '../../../common/components/Container';
import StickyButtonsWrapper from '../../../common/components/StickyButtonsWrapper';
import Header from '../../../common/components/header/Header';
import PrimaryText from '../../../common/components/PrimaryText';
import { PAGE_AFTER_CLOSE } from './ProfileCreatorHeader';
import { getGrantedProfileCreationSteps, findGrantedProfileCreationFirstStep } from './candidateProfileStepsConfig';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  logo: {
    marginBottom: spacings.sm,
  },
  headerText: {
    width: wp(80),
    marginBottom: spacings.sm,
  },
  headerOptionalText: {
    textAlign: 'center',
    marginTop: spacings.sm,
    marginBottom: spacings.xxs,
  },
});

class ProfileCreatorWelcome extends React.Component {
  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.WELCOME);
    this.props.profileCreatorCopyDataFromProfile(this.props.profile);
  }

  handleBack = () => {
    this.props.history.replace(PAGE_AFTER_CLOSE);
  };

  render() {
    const { translate } = this.props;
    return (
      <Container>
        <ScreenHeader title={translate('candidateProfileCreator.header.header')} showBackArrow handleBack={this.handleBack} />
        <ScrollView>
          <Wrapper style={styles.container}>
            <Header style={styles.headerText}>{translate('candidateProfileCreator.welcome.header')}</Header>
            <PrimaryText style={styles.headerOptionalText}>{this.props.translate('candidateProfileCreator.welcome.subHeader')}</PrimaryText>
            <StepComponent steps={getGrantedProfileCreationSteps(this.props.grantedFeatures)} translationPrefix="candidateProfileCreator.welcome" />
          </Wrapper>
        </ScrollView>
        <StickyButtonsWrapper>
          <LinkButton to={findGrantedProfileCreationFirstStep(this.props.grantedFeatures)} title={translate('candidateProfileCreator.welcome.button')} />
        </StickyButtonsWrapper>
      </Container>
    );
  }
}

ProfileCreatorWelcome.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  profileCreatorCopyDataFromProfile: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Map).isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};
const mapStateToProps = (state) => ({
  profile: state.candidate.get('profile'),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { profileCreatorCopyDataFromProfile })(withLocalize(ProfileCreatorWelcome));
