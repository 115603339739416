import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import CandidateProfileAboutMeFormValidation, { ABOUT_ME_MAX_LENGTH } from './CandidateProfileAboutMeFormValidation';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';

const CandidateProfileAboutMeForm = ({
  translate, renderFooter, handleSubmit, aboutMe,
}) => (
  <FormikWithValidation
    validation={CandidateProfileAboutMeFormValidation}
    initialValues={{ aboutMe }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <KeyboardAwareFlexWrapper>
          <FormikFieldPrimaryTextInput
            name="aboutMe"
            multiline
            numberOfLines={4}
            counter
            maxLength={ABOUT_ME_MAX_LENGTH}
            label={translate('candidateProfile.details.aboutMeLabel')}
            scrollEnabled={false}
          />
        </KeyboardAwareFlexWrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfileAboutMeForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  aboutMe: PropTypes.string.isRequired,
};

export default withLocalize(CandidateProfileAboutMeForm);
