import React from 'react';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../../constants/userScopes';
import {
  COMPANY_SHIFT_CREATE,
  COMPANY_SHIFT_CREATE_MAIN,
  COMPANY_SHIFT_CREATE_SALARY,
  COMPANY_SHIFT_CREATE_DATES_AND_TIMES,
  COMPANY_SHIFT_CREATE_ACCESS_ENTITIES,
} from '../../../constants/routes';
import NewShiftContainer from './NewShiftContainer';
import CompanyShiftsMainStepEditableContainer from './main/CompanyShiftsMainStepEditableContainer';
import CompanyShiftsSalaryStepEditableContainer from './salary/CompanyShiftsSalaryStepEditableContainer';
import ShiftAccessEntitiesStepEditableContainer from './accessEntities/ShiftAccessEntitiesStepEditableContainer';
import CompanyShiftsDatesAndTimesStepEditableContainer from './dateAndTime/CompanyShiftsDatesAndTimesStepEditableContainer';

const CompanyShiftsCreationRouting = () => (
  <>
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_CREATE} component={NewShiftContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_CREATE_MAIN} component={CompanyShiftsMainStepEditableContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_CREATE_DATES_AND_TIMES} component={CompanyShiftsDatesAndTimesStepEditableContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_CREATE_SALARY} component={CompanyShiftsSalaryStepEditableContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_SHIFT_CREATE_ACCESS_ENTITIES} component={ShiftAccessEntitiesStepEditableContainer} />
  </>
);

export default CompanyShiftsCreationRouting;
