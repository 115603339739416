import React from 'react';
import { StyleSheet, View } from 'react-native';
import AdminFindCandidatesFilters from './AdminFindCandidatesFilters';
import AdminFindCandidatesList from './AdminFindCandidatesList';
import { shadowBottom, spacings } from '../../common/styles/base.style';
import Container from '../../common/components/Container';

const styles = StyleSheet.create({
  container: {
    ...shadowBottom,
    marginTop: 100,
    paddingHorizontal: spacings.sm,
  },
});

function AdminFindCandidatesContainer() {
  return (
    <Container>
      <View style={styles.container}>
        <AdminFindCandidatesFilters />
      </View>
      <AdminFindCandidatesList />
    </Container>
  );
}

export default AdminFindCandidatesContainer;
