import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import queryString from 'query-string';
import Routing from '../../../core/routing/index';
import Container from '../../../common/components/Container';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import Arrow, { ARROW } from '../../../common/components/Arrow';
import { toDatetime } from '../../../utilities/dateFormatter';
import getDefinition from './CandidateListItemDefinitions';
import Touchable from '../../../common/components/Touchable';
import ImageComponent from '../../../common/components/image/ImageComponent';
import { showConfirmAlert } from '../../../common/components/alert/alerts';
import { CANDIDATE_GIG_DETAILS } from '../../../constants/routes';
import CandidateGigOverview from './CandidateGigOverview';
import { gigPropType } from '../../../constants/propTypes';
import { GIG_DETAILS_ORIGIN } from '../../../constants/gigDetailsOrigin';
import { PROVIDER_ENUM } from '../../../constants/providerTypes';
import { CLOSED } from '../../../constants/gigStatus';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    marginTop: spacings.md,
  },
  topContainer: {
    marginHorizontal: spacings.md,
    marginBottom: spacings.sm,
    flexDirection: 'row',
  },
  middleContainer: {
    paddingHorizontal: spacings.md,
    paddingVertical: spacings.sm,
  },
  bottomContainer: {
    marginHorizontal: spacings.md,
    marginTop: spacings.sm,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  seeGigStyle: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xs, null, colors.magenta),
    marginRight: 2,
  },
  middleText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, null, colors.white),
    lineHeight: 17,
  },
  middleSubText: {
    marginBottom: spacings.xxs,
    ...font(fontFamily.SFProTextRegular, fontSizes.xxxs, null, colors.white),
  },
  middleFooter: {
    marginTop: spacings.xs,
    ...font(fontFamily.SFProTextRegular, fontSizes.xxxs, null, colors.white),
  },
  actionButton: {
    paddingHorizontal: spacings.sm,
  },
  actionButtonText: {
    marginTop: spacings.xxs,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayBold, fontSizes.xs),
  },
  bottomContainerRowMode: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomRadius: {
    borderBottomRightRadius: 17,
    borderBottomLeftRadius: 17,
  },
  expiredGigText: {
    color: colors.brownGrey,
  },
});

export function shouldComponentUpdate(currentProps, nextProps) {
  return !shallowequal(currentProps.gig, nextProps.gig);
}

class GigListItem extends Component {
  shouldComponentUpdate(nextProps) {
    return shouldComponentUpdate(this.props, nextProps);
  }

  viewGigDetails = () => {
    const { gig, origin } = this.props;
    const path = CANDIDATE_GIG_DETAILS.replace(':id', gig.gigId);
    this.props.history.push(`${path}?${queryString.stringify({ myGigs: origin === GIG_DETAILS_ORIGIN.MY_GIGS, origin })}`);
  };

  shouldRenderActionRow(definition) {
    return definition && definition.actions && definition.actions.length
      && (definition.actionsForAllProviders === true || this.props.gig.gigProvider === PROVIDER_ENUM.LETSGIG);
  }

  renderAction(action) {
    const {
      dispatchAction, internalFunctions, translate, gig,
    } = this.props;
    internalFunctions.dispatch = dispatchAction;
    internalFunctions.translate = translate;
    const actionfnc = action.getAction(gig.gigId, internalFunctions);
    const onPressFnc = action.confirmWithAlert ? () => showConfirmAlert(this.props.translate, action.alertParams, actionfnc) : actionfnc;
    return (
      <View key={action.label} style={styles.actionButton}>
        <Touchable onPress={onPressFnc}>
          <ImageComponent size="xxs" image={action.icon} />
          {action.label && <Text style={styles.actionButtonText}>{translate(action.label)}</Text>}
        </Touchable>
      </View>
    );
  }

  renderActions(actions) {
    return (
      <Container style={styles.bottomContainer}>
        { actions.map((action) => this.renderAction(action)) }
      </Container>
    );
  }

  render() {
    const itemDefinition = this.props.itemDefinition || getDefinition(this.props.gig.matching);
    const t = (key, ...args) => this.props.translate(`candidateMyGigs.${key}`, ...args);
    const { gig, online, greyOutClosedGigs } = this.props;
    const isClosed = gig.status === CLOSED;

    const greyOutGig = greyOutClosedGigs && isClosed;
    return (
      <Container style={[styles.container, this.shouldRenderActionRow(itemDefinition) && { marginBottom: spacings.sm }]}>
        <Touchable onPress={this.viewGigDetails}>
          <Container style={styles.topContainer}>
            <Container>
              <CandidateGigOverview gig={gig} online={online} closedGigStyle={[{}, greyOutGig && styles.expiredGigText]} />
            </Container>
            <View style={{ flexDirection: 'row', paddingTop: 5 }}>
              <Text style={[styles.seeGigStyle, greyOutGig && styles.expiredGigText]}>{`${t('seeGig')} `}</Text>
              <View style={{ paddingTop: 2 }}>
                <Arrow color={greyOutGig ? ARROW.COLOR.BROWN_GREY : ARROW.COLOR.MAGENTA} />
              </View>
            </View>
          </Container>
        </Touchable>
        {itemDefinition.middleText && (
        <Container
          style={[styles.middleContainer, { backgroundColor: itemDefinition.middleColor }, !this.shouldRenderActionRow(itemDefinition) && styles.bottomRadius]}
        >
          <Text style={styles.middleText}>{t(itemDefinition.middleText, { companyName: gig.companyName })}</Text>
          {itemDefinition.middleSubText
          && <Text style={styles.middleSubText}>{t(itemDefinition.middleSubText)}</Text>}
          {gig.matching && gig.matching.lastTransitionDate
          && <Text style={styles.middleFooter}>{`${t('updated')} ${toDatetime(gig.matching.lastTransitionDate)}`}</Text>}
        </Container>
        )}
        {this.shouldRenderActionRow(itemDefinition) && this.renderActions(itemDefinition.actions)}
      </Container>
    );
  }
}

GigListItem.propTypes = {
  gig: gigPropType.isRequired,
  online: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatchAction: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  internalFunctions: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  itemDefinition: PropTypes.object,
  origin: PropTypes.string,
  greyOutClosedGigs: PropTypes.bool,
};
GigListItem.defaultProps = {
  online: null,
  internalFunctions: {},
  itemDefinition: null,
  // this list is hijacked by find gigs "swipe" view, sorry!
  origin: GIG_DETAILS_ORIGIN.MY_GIGS,
  greyOutClosedGigs: false,
};

export const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (action) => dispatch(action),
});

export default connect(null, mapDispatchToProps)(withRouter(withLocalize(GigListItem)));
