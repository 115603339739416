import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import ImageComponent from '../image/ImageComponent';
import NavigationRightButtonWrapper from './NavigationRightButtonWrapper';

const BTN_HIT_SLOP_VERTICAL = 30;
const BTN_HIT_SLOP_HORIZONTAL = 50;

const NavigationRightButton = ({
  icon,
  iconWidth,
  onPress,
  paddingRight,
}) => (
  <NavigationRightButtonWrapper paddingRight={paddingRight}>
    <TouchableOpacity
      onPress={onPress}
      hitSlop={{
        top: BTN_HIT_SLOP_VERTICAL, bottom: BTN_HIT_SLOP_VERTICAL, left: BTN_HIT_SLOP_HORIZONTAL, right: BTN_HIT_SLOP_HORIZONTAL,
      }}
    >
      <ImageComponent style={{ image: { height: 18, width: iconWidth } }} image={icon} />
    </TouchableOpacity>
  </NavigationRightButtonWrapper>
);

NavigationRightButton.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      uri: PropTypes.string,
    }),
  ]).isRequired,
  iconWidth: PropTypes.number,
  onPress: PropTypes.func.isRequired,
  paddingRight: PropTypes.number,
};

NavigationRightButton.defaultProps = {
  iconWidth: 18,
  paddingRight: undefined,
};

export default NavigationRightButton;
