import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

import { getUserStatus } from './userStatusActions';
import { APP_STATE_ACTIVE } from '../../watchers/app_state/AppStateWatcher';
import { getInstallationId } from '../../auth/deviceDetailsUtil';
import { CANDIDATE_USER, COMPANY_USER } from '../../../constants/userScopes';
import { getCompanyChats } from '../../../company/chat/companyChatActions';
import { getCandidateChatsInfo } from '../../../candidate/chat/candidateChatActions';
import { getCompanyPoolGroupChatsInfo, getCompanyPoolDirectChatsInfo } from '../../../company/candidatesPool/chat/companyPoolChatActions';
import { getCandidatePoolGroupChatsInfo, getCandidatePoolDirectChatsInfo } from '../../../candidate/myPools/chat/candidatePoolChatActions';

class UserStatusUpdater extends Component {
  static STATUS_CALL_INTERVAL = 30000;

  constructor(props) {
    super(props);
    this.lastUserStatusUpdateTimestamp = 0;
    this.callUserStatusIfNeeded();
  }

  shouldComponentUpdate(prevProps) {
    return !shallowequal(prevProps.grantedFeatures, this.props.grantedFeatures)
      || prevProps.authenticated !== this.props.authenticated
      || prevProps.appState !== this.props.appState;
  }

  componentDidUpdate() {
    if (!this.props.authenticated) {
      this.lastUserStatusUpdateTimestamp = 0;
    }
    this.callUserStatusIfNeeded();
    this.refreshChatList();
  }

  callUserStatusIfNeeded() {
    if (this.shouldCallScheduledFunction()) {
      this.lastUserStatusUpdateTimestamp = Date.now();
      this.props.getUserStatus(getInstallationId());
      setTimeout(() => this.callUserStatusIfNeeded(), UserStatusUpdater.STATUS_CALL_INTERVAL);
    }
  }

  refreshChatList() {
    if (this.props.grantedFeatures && this.isAppInForeground()) {
      if (COMPANY_USER === this.props.userScope) {
        if (this.props.grantedFeatures.includes('COMPANY_CHAT_REFRESH')) {
          this.props.getCompanyChats(false);
        }
        if (this.props.grantedFeatures.includes('CANDIDATES_POOL')) {
          this.props.getCompanyPoolGroupChatsInfo(false).then(() => this.props.getCompanyPoolDirectChatsInfo(false));
        }
      }
      if (CANDIDATE_USER === this.props.userScope) {
        this.props.getCandidateChatsInfo(false);
        if (this.props.grantedFeatures.includes('CANDIDATES_POOL')) {
          this.props.getCandidatePoolGroupChatsInfo(false).then(() => this.props.getCandidatePoolDirectChatsInfo(false));
        }
      }
    }
  }

  shouldCallScheduledFunction() {
    return this.isAuthenticated() && this.isAppInForeground() && this.scheduledIntervalPassed();
  }

  isAuthenticated() {
    return this.props.authenticated;
  }

  isAppInForeground() {
    return this.props.appState === APP_STATE_ACTIVE;
  }

  scheduledIntervalPassed() {
    return Date.now() - this.lastUserStatusUpdateTimestamp > UserStatusUpdater.STATUS_CALL_INTERVAL;
  }

  render() {
    return null;
  }
}

UserStatusUpdater.propTypes = {
  getUserStatus: PropTypes.func.isRequired,
  getCompanyChats: PropTypes.func.isRequired,
  getCompanyPoolGroupChatsInfo: PropTypes.func.isRequired,
  getCandidateChatsInfo: PropTypes.func.isRequired,
  getCandidatePoolGroupChatsInfo: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  appState: PropTypes.string.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string),
  userScope: PropTypes.string,
  getCompanyPoolDirectChatsInfo: PropTypes.func.isRequired,
  getCandidatePoolDirectChatsInfo: PropTypes.func.isRequired,
};

UserStatusUpdater.defaultProps = {
  userScope: null,
  grantedFeatures: undefined,
};

const mapStateToProps = (state) => {
  const grantedFeaturesList = state.grantedFeatures.get('grantedFeatures');

  return ({
    authenticated: state.auth.get('authenticated'),
    userScope: state.auth.get('userScope'),
    appState: state.appStateData.get('appState'),
    grantedFeatures: grantedFeaturesList ? grantedFeaturesList.toJS() : undefined,
  });
};

export default connect(mapStateToProps, {
  getUserStatus,
  getCompanyChats,
  getCompanyPoolGroupChatsInfo,
  getCompanyPoolDirectChatsInfo,
  getCandidateChatsInfo,
  getCandidatePoolGroupChatsInfo,
  getCandidatePoolDirectChatsInfo,
})(UserStatusUpdater);
