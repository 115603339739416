import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Text, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import { toDatetime } from '../../utilities/dateFormatter';
import CompanyChatGigCandidatesListItem from './CompanyChatGigCandidatesListItem';
import { candidateDetailChatPropType, chatGigItemPropType } from '../../constants/propTypes';
import {
  colors, fontFamily, fontSizes, shadowBottom, spacings,
} from '../../common/styles/base.style';
import Container from '../../common/components/Container';
import PrimaryFlatList from '../../common/components/PrimaryFlatList';
import { OPEN } from '../../constants/gigStatus';
import { font } from '../../common/styles/mixins';
import ChatUnavailableMessage from '../../common/chat/ChatUnavailableMessage';

const styles = StyleSheet.create({
  subheaderContainer: {
    width: '100%',
    alignItems: 'center',
    marginBottom: spacings.sm,
  },
  subheader: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xs, null, colors.brownGrey),
  },
});

function subHeader(gig, t) {
  return () => (
    <View style={styles.subheaderContainer}>
      <Text style={styles.subheader}>
        {gig.status === OPEN
          ? t('companyGigs.list.expiresLabel') + toDatetime(gig.expiryDate)
          : t('companyGigs.list.closedLabel') + toDatetime(gig.closeDate)}
      </Text>
    </View>
  );
}

function mainContent(gig, companyGigChats, usersPresence, refreshing, onRefresh, onPress, error, grantedFeatures) {
  if (error) {
    return (<ChatUnavailableMessage />);
  }
  return (
    <PrimaryFlatList
      emptyListTextKey="companyChat.noChats"
      data={companyGigChats}
      refreshing={refreshing}
      onRefresh={onRefresh}
      renderItem={({ item: candidate }) => (
        <CompanyChatGigCandidatesListItem
          gigId={gig.gigId}
          position={gig.position}
          name={gig.name}
          {...candidate}
          onPress={onPress}
          grantedFeatures={grantedFeatures}
          online={usersPresence[candidate.candidateId]}
        />
      )}
      keyExtractor={(item) => item.chatId}
    />
  );
}

const CompanyChatGigCandidatesScreen = ({
  translate, gig, companyGigChats, usersPresence, onPress, refreshing, onRefresh, error, grantedFeatures,
}) => (
  <Container>
    <View style={shadowBottom}>
      <ScreenHeader title={gig.name} subheaderFunc={subHeader(gig, translate)} showBackArrow />
    </View>
    <Container>
      {mainContent(gig, companyGigChats, usersPresence, refreshing, onRefresh, onPress, error, grantedFeatures)}
    </Container>
  </Container>
);

CompanyChatGigCandidatesScreen.propTypes = {
  gig: chatGigItemPropType.isRequired,
  companyGigChats: PropTypes.arrayOf(candidateDetailChatPropType).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.bool.isRequired,
};

export default withLocalize(CompanyChatGigCandidatesScreen);
