import {
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_CATEGORIES,
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_LOCATION,
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_POSITION_SEARCH,
} from '../../../../constants/actionTypes';

export function candidateFindGigsChangeLocation(cityKey) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_CHANGE_LOCATION,
    cityKey,
  };
}

export function candidateFindGigsChangeCategories(categories) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_CHANGE_CATEGORIES,
    categories,
  };
}

export function candidateFindGigsChangePositionSearch(positionSearch) {
  return {
    type: CANDIDATE_GIGS_FIND_GIGS_CHANGE_POSITION_SEARCH,
    positionSearch: positionSearch || undefined,
  };
}
