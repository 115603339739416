import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Button } from '../../common/components/index';
import BackgroundImageScreen from '../../common/screens/BackgroundImageScreen';
import Header from '../../common/components/header/Header';
import PrimaryText from '../../common/components/PrimaryText';
import { spacings } from '../../common/styles/base.style';
import ViewportController from '../../common/components/ViewportController';

const styles = StyleSheet.create({
  text: {
    maxWidth: 300,
    marginBottom: spacings.lg,
  },
  buttonsContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const backgroundSrc = require('../../assets/photos/afterLogin/afterLogin.jpg');

const CompanyPostGigSplashScreen = ({ translate, onButtonPress }) => (
  <ViewportController safeAreaBottom statusBarHidden>
    <BackgroundImageScreen img={backgroundSrc}>
      <View style={styles.buttonsContainer}>
        <Header style={styles.text}>{translate('companyPostGigSplashScreen.text1')}</Header>
        <PrimaryText style={[styles.text]}>{translate('companyPostGigSplashScreen.text2')}</PrimaryText>
        <Button onPress={onButtonPress} title={translate('companyPostGigSplashScreen.button')} />
      </View>
    </BackgroundImageScreen>
  </ViewportController>
);

CompanyPostGigSplashScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
};

export default CompanyPostGigSplashScreen;
