import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileCreatorStepExperienceScreen from './ProfileCreatorStepExperienceScreen';
import { candidateExperiencePropType } from '../../../../../constants/propTypes';
import { profileCreatorAddExperience } from '../../profileCreatorActions';
import { getExperienceByKey, getExperienceKey, getNewExperience } from './profileExperienceHelper';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepExperienceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      experience: props.savedExperience,
      experienceToEdit: getNewExperience(),
      modalVisible: false,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.EXPERIENCE);
  }

  openEmptyModal = () => {
    this.setState({
      modalVisible: true,
      experienceToEdit: getNewExperience(),
    });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  handleEditExperience = (key) => {
    this.setState({
      experienceToEdit: getExperienceByKey(this.state.experience, key),
      modalVisible: true,
    });
  };

  handleRemoveExperience = (key) => {
    const { experience } = this.state;
    this.setState({ experience: experience.filter((e) => getExperienceKey(e) !== key) });
  };

  handleAddExperience = (newExperience) => {
    this.closeModal();
    const newExperienceKey = getExperienceKey(newExperience);
    const experienceExists = getExperienceByKey(this.state.experience, newExperienceKey) != null;
    if (!experienceExists) {
      this.addExperience(newExperience);
    } else {
      this.editExperience(newExperience);
    }
  };

  handleSubmit = () => {
    const { experience } = this.state;
    this.props.profileCreatorAddExperience(experience);
    this.doRedirect();
  };

  handleSkip = () => {
    const { experience } = this.state;
    this.props.profileCreatorAddExperience(experience);
    this.doRedirect();
  };

  doRedirect = () => this.setState({ redirect: true });

  addExperience(newExperience) {
    this.setState({ experience: this.state.experience.concat([newExperience]) });
  }

  editExperience(newExperience) {
    const newExperienceKey = getExperienceKey(newExperience);
    const currentExperience = this.state.experience;
    for (let i = 0; i < currentExperience.length; i += 1) {
      if (getExperienceKey(currentExperience[i]) === newExperienceKey) {
        currentExperience[i] = newExperience;
        this.setState({ experience: currentExperience });
        break;
      }
    }
  }

  render = () => (
    <ProfileCreatorStepExperienceScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.EXPERIENCE, this.props.grantedFeatures)}
      experience={this.state.experience}
      handleRemoveExperience={this.handleRemoveExperience}
      handleAddExperience={this.handleAddExperience}
      openEmptyModal={this.openEmptyModal}
      closeModal={this.closeModal}
      handleSubmit={this.handleSubmit}
      handleSkip={this.handleSkip}
      handleEditExperience={this.handleEditExperience}
      experienceToEdit={this.state.experienceToEdit}
      modalVisible={this.state.modalVisible}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.EXPERIENCE, this.props.grantedFeatures)}
    />
  );
}

ProfileCreatorStepExperienceContainer.propTypes = {
  savedExperience: PropTypes.arrayOf(candidateExperiencePropType.isRequired).isRequired,
  profileCreatorAddExperience: PropTypes.func.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  savedExperience: state.candidate.get('profileData').get('experience').toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { profileCreatorAddExperience })(ProfileCreatorStepExperienceContainer);
