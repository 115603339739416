import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import PrimaryPicker from './PrimaryPicker';
import { getValueByPath } from '../../../utilities/objectUtils';

const FormikPicker = ({
  field: { name, value }, form: {
    setFieldValue, setFieldTouched, touched, errors,
  }, onValueChange, ...props
}) => (
  <PrimaryPicker
    {...props}
    onValueChange={(itemValue) => {
      setFieldValue(name, itemValue);
      setFieldTouched(name, true);
      if (onValueChange) {
        onValueChange(itemValue);
      }
    }}
    selectedValue={value}
    error={(getValueByPath(touched, name) && errors[name]) || null}
    testID={`${name}Picker`}
  />
);

FormikPicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onValueChange: PropTypes.func,
};

FormikPicker.defaultProps = {
  onValueChange: null,
};

const FormikFieldPrimaryPicker = (props) => (
  <Field
    component={FormikPicker}
    {...props}
  />
);

export default FormikFieldPrimaryPicker;
