import React from 'react';
import {
  Image, StyleSheet, Text, View,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import Container from '../../../common/components/Container';
import { LinkButton } from '../../../common/components';
import { BUTTON_TYPES } from '../../../common/components/buttons/Button';
import Routing from '../../../core/routing/index';
import { COMPANY_SHIFT_CANDIDATES, COMPANY_SHIFT_CREATE } from '../../../constants/routes';
import { shiftPropType } from '../../../constants/propTypes';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import BaseListItem from '../../../common/components/BaseListItem';
import { storeShiftsListScrollPosition } from '../../shifts/companyShiftsActions';
import {
  colors, fontFamily, fontSizes, spacings, wp,
} from '../../../common/styles/base.style';
import { toModernDateWithWeekDayName, toTime } from '../../../utilities/dateFormatter';
import { font } from '../../../common/styles/mixins';
import { SALARY_COMPONENTS } from '../../../common/gig/shiftSalaryUtils';
import { SHIFT_TABS } from './shiftsConstants';

const { withRouter } = Routing;

const calendar = require('../../../assets/icons/calendar/calendar.png');
const candidate = require('../../../assets/icons/person/person.png');
const moneyIcon = require('../../../assets/icons/money/money.png');

const styles = StyleSheet.create({
  footerFloatingButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  position: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, -0.1, colors.greyishBrown),
  },
  description: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xs, undefined, colors.lightPeach),
    marginTop: spacings.xxs,
  },
  footerWrapper: {
    flexDirection: 'column',
    borderTopWidth: 1,
    borderTopColor: colors.veryLightGrey,
    marginTop: spacings.sm,
    paddingLeft: spacings.xs,
    paddingTop: spacings.xs,
  },
  footerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacings.sm,
  },
  datesAndTimesSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowTextBold: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xs),
  },
  rowTextRegular: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs),
  },
  image: {
    marginRight: spacings.sm,
  },
});

class CompanyCandidatesPoolShiftsScreen extends React.Component {
  componentDidMount() {
    this.scrollPosition = 0;
    if (this.props.history.action === 'POP') {
      const { storedScrollPosition } = this.props;
      if (storedScrollPosition) {
        this.scrollToLastKnownPosition(storedScrollPosition);
      }
    } else {
      this.props.storeScrollPosition(this.scrollPosition);
    }
  }

  componentWillUnmount() {
    this.props.storeScrollPosition(this.scrollPosition);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  goToShift = (gigId) => {
    this.props.history.push(COMPANY_SHIFT_CANDIDATES.replace(':gigId', gigId));
  };

  translateByKey = (key, params) => this.props.translate(`company.candidatesPool.shifts.${key}`, params);

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToOffset({ offset: scrollPosition, animated: true }), 0);
  }

  renderDatesAndTimes = (shiftDatesAndTimes) => (
    <View style={styles.footerRow}>
      <Image source={calendar} style={styles.image} />
      <View style={{ flexDirection: 'column' }}>
        {shiftDatesAndTimes.map((item) => (
          <View style={styles.datesAndTimesSection} key={item.id}>
            <View style={{ width: wp(25) }}>
              <Text style={styles.rowTextBold}>{toModernDateWithWeekDayName(item.startDate)}</Text>
            </View>
            <Text style={styles.rowTextBold}>{`${toTime(item.startDate)} - ${toTime(item.endDate)}`}</Text>
          </View>
        ))}
      </View>
    </View>
  );

  renderCandidatesAndBookings = (shift) => (
    <View style={styles.footerRow}>
      <Image source={candidate} style={styles.image} />
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.rowTextBold}>
          {`${shift.candidatesNumber} ${this.translateByKey(shift.candidatesNumber === 1 ? 'candidate' : 'candidates')}`}
        </Text>
        <Text style={styles.rowTextBold}>
          {`${shift.bookedCandidatesNumber} ${this.translateByKey(!shift.bookedCandidatesNumber === 1 ? 'booking' : 'bookings')}`}
        </Text>
      </View>
    </View>
  );

  renderSalaryInfo = (shift) => (
    <View style={styles.footerRow}>
      <Image source={moneyIcon} style={styles.image} />
      <View style={{ flexDirection: 'column' }}>
        <Text style={styles.rowTextBold}>{`${shift.shiftSalary.salary} ${this.translateByKey('salaryUnit')}`}</Text>
        {shift.shiftSalary.holidayPay && (
          <Text style={styles.rowTextRegular}>{`+ ${SALARY_COMPONENTS.holidayPay}% ${this.translateByKey('holidayPay')}`}</Text>
        )}
        {shift.shiftSalary.obPayment.applicable && (<Text style={styles.rowTextRegular}>{this.translateByKey('OBPayment')}</Text>)}
      </View>
    </View>
  );

  render() {
    const { shifts, activeTab } = this.props;

    return (
      <Container>
        <PrimaryFlatList
          emptyListTextKey={
            activeTab === SHIFT_TABS.OPEN
              ? this.translateByKey('activeEmptyList')
              : this.translateByKey(activeTab === SHIFT_TABS.CLOSED ? 'closedEmptyList' : 'archivedEmptyList')
          }
          contentContainerStyle={{ paddingBottom: spacings.xxxxl }}
          data={shifts}
          keyExtractor={(shift) => shift.gigId}
          innerRef={this.setListRef}
          onScroll={this.onScroll}
          renderItem={({ item: shift }) => (
            <BaseListItem
              renderMainContent={() => (
                <Container>
                  <Text style={styles.position}>{shift.position}</Text>
                  <Text style={styles.description}>{shift.description}</Text>
                </Container>
              )}
              renderFooter={() => (
                <View style={styles.footerWrapper}>
                  {this.renderDatesAndTimes(shift.shiftDatesAndTimes)}
                  {this.renderCandidatesAndBookings(shift)}
                  {this.renderSalaryInfo(shift)}
                </View>
              )}
              onPress={() => this.goToShift(shift.gigId)}
            />
          )}
        />
        {activeTab === SHIFT_TABS.OPEN && (
          <View style={styles.footerFloatingButton}>
            <LinkButton
              type={BUTTON_TYPES.PRIMARY_RED}
              title={this.translateByKey('addShift')}
              to={COMPANY_SHIFT_CREATE}
            />
          </View>
        )}
      </Container>
    );
  }
}

CompanyCandidatesPoolShiftsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  shifts: PropTypes.arrayOf(shiftPropType).isRequired,
  activeTab: PropTypes.string.isRequired,
  storedScrollPosition: PropTypes.number.isRequired,
  storeScrollPosition: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.auth.get('userId'),
  storedScrollPosition: state.companyShifts.get('shiftsListScrollPosition'),
});

export default connect(
  mapStateToProps,
  { storeScrollPosition: storeShiftsListScrollPosition },
)(withLocalize(withRouter(CompanyCandidatesPoolShiftsScreen)));
