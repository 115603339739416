import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ScreenHeader from '../components/header/ScreenHeader';
import Container from '../components/Container';
import KeyboardAwareFlexWrapper from '../components/KeyboardAwareFlexWrapper';
import FormikWithValidation from '../components/form/FormikWithValidation';
import GeneralFormError from '../components/form/GeneralFormError';
import AutoScrollableKeyboardAwareScrollView from '../keyboard/AutoScrollableKeyboardAwareScrollView';
import { font } from '../styles/mixins';
import { fontFamily, fontSizes } from '../styles/base.style';
import { emailValidation } from '../auth/LoginValidation';
import FormikFieldPrimaryTextInput from '../components/form/FormikFieldPrimaryTextInput';
import Button from '../components/buttons/Button';

const styles = StyleSheet.create({
  textContainer: {
    alignItems: 'center',
    padding: 20,
  },
  text: {
    marginVertical: 30,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl),
  },
});

class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onSubmit, translate } = this.props;
    return (
      <Container>
        <ScreenHeader showBackArrow containerStyle={{ marginBottom: 5 }} title={translate('loginScreen.resetPassword')} />
        <FormikWithValidation
          validation={emailValidation}
          onSubmit={onSubmit}
          initialValues={{ email: '' }}
          render={({ handleSubmit, isBtnDisabled, errors }) => (
            <AutoScrollableKeyboardAwareScrollView>
              <Container>
                <KeyboardAwareFlexWrapper>
                  <View style={{ alignItems: 'center', flex: 1 }}>
                    <Text style={styles.text}>{translate('loginScreen.enterEmail')}</Text>
                    <FormikFieldPrimaryTextInput
                      innerRef={(ref) => !this.state.emailInputRef && this.setState({ emailInputRef: ref })}
                      name="email"
                      autoCapitalize="none"
                      label="Email"
                      keyboardType="email-address"
                    />
                  </View>
                </KeyboardAwareFlexWrapper>
                <KeyboardAwareFlexWrapper flexOnStart={false}>
                  <GeneralFormError errors={errors} />
                  <Button
                    title={translate('loginScreen.resetPassword')}
                    onPress={handleSubmit}
                    disabled={isBtnDisabled}
                    testID="signupBtn"
                  />
                </KeyboardAwareFlexWrapper>
              </Container>
            </AutoScrollableKeyboardAwareScrollView>
          )}
        />
      </Container>
    );
  }
}

ForgotPasswordScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(ForgotPasswordScreen);
