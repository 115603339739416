import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Text, TouchableWithoutFeedback, TouchableOpacity, TextInput, Animated, Dimensions, Keyboard, StyleSheet,
} from 'react-native';
import { colors } from '../styles/base.style';
import { stylePropTypes } from '../../constants/propTypes';

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

const searchIcon = require('../../assets/icons/searchbox/search.png');
const deleteIcon = require('../../assets/icons/searchbox/delete.png');

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 5,
  },
  input: {
    height: 30,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    textAlign: 'left',
    borderColor: '#444',
    backgroundColor: '#f7f7f7',
    borderRadius: 15,
    fontSize: 13,
  },
  inputDisabled: {
    backgroundColor: colors.veryLightGrey,
  },
  placeholderColor: {
    color: 'grey',
  },
  iconSearch: {
    flex: 1,
    position: 'absolute',
    height: 18,
    width: 18,
    tintColor: 'grey',
  },
  iconDelete: {
    position: 'absolute',
    right: 70,
    height: 18,
    width: 18,
    tintColor: 'grey',
  },
  cancelButton: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    width: 60,
    height: 50,
  },
  cancelButtonText: {
    fontSize: 14,
    color: colors.magenta,
  },
});

class SearchBox extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      keyword: props.defaultValue,
      expanded: false,
    };
    const { width } = Dimensions.get('window');
    this.contentWidth = width;
    this.middleWidth = width / 2;
    this.paddingRightOnExpand = this.props.paddingRightOnExpand;

    this.iconSearchAnimated = new Animated.Value(
      this.middleWidth - this.props.placeholderCollapsedMargin - 30,
    );
    this.iconDeleteAnimated = new Animated.Value(0);
    this.inputFocusWidthAnimated = new Animated.Value(this.contentWidth - 38);
    this.inputFocusPlaceholderAnimated = new Animated.Value(
      this.middleWidth - this.props.placeholderCollapsedMargin - 14,
    );
    this.btnCancelAnimated = new Animated.Value(this.contentWidth);

    this.onFocus = this.onFocus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.focus = this.focus.bind(this);
    this.expandAnimation = this.expandAnimation.bind(this);
    this.collapseAnimation = this.collapseAnimation.bind(this);
    this.onLayout = this.onLayout.bind(this);

    this.placeholder = this.props.placeholder || 'Search';
    this.cancelTitle = this.props.cancelTitle || 'Cancel';
    this.autoFocus = this.props.autoFocus;

    this.shadowOpacityAnimated = new Animated.Value(
      this.props.shadowOpacityCollapsed,
    );
    this.shadowHeight = this.props.shadowOffsetHeightCollapsed;
  }

  componentDidMount() {
    if (this.autoFocus) {
      this.setState({ expanded: true });
    }
  }

  onLayout = (event) => {
    const contentWidth = event.nativeEvent.layout.width;
    this.contentWidth = contentWidth;
    this.middleWidth = contentWidth / 2;
    const { expanded } = this.state;
    if (expanded) {
      this.expandAnimation();
    } else {
      this.collapseAnimation();
    }
  };

  onSearch = async () => {
    const { keyword } = this.state;
    if (this.props.beforeSearch) {
      await this.props.beforeSearch(keyword);
    }
    if (this.props.keyboardShouldPersist === false) {
      await Keyboard.dismiss();
    }
    if (this.props.onSearch) {
      await this.props.onSearch(keyword);
    }
    if (this.props.afterSearch) {
      await this.props.afterSearch(keyword);
    }
  };

  onChangeText = async (text) => {
    await this.setState({ keyword: text });
    const { keyword } = this.state;
    await new Promise((resolve) => {
      Animated.timing(this.iconDeleteAnimated, {
        toValue: text.length > 0 ? 1 : 0,
        duration: 200,
        useNativeDriver: false,
      }).start(() => resolve());
    });
    if (this.props.onChangeText) {
      await this.props.onChangeText(keyword);
    }
  };

  onFocus = async () => {
    if (!this.props.editable) {
      return;
    }
    if (this.props.beforeFocus) {
      await this.props.beforeFocus();
    }
    if (this.inputKeywordRef.isFocused) {
      await this.inputKeywordRef.focus();
    }
    await this.setState((prevState) => ({ expanded: !prevState.expanded }));
    await this.expandAnimation();
    if (this.props.onFocus) {
      const { keyword } = this.state;
      await this.props.onFocus(keyword);
    }
    if (this.props.afterFocus) {
      await this.props.afterFocus();
    }
  };

  focus = async (text = '') => {
    await this.setState({ keyword: text });
    await this.inputKeywordRef.focus();
  };

  onDelete = async () => {
    if (this.props.beforeDelete) {
      await this.props.beforeDelete();
    }
    await new Promise((resolve) => {
      Animated.timing(this.iconDeleteAnimated, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start(() => resolve());
    });
    await this.setState({ keyword: '' });
    if (this.props.onDelete) {
      await this.props.onDelete();
    }
    if (this.props.afterDelete) {
      await this.props.afterDelete();
    }
  };

  onCancel = async () => {
    if (this.props.beforeCancel) {
      await this.props.beforeCancel();
    }
    await this.setState({ keyword: '' });
    await this.setState((prevState) => ({ expanded: !prevState.expanded }));
    await this.collapseAnimation(true);
    if (this.props.onCancel) {
      await this.props.onCancel();
    }
    if (this.props.afterCancel) {
      await this.props.afterCancel();
    }
  };

  expandAnimation = () => new Promise((resolve) => {
    const { keyword } = this.state;
    Animated.parallel([
      Animated.timing(this.inputFocusWidthAnimated, {
        toValue: this.contentWidth - this.paddingRightOnExpand,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.btnCancelAnimated, {
        toValue: 10,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.inputFocusPlaceholderAnimated, {
        toValue: this.props.placeholderExpandedMargin,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.iconSearchAnimated, {
        toValue: this.props.placeholderExpandedMargin - 16,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.iconDeleteAnimated, {
        toValue: keyword.length > 0 ? 1 : 0,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.shadowOpacityAnimated, {
        toValue: this.props.shadowOpacityExpanded,
        duration: 200,
        useNativeDriver: false,
      }).start(),
    ]);
    this.shadowHeight = this.props.shadowOffsetHeightExpanded;
    resolve();
  });

  collapseAnimation = (isForceAnim = false) => new Promise((resolve) => {
    Animated.parallel([
      this.props.keyboardShouldPersist === false ? Keyboard.dismiss() : null,
      Animated.timing(this.inputFocusWidthAnimated, {
        toValue: this.contentWidth - 10,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.btnCancelAnimated, {
        toValue: this.contentWidth,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      this.props.keyboardShouldPersist === false
        ? Animated.timing(this.inputFocusPlaceholderAnimated, {
          toValue: this.middleWidth - this.props.placeholderCollapsedMargin,
          duration: 200,
          useNativeDriver: false,
        }).start()
        : null,
      this.props.keyboardShouldPersist === false || isForceAnim === true
        ? Animated.timing(this.iconSearchAnimated, {
          toValue: this.middleWidth - (this.props.placeholderCollapsedMargin + 16),
          duration: 200,
          useNativeDriver: false,
        }).start()
        : null,
      Animated.timing(this.iconDeleteAnimated, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start(),
      Animated.timing(this.shadowOpacityAnimated, {
        toValue: this.props.shadowOpacityCollapsed,
        duration: 200,
        useNativeDriver: false,
      }).start(),
    ]);
    this.shadowHeight = this.props.shadowOffsetHeightCollapsed;
    resolve();
  });

  setInputKeywordRef = (ref) => {
    this.inputKeywordRef = ref;
  };

  render() {
    const { keyword } = this.state;
    const { style, editable } = this.props;

    return (
      <Animated.View
        style={[styles.container, style && style.container && { ...style.container }, { height: this.props.inputHeight + 10 }]}
        onLayout={this.onLayout}
      >
        <AnimatedTextInput
          ref={this.setInputKeywordRef}
          style={[
            styles.input,
            !editable && styles.inputDisabled,
            style && style.input && { ...style.input },
            { height: this.props.inputHeight, borderRadius: this.props.inputHeight / 2 },
            {
              width: this.inputFocusWidthAnimated,
              paddingLeft: this.inputFocusPlaceholderAnimated,
            },
            this.props.shadowVisible && {
              shadowOffset: {
                width: this.props.shadowOffsetWidth,
                height: this.shadowHeight,
              },
              shadowColor: this.props.shadowColor,
              shadowOpacity: this.shadowOpacityAnimated,
              shadowRadius: this.props.shadowRadius,
            },
          ]}
          editable={editable}
          value={keyword}
          onChangeText={this.onChangeText}
          placeholder={this.placeholder}
          placeholderTextColor={
            style && style.placeholderColor ? style.placeholderColor.color : styles.placeholderColor.color
          }
          onSubmitEditing={this.onSearch}
          autoCorrect={false}
          blurOnSubmit={this.props.blurOnSubmit}
          returnKeyType={this.props.returnKeyType}
          keyboardType={this.props.keyboardType}
          autoCapitalize={this.props.autoCapitalize}
          onFocus={this.onFocus}
          underlineColorAndroid="transparent"
          accessibilityTraits="search"
        />

        <TouchableWithoutFeedback onPress={this.onFocus}>
          {this.props.iconSearch
            ? (
              <Animated.View style={[styles.iconSearch, { top: (this.props.inputHeight + 10) / 2 - 9 }, { left: this.iconSearchAnimated }]}>
                {this.props.iconSearch}
              </Animated.View>
            ) : (
              <Animated.Image
                source={searchIcon}
                style={[styles.iconSearch, { top: (this.props.inputHeight + 10) / 2 - 9 }, { left: this.iconSearchAnimated }]}
              />
            )}
        </TouchableWithoutFeedback>

        {this.props.useClearButton && (
          <TouchableWithoutFeedback onPress={this.onDelete}>
            {this.props.iconDelete
              ? (
                <Animated.View style={[styles.iconDelete, style && style.iconDelete, { opacity: this.iconDeleteAnimated }]}>
                  {this.props.iconDelete}
                </Animated.View>
              ) : (
                <Animated.Image
                  source={deleteIcon}
                  style={[
                    styles.iconDelete,
                    style && style.iconDelete,
                    this.props.paddingRightOnExpand && { right: this.props.paddingRightOnExpand },
                    { opacity: this.iconDeleteAnimated },
                  ]}
                />
              )}
          </TouchableWithoutFeedback>
        )}

        <TouchableOpacity onPress={this.onCancel}>
          <Animated.View style={[styles.cancelButton, style && style.cancelButton, { left: this.btnCancelAnimated }]}>
            <Text style={[styles.cancelButtonText, style && style.cancelButtonText]}>{this.cancelTitle}</Text>
          </Animated.View>
        </TouchableOpacity>
      </Animated.View>
    );
  }
}

SearchBox.propTypes = {
  style: stylePropTypes,
  beforeFocus: PropTypes.func,
  onFocus: PropTypes.func,
  afterFocus: PropTypes.func,
  beforeSearch: PropTypes.func,
  onSearch: PropTypes.func,
  afterSearch: PropTypes.func,
  onChangeText: PropTypes.func,
  beforeCancel: PropTypes.func,
  onCancel: PropTypes.func,
  afterCancel: PropTypes.func,
  beforeDelete: PropTypes.func,
  onDelete: PropTypes.func,
  afterDelete: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  cancelTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  returnKeyType: PropTypes.string,
  keyboardType: PropTypes.string,
  autoCapitalize: PropTypes.string,
  inputHeight: PropTypes.number,
  paddingRightOnExpand: PropTypes.number,
  editable: PropTypes.bool,
  blurOnSubmit: PropTypes.bool,
  keyboardShouldPersist: PropTypes.bool,
  useClearButton: PropTypes.bool,
  placeholderCollapsedMargin: PropTypes.number,
  placeholderExpandedMargin: PropTypes.number,
  shadowOffsetHeightCollapsed: PropTypes.number,
  shadowOffsetHeightExpanded: PropTypes.number,
  shadowOffsetWidth: PropTypes.number,
  shadowColor: PropTypes.string,
  shadowOpacityCollapsed: PropTypes.number,
  shadowOpacityExpanded: PropTypes.number,
  shadowRadius: PropTypes.number,
  shadowVisible: PropTypes.bool,
  autoFocus: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  iconDelete: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  iconSearch: PropTypes.object,
};

SearchBox.defaultProps = {
  defaultValue: '',
  editable: true,
  blurOnSubmit: true,
  useClearButton: true,
  inputHeight: 30,
  paddingRightOnExpand: 65,
  autoFocus: false,
  keyboardShouldPersist: false,
  placeholderCollapsedMargin: 10,
  placeholderExpandedMargin: 30,
  shadowOffsetWidth: 0,
  shadowOffsetHeightCollapsed: 2,
  shadowOffsetHeightExpanded: 4,
  shadowColor: '#000',
  shadowOpacityCollapsed: 0.12,
  shadowOpacityExpanded: 0.24,
  shadowRadius: 4,
  shadowVisible: false,
  returnKeyType: 'search',
  keyboardType: 'default',

  autoCapitalize: undefined,
  beforeFocus: undefined,
  onFocus: undefined,
  afterFocus: undefined,
  beforeSearch: undefined,
  onSearch: undefined,
  afterSearch: undefined,
  onChangeText: undefined,
  beforeCancel: undefined,
  onCancel: undefined,
  afterCancel: undefined,
  beforeDelete: undefined,
  onDelete: undefined,
  afterDelete: undefined,
  placeholder: undefined,
  cancelTitle: undefined,
  iconDelete: undefined,
  iconSearch: undefined,
  style: undefined,
};

export default SearchBox;
