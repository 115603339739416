import { createClass } from '@letsgig/asteroid';
import randomatic from 'randomatic';
import config from '../../config/config';

const Asteroid = createClass();
// Connect to a Meteor backend
const asteroid = new Asteroid({
  endpoint: config.rocketChatWebsocketUrl,
  reconnectInterval: 3000,
  autoConnect: false,
});

export { asteroid as client };

const chatMessagesHandlers = {};
const chatsUpdatesHandlers = {};
const userStatusUpdatesHandlers = {};
const loginHandlers = {};
let loggedIn = false;

// because login handler uses our custom mechanism, we need to reset it
asteroid.on('disconnected', () => {
  console.log('Chat disconnected');
  loggedIn = false;
  // subscriptions get cleared automatically but not handlers - and our subscribe methods set both
  asteroid.ddp.removeEvent('changed');
});

export function addDisconnectedHandler(handler) {
  asteroid.on('disconnected', handler);
}

asteroid.on('connected', () => {
  console.log('Chat connected');
});

export function addConnectedHandler(handler) {
  asteroid.on('connected', handler);
  // the handler won't be triggerred if connected, do it manually
  if (asteroid.ddp.status === 'connected') {
    console.log('Already connected while adding connected handler. Executing handler.');
    handler();
  }
}

export function removeConnectedHandler(handler) {
  console.log('Removing connected handler.');
  asteroid.removeListener('connected', handler);
}

export function addLoginHandler(handler) {
  const handlerId = randomatic('aA0', 10);
  console.log(`Adding login handler with id = ${handlerId}.`);
  loginHandlers[handlerId] = handler;

  if (loggedIn) {
    console.log('Already logged in. Executing handler');
    handler();
  }

  return handlerId;
}

export function removeLoginHandler(handlerId) {
  console.log(`Removing login handler with id = ${handlerId}.`);
  delete loginHandlers[handlerId];
}

export function connect() {
  console.log('Connecting to chat');
  asteroid.ddp.autoReconnect = true;
  asteroid.connect();
}

export function disconnect() {
  console.log('Disconnecting chat');
  asteroid.disconnect();
}

export function login(token) {
  if (!loggedIn) {
    console.log('Not logged in yet. Logging in.');

    asteroid.call('login', { resume: token })
      .then(() => {
        loggedIn = true;

        console.log('Logged in. Executing handlers.');
        Object.keys(loginHandlers).forEach((handlerId) => {
          loginHandlers[handlerId]();
        });
      })
      .catch((error) => {
        // TODO display error somehow
        console.warn(error);
      });
  } else {
    console.log('Already logged or logging in.');
  }
}

export function subscribeToChatMessages(chatId, handler) {
  console.log(`Subscribing to chat messages for chatId = ${chatId}`);
  const wrappedHandler = ({ collection, fields }) => {
    if (collection === 'stream-room-messages') {
      handler(fields.args[0]);
    }
  };
  asteroid.ddp.on('changed', wrappedHandler);
  const subscriptionId = asteroid.subscribe('stream-room-messages', chatId, false).id;
  chatMessagesHandlers[subscriptionId] = wrappedHandler;
  console.log(`Chat messages subscriptionId = ${subscriptionId}`);
  return subscriptionId;
}

export function unsubscribeChatMessages(subscriptionId) {
  console.log(`Unsubscribing chat messages for subscriptionId = ${subscriptionId}`);
  // don't listen to messages any more
  asteroid.unsubscribe(subscriptionId);

  // clean up listener, otherwise multiple are created when remounting the component
  asteroid.ddp.removeListener('changed', chatMessagesHandlers[subscriptionId]);
  delete chatMessagesHandlers[subscriptionId];
}

export function subscribeChatsUpdated(userId, handler) {
  console.log('Subscribing to chats updates');
  const wrappedHandler = ({ collection, fields }) => {
    if (collection === 'stream-notify-user' && fields && fields.args && fields.args[0] === 'updated') {
      handler(fields.args[1]);
    }
  };
  asteroid.ddp.on('changed', wrappedHandler);
  const subscriptionId = asteroid.subscribe('stream-notify-user', `${userId}/rooms-changed`, false).id;
  chatsUpdatesHandlers[subscriptionId] = wrappedHandler;
  console.log(`Chats updates subscriptionId = ${subscriptionId}`);
  return subscriptionId;
}

export function unsubscribeChatsUpdated(subscriptionId) {
  console.log(`Unsubscribing chats updates for subscriptionId = ${subscriptionId}`);
  asteroid.unsubscribe(subscriptionId);
  asteroid.ddp.removeListener('changed', chatsUpdatesHandlers[subscriptionId]);
  delete chatsUpdatesHandlers[subscriptionId];
}

export function subscribeUserStatusChange(handler) {
  console.log('Subscribing to users status updates');
  const wrappedHandler = ({ collection, fields }) => {
    if (collection === 'stream-notify-logged' && fields && fields.eventName && fields && fields.eventName === 'user-status' && fields.args) {
      handler(fields.args[0][1], fields.args[0][2] === 1);
    }
  };
  asteroid.ddp.on('changed', wrappedHandler);
  const subscriptionId = asteroid.subscribe('stream-notify-logged', 'user-status', false).id;
  userStatusUpdatesHandlers[subscriptionId] = wrappedHandler;
  console.log(`Chats updates subscriptionId = ${subscriptionId}`);
  return subscriptionId;
}

export function unsubscribeUserStatusChange(subscriptionId) {
  console.log(`Unsubscribing users status updates for subscriptionId = ${subscriptionId}`);
  asteroid.unsubscribe(subscriptionId);
  asteroid.ddp.removeListener('changed', chatsUpdatesHandlers[subscriptionId]);
  delete userStatusUpdatesHandlers[subscriptionId];
}

export function loadChatHistory(chatId, handler) {
  console.log(`Loading chat ${chatId} history.`);
  asteroid.call(
    'loadHistory',
    chatId,
    null, // newest message timestamp
    1000, // no of messages
    null,
  ) // { '$date': 1410177601 } last data download date
    .then((res) => {
      handler(res.messages);
    });
}

export function sendMessage(messageId, chatId, text) {
  console.log(`Sending message with messageId = ${messageId}`);
  asteroid.call(
    'sendMessage',
    {
      _id: messageId,
      rid: chatId,
      msg: text,
    },
  );
}

export function markChatAsRead(chatId) {
  console.log(`Marking chat = ${chatId} as read`);
  asteroid.call(
    'readMessages',
    chatId,
  );
}

export function getChats(handler) {
  console.log('Getting all subscriptions.');
  asteroid.call('subscriptions/get')
    .then((res) => {
      handler(res);
    });
}
