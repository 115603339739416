import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { widthSpacingsBolek, heightSpacingsBolek } from '../styles/base.style';
import { imagePropType, stylePropTypes } from '../../constants/propTypes';

const iconEntryStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: heightSpacingsBolek.p01,
    marginBottom: heightSpacingsBolek.p01,
  },
  image: {
    width: widthSpacingsBolek.p40,
    height: widthSpacingsBolek.p40,
    marginRight: widthSpacingsBolek.p40,
  },
});

function IconEntry({ icon, children, style }) {
  return (
    <View style={[iconEntryStyles.wrapper, style]}>
      <Image style={iconEntryStyles.image} source={icon} />
      {children}
    </View>
  );
}

IconEntry.propTypes = {
  children: PropTypes.node.isRequired,
  icon: imagePropType,
  style: stylePropTypes,
};

IconEntry.defaultProps = {
  icon: null,
  style: null,
};

export default IconEntry;
