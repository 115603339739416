import React from 'react';
import {
  View, StyleSheet, Text, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../styles/base.style';
import ImageComponent from './image/ImageComponent';
import { font } from '../styles/mixins';
import Routing from '../../core/routing/index';
import Arrow from './Arrow';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  stepWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: spacings.xxs,
    width: '100%',
  },
  stepName: {
    alignSelf: 'flex-start',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),

  },
  stepNameDone: {
    textDecorationLine: 'line-through',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
  },
  doneIcon: {
    width: 20,
    height: 20,
  },
  doneIconWrapper: {
    flex: 1,
    alignItems: 'flex-end',
  },
  stepRequired: {
    flex: 1,
    textAlign: 'right',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm, undefined, colors.magenta),

  },
  stepOptional: {
    flex: 1,
    textAlign: 'right',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm, undefined, colors.lightPeach),
  },
  editStepWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: spacings.md,
  },
  editLinkWrapper: {
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
  },
  editLinkText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl),
  },
});

const icon = require('../../assets/icons/checkbox/on.png');

const newGigSteps = (step, t, translationPrefix) => (
  <View key={step.titleKey} style={styles.stepWrapper}>
    <Text style={[styles.stepName, step.done && styles.stepNameDone]}>{t(`${translationPrefix}.steps.${step.titleKey}`)}</Text>
    {step.done
      ? (
        <ImageComponent style={{ image: styles.doneIcon, wrapper: styles.doneIconWrapper }} image={icon} />
      )
      : (
        <Text style={step.required ? styles.stepRequired : styles.stepOptional}>
          {step.required ? t('companyGigCreator.welcome.required') : t('companyGigCreator.welcome.optional')}
        </Text>
      )}
  </View>
);

const editGigSteps = (step, t, translationPrefix, history, gigId) => (
  <TouchableOpacity
    key={step.titleKey}
    style={styles.editStepWrapper}
    onPress={() => history.push(step.editPath.replace(':gigId', gigId))}
  >
    <Text style={styles.editLinkText}>{t(`${translationPrefix}.steps.${step.titleKey}`)}</Text>
    <View style={{ paddingTop: 4 }}>
      <Arrow />
    </View>
  </TouchableOpacity>
);

const StepComponent = (props) => {
  const t = props.translate;
  const renderSteps = props.isEdit ? editGigSteps : newGigSteps;

  return (
    props.steps.map((step) => (
      renderSteps(step, t, props.translationPrefix, props.history, props.gigId)
    ))
  );
};

StepComponent.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    titleKey: PropTypes.string.isRequired,
    required: PropTypes.number,
    done: PropTypes.number,
  })).isRequired,
  translationPrefix: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  gigId: PropTypes.string,
};

StepComponent.defaultProps = {
  gigId: '',
  isEdit: false,
};

export default withRouter(withLocalize(StepComponent));
