import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Touchable from './Touchable';
import RightArrow from './RightArrow';
import { colors, spacings, shadow } from '../styles/base.style';
import { stylePropTypes } from '../../constants/propTypes';
import Container from './Container';

const styles = StyleSheet.create({
  base: {
    marginVertical: spacings.sm,
    borderRadius: 17,
    backgroundColor: colors.white,
    marginHorizontal: spacings.md,
  },
  container: {
    padding: spacings.md,
    paddingBottom: 0,
    borderRadius: 17,
  },
  footer: {
    paddingBottom: spacings.md,
  },
  mainContentMargin: {
    marginRight: spacings.sm,
  },
  icon: {
    position: 'absolute',
    top: 6,
    right: 0,
  },
  image: {
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    width: '100%',
    height: 200,
  },
  imageWrapper: {
    overflow: 'hidden',
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
  },
  headerWrapper: {
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
  },
});

const BaseListItem = ({
  onPress,
  renderMainContent,
  renderHeader,
  renderFooter,
  hideArrow,
  disableShadow,
  imageSource,
  style,
}) => (
  <Touchable
    style={[styles.base, !disableShadow && shadow, style]}
    activeOpacity={0.7}
    onPress={onPress}
  >
    {renderHeader && <View style={styles.headerWrapper}>{renderHeader()}</View>}
    {imageSource && <View style={styles.imageWrapper}>{imageSource()}</View>}
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Container style={!hideArrow ? styles.mainContentMargin : {}}>{renderMainContent()}</Container>
        {!hideArrow && <RightArrow additionalStyles={[styles.icon]} />}
      </View>
      <View style={renderFooter && styles.footer}>{renderFooter()}</View>
    </View>
  </Touchable>
);

BaseListItem.propTypes = {
  renderMainContent: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
  onPress: PropTypes.func.isRequired,
  hideArrow: PropTypes.bool,
  disableShadow: PropTypes.bool,
  imageSource: PropTypes.func,
  style: stylePropTypes,
};

BaseListItem.defaultProps = {
  renderHeader: () => {},
  renderFooter: () => {},
  hideArrow: false,
  disableShadow: false,
  imageSource: () => {},
  style: undefined,
};

export default BaseListItem;
