import validator from 'validator';
import moment from 'moment';
import { isFutureDateIgnoreDayUtc } from '../../../../core/validation/validators';

export const CANDIDATE_PROFILE_SCHOOL_MAX_LENGTH = 30;
export const CANDIDATE_PROFILE_COURSE_MAX_LENGTH = 80;

export default [
  {
    field: 'category',
    custom: (value) => value != null,
    message: 'null_value',
    grantedByToggle: 'PROFILE_DATA_EXTENSION',
  },
  {
    field: 'course',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'course',
    method: validator.isLength,
    args: [{ max: CANDIDATE_PROFILE_COURSE_MAX_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'school',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'school',
    method: validator.isLength,
    args: [{ max: CANDIDATE_PROFILE_SCHOOL_MAX_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'fromDate',
    custom: (value) => value != null,
    message: 'null_value',
  },
  {
    field: 'fromDate',
    custom: (value) => !isFutureDateIgnoreDayUtc(value),
    message: 'future_date',
  },
  {
    field: 'toDate',
    custom: (value, args, data) => (data.ongoing ? true : value != null),
    message: 'null_value',
  },
  {
    field: 'toDate',
    custom: (value, args, data) => (data.ongoing ? true : !isFutureDateIgnoreDayUtc(value)),
    message: 'future_date',
  },
  {
    field: 'toDate',
    custom: (value, args, data) => (data.ongoing ? true : moment(value).isSameOrAfter(data.fromDate)),
    message: 'before_start_date',
  },
  {
    field: 'graduationDate',
    custom: (value) => value != null,
    message: 'null_value',
    grantedByToggle: 'PROFILE_DATA_EXTENSION',
  },
  {
    field: 'graduationDate',
    custom: (value, args, data) => (data.ongoing ? moment(value).isSameOrAfter(data.fromDate) : true),
    message: 'before_start_date',
    grantedByToggle: 'PROFILE_DATA_EXTENSION',
  },
  {
    field: 'graduationDate',
    custom: (value, args, data) => (data.ongoing ? true : moment(value).isSameOrAfter(data.toDate)),
    message: 'before_to_date',
    grantedByToggle: 'PROFILE_DATA_EXTENSION',
  },
];
