import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { StyleSheet, Text } from 'react-native';
import Touchable from '../../../common/components/Touchable';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacings.sm,
    marginBottom: spacings.xxs,
  },
  refresh: {
    marginLeft: spacings.xs,
    color: colors.greyishBrown,
    fontSize: fontSizes.xxs,
    fontFamily: fontFamily.SFProDisplayRegular,
  },
});

const RefreshButton = ({ translate, handleRefresh }) => (
  <Touchable style={styles.container} onPress={handleRefresh}>
    <Text style={styles.refresh}>
      {translate('generic.refresh')}
    </Text>
  </Touchable>
);

RefreshButton.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(RefreshButton);
