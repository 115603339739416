import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text } from 'react-native';
import { Translate } from 'react-localize-redux';
import {
  fontFamily, fontSizes, spacings, colors,
} from '../styles/base.style';
import { font } from '../styles/mixins';
import { stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  text: {
    width: '100%',
    paddingTop: spacings.sm,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, undefined, colors.brownishGrey),
  },
});

const ListEmptyLabel = ({ textKey, style }) => (
  <Text style={[styles.text, style]}><Translate id={textKey} /></Text>
);

ListEmptyLabel.propTypes = {
  textKey: PropTypes.string.isRequired,
  style: stylePropTypes,
};

ListEmptyLabel.defaultProps = {
  style: null,
};

export default ListEmptyLabel;
