import React from 'react';
import { withLocalize } from 'react-localize-redux';

import {
  COMPANY_CANDIDATES_POOL_GROUP_CREATE,
  COMPANY_CANDIDATES_POOL_GROUP_MODIFY,
  COMPANY_CANDIDATES_POOL_GROUP_VIEW,
  COMPANY_CANDIDATES_POOL_GROUP_CHAT,
} from '../../../constants/routes';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../../constants/userScopes';
import CompanyCandidatesPoolGroupEditableContainer from './editable/CompanyCandidatesPoolGroupEditableContainer';
import CompanyCandidatesPoolGroupViewContainer from './view/CompanyCandidatesPoolGroupViewContainer';
import CompanyPoolGroupChatContainer from '../chat/CompanyPoolGroupChatContainer';

class CompanyCandidatesPoolMyPoolRouter extends React.PureComponent {
  render() {
    return (
      <>
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_GROUP_CREATE} component={CompanyCandidatesPoolGroupEditableContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_GROUP_VIEW} component={CompanyCandidatesPoolGroupViewContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_GROUP_MODIFY} component={CompanyCandidatesPoolGroupEditableContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_GROUP_CHAT} component={CompanyPoolGroupChatContainer} />
      </>
    );
  }
}

export default withLocalize(CompanyCandidatesPoolMyPoolRouter);
