const SUBSCRIPTION_STATUS_ENUM = {
  ACTIVE: 'active',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
};

const SUBSCRIPTION_BILLING_ENUM = {
  PAY_PER_USE: 'payPerUse',
  UNLIMITED: 'unlimited',
};

module.exports = {
  SUBSCRIPTION_BILLING_ENUM,
  SUBSCRIPTION_STATUS_ENUM,
};
