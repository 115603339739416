import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, Platform, Text, View, ScrollView
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import LinkButton from '../components/buttons/LinkButton';
import { CANDIDATE_USER, COMPANY_USER, SUPERADMIN_USER } from '../../constants/userScopes';
import ScreenHeader from '../components/header/ScreenHeader';
import Container from '../components/Container';
import companyAppTerms from '../../../app_terms/companyAppTerms';
import candidateAppTerms from '../../../app_terms/candidateAppTerms';
import SelectListItem from '../components/form/SelectListItem';

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  paragraphs: {
    fontSize: 16,
    lineHeight: 24,
    paddingHorizontal: 20
  },
  bottomInnerContainer: {
    paddingVertical: 20
  },
  linkButtonContainer: {
    paddingVertical: 15
  }
});

class TermsAndAgreementScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckBoxEnabled: false,
      isChecked: false,
    };
  }

  handleScroll = (event) => {
    const { layoutMeasurement, contentSize, contentOffset } = event.nativeEvent;

    // Calculate the current scroll position
    const scrollY = contentOffset.y;

    // Calculate whether the ScrollView is at the bottom
    const isCheckBoxEnabled = Math.floor(layoutMeasurement.height + scrollY + 5) >= Math.floor(contentSize.height);

    if (isCheckBoxEnabled) {
      this.setState({ isCheckBoxEnabled });
    }
  };

  render() {
    const {
      translate,
      userType,
      redirectPath,
      redirect,
      onSubmit
    } = this.props;

    const t = (key) => translate(`${userType.toLowerCase()}WelcomeScreen.signupScreen.${key}`);

    return (
      <Container>
        <ScreenHeader title={t('contractAgreementHeader')} showBackArrow />

        <ScrollView
          onScroll={this.handleScroll}
          scrollEventThrottle={16}
        >
          <View style={styles.container}>
            <Text style={styles.paragraphs}>{userType == COMPANY_USER ? companyAppTerms : candidateAppTerms}</Text>
          </View>
        </ScrollView>

        <View style={styles.bottomInnerContainer}>
          <SelectListItem
            key={1}
            value="accept"
            label={t('contractAgreementText')}
            selected={this.state.isChecked}
            disabled={Platform.OS == 'web' ? false : !this.state.isCheckBoxEnabled}
            iconPosition="left"
            onPress={() => this.setState({ isChecked: !this.state.isChecked })}
          />
          <View style={styles.container}>
            <LinkButton
              to={redirectPath}
              title={translate('generic.next')}
              doRedirect={redirect}
              onPress={onSubmit}
              disabled={!this.state.isChecked}
              testID="contractBtn"
            />
          </View>
        </View>
      </Container>
    );
  }
}

TermsAndAgreementScreen.propTypes = {
  userType: PropTypes.oneOf([CANDIDATE_USER, COMPANY_USER, SUPERADMIN_USER]).isRequired,
  redirectPath: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
};

export default withLocalize(TermsAndAgreementScreen);
