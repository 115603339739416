import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';

import {
  fontFamily, fontSizes, spacings, wp,
} from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import EntryRow from '../../../../common/components/EntryRow';
import Container from '../../../../common/components/Container';
import { toModernDate, toTime } from '../../../../utilities/dateFormatter';
import IconButtonBolek from '../../../../common/components/buttons/IconButtonBolek';
import { imagePropType } from '../../../../constants/propTypes';

const styles = StyleSheet.create({
  textEntry: {
    flexDirection: 'row',
    paddingHorizontal: spacings.sm,
    width: wp(39),
  },
  date: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
  },
  time: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
  },
  image: {
    width: 30,
    height: 32,
    paddingLeft: spacings.sm,
  },
});

const DateAndTimeRow = ({
  itemId, leftValue, rightValue, actionImg, onActionPress,
}) => (
  <Container row style={{ alignItems: 'center' }}>
    <EntryRow style={{ marginRight: spacings.sm }}>
      <View style={styles.textEntry}>
        <Text style={styles.date}>{`${toModernDate(leftValue)}, `}</Text>
        <Text style={styles.time}>{toTime(leftValue)}</Text>
      </View>
      <View style={styles.textEntry}>
        <Text style={[styles.date]}>{`${toModernDate(rightValue)}, `}</Text>
        <Text style={[styles.time]}>{toTime(rightValue)}</Text>
      </View>
    </EntryRow>
    <IconButtonBolek onPress={() => onActionPress(itemId)} source={actionImg} />
  </Container>
);

DateAndTimeRow.propTypes = {
  itemId: PropTypes.string.isRequired,
  leftValue: PropTypes.number.isRequired,
  rightValue: PropTypes.number.isRequired,
  actionImg: imagePropType.isRequired,
  onActionPress: PropTypes.func.isRequired,
};

export default DateAndTimeRow;
