import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { companyBillingProfilePropTypes } from '../../../constants/propTypes';
import FormikWithValidation from '../../../common/components/form/FormikWithValidation';
import FormikFieldPrimaryInputTextNextSupport from '../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import Wrapper from '../../../common/components/Wrapper';
import Header from '../../../common/components/header/Header';
import Button from '../../../common/components/buttons/Button';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import { billingProfileFormValidation } from './billingProfileFormValidation';
import { spacings, colors, fontSizes } from '../../../common/styles/base.style';
import AutoScrollableKeyboardAwareScrollView from '../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import FormikEffect from '../../../common/components/form/FormikEffect';
// import GeneralFormError from '../../../common/components/form/GeneralFormError';
import ViewportController from '../../../common/components/ViewportController';

const styles = StyleSheet.create({
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subheader: {
    fontSize: fontSizes.xl,
    paddingBottom: spacings.sm,
  },
});

class BillingProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      profile,
      handleSave,
      translate,
      handleBack,
      onFormChange,
      headerTitle,
      buttonLabel,
      subheaderFunc,
    } = this.props;
    const t = (key) => translate(`company.payments.billingProfile.${key}`);

    return (
      <ViewportController safeAreaBottom>
        <ScreenHeader
          title={headerTitle}
          showBackArrow
          handleBack={handleBack}
          shadow
          subheaderFunc={subheaderFunc}
        />
        {profile
          ? (
            <ViewportController safeAreaBottom>
              <AutoScrollableKeyboardAwareScrollView>
                <Wrapper style={{ marginTop: spacings.sm }}>
                  <FormikWithValidation
                    enableReinitialize
                    initialValues={profile}
                    validation={billingProfileFormValidation}
                    onSubmit={handleSave}
                    render={({ handleSubmit, isBtnDisabled, /* errors */ }) => (
                      <>
                        <FormikEffect onChange={onFormChange} />

                        {/* <Header style={styles.subheader}>{t('emailSubheader')}</Header>
                        <FormikFieldPrimaryInputTextNextSupport
                          name="email"
                          label={t('labels.email')}
                          keyboardType="email-address"
                          nextComponent={this.state.cityInputRef}
                          autoCapitalize="none"
                        /> */}

                        <Header style={styles.subheader}>{t('addressSubheader')}</Header>
                        <FormikFieldPrimaryInputTextNextSupport
                          name="address.city"
                          label={t('labels.city')}
                          innerRef={(ref) => !this.state.cityInputRef && this.setState({ cityInputRef: ref })}
                          nextComponent={this.state.addressLine1InputRef}
                        />
                        <FormikFieldPrimaryInputTextNextSupport
                          name="address.line1"
                          label={t('labels.line1')}
                          innerRef={(ref) => !this.state.addressLine1InputRef && this.setState({ addressLine1InputRef: ref })}
                          nextComponent={this.state.addressLine2InputRef}
                        />
                        {/* <FormikFieldPrimaryInputTextNextSupport
                          name="address.line2"
                          label={t('labels.line2')}
                          innerRef={(ref) => !this.state.addressLine2InputRef && this.setState({ addressLine2InputRef: ref })}
                          nextComponent={this.state.postalCodeInputRef}
                        /> */}
                        <FormikFieldPrimaryInputTextNextSupport
                          name="address.postal_code"
                          label={t('labels.postalCode')}
                          innerRef={(ref) => !this.state.postalCodeInputRef && this.setState({ postalCodeInputRef: ref })}
                          nextComponent={this.state.countryInputRef}
                        />
                        {/* <FormikFieldPrimaryInputTextNextSupport
                          name="address.country"
                          label={t('labels.country')}
                          innerRef={(ref) => !this.state.countryInputRef && this.setState({ countryInputRef: ref })}
                          nextComponent={this.state.vatNumberInputRef}
                          disabled
                        /> */}
                        {/* <Header style={styles.subheader}>{t('invoiceSubheader')}</Header>
                         <FormikFieldPrimaryInputTextNextSupport
                          name="vatNumber"
                          label={t('labels.vatNumber')}
                          innerRef={(ref) => !this.state.vatNumberInputRef && this.setState({ vatNumberInputRef: ref })}
                          nextComponent={this.state.invoiceReferenceInputRef}
                          placeholder={t('labels.vatNumberPlaceholder')}
                          autoCorrect={false}
                          autoCapitalize="characters"
                        />
                        <FormikFieldPrimaryInputTextNextSupport
                          name="invoiceReference"
                          label={t('labels.invoiceReference')}
                          innerRef={(ref) => !this.state.invoiceReferenceInputRef && this.setState({ invoiceReferenceInputRef: ref })}
                          returnKeyType="done"
                        />
                        <GeneralFormError errors={errors} /> */}
                        <Button
                          title={buttonLabel || translate('generic.save')}
                          disabled={isBtnDisabled}
                          onPress={handleSubmit}
                          showLoaderOnPress
                        />
                      </>
                    )}
                  />
                </Wrapper>
              </AutoScrollableKeyboardAwareScrollView>
            </ViewportController>
          )
          : <ActivityIndicator size="large" color={colors.magenta} style={styles.spinner} />}
      </ViewportController>
    );
  }
}

BillingProfileScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  subheaderFunc: PropTypes.func,
  buttonLabel: PropTypes.string,
  profile: companyBillingProfilePropTypes,
  handleSave: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

BillingProfileScreen.defaultProps = {
  profile: null,
  buttonLabel: undefined,
  subheaderFunc: undefined,
};

export default withLocalize(BillingProfileScreen);
