import { GIG_CATEGORIES_REQUEST } from '../../constants/actionTypes';

function getGigCategories() {
  return {
    type: GIG_CATEGORIES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'gig/category',
      },
    },
  };
}

export function getAvailableGigCategories() {
  return async (dispatch, getState) => {
    const categories = getState().gigCategories;
    if (categories.size > 0) {
      return;
    }
    dispatch(getGigCategories());
  };
}
