import React, { Component } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikFieldMonthYearPicker from '../../../../common/components/form/FormikFieldMonthYearPicker';
import FormikFieldPrimarySwitch from '../../../../common/components/form/switch/FormikFieldPrimarySwitch';
import { activeLanguagePropTypes, candidateEducationPropType, educationCategoryPropType } from '../../../../constants/propTypes';
import validation, { CANDIDATE_PROFILE_COURSE_MAX_LENGTH, CANDIDATE_PROFILE_SCHOOL_MAX_LENGTH } from './CandidateProfileEducationFormValidation';
import Wrapper from '../../../../common/components/Wrapper';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import FormikFieldPrimaryInputTextNextSupport from '../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';
import { getEducationCategories } from '../../education/category/educationCategoriesActions';
import FeatureToggle from '../../../../common/components/FeatureToggle';

class CandidateProfileEducationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getEducationCategories();
  }

  getTranslatedCategories = (nullable) => {
    const categories = this.props.educationCategories.map((c) => ({
      value: c.key,
      label: c.translations.find((t) => t.code === this.props.activeLanguage.code).value,
    }));
    if (Platform.OS !== 'ios' && !this.props.education.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  render() {
    const {
      translate, renderFooter, education, handleSubmit,
    } = this.props;

    const t = (key) => translate(`candidateProfile.addEducation.${key}`);
    return (
      <FormikWithValidation
        validation={validation.filter((item) => !item.grantedByToggle || this.props.grantedFeatures.includes('PROFILE_DATA_EXTENSION'))}
        initialValues={education}
        enableReinitialize
        onSubmit={handleSubmit}
        render={({
          handleSubmit: handleSubmitFormik, isBtnDisabled, values, setFieldValue,
        }) => (
          <>
            <ModalKeyboardAwareScrollView>
              <Wrapper>
                <FeatureToggle requestedFeature="PROFILE_DATA_EXTENSION">
                  <FormikFieldPrimaryPicker
                    name="category"
                    items={this.getTranslatedCategories(true)}
                    label={t('categoryLabel')}
                    nullable
                  />
                </FeatureToggle>
                <FormikFieldPrimaryInputTextNextSupport
                  name="course"
                  label={t('courseLabel')}
                  placeholder={t('coursePlaceholder')}
                  maxLength={CANDIDATE_PROFILE_COURSE_MAX_LENGTH}
                  counter
                  nextComponent={this.state.schoolInputRef}
                />
                <FormikFieldPrimaryTextInput
                  innerRef={(ref) => !this.state.schoolInputRef && this.setState({ schoolInputRef: ref })}
                  name="school"
                  label={t('schoolLabel')}
                  placeholder={t('schoolPlaceholder')}
                  maxLength={CANDIDATE_PROFILE_SCHOOL_MAX_LENGTH}
                  counter
                  returnKeyType="done"
                />
                <FormikFieldMonthYearPicker
                  name="fromDate"
                  label={t('fromLabel')}
                />
                <FormikFieldMonthYearPicker
                  name="toDate"
                  label={t('toLabel')}
                  enabled={!values.ongoing}
                  onValueChange={
                    (val) => {
                      if (val && this.props.grantedFeatures.includes('PROFILE_DATA_EXTENSION')) {
                        setFieldValue('graduationDate', val);
                      }
                    }
                  }
                />
                <FeatureToggle requestedFeature="PROFILE_DATA_EXTENSION">
                  <FormikFieldMonthYearPicker
                    name="graduationDate"
                    label={t('graduationLabel')}
                    withFuture
                    nullable
                  />
                </FeatureToggle>
              </Wrapper>
            </ModalKeyboardAwareScrollView>
            {renderFooter({ handleSubmitFormik, isBtnDisabled })}
          </>
        )}
      />
    );
  }
}

CandidateProfileEducationForm.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  renderFooter: PropTypes.func.isRequired,
  education: candidateEducationPropType.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getEducationCategories: PropTypes.func.isRequired,
  educationCategories: PropTypes.arrayOf(educationCategoryPropType).isRequired,
  grantedFeatures: PropTypes.arrayOf(String).isRequired,
};

const mapStateToProps = (state) => ({
  educationCategories: state.educationCategories.toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { getEducationCategories })(withLocalize(CandidateProfileEducationForm));
