import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import { clearGigCandidates, getGigCandidates, hireByLetsgig, storeMyGigsScrollPosition } from '../companyGigCandidatesActions';
import { gigCandidatePropType, withGigCandidateAccessOnHireHandlePropTypes } from '../../../../../constants/propTypes';
import { OFFERS_LIST_KEY } from '../companyGigCandidatesReducer';
import { GIG_CANDIDATE_STATUS } from '../../../../../constants/matchingStatus';
import Routing from '../../../../../core/routing/index';
import candidatesListStyles from '../components/GigCandidatesListStyle';
import CompanyGigCandidateOfferModal from '../offerModal/CompanyGigCandidateOfferModal';
import CompanyGigCandidateViewOfferModal from '../offerModal/CompanyGigCandidateViewOfferModal';
import { RIGHT_BUTTONS } from '../../../../../common/components/header/ScreenHeader';
import CompanyGigCandidateListItem from '../CompanyGigCandidateListItem';
import ListItem from '../../../../../common/components/ListItem';
import { COMPANY_GIG_CHAT, COMPANY_GIG_MATCHING_HIRED } from '../../../../../constants/routes';
import { showCancelResendOfferAlert } from '../../../../../common/components/alert/alerts';
import { withGigCandidateAccessHandleOnHire } from '../withGigCandidateAccessHandleOnHire';
import { removeGigCandidateStatusIndicator } from '../../../companyGigActions';
import CandidateQuestionnaireModalView from '../questionnaireModal/CandidateQuestionnaireModalView';
import PrimarySectionList from '../../../../../common/components/PrimarySectionList';
import { successNotification, errorNotification } from '../../../../../common/notifications_center/notificationCenterActions';
import { checkPaymentIntent } from '../../../../payments/companyPaymentsActions';

const { withRouter } = Routing;

class CompanyGigOffersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      viewModalVisible: false,
      modalGigId: undefined,
      modalCandidateId: undefined,
      questionnaireModalVisible: false,
      modalQuestionnaire: undefined,
    };
  }

  componentDidMount() {
    this.scrollPosition = 0;
    this.props
      .getGigCandidates(
        this.props.match.params.id,
        [GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED, GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER],
        OFFERS_LIST_KEY
      )
      .then(() => {
        if (this.props.history.action === 'POP') {
          const { storedScrollPosition } = this.props;
          if (storedScrollPosition) {
            this.scrollToLastKnownPosition(storedScrollPosition);
          }
        } else {
          this.props.storeMyGigsScrollPosition(this.scrollPosition);
        }
        this.setTabInactive();
      });

      const querySearch = queryString.parse(this.props.location.search);

      if (querySearch.payment_intent) {
        this.getPaymentIntent(querySearch.payment_intent, querySearch.gigId, querySearch.candidateId);
      }
  }

  componentWillUnmount() {
    this.props.storeMyGigsScrollPosition(this.scrollPosition);
    this.props.clearGigCandidates(OFFERS_LIST_KEY);
  }

  getPaymentIntent = (paymentIntentId, gigId, candidateId) => {
    const bodyData = {
      paymentIntentId,
    };
    this.props.checkPaymentIntent(bodyData).then(async (data) => {
      const intentStatus = data.payload.status;
      if (intentStatus === 'requires_capture') {
        await this.props.hireByLetsgig(gigId, candidateId);
        this.props.successNotification('company.payments.directHiring.successPayTitle');
        // Clear payment_intent from the search parameters
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.delete('payment_intent');
        this.props.history.replace({
          search: searchParams.toString()
        });
        this.props.history.push(COMPANY_GIG_MATCHING_HIRED.replace(':id', gigId));
      } else {
        this.props.errorNotification('company.payments.directHiring.failedPayTitle');
      }
      // Clear payment_intent from the search parameters
      const searchParams = new URLSearchParams(this.props.location.search);
      searchParams.delete('payment_intent');
      this.props.history.replace({
        search: searchParams.toString()
      });
    });
  };

  closeModal = (quiet) => {
    if (quiet) {
      this.setState({ modalVisible: false });
    } else {
      showCancelResendOfferAlert(
        (subKey) => this.props.translate(`company.gigOffer.${subKey}`),
        () => this.setState({ modalVisible: false })
      );
    }
  };

  openViewModal = (gigId, candidateId, editEnabled) => {
    this.setState({
      viewModalVisible: true,
      viewModalEditEnabled: editEnabled,
      modalGigId: gigId,
      modalCandidateId: candidateId,
    });
  };

  setTabInactive = () => {
    if (this.isNewContentInTab()) {
      this.props.removeGigCandidateStatusIndicator(this.props.match.params.id, GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED);
    }
  };

  isNewContentInTab = () => !!this.props.newContentIndicators.find(
      ({ gigId, gigCandidateStatus }) => gigId === this.props.match.params.id && gigCandidateStatus === GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED
    );

  setListRef = (ref) => {
    this.listRef = ref;
  };

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  closeViewModal = () => {
    this.setState({ viewModalVisible: false });
  };

  offerViewModalOpenEdit = () => {
    this.closeViewModal();
    this.openModal(this.state.modalGigId, this.state.modalCandidateId);
  };

  // eslint-disable-next-line react/sort-comp
  offerViewModalEditHeaderProps = { rightButton: RIGHT_BUTTONS.EDIT, handlerForRightButton: this.offerViewModalOpenEdit };

  openModal = (gigId, candidateId) => {
    this.setState({
      modalVisible: true,
      modalGigId: gigId,
      modalCandidateId: candidateId,
    });
  };

  openQuestionnaireModal = (questionnaire) => {
    this.setState({ questionnaireModalVisible: true, modalQuestionnaire: questionnaire });
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  startChat(gigId, candidateId) {
    this.props.history.push(COMPANY_GIG_CHAT.replace(':gigId', gigId).replace(':candidateId', candidateId));
  }

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(
      () => this.listRef.scrollToLocation({
          sectionIndex: 0,
          animated: true,
          itemIndex: 0,
          viewOffset: -scrollPosition + 7,
          viewPosition: 0,
        }),
      0
    );
  }

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, modalQuestionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalView
        visible={questionnaireModalVisible}
        closeModal={this.closeQuestionnaireModal}
        questionnaire={modalQuestionnaire}
      />
    );
  }

  renderViewModal() {
    const { viewModalVisible, modalGigId, modalCandidateId, viewModalEditEnabled } = this.state;

    return (
      <CompanyGigCandidateViewOfferModal
        headerProps={viewModalEditEnabled ? this.offerViewModalEditHeaderProps : undefined}
        visible={viewModalVisible}
        closeModal={this.closeViewModal}
        gigId={modalGigId}
        candidateId={modalCandidateId}
      />
    );
  }

  renderModal() {
    const { modalVisible, modalGigId, modalCandidateId } = this.state;
    return (
      <CompanyGigCandidateOfferModal
        edit
        visible={modalVisible}
        closeModal={this.closeModal}
        gigId={modalGigId}
        candidateId={modalCandidateId}
      />
    );
  }

  render() {
    const { translate, offers } = this.props;
    const acceptedOffers = offers ? offers.filter((offer) => offer.matching.status === GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED) : undefined;
    const pendingOffers = offers ? offers.filter((offer) => offer.matching.status === GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER) : undefined;

    return (
      <>
        <PrimarySectionList
          getItemLayout={(data, index) => ({ length: 275, offset: index * 275, index })} // fix for not scrolling over ~1300 pixels
          emptyListTextKey="companyGigs.candidates.noMatchingCandidates"
          contentContainerStyle={candidatesListStyles.contentContainerStyle}
          sections={[
            { title: translate('companyGigs.candidates.acceptedOffers'), data: acceptedOffers || [], dataLoading: !acceptedOffers },
            { title: translate('companyGigs.candidates.pendingOffers'), data: pendingOffers || [], dataLoading: !pendingOffers },
          ]}
          keyExtractor={(item) => item.userId}
          onScroll={this.onScroll}
          innerRef={this.setListRef}
          renderItem={({ item: candidate }) => (
            <ListItem
              renderContent={() => (
                <CompanyGigCandidateListItem
                  candidate={candidate}
                  gigId={this.props.match.params.id}
                  online={this.props.usersPresence[candidate.userId]}
                  internalFunctions={{
                    openModal: (editEnabled) => this.openViewModal(this.props.match.params.id, candidate.userId, editEnabled),
                    showQuestionnaire: () => this.openQuestionnaireModal(candidate.matching.questionnaire),
                    handleBillingOnHire: this.props.handleBillingOnHire,
                    goToPricingPlan: this.props.goToPricingPlan,
                  }}
                />
              )}
            />
          )}
        />
        {this.renderModal()}
        {this.renderViewModal()}
        {this.renderQuestionnaireModal()}
      </>
    );
  }
}

CompanyGigOffersView.propTypes = {
  getGigCandidates: PropTypes.func.isRequired,
  clearGigCandidates: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(gigCandidatePropType),
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      gigId: PropTypes.string.isRequired,
      gigCandidateStatus: PropTypes.string.isRequired,
    })
  ).isRequired,
  removeGigCandidateStatusIndicator: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  ...withGigCandidateAccessOnHireHandlePropTypes,
  storedScrollPosition: PropTypes.number.isRequired,
  storeMyGigsScrollPosition: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  errorNotification: PropTypes.func.isRequired,
  checkPaymentIntent: PropTypes.func.isRequired,
  hireByLetsgig: PropTypes.func.isRequired,
};

CompanyGigOffersView.defaultProps = {
  offers: null,
};

const mapStateToProps = (state) => ({
  offers: state.gigCandidates.get(OFFERS_LIST_KEY) ? state.gigCandidates.get(OFFERS_LIST_KEY).toJS() : null,
  storedScrollPosition: state.gigCandidates.get('companyMyGigsScrollPosition'),
  newContentIndicators: state.companyGig.get('gigsList').get('newContentIndicators').toJS(),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default withGigCandidateAccessHandleOnHire(
  withRouter(
    connect(mapStateToProps, {
      getGigCandidates,
      clearGigCandidates,
      removeGigCandidateStatusIndicator,
      storeMyGigsScrollPosition,
      errorNotification,
      successNotification,
      checkPaymentIntent,
      hireByLetsgig,
    })(withLocalize(CompanyGigOffersView))
  )
);
