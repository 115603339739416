import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import GigCreatorHeader from '../../GigCreatorHeader';
import { LinkButton } from '../../../../common/components/index';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import FormikFieldDateTimePicker from '../../../../common/components/form/FormikFieldDateTimePicker';
import GigCreatorStepInfoValidation from './GigCreatorStepInfoValidation';
import Container from '../../../../common/components/Container';
import FormikFieldPrimaryInputTextNextSupport from '../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import { spacings } from '../../../../common/styles/base.style';
import Button from '../../../../common/components/buttons/Button';
import AutoScrollableKeyboardAwareScrollView from '../../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';

const getDefaultDate = () => {
  const minDate = moment();
  if (minDate.minute() !== 0) {
    minDate.add(1, 'h').minute(0);
  }
  return minDate.valueOf();
};

class GigCreatorStepInfoScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      translate, initialValues, onSubmit, stepNumber, isEdit, nextScreen, maxStepsNumber,
    } = this.props;

    return (
      <Container>
        <FormikWithValidation
          validation={GigCreatorStepInfoValidation}
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, status, isBtnDisabled }) => (
            <>
              <GigCreatorHeader
                isEdit={isEdit}
                stepNo={stepNumber}
                title={translate('companyGigCreator.info.header')}
                onRightButtonPress={handleSubmit}
                isRightButtonDisabled={isBtnDisabled}
                maxStepsNumber={maxStepsNumber}
              />
              <AutoScrollableKeyboardAwareScrollView>
                <KeyboardAwareFlexWrapper>
                  <FormikFieldPrimaryInputTextNextSupport
                    name="name"
                    counter
                    maxLength={30}
                    label={translate('companyGigCreator.info.titleLabel')}
                    placeholder={translate('companyGigCreator.info.placeholder')}
                    nextComponent={this.state.expiryDateInputRef}
                    style={{ label: { paddingTop: spacings.lg }, input: { paddingTop: spacings.lg } }}
                  />
                  <FormikFieldDateTimePicker
                    innerRef={(ref) => !this.state.expiryDateInputRef && this.setState({ expiryDateInputRef: ref })}
                    name="expiryDate"
                    label={translate('companyGigCreator.info.expiryDateLabel')}
                    placeholder={translate('companyGigCreator.info.expiryDatePlaceholder')}
                    defaultDateFunc={getDefaultDate}
                    minimumDateFunc={() => new Date().getTime()}
                  />
                </KeyboardAwareFlexWrapper>

                {isEdit ? (
                  <Button
                    title={translate('generic.save')}
                    disabled={isBtnDisabled}
                    onPress={handleSubmit}
                  />
                ) : (
                  <LinkButton
                    to={nextScreen}
                    title={translate('generic.next')}
                    doRedirect={status && status.redirect}
                    disabled={isBtnDisabled}
                    onPress={handleSubmit}
                  />
                )}
              </AutoScrollableKeyboardAwareScrollView>
            </>
          )}
        />
      </Container>
    );
  }
}

GigCreatorStepInfoScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    expiryDate: PropTypes.number,
  }).isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepInfoScreen);
