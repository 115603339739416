import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import CandidateMyPoolsAvailableShiftsScreen from './CandidateMyPoolsAvailableShiftsScreen';
import CandidateMyPoolsBookedShiftsScreen from './CandidateMyPoolsBookedShiftsScreen';
import { bookShift, getShifts } from '../../shift/candidateShiftActions';
import Container from '../../../common/components/Container';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import {
  activityIndicator, colors, dynamicSpacings, spacings,
} from '../../../common/styles/base.style';
import {
  CANDIDATE_MY_POOLS_SHIFTS,
  CANDIDATE_MY_POOLS_SHIFTS_AVAILABLE,
  CANDIDATE_MY_POOLS_SHIFTS_BOOKED,
} from '../../../constants/routes';
import Routing from '../../../core/routing/index';

const { Route, Redirect, withRouter } = Routing;

const styles = StyleSheet.create({
  shiftTypesSection: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: spacings.md,
    backgroundColor: colors.ultraLightGrey,
    paddingHorizontal: dynamicSpacings.md,
  },
});

const SHIFTS_TABS = {
  AVAILABLE: 'AVAILABLE',
  BOOKED: 'BOOKED',
};

class CandidateMyPoolsShiftsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { shifts: [], loading: true, selectedShiftType: undefined };
  }

  componentDidMount() {
    switch (this.props.location.pathname) {
      case CANDIDATE_MY_POOLS_SHIFTS:
      case CANDIDATE_MY_POOLS_SHIFTS_AVAILABLE: {
        this.fetchShifts(SHIFTS_TABS.AVAILABLE);
        break;
      }
      case CANDIDATE_MY_POOLS_SHIFTS_BOOKED: {
        this.fetchShifts(SHIFTS_TABS.BOOKED);
        break;
      }
      default:
    }
  }

  fetchShifts = (selectedShiftType) => {
    this.setState({ loading: true, selectedShiftType });
    if (selectedShiftType === SHIFTS_TABS.AVAILABLE) {
      this.props.getShifts({ available: true, applied: false }).then((res) => {
        this.setState({ shifts: res.payload, loading: false });
      });
    }
    if (selectedShiftType === SHIFTS_TABS.BOOKED) {
      this.props.getShifts({ applied: true }).then((res) => {
        this.setState({ shifts: res.payload, loading: false });
      });
    }
  };

  render() {
    const { translate } = this.props;
    const { loading, selectedShiftType } = this.state;

    return (
      <Container>
        <Route exact path={CANDIDATE_MY_POOLS_SHIFTS} render={() => <Redirect to={CANDIDATE_MY_POOLS_SHIFTS_AVAILABLE} />} />
        <View style={styles.shiftTypesSection}>
          <Button
            title={translate('candidate.myPools.shifts.availableShifts')}
            type={selectedShiftType === SHIFTS_TABS.AVAILABLE ? BUTTON_TYPES.PRIMARY_RED : BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.FLEX_SM}
            onPress={() => this.fetchShifts(SHIFTS_TABS.AVAILABLE)}
          />
          <Button
            title={translate('candidate.myPools.shifts.bookedShifts')}
            type={selectedShiftType === SHIFTS_TABS.BOOKED ? BUTTON_TYPES.PRIMARY_RED : BUTTON_TYPES.SECONDARY}
            size={BUTTON_SIZES.FLEX_SM}
            onPress={() => this.fetchShifts(SHIFTS_TABS.BOOKED)}
          />
        </View>
        <Container>
          {loading && (
            <ActivityIndicator size="large" style={{ ...activityIndicator, backgroundColor: colors.smokeWhite }} />
          )}
          {!loading && selectedShiftType === SHIFTS_TABS.AVAILABLE && (
            <CandidateMyPoolsAvailableShiftsScreen {...this.state} />
          )}
          {!loading && selectedShiftType === SHIFTS_TABS.BOOKED && (
            <CandidateMyPoolsBookedShiftsScreen {...this.state} />
          )}
        </Container>
      </Container>
    );
  }
}

CandidateMyPoolsShiftsContainer.propTypes = {
  getShifts: PropTypes.func.isRequired,
  bookShift: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default connect(null, { getShifts, bookShift })(withLocalize(withRouter(CandidateMyPoolsShiftsContainer)));
