import React from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import {
  colors, spacings, fontFamily, fontSizes,
} from '../styles/base.style';

export const BADGE_TYPE = {
  ORANGE: 'orange',
  SEA_BLUE: 'seaBlue',
};

const styles = StyleSheet.create({
  base: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.xxs,
    letterSpacing: 0.5,
    backgroundColor: colors.bluePurpleTwo,
    color: colors.white,
    paddingHorizontal: spacings.sm - 3,
    paddingVertical: spacings.xxs,
    borderRadius: 12,
    marginBottom: spacings.xs,
    marginRight: spacings.xs,
    textAlign: 'center',
    overflow: 'hidden', // to have radius on ios
  },
  [BADGE_TYPE.SEA_BLUE]: {
    backgroundColor: colors.tealish,
  },
  [BADGE_TYPE.ORANGE]: {
    backgroundColor: colors.brightOrange,
    paddingVertical: spacings.xs,
    fontSize: fontSizes.xs,
    borderRadius: 16,
    paddingHorizontal: spacings.sm - 1,
  },
});

const CategoryBadge = ({ categoryName, type }) => (
  <Text style={[styles.base, type && styles[type]]}>{categoryName}</Text>
);

CategoryBadge.propTypes = {
  categoryName: PropTypes.string.isRequired,
  type: PropTypes.string,
};

CategoryBadge.defaultProps = {
  type: null,
};

export default CategoryBadge;
