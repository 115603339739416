import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import {
  colors, dynamicSpacings, fontFamily, fontSizes, modernColors, spacings,
} from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import { getAmountWithTax, getTaxFromAmount, SALARY_COMPONENTS } from '../../../../common/gig/shiftSalaryUtils';

const summaryStyles = StyleSheet.create({
  summary: {
    display: 'flex',
    backgroundColor: modernColors.lightGrey,
    paddingVertical: spacings.md,
    paddingHorizontal: dynamicSpacings.sm,
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm),
    marginBottom: spacings.sm,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: spacings.md,
    marginTop: spacings.xxs,
  },
  rowTotal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: spacings.md,
    marginTop: spacings.sm,
    marginBottom: spacings.sm,
    borderBottomWidth: 2,
    borderBottomColor: colors.greyishBrown,
    paddingBottom: spacings.xxs,
  },
  rowTotalText: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm),
  },
});

const ShiftSalarySummary = (props) => {
  const { values, translate } = props;
  const translateByKey = (key, params) => translate(`company.shifts.shift.${key}`, params);

  const totalSalary = values.holidayPay ? getAmountWithTax(values.salary, SALARY_COMPONENTS.holidayPay) : values.salary;
  const socialFees = getTaxFromAmount(totalSalary, SALARY_COMPONENTS.socialFees);
  const insurance = getTaxFromAmount(totalSalary, SALARY_COMPONENTS.insurance);
  const totalSalaryCost = +totalSalary + +socialFees + +insurance;
  const platformAndEducation = getTaxFromAmount(totalSalaryCost, SALARY_COMPONENTS.platformAndEducation);
  const letsgigMargin = getTaxFromAmount(totalSalaryCost, SALARY_COMPONENTS.letsgigMargin);
  const totalHiringCost = +totalSalaryCost + +platformAndEducation + +letsgigMargin;

  return (
    <View style={summaryStyles.summary}>
      <Text style={summaryStyles.header}>{translateByKey('salary.summaryHeader')}</Text>
      <View style={{ flexDirection: 'column' }}>
        <View style={summaryStyles.row}>
          <Text>
            {values.holidayPay
              ? translateByKey('salary.summaryValueHoliday', { percent: SALARY_COMPONENTS.holidayPay })
              : translateByKey('salary.summaryValue')}
          </Text>
          <Text>{totalSalary}</Text>
        </View>
        <View style={summaryStyles.row}>
          <Text>{translateByKey('salary.summarySocialFees', { percent: SALARY_COMPONENTS.socialFees })}</Text>
          <Text>{socialFees}</Text>
        </View>
        <View style={summaryStyles.row}>
          <Text>{translateByKey('salary.summaryInsurance', { percent: SALARY_COMPONENTS.insurance })}</Text>
          <Text>{insurance}</Text>
        </View>
        <View style={summaryStyles.rowTotal}>
          <Text style={summaryStyles.rowTotalText}>{translateByKey('salary.summaryTotalSalaryCost')}</Text>
          <Text style={summaryStyles.rowTotalText}>{totalSalaryCost}</Text>
        </View>
        <View style={summaryStyles.row}>
          <Text>{translateByKey('salary.summaryPlatformFee', { percent: SALARY_COMPONENTS.platformAndEducation })}</Text>
          <Text>{platformAndEducation}</Text>
        </View>
        <View style={summaryStyles.row}>
          <Text>{translateByKey('salary.summaryLetsgigFee', { percent: SALARY_COMPONENTS.letsgigMargin })}</Text>
          <Text>{letsgigMargin}</Text>
        </View>
        <View style={summaryStyles.rowTotal}>
          <Text style={summaryStyles.rowTotalText}>{translateByKey('salary.summaryTotalHiringCost')}</Text>
          <Text style={summaryStyles.rowTotalText}>{totalHiringCost}</Text>
        </View>
      </View>
    </View>
  );
};

ShiftSalarySummary.propTypes = {
  translate: PropTypes.func.isRequired,
  values: PropTypes.shape({
    holidayPay: PropTypes.bool,
    salary: PropTypes.string,
  }).isRequired,
};

export default withLocalize(ShiftSalarySummary);
