import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text, View, StyleSheet } from 'react-native';
import NavItem from './NavItem';
import { countUnreadChats } from '../../chat/chatHelper';
import { fontFamily, colors } from '../../styles/base.style';

const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
    right: 4,
    top: 9,
    backgroundColor: colors.seablue,
    borderRadius: 10,
    borderColor: colors.white,
    borderWidth: 2,
  },
  text: {
    color: colors.white,
    paddingHorizontal: 7,
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: 11.5,
    letterSpacing: -0.5,
  },
});

class ChatNavItem extends Component {
  renderExtras = () => {
    const { unreadChatsNo } = this.props;
    if (unreadChatsNo === 0) {
      return null;
    }

    return <View style={styles.bubble}><Text style={styles.text}>{unreadChatsNo}</Text></View>;
  };

  render() {
    const {
      unreadChatsNo, ...props
    } = this.props;

    return (
      <NavItem {...props} renderExtras={this.renderExtras} renderExtrasData={unreadChatsNo} />
    );
  }
}

ChatNavItem.propTypes = {
  unreadChatsNo: PropTypes.number.isRequired,
};

const mapStateToProps = (state, { store }) => {
  const userScope = state.auth.get('userScope');

  return {
    unreadChatsNo: countUnreadChats(userScope, store),
  };
};

export default connect(mapStateToProps)(ChatNavItem);
