import { List, fromJS } from 'immutable';
import { EDUCATION_CATEGORIES_REQUEST_SUCCESS } from '../../../../constants/actionTypes';

export default function educationCategoriesReducer(state = List(), action) {
  switch (action.type) {
    case EDUCATION_CATEGORIES_REQUEST_SUCCESS:
      return fromJS(action.payload.categories.sort((a, b) => a.order - b.order));
    default:
      return state;
  }
}
