import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import UserProfileImage from '../image/UserProfileImage';
import * as propTypes from '../../../constants/propTypes';
import OnlineIndicator from './OnlineIndicator';

const styles = StyleSheet.create({
  container: {
    marginRight: 11,
  },
  profileImage: {
    width: 66,
    height: 66,
    borderRadius: 33,
  },
  onlineIndicator: {
    position: 'absolute',
    bottom: 2,
    right: 2,
  },
});

function ChatListItemProfileImage({ profileImageUri, online, style }) {
  return (
    <View style={styles.container}>
      <UserProfileImage
        profileImageUri={profileImageUri}
        style={[styles.profileImage, style.profileImage]}
      />
      <OnlineIndicator online={online} style={{ ...styles.onlineIndicator, ...style.indicator }} />
    </View>
  );
}

ChatListItemProfileImage.propTypes = {
  profileImageUri: PropTypes.string,
  online: PropTypes.bool,
  style: PropTypes.shape({
    profileImage: propTypes.stylePropTypes,
    indicator: propTypes.stylePropTypes,
  }),
};

ChatListItemProfileImage.defaultProps = {
  profileImageUri: null,
  online: false,
  style: {
    profileImage: null,
    indicator: {},
  },
};

export default ChatListItemProfileImage;
