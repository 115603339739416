import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import MonthYearPicker from './MonthYearPicker';
import { getValueByPath } from '../../../utilities/objectUtils';

/*
  We need this to be a Component because we don't want onValueChange function to change between rerenders: MonthYearPicker is a PureComponent.
 */
class FormikMonthYearPicker extends Component {
  onValueChange = (itemValue) => {
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched },
      onValueChange,
    } = this.props;

    setFieldValue(name, itemValue);
    setFieldTouched(name, true);
    if (onValueChange) {
      onValueChange(itemValue);
    }
  };

  render() {
    const {
      field: { name, value }, form: {
        touched, errors,
      }, ...props
    } = this.props;

    return (
      <MonthYearPicker
        {...props}
        onValueChange={this.onValueChange}
        value={value}
        error={(getValueByPath(touched, name) && errors[name]) || null}
        testID={name}
      />
    );
  }
}

FormikMonthYearPicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onValueChange: PropTypes.func,
};

FormikMonthYearPicker.defaultProps = {
  onValueChange: null,
};

const FormikFieldMonthYearPicker = (props) => (
  <Field
    component={FormikMonthYearPicker}
    {...props}
  />
);

export default FormikFieldMonthYearPicker;
