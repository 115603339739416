import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import PrimaryModal from '../../../../common/components/modal/PrimaryModal';
import { BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import { Button } from '../../../../common/components';
import PrimaryText from '../../../../common/components/PrimaryText';
import { fontFamily, fontSizes, hp, spacings, wp } from '../../../../common/styles/base.style';
import ImageComponent from '../../../../common/components/image/ImageComponent';

const imgSrc = require('../../../../assets/photos/favouritesPopup/gigs-info.png');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  boldText: {
    width: '75%',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: fontSizes.xxl,
    fontFamily: fontFamily.SFProDisplayBold,
    marginBottom: spacings.sm,
  },
  regularText: {
    width: '90%',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: fontSizes.lg,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginBottom: spacings.md,
    paddingTop: 30,
    paddingBottom: 30,
  },
  applyButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
});

const ApplyExternalAction = ({ translate, onApply }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  return (
    <View style={styles.applyButton}>
      <Button type={BUTTON_TYPES.PRIMARY_RED} title={translate('externalGigPreview.applyButton')} onPress={openModal} />
      <PrimaryModal visible={modalVisible} onClose={closeModal}>
        {/* Modal Content */}
        <ImageComponent image={imgSrc} size="md" style={{ image: { width: wp(65), height: hp(45), resizeMode: 'contain' } }} />
        <PrimaryText style={styles.boldText}>
          {translate('externalGigPreview.thankYou')}
        </PrimaryText>
        <PrimaryText style={styles.regularText}>
          {translate('externalGigPreview.thankYouForApplying')}
        </PrimaryText>
        <Button type={BUTTON_TYPES.PRIMARY_RED} title="Tillbaka" onPress={onApply} />
      </PrimaryModal>
    </View>
  );
};

ApplyExternalAction.propTypes = {
  translate: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default withLocalize(ApplyExternalAction);
