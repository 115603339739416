/*
  The placeholders here are replaced before the build with the right values.
  This way we can use the same mechanism for the web and apps.
*/

function isPlaceholder(value) {
  return value.startsWith('LETSGIG_CONFIG_PLACEHOLDER__');
}

const DEFAULT_CONFIG = {
  baseUrl: process.env.BASE_URL || 'http://localhost:3000/',
  mainPageUrl: 'https://www-stage.letsgig.com',
  rocketChatWebsocketUrl: 'ws://3.252.187.62/websocket',
  rocketChatRestUrl: 'http://3.252.187.62/api/v1',
  termsOfUseUrl: 'https://s3-eu-west-1.amazonaws.com/assets.letsgig.com/terms.html',
  privacyPolicyUrl: 'https://s3-eu-west-1.amazonaws.com/assets.letsgig.com/privacyPolicy.html',
  contactEmail: 'hello@letsgig.com',
  sentryDSN: 'https://3844ec44995a43938949d354b0ff42e5@sentry.io/1407120',
  googleAnalyticsTrackingId: 'UA-132547031-1',
  facebookAppId: '165545581529574'
};

const config = {
  baseUrl: 'https://api-prd.letsgig.com/',
  mainPageUrl: 'https://www.letsgig.com',
  rocketChatWebsocketUrl: 'wss://new-lg-rocketchat-production.letsgig.com/websocket',
  rocketChatRestUrl: 'https://new-lg-rocketchat-production.letsgig.com/api/v1',
  defaultLocale: 'en',
  termsOfUseUrl: 'LETSGIG_CONFIG_PLACEHOLDER__TERMS_OF_USE_URL',
  privacyPolicyUrl: 'LETSGIG_CONFIG_PLACEHOLDER__PRIVACY_POLICY_URL',
  contactEmail: 'LETSGIG_CONFIG_PLACEHOLDER__CONTACT_EMAIL',
  sentryDSN: 'https://c5a7a339358945b6b0a70a38dcd80605@sentry.io/1361323',
  googleAnalyticsTrackingId: 'UA-132547031-2',
  facebookAppId: '324104241629059'
};

// const config = {
//  baseUrl: "https://api-prd.letsgig.com",
//  mainPageUrl: "https://www.letsgig.com",
//  rocketChatWebsocketUrl: "wss://new-lg-rocketchat-production.letsgig.com/websocket",
//  rocketChatRestUrl: "https://new-lg-rocketchat-production.letsgig.com/api/v1",
//  defaultLocale: "en",
//  termsOfUseUrl: "LETSGIG_CONFIG_PLACEHOLDER__TERMS_OF_USE_URL",
//  privacyPolicyUrl: "LETSGIG_CONFIG_PLACEHOLDER__PRIVACY_POLICY_URL",
//  contactEmail: "LETSGIG_CONFIG_PLACEHOLDER__CONTACT_EMAIL",
//  sentryDSN: "https://c5a7a339358945b6b0a70a38dcd80605@sentry.io/1361323",
//  googleAnalyticsTrackingId: "UA-132547031-2",
//  facebookAppId: "324104241629059"
// };

function setDefaultConfigIfNeeded() {
  const props = Object.keys(config);
  for (let i = 0; i < props.length; i += 1) {
    const prop = props[i];
    if (isPlaceholder(config[prop])) {
      config[prop] = DEFAULT_CONFIG[prop];
    }
  }
}

setDefaultConfigIfNeeded();

export default config;
