import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import CandidateChatGigListScreen from './CandidateChatGigListScreen';
import { getCandidateChatsInfo } from './candidateChatActions';
import { CANDIDATE_GIG_CHAT } from '../../constants/routes';
import { candidateChatGigItemPropType } from '../../constants/propTypes';

class CandidateChatGigListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, refreshing: true };
  }

  componentDidMount() {
    this.getChatGigList();
  }

  onRefresh = () => {
    this.setState({ refreshing: true });
    this.getChatGigList();
  };

  onPress = (gigId) => {
    this.props.history.push(CANDIDATE_GIG_CHAT.replace(':id', gigId));
  };

  getChatGigList = () => {
    this.props.getCandidateChatsInfo()
      .then((response) => {
        if (response.isError) {
          this.setState({ error: true });
        }
        this.setState({ refreshing: false });
      });
  };

  render() {
    return (
      <CandidateChatGigListScreen
        chats={this.props.chats}
        usersPresence={this.props.usersPresence}
        onPress={this.onPress}
        error={this.state.error}
        refreshing={this.state.refreshing}
        onRefresh={this.onRefresh}
      />
    );
  }
}

CandidateChatGigListContainer.propTypes = {
  chats: PropTypes.arrayOf(candidateChatGigItemPropType).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  getCandidateChatsInfo: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = (state) => ({
  chats: state.chat.get('candidateChats').toJS(),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, { getCandidateChatsInfo })(CandidateChatGigListContainer);
