import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { getPoolProfile } from '../profile/poolProfileActions';
import {
  colors, fontFamily, fontSizes, shadowBottom,
} from '../../../common/styles/base.style';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import {
  COMPANY_CANDIDATES_POOL_MAIN,
  COMPANY_CANDIDATES_POOL_MY_POOL,
  COMPANY_CANDIDATES_POOL_GROUPS,
  COMPANY_CANDIDATES_POOL_SHIFTS,
  COMPANY_CANDIDATES_POOL_PROFILE_EDIT,
  COMPANY_CANDIDATES_POOL_PROFILE_VIEW,
} from '../../../constants/routes';
import TabBar from '../../../common/components/tabs/TabBar';
import { font } from '../../../common/styles/mixins';
import Routing from '../../../core/routing/index';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../../constants/userScopes';
import CompanyCandidatesPoolMyPoolContainer from './CompanyCandidatesPoolMyPoolContainer';
import CompanyCandidatesPoolGroupsContainer from '../groups/CompanyCandidatesPoolGroupsContainer';
import { COMPANY_POOLS_CONTENT_FIELDS } from '../companyCandidatesPoolReducer';
import CompanyCandidatesPoolShiftsContainer from '../shifts/CompanyCandidatesPoolShiftsContainer';
import Button, { BUTTON_SIZES } from '../../../common/components/buttons/Button';
import { POOL_CHAT_TYPE } from '../../../common/chat/pool/poolChatReducerHelper';

const { withRouter, Route, Redirect } = Routing;

const styles = StyleSheet.create({
  header: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxxl, null, colors.greyishBrown),
  },
});

class CompanyCandidatesPoolMyPoolRouter extends React.PureComponent {
  onShowPoolProfile = () => {
    this.props.getPoolProfile().then((res) => {
      if (!res.isError) {
        const { description, contactInfo: cI } = res.payload;
        if (description == null && (cI == null || (cI.street == null && cI.city == null && cI.phoneNumber == null && cI.email == null && cI.website == null))) {
          this.props.history.push(COMPANY_CANDIDATES_POOL_PROFILE_EDIT);
        } else {
          this.props.history.push(COMPANY_CANDIDATES_POOL_PROFILE_VIEW);
        }
      }
    });
  };

  render() {
    const {
      translate,
      newGroupChatContent,
      newDirectChatContent,
      newMyPoolContent,
      newGroupsContent,
      grantedFeatures,
    } = this.props;

    const tabs = [
      {
        textKey: 'company.candidatesPool.myPoolTab',
        link: COMPANY_CANDIDATES_POOL_MY_POOL,
        isNewContent: newMyPoolContent || newDirectChatContent,
      },
      {
        textKey: 'company.candidatesPool.groupsTab',
        link: COMPANY_CANDIDATES_POOL_GROUPS,
        isNewContent: newGroupsContent || newGroupChatContent,
      },
    ];

    if (grantedFeatures.includes('CANDIDATES_POOL_SHIFTS')) {
      tabs.push({
        textKey: 'company.candidatesPool.shiftsTab',
        link: COMPANY_CANDIDATES_POOL_SHIFTS,
      });
    }

    return (
      <>
        <View style={shadowBottom}>
          <ScreenHeader title={translate('company.candidatesPool.poolCandidatesList.header')} style={styles.header} />
          <View>
            <Button
              title={translate('company.candidatesPool.poolProfile')}
              onPress={() => this.onShowPoolProfile()}
              size={BUTTON_SIZES.SM}
            />
          </View>
          <Route exact path={COMPANY_CANDIDATES_POOL_MAIN} render={() => <Redirect to={COMPANY_CANDIDATES_POOL_MY_POOL} />} />
          <TabBar items={tabs} />
        </View>
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_MY_POOL} component={CompanyCandidatesPoolMyPoolContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_GROUPS} component={CompanyCandidatesPoolGroupsContainer} />
        <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL_SHIFTS} component={CompanyCandidatesPoolShiftsContainer} />
      </>
    );
  }
}

CompanyCandidatesPoolMyPoolRouter.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  getPoolProfile: PropTypes.func.isRequired,
  newGroupChatContent: PropTypes.bool.isRequired,
  newDirectChatContent: PropTypes.bool.isRequired,
  newMyPoolContent: PropTypes.bool.isRequired,
  newGroupsContent: PropTypes.bool.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CompanyCandidatesPoolMyPoolRouter.defaultProps = {
};

const mapStateToProps = (state) => {
  const poolChatsData = Object.values(state.poolChat.get('poolChatsData').toJS());
  const myPoolsContentMap = state.companyCandidatesPool.get('newContent').toJS();
  return ({
    newGroupChatContent: poolChatsData.find((chat) => chat.unread && chat.type === POOL_CHAT_TYPE.GROUP) != null,
    newDirectChatContent: poolChatsData.find((chat) => chat.unread && chat.type === POOL_CHAT_TYPE.DIRECT) != null,
    newMyPoolContent: myPoolsContentMap[COMPANY_POOLS_CONTENT_FIELDS.MY_POOL].length > 0,
    newGroupsContent: myPoolsContentMap[COMPANY_POOLS_CONTENT_FIELDS.GROUPS].length > 0,
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  });
};

export default withRouter(connect(mapStateToProps, { getPoolProfile })(withLocalize(CompanyCandidatesPoolMyPoolRouter)));
