import React from 'react';
import { Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import {
  fontFamily, fontSizes, heightSpacingsBolek,
} from '../../styles/base.style';
import { font } from '../../styles/mixins';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayBold, fontSizes.md),
    paddingTop: heightSpacingsBolek.p40,
    paddingBottom: heightSpacingsBolek.p15,
  },
});
const SectionHeaderBolek = ({ title, style }) => (
  <Text style={[styles.header, style]}>{title}</Text>
);

SectionHeaderBolek.propTypes = {
  title: PropTypes.string.isRequired,
  style: stylePropTypes,
};

SectionHeaderBolek.defaultProps = {
  style: undefined,
};

export default SectionHeaderBolek;
