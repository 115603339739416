import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CreatorStepHeader, { creatorStepHeaderExternalPropTypes } from '../../../common/components/CreatorStepHeader';
import Routing from '../../../core/routing/index';
import { profileCreatorClearAllData } from './profileCreatorActions';
import { colors } from '../../../common/styles/base.style';
import { getMaxGrantedStepsNumber } from './candidateProfileStepsConfig';
import { CANDIDATE_PROFILE } from '../../../constants/routes';

const { withRouter } = Routing;
export const PAGE_AFTER_CLOSE = CANDIDATE_PROFILE;

class ProfileCreatorHeader extends React.PureComponent {
  handleClose = () => {
    this.props.profileCreatorClearAllData();
    this.props.history.replace(PAGE_AFTER_CLOSE);
  };

  render() {
    const { title, ...props } = this.props;
    return (
      <CreatorStepHeader
        headerTranslationKey={title}
        maxSteps={getMaxGrantedStepsNumber(this.props.grantedFeatures)}
        handleClose={this.handleClose}
        marginBottom={0}
        progressColor={colors.seablue}
        {...props}
      />
    );
  }
}

ProfileCreatorHeader.propTypes = {
  ...creatorStepHeaderExternalPropTypes,
  profileCreatorClearAllData: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.candidate.get('profile'),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { profileCreatorClearAllData })(withRouter(ProfileCreatorHeader));
