import { Map } from 'immutable';
import { COMPANY_SAVE_SELECTED_PRICING_PLAN } from '../../constants/actionTypes';

const INITIAL_MAP = {
  selectedPricingPlanId: null,
};

export default function companyPaymentsReducer(state = Map(INITIAL_MAP), action) {
  switch (action.type) {
    case COMPANY_SAVE_SELECTED_PRICING_PLAN: {
      return state.set('selectedPricingPlanId', action.selectedPricingPlanId);
    }
    default:
      return state;
  }
}
