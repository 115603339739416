import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, Text, ScrollView,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import Container from '../../../common/components/Container';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import SettingsSupportSection from '../../../common/settings/SettingsSupportSection';
import { colors, fontSizes, spacings } from '../../../common/styles/base.style';
import { version } from '../../../../package.json';
import ViewportController from '../../../common/components/ViewportController';
import LogsModal from '../../../common/log/LogsModal';

const styles = StyleSheet.create({
  containerWrapper: {
    padding: spacings.md,
  },
  version: {
    position: 'absolute',
    bottom: spacings.xs,
    right: spacings.xs,
    fontSize: fontSizes.xxs,
    color: colors.brownGrey,
  },
});

const AdminProfileSettingsScreen = ({
  translate,
  handleBack,
  onLogout,
  onDelete,
  onCloseLogsModal,
  logsModalVisible,
}) => (
  <ViewportController safeAreaBottom>
    <Container>
      <ScreenHeader
        title={translate('settings.settingsScreenHeader')}
        showBackArrow
        handleBack={handleBack}
      />
      <ScrollView contentContainerStyle={styles.containerWrapper}>
        <SettingsSupportSection onLogout={onLogout} onDelete={onDelete} />
      </ScrollView>
      <Text style={styles.version}>{version}</Text>
      <LogsModal
        visible={logsModalVisible}
        closeModal={onCloseLogsModal}
      />
    </Container>
  </ViewportController>
);

AdminProfileSettingsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCloseLogsModal: PropTypes.func.isRequired,
  logsModalVisible: PropTypes.bool,
};

AdminProfileSettingsScreen.defaultProps = {
  logsModalVisible: false,
};

export default withLocalize(AdminProfileSettingsScreen);
