import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, Text } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import validation from './CandidateProfileAvailabilityFormValidation';
import FormikFieldSelectList from '../../../../common/components/form/FormikSelectList';
import { GIG_SHIFTS, GIG_WORKLOAD, getWorkloadLabel } from '../../../../constants/availabilityTypes';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import {
  spacings, fontFamily, fontSizes, colors,
} from '../../../../common/styles/base.style';
import { candidateProfileAvailabilityPropType } from '../../../../constants/propTypes';
import Wrapper from '../../../../common/components/Wrapper';
import Hr from '../../../../common/components/Hr';

const styles = StyleSheet.create({
  header: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.lg,
    textAlign: 'center',
    color: colors.greyishBrown,
    marginBottom: spacings.md,
  },
  label: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
    marginBottom: spacings.xs,
  },
  subLabel: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.md,
    color: colors.greyishBrown,
    marginBottom: spacings.sm,
  },
  optional: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xs,
    color: colors.seablue,
    marginTop: -spacings.xs,
    marginBottom: spacings.sm,
  },
  btn: {
    marginTop: spacings.md,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
});

class CandidateProfileAvailabilityForm extends Component {
  render() {
    const {
      translate, renderFooter, availability, handleSubmit,
    } = this.props;
    this.t = (key) => translate(`candidateProfileCreator.availability.${key}`);
    const getAvailableItems = () => [
      { value: true, label: this.t('available') },
      { value: false, label: this.t('notAvailable') },
    ];

    return (
      <FormikWithValidation
        validation={validation}
        initialValues={availability}
        onSubmit={handleSubmit}
        render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
          <>
            <ScrollView style={{ paddingTop: spacings.md }}>
              <Wrapper>
                <Text style={styles.label}>{this.t('availableLabel')}</Text>
                <FormikFieldSelectList marginBottom={styles.noMarginBottom} name="available" items={getAvailableItems()} />

                <Hr />

                <Text style={styles.label}>{this.t('hoursLabel')}</Text>
                <FormikFieldSelectList
                  marginBottom={styles.noMarginBottom}
                  name="hours"
                  items={Object.keys(GIG_WORKLOAD).map((key) => ({
                    componentKey: key,
                    value: { min: GIG_WORKLOAD[key].min, max: GIG_WORKLOAD[key].max },
                    label: getWorkloadLabel(GIG_WORKLOAD[key], translate),
                  }))}
                />

                <Hr />

                <Text style={styles.label}>{this.t('timeLabel')}</Text>
                <Text style={styles.optional}>{this.t('multipleChoices')}</Text>
                <FormikFieldSelectList
                  name="times"
                  items={Object.keys(GIG_SHIFTS).map((key) => ({ value: GIG_SHIFTS[key], label: translate(`gigAvailability.${key}`) }))}
                  multiple
                />

              </Wrapper>

            </ScrollView>
            {renderFooter({ handleSubmitFormik, isBtnDisabled })}
          </>

        )}
      />
    );
  }
}

CandidateProfileAvailabilityForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  availability: candidateProfileAvailabilityPropType.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withLocalize(CandidateProfileAvailabilityForm);
