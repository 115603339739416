import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Row, Rows, Table } from 'react-native-table-component';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { spacings, standardColors } from '../../common/styles/base.style';
import Routing from '../../core/routing/index';
import { getAdminCityList, updateAdminCity } from './AdminSettingsActions';
import FormikFieldPrimarySwitch from '../../common/components/form/switch/FormikFieldPrimarySwitch';
import { showAlertMessage } from '../../common/components/alert/alerts';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});

class AdminSettingsCityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHead: ['Order', 'Key', 'Name', 'Enabled'],
      flexArr: [1, 1, 1, 1],
    };
  }

  componentDidMount() {
    this.props.getAdminCityList();
  }

  updateCity(gig, isEnabled) {
    console.log(`gig  ${JSON.stringify(gig)}`);
    this.props
      .updateAdminCity({
        key: gig.key,
        order: gig.order,
        name: gig.translations[0].value,
        long: gig.geoLoc.coordinates[0],
        lat: gig.geoLoc.coordinates[1],
        enabled: isEnabled,
      })
      .then((response) => {
        if (!response.isError) {
          showAlertMessage(this.props.translate, 'generic.ok', 'companyGigCreator.edit.notification.success.text', 'adminSettings.success', () => {});
        } else {
          showAlertMessage(this.props.translate, 'generic.ok', 'adminSettings.errorUpdateCity', 'errors.error', () => {});
        }
      });
  }

  renderLinks = (isEnabled, gig) => {
    const detailsLink = (
      <View
        style={{
          margin: 5,
          justifyContent: 'center',
          alignContent: 'center',
          alignSelf: 'center',
        }}
      >
        <Formik validate={false} onSubmit={this.createCity} initialValues={{ enabled: isEnabled }} innerRef={this.formik}>
          {({ setFieldValue }) => (
            <>
              <FormikFieldPrimarySwitch
                name="enabled"
                onValueChange={(value) => {
                  console.log(`enabled${value}`);
                  setFieldValue('enabled', value);
                  this.updateCity(gig, value);
                }}
                enabled={isEnabled}
              />
            </>
          )}
        </Formik>
      </View>
    );

    return <View>{detailsLink}</View>;
  };

  render() {
    const { citiesList } = this.props;
    const itemsData = citiesList.map((gig) => [gig.order, gig.key, gig.translations[0].value, this.renderLinks(gig.enabled, gig)]);
    const { state } = this;
    return (
      <>
        <View style={styles.container}>
          <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
            <Row data={state.tableHead} style={styles.head} textStyle={styles.text} flexArr={state.flexArr} />
            <Rows data={itemsData} textStyle={styles.text} flexArr={state.flexArr} />
          </Table>
        </View>
      </>
    );
  }
}

AdminSettingsCityList.propTypes = {
  citiesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAdminCityList: PropTypes.func.isRequired,
  updateAdminCity: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const citiesList = state.admin.get('settings').get('adminCities').toJS();
  return {
    citiesList,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAdminCityList,
    updateAdminCity,
  })(withLocalize(AdminSettingsCityList))
);
