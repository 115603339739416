import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Platform, StyleSheet, View } from 'react-native';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import Container from '../../../common/components/Container';
import FormikFieldPrimaryTextInput from '../../../common/components/form/FormikFieldPrimaryTextInput';
import { Button } from '../../../common/components';
import FormikFieldPrimaryPicker from '../../../common/components/form/FormikFieldPrimaryPicker';
import FormikFieldFileInput from '../../../common/components/form/FormikFieldFileInput';
import { spacings } from '../../../common/styles/base.style';
import Banner from '../../../common/components/Banner';
import { postAssociation, resetStatus } from '../AdminAssociationActions';
import { getAvailableMunicipalities } from '../../../common/gig/city/gigCitiesActions';

const AdminNewAssociationContainer = ({ translate }) => {
  const styles = StyleSheet.create({
    wrapper: {
      paddingTop: spacings.lg,
      paddingHorizontal: spacings.xl,
      paddingBottom: spacings.sm,
      backgroundColor: '#FFF',
      marginHorizontal: spacings.xl,
      marginTop: spacings.xl,
      marginBottom: spacings.l,
      borderRadius: 25,
      justifyContent: 'space-between',
    },
    saveButton: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  });

  const dispatch = useDispatch();
  const cities = useSelector((state) => state.gigCities).toJS();
  const isLoading = useSelector((state) => state.admin.get('associations').get('isLoading'));
  const isSuccess = useSelector((state) => state.admin.get('associations').get('isSuccess'));
  const isError = useSelector((state) => state.admin.get('associations').get('isError'));

  const getTranslatedList = (nullable, list) => {
    const categories = list.map((c) => ({
      key: c.key,
      value: c.key,
      label: c.name ?? '',
    }));
    if (Platform.OS !== 'ios' && !list.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  const onSubmit = (values) => {
    dispatch(resetStatus());
    dispatch(postAssociation(values));
  };

  useEffect(() => {
    dispatch(getAvailableMunicipalities());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetStatus());
    },
    []
  );

  return (
    <Container>
      <ScreenHeader showBackArrow title={translate('adminFindAssociationScreen.newAssociation')} />
      {isLoading && <Banner isPending={isLoading} text="Saving..." />}
      {isSuccess && <Banner isSuccess={isSuccess} text="Saved!" />}
      {isError && <Banner isPending={false} isSuccess={false} text="There was an error saving the association" />}
      <View style={styles.wrapper}>
        <Formik
          validate={false}
          initialValues={{
            city: '',
            association: '',
            associationCSV: null,
          }}
          onSubmit={(values) => onSubmit(values)}
          onReset={() => dispatch(resetStatus())}
        >
          {({ handleSubmit, resetForm }) => (
            <>
              <FormikFieldFileInput name="associationCSV" label={translate('adminPanel.csvAssociation')} accept=".csv" />
              <FormikFieldPrimaryPicker
                name="city"
                items={getTranslatedList(true, cities ?? [])}
                label={translate('adminFindAssociationScreen.cityLabel')}
              />
              <FormikFieldPrimaryTextInput
                name="association"
                label={translate('adminFindAssociationScreen.associationName')}
                placeholder={translate('adminFindAssociationScreen.associationName')}
              />
              <View style={styles.saveButton}>
                <Button size="md" title={translate('adminFindAssociationScreen.reset')} onPress={resetForm} />
                <Button size="md" title={translate('adminFindAssociationScreen.save')} onPress={handleSubmit} />
              </View>
            </>
          )}
        </Formik>
      </View>
    </Container>
  );
};

AdminNewAssociationContainer.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withLocalize(AdminNewAssociationContainer);
