import queryString from 'query-string';

import {
  ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST,
  ASSOCIATIONS_RESET_STATUS
} from '../constants/actionTypes';

export function getAssociations(city) {
  return {
    type: ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/public/gig/association',
        params: {
          city
        },
        paramsSerializer: (p) => queryString.stringify(p),
      },
    },
  };
}

export function associationResetStatus() {
  return {
    type: ASSOCIATIONS_RESET_STATUS,
    payload: {},
  };
}
