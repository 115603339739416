import moment from 'moment';
import { STORE_LOG } from '../../constants/actionTypes';

export function log(msg) {
  return {
    type: STORE_LOG,
    log: {
      msg: JSON.stringify(msg),
      ts: moment().valueOf(),
    },
  };
}
