import React from 'react';
import PropTypes from 'prop-types';
import EditModalFooter from '../../../../common/components/modal/EditModalFooter';

const CandidateProfileEditDetailsFormWithFooter = ({ component: Component, closeModal, ...props }) => (
  <Component
    {...props}
    renderFooter={({ handleSubmitFormik, isBtnDisabled }) => (
      <EditModalFooter
        handleSubmitFormik={handleSubmitFormik}
        isBtnDisabled={isBtnDisabled}
        handleCancel={closeModal}
      />
    )}
  />
);

CandidateProfileEditDetailsFormWithFooter.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default CandidateProfileEditDetailsFormWithFooter;
