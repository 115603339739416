import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../../../common/components/index';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import { languagePropType } from '../../../../../constants/propTypes';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';
import LanguagesForm from '../../../../../common/languages/LanguagesForm';
import Container from '../../../../../common/components/Container';

const ProfileCreatorStepLanguagesScreen = ({
  translate, selectedLanguages, handleSubmit, stepNumber, nextScreen, redirect, handleAddLanguage, handleRemoveLanguage, handleUpdateLanguage,
}) => (
  <Container>
    <ProfileCreatorHeader
      stepNo={stepNumber}
      title={translate('candidateProfileCreator.languages.header')}
      infoText={translate('candidateProfileCreator.languages.subheader')}
    />
    <LanguagesForm
      languages={selectedLanguages}
      handleAddLanguage={handleAddLanguage}
      handleRemoveLanguage={handleRemoveLanguage}
      handleUpdateLanguage={handleUpdateLanguage}
      formDescription={translate('candidateProfile.languages.label')}
      btnTitle={translate('candidateProfile.languages.addLanguageBtn')}
    />
    <StickyButtonsWrapper>
      <LinkButton
        to={nextScreen}
        title={translate('generic.next')}
        doRedirect={redirect}
        disabled={selectedLanguages.length === 0}
        onPress={() => handleSubmit(selectedLanguages)}
      />
    </StickyButtonsWrapper>
  </Container>
);

ProfileCreatorStepLanguagesScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  selectedLanguages: PropTypes.arrayOf(languagePropType).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleAddLanguage: PropTypes.func.isRequired,
  handleRemoveLanguage: PropTypes.func.isRequired,
  handleUpdateLanguage: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
};

export default withLocalize(ProfileCreatorStepLanguagesScreen);
