import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { stylePropTypes } from '../../constants/propTypes';

const arrow = require('../../assets/icons/arrows/iconsArrowLeftMagenta.png');

const styles = StyleSheet.create({
  icon: {
    width: 9,
    height: 14,
    transform: [{ rotateY: '180deg' }],
  },
});

/**
 * @deprecated
 */
const RightArrow = ({ additionalStyles }) => (
  <Image style={[styles.icon].concat(additionalStyles)} source={arrow} />
);

RightArrow.propTypes = {
  additionalStyles: stylePropTypes,
};

RightArrow.defaultProps = {
  additionalStyles: undefined,
};

export default RightArrow;
