import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  View, Text, StyleSheet,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';

import Routing from '../../../core/routing/index';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import {
  colors, fontFamily, fontSizes, spacings, wp,
} from '../../../common/styles/base.style';
import { shiftPropType } from '../../../constants/propTypes';
import BaseListItem from '../../../common/components/BaseListItem';
import { candidateClearMyPoolsNewContent, storeShiftsScrollPosition } from '../candidateMyPoolsActions';
import { CANDIDATE_SHIFT_DETAILS } from '../../../constants/routes';
import UserProfileImage from '../../../common/components/image/UserProfileImage';
import { toModernDateWithWeekDayName, toTime } from '../../../utilities/dateFormatter';
import { font } from '../../../common/styles/mixins';
import { calculateShiftTotalHours, calculateShiftTotalIncome } from '../../../common/gig/shiftSalaryUtils';
import IconEntry from '../../../common/components/IconEntry';
import PrimaryTextBolek, { TYPES } from '../../../common/components/PrimaryTextBolek';
import { CANDIDATES_POOLS_CONTENT_FIELDS } from '../candidateMyPoolsReducer';

const { withRouter } = Routing;

const calendarIcon = require('../../../assets/icons/calendar/calendar.png');
const candidateIcon = require('../../../assets/icons/person/person.png');
const locationIcon = require('../../../assets/icons/location/location.png');
const clockIcon = require('../../../assets/icons/clock/clock.png');
const moneyIcon = require('../../../assets/icons/money/money.png');

const styles = StyleSheet.create({
  listItemContainer: {
    flexDirection: 'column',
  },
  highlighted: {
    borderColor: colors.seablue,
    borderWidth: 2,
  },
  listItemHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  shiftLabelContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingRight: spacings.sm,
  },
  positionText: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm, null, colors.greyishBrown),
  },
  companyNameText: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm, null, colors.brownGrey),
  },
  profileImage: {
    width: 54,
    height: 54,
    borderRadius: 27,
    marginRight: spacings.sm,
  },
  conditionsWrapper: {
    flexDirection: 'column',
    borderTopWidth: 1,
    borderTopColor: colors.veryLightGrey,
    paddingTop: spacings.xs,
    marginTop: spacings.sm,
    paddingLeft: spacings.xs,
  },
  conditionRow: {
    marginTop: spacings.xs,
  },
  conditionText: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs),
  },
  datesAndTimesSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

class CandidateMyPoolsAvailableShiftsScreen extends Component {
  componentDidMount() {
    this.scrollPosition = 0;
    if (this.props.history.action === 'POP') {
      const { storedScrollPosition } = this.props;
      if (storedScrollPosition) {
        this.scrollToLastKnownPosition(storedScrollPosition);
      }
    } else {
      this.props.storeShiftsScrollPosition(this.scrollPosition);
    }
  }

  componentWillUnmount() {
    this.props.storeShiftsScrollPosition(this.scrollPosition);
    this.props.candidateClearMyPoolsNewContent(CANDIDATES_POOLS_CONTENT_FIELDS.SHIFTS);
  }

  showAvailableShiftDetails = (gigId) => {
    this.props.history.push(CANDIDATE_SHIFT_DETAILS.replace(':id', gigId));
  };

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  translateByKey = (key, params) => this.props.translate(`candidate.myPools.shifts.${key}`, params);

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToOffset({ offset: scrollPosition, animated: true }), 0);
  }

  renderShiftHeaderInfo = (shift) => (
    <View style={styles.listItemHeaderContainer}>
      <UserProfileImage profileImageUri={shift.companyUser.profileImageUri} style={styles.profileImage} />
      <View style={styles.shiftLabelContainer}>
        <Text style={styles.positionText}>{shift.position}</Text>
        <Text style={styles.companyNameText}>{`${this.props.translate('generic.at')} ${shift.company.name}`}</Text>
      </View>
    </View>
  );

  renderShiftDatesAndTimes = (shiftDatesAndTimes) => (
    <IconEntry icon={calendarIcon} style={styles.conditionRow}>
      <View style={{ flexDirection: 'column' }}>
        {shiftDatesAndTimes.map((item) => (
          <View style={styles.datesAndTimesSection} key={item.id}>
            <View style={{ width: wp(22) }}>
              <PrimaryTextBolek type={TYPES.SECONDARY}>
                {toModernDateWithWeekDayName(item.startDate)}
              </PrimaryTextBolek>
            </View>
            <PrimaryTextBolek type={TYPES.SECONDARY}>
              {`${toTime(item.startDate)} - ${toTime(item.endDate)}`}
            </PrimaryTextBolek>
          </View>
        ))}
      </View>
    </IconEntry>
  );

  renderShiftTotalDuration = (shiftDatesAndTimes) => (
    <IconEntry icon={clockIcon} style={styles.conditionRow}>
      <PrimaryTextBolek type={TYPES.SECONDARY}>
        {`${calculateShiftTotalHours(shiftDatesAndTimes)} ${this.translateByKey('hours')}`}
      </PrimaryTextBolek>
    </IconEntry>
  );

  renderSalaryInfo = (shiftSalary, shiftDatesAndTimes) => (
    <IconEntry icon={moneyIcon} style={styles.conditionRow}>
      <PrimaryTextBolek type={TYPES.SECONDARY}>
        {this.translateByKey('totalIncome', {
          amount: calculateShiftTotalIncome(shiftDatesAndTimes, shiftSalary),
          obPayment: shiftSalary.obPayment.applicable ? this.translateByKey('OBPayment') : '',
        })}
      </PrimaryTextBolek>
    </IconEntry>
  );

  renderCandidatesNumberInfo = (candidatesNumber, bookedCandidatesNumber) => {
    const availableVacancies = +candidatesNumber - +bookedCandidatesNumber;
    return (
      <IconEntry icon={candidateIcon} style={styles.conditionRow}>
        <PrimaryTextBolek type={TYPES.SECONDARY}>
          {`${availableVacancies} ${this.translateByKey(availableVacancies === 1 ? 'candidateKey' : 'candidatesKey')}`}
        </PrimaryTextBolek>
      </IconEntry>
    );
  };

  renderLocationInfo = (location) => (
    <IconEntry icon={locationIcon} style={styles.conditionRow}>
      <PrimaryTextBolek type={TYPES.SECONDARY}>
        {`${location.street}, ${location.place}`}
      </PrimaryTextBolek>
    </IconEntry>
  );

  render() {
    const { shifts, newContentList } = this.props;

    return (
      <PrimaryFlatList
        emptyListTextKey={this.translateByKey('availableEmptyList')}
        data={shifts}
        keyExtractor={(shift) => shift.gigId}
        innerRef={this.setListRef}
        onScroll={this.onScroll}
        renderItem={({ item: shift }) => (
          <BaseListItem
            renderMainContent={() => (
              <View style={styles.listItemContainer}>
                {this.renderShiftHeaderInfo(shift)}
                <View style={styles.conditionsWrapper}>
                  {this.renderShiftDatesAndTimes(shift.shiftDatesAndTimes)}
                  {this.renderShiftTotalDuration(shift.shiftDatesAndTimes)}
                  {this.renderSalaryInfo(shift.shiftSalary, shift.shiftDatesAndTimes)}
                  {this.renderCandidatesNumberInfo(shift.candidatesNumber, shift.bookedCandidatesNumber)}
                  {this.renderLocationInfo(shift.location)}
                </View>
              </View>
            )}
            onPress={() => this.showAvailableShiftDetails(shift.gigId)}
            style={newContentList.includes(shift.gigId) ? styles.highlighted : null}
          />
        )}
      />
    );
  }
}

CandidateMyPoolsAvailableShiftsScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(shiftPropType).isRequired,
  newContentList: PropTypes.arrayOf(PropTypes.string),
  storeShiftsScrollPosition: PropTypes.func.isRequired,
  candidateClearMyPoolsNewContent: PropTypes.func.isRequired,
  storedScrollPosition: PropTypes.number.isRequired,
};

CandidateMyPoolsAvailableShiftsScreen.defaultProps = {
  newContentList: [],
};

const mapStateToProps = (state) => ({
  storedScrollPosition: state.candidateMyPools.get('shiftsScrollPosition'),
  newContentList: state.candidateMyPools.get('newContent').get(CANDIDATES_POOLS_CONTENT_FIELDS.SHIFTS).toJS(),
});

export default connect(mapStateToProps, {
  storeShiftsScrollPosition, candidateClearMyPoolsNewContent,
})(withRouter(withLocalize(CandidateMyPoolsAvailableShiftsScreen)));
