import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import FormValidator from '../../../core/validation/FormValidator';
import { ERROR_KEY_FATAL } from '../../../core/client/axiosRequestsMiddleware';
import { validationSchemaPropTypes } from '../../../constants/propTypes';

class FormikWithValidation extends Component {
  static extendRender(formRender) {
    return (props) => {
      const isBtnDisabled = FormikWithValidation.shouldBtnBeDisabled(props);
      return formRender({ ...props, ...{ isBtnDisabled } });
    };
  }

  /*
  We want to disable the button when:
  -submitting the form
  -it's invalid and there's no fatal error (if sth goes wrong on the server, we want to enable user to click once again
   */
  static shouldBtnBeDisabled(props) {
    return props.isSubmitting || (!props.isValid && props.errors[ERROR_KEY_FATAL] === undefined);
  }

  constructor(props) {
    super(props);
    this.formik = React.createRef();
    if (props.validation) {
      this.validator = new FormValidator(props.validation);
    }
  }

  validate = (values) => this.validator.validate(values);

  render() {
    const { validate, render, ...props } = this.props;
    return (
      <Formik
        innerRef={this.formik}
        validate={validate || this.validate}
        validateOnMount
        {...props}
      >
        {FormikWithValidation.extendRender(render)}
      </Formik>
    );
  }
}

FormikWithValidation.propTypes = {
  validation: validationSchemaPropTypes,
  validate: PropTypes.func,
  render: PropTypes.func.isRequired,
};

FormikWithValidation.defaultProps = {
  validation: [],
  validate: null,
};

export default FormikWithValidation;
