import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import EditableField from '../../../../common/components/EditableField';
import { candidateProfileSetAvailability } from '../../CandidateProfileActions';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import CandidateProfileAvailabilityForm from '../../forms/availability/CandidateProfileAvailabilityForm';
import { candidateProfileAvailabilityPropType } from '../../../../constants/propTypes';
import { getWorkloadLabel, getShiftKey } from '../../../../constants/availabilityTypes';

class EditableFieldAvailabilityModalAware extends React.Component {
  static getTimesValues(translate, availability) {
    return availability.times.length > 0 ? `\n${availability.times.map((t) => `${translate(`gigAvailability.${getShiftKey(t)}`)}`).join(', ')}` : '';
  }

  static getHoursValue(translate, availability) {
    return availability.hours ? `\n${getWorkloadLabel(availability.hours, translate, true)}` : '';
  }

  getAvailableValue(availability) {
    return `${availability.available ? this.t('availableYes') : this.t('availableNo')}`;
  }

  getFieldValue(translate, availability) {
    return this.getAvailableValue(availability)
      + EditableFieldAvailabilityModalAware.getTimesValues(translate, availability)
      + EditableFieldAvailabilityModalAware.getHoursValue(translate, availability);
  }

  t = (key) => this.props.translate(`candidateProfile.details.${key}`);

  openModal = () => {
    const {
      closeModal, availability,
    } = this.props;

    this.props.setModalContent(
      this.t('availabilityLabel'),

      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileAvailabilityForm}
        closeModal={closeModal}
        availability={availability}
        handleSubmit={(values) => {
          this.props.candidateProfileSetAvailability(values)
            .then((response) => {
              if (!response.isError) {
                closeModal();
              }
            });
        }}
      />,
    );
  };

  render() {
    const { translate, availability } = this.props;
    return (
      <EditableField
        label={this.t('availabilityLabel')}
        value={this.getFieldValue(translate, availability)}
        onPress={this.openModal}
        showWarning={!availability.hours || !availability.times || availability.times.length === 0}
      />
    );
  }
}

EditableFieldAvailabilityModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateProfileSetAvailability: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  availability: candidateProfileAvailabilityPropType.isRequired,
};

const mapStateToProps = (state) => {
  const {
    available, times, hours,
  } = state.candidate.get('profile').toJS();

  return ({
    availability: {
      available,
      times,
      hours,
    },
  });
};

export default connect(mapStateToProps, { candidateProfileSetAvailability })(withLocalize(EditableFieldAvailabilityModalAware));
