import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Touchable from './Touchable';
import { spacings } from '../styles/base.style';
import Arrow, { ARROW } from './Arrow';

const BTN_HIT_SLOP_VERTICAL = 0;
const BTN_HIT_SLOP_HORIZONTAL = 30;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: spacings.md,
  },
  contentWrapper: {
    flex: 1,
    marginRight: 10,
  },
  iconWrapper: {
    marginLeft: 10,
    alignItems: 'center',
  },
});

const ClickableListItem = ({
  renderContent, onPress, style, arrow,
}) => (
  <Touchable
    style={[styles.container, style.container]}
    onPress={onPress}
    hitSlop={{
      top: BTN_HIT_SLOP_VERTICAL, bottom: BTN_HIT_SLOP_VERTICAL, left: BTN_HIT_SLOP_HORIZONTAL, right: BTN_HIT_SLOP_HORIZONTAL,
    }}
  >
    <View style={styles.contentWrapper}>
      {renderContent()}
    </View>
    <View style={[styles.iconWrapper, style.iconWrapper]}>
      <Arrow color={arrow.color} direction={arrow.direction} />
    </View>
  </Touchable>
);

ClickableListItem.propTypes = {
  renderContent: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.shape({
    container: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    contentWrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    iconWrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  }),
  arrow: PropTypes.shape({
    color: PropTypes.string,
    direction: PropTypes.string,
  }),
};

ClickableListItem.defaultProps = {
  style: {
    container: null,
    contentWrapper: null,
    iconWrapper: null,
  },
  arrow: {
    color: ARROW.COLOR.MAGENTA,
    direction: ARROW.DIRECTION.RIGHT,
  },
};

export default ClickableListItem;
