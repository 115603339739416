import React, { Component } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { Row, Rows, Table } from 'react-native-table-component';
import PropTypes from 'prop-types';
import {
  fontSizes, spacings, standardColors,
} from '../../../common/styles/base.style';
import Routing from '../../../core/routing/index';
import ExternalLink from '../../../common/components/buttons/ExternalLink';
import { LinkButton } from '../../../common/components';
import { ADMIN_GIG_DETAILS } from '../../../constants/routes';
import { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
    marginBottom: spacings.md,
  },

  container: {
    flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff',
  },
  head: { height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
});

class AdminFindCompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHead: ['Company', 'E-mail', 'Tel No.', 'Registration No.', 'Association'],
      flexArr: [2, 2, 2, 2, 2],
    };
  }

  renderLinks = (gig) => {
    const externalLink = gig.applyUrl ? (
      <ExternalLink
        linkUrl={gig.applyUrl}
        title="Link"
        style={{ text: { fontSize: fontSizes.md }, wrapper: { marginBottom: 0 } }}
        newTab
      />
    ) : null;

    const detailsLink = (
      <LinkButton
        title="Details"
        to={ADMIN_GIG_DETAILS}
        replacePathValue={gig.gigId}
        type={BUTTON_TYPES.TEXT}
        size={BUTTON_SIZES.MD}
        style={{ wrapper: { marginBottom: 0, alignSelf: 'flex-start' } }}
      />
    );

    return (
      <View>
        {externalLink}
        {detailsLink}
      </View>
    );
  };

  render() {
    const { companyList } = this.props;
    const itemsData = companyList.map((g) => [
      g.companyName,
      g.email,
      g.telNo,
      g.registrationNumber,
      g.association,
    ]);
    const { state } = this;
    return (
      <>
        <View style={styles.container}>
          <Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
            <Row data={state.tableHead} style={styles.head} textStyle={styles.text} flexArr={state.flexArr} />
            <Rows data={itemsData} textStyle={styles.text} flexArr={state.flexArr} />
          </Table>
        </View>
      </>

    );
  }
}

AdminFindCompanyList.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  companyList: state.admin.get('companies').get('adminCompanyList').toJS(),
});

export default withRouter(connect(mapStateToProps, {
})(withLocalize(AdminFindCompanyList)));
