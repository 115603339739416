import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import ListItem from '../../../../common/components/ListItem';
import GigListItem from '../../my_gigs/GigListItem';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { candidateGetFavouriteGigs, EXPIRED_GIG_VISIBILITY_THRESHOLD } from '../candidateFindGigsActions';
import FIND_GIGS_SINGLE_ITEM_DEFINITIONS from './CandidateFindGigsSingleItemDefinitions';
import { GIG_DETAILS_ORIGIN } from '../../../../constants/gigDetailsOrigin';
import { CLOSED } from '../../../../constants/gigStatus';

class CandidateFindGigsSingleFavourites extends React.Component {
  componentDidMount() {
    this.props.candidateGetFavouriteGigs(moment.utc().subtract(EXPIRED_GIG_VISIBILITY_THRESHOLD, 'days').toDate());
  }

  render() {
    return (
      <PrimaryFlatList
        emptyListTextKey="candidateFindGigs.single.noFavourite"
        data={this.props.gigsFavourite}
        keyExtractor={(item) => item.gigId}
        renderItem={({ item: gig }) => (
          <ListItem
            renderContent={() => (
              <GigListItem
                greyOutClosedGigs
                gig={gig}
                itemDefinition={gig.status === CLOSED ? FIND_GIGS_SINGLE_ITEM_DEFINITIONS.favouriteClosed : FIND_GIGS_SINGLE_ITEM_DEFINITIONS.favourite}
                origin={GIG_DETAILS_ORIGIN.FAVOURITES}
              />
            )}
          />
        )}
      />
    );
  }
}

CandidateFindGigsSingleFavourites.propTypes = {
  candidateGetFavouriteGigs: PropTypes.func.isRequired,
  gigsFavourite: PropTypes.arrayOf(matchedGigForCandidatePropType),
};

CandidateFindGigsSingleFavourites.defaultProps = {
  gigsFavourite: null,
};

const mapStateToProps = (state) => ({
  gigsFavourite: state.candidateFindGigs.get('gigsFavourite') ? state.candidateFindGigs.get('gigsFavourite').toJS() : null,
});

export default connect(mapStateToProps, { candidateGetFavouriteGigs })(CandidateFindGigsSingleFavourites);
