import React, { Component } from 'react';
import { ActivityIndicator, Platform, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import queryString from 'query-string';

import { goTo } from '../../common/history/historyUtil';
import { getGig, getSimilarGigs } from './candidateGigActions';
import ScreenHeader, { RIGHT_BUTTONS } from '../../common/components/header/ScreenHeader';
import Container from '../../common/components/Container';
import CandidateGigDetailsActionsContainer from './find_gigs/gigDetailsActions/CandidateGigDetailsActionsContainer';
import GigDetails from '../../common/gig/GigDetails';
import { CLOSED } from '../../constants/gigStatus';
import { candidateProfilePropType } from '../../constants/propTypes';
import getProfile from '../profile/CandidateProfileActions';
import { CANDIDATE_FIND_GIGS, redirectToErrorScreen } from '../../constants/routes';
import { GIG_CANDIDATE_STATUSES_WITH_CHAT_AVAILABLE } from '../../constants/matchingStatus';
import { isCompletedOrRequiredCompleted } from '../../constants/profileCompletenessStatus';
import { shareGig } from '../../common/share/share';
import ViewportController from '../../common/components/ViewportController';
import { PROVIDER_ENUM } from '../../constants/providerTypes';
import {
  activityIndicator, fontFamily, fontSizes,
} from '../../common/styles/base.style';
import { font } from '../../common/styles/mixins';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';
import { candidateClearSimilarGigs } from './candidateSimilarGigsActions';
import { GIG_DETAILS_ORIGIN } from '../../constants/gigDetailsOrigin';
import {
  addFavouriteGig,
  addNotInterestingGig,
  candidateFindGigsSetCurrentIndex,
  removeFavouriteGigFromList,
  removeNotInterestingGigFromList,
} from './find_gigs/candidateFindGigsActions';

const externalGigDetailsStyling = StyleSheet.create({
  position: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxl),
  },
  scrollViewContainer: {
    paddingBottom: 67,
  },
});

class CandidateGigDetailsContainer extends Component {
  constructor(props) {
    super(props);

    const isFavourite = this.parseOrigin() === GIG_DETAILS_ORIGIN.FAVOURITES;
    const isNotInteresting = this.parseOrigin() === GIG_DETAILS_ORIGIN.NOT_INTERESTING;
    const showFavouritesButtons = [GIG_DETAILS_ORIGIN.FIND_GIGS_SINGLE, GIG_DETAILS_ORIGIN.FAVOURITES, GIG_DETAILS_ORIGIN.NOT_INTERESTING]
      .includes(this.parseOrigin());

    this.state = {
      loadingGig: true,
      gig: undefined,
      similarGigs: null,
      showGigActions: true,
      isFavourite,
      isNotInteresting,
      showFavouritesButtons,
    };
  }

  componentDidMount() {
    const { id: gigId } = this.props.match.params;
    googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.VIEW_DETAILS);
    this.fetchData(gigId);

    if (this.props.profile.available == null) {
      this.props.getProfile();
    }
  }

  componentDidUpdate(prevProps) {
    const { id: gigId } = this.props.match.params;

    if (gigId !== prevProps.match.params.id) {
      this.fetchData(this.props.match.params.id);
    }
  }

  isChatAvailable = () => GIG_CANDIDATE_STATUSES_WITH_CHAT_AVAILABLE.includes(this.state.gig.matchingStatus);

  openActionSheet = () => {
    this.setState({ showGigActions: false });
    const { translate } = this.props;
    const options = [translate('generic.share'), translate('generic.cancel')];
    const cancelButtonIndex = 1;

    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        showSeparators: true,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          await shareGig(this.state.gig.shortId);
        }
        setTimeout(() => this.setState({ showGigActions: true }), 150);
      },
    );
  };

  parseOrigin = () => queryString.parse(this.props.location.search).origin

  handleBack = () => {
    if (this.isGigPreviewInMyGigsContext()) {
      this.props.candidateClearSimilarGigs();
      this.props.history.goBack();
    } else {
      this.props.history.goBack();
    }
  };

  applyDecision = (like) => {
    const { id: gigId } = this.props.match.params;
    const { currentIndex } = this.props;

    if (this.parseOrigin() === GIG_DETAILS_ORIGIN.FAVOURITES || this.parseOrigin() === GIG_DETAILS_ORIGIN.NOT_INTERESTING) {
      if (like) {
        this.props.addFavouriteGig(gigId);
        this.props.removeNotInterestingGigFromList(gigId);
      } else if (!like) {
        this.props.addNotInterestingGig(gigId);
        this.props.removeFavouriteGigFromList(gigId);
      }
    } else if (this.parseOrigin() === GIG_DETAILS_ORIGIN.FIND_GIGS_SINGLE) {
      if (like) {
        this.props.addFavouriteGig(gigId);
        if (this.state.isNotInteresting) {
          this.props.removeNotInterestingGigFromList(gigId);
        } else {
          this.props.candidateFindGigsSetCurrentIndex(currentIndex + 1);
        }
      } else {
        this.props.addNotInterestingGig(gigId);
        if (this.state.isFavourite) {
          this.props.removeFavouriteGigFromList(gigId);
        } else {
          this.props.candidateFindGigsSetCurrentIndex(currentIndex + 1);
        }
        setTimeout(() => {
          this.props.history.goBack();
        }, 800);
      }
    }
  }

  handleLike = () => {
    this.applyDecision(true);
    this.setState({ isFavourite: true, isNotInteresting: false });
  }

  handleReject = () => {
    this.applyDecision(false);
    this.setState({ isFavourite: false, isNotInteresting: true });
  }

  handleOnRewind = () => {
    this.rewindToFindGigs();
  };

  fetchData(gigId) {
    this.setState({ loadingGig: true });
    const { origin } = queryString.parse(this.props.location.search);
    this.props.getGig(gigId, origin)
      .then((response) => {
        if (!response.isError) {
          const gig = response.payload;
          this.setState({ gig, loadingGig: false });
          this.props.getSimilarGigs({ gigId: gig.gigId }, this.props.lastSeenSimilarGigs).then((res) => {
            this.setState({ similarGigs: res.payload });
          });
        } else if (response && response.status === 404) {
          redirectToErrorScreen(this.props.history, 'errors.candidateGigUnavailableTitle');
          this.setState({ loadingGig: false });
        } else {
          redirectToErrorScreen(this.props.history, 'errors.candidateGigCurrentlyUnavailableTitle');
          this.setState({ loadingGig: false });
        }
      });
  }

  isGigPreviewInMyGigsContext() {
    if (Platform.OS === 'web') {
      return true;
    }

    return queryString.parse(this.props.location.search).myGigs === 'true';
  }

  rewindToFindGigs() {
    goTo(this.props.history, CANDIDATE_FIND_GIGS);
  }

  isCareerUrlApplicable() {
    return this.props.grantedFeatures.includes('CAREER_URL') && !!this.state.gig.careerUrl;
  }

  hasActionButton() {
    return this.isCareerUrlApplicable() || !this.state.gig.matchingStatus;
  }

  render() {
    const {
      gig, showGigActions, loadingGig, similarGigs, isFavourite, isNotInteresting, showFavouritesButtons,
    } = this.state;
    const { lastSeenSimilarGigs, profile: { available, profileCompleteness } } = this.props;

    if (loadingGig) {
      return (
        <Container>
          <ActivityIndicator size="large" style={{ ...activityIndicator }} />
        </Container>
      );
    }

    return (
      <ViewportController>
        <Container>
          <ScreenHeader
            title={this.props.translate('gigPreview.header')}
            showBackArrow
            handleBack={this.handleBack}
            onRewind={lastSeenSimilarGigs && lastSeenSimilarGigs.length > 3 ? this.handleOnRewind : null}
            rightButton={RIGHT_BUTTONS.MENU}
            handlerForRightButton={this.openActionSheet}
            shadow
          />
          {gig && (
            <GigDetails
              gig={gig}
              similarGigs={similarGigs}
              renderChatButton={this.isChatAvailable()}
              renderStatusLabel={!this.isGigPreviewInMyGigsContext()}
              showConditions={gig.gigProvider === PROVIDER_ENUM.LETSGIG}
              isFavourite={isFavourite}
              isNotInteresting={isNotInteresting}
              translationKey="candidate.gigDetails"
              style={!gig.isApplicable ? externalGigDetailsStyling : { scrollViewContainer: { paddingBottom: this.hasActionButton() ? 67 : 0 } }}
              showFavouritesButtons={showFavouritesButtons}
              like={this.handleLike}
              reject={this.handleReject}
            />
          )}
          {gig && gig.status !== CLOSED && (!this.isGigPreviewInMyGigsContext() || this.isCareerUrlApplicable()) && showGigActions && (
            <CandidateGigDetailsActionsContainer
              isCandidateAvailable={available}
              gig={gig}
              profileRequiredCompleted={isCompletedOrRequiredCompleted(profileCompleteness)}
              updateGigDataOnPreviewScreen={(gigId, status) => {
                this.setState({ gig: { ...gig, matchingStatus: status } });
              }}
            />
          )}
        </Container>
      </ViewportController>
    );
  }
}

CandidateGigDetailsContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  showActionSheetWithOptions: PropTypes.func.isRequired,
  getGig: PropTypes.func.isRequired,
  getSimilarGigs: PropTypes.func.isRequired,
  candidateClearSimilarGigs: PropTypes.func.isRequired,
  lastSeenSimilarGigs: PropTypes.arrayOf(PropTypes.string).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  getProfile: PropTypes.func.isRequired,
  profile: candidateProfilePropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  addFavouriteGig: PropTypes.func.isRequired,
  addNotInterestingGig: PropTypes.func.isRequired,
  removeFavouriteGigFromList: PropTypes.func.isRequired,
  removeNotInterestingGigFromList: PropTypes.func.isRequired,
  candidateFindGigsSetCurrentIndex: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.candidate.get('profile').toJS(),
  lastSeenSimilarGigs: state.candidateSimilarGigs.get('lastSeenSimilarGigs').toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  currentIndex: state.candidateFindGigs.get('currentIndex'),
  gigs: state.candidateFindGigs.get('gigs').toJS(),
});

export default connect(mapStateToProps, {
  getGig,
  candidateClearSimilarGigs,
  getSimilarGigs,
  getProfile,
  candidateFindGigsSetCurrentIndex,
  addFavouriteGig,
  addNotInterestingGig,
  removeNotInterestingGigFromList,
  removeFavouriteGigFromList,
})(withLocalize(connectActionSheet(CandidateGigDetailsContainer)));
