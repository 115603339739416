import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gigCreatorAddQuestionnaire } from '../../gigCreatorActions';
import GigCreatorStepQuestionnaireScreen from './GigCreatorStepQuestionnaireScreen';
import { questionnairePropType, withGigEditPropTypes } from '../../../../constants/propTypes';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepQuestionnaireContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: props.questionnaire || [],
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.QUESTIONNAIRE));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.QUESTIONNAIRE);
  }

  handleAddQuestion = () => {
    const newQuestion = {
      text: '',
      type: 'OPEN',
      questionId: `${Math.random()}`,
    };
    this.setState({ questionnaire: this.state.questionnaire.concat(newQuestion) });
  };

  handleRemoveQuestion = (oldQuestionId) => {
    this.setState({ questionnaire: this.state.questionnaire.filter((question) => question.questionId !== oldQuestionId) });
  };

  handleUpdateQuestion = (questionItem, text) => {
    const currentQuestionnaireItems = this.state.questionnaire;
    for (let i = 0; i < currentQuestionnaireItems.length; i += 1) {
      if (currentQuestionnaireItems[i].questionId === questionItem.questionId) {
        currentQuestionnaireItems[i].text = text;
        this.setState({ questionnaire: currentQuestionnaireItems });
        break;
      }
    }
  };

  handleSubmit = () => {
    this.props.gigCreatorAddQuestionnaire(this.state.questionnaire);

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.QUESTIONNAIRE, { questionnaire: this.state.questionnaire }).then(() => this.props.history.goBack());
    } else {
      this.setState({ redirect: true });
    }
  };

  handleSkip = () => {
    this.props.gigCreatorAddQuestionnaire([]);
    this.setState({ questionnaire: [], redirect: true });
  };

  render = () => (
    <GigCreatorStepQuestionnaireScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      questionnaire={this.state.questionnaire}
      handleSubmit={this.handleSubmit}
      handleSkip={this.handleSkip}
      handleAddQuestion={this.handleAddQuestion}
      handleRemoveQuestion={this.handleRemoveQuestion}
      handleUpdateQuestion={this.handleUpdateQuestion}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  )
}

GigCreatorStepQuestionnaireContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  gigCreatorAddQuestionnaire: PropTypes.func.isRequired,
  questionnaire: PropTypes.arrayOf(questionnairePropType),
  ...withGigEditPropTypes,
};

GigCreatorStepQuestionnaireContainer.defaultProps = {
  questionnaire: [],
};

const mapStateToProps = (state) => {
  const {
    gig: {
      questionnaire,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { questionnaire, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddQuestionnaire })(withLocalize(GigCreatorStepQuestionnaireContainer)));
