export default function stringTruncate(string, length) {
  if (!string) {
    return '';
  }

  if (string.length > length) {
    return `${string.slice(0, length)} ...`;
  }

  return string;
}
