import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import GigCategorySelectListContainer from '../../../../../common/gig/GigCategorySelectListContainer';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import validation from '../../../forms/category/CandidateProfileCategoryFormValidation';
import { LinkButton } from '../../../../../common/components';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';
import FormikWithValidation from '../../../../../common/components/form/FormikWithValidation';
import { candidateCategoryValidationParams } from '../../../../../constants/propTypes';
import { spacings } from '../../../../../common/styles/base.style';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  loadingIcon: {
    marginTop: spacings.lg,
    justifyContent: 'center',
  },
});

function getInfoText(translate, candidateCategoriesValidationParams) {
  if (candidateCategoriesValidationParams && candidateCategoriesValidationParams.max) {
    return translate('candidateProfileCreator.category.textWithMaxSelectLimit', { maxSelectLimit: candidateCategoriesValidationParams.max });
  }
  return translate('candidateProfileCreator.category.text');
}

const ProfileCreatorStepCategoryScreen = ({
  stepNumber, translate, nextScreen, redirect, handleSubmit, selectedCategories, candidateCategoriesValidationParams, validationParamsFetched,
}) => (
  <View style={styles.container}>
    <ProfileCreatorHeader
      stepNo={stepNumber}
      title={translate('candidateProfileCreator.category.header')}
      infoText={getInfoText(translate, candidateCategoriesValidationParams)}
    />
    {
        validationParamsFetched
          ? (
            <FormikWithValidation
              validation={validation(candidateCategoriesValidationParams)}
              initialValues={{ categories: selectedCategories }}
              onSubmit={handleSubmit}
              render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
                <>
                  <GigCategorySelectListContainer
                    name="general"
                    maxSelect={candidateCategoriesValidationParams.max}
                  />
                  <StickyButtonsWrapper>
                    <LinkButton
                      to={nextScreen}
                      title={translate('generic.next')}
                      onPress={handleSubmitFormik}
                      doRedirect={redirect}
                      disabled={isBtnDisabled}
                    />
                  </StickyButtonsWrapper>
                </>
              )}
            />
          )
          : (<ActivityIndicator size="large" style={styles.loadingIcon} />)
      }
  </View>
);

ProfileCreatorStepCategoryScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  candidateCategoriesValidationParams: candidateCategoryValidationParams.isRequired,
  validationParamsFetched: PropTypes.bool,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
};

ProfileCreatorStepCategoryScreen.defaultProps = {
  validationParamsFetched: false,
};

export default withLocalize(ProfileCreatorStepCategoryScreen);
