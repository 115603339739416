import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import CompanyProfileSettingsScreen from './CompanyProfileSettingsScreen';
import Routing from '../../../core/routing/index';
import { FIRST_CHECK_USER_STATUS } from '../../../constants/routes';
import { getInstallationId } from '../../../common/auth/deviceDetailsUtil';
import { logoutUser } from '../../../common/auth/authActions';
import { deleteAccount } from '../../../common/user/userActions';

const { withRouter } = Routing;

class CompanyProfileSettingsContainer extends React.Component {
  onLogout = () => {
    this.props.logoutUser(getInstallationId());
  };

  onDelete = () => {
    this.props.deleteAccount();
  };

  goBack = () => {
    if (queryString.parse(this.props.location.search).notificationSettings) {
      this.props.history.replace(FIRST_CHECK_USER_STATUS);
      return;
    }
    this.props.history.goBack();
  };

  render() {
    return (
      <CompanyProfileSettingsScreen
        onLogout={this.onLogout}
        onDelete={this.onDelete}
        handleBack={this.goBack}
      />
    );
  }
}

CompanyProfileSettingsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  logoutUser: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { logoutUser, deleteAccount })(CompanyProfileSettingsContainer));
