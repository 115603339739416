import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const hasRole = (currentUserScope, allowedFor) => allowedFor.includes(currentUserScope);

const UserScope = ({
  children, currentUserScope, allowedFor,
}) => (
  <>{hasRole(currentUserScope, allowedFor) && children}</>
);

const mapStateToProps = (state) => ({
  currentUserScope: state.auth.get('userScope'),
});

UserScope.propTypes = {
  children: PropTypes.node,
  allowedFor: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUserScope: PropTypes.string,
};

UserScope.defaultProps = {
  currentUserScope: '',
  children: undefined,
};

export default connect(mapStateToProps)(UserScope);
