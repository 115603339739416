import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import PrimaryModal from '../../../../common/components/modal/PrimaryModal';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import ImageComponent from '../../../../common/components/image/ImageComponent';
import PrimaryText from '../../../../common/components/PrimaryText';
import {
  colors, fontFamily, fontSizes, hp, spacings, windowHeight, wp,
} from '../../../../common/styles/base.style';
import Header from '../../../../common/components/header/Header';
import { font } from '../../../../common/styles/mixins';

const imgSrc = require('../../../../assets/photos/favouritesPopup/gigs-info.png');

function getImageHeight() {
  if (windowHeight > 700) {
    return hp(22);
  } if (windowHeight > 500) {
    return hp(15);
  }
  return 0;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    color: colors.black,
    marginBottom: spacings.xl,
  },
  regularText: {
    width: '90%',
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: spacings.md,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm, 0.4),
  },
  button: {
    marginTop: spacings.md,
    marginBottom: spacings.xl,
  },
  imageWrapper: {
    flex: 1,
  },
  image: {
    width: wp(65),
    height: getImageHeight(),
    resizeMode: 'contain',
  },
});

function ProposeFavouritesTabModalView({ visible, closeModal, translate }) {
  return (
    <PrimaryModal
      header=""
      visible={visible}
      handleCancel={closeModal}
      headerProps={{ showBackArrow: false, shadow: false }}
    >
      <View style={styles.container}>
        <ImageComponent
          image={imgSrc}
          size="lg"
          style={{
            wrapper: styles.imageWrapper,
            image: styles.image,
          }}
        />
        <Header style={styles.header}>{translate('singleViewInfoModal.title')}</Header>
        <PrimaryText style={styles.regularText}>{translate('singleViewInfoModal.paragraphOne')}</PrimaryText>
        <PrimaryText style={styles.regularText}>{translate('singleViewInfoModal.paragraphTwo')}</PrimaryText>
        <Button
          size={BUTTON_SIZES.MD}
          type={BUTTON_TYPES.PRIMARY_RED}
          title={translate('singleViewInfoModal.button')}
          onPress={closeModal}
          style={{ wrapper: styles.button }}
        />
      </View>
    </PrimaryModal>
  );
}

ProposeFavouritesTabModalView.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default withLocalize(ProposeFavouritesTabModalView);
