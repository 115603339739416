import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import Modal from './Modal';
import modalStyle from '../../styles/modal.style';
import ScreenHeader from '../header/ScreenHeader';
import Container from '../Container';
import ViewportController from '../ViewportController';

const PrimaryModal = ({ children, header, visible, handleCancel, headerProps }) => (
  <Modal animationType="slide" transparent visible={visible} onRequestClose={handleCancel}>
    <ViewportController>
      <Container style={modalStyle.fullScreenModal}>
        <ScreenHeader title={header} safeAreaTop={Platform.OS === 'ios'} handleBack={handleCancel} {...headerProps} />
        <Container>{children}</Container>
      </Container>
    </ViewportController>
  </Modal>
);
PrimaryModal.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  headerProps: PropTypes.object,
};
PrimaryModal.defaultProps = {
  children: null,
  headerProps: {},
};

export default PrimaryModal;
