import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';

import Routing from '../../../core/routing/index';
import getAdminProfile from '../AdminProfileActions';
import AdminProfileViewDetailsScreen from './AdminProfileViewDetailsScreen';
import { candidateProfilePropType } from '../../../constants/propTypes';
import { ADMIN_PROFILE_SETTINGS } from '../../../constants/routes';

const { withRouter } = Routing;

class AdminProfileViewDetailsContainer extends Component {
  componentDidMount() {
    this.props.getAdminProfile();
  }

  render() {
    return (
      <AdminProfileViewDetailsScreen
        profile={this.props.profile}
        onSettingsButton={() => this.props.history.push(ADMIN_PROFILE_SETTINGS)}
      />
    );
  }
}

AdminProfileViewDetailsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  getAdminProfile: PropTypes.func.isRequired,
  profile: candidateProfilePropType,
};

AdminProfileViewDetailsContainer.defaultProps = {
  profile: {},
};

const mapStateToProps = (state) => ({
  profile: state.admin.get('profile').toJS(),
});

export default connect(mapStateToProps, { getAdminProfile })(withLocalize(withRouter(AdminProfileViewDetailsContainer)));
