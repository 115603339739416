import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { clearGigCandidates, getGigCandidates, storeMyGigsScrollPosition } from '../companyGigCandidatesActions';
import { gigCandidatePropType } from '../../../../../constants/propTypes';
import { HIRED_LIST_KEY } from '../companyGigCandidatesReducer';
import { GIG_CANDIDATE_STATUS } from '../../../../../constants/matchingStatus';
import Routing from '../../../../../core/routing/index';
import candidatesListStyles from '../components/GigCandidatesListStyle';
import PrimaryFlatList from '../../../../../common/components/PrimaryFlatList';
import CompanyGigCandidateViewOfferModal from '../offerModal/CompanyGigCandidateViewOfferModal';
import CompanyGigCandidateListItem from '../CompanyGigCandidateListItem';
import ListItem from '../../../../../common/components/ListItem';
import CandidateQuestionnaireModalView from '../questionnaireModal/CandidateQuestionnaireModalView';

const { withRouter } = Routing;

class CompanyGigHiredView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModalVisible: false,
      modalGigId: undefined,
      modalCandidateId: undefined,
      questionnaireModalVisible: false,
      modalQuestionnaire: undefined,
    };
  }

  componentDidMount() {
    this.scrollPosition = 0;
    this.props.getGigCandidates(this.props.match.params.id, [GIG_CANDIDATE_STATUS.HIRED], HIRED_LIST_KEY)
      .then(() => {
        if (this.props.history.action === 'POP') {
          const { storedScrollPosition } = this.props;
          if (storedScrollPosition) {
            this.scrollToLastKnownPosition(storedScrollPosition);
          }
        } else {
          this.props.storeMyGigsScrollPosition(this.scrollPosition);
        }
      });
  }

  componentWillUnmount() {
    this.props.storeMyGigsScrollPosition(this.scrollPosition);
    this.props.clearGigCandidates(HIRED_LIST_KEY);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  openViewModal = (gigId, candidateId) => {
    this.setState({
      viewModalVisible: true,
      modalGigId: gigId,
      modalCandidateId: candidateId,
    });
  };

  closeViewModal = () => {
    this.setState({ viewModalVisible: false });
  };

  openQuestionnaireModal = (questionnaire) => {
    this.setState({ questionnaireModalVisible: true, modalQuestionnaire: questionnaire });
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToOffset({ offset: scrollPosition, animated: true }), 0);
  }

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, modalQuestionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalView
        visible={questionnaireModalVisible}
        closeModal={this.closeQuestionnaireModal}
        questionnaire={modalQuestionnaire}
      />
    );
  }

  renderViewModal() {
    const {
      viewModalVisible,
      modalGigId,
      modalCandidateId,
      viewModalEditEnabled,
    } = this.state;

    return (
      <CompanyGigCandidateViewOfferModal
        headerProps={viewModalEditEnabled ? this.offerViewModalEditHeaderProps : undefined}
        visible={viewModalVisible}
        closeModal={this.closeViewModal}
        gigId={modalGigId}
        candidateId={modalCandidateId}
      />
    );
  }

  render() {
    return (
      <>
        <PrimaryFlatList
          getItemLayout={(data, index) => ({ length: 275, offset: index * 275, index })} // fix for not scrolling over ~1300 pixels
          emptyListTextKey="companyGigs.candidates.noMatchingCandidates"
          contentContainerStyle={candidatesListStyles.contentContainerStyle}
          data={this.props.hired}
          keyExtractor={(item) => item.userId}
          onScroll={this.onScroll}
          innerRef={this.setListRef}
          renderItem={({ item: candidate }) => (
            <ListItem
              renderContent={() => (
                <CompanyGigCandidateListItem
                  candidate={candidate}
                  gigId={this.props.match.params.id}
                  internalFunctions={{
                    openModal: () => this.openViewModal(this.props.match.params.id, candidate.userId),
                    showQuestionnaire: () => this.openQuestionnaireModal(candidate.matching.questionnaire),
                  }}
                  online={this.props.usersPresence[candidate.userId]}
                />
              )}
            />
          )}
        />
        {this.renderViewModal()}
        {this.renderQuestionnaireModal()}
      </>
    );
  }
}

CompanyGigHiredView.propTypes = {
  getGigCandidates: PropTypes.func.isRequired,
  clearGigCandidates: PropTypes.func.isRequired,
  hired: PropTypes.arrayOf(gigCandidatePropType),
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  storedScrollPosition: PropTypes.number.isRequired,
  storeMyGigsScrollPosition: PropTypes.func.isRequired,
};

CompanyGigHiredView.defaultProps = {
  hired: null,
};

const mapStateToProps = (state) => ({
  hired: state.gigCandidates.get(HIRED_LIST_KEY) ? state.gigCandidates.get(HIRED_LIST_KEY).toJS() : null,
  storedScrollPosition: state.gigCandidates.get('companyMyGigsScrollPosition'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default withRouter(connect(mapStateToProps, { getGigCandidates, clearGigCandidates, storeMyGigsScrollPosition })(CompanyGigHiredView));
