import React from 'react';
import { StyleSheet, View } from 'react-native';

import { spacings } from '../../../common/styles/base.style';
import Container from '../../../common/components/Container';
import AdminFindAssociationFilters from './filters/AdminFindAssociationFilters';
import AdminAssociationsList from './AdminAssociationsList';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacings.sm,
  },
});

function AdminFindCompanyContainer() {
  return (
    <Container>
      <View style={styles.container}>
        <AdminFindAssociationFilters />
      </View>
      <AdminAssociationsList />
    </Container>
  );
}

export default AdminFindCompanyContainer;
