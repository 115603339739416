import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import Wrapper from '../../../../common/components/Wrapper';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import { MAX_PERSONAL_NUMBER_LENGTH, bankAccountNorValidation } from './bankAccountNoValidation';

const CandidateProfileBankAccountNumberForm = ({
  translate, renderFooter, handleSubmit, bankAccountNo,
}) => (
  <FormikWithValidation
    validation={bankAccountNorValidation}
    initialValues={{ bankAccountNo }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <KeyboardAwareFlexWrapper>
          <Wrapper>
            <FormikFieldPrimaryTextInput
              name="bankAccountNo"
              label={translate('candidateProfile.details.bankAccountNo')}
              placeholder="XXXXXXXX"
              maxLength={MAX_PERSONAL_NUMBER_LENGTH}
              returnKeyType="done"
              onSubmitEditing={handleSubmitFormik}
            />
          </Wrapper>
        </KeyboardAwareFlexWrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfileBankAccountNumberForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  bankAccountNo: PropTypes.string,
};

CandidateProfileBankAccountNumberForm.defaultProps = {
  bankAccountNo: undefined,
};

export default withLocalize(CandidateProfileBankAccountNumberForm);
