import { StyleSheet } from 'react-native';
import { spacings } from './base.style';

export default StyleSheet.create({
  flexVerticalCentered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexDisplayVerticalCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexVerticalTopCentered: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  withMargin: {
    marginVertical: spacings.sm,
    marginTop: spacings.lg,
    marginHorizontal: spacings.sm,
  },
});
