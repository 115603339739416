import { Map } from 'immutable';
import { BUNDLE_UPDATE_READY, BUNDLE_RELOADED } from '../../../constants/actionTypes';

const INITIAL_APP_STATE = {
  bundleUpdateReady: false,
};

export default function updateReducer(state = Map(INITIAL_APP_STATE), action) {
  switch (action.type) {
    case BUNDLE_UPDATE_READY:
      return state.set('bundleUpdateReady', true);
    case BUNDLE_RELOADED:
      return state.set('bundleUpdateReady', false);
    default:
      return state;
  }
}
