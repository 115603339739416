import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import ImageComponent from './ImageComponent';
import { stylePropTypes } from '../../../constants/propTypes';

const warningIcon = require('../../../assets/icons/warningGreen.png');

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-start',
  },
  image: {
    width: 18,
    height: 18,
  },
});

class WarningIcon extends PureComponent {
  render() {
    return (
      <ImageComponent
        image={warningIcon}
        style={{ wrapper: [styles.wrapper, this.props.style.wrapper], image: [styles.image, this.props.style.image] }}
      />
    );
  }
}

WarningIcon.propTypes = {
  style: PropTypes.shape({
    wrapper: stylePropTypes,
    image: stylePropTypes,
  }),
};

WarningIcon.defaultProps = {
  style: {
    wrapper: null,
    image: null,
  },
};

export default WarningIcon;
