import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';

import Routing from '../../../../core/routing/index';
import { COMPANY_SHIFT_CREATE_DATES_AND_TIMES } from '../../../../constants/routes';
import CompanyShiftsMainStepEditableScreen from './CompanyShiftsMainStepEditableScreen';
import { gigCityPropType, gigLocationPropType } from '../../../../constants/propTypes';
import { getAvailableGigCities } from '../../../../common/gig/city/gigCitiesActions';
import { getTranslationForCity } from '../../../../common/gig/city/gigCitiesHelper';
import {
  shiftCreatorUseTemplate, shiftCreatorSetPosition, shiftCreatorSetDescription, shiftCreatorSetCandidatesNumber, shiftCreatorSetLocation,
} from '../shiftCreatorActions';
import { getShiftTemplates, removeShiftTemplate } from '../../companyShiftsActions';
import { searchForShiftLocation } from '../../../../common/geolocation/geolocationActions';
import { showConfirmAlert } from '../../../../common/components/alert/alerts';

const { withRouter } = Routing;

class CompanyShiftsMainStepEditableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickedLocation: props.shiftLocation.geoLoc ? {
        street: props.shiftLocation.street,
        place: props.shiftLocation.place,
        geoLoc: props.shiftLocation.geoLoc,
      } : null,
      templates: null,
    };
  }

  componentDidMount() {
    this.props.getAvailableGigCities();
    this.loadShiftTemplates();
  }

  loadShiftTemplates = () => {
    this.props.getShiftTemplates().then((res) => {
      this.setState({ templates: res.payload });
    });
  };

  getCitiesWithTranslations = () => this.props.availableCities && this.props.availableCities.map((city) => ({
    value: city.key,
    label: getTranslationForCity(city),
    coordinates: city.geoLoc.coordinates,
  }));

  onLocationChange = async (pickedLocation) => {
    this.setState({ pickedLocation });
  };

  onChooseTemplate = (template) => {
    this.props.shiftCreatorUseTemplate(template);
    this.setState({
      pickedLocation: {
        street: template.location.street,
        place: template.location.place,
        geoLoc: template.location.geoLoc,
      },
    });
  };

  onRemoveTemplate = (template) => {
    showConfirmAlert(this.props.translate, {
      confirmKey: 'generic.confirm',
      cancelKey: 'generic.cancel',
      textKey: 'company.shifts.shift.main.removeShiftConfirmation.text',
      titleKey: 'company.shifts.shift.main.removeShiftConfirmation.title',
      confirmStyle: 'destructive',
    }, () => {
      this.props.removeShiftTemplate(template.gigId).then(() => {
        this.loadShiftTemplates();
      });
    });
  };

  handleSubmit = ({
    position, description, candidatesNumber, cityKey,
  }) => {
    const { pickedLocation } = this.state;

    this.props.shiftCreatorSetPosition(position);
    this.props.shiftCreatorSetDescription(description);
    this.props.shiftCreatorSetCandidatesNumber(parseInt(candidatesNumber, 10));
    this.props.shiftCreatorSetLocation({ ...pickedLocation, cityV2: { key: cityKey } });
    this.props.history.push(COMPANY_SHIFT_CREATE_DATES_AND_TIMES);
  };

  render() {
    const { pickedLocation, templates } = this.state;
    const {
      position, description, candidatesNumber, shiftLocation,
    } = this.props;
    const initialValues = {
      position, description, candidatesNumber, cityKey: shiftLocation.cityV2.key,
    };

    return (
      <CompanyShiftsMainStepEditableScreen
        shiftLocation={pickedLocation}
        cities={this.getCitiesWithTranslations()}
        templates={templates}
        onLocationChange={this.onLocationChange}
        onChooseTemplate={this.onChooseTemplate}
        onRemoveTemplate={this.onRemoveTemplate}
        initialValues={initialValues}
        handleSubmit={this.handleSubmit}
        searchForShiftLocation={this.props.searchForShiftLocation}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    position,
    description,
    candidatesNumber,
    location,
  } = state.shiftCreator.get('shift').toJS();

  return {
    position,
    description,
    candidatesNumber: `${candidatesNumber}`,
    shiftLocation: location,
    availableCities: state.gigCities.toJS(),
  };
};

CompanyShiftsMainStepEditableContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  candidatesNumber: PropTypes.string.isRequired,
  shiftLocation: gigLocationPropType.isRequired,
  shiftCreatorUseTemplate: PropTypes.func.isRequired,
  shiftCreatorSetPosition: PropTypes.func.isRequired,
  shiftCreatorSetDescription: PropTypes.func.isRequired,
  shiftCreatorSetCandidatesNumber: PropTypes.func.isRequired,
  shiftCreatorSetLocation: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  getShiftTemplates: PropTypes.func.isRequired,
  removeShiftTemplate: PropTypes.func.isRequired,
  searchForShiftLocation: PropTypes.func.isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType).isRequired,
};

export default connect(mapStateToProps, {
  getAvailableGigCities,
  shiftCreatorUseTemplate,
  shiftCreatorSetPosition,
  shiftCreatorSetDescription,
  shiftCreatorSetCandidatesNumber,
  shiftCreatorSetLocation,
  searchForShiftLocation,
  getShiftTemplates,
  removeShiftTemplate,
})(withLocalize(withRouter(CompanyShiftsMainStepEditableContainer)));
