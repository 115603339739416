import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { getPoolProfile } from '../candidateMyPoolsActions';
import Routing from '../../../core/routing/index';
import CandidatesPoolDetailsScreen from '../../../common/shift/CandidatesPoolDetailsScreen';
import { CANDIDATE_MY_POOLS_GROUP_CHAT } from '../../../constants/routes';
import { CANDIDATE_USER } from '../../../constants/userScopes';

const { withRouter } = Routing;

class CandidatesPoolProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { poolProfile: null };
  }

  componentDidMount() {
    const { companyId } = this.props.match.params;
    this.fetchPoolProfile(companyId);
  }

  onGroupChat(groupId) {
    this.props.history.push(CANDIDATE_MY_POOLS_GROUP_CHAT.replace(':groupId', groupId));
  }

  fetchPoolProfile = (companyId) => {
    this.props.getPoolProfile(companyId).then((res) => {
      this.setState({ poolProfile: res.payload });
    });
  };

  render() {
    return (
      <CandidatesPoolDetailsScreen poolProfile={this.state.poolProfile} onGroupChat={(groupId) => this.onGroupChat(groupId)} userScope={CANDIDATE_USER} />
    );
  }
}

CandidatesPoolProfileContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  getPoolProfile: PropTypes.func.isRequired,
};

export default connect(null, { getPoolProfile })(withRouter(CandidatesPoolProfileContainer));
