import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import CandidateProfileHomeLocationForm from '../../../forms/homeLocation/CandidateProfileHomeLocationForm';
import { candidateHomeLocationPropType, candidateLocationPropType } from '../../../../../constants/propTypes';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';
import { LinkButton } from '../../../../../common/components';
import { BUTTON_SIZES } from '../../../../../common/components/buttons/Button';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const ProfileCreatorStepHomeLocationScreen = ({
  translate, homeLocation, location, handleSubmit, stepNumber, nextScreen, redirect, onRegionChange, loading,
}) => (
  <View style={styles.container}>
    <ProfileCreatorHeader
      stepNo={stepNumber}
      title={translate('candidateProfileCreator.homeLocation.header')}
      infoText={translate('candidateProfileCreator.homeLocation.homeLocationSubLabel')}
    />
    <CandidateProfileHomeLocationForm
      homeLocation={homeLocation}
      location={location}
      onRegionChange={onRegionChange}
    />
    <StickyButtonsWrapper style={styles.buttonsContainer}>
      <LinkButton
        size={BUTTON_SIZES.LG}
        to={nextScreen}
        title={translate('generic.next')}
        onPress={handleSubmit}
        doRedirect={redirect}
        disabled={loading}
      />
    </StickyButtonsWrapper>
  </View>
);

ProfileCreatorStepHomeLocationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  homeLocation: candidateHomeLocationPropType,
  location: candidateLocationPropType,
  handleSubmit: PropTypes.func.isRequired,
  onRegionChange: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

ProfileCreatorStepHomeLocationScreen.defaultProps = {
  homeLocation: undefined,
  location: undefined,
};

export default withLocalize(ProfileCreatorStepHomeLocationScreen);
