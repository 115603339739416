import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileCreatorStepCurrentOccupationScreen from './ProfileCreatorStepCurrentOccupationScreen';
import { profileCreatorAddCurrentOccupation } from '../../profileCreatorActions';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepCurrentOccupationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.CURRENT_OCCUPATION);
  }

  handleSubmit = ({ currentOccupation }) => {
    this.props.profileCreatorAddCurrentOccupation(currentOccupation);
    this.doRedirect();
  };

  doRedirect = () => this.setState({ redirect: true });

  render = () => (
    <ProfileCreatorStepCurrentOccupationScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.CURRENT_OCCUPATION, this.props.grantedFeatures)}
      selectedCurrentOccupation={this.props.selectedCurrentOccupation}
      handleSubmit={this.handleSubmit}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.CURRENT_OCCUPATION, this.props.grantedFeatures)}
    />
  );
}

ProfileCreatorStepCurrentOccupationContainer.propTypes = {
  profileCreatorAddCurrentOccupation: PropTypes.func.isRequired,
  selectedCurrentOccupation: PropTypes.arrayOf(PropTypes.string).isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  selectedCurrentOccupation: state.candidate.get('profileData').get('currentOccupation').toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { profileCreatorAddCurrentOccupation })(ProfileCreatorStepCurrentOccupationContainer);
