import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as chatClient from './chatClient';
import {
  chatSubscriptionsReceived, chatUpdated, userPresenceUpdated, getChatCredentials,
} from './chatActions';
import { getGigChatsDetails, getChatList, isNewGigChat } from './chatHelper';
import { addNoChatNotification, removeNoChatNotification } from '../notifications_center/notificationCenterActions';
import { APP_STATE_ACTIVE, APP_STATE_BACKGROUND } from '../watchers/app_state/AppStateWatcher';
import { getPoolGroupChatsList, getPoolDirectChatsList, isNewPoolChat } from './pool/poolChatHelper';
import { poolChatUpdated } from './pool/poolChatActions';

const CHAT_MINIMUM_APP_IN_ACTIVE_STATE_PERIOD = 5000;
const CHAT_RECONNECT_THRESHOLD = 2;

class ChatProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { reconnectAttempts: 0 };
  }

  componentDidMount() {
    chatClient.addConnectedHandler(this.onConnect);
    chatClient.addDisconnectedHandler(this.onDisconnect);
    this.loginHandlerId = chatClient.addLoginHandler(this.onLogin);
    // if (this.props.authenticated) {
    this.getChatCredentials();
    // }
  }

  componentDidUpdate(prevProps) {
    const { token, authenticated, appState } = this.props;

    if (prevProps.token == null && token && appState === APP_STATE_ACTIVE) {
      chatClient.disconnect();
      chatClient.connect();
    } else if (prevProps.authenticated === false && authenticated === true) {
      this.getChatCredentials();
    } else if (prevProps.authenticated === true && authenticated === false) {
      chatClient.disconnect();
    } else if (prevProps.appState !== appState && appState === APP_STATE_BACKGROUND) {
      chatClient.disconnect();
    } else if (token && prevProps.appState !== appState && appState === APP_STATE_ACTIVE) {
      chatClient.disconnect();
      chatClient.connect();
    }
  }

  // can it even happen?
  componentWillUnmount() {
    chatClient.removeConnectedHandler(this.onConnect);
    chatClient.removeLoginHandler(this.loginHandlerId);
    chatClient.unsubscribeChatsUpdated(this.chatsUpdatedSubscriptionId);
    chatClient.unsubscribeUserStatusChange(this.userStatusSubscriptionId);
  }

  onConnect = () => {
    chatClient.login(this.props.token);
    this.props.removeNoChatNotification();
    this.resetReconnectAttemptsThreshold();
  };

  onDisconnect = () => {
    // user is logged in but chat connection failed, display only on chat screens
    if (this.props.authenticated
      && this.props.location.pathname.includes('chat')
      && this.isAppActiveSince(CHAT_MINIMUM_APP_IN_ACTIVE_STATE_PERIOD)
      && this.isReconnectAttemptsThresholdExceeded()) {
      this.props.addNoChatNotification();
    }
  };

  onChatUpdateListener = (chat) => {
    if (chat.lastMessage && chat.customFields) {
      const { gigId } = chat.customFields;
      if (gigId) {
        this.props.chatUpdated(gigId, chat._id, chat.lastMessage._updatedAt.$date, chat.lastMessage.msg);
        if (isNewGigChat(this.props.userScope, chat._id)) {
          getGigChatsDetails(this.props.userScope, gigId);
        }
      }
      if (this.props.grantedFeatures.includes('CANDIDATES_POOL')) {
        if (chat.customFields.groupId || chat.customFields.poolCandidateId) {
          const poolChatId = chat.customFields.groupId || chat.customFields.poolCandidateId;
          this.props.poolChatUpdated(poolChatId, chat._id, chat.lastMessage.ts.$date, chat.lastMessage.msg);
          if (isNewPoolChat(poolChatId)) {
            if (chat.customFields.groupId) {
              getPoolGroupChatsList(this.props.userScope);
            } else {
              getPoolDirectChatsList(this.props.userScope);
            }
          }
        }
      }
    }
  };

  onUserStatusChangeListener = (userId, online) => {
    this.props.userPresenceUpdated(userId, online);
  };

  onLogin = () => {
    console.log('ChatProvider: Logged in');
    chatClient.getChats((subscriptions) => {
      this.props.chatSubscriptionsReceived(subscriptions);
    });

    this.chatsUpdatedSubscriptionId = chatClient.subscribeChatsUpdated(this.props.userId, this.onChatUpdateListener);
    this.userStatusSubscriptionId = chatClient.subscribeUserStatusChange(this.onUserStatusChangeListener);
  };

  getChatCredentials = () => {
    this.props.getChatCredentials()
      .then((response) => {
        if (!response.isError) {
          getChatList(this.props.userScope);
        } else if (response.status === 404) {
          console.log('No chat account found for user.');
        }
      });
  };

  isAppActiveSince(periodMilis) {
    return this.props.appState === APP_STATE_ACTIVE && Date.now() - this.props.lastAppStateChangeTimestamp > periodMilis;
  }

  isReconnectAttemptsThresholdExceeded() {
    if (this.state.reconnectAttempts >= CHAT_RECONNECT_THRESHOLD) {
      return true;
    }
    this.setState({ reconnectAttempts: this.state.reconnectAttempts + 1 });
    return false;
  }

  resetReconnectAttemptsThreshold() {
    this.setState({ reconnectAttempts: 0 });
  }

  render() {
    return null;
  }
}

ChatProvider.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  getChatCredentials: PropTypes.func.isRequired,
  chatSubscriptionsReceived: PropTypes.func.isRequired,
  chatUpdated: PropTypes.func.isRequired,
  poolChatUpdated: PropTypes.func.isRequired,
  userPresenceUpdated: PropTypes.func.isRequired,
  addNoChatNotification: PropTypes.func.isRequired,
  removeNoChatNotification: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  token: PropTypes.string,
  userScope: PropTypes.string,
  userId: PropTypes.string,
  appState: PropTypes.string.isRequired,
  lastAppStateChangeTimestamp: PropTypes.number.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ChatProvider.defaultProps = {
  token: undefined,
  userId: undefined,
  userScope: undefined,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  userScope: state.auth.get('userScope'),
  token: state.chatAuth.get('chatToken'),
  userId: state.chatAuth.get('chatUserId'),
  lastAppStateChangeTimestamp: state.appStateData.get('lastAppStateChangeTimestamp'),
  appState: state.appStateData.get('appState'),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, {
  getChatCredentials,
  chatSubscriptionsReceived,
  chatUpdated,
  userPresenceUpdated,
  addNoChatNotification,
  removeNoChatNotification,
  poolChatUpdated,
})(ChatProvider);
