import {
  CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST,
  CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST,
  CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST,
  CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST,
  CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST,
  CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST,
  CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST,
  CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST,
  CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST,
  CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST,
  CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST,
  CANDIDATE_PROFILE_REQUEST,
  CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST,
  CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST,
  CANDIDATE_PROFILE_SET_CATEGORY_REQUEST,
  CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST,
  CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST,
  CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST,
  CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST,
  CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST,
  CANDIDATE_PROFILE_SET_EMAIL_REQUEST,
  CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST,
  CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST,
  CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST,
  ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST
} from '../../constants/actionTypes';
import { transformRequestTransforms } from '../../core/client/axiosClientConfig';
import { clearToDateIfOngoing } from './profileHelper';
import { uploadImage } from '../../core/client/imageUploadClient';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export default function getProfile() {
  return {
    type: CANDIDATE_PROFILE_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/profile',
      },
    },
  };
}

export function getProfileCategoryValidationParams() {
  return {
    type: CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/profile/validationParams',
      },
    },
  };
}

export function setAvailability(available) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_AVAILABILITY, available);
  return {
    type: CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/available',
        data: { available },
      },
    },
  };
}

export function candidateProfileSetFullName(fullName) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_FULL_NAME);
  return {
    type: CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/fullName',
        data: fullName,
      },
    },
  };
}

export function candidateProfileSetEmail(email) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EMAIL);
  return {
    type: CANDIDATE_PROFILE_SET_EMAIL_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'user/account/email',
        data: email,
      },
    },
  };
}

export function candidateProfileSetAvailability(availability) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_AVAILABILITY_DETAILS);
  return {
    type: CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/availabilityDetails',
        data: availability,
      },
    },
  };
}

export function candidateProfileSetGigLocation(location) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_LOCATION);
  return {
    type: CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/location',
        data: location,
      },
    },
  };
}

export function candidateProfileSetHomeLocation(homeLocation) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_HOME_LOCATION);
  return {
    type: CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/homeLocation',
        data: homeLocation,
      },
    },
  };
}

export function candidateProfileSetCategory(categories) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_CATEGORY);
  return {
    type: CANDIDATE_PROFILE_SET_CATEGORY_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/category',
        data: categories,
      },
    },
  };
}

export function candidateProfileSetCurrentOccupation(currentOccupation) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_CURRENT_OCCUPATION);
  return {
    type: CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/currentOccupation',
        data: currentOccupation,
      },
    },
  };
}

function transformEducation(data) {
  return { education: clearToDateIfOngoing(data.education) };
}

export function candidateProfileAddEducation(education) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EDUCATION, 'add');
  return {
    type: CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/education/',
        data: { education },
        transformRequest: [transformEducation].concat(transformRequestTransforms),
      },
    },
  };
}

export function candidateProfileEditEducation(education) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EDUCATION, 'edit');
  return {
    type: CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `candidate/profile/education/${education.educationId}`,
        data: { education },
        transformRequest: [transformEducation].concat(transformRequestTransforms),
      },
    },
  };
}

export function candidateProfileRemoveEducation(education) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EDUCATION, 'remove');
  return {
    type: CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST,
    educationId: education.educationId,
    payload: {
      request: {
        method: 'delete',
        url: `candidate/profile/education/${education.educationId}`,
      },
    },
  };
}

function transformExperience(data) {
  return { experience: clearToDateIfOngoing(data.experience) };
}

export function candidateProfileAddExperience(experience) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EXPERIENCE, 'add');
  return {
    type: CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/experience/',
        data: { experience },
        transformRequest: [transformExperience].concat(transformRequestTransforms),
      },
    },
  };
}

export function candidateProfileEditExperience(experience) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EXPERIENCE, 'edit');
  return {
    type: CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `candidate/profile/experience/${experience.experienceId}`,
        data: { experience },
        transformRequest: [transformExperience].concat(transformRequestTransforms),
      },
    },
  };
}

export function candidateProfileRemoveExperience(experience) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_EXPERIENCE, 'remove');
  return {
    type: CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST,
    experienceId: experience.experienceId,
    payload: {
      request: {
        method: 'delete',
        url: `candidate/profile/experience/${experience.experienceId}`,
      },
    },
  };
}

export function candidateProfileEditAboutMe(aboutMe) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_ABOUT_ME);
  return {
    type: CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/aboutMe',
        data: aboutMe,
      },
    },
  };
}

export function candidateProfileEditPhoneNumber(msisdn) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_MSISDN);
  return {
    type: CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/msisdn',
        data: msisdn,
      },
    },
  };
}

export function candidateProfileEditPersonalNumber(personalNumber) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_PERSONAL_NUMBER);
  return {
    type: CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/personalNumber',
        data: personalNumber,
      },
    },
  };
}

export function candidateProfileEditClearingNumber(clearingNumber) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_CLEARING_NUMBER);
  return {
    type: CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/clearingNumber',
        data: clearingNumber,
      },
    },
  };
}

export function candidateProfileEditBankAccountNumber(bankAccountNo) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_BANK_ACCOUNT_NUMBER);
  return {
    type: CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/bankAccountNo',
        data: bankAccountNo,
      },
    },
  };
}

export function candidateProfileSetLanguages(languages) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_LANGUAGES);
  return {
    type: CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/languages',
        data: { languages },
      },
    },
  };
}

export const setCandidateContractStatus = () => ({
  type: CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST,
  payload: {
    request: {
      method: 'post',
      url: '/candidate/profile/accept-contract',
      data: {
        accepted: true
      }
    },
  },
});

export function candidateProfileEditAssociation(association) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.UPDATE_ASSOCIATION);
  return {
    type: ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'candidate/profile/association',
        data: association
      },
    },
  };
}

export function candidateProfileEditProfileImage(imageUri) {
  return uploadImage(imageUri, 'candidate/profile/profileImage', CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST);
}
