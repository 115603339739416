/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import {
  View, Text, StyleSheet, TouchableOpacity, TextInput, KeyboardAvoidingView, Platform, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  GiftedChat, MessageContainer, Bubble, MessageText,
} from 'react-native-gifted-chat';
import { List } from 'immutable';
import { withLocalize } from 'react-localize-redux';

import { getChatCredentials } from '../chatActions';
import * as chatClient from '../chatClient';
import ScreenHeader from '../../components/header/ScreenHeader';
import ImageComponent from '../../components/image/ImageComponent';
import {
  colors, spacings, fontFamily, fontSizes, shadowBottom, hp,
} from '../../styles/base.style';
import Container from '../../components/Container';
import { font } from '../../styles/mixins';
import Wrapper from '../../components/Wrapper';
import PrimaryText from '../../components/PrimaryText';
import { activeLanguagePropTypes } from '../../../constants/propTypes';
import ViewportController from '../../components/ViewportController';
import { poolChatLoadMessages, poolChatNewMessage, poolChatSaveLastMessageRead } from './poolChatActions';

const sendIcon = require('../../../assets/icons/send/send.png');
const sendInactiveIcon = require('../../../assets/icons/send/sendInactive.png');

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'flex-start',
    paddingLeft: spacings.xxxl,
    paddingBottom: spacings.sm,
    ...Platform.select({
      web: {
        paddingLeft: spacings.xxxxl,
        paddingBottom: spacings.lg,
      },
    }),
  },
  header: {
    marginBottom: 0,
  },
  subheader: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xs, 0.2, colors.brownGrey),
  },
  profileImageWrapper: {
    position: 'absolute',
    top: 4,
    right: 0,
    paddingTop: spacings.sm,
    zIndex: 100,
    paddingRight: spacings.sm,
    paddingBottom: 1,
    ...Platform.select({
      web: {
        paddingRight: spacings.lg,
        paddingTop: spacings.lg,
      },
    }),
  },
  profileImage: {
    height: 37,
    width: 37,
    borderRadius: 18,
  },
  rightBubble: {
    backgroundColor: colors.seablue,
  },
  leftMessageStyle: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.md, null, colors.greyishBrown),
  },
  rightMessageStyle: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.md, null, colors.white),
  },
  chatFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.smokeWhite,
    // paddingTop: 5,
    paddingHorizontal: spacings.sm,
    borderTopWidth: 1,
    borderTopColor: colors.veryLightGrey,
    maxHeight: hp(25),
  },
  textInput: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.lg, null, colors.greyishBrown),
    flex: 1,
    backgroundColor: colors.smokeWhite,
    paddingLeft: spacings.xxs,
    paddingVertical: spacings.xs,
  },
});

const sendImageStyles = StyleSheet.create({
  wrapper: {
    paddingLeft: spacings.xs,
    paddingTop: spacings.xs,
    paddingBottom: spacings.sm,
  },
  image: {
    width: 24,
    height: 22,
  },
});

class CustomGiftedChat extends GiftedChat {
  renderMessages() {
    return (
      <Container>
        <MessageContainer
          {...this.props}
          invertibleScrollViewProps={this.invertibleScrollViewProps}
          messages={this.getMessages()}
          // eslint-disable-next-line no-underscore-dangle, no-return-assign
          ref={(component) => this._messageContainerRef = component}
        />
        {this.renderChatFooter()}
      </Container>
    );
  }
}

class PoolChatView extends Component {
  componentDidMount() {
    this.loginHandlerId = chatClient.addLoginHandler(this.onLogin);

    if (!this.props.chatUserId) {
      this.props.getChatCredentials();
    }
  }

  componentWillUnmount() {
    chatClient.removeLoginHandler(this.loginHandlerId);
    chatClient.unsubscribeChatMessages(this.chatMessagesSubscriptionId);
  }

  static getMessageDate(message) {
    return message._updatedAt.$date;
  }

  onLogin = () => {
    const { poolChatId, chatId } = this.props;
    this.chatMessagesSubscriptionId = chatClient.subscribeToChatMessages(chatId, this.onNewMessageListener);
    chatClient.loadChatHistory(chatId, (messages) => {
      chatClient.markChatAsRead(chatId);
      this.props.poolChatLoadMessages(chatId, messages);
      const lastMessage = messages[0];
      if (lastMessage) {
        this.props.poolChatSaveLastMessageRead(poolChatId, chatId, PoolChatView.getMessageDate(lastMessage));
      }
    });
  };

  onNewMessageListener = (message) => {
    const { poolChatId, chatId } = this.props;
    this.props.poolChatNewMessage(chatId, message);
    this.props.poolChatSaveLastMessageRead(poolChatId, chatId, PoolChatView.getMessageDate(message));
  };

  onSend = (messages = []) => {
    const { chatId } = this.props;
    messages.forEach((message) => {
      chatClient.sendMessage(message._id, chatId, message.text);
    });
  };

  getPlaceholderMessage() {
    return {
      _id: 1,
      text: this.props.emptyChatPlaceholder,
      createdAt: new Date(),
      system: true,
    };
  }

  getChatMessages = () => {
    const { chatMessages } = this.props;
    if (chatMessages == null) {
      return [];
    }
    if (!chatMessages.size) {
      return [this.getPlaceholderMessage()];
    }
    return chatMessages.map(this.mapMessage.bind(this)).toArray();
  };

  mapMessage = (message) => {
    const userId = message.get('u').get('_id');
    return {
      _id: message.get('_id'),
      text: message.get('msg'),
      createdAt: message.get('_updatedAt').get('$date'),
      user: {
        _id: userId,
        name: message.get('u').get('name'),
        avatar: this.props.chatUserId === userId ? null : undefined, // TODO add proper avatar for message
      },
    };
  };

  static renderNoMessages({ currentMessage }) {
    return (
      <Wrapper noFlex>
        <PrimaryText>{currentMessage.text}</PrimaryText>
      </Wrapper>
    );
  }

  static renderBubble(props) {
    return (
      <Bubble
        {...props}
        wrapperStyle={{ right: styles.rightBubble }}
      />
    );
  }

  static renderMessageText(props) {
    return (
      <MessageText
        {...props}
        textStyle={{ left: styles.leftMessageStyle, right: styles.rightMessageStyle }}
      />
    );
  }

  static renderInputToolbar(props) {
    return (
      <View noFlex style={styles.chatFooter}>
        {props.renderComposer(props)}
        {props.renderSend(props)}
      </View>
    );
  }

  static renderComposer(props) {
    return (
      <ScrollView>
        <TextInput
          value={props.text}
          onChangeText={(text) => props.onTextChanged(text)}
          multiline
          style={styles.textInput}
          placeholder={props.placeholder}
          placeholderTextColor={colors.lightPeach}
          enablesReturnKeyAutomatically
          underlineColorAndroid="transparent"
          scrollEnabled={false}
          onKeyPress={({ key, shiftKey }) => {
            if (key === 'Enter' && !shiftKey && props.text.trim().length > 0) props.onSend({ text: props.text.trim() }, true);
          }}
        />
      </ScrollView>
    );
  }

  static renderSend(props) {
    return (
      <TouchableOpacity
        style={{ paddingTop: 7 }}
        onPress={() => { if (props.text.trim().length > 0) props.onSend({ text: props.text.trim() }, true); }}
        accessibilityTraits="button"
      >
        <ImageComponent image={props.text.trim().length > 0 ? sendIcon : sendInactiveIcon} style={sendImageStyles} />
      </TouchableOpacity>
    );
  }

  renderSubHeader() {
    const { chatSubheader } = this.props;
    return (
      <Text style={styles.subheader}>{chatSubheader}</Text>
    );
  }

  render() {
    const {
      translate, chatHeader, chatUserId, activeLanguage, withUsernameOnMessage,
    } = this.props;
    return (
      <ViewportController safeAreaBottom style={{ backgroundColor: colors.smokeWhite }}>
        <View style={shadowBottom}>
          <ScreenHeader
            title={chatHeader}
            showBackArrow
            subheaderFunc={() => this.renderSubHeader()}
            containerStyle={styles.headerContainer}
            headerStyle={styles.header}
          />
        </View>
        <CustomGiftedChat
          placeholder={translate('candidateChat.sendInputPlaceholder')}
          locale={activeLanguage.code}
          messages={this.getChatMessages()}
          maxInputLength={400}
          onSend={(messages) => this.onSend(messages)}
          alwaysShowSend
          renderBubble={PoolChatView.renderBubble}
          renderMessageText={PoolChatView.renderMessageText}
          minInputToolbarHeight={49}
          renderAvatarOnTop
          renderInputToolbar={PoolChatView.renderInputToolbar}
          renderComposer={PoolChatView.renderComposer}
          renderSend={PoolChatView.renderSend}
          renderSystemMessage={PoolChatView.renderNoMessages}
          renderUsernameOnMessage={withUsernameOnMessage}
          user={{ _id: chatUserId }}
        />
        {Platform.OS === 'ios' && (<KeyboardAvoidingView behavior="padding" />)}
      </ViewportController>
    );
  }
}

PoolChatView.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  getChatCredentials: PropTypes.func.isRequired,
  poolChatLoadMessages: PropTypes.func.isRequired,
  poolChatNewMessage: PropTypes.func.isRequired,
  poolChatSaveLastMessageRead: PropTypes.func.isRequired,

  chatMessages: PropTypes.instanceOf(List),
  chatId: PropTypes.string.isRequired,
  poolChatId: PropTypes.string.isRequired,
  chatHeader: PropTypes.string.isRequired,
  chatUserId: PropTypes.string,
  chatSubheader: PropTypes.string,
  withUsernameOnMessage: PropTypes.bool,

  emptyChatPlaceholder: PropTypes.string,

  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

PoolChatView.defaultProps = {
  chatMessages: null,
  chatUserId: undefined,
  chatSubheader: '',
  emptyChatPlaceholder: '',
  withUsernameOnMessage: false,
};

const mapStateToProps = (state, props) => ({
  chatMessages: state.poolChat.get('chatMessages').get(props.chatId),
  chatUserId: state.chatAuth.get('chatUserId'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, {
  getChatCredentials,
  poolChatLoadMessages,
  poolChatNewMessage,
  poolChatSaveLastMessageRead,
})(withLocalize(PoolChatView));
