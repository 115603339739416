import { registerRootComponent } from 'expo';
import { activateKeepAwakeAsync } from 'expo-keep-awake';
import Sentry from './src/common/sentry/SentryExpo';
import AppLoader from './src/AppLoader';
// import StoryBookUI from './storybook/config/app/config';
import config from './src/config/config';

Sentry.init({
  dsn: config.sentryDSN,
  enableInExpoDevelopment: true,
});

// const STORY_BOOK_ENABLED = (process.env.STORY_BOOK_ENABLED === 'true');

// eslint-disable-next-line no-undef
// const AppContainer = __DEV__ && STORY_BOOK_ENABLED ? StoryBookUI : AppLoader;
const AppContainer = AppLoader;

// eslint-disable-next-line no-undef
if (__DEV__) {
  activateKeepAwakeAsync();
}

registerRootComponent(AppContainer);
