import axios from 'axios';
import moment from 'moment';
import traverse from 'traverse';
import isDateField from '../../utilities/dateFieldChecker';
import config from '../../config/config';
import axiosHeaders from './axiosHeaders';

function tryToTransformEpochDateToISO(update, key, val) {
  if (typeof val === 'number' && isDateField(key)) {
    const maybeDate = moment(val);
    if (maybeDate.isValid()) {
      update(maybeDate.toISOString());
    }
  }
}

function tryToTransformISODateToEpoch(update, key, val) {
  if (isDateField(key)) {
    const maybeDate = moment(val, moment.ISO_8601);
    if (maybeDate.isValid()) {
      update(maybeDate.valueOf());
    }
  }
}

function transformAxiosRequest(data) {
  if (data) {
    traverse(data).forEach(function transformNode(val) {
      if (!this.isRoot) {
        tryToTransformEpochDateToISO(this.update, this.key, val);
      }
    });
  }

  return data;
}

function transformAxiosResponse(data) {
  if (data) {
    traverse(data).forEach(function transformNode(val) {
      if (!this.isRoot) {
        tryToTransformISODateToEpoch(this.update, this.key, val);
      }
    });
  }

  return data;
}

export const transformRequestTransforms = [transformAxiosRequest].concat(axios.defaults.transformRequest);
const axiosClientConfig = axios.create({
  baseURL: config.baseUrl,
  responseType: 'json',
  headers: {
    common: {
      Accept: 'application/json',
      ...axiosHeaders(),
    },
    post: { 'Content-Type': 'application/json' },
  },
  timeout: 30 * 1000, // ms
  transformRequest: transformRequestTransforms,
  transformResponse: axios.defaults.transformResponse.concat(transformAxiosResponse),
});

export default axiosClientConfig;
