import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';

import Routing from '../../../core/routing/index';
import getProfile, { candidateProfileEditProfileImage, setAvailability } from '../CandidateProfileActions';
import CandidateProfileViewDetailsScreen from './CandidateProfileViewDetailsScreen';
import { candidateProfilePropType } from '../../../constants/propTypes';
import { CANDIDATE_PROFILE_SETTINGS, CANDIDATE_PROFILE_EDIT } from '../../../constants/routes';
import { isProfileNotReady } from '../../../constants/profileCompletenessStatus';
import { showInformationAlert } from '../../../common/components/alert/alerts';

const { withRouter } = Routing;

class CandidateProfileViewDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available: this.props.profile.available,
    };
  }

  componentDidMount() {
    this.props.getProfile();
  }

  onChangeAvailable = (isAvailable) => {
    const { translate, profile } = this.props;
    if (isAvailable && isProfileNotReady(profile)) {
      showInformationAlert(translate, { titleKey: '', textKey: 'candidateProfile.availableForGigs.error', confirmKey: 'generic.close' });
      return;
    }
    this.setState({ available: isAvailable });
    this.props.setAvailability(isAvailable).then((response) => {
      if (response.isError) {
        if (response.errors && response.errors.general === 'profile_incomplete') {
          this.setState({ available: false });
          showInformationAlert(translate, { titleKey: '', textKey: 'candidateProfile.availableForGigs.error', confirmKey: 'generic.close' });
        }
      }
    });
  };

  render() {
    return (
      <CandidateProfileViewDetailsScreen
        profile={this.props.profile}
        onSettingsButton={() => this.props.history.push(CANDIDATE_PROFILE_SETTINGS)}
        onEditProfile={() => this.props.history.push(CANDIDATE_PROFILE_EDIT)}
        onEditProfileImage={this.props.candidateProfileEditProfileImage}
        onChangeAvailable={this.onChangeAvailable}
        available={this.state.available || false}
      />
    );
  }
}

CandidateProfileViewDetailsContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  getProfile: PropTypes.func.isRequired,
  setAvailability: PropTypes.func.isRequired,
  candidateProfileEditProfileImage: PropTypes.func.isRequired,
  profile: candidateProfilePropType,
};

CandidateProfileViewDetailsContainer.defaultProps = {
  profile: undefined,
};

const mapStateToProps = (state) => ({
  profile: state.candidate.get('profile').toJS(),
});

export default connect(mapStateToProps, {
  getProfile, setAvailability, candidateProfileEditProfileImage,
})(withLocalize(withRouter(CandidateProfileViewDetailsContainer)));
