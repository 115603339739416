import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import StaticFormLabel from './form/StaticFormLabel';
import { stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  label: {
    marginBottom: 6,
  },
});

const DisplayField = ({ label, value, style }) => (
  <View style={style}>
    <StaticFormLabel label={label.toString()} size="sm" style={styles.label} />
    <StaticFormLabel label={value.toString()} size="lg" />
  </View>
);

DisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: stylePropTypes,
};
DisplayField.defaultProps = {
  style: undefined,
};

export default DisplayField;
