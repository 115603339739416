import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';
import { colors, fontFamily } from '../../styles/base.style';

const styles = StyleSheet.create({
  counter: {
    width: 19,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 6,
    borderRadius: 4,
  },
  counterActive: {
    backgroundColor: colors.magenta,
  },
  counterNotActive: {
    backgroundColor: colors.lightPeach,
  },
  counterText: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: 10,
    letterSpacing: 0.2,
    textAlign: 'center',
  },
  counterTextActive: {
    color: colors.white,
  },
  counterTextNotActive: {
    color: colors.greyishBrown,
  },
});

const TabCounter = ({ highlighted, value }) => (
  <View style={[styles.counter, highlighted ? styles.counterActive : styles.counterNotActive]}>
    <Text style={[styles.counterText, highlighted ? styles.counterTextActive : styles.counterTextNotActive]}>{value}</Text>
  </View>
);

TabCounter.propTypes = {
  value: PropTypes.number.isRequired,
  highlighted: PropTypes.bool,
};

TabCounter.defaultProps = {
  highlighted: false,
};

export default TabCounter;
