import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Text, View, Image, Platform,
} from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';
import moment from 'moment';
import queryString from 'query-string';

import styles from './FindGigsListItemStyles';
import Header from '../../../../common/components/header/Header';
import GigSalary from '../../GigSalary';
import { fingGigPropType } from '../../../../constants/propTypes';
import GigDetailsImage from '../../../../common/gig/GigDetailsImage';
import ExternalGigImage from './ExternalGigImage';
import { getWorkloadLabel } from '../../../../constants/availabilityTypes';
import { toDate } from '../../../../utilities/dateFormatter';
import BaseListItem from '../../../../common/components/BaseListItem';
import { CANDIDATE_GIG_DETAILS } from '../../../../constants/routes';
import Routing from '../../../../core/routing/index';
import { shareGig } from '../../../../common/share/share';
import IconButton from '../../../../common/components/buttons/IconButton';
import { GIG_DETAILS_ORIGIN } from '../../../../constants/gigDetailsOrigin';
import { PROVIDER_ENUM } from '../../../../constants/providerTypes';

const locationIcon = require('../../../../assets/icons/location/locationIcon.png');
const shareIos = require('../../../../assets/icons/share/shareIos.png');
const shareDefault = require('../../../../assets/icons/share/shareAndroid.png');

const { withRouter } = Routing;

const shareImg = Platform.select({
  android: shareDefault,
  ios: shareIos,
  web: shareDefault,
});

class LetsgigFindGigsListItem extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.gig.lastUpdateDate !== this.props.gig.lastUpdateDate;
  }

  redirectToGigDetails = (gig, history) => {
    const queryStr = queryString.stringify({ origin: GIG_DETAILS_ORIGIN.FIND_GIGS });
    history.push(`${CANDIDATE_GIG_DETAILS.replace(':id', gig.gigId)}?${queryStr}`);
  };

  renderMainContent = (gig, translate) => (
    <>
      <View style={styles.firstLineContainer}>
        <Text style={styles.publishDate}>{moment(gig.publishDate).from(moment())}</Text>
        <IconButton
          style={styles.iconButtonWrapper}
          onPress={() => shareGig(gig.shortId)}
          label={translate('generic.share')}
          source={shareImg}
        />
      </View>
      <Header style={styles.position}>{gig.position}</Header>
      {gig.compensation && <GigSalary compensation={gig.compensation} />}
      {gig.workload && (
        <Text style={styles.weeklyWorkload}>{getWorkloadLabel(gig.workload, translate, true)}</Text>
      )}
      <Text numberOfLines={2} style={styles.description}>{gig.description}</Text>
      {!!gig.companyName && <Text style={styles.companyName}>{gig.companyName}</Text>}
      <View style={styles.locationContainer}>
        <Image style={styles.locationIcon} source={locationIcon} />
        <Text style={styles.location}>
          {gig.location.city}
          {gig.location.street && gig.location.street.trim() ? `, ${gig.location.street}` : ''}
        </Text>
      </View>
      <Text style={styles.expiryDate}>{`${translate('candidate.gigOverview.expiryDate')}: ${toDate(gig.expiryDate)}`}</Text>
    </>
  );

  renderImage = (gig, translate) => {
    if (gig.gigProvider === PROVIDER_ENUM.LETSGIG) {
      return (<GigDetailsImage gig={gig} renderStatusLabel />);
    }
    return (<ExternalGigImage imageUrl={gig.photoUri || gig.imageUrl} gig={gig} translate={translate} />);
  };

  render() {
    const { gig, history, translate } = this.props;

    return (
      <BaseListItem
        hideArrow
        renderMainContent={() => this.renderMainContent(gig, translate)}
        imageSource={() => (this.renderImage(gig, translate))}
        onPress={() => this.redirectToGigDetails(gig, history)}
      />
    );
  }
}

LetsgigFindGigsListItem.propTypes = {
  translate: PropTypes.func.isRequired,
  gig: fingGigPropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(LetsgigFindGigsListItem);
