import { StyleSheet } from 'react-native';

export const checkboxIconSize = 26;

export const checkboxIconStyle = StyleSheet.create({
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    resizeMode: 'contain',
    width: checkboxIconSize,
    height: checkboxIconSize,
  },
  hidden: {
    opacity: 0,
  },
});
