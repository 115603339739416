import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import CandidateProfileFullNameForm from '../../forms/CandidateProfileFullNameForm';
import EditableField from '../../../../common/components/EditableField';
import { candidateProfileSetFullName } from '../../CandidateProfileActions';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';

class EditableFieldFullNameModalAware extends React.Component {
  openModal = () => {
    const {
      translate, closeModal, firstName, lastName,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.fullNameLabel'),

      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileFullNameForm}
        closeModal={closeModal}
        firstName={firstName}
        lastName={lastName}
        handleSubmit={(values) => {
          this.props.candidateProfileSetFullName(values)
            .then((response) => {
              if (!response.isError) {
                closeModal();
              }
            });
        }}
      />,
    );
  };

  render() {
    const { translate, firstName, lastName } = this.props;
    return (
      <EditableField
        label={translate('candidateProfile.details.fullNameLabel')}
        value={`${firstName} ${lastName}`}
        onPress={this.openModal}
      />
    );
  }
}

EditableFieldFullNameModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateProfileSetFullName: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const profile = state.candidate.get('profile');
  return {
    firstName: profile.get('firstName'),
    lastName: profile.get('lastName'),
  };
};

export default connect(mapStateToProps, { candidateProfileSetFullName })(withLocalize(EditableFieldFullNameModalAware));
