import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, Platform } from 'react-native';
import { withLocalize } from 'react-localize-redux';

import GigCreatorHeader from '../../GigCreatorHeader';
import LinkButton from '../../../../common/components/buttons/LinkButton';
import { spacings, colors, wp } from '../../../../common/styles/base.style';
import Container from '../../../../common/components/Container';
import Wrapper from '../../../../common/components/Wrapper';
import Button, { BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import PrimaryText from '../../../../common/components/PrimaryText';
import ImageSelectorComponent from '../../../../common/components/image/ImageSelectorComponent';
import StickyButtonsWrapper from '../../../../common/components/StickyButtonsWrapper';

const ICON_SIZE = 150;
const styles = StyleSheet.create({
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  iconWrapper: {
    paddingBottom: spacings.xl,
  },
  photo: {
    width: wp(100),
    height: 200,
  },
  photoWrapper: {
    paddingBottom: spacings.xl,
  },
  wrapper: {
    alignItems: 'center',
  },
});

const gallery = require('../../../../assets/icons/photo/gallery.png');
const camera = require('../../../../assets/icons/photo/camera.png');

const TARGET_WIDTH = 3000;
const SPINNER_COLOR = colors.magenta;
const TARGET_ASPECT = [16, 9];

const GigCreatorStepPhotoScreen = ({
  translate, photoUri, handlePhotoUpload, uploadPhotoAction, handleSubmit, handleSkip, stepNumber, nextScreen, redirect, imageValidator, isEdit, maxStepsNumber,
}) => (
  <Container>
    <GigCreatorHeader
      isEdit={isEdit}
      stepNo={stepNumber}
      maxStepsNumber={maxStepsNumber}
      title={translate('companyGigCreator.photo.header')}
      optional
      onRightButtonPress={handleSubmit}
    />
    <ScrollView contentContainerStyle={styles.wrapper}>
      <Wrapper style={{ marginTop: spacings.lg }}>
        <PrimaryText>{translate('companyGigCreator.photo.text')}</PrimaryText>
      </Wrapper>
      {!photoUri && Platform.OS !== 'web' && (
        <ImageSelectorComponent
          style={{ wrapper: styles.iconWrapper, image: styles.icon }}
          uri={photoUri}
          showEditIcon={false}
          defaultImage={gallery}
          uploadImageAction={uploadPhotoAction}
          uploadHandler={handlePhotoUpload}
          imageValidator={imageValidator}
          resizedWidth={TARGET_WIDTH}
          spinnerColor={SPINNER_COLOR}
          aspect={TARGET_ASPECT}
          actionSheetMessage={translate('companyGigCreator.photo.actionSheetMessage')}
        />
      )}

      {photoUri && (
        <ImageSelectorComponent
          style={{ wrapper: styles.photoWrapper, image: styles.photo }}
          uri={photoUri}
          defaultImage={camera}
          uploadImageAction={uploadPhotoAction}
          uploadHandler={handlePhotoUpload}
          imageValidator={imageValidator}
          resizedWidth={TARGET_WIDTH}
          spinnerColor={SPINNER_COLOR}
          aspect={TARGET_ASPECT}
          actionSheetMessage={translate('companyGigCreator.photo.actionSheetMessage')}
        />
      )}

      {isEdit ? (
        <Button
          title={translate('companyGigCreator.photo.predefinedButton')}
          onPress={handleSkip}
        />
      ) : (
        <LinkButton
          type={BUTTON_TYPES.SECONDARY}
          to={nextScreen}
          title={translate('companyGigCreator.photo.predefinedButton')}
          doRedirect={redirect}
          onPress={handleSkip}
        />
      )}
    </ScrollView>
    {photoUri && (
      <StickyButtonsWrapper>
        {isEdit ? (
          <Button
            title={translate('generic.save')}
            onPress={handleSubmit}
          />
        ) : (
          <LinkButton
            to={nextScreen}
            doRedirect={redirect}
            onPress={handleSubmit}
            title={translate('generic.next')}
          />
        )}
      </StickyButtonsWrapper>
    )}
  </Container>
);

GigCreatorStepPhotoScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  photoUri: PropTypes.string,
  uploadPhotoAction: PropTypes.func.isRequired,
  handlePhotoUpload: PropTypes.func.isRequired,
  imageValidator: PropTypes.func.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

GigCreatorStepPhotoScreen.defaultProps = {
  photoUri: undefined,
};

export default withLocalize(GigCreatorStepPhotoScreen);
