import { fromJS, Map } from 'immutable';
import {
  COMPANY_GIG_CANDIDATE_PROFILE_REQUEST_SUCCESS,
  COMPANY_GIG_CLEAR_CANDIDATES,
  COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_GET_CANDIDATES_REQUEST_SUCCESS,
  COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST_SUCCESS,
  COMPANY_GIG_HIRE_BY_OWN_REQUEST_SUCCESS,
  COMPANY_GIG_REJECT_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST_SUCCESS,
  COMPANY_GIG_STORE_SCROLL_POSITION,
} from '../../../../constants/actionTypes';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';

export const SUGGESTED_LIST_KEY = 'suggested';
export const SHORTLISTED_LIST_KEY = 'shortlisted';
export const MATCHED_LIST_KEY = 'matched';
export const HISTORY_LIST_KEY = 'history';
export const APPLIED_LIST_KEY = 'applied';
export const OFFERS_LIST_KEY = 'offers';
export const HIRED_LIST_KEY = 'hired';

export const companyGetListKeyForStatus = (status) => {
  switch (status) {
    case GIG_CANDIDATE_STATUS.SUGGESTED:
      return SUGGESTED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.APPLIED:
      return APPLIED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL:
      return SHORTLISTED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.SHORTLISTED:
      return MATCHED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER:
    case GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED:
      return OFFERS_LIST_KEY;
    case GIG_CANDIDATE_STATUS.HIRED:
      return HIRED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.REJECTED_BY_CANDIDATE:
    case GIG_CANDIDATE_STATUS.REJECTED_BY_COMPANY:
      return HISTORY_LIST_KEY;
    default:
      return null;
  }
};

export const companyGetPreviousGigCandidateStatuses = (status) => {
  switch (status) {
    case GIG_CANDIDATE_STATUS.APPLIED:
      return [GIG_CANDIDATE_STATUS.SUGGESTED];
    case GIG_CANDIDATE_STATUS.SHORTLISTED:
      return [GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL];
    case GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED:
      return [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER];
    case GIG_CANDIDATE_STATUS.HIRED:
      return [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER, GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED];
    default:
      return undefined;
  }
};

const INITIAL_STATE = {
  [SUGGESTED_LIST_KEY]: null,
  [SHORTLISTED_LIST_KEY]: null,
  [MATCHED_LIST_KEY]: null,
  [HISTORY_LIST_KEY]: null,
  [APPLIED_LIST_KEY]: null,
  [OFFERS_LIST_KEY]: null,
  [HIRED_LIST_KEY]: null,
  profiles: Map(),
  companyMyGigsScrollPosition: 0,
};

function lastTransitionDateComparator(a, b) {
  return b.get('matching').get('lastTransitionDate') - a.get('matching').get('lastTransitionDate');
}

const listToComparatorMap = {
  [SHORTLISTED_LIST_KEY]: lastTransitionDateComparator,
  [MATCHED_LIST_KEY]: lastTransitionDateComparator,
  [HIRED_LIST_KEY]: lastTransitionDateComparator,
  [HISTORY_LIST_KEY]: lastTransitionDateComparator,
};

function getCandidates(candidates, listKey) {
  const candidatesComparator = listToComparatorMap[listKey];
  if (candidatesComparator) {
    return candidates.sort(candidatesComparator);
  }
  return candidates;
}

function clearList(state, listName) {
  return state.set(listName, null);
}

function setCurrentDateTime(state, listKey, candidateId) {
  return state.set(listKey, state.get(listKey)
    .map((candidate) => (candidate.get('userId') === candidateId
      ? candidate.setIn(['matching', 'lastTransitionDate'], new Date().getTime()) : candidate)));
}

function setListButSkipCandidateWithId(state, listKey, candidateId) {
  const currentList = state.get(listKey);
  if (!currentList) {
    return state;
  }
  const listWithoutCandidate = currentList.filterNot((candidate) => candidate.get('userId') === candidateId);
  return state.set(listKey, listWithoutCandidate);
}

export default function companyGigCandidatesReducer(state = new Map(INITIAL_STATE), action) {
  switch (action.type) {
    case COMPANY_GIG_GET_CANDIDATES_REQUEST_SUCCESS:
      return state.set(action.meta.previousAction.listKey, getCandidates(fromJS(action.payload), action.meta.previousAction.listKey));
    case COMPANY_GIG_CLEAR_CANDIDATES:
      return clearList(state, action.listKey);
    case COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST_SUCCESS:
      return setListButSkipCandidateWithId(state, action.meta.previousAction.listKey, action.meta.previousAction.candidateId);
    case COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST_SUCCESS: {
      const { listKey, candidateId } = action.meta.previousAction;
      if (listKey === OFFERS_LIST_KEY) {
        return setCurrentDateTime(state, OFFERS_LIST_KEY, candidateId);
      }
      return setListButSkipCandidateWithId(state, listKey, candidateId);
    }
    case COMPANY_GIG_HIRE_BY_OWN_REQUEST_SUCCESS:
      return setListButSkipCandidateWithId(state, OFFERS_LIST_KEY, action.meta.previousAction.candidateId);
    case COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST_SUCCESS:
      return setListButSkipCandidateWithId(state, OFFERS_LIST_KEY, action.meta.previousAction.candidateId);
    case COMPANY_GIG_REJECT_CANDIDATE_REQUEST_SUCCESS:
      return setListButSkipCandidateWithId(state, action.meta.previousAction.listKey, action.meta.previousAction.candidateId);
    case COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST_SUCCESS:
      return setListButSkipCandidateWithId(state, SUGGESTED_LIST_KEY, action.meta.previousAction.candidateId);
    case COMPANY_GIG_CANDIDATE_PROFILE_REQUEST_SUCCESS:
      return state.setIn(['profiles', action.payload.userId], action.payload);
    case COMPANY_GIG_STORE_SCROLL_POSITION:
      return state.set('companyMyGigsScrollPosition', action.scrollPosition);
    default:
      return state;
  }
}
