import { COMPANY_SIGNUP_REQUEST, COMPANY_AUTOCREATE_SIGNUP_REQUEST } from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export function signupCompanyNew(contactData) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.AUTH.SIGN_UP);
  return {
    type: COMPANY_SIGNUP_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/auth/company/new/signup',
        data: contactData,
      },
    },
  };
}

export function signupCompany(contactData) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.AUTH.SIGN_UP);
  return {
    type: COMPANY_SIGNUP_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/auth/company/askForContact',
        data: contactData,
      },
    },
  };
}

export function autoCreateSignUpCompany(autoCreateId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.AUTH.AUTO_CREATE_SIGN_UP);
  return {
    type: COMPANY_AUTOCREATE_SIGNUP_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/auth/company/autocreate/${autoCreateId}`,
      },
    },
  };
}
