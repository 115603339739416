import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import {
  clearGigCandidates, suggestCandidates, getGigCandidates, storeMyGigsScrollPosition,
} from '../companyGigCandidatesActions';
import { SUGGESTED_LIST_KEY, APPLIED_LIST_KEY } from '../companyGigCandidatesReducer';
import Routing from '../../../../../core/routing/index';
import candidatesListStyles from '../components/GigCandidatesListStyle';
import PrimarySectionList from '../../../../../common/components/PrimarySectionList';
import { GIG_CANDIDATE_STATUS } from '../../../../../constants/matchingStatus';
import { gigCandidatePropType } from '../../../../../constants/propTypes';
import CompanyGigCandidateListItem from '../CompanyGigCandidateListItem';
import ListItem from '../../../../../common/components/ListItem';
import Container from '../../../../../common/components/Container';
import { removeGigCandidateStatusIndicator } from '../../../companyGigActions';
import { TAB_NAMES } from '../../../../../constants/gigCandidateStatusConfig';
import CandidateQuestionnaireModalView from '../questionnaireModal/CandidateQuestionnaireModalView';

const { APPLIED } = GIG_CANDIDATE_STATUS;
const { withRouter } = Routing;

class CompanyGigCandidatesToReviewView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaireModalVisible: false,
      modalQuestionnaire: undefined,
      noMoreSuggested: false,
    };
  }

  componentDidMount() {
    this.scrollPosition = 0;
    Promise.all([
      this.props.getGigCandidates(this.props.match.params.id, [APPLIED], APPLIED_LIST_KEY),
      this.props.suggestCandidates(this.props.match.params.id, SUGGESTED_LIST_KEY),
    ]).then((results) => {
      const suggestedResults = results[1];
      if (suggestedResults.payload && suggestedResults.payload.length === 0) {
        this.setState({ noMoreSuggested: true });
      }
      if (this.props.history.action === 'POP') {
        const { storedScrollPosition } = this.props;
        if (storedScrollPosition) {
          this.scrollToLastKnownPosition(storedScrollPosition);
        }
      } else {
        this.props.storeMyGigsScrollPosition(this.scrollPosition);
      }
      this.setTabInactive();
    });
  }

  componentDidUpdate(prevProps) {
    const shouldRefreshSuggestedList = prevProps.suggestedCandidates
      && prevProps.suggestedCandidates.length > 0
      && this.props.suggestedCandidates
      && this.props.suggestedCandidates.length === 0
      && !this.state.noMoreSuggested;

    if (shouldRefreshSuggestedList) {
      this.props.suggestCandidates(this.props.match.params.id, SUGGESTED_LIST_KEY).then((suggestedResults) => {
        if (suggestedResults.payload && suggestedResults.payload.length === 0) {
          this.setState({ noMoreSuggested: true });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.storeMyGigsScrollPosition(this.scrollPosition);
    this.props.clearGigCandidates(SUGGESTED_LIST_KEY);
    this.props.clearGigCandidates(APPLIED_LIST_KEY);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  setTabInactive = () => {
    if (this.isNewContentInTab()) {
      this.props.removeGigCandidateStatusIndicator(this.props.match.params.id, TAB_NAMES.REVIEW);
    }
  };

  isNewContentInTab = () => !!this.props.newContentIndicators
    .find(({ gigId, gigCandidateStatus }) => gigId === this.props.match.params.id && gigCandidateStatus === TAB_NAMES.REVIEW);

  openQuestionnaireModal = (questionnaire) => {
    this.setState({ questionnaireModalVisible: true, modalQuestionnaire: questionnaire });
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToLocation({
      sectionIndex: 0,
      animated: true,
      itemIndex: 0,
      viewOffset: -scrollPosition + 7,
      viewPosition: 0,
    }), 0);
  }

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, modalQuestionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalView
        visible={questionnaireModalVisible}
        closeModal={this.closeQuestionnaireModal}
        questionnaire={modalQuestionnaire}
      />
    );
  }

  render() {
    const { appliedCandidates, suggestedCandidates, translate } = this.props;
    return (
      <Container>
        <PrimarySectionList
          getItemLayout={(data, index) => ({ length: 275, offset: index * 275, index })} // fix for not scrolling over ~1300 pixels
          emptyListTextKey="companyGigs.candidates.noMatchingCandidates"
          contentContainerStyle={candidatesListStyles.contentContainerStyle}
          sections={[
            { title: translate('companyGigs.candidates.applied'), data: appliedCandidates || [], dataLoading: !appliedCandidates },
            { title: translate('companyGigs.candidates.suggested'), data: suggestedCandidates || [], dataLoading: !suggestedCandidates },
          ]}
          onScroll={this.onScroll}
          innerRef={this.setListRef}
          keyExtractor={(item) => item.userId}
          renderItem={({ item: candidate }) => (
            <ListItem
              renderContent={() => (
                <CompanyGigCandidateListItem
                  candidate={candidate}
                  gigId={this.props.match.params.id}
                  online={this.props.usersPresence[candidate.userId]}
                  internalFunctions={{
                    showQuestionnaire: () => this.openQuestionnaireModal(candidate.matching.questionnaire),
                  }}
                />
              )}
            />
          )}
        />
        {this.renderQuestionnaireModal()}
      </Container>
    );
  }
}

CompanyGigCandidatesToReviewView.propTypes = {
  translate: PropTypes.func.isRequired,
  suggestCandidates: PropTypes.func.isRequired,
  getGigCandidates: PropTypes.func.isRequired,
  clearGigCandidates: PropTypes.func.isRequired,
  suggestedCandidates: PropTypes.arrayOf(gigCandidatePropType),
  appliedCandidates: PropTypes.arrayOf(gigCandidatePropType),
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.shape({
    gigId: PropTypes.string.isRequired,
    gigCandidateStatus: PropTypes.string.isRequired,
  })).isRequired,
  removeGigCandidateStatusIndicator: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  storedScrollPosition: PropTypes.number.isRequired,
  storeMyGigsScrollPosition: PropTypes.func.isRequired,
};

CompanyGigCandidatesToReviewView.defaultProps = {
  suggestedCandidates: undefined,
  appliedCandidates: undefined,
};

const mapStateToProps = (state) => ({
  suggestedCandidates: state.gigCandidates.get(SUGGESTED_LIST_KEY) ? state.gigCandidates.get(SUGGESTED_LIST_KEY).toJS() : undefined,
  appliedCandidates: state.gigCandidates.get(APPLIED_LIST_KEY) ? state.gigCandidates.get(APPLIED_LIST_KEY).toJS() : undefined,
  storedScrollPosition: state.gigCandidates.get('companyMyGigsScrollPosition'),
  newContentIndicators: state.companyGig.get('gigsList').get('newContentIndicators').toJS(),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default withRouter(connect(mapStateToProps, {
  suggestCandidates,
  clearGigCandidates,
  getGigCandidates,
  removeGigCandidateStatusIndicator,
  storeMyGigsScrollPosition,
})(withLocalize(CompanyGigCandidatesToReviewView)));
