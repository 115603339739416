import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, Image, TouchableOpacity, Platform,
} from 'react-native';
import Routing from '../../../core/routing/index';
import { spacings } from '../../styles/base.style';
import ViewportController from '../ViewportController';

const { withRouter } = Routing;

const darkLeftArrowIcon = require('../../../assets/icons/arrows/iconsArrowLeft.png');
const whiteLeftArrowIcon = require('../../../assets/icons/arrows/arrowLeftWhite.png');

export const themes = { DARK: 'DARK', LIGHT: 'LIGHT' };

const styles = StyleSheet.create({
  wrapper: {
    zIndex: 100,
    paddingLeft: spacings.sm,
    paddingRight: spacings.sm,
    ...Platform.select({
      web: {
        paddingLeft: spacings.xl,
        paddingRight: spacings.md,
        paddingTop: spacings.xl,
      },
    }),
  },
  back: {
    height: 18,
    width: 11,
  },
  imageNoSafeArea: {
    paddingTop: 18,
  },
});

class BackButton extends Component {
  defaultOnPressBehaviour = () => {
    this.props.history.goBack();
  };

  render() {
    const { onPress, theme, noSafeArea } = this.props;
    return (
      <TouchableOpacity
        style={styles.wrapper}
        onPress={onPress || this.defaultOnPressBehaviour}
      >
        <ViewportController safeAreaTop={!noSafeArea} noFlex style={noSafeArea && Platform.OS !== 'web' ? styles.imageNoSafeArea : null}>
          <Image style={styles.back} source={theme === themes.DARK ? darkLeftArrowIcon : whiteLeftArrowIcon} resizeMode="contain" />
        </ViewportController>
      </TouchableOpacity>
    );
  }
}

BackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  onPress: PropTypes.func,
  theme: PropTypes.oneOf(Object.values(themes)),
  noSafeArea: PropTypes.bool,
};

BackButton.defaultProps = {
  onPress: undefined,
  theme: themes.DARK,
  noSafeArea: false,
};

export default (withRouter(BackButton));
