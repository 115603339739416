import {
  ADMIN_SETTINGS_GET_FORTNOX_CANDIDATES_REQUEST,
} from '../../constants/actionTypes';

export function getFortnoxCandidates() {
  return {
    type: ADMIN_SETTINGS_GET_FORTNOX_CANDIDATES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/fortnox',
      },
    },
  };
}
