import queryString from 'query-string';
import {
  ADMIN_COMPANIES_SIGN_UP_COMPANY_REQUEST,
  ADMIN_COMPANY_FIND_FILTER_FORM_SUBMIT,
  ADMIN_COMPANY_FIND_COMPANY_REQUEST, ADMIN_COMPANY_DETAILS_REQUEST, ADMIN_COMPANY_DETAILS_CLEAR,
} from '../../constants/actionTypes';

export const COMPANY_LIMIT = 10;

export function adminSignUpCompany(companyData) {
  console.log(`adminSignUpCompanyadminSignUpCompany11${companyData}`);
  return {
    type: ADMIN_COMPANIES_SIGN_UP_COMPANY_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'admin/company/signUp',
        data: companyData,
      },
    },
  };
}

export function adminFindCompanyChangeProviderCompanyIdSearch(formValues) {
  return {
    type: ADMIN_COMPANY_FIND_FILTER_FORM_SUBMIT,
    formValues,
  };
}

export function adminFindCompany(params) {
  return {
    type: ADMIN_COMPANY_FIND_COMPANY_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/company',
        params: {
          offset: 0,
          limit: COMPANY_LIMIT,
          ...params,
        },
        paramsSerializer: (p) => queryString.stringify(p),
      },
    },
  };
}

export function adminCompanyDetails(gigId) {
  return {
    type: ADMIN_COMPANY_DETAILS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `admin/gig/${gigId}`,
      },
    },
  };
}

export function adminClearGigDetails() {
  return {
    type: ADMIN_COMPANY_DETAILS_CLEAR,
  };
}
