import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { registerInstallation } from '../user/userActions';
import { getInstallationId, getUserDeviceDetails } from './deviceDetailsUtil';

class InstallationUpdater extends PureComponent {
  constructor(props) {
    super(props);
    this.callInProgress = false;
  }

  componentDidUpdate() {
    if (this.props.authenticated && !this.callInProgress) {
      this.callInProgress = true;
      this.props.registerInstallation(getInstallationId(), getUserDeviceDetails())
        .then(() => { this.callInProgress = false; }, () => { this.callInProgress = false; });
    }
  }

  render() {
    return null;
  }
}

InstallationUpdater.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  registerInstallation: PropTypes.func.isRequired,
};

InstallationUpdater.defaultProps = {
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
});

export default connect(mapStateToProps, { registerInstallation })(InstallationUpdater);
