import React, { Component } from 'react';
import {
  Image, StyleSheet, Text, View, Animated,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GIG_EXPERIENCE } from '../../constants/experienceTypes';
import { colors, fontFamily, fontSizes } from '../styles/base.style';
import { gigCategoryPropType, gigPropType, stylePropTypes } from '../../constants/propTypes';
import { getImageSourceForGig } from '../components/image/imageHelper';
import { findCategoryByKey, sortCategoriesByOrder } from './gigCategoriesHelper';
import { getAvailableGigCategories } from './gigCategoriesActions';
import ImageComponent from '../components/image/ImageComponent';
import { GIG_CANDIDATE_STATUS } from '../../constants/matchingStatus';
import { font } from '../styles/mixins';

const {
  PENDING_SHORTLIST_APPROVAL,
  SHORTLISTED,
  APPLIED,
  REJECTED_BY_CANDIDATE,
} = GIG_CANDIDATE_STATUS;
const tickIcon = require('../../assets/icons/tick.png');
const warningIcon = require('../../assets/icons/warningGreen.png');

const styles = StyleSheet.create({
  badgeWrapper: {
    position: 'absolute',
    top: 100,
    right: 0,
    width: 140,
  },
  badgeText: {
    fontSize: fontSizes.xs,
    color: colors.white,
    fontFamily: fontFamily.SFProDisplayBold,
    paddingVertical: 5,
    marginVertical: 3,
    paddingHorizontal: 10,
    backgroundColor: colors.tealish,
  },
  statusWrapper: {
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomRightRadius: 17,
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
  statusText: {
    fontSize: fontSizes.xs,
    fontFamily: fontFamily.SFProDisplayBold,
    paddingVertical: 5,
    marginRight: 10,
    marginLeft: 10,
    textAlign: 'right',
    color: colors.greyishBrown,
  },
  lessRightMarginForIcon: {
    marginRight: 5,
  },
  bottomLabelWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    height: 20,
    justifyContent: 'center',
  },
  bottomLabelWrapperFavourite: {
    backgroundColor: colors.lipstick,
  },
  bottomLabelWrapperNotInteresting: {
    backgroundColor: colors.gondola,
  },
  bottomLabel: {
    textAlign: 'center',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xs, 0, colors.white),
  },
  image: {
    height: 200,
    width: '100%',
    position: 'relative',
  },
});

const iconStyles = StyleSheet.create({
  image: {
    width: 16,
    height: 16,
  },
  wrapper: {
    marginRight: 10,
  },
});

const renderText = (text, icon, backgroundColor = colors.white) => (
  <View style={[styles.statusWrapper, { backgroundColor }]}>
    <Text style={[styles.statusText, icon && styles.lessRightMarginForIcon]}>{text}</Text>
    {icon && <ImageComponent image={icon} style={iconStyles} />}
  </View>
);

const renderStatus = (translate, status) => {
  switch (status) {
    case PENDING_SHORTLIST_APPROVAL:
      return renderText(translate('statuses.candidatePendingShortlisting'), warningIcon, colors.gold);
    case SHORTLISTED:
      return renderText(translate('statuses.shortlisted'), tickIcon);
    case APPLIED:
      return renderText(translate('statuses.applied'), tickIcon);
    case REJECTED_BY_CANDIDATE:
      return renderText(translate('statuses.rejected'));
    default:
      return renderText(status);
  }
};

const FAV_LABEL_ANIMATION_MS = 400;

class GigDetailsImage extends Component {
  constructor(props) {
    super(props);
    this.favouriteLabelOpacity = new Animated.Value(props.isFavourite ? 1 : 0);
    this.notInterestingLabelOpacity = new Animated.Value(props.isNotInteresting ? 1 : 0);
  }
  componentDidMount() {
    this.props.getAvailableGigCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFavourite === false && this.props.isFavourite === true) {
      Animated.parallel([
        this.animateFavouriteLabel(1),
        this.animateNotInterestingLabel(0),
      ], { stopTogether: false }).start();
    } else if (prevProps.isNotInteresting === false && this.props.isNotInteresting === true) {
      Animated.parallel([
        this.animateFavouriteLabel(0),
        this.animateNotInterestingLabel(1),
      ], { stopTogether: false }).start();
    }
  }

  animateFavouriteLabel(toValue) {
    return Animated.timing(this.favouriteLabelOpacity, {
      toValue,
      duration: FAV_LABEL_ANIMATION_MS,
      useNativeDriver: true,
    });
  }

  animateNotInterestingLabel(toValue) {
    return Animated.timing(this.notInterestingLabelOpacity, {
      toValue,
      duration: FAV_LABEL_ANIMATION_MS,
      useNativeDriver: true,
    });
  }

  renderImage() {
    const { gig, availableCategories, gigImageStyles } = this.props;
    if (!availableCategories.length) {
      return null;
    }

    return (
      <Image
        source={
        getImageSourceForGig(gig.photoUri || findCategoryByKey(availableCategories, sortCategoriesByOrder(gig.categories, availableCategories)[0]).image)
      }
        style={[styles.image, gigImageStyles]}
      />
    );
  }

  render() {
    const {
      gig,
      availableCategories,
      translate,
      renderStatusLabel,
    } = this.props;
    return (
      <View>
        {this.renderImage(gig, availableCategories)}
        {gig.matchingStatus && renderStatusLabel ? renderStatus(translate, gig.matchingStatus) : null}
        <View style={styles.badgeWrapper}>
          {gig.experience === GIG_EXPERIENCE.NO_EXPERIENCE_NEEDED
          && <Text style={[styles.badgeText, { backgroundColor: colors.tealish }]}>{translate('gigPreview.noExperience')}</Text>}
          {gig.noStartDate
          && <Text style={[styles.badgeText, { backgroundColor: colors.brightOrange }]}>{translate('gigPreview.immediateStart')}</Text>}
        </View>
        <Animated.View style={[styles.bottomLabelWrapper, styles.bottomLabelWrapperFavourite, { opacity: this.favouriteLabelOpacity }]}>
          <Text style={styles.bottomLabel}>{translate('gigPreview.favouriteLabel')}</Text>
        </Animated.View>
        <Animated.View style={[styles.bottomLabelWrapper, styles.bottomLabelWrapperNotInteresting, { opacity: this.notInterestingLabelOpacity }]}>
          <Text style={styles.bottomLabel}>{translate('gigPreview.notInterestingLabel')}</Text>
        </Animated.View>
      </View>
    );
  }
}

GigDetailsImage.propTypes = {
  translate: PropTypes.func.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  gig: gigPropType.isRequired,
  renderStatusLabel: PropTypes.bool,
  gigImageStyles: stylePropTypes,
  isFavourite: PropTypes.bool,
  isNotInteresting: PropTypes.bool,
};

GigDetailsImage.defaultProps = {
  renderStatusLabel: false,
  gigImageStyles: null,
  isFavourite: false,
  isNotInteresting: false,
};

const mapStateToProps = (state) => ({
  availableCategories: state.gigCategories.toJS(),
});

export const gigImageStyle = styles;
export default connect(mapStateToProps, { getAvailableGigCategories })(withLocalize(GigDetailsImage));
