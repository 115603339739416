import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import SignupScreen from '../../common/screens/SignupScreen';
import CompanySignupValidation from './CompanySignupValidation';
import { signupCompanyNew } from './companySignupActions';

import { COMPANY_USER } from '../../constants/userScopes';
import { COMPANY_WELCOME } from '../../constants/routes';
import { resetSignUpSuccess } from '../../common/auth/authActions';

class CompanySignupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsModalVisible: false,
      privacyModalVisible: false,
      signUpSuccess: false,
    };
  }

  onSubmit = (values, { setErrors, setSubmitting }) => {
    if (values.city && !values.association) {
      alert('Välj en förening');
      setSubmitting(false);
      return;
    }
    this.props.signupCompanyNew({ ...values, locale: this.props.locale }).then((response) => {
      if (response.isError) {
        setErrors(response.errors);
        setSubmitting(false);
        return;
      }
      this.onSignUpSuccess();
    });
  };

  onOpenPrivacy = () => {
    this.setState({ privacyModalVisible: true });
  };
  onOpenTerms = () => {
    this.setState({ termsModalVisible: true });
  };
  handleCloseModal = () => {
    this.setState({ privacyModalVisible: false });
    this.setState({ termsModalVisible: false });
  };

  handleCloseSignup = () => {
    this.setState({ signUpSuccess: false });
    this.props.resetSignUpSuccess();
    // this.props.history.goBack();
    this.props.history.replace(COMPANY_WELCOME);
  };

  onSignUpSuccess = () => {
    this.setState({ signUpSuccess: true });
  };

  render() {
    return (
      <SignupScreen
        userType={COMPANY_USER}
        redirectPath={COMPANY_WELCOME}
        validation={CompanySignupValidation}
        onSubmit={this.onSubmit}
        redirect={false} // prevent redirect upon signup success
        signUpSuccess={this.state.signUpSuccess}
        onOpenPrivacy={this.onOpenPrivacy}
        onOpenTerms={this.onOpenTerms}
        handleCloseModal={this.handleCloseModal}
        termsModalVisible={this.state.termsModalVisible}
        privacyModalVisible={this.state.privacyModalVisible}
        handleCloseSignup={this.handleCloseSignup}
      />
    );
  }
}

CompanySignupContainer.propTypes = {
  signupCompanyNew: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  resetSignUpSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.locale.get('locale'),
});

export default connect(mapStateToProps, { signupCompanyNew, resetSignUpSuccess })(CompanySignupContainer);
