import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import BaseListItem from '../../common/components/BaseListItem';
import { COMPANY_CHAT_GIG_CANDIDATES_LIST } from '../../constants/routes';
import PrimaryFlatList from '../../common/components/PrimaryFlatList';
import CompanyChatGigListItem from './CompanyChatGigListItem';
import ChatListItemBadge from './ChatListItemBadge';
import Routing from '../../core/routing/index';
import { chatGigItemPropType } from '../../constants/propTypes';
import ChatUnavailableMessage from '../../common/chat/ChatUnavailableMessage';

const { withRouter } = Routing;

class CompanyChatGigList extends Component {
  onPress = (gigId) => {
    this.props.history.push(COMPANY_CHAT_GIG_CANDIDATES_LIST.replace(':id', gigId));
  };

  render() {
    const {
      translate, gigs, error, refreshing, onRefresh,
    } = this.props;

    if (error) {
      return <ChatUnavailableMessage />;
    }

    return (
      <PrimaryFlatList
        emptyListTextKey="companyChat.noChats"
        data={gigs}
        refreshing={refreshing}
        onRefresh={onRefresh}
        keyExtractor={(gig) => gig.gigId}
        renderItem={({ item: gig }) => (
          <BaseListItem
            onPress={() => this.onPress(gig.gigId)}
            renderMainContent={() => <CompanyChatGigListItem key={gig.gigId} {...gig} translate={translate} />}
            renderFooter={() => <ChatListItemBadge candidateChats={gig.candidateChats} />}
            key={gig.gigId}
          />
        )}
      />
    );
  }
}

CompanyChatGigList.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  gigs: PropTypes.arrayOf(chatGigItemPropType).isRequired,
  error: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default withRouter(withLocalize(CompanyChatGigList));
