import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { COMPANY_USER } from '../../constants/userScopes';
import {
  COMPANY_CHAT, COMPANY_CHAT_GIG_CANDIDATES_LIST, COMPANY_CHAT_GIG_LIST, COMPANY_GIG_CHAT,
} from '../../constants/routes';
import CompanyChatGigListContainer from './CompanyChatGigListContainer';
import CompanyChatGigCandidatesContainer from './CompanyChatGigCandidatesContainer';
import CompanyGigChatContainer from './CompanyGigChatContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = COMPANY_CHAT_GIG_LIST;

const CompanyChatRouting = () => (
  <>
    <Route exact path={COMPANY_CHAT} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_CHAT_GIG_CANDIDATES_LIST} component={CompanyChatGigCandidatesContainer} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_GIG_CHAT} component={CompanyGigChatContainer} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CHAT_GIG_LIST} component={CompanyChatGigListContainer} />
  </>
);

export default CompanyChatRouting;
