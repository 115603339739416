import {
  COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST,
  COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST,
  COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST,
  COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST,
} from '../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';
import { POOL_CHAT_TYPE } from '../../../common/chat/pool/poolChatReducerHelper';

export function getCompanyPoolGroupChatsInfo(handleInternalError = true) {
  return {
    type: COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/candidatesPool/group/chat',
      },
    },
    handleInternalError,
    chatType: POOL_CHAT_TYPE.GROUP,
  };
}

export function createOrGetCompanyPoolGroupChat(groupId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.CHAT.OPEN_GROUP_CHAT);
  return {
    type: COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/company/candidatesPool/group/${groupId}/chat`,
        data: { groupId },
      },
    },
    poolChatId: groupId,
    chatType: POOL_CHAT_TYPE.GROUP,
  };
}

export function getCompanyPoolDirectChatsInfo(handleInternalError = true) {
  return {
    type: COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/candidatesPool/chat',
      },
    },
    handleInternalError,
    chatType: POOL_CHAT_TYPE.DIRECT,
  };
}

export function createOrGetCompanyPoolDirectChat(poolCandidateId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.CHAT.OPEN_DIRECT_CHAT);
  return {
    type: COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/company/candidatesPool/${poolCandidateId}/chat`,
        data: { poolCandidateId },
      },
    },
    poolChatId: poolCandidateId,
    chatType: POOL_CHAT_TYPE.DIRECT,
  };
}
