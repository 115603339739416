import {
  COMPANY_CREATE_GIG_AVAILABILITY,
  COMPANY_CREATE_GIG_CATEGORY,
  COMPANY_CREATE_GIG_COMPENSATION,
  COMPANY_CREATE_GIG_DATES,
  COMPANY_CREATE_GIG_DESCRIPTION,
  COMPANY_CREATE_GIG_EXPERIENCE,
  COMPANY_CREATE_GIG_INFO,
  COMPANY_CREATE_GIG_LANGUAGE,
  COMPANY_CREATE_GIG_QUESTIONNAIRE,
  COMPANY_CREATE_GIG_LOCATION,
  COMPANY_CREATE_GIG_OCCUPATION,
  COMPANY_CREATE_GIG_PHOTO,
  COMPANY_GIG_EDIT_AVAILABILITY,
  COMPANY_GIG_EDIT_CATEGORY,
  COMPANY_GIG_EDIT_COMPENSATION,
  COMPANY_GIG_EDIT_DATES,
  COMPANY_GIG_EDIT_DESCRIPTION,
  COMPANY_GIG_EDIT_EXPERIENCE,
  COMPANY_GIG_EDIT_INFO,
  COMPANY_GIG_EDIT_LANGUAGE,
  COMPANY_GIG_EDIT_LOCATION,
  COMPANY_GIG_EDIT_OCCUPATION,
  COMPANY_GIG_EDIT_PHOTO,
  COMPANY_GIG_EDIT_QUESTIONNAIRE,
  COMPANY_CREATE_GIG_PREVIEW,
} from '../../constants/routes';

export const STEP_NAME = {
  INFO: 'gigInfo',
  OCCUPATION: 'occupation',
  CATEGORY: 'category',
  POSITION: 'position',
  LOCATION: 'location',
  DATES: 'dates',
  COMPENSATION: 'compensation',
  AVAILABILITY: 'availability',
  EXPERIENCE: 'experience',
  LANGUAGE: 'language',
  QUESTIONNAIRE: 'questionnaire',
  PHOTO: 'photo',
};

const STEPS_CONFIG = [
  {
    titleKey: STEP_NAME.INFO,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_INFO,
    creatorPath: COMPANY_CREATE_GIG_INFO,
  },
  {
    titleKey: STEP_NAME.OCCUPATION,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_OCCUPATION,
    creatorPath: COMPANY_CREATE_GIG_OCCUPATION,
    featuredToggle: 'OCCUPATION',
  },
  {
    titleKey: STEP_NAME.CATEGORY,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_CATEGORY,
    creatorPath: COMPANY_CREATE_GIG_CATEGORY,
  },
  {
    titleKey: STEP_NAME.POSITION,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_DESCRIPTION,
    creatorPath: COMPANY_CREATE_GIG_DESCRIPTION,
  },
  {
    titleKey: STEP_NAME.LOCATION,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_LOCATION,
    creatorPath: COMPANY_CREATE_GIG_LOCATION,
  },
  {
    titleKey: STEP_NAME.DATES,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_DATES,
    creatorPath: COMPANY_CREATE_GIG_DATES,
  },
  {
    titleKey: STEP_NAME.COMPENSATION,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_COMPENSATION,
    creatorPath: COMPANY_CREATE_GIG_COMPENSATION,
  },
  {
    titleKey: STEP_NAME.AVAILABILITY,
    required: 1,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_AVAILABILITY,
    creatorPath: COMPANY_CREATE_GIG_AVAILABILITY,
  },
  {
    titleKey: STEP_NAME.EXPERIENCE,
    required: 0,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_EXPERIENCE,
    creatorPath: COMPANY_CREATE_GIG_EXPERIENCE,
  },
  {
    titleKey: STEP_NAME.LANGUAGE,
    required: 0,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_LANGUAGE,
    creatorPath: COMPANY_CREATE_GIG_LANGUAGE,
  },
  {
    titleKey: STEP_NAME.QUESTIONNAIRE,
    required: 0,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_QUESTIONNAIRE,
    creatorPath: COMPANY_CREATE_GIG_QUESTIONNAIRE,
    featuredToggle: 'QUESTIONNAIRE',
  },
  {
    titleKey: STEP_NAME.PHOTO,
    required: 0,
    isEditable: true,
    editPath: COMPANY_GIG_EDIT_PHOTO,
    creatorPath: COMPANY_CREATE_GIG_PHOTO,
  },
];

export const getStepConfig = (grantedFeatures) => STEPS_CONFIG.filter((step) => !(step.featuredToggle && !grantedFeatures.includes(step.featuredToggle)));

const getStepNumber = (stepConfig, titleKey) => stepConfig.findIndex((step) => step.titleKey === titleKey) + 1;

const getNextRoute = (stepConfig, stepNumber) => {
  if (stepNumber === stepConfig.length) {
    return COMPANY_CREATE_GIG_PREVIEW;
  }
  return stepConfig[stepNumber].creatorPath;
};

export const getStepInfo = (grantedFeatures, titleKey) => {
  const config = getStepConfig(grantedFeatures);
  const stepNumber = getStepNumber(config, titleKey);
  const nextScreen = getNextRoute(config, stepNumber);
  return {
    config,
    stepNumber,
    nextScreen,
    maxStepsNumber: config.length,
  };
};
