import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Routing from '../../core/routing/index';
import { getAdminSettingGigLimit, getAllAdminSettings } from './AdminSettingsActions';
import AdminSettingsScreen from './AdminSettingsScreen';
import { candidateProfilePropType } from '../../constants/propTypes';
import Container from '../../common/components/Container';

const { withRouter } = Routing;

class AdminSettingsContainer extends Component {
  componentDidMount() {
    this.props.getAdminSettingGigLimit();
    this.props.getAllAdminSettings();
  }

  render() {
    return (
      this.props.settings?.adminSettingsGigLimit?.gigLimit !== '' && (
        <Container>
          <AdminSettingsScreen settingsList={this.props.settings?.adminSettingsList} />
        </Container>
      )
    );
  }
}

AdminSettingsContainer.propTypes = {
  settings: candidateProfilePropType,
  getAdminSettingGigLimit: PropTypes.func.isRequired,
  getAllAdminSettings: PropTypes.func.isRequired,
};

AdminSettingsContainer.defaultProps = {
  settings: {},
};

const mapStateToProps = (state) => ({
  settings: state.admin.get('settings').toJS(),
});

export default connect(mapStateToProps, { getAdminSettingGigLimit, getAllAdminSettings })(withLocalize(withRouter(AdminSettingsContainer)));
