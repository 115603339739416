import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  flexOne: {
    flex: 1,
  },
});

const HorizontalWrapper = ({ children, noFlex, style }) => (
  <View style={[styles.wrapper, noFlex ? null : styles.flexOne, style]}>
    {children}
  </View>
);

HorizontalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  noFlex: PropTypes.bool,
  style: stylePropTypes,
};
HorizontalWrapper.defaultProps = {
  noFlex: false,
  style: undefined,
};

export default HorizontalWrapper;
