import { standardColors } from './base.style';

export function font(family, size, letterSpacing = 0, color = standardColors.text) {
  return {
    fontFamily: family,
    fontSize: size,
    letterSpacing,
    color,
  };
}
