import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { StyleSheet, View, Text } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FindGigsSelectCategoryField from './FindGigsSelectCategoryField';
import ViewportController from '../../../../common/components/ViewportController';
import Touchable from '../../../../common/components/Touchable';
import FindGigsSelectCityField from './FindGigsSelectCityField';
import { RIGHT_BUTTONS } from '../../../../common/components/header/ScreenHeader';
import { getAvailableGigCategories } from '../../../../common/gig/gigCategoriesActions';
import { getAvailableGigCities } from '../../../../common/gig/city/gigCitiesActions';
import { candidateFindGigsChangeCategories, candidateFindGigsChangeLocation, candidateFindGigsChangePositionSearch } from './candidateFindGigsFilterActions';
import { activeLanguagePropTypes, gigCategoryPropType, gigCityPropType } from '../../../../constants/propTypes';
import { CITY_OPTION_GIGS_ANYWHERE, renderCategories, renderLocalization } from './candidatesFindGigsFiltersHelpers';
import { windowWidth, spacings } from '../../../../common/styles/base.style';
import Hr from '../../../../common/components/Hr';
import SearchBox from '../../../../common/components/SearchBox';
import FeatureToggle from '../../../../common/components/FeatureToggle';

const BTN_HIT_SLOP_HORIZONTAL = 0;
const BTN_HIT_SLOP_VERTICAL = 20;

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
  },
  categories: {
    marginRight: 2,
  },
  localizations: {
    marginRight: 10,
  },
});

class CandidateFindGigsFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      categoriesMaxWidth: 120,
    };

    this.t = (key) => props.translate(`candidateFindGigs.${key}`);

    this.windowWidth = windowWidth;
  }

  componentDidMount() {
    this.props.getAvailableGigCategories();
    this.props.getAvailableGigCities();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled === false && this.props.disabled === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showFilters: false });
    }
  }

  switchFiltersVisibility = (disabled) => {
    if (disabled) {
      return;
    }
    this.setState({ showFilters: !this.state.showFilters });
  };

  updateCategoriesWidth = (evt) => {
    const { width } = evt.nativeEvent.layout;
    this.setState({ categoriesMaxWidth: this.windowWidth - (width + 36 + 21) });
  };

  handleCityChange = async (cityKey) => {
    await this.props.candidateFindGigsChangeLocation(cityKey);
  };

  handleCategoriesChange = async (categories) => {
    this.props.candidateFindGigsChangeCategories(categories);
  };

  handlePositionSearchChange = async (position) => {
    this.props.candidateFindGigsChangePositionSearch(position);
  };

  render() {
    const {
      cityKey, selectedCategories, availableCategories, availableCities, translate, activeLanguage, disabled,
    } = this.props;

    return (
      <>
        <ViewportController safeAreaTop noFlex>
          <Touchable
            onPress={() => { this.switchFiltersVisibility(disabled); }}
            hitSlop={{
              top: BTN_HIT_SLOP_VERTICAL, bottom: BTN_HIT_SLOP_VERTICAL, left: BTN_HIT_SLOP_HORIZONTAL, right: BTN_HIT_SLOP_HORIZONTAL,
            }}
          >
            <View>
              <View style={styles.headerContainer}>
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  style={[styles.categories, { maxWidth: this.state.categoriesMaxWidth }]}
                >
                  {renderCategories(selectedCategories, availableCategories, activeLanguage, translate)}
                </Text>
                <Text onLayout={this.updateCategoriesWidth} style={styles.localizations}>{renderLocalization(cityKey, availableCities, translate)}</Text>
              </View>
              { RIGHT_BUTTONS.FILTERS(() => { this.switchFiltersVisibility(disabled); }) }
            </View>
          </Touchable>
        </ViewportController>
        {this.state.showFilters && !disabled && (
          <>
            <Hr />
            <FindGigsSelectCityField
              selectedCity={cityKey}
              handleSubmit={this.handleCityChange}
              cities={availableCities}
            />
            <FindGigsSelectCategoryField
              translate={translate}
              handleSubmit={this.handleCategoriesChange}
              categories={selectedCategories}
              activeLanguage={activeLanguage}
              availableCategories={availableCategories}
            />
          </>
        )}
        <FeatureToggle requestedFeature="FIND_GIGS_SEARCHBOX">
          <View style={{ paddingBottom: spacings.xs }}>
            <SearchBox
              editable={!disabled}
              inputHeight={32}
              placeholder={translate('generic.search')}
              cancelTitle={translate('generic.cancel')}
              defaultValue={this.props.positionSearch}
              onSearch={this.handlePositionSearchChange}
              onCancel={this.handlePositionSearchChange}
              onDelete={this.handlePositionSearchChange}
              autoFocus={!!this.props.positionSearch}
            />
          </View>
        </FeatureToggle>
      </>
    );
  }
}

CandidateFindGigsFilters.propTypes = {
  translate: PropTypes.func.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType).isRequired,
  cityKey: PropTypes.string.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  positionSearch: PropTypes.string,

  candidateFindGigsChangeLocation: PropTypes.func.isRequired,
  candidateFindGigsChangeCategories: PropTypes.func.isRequired,
  candidateFindGigsChangePositionSearch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CandidateFindGigsFilters.defaultProps = {
  positionSearch: undefined,
  disabled: false,
};

const mapStateToProps = (state) => {
  let selectedCity = state.candidateFindGigsFilters.get('cityKey');
  let selectedCategories = state.candidateFindGigsFilters.get('categories');

  if (!selectedCity) {
    const location = state.candidate.get('profile').get('location');
    selectedCity = (location && location.get('cityV2').get('key')) || CITY_OPTION_GIGS_ANYWHERE;
  }

  if (!selectedCategories) {
    selectedCategories = state.candidate.get('profile').get('categories');
  }

  return {
    cityKey: selectedCity,
    selectedCategories: selectedCategories.toJS(),
    positionSearch: state.candidateFindGigsFilters.get('positionSearch'),
    availableCategories: state.gigCategories.toJS(),
    availableCities: state.gigCities.toJS(),
  };
};

export default connect(mapStateToProps, {
  getAvailableGigCategories,
  getAvailableGigCities,
  candidateFindGigsChangeCategories,
  candidateFindGigsChangeLocation,
  candidateFindGigsChangePositionSearch,
})(withLocalize(CandidateFindGigsFilters));
