import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import EditableField from '../../../../common/components/EditableField';
import stringTruncate from '../../../../utilities/stringUtils';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import CandidateProfileAboutMeForm from '../../forms/aboutMe/CandidateProfileAboutMeForm';
import { candidateProfileEditAboutMe } from '../../CandidateProfileActions';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';

class CandidateProfileEditDetailsAboutModalAware extends React.Component {
  openModal = () => {
    const {
      translate, closeModal, aboutMe,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.aboutMe'),
      <ModalKeyboardAwareScrollView>
        <CandidateProfileEditDetailsFormWithFooter
          component={CandidateProfileAboutMeForm}
          closeModal={closeModal}
          aboutMe={aboutMe}
          handleSubmit={(value) => {
            this.props.candidateProfileEditAboutMe(value)
              .then((response) => {
                if (!response.isError) {
                  closeModal();
                }
              });
          }}
        />
      </ModalKeyboardAwareScrollView>,
    );
  };

  render() {
    const { translate, aboutMe } = this.props;

    return (
      <EditableField
        label={translate('candidateProfile.details.aboutMe')}
        value={stringTruncate(aboutMe, 35) || ''}
        placeholder={translate('candidateProfile.details.aboutMePlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

CandidateProfileEditDetailsAboutModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  candidateProfileEditAboutMe: PropTypes.func.isRequired,
  aboutMe: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const profile = state.candidate.get('profile');
  return {
    aboutMe: profile.get('aboutMe') || '',
  };
};

export default connect(mapStateToProps, { candidateProfileEditAboutMe })(withLocalize(CandidateProfileEditDetailsAboutModalAware));
