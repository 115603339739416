import ReactRouterPropTypes from 'react-router-prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPoolProfile } from './poolProfileActions';
import CandidatesPoolDetailsScreen from '../../../common/shift/CandidatesPoolDetailsScreen';
import { goTo } from '../../../common/history/historyUtil';
import { COMPANY_CANDIDATES_POOL_MY_POOL } from '../../../constants/routes';
import Routing from '../../../core/routing/index';
import { COMPANY_USER } from '../../../constants/userScopes';

const { withRouter } = Routing;

class CompanyCandidatesPoolProfileDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { poolProfile: null };
  }

  componentDidMount() {
    this.props.getPoolProfile().then((res) => {
      this.setState({ poolProfile: res.payload });
    });
  }

  render() {
    return (
      <CandidatesPoolDetailsScreen
        poolProfile={this.state.poolProfile}
        customHandleBack={() => goTo(this.props.history, COMPANY_CANDIDATES_POOL_MY_POOL)}
        userScope={COMPANY_USER}
      />
    );
  }
}

CompanyCandidatesPoolProfileDetailsContainer.propTypes = {
  getPoolProfile: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default connect(null, { getPoolProfile })(withRouter(CompanyCandidatesPoolProfileDetailsContainer));
