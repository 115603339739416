import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import GigCreatorHeader from '../../GigCreatorHeader';
import Button, { BUTTON_SIZES } from '../../../../common/components/buttons/Button';
import LinkButton from '../../../../common/components/buttons/LinkButton';
import { dynamicSpacings, shadowTop, spacings } from '../../../../common/styles/base.style';
import Container from '../../../../common/components/Container';
import { languagePropType } from '../../../../constants/propTypes';
import LanguagesForm from '../../../../common/languages/LanguagesForm';

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    paddingTop: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
    ...shadowTop,
  },
});

const GigCreatorStepLanguageScreen = ({
  translate,
  languages,
  handleSubmit,
  handleSkip,
  handleAddLanguage,
  handleRemoveLanguage,
  handleUpdateLanguage,
  stepNumber,
  nextScreen,
  redirect,
  isEdit,
  maxStepsNumber,
}) => (
  <Container>
    <GigCreatorHeader
      isEdit={isEdit}
      stepNo={stepNumber}
      maxStepsNumber={maxStepsNumber}
      title={translate('companyGigCreator.language.header')}
      optional
      onRightButtonPress={handleSubmit}
    />
    <LanguagesForm
      languages={languages}
      handleAddLanguage={handleAddLanguage}
      handleRemoveLanguage={handleRemoveLanguage}
      handleUpdateLanguage={handleUpdateLanguage}
      formDescription={translate('companyGigCreator.language.label')}
      btnTitle={translate('companyGigCreator.language.addLanguageBtn')}
    />

    <View style={[styles.buttonsContainer, isEdit ? { justifyContent: 'center' } : null]}>
      {isEdit ? (<Button title={translate('generic.save')} onPress={handleSubmit} />)
        : (
          <>
            <LinkButton
              size={BUTTON_SIZES.FLEX_MD}
              type="secondary"
              to={nextScreen}
              title={translate('generic.skip')}
              onPress={handleSkip}
            />
            <LinkButton
              size={BUTTON_SIZES.FLEX_MD}
              to={nextScreen}
              title={translate('generic.next')}
              doRedirect={redirect}
              disabled={languages.length === 0}
              onPress={handleSubmit}
            />
          </>
        )}
    </View>
  </Container>
);

GigCreatorStepLanguageScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  languages: PropTypes.arrayOf(languagePropType).isRequired,

  handleSubmit: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  handleAddLanguage: PropTypes.func.isRequired,
  handleRemoveLanguage: PropTypes.func.isRequired,
  handleUpdateLanguage: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepLanguageScreen);
