import { GET_USER_STATUS_REQUEST } from '../../../constants/actionTypes';

export function getUserStatus(installationId) {
  return {
    type: GET_USER_STATUS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `user/status?installationId=${installationId}`,
      },
    },
  };
}
