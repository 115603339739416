import React from 'react';
import {
  Image, StyleSheet, Text, View,
} from 'react-native';
import PropTypes from 'prop-types';
import Touchable from '../components/Touchable';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../styles/base.style';

const favouriteDisabledIcon = require('../../assets/icons/singlegigview/btn_shortlist.png');
const rejectDisabledIcon = require('../../assets/icons/singlegigview/Reject_active.png');

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    alignSelf: 'center',
    color: colors.gondola,
    paddingLeft: spacings.xxs,
    fontSize: fontSizes.xs,
    fontFamily: fontFamily.SFProDisplaySemiBold,
  },
  icon: {
    width: 19,
    height: 19,
  },
  touchable: {
    flexDirection: 'row',
  },
});

const SingleFindGigsButtons = ({
  like, reject, isFavourite, isNotInteresting, translate,
}) => (
  <View style={styles.container}>
    {!isNotInteresting && (
    <Touchable
      style={styles.touchable}
      onPress={reject}
    >
      <Image source={rejectDisabledIcon} style={styles.icon} />
      <Text style={styles.label}>
        {translate('candidate.gigDetails.favouriteLabels.addToNotInteresting')}
      </Text>
    </Touchable>
    )}
    {!isFavourite && (
    <Touchable style={[styles.touchable, { paddingLeft: spacings.sm }]} onPress={like}>
      <Image source={favouriteDisabledIcon} style={styles.icon} />
      <Text style={styles.label}>
        {translate('candidate.gigDetails.favouriteLabels.addToFavourites')}
      </Text>
    </Touchable>
    )}
  </View>
);

SingleFindGigsButtons.propTypes = {
  like: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  isNotInteresting: PropTypes.bool.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

export default SingleFindGigsButtons;
