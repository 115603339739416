import React from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import AuthorizedRoute from '../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../constants/userScopes';
import {
  COMPANY_CHAT,
  COMPANY,
  COMPANY_AUTH,
  COMPANY_POSTGIG,
  COMPANY_PROFILE,
  COMPANY_GIGS_ROOT,
  COMPANY_SHIFT_ROOT,
  COMPANY_CANDIDATES_POOL,
  FIRST_CHECK_USER_STATUS,
} from '../constants/routes';
import CompanyChatRouting from './chat/CompanyChatRouting';
import CompanyNavigatorBar from './menu/CompanyNavigatorBar';
import ViewportController from '../common/components/ViewportController';
import CompanyGigsRouting from './gigs/CompanyGigsRouting';
import GigCreatorRouting from './gig_creator/GigCreatorRouting';
import CompanyCandidatesPoolRouting from './candidatesPool/CompanyCandidatesPoolRouting';
import CompanyProfileRouting from './profile/CompanyProfileRouting';
import TenantIndicator from '../common/user/TenantIndicator';
import Routing from '../core/routing/index';
import CompanyShiftsRouting from './shifts/CompanyShiftsRouting';
import renderMenu from './menu/appMenuConfig';
import CompanyAuthRouting from './auth/CompanyAuthRouting';

const { Route, withRouter, Redirect } = Routing;
const DEFAULT_SCREEN = FIRST_CHECK_USER_STATUS;

const CompanyRouting = (props) => (
  <ViewportController safeAreaBottom={renderMenu(props.location.pathname)}>
    <Route exact path={COMPANY} render={() => <Redirect to={DEFAULT_SCREEN} />} />
    <Route path={COMPANY_AUTH} component={CompanyAuthRouting} />
    <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_GIGS_ROOT} render={() => <Redirect to={DEFAULT_SCREEN} />} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_GIGS_ROOT} component={CompanyGigsRouting} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CHAT} component={CompanyChatRouting} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_POSTGIG} component={GigCreatorRouting} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_CANDIDATES_POOL} component={CompanyCandidatesPoolRouting} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_PROFILE} component={CompanyProfileRouting} />
    <AuthorizedRoute only={COMPANY_USER} path={COMPANY_SHIFT_ROOT} component={CompanyShiftsRouting} />
    {Platform.OS !== 'web' && <CompanyNavigatorBar />}
    <TenantIndicator />
  </ViewportController>
);

CompanyRouting.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

CompanyRouting.defaultProps = {
  location: null,
};

export default withRouter(CompanyRouting);
