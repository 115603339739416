import React from 'react';
import { Text } from 'react-native';
import InlineButton from '../components/buttons/InlineButton';

export const getTermsAndPrivacySwitchLabel = (t, onOpenPrivacy, onOpenTerms, style) => (
  <Text style={style}>
    <Text>{t('termsAcceptText1')}</Text>
    <InlineButton
      text={t('privacyButton')}
      onPress={onOpenPrivacy}
    />
    <Text>{t('termsAcceptText2')}</Text>
    <InlineButton
      text={t('termsButton')}
      onPress={onOpenTerms}
    />
    <Text>{t('termsAcceptText3')}</Text>
  </Text>
);
