import { getValueByPath } from '../../utilities/objectUtils';

class FormValidator {
  /**
   * Create a form validator that validates objects
   * and returns invalid fields + validation error messages
   * @params {validations[]} validation objects defined in forms/
   * See forms/UserSignup for an example
   */
  constructor(validations) {
    this.validations = validations;
  }

  /**
   * Validate a object of form data
   * It will go through each field and run validations against them
   * If fullValidation: true, the validation method will receive whole form data
   * This can be used to compare and validate fields to each other
   * @params {Object} data form data object to validate
   * @returns {Object} validation object
   * @example
   *   { email: { m: 'empty' } }
   */
  validate(data) {
    const validation = {};

    this.validations.forEach((rule) => {
      const val = getValueByPath(data, rule.field);

      const fieldValueStr = val ? val.toString() : '';
      const args = rule.args || [];
      const validWhen = rule.validWhen === undefined ? true : rule.validWhen;

      if (!rule.method && !rule.custom) {
        throw new Error(`Rule for field [${rule.field}] is missing 'method' or 'custom' property.`);
      }

      let validationResult;
      if (rule.method) {
        validationResult = rule.method(fieldValueStr, ...args);
      } else if (rule.custom) {
        validationResult = rule.custom(getValueByPath(data, rule.field), args, data);
      }

      if (validationResult !== validWhen) {
        validation[rule.field] = rule.message;
      }
    });

    return validation;
  }
}

export default FormValidator;
