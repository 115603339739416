import validator from 'validator';

export const USER_EMAIL_MAX_LENGTH = 100;
export const USER_PASSWORD_MIN_LENGTH = 5;
export const USER_PASSWORD_MAX_LENGTH = 60;

export const emailValidation = [
  // email
  {
    field: 'email',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'email',
    method: validator.isEmail,
    message: 'wrong_format',
  },
  {
    field: 'email',
    method: validator.isLength,
    args: [{ max: USER_EMAIL_MAX_LENGTH }],
    message: 'too_long',
  },
];
export const passwordValidation = [
  // password
  {
    field: 'password',
    method: validator.isLength,
    args: [{ min: USER_PASSWORD_MIN_LENGTH }],
    message: 'too_short',
  },
  {
    field: 'password',
    method: validator.isLength,
    args: [{ max: USER_PASSWORD_MAX_LENGTH }],
    message: 'too_long',
  },
];

export const loginValidation = [
  ...emailValidation,
  ...passwordValidation,
];
