import { Map, List } from 'immutable';
import {
  GET_USER_STATUS_REQUEST_SUCCESS,
  GET_ENABLED_FEATURES_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

const INITIAL_MAP = {
  grantedFeatures: undefined,
};

export default function getGrantedFeaturesReducer(state = Map(INITIAL_MAP), action) {
  switch (action.type) {
    case GET_USER_STATUS_REQUEST_SUCCESS:
      return state.set('grantedFeatures', List(action.payload.grantedFeatures));
    case GET_ENABLED_FEATURES_REQUEST_SUCCESS:
      return state.set('grantedFeatures', List(action.payload));
    default:
      return state;
  }
}
