import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import ModalKeyboardAwareScrollView from '../../../../common/keyboard/ModalKeyboardAwareScrollView';
import CandidateProfileClearingNumberForm from '../../forms/clearingNumber/CandidateProfileClearingNumberForm';
import { candidateProfileEditClearingNumber } from '../../CandidateProfileActions';

class CandidateProfileEditDetailsClearingNumberModalAware extends React.Component {
  handleSubmit = (value, { setErrors, setSubmitting }) => {
    const { candidateProfileEditClearingNumber: editAction, closeModal } = this.props;
    editAction(value)
      .then((response) => {
        if (!response.isError) {
          closeModal();
        } else {
          setErrors(response.errors);
          setSubmitting(false);
        }
      });
  };

  openModal = () => {
    const {
      translate, clearingNumber, closeModal,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.clearingNumber'),
      <ModalKeyboardAwareScrollView>
        <CandidateProfileEditDetailsFormWithFooter
          component={CandidateProfileClearingNumberForm}
          closeModal={closeModal}
          clearingNumber={clearingNumber}
          handleSubmit={this.handleSubmit}
        />
      </ModalKeyboardAwareScrollView>,
    );
  };

  render() {
    const { translate, clearingNumber } = this.props;

    return (
      <EditableField
        label={translate('candidateProfile.details.clearingNumber')}
        value={clearingNumber}
        placeholder={translate('candidateProfile.details.clearingNumberFieldPlaceholder')}
        onPress={this.openModal}
      />
    );
  }
}

CandidateProfileEditDetailsClearingNumberModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  candidateProfileEditClearingNumber: PropTypes.func.isRequired,
  clearingNumber: PropTypes.string,
};

CandidateProfileEditDetailsClearingNumberModalAware.defaultProps = {
  clearingNumber: undefined,
};

const mapStateToProps = (state) => ({ clearingNumber: state.candidate.get('profile').get('clearingNumber') });

export default connect(mapStateToProps, { candidateProfileEditClearingNumber })(withLocalize(CandidateProfileEditDetailsClearingNumberModalAware));
