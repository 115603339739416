import React from 'react';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { ADMIN_USER, SUPERADMIN_USER } from '../../constants/userScopes';
import {
  ADMIN_PROFILE,
  ADMIN_PROFILE_VIEW,
  ADMIN_PROFILE_SETTINGS,
} from '../../constants/routes';
import Routing from '../../core/routing';
import AdminProfileSettingsContainer from './settings/AdminProfileSettingsContainer';
import AdminProfileViewDetailsContainer from './view/AdminProfileViewDetailsContainer';

const { Redirect } = Routing;

const DEFAULT_SCREEN = ADMIN_PROFILE_VIEW;

const AdminProfileRouting = () => (
  <>
    <AuthorizedRoute exact only={[SUPERADMIN_USER, ADMIN_USER]} path={ADMIN_PROFILE} render={() => <Redirect to={DEFAULT_SCREEN} />} />
    <AuthorizedRoute only={[SUPERADMIN_USER, ADMIN_USER]} path={ADMIN_PROFILE_VIEW} component={AdminProfileViewDetailsContainer} />
    <AuthorizedRoute only={[SUPERADMIN_USER, ADMIN_USER]} path={ADMIN_PROFILE_SETTINGS} component={AdminProfileSettingsContainer} />
  </>
);

export default AdminProfileRouting;
