import { GET_CONFIGURATION_BY_KEY } from '../../constants/actionTypes';

export function getConfigurationByKey(key) {
  return {
    type: GET_CONFIGURATION_BY_KEY,
    payload: {
      request: {
        method: 'get',
        url: `configuration/${key}`,
      },
    },
  };
}
