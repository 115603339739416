import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import placeholderIcon from '../../assets/icons/placeholder.png';
import GigCategorySelectListScreen from './GigCategorySelectListScreen';
import { gigCategoryPropType } from '../../constants/propTypes';
import { getTranslationForCategory } from './gigCategoriesHelper';
import { getAvailableGigCategories } from './gigCategoriesActions';

class GigCategorySelectListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    };
  }

  componentDidMount() {
    this.props.getAvailableGigCategories();
  }

  getCategoriesWithTranslations = () => {
    const categories = [];
    this.props.availableCategories.forEach((c) => categories.push({

      ...c,
      value: c.key,
      label: getTranslationForCategory(c, this.props.activeLanguage),
      icon: placeholderIcon, // TODO: load c.key image name

    }));

    return categories;
  };

  render() {
    const {
      maxSelect, activeLanguage, translate,
    } = this.props;

    return (
      <GigCategorySelectListScreen
        categories={this.getCategoriesWithTranslations()}
        maxSelect={maxSelect}
        activeLanguage={activeLanguage}
        translate={translate}
        errors={this.state.errors}
      />
    );
  }
}

GigCategorySelectListContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,

  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  maxSelect: PropTypes.number,
};

GigCategorySelectListContainer.defaultProps = {
  maxSelect: undefined,
};

const mapStateToProps = (state) => ({
  availableCategories: state.gigCategories.toJS(),
});

export default connect(
  mapStateToProps,
  { getAvailableGigCategories },
)(withLocalize(GigCategorySelectListContainer));
