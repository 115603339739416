import React from 'react';
import {
  StyleSheet, View, ScrollView, ActivityIndicator, Linking, Text, TouchableOpacity, Image,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  activityIndicator, fontSizes, fontFamily, spacings, colors, wp, heightSpacingsBolek,
} from '../styles/base.style';
import { poolPropType } from '../../constants/propTypes/candidatePropTypes';
import { font } from '../styles/mixins';
import Wrapper from '../components/Wrapper';
import ScreenHeader from '../components/header/ScreenHeader';
import PoolChatButton from '../chat/pool/PoolChatButton';
import SectionHeaderBolek from '../components/header/SectionHeaderBolek';
import { CANDIDATE_USER, COMPANY_USER } from '../../constants/userScopes';
import UserScope from '../auth/UserScope';
import PrimaryTextBolek from '../components/PrimaryTextBolek';
import { COMPANY_CANDIDATES_POOL_PROFILE_EDIT } from '../../constants/routes';
import { BUTTON_TYPES } from '../components/buttons/Button';
import IconEntry from '../components/IconEntry';
import { LinkButton } from '../components';
import ViewportController from '../components/ViewportController';
import { getResizedImageFromDimensions } from '../components/image/imageHelper';

const locationIcon = require('../../assets/icons/location/location.png');
const phoneIcon = require('../../assets/icons/phone/phone.png');
const emailIcon = require('../../assets/icons/email/email.png');
const websiteIcon = require('../../assets/icons/website/website.png');

export const profileImagePercentageAspect = 45; // 56% is optimal for landscape mode of most devices

const styles = StyleSheet.create({
  label: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
  },
  text: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm),
  },
  groupView: {
    backgroundColor: colors.smokeWhite,
    width: '100%',
    padding: spacings.xs,
    marginBottom: 3,
    flexDirection: 'row',
  },
  groupName: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm),
    flex: 1,
  },
  editButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
  },
});

function CandidatesPoolDetailsScreen(props) {
  const {
    poolProfile, onGroupChat, translate, customHandleBack, userScope,
  } = props;
  const translateByKey = (key) => translate(`poolProfile.${key}`);

  if (!poolProfile) {
    return (
      <ActivityIndicator size="large" style={{ ...activityIndicator }} />
    );
  }

  const getWebsiteUrl = (website) => (website.startsWith('http') ? website : `https://${website}`);

  return (
    <ViewportController>
      <ScreenHeader title={poolProfile.company.name} showBackArrow handleBack={customHandleBack} />
      <ScrollView style={{ flex: 1 }}>
        {poolProfile.photoUri && (
          <Image
            source={{ uri: getResizedImageFromDimensions(poolProfile.photoUri, wp(100), wp(profileImagePercentageAspect)) }}
            style={{ width: wp(100), height: wp(profileImagePercentageAspect) }}
            fadeDuration={0}
          />
        )}
        <Wrapper paddingHorizontal style={{ marginBottom: userScope === CANDIDATE_USER ? heightSpacingsBolek.p40 : heightSpacingsBolek.p140 }}>
          {poolProfile.description && (
            <>
              <SectionHeaderBolek
                title={translateByKey('descriptionHeader')}
              />
              <PrimaryTextBolek>{poolProfile.description}</PrimaryTextBolek>
            </>
          )}
          <UserScope allowedFor={[CANDIDATE_USER]}>
            {poolProfile.groups && !!poolProfile.groups.length && (
              <SectionHeaderBolek
                title={translateByKey('teamsHeader')}
              />
            )}
            {poolProfile.groups && poolProfile.groups.map((group) => (
              <View key={group.groupId} style={styles.groupView}>
                <Text style={styles.groupName}>{group.name}</Text>
                <PoolChatButton
                  poolChatId={group.groupId}
                  onPress={() => onGroupChat(group.groupId)}
                  style={{ icon: { width: 22, height: 19 } }}
                />
              </View>
            ))}
          </UserScope>
          {poolProfile.contactInfo
          && (poolProfile.contactInfo.street || poolProfile.contactInfo.city || poolProfile.phoneNumber || poolProfile.email || poolProfile.website) && (
            <>
              <SectionHeaderBolek
                title={translateByKey('contactInfoHeader')}
              />
              <IconEntry icon={locationIcon}>
                <PrimaryTextBolek>{poolProfile.company.name}</PrimaryTextBolek>
              </IconEntry>
              {poolProfile.contactInfo.street && (
                <IconEntry>
                  <PrimaryTextBolek>{poolProfile.contactInfo.street}</PrimaryTextBolek>
                </IconEntry>
              )}
              {poolProfile.contactInfo.city && (
                <IconEntry>
                  <PrimaryTextBolek>{poolProfile.contactInfo.city}</PrimaryTextBolek>
                </IconEntry>
              )}
              {poolProfile.contactInfo.phoneNumber && (
                <IconEntry icon={phoneIcon}>
                  <PrimaryTextBolek>{poolProfile.contactInfo.phoneNumber}</PrimaryTextBolek>
                </IconEntry>
              )}
              {poolProfile.contactInfo.email && (
                <IconEntry icon={emailIcon}>
                  <TouchableOpacity onPress={() => Linking.openURL(`mailto:${poolProfile.contactInfo.email}`)}>
                    <PrimaryTextBolek style={{ color: colors.seablue }}>
                      {poolProfile.contactInfo.email}
                    </PrimaryTextBolek>
                  </TouchableOpacity>
                </IconEntry>
              )}
              {poolProfile.contactInfo.website && (
                <IconEntry icon={websiteIcon}>
                  <TouchableOpacity onPress={() => Linking.openURL(getWebsiteUrl(poolProfile.contactInfo.website))}>
                    <PrimaryTextBolek style={{ color: colors.seablue }}>
                      {poolProfile.contactInfo.website}
                    </PrimaryTextBolek>
                  </TouchableOpacity>
                </IconEntry>
              )}
            </>
          )}
          {!!poolProfile.company.description && (
            <>
              <SectionHeaderBolek
                title={translateByKey('companyDescriptionHeader')}
              />
              <PrimaryTextBolek>{poolProfile.company.description}</PrimaryTextBolek>
            </>
          )}
        </Wrapper>
      </ScrollView>
      <UserScope allowedFor={[COMPANY_USER]}>
        <View style={styles.editButton}>
          <ViewportController safeAreaBottom>
            <LinkButton
              to={COMPANY_CANDIDATES_POOL_PROFILE_EDIT}
              title={translateByKey('editButton')}
              type={BUTTON_TYPES.PRIMARY_RED}
            />
          </ViewportController>
        </View>
      </UserScope>
    </ViewportController>
  );
}

CandidatesPoolDetailsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  userScope: PropTypes.string.isRequired,
  onGroupChat: PropTypes.func,
  customHandleBack: PropTypes.func,
  poolProfile: poolPropType,
};
CandidatesPoolDetailsScreen.defaultProps = {
  poolProfile: null,
  onGroupChat: null,
  customHandleBack: null,
};

const mapStateToProps = (state) => ({
  userScope: state.auth.get('userScope'),
});

export default connect(mapStateToProps)(withLocalize(CandidatesPoolDetailsScreen));
