import {
  StyleSheet, View,
} from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

import {
  modernColors, spacings,
} from '../styles/base.style';
import { stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  entryWrapper: {
    backgroundColor: modernColors.darkGrey,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: spacings.xxs,
    paddingVertical: spacings.xs,
  },
});

const EntryRow = ({ children, style }) => children && (<View style={[styles.entryWrapper, style]}>{children}</View>);

EntryRow.propTypes = {
  children: PropTypes.node.isRequired,
  style: stylePropTypes,
};

EntryRow.defaultProps = {
  style: undefined,
};

export default EntryRow;
