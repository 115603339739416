import { ADMIN_CANDIDATES_SEND_NOTIFICATION_REQUEST } from '../../constants/actionTypes';

export function adminSendNotification(data) {
  const formData = new FormData();
  if (data.receiversCsv) {
    formData.append('receiversCsv', data.receiversCsv, data.receiversCsv.name);
  } else if (data.receivers) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.receivers.length; i++) {
      formData.append('receivers[]', data.receivers[i]);
    }
  }
  formData.append('type', data.type);
  formData.append('enTitle', data.enTitle);
  formData.append('svTitle', data.svTitle);
  formData.append('enText', data.enText);
  formData.append('svText', data.svText);
  return {
    type: ADMIN_CANDIDATES_SEND_NOTIFICATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: '/admin/notification',
        data: formData,
      },
    },
    formData,
  };
}
