import { fromJS, List, Map } from 'immutable';
import {
  NOTIFICATIONS_ADD, NOTIFICATIONS_MINIMIZE, NOTIFICATIONS_REMOVE, NOTIFICATIONS_REVIVE, ON_APP_STATE_CHANGE,
} from '../../constants/actionTypes';
import {
  CONNECTION_ERROR_NOTIFICATION_ID,
  NO_CHAT_NOTIFICATION_ID,
  NO_INTERNET_NOTIFICATION_ID,
  SERVER_ERROR_NOTIFICATION_ID,
} from './notificationCenterActions';
import { APP_STATE_ACTIVE } from '../watchers/app_state/AppStateWatcher';

// this will only work for static ids. maybe id of the notification should be title + text + translation data in the future
const lastNotificationTime = {};

const shouldIgnoreChosenErrorsIfNoInternet = (notifications, notification) => {
  if (![CONNECTION_ERROR_NOTIFICATION_ID, SERVER_ERROR_NOTIFICATION_ID, NO_CHAT_NOTIFICATION_ID].includes(notification.get('id'))) {
    return false;
  }

  return notifications.find((n) => n.get('id') === NO_INTERNET_NOTIFICATION_ID) !== undefined;
};

const shouldIgnoreChosenErrorsIfTooShortInForeground = (appInForegroundTimestamp, notification) => {
  if (![CONNECTION_ERROR_NOTIFICATION_ID].includes(notification.get('id'))) {
    return false;
  }
  return (Date.now() - appInForegroundTimestamp) < (3 * 1000);
};

const shouldDebounce = (notification) => {
  if (notification.get('debounce')) {
    const id = notification.get('id');
    const currentMilis = new Date().getTime();
    if (lastNotificationTime[id] !== undefined && lastNotificationTime[id] + notification.get('debounce') > currentMilis) {
      return true;
    }
  }
  return false;
};

// needs a change if there will be extra data for translations
const isDuplicate = (notifications, notification) => notifications.find((n) => n.get('titleKey') === notification.get('titleKey')
    && n.get('textKey') === notification.get('textKey')) !== undefined;

export default function notificationCenterReducer(state = Map({
  notifications: List(),
  appInForegroundTimestamp: Date.now(),
}), action) {
  switch (action.type) {
    case NOTIFICATIONS_ADD: {
      const immutableNotification = fromJS(action.notification);

      // avoid duplicates
      if (isDuplicate(state.get('notifications'), immutableNotification)) {
        console.log('Notification ignored: duplicate');
        return state;
      }

      if (shouldIgnoreChosenErrorsIfNoInternet(state.get('notifications'), immutableNotification)) {
        console.log('Notification ignored: no internet');
        return state;
      }

      if (shouldIgnoreChosenErrorsIfTooShortInForeground(state.get('appInForegroundTimestamp'), immutableNotification)) {
        console.log('Notification ignored: too short in foreground');
        return state;
      }

      if (shouldDebounce(immutableNotification)) {
        console.log('Notification debounced');
        return state;
      }

      // this might need a change if doesn't work well together with other error messages
      if (immutableNotification.get('permanent')) {
        console.log('Permanent notification: pushing as first');
        return state.set('notifications', state.get('notifications').insert(0, immutableNotification));
      }
      return state.set('notifications', state.get('notifications').push(immutableNotification));
    }
    case NOTIFICATIONS_REMOVE:
      lastNotificationTime[action.notificationId] = new Date().getTime();
      return state.set('notifications', state.get('notifications').filter((n) => n.get('id') !== action.notificationId));
    case NOTIFICATIONS_MINIMIZE:
      return state.set('notifications', state.get('notifications').map((n) => (n.get('id') === action.notificationId ? n.set('minimized', true) : n)));
    case NOTIFICATIONS_REVIVE:
      return state.set('notifications', state.get('notifications').map((n) => (n.get('id') === action.notificationId ? n.set('minimized', false) : n)));
    case ON_APP_STATE_CHANGE:
      if (action.appState === APP_STATE_ACTIVE) {
        return state.set('appInForegroundTimestamp', Date.now());
      }
      return state;
    default:
      return state;
  }
}
