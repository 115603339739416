import { fromJS, List, Map } from 'immutable';
import {
  PROFILE_CREATOR_ADD_AVAILABILITY,
  PROFILE_CREATOR_ADD_CATEGORIES,
  PROFILE_CREATOR_ADD_EDUCATION,
  PROFILE_CREATOR_ADD_EXPERIENCE,
  PROFILE_CREATOR_ADD_CURRENT_OCCUPATION,
  PROFILE_CREATOR_ADD_LANGUAGES,
  PROFILE_CREATOR_CLEAR_ALL_DATA,
  PROFILE_CREATOR_COPY_DATA_FROM_PROFILE,
  PROFILE_CREATOR_ADD_GIG_LOCATION,
  PROFILE_CREATOR_ADD_HOME_LOCATION,
} from '../../../constants/actionTypes';
import { INITIAL_PROFILE_STATE } from '../profileReducer';

export default function gigCreatorReducer(state = new Map(INITIAL_PROFILE_STATE), action) {
  switch (action.type) {
    case PROFILE_CREATOR_CLEAR_ALL_DATA:
      return new Map(INITIAL_PROFILE_STATE);
      // e.g. availability can be set before profile creator
    case PROFILE_CREATOR_COPY_DATA_FROM_PROFILE:
      return Map(action.profile);
    case PROFILE_CREATOR_ADD_CATEGORIES:
      return state.set('categories', List(action.categories));
    case PROFILE_CREATOR_ADD_AVAILABILITY: {
      const {
        available, hours, times,
      } = action.availability;

      return state.merge(Map({
        available,
        hours,
        times: List(times),
      }));
    }
    case PROFILE_CREATOR_ADD_GIG_LOCATION: {
      return state.set('location', fromJS(action.location));
    }
    case PROFILE_CREATOR_ADD_HOME_LOCATION: {
      return state.set('homeLocation', fromJS(action.homeLocation));
    }
    case PROFILE_CREATOR_ADD_EXPERIENCE:
      return state.set('experience', fromJS(action.experience));
    case PROFILE_CREATOR_ADD_EDUCATION:
      return state.set('education', fromJS(action.education));
    case PROFILE_CREATOR_ADD_CURRENT_OCCUPATION:
      return state.set('currentOccupation', List(action.currentOccupation));
    case PROFILE_CREATOR_ADD_LANGUAGES:
      return state.set('languages', List(action.languages));
    default:
      return state;
  }
}
