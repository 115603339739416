import queryString from 'query-string';
import {
  ADMIN_GIGS_FIND_FILTER_FORM_SUBMIT,
  ADMIN_GIGS_FIND_GIGS_REQUEST, ADMIN_GIG_DETAILS_REQUEST,
  ADMIN_GIG_DETAILS_CLEAR,
  ADMIN_GIG_PAUSE_REQUEST,
} from '../../constants/actionTypes';

export const GIGS_LIMIT = 10;

export function adminFindGigsChangeProviderGigIdSearch(formValues) {
  return {
    type: ADMIN_GIGS_FIND_FILTER_FORM_SUBMIT,
    formValues,
  };
}

export function adminFindGigs(params) {
  return {
    type: ADMIN_GIGS_FIND_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/gig',
        params: {
          offset: 0,
          limit: GIGS_LIMIT,
          ...params,
        },
        paramsSerializer: (p) => queryString.stringify(p),
      },
    },
  };
}

export function adminGigDetails(gigId) {
  return {
    type: ADMIN_GIG_DETAILS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `admin/gig/${gigId}`,
      },
    },
  };
}

export function adminClearGigDetails() {
  return {
    type: ADMIN_GIG_DETAILS_CLEAR,
  };
}

export function adminPauseGig(gigId) {
  return {
    type: ADMIN_GIG_PAUSE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `admin/company/${gigId}/pause`
      },
    },
  };
}
