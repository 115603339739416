import React, { useEffect, useState } from 'react';
import {
  LocalizeProvider,
  withLocalize,
} from 'react-localize-redux';
import { Provider } from 'react-redux';
import {
  StyleSheet,
  View,
  Platform,
  StatusBar,
} from 'react-native';
import { PersistGate } from 'redux-persist/integration/react';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { persistor, store } from './core/redux/store';
import Routing, { Router } from './core/routing/index';
import Root from './common/Root';
import WelcomeScreen from './common/screens/WelcomeScreen';
import WelcomeCarouselContainer from './common/screens/WelcomeCarouselContainer';
import CandidateRouting from './candidate/CandidateRouting';
import AuthorizedRoute from './common/components/navigation/AuthorizedRoute';
import TranslationsWrapper from './core/localization/TranslationsWrapper';
import NotificationPermissionContainer from './common/notification/NotificationPermissionContainer';
import {
  ROOT,
  ERROR,
  WELCOME,
  COMPANY,
  WELCOME_CAROUSEL,
  CANDIDATE,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  FIRST_CHECK_USER_STATUS,
  NOTIFICATION_PERMISSION, ADMIN, DEEP_LINK_REDIRECT,
} from './constants/routes';
import ChatProvider from './common/chat/ChatProvider';
import DataProvider from './common/DataProvider';
import AppearanceProviderForMobiles from './common/AppearanceProviderForMobiles';
import GoogleAnalyticsComponent from './common/analytics/googleAnalyticsComponent';
import SentryProvider from './common/sentry/SentryProvider';
import NotificationsCenter from './common/notifications_center/NotificationCenter';
import InternetConnectionWatcher from './common/watchers/internet_connection/InternetConnectionWatcher';
import ErrorScreen from './common/screens/ErrorScreen';
import ForgotPasswordContainer from './common/auth/password/ForgotPasswordContainer';
import ResetPasswordContainer from './common/auth/password/ResetPasswordContainer';
import DeepLinksHandler from './deeplinks/DeepLinksHandler';
import AppStateWatcher from './common/watchers/app_state/AppStateWatcher';
import BundleUpdater from './common/updaters/bundle/BundleUpdater';
import PushNotificationsHandler from './core/pushNotifications/PushNotificationsHandler';
import CheckUserStatusContainer from './common/user_status/CheckUserStatusContainer';
import UserStatusUpdater from './common/updaters/user_status/UserStatusUpdater';
import LostInternetConnectionNotifier from './common/notifications_center/LostInternetConnectionNotifier';
import PushNotificationsUpdater from './common/notification/PushNotificationsUpdater';
import FeatureToggle from './common/components/FeatureToggle';
import InstallationUpdater from './common/auth/InstallationUpdater';
import { contentWidth } from './common/styles/base.style';
import AppContentWrapper from './AppContentWrapper';
import CompanyRouting from './company/CompanyRouting';
import AnonymousUserGrantedFeaturesUpdater from './common/featureToggle/AnonymousUserGrantedFeaturesUpdater';
import UserScope from './common/auth/UserScope';
import { CANDIDATE_USER, COMPANY_USER } from './constants/userScopes';
import AdminRouting from './admin/AdminRouting';
import FeatureToggleGate from './common/featureToggle/FeatureToggleSplashScreenGate';
import { PrintingContextProvider } from './core/printingContext';
import PaymentDeepLink from './paymentDeepLink/PaymentDeepLink';

const { Route, BackButton: AndroidBackButton, withRouter } = Routing;
const TranslationsWrapperWithRouter = withRouter(TranslationsWrapper);
const ChatProviderWithRouter = withRouter(ChatProvider);
const GoogleAnalyticsWithRouter = withRouter(GoogleAnalyticsComponent);

// we need to pass location as a prop to our AuthorizedRoute to cause rerender.
// see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    backgroundColor: 'white',
    ...Platform.select({
      web: {
        minHeight: '100%',
        width: contentWidth,
        marginHorizontal: 'auto',
      },
    }),
  },
});

const App = () => {
  let isPrint;
  if (Platform.OS === 'web') {
    const printMedia = window.matchMedia('print');
    const [isPrintMedia, setIsPrint] = useState(printMedia.matches);
    isPrint = isPrintMedia;
    useEffect(() => {
      const changeHandler = (event) => setIsPrint(event.matches);
      printMedia.addEventListener('change', changeHandler);
      return () => printMedia.removeEventListener('change', changeHandler);
    });
  }
  return (
    <AppearanceProviderForMobiles>
      <LocalizeProvider>
        <StatusBar
          backgroundColor="#888888"
          barStyle="default"
          hidden={false}
          translucent
        />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DataProvider />
            <SentryProvider />
            <AppStateWatcher />
            <InternetConnectionWatcher />
            <InstallationUpdater />
            <UserStatusUpdater />
            <AnonymousUserGrantedFeaturesUpdater />
            <LostInternetConnectionNotifier />
            <FeatureToggleGate>
              <PrintingContextProvider isPrint={isPrint}>
                <Router>
                  <TranslationsWrapperWithRouter>
                    <PushNotificationsUpdater />
                    <BundleUpdater />
                    <UserScope allowedFor={[CANDIDATE_USER, COMPANY_USER]}>
                      <ChatProviderWithRouter />
                    </UserScope>
                    <GoogleAnalyticsWithRouter />
                    <SafeAreaProvider>
                      <View style={styles.appContainer}>
                        <ActionSheetProvider>
                          <>
                            <NotificationsCenter />
                            <DeepLinksHandler />
                            <FeatureToggle requestedFeature="PUSH_NOTIFICATIONS_APP_HANDLING">
                              <PushNotificationsHandler />
                            </FeatureToggle>
                            <AppContentWrapper>
                              <AndroidBackButton />
                              <Route exact path={ROOT} component={Root} />
                              <Route exact path={WELCOME_CAROUSEL} component={WelcomeCarouselContainer} />
                              <Route exact path={WELCOME} component={WelcomeScreen} />
                              <Route path={ERROR} component={ErrorScreen} />
                              <Route path={FORGOT_PASSWORD} component={ForgotPasswordContainer} />
                              <Route path={RESET_PASSWORD} component={ResetPasswordContainer} />
                              <Route path={COMPANY} component={CompanyRouting} />
                              <Route path={CANDIDATE} component={CandidateRouting} />
                              <Route path={ADMIN} component={AdminRouting} />
                              <Route path={DEEP_LINK_REDIRECT} component={PaymentDeepLink} />
                              <AuthorizedRoute path={FIRST_CHECK_USER_STATUS} component={CheckUserStatusContainer} />
                              <AuthorizedRoute path={NOTIFICATION_PERMISSION} component={NotificationPermissionContainer} />
                            </AppContentWrapper>
                          </>
                        </ActionSheetProvider>
                      </View>
                    </SafeAreaProvider>
                  </TranslationsWrapperWithRouter>
                </Router>
              </PrintingContextProvider>
            </FeatureToggleGate>
          </PersistGate>
        </Provider>
      </LocalizeProvider>
    </AppearanceProviderForMobiles>
  );
};

export default withLocalize(App);
