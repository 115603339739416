import React from 'react';
import Routing from '../../core/routing/index';
import CandidateWelcomeScreen from './CandidateWelcomeScreen';
import LoginContainer from './LoginContainer';
import CandidateSignupContainer from './CandidateSignupContainer';
import CandidateContractAgreementContainer from './CandidateContractAgreementContainer';

import {
  CANDIDATE_AUTH,
  CANDIDATE_WELCOME,
  CANDIDATE_LOGIN,
  CANDIDATE_SIGNUP,
  CANDIDATE_CONTRACT_AGREEMENT,
} from '../../constants/routes';

const { Route, Redirect } = Routing;

const CandidateAuthRouting = () => (
  <>
    <Route exact path={CANDIDATE_AUTH} render={() => <Redirect to={CANDIDATE_WELCOME} />} />
    <Route path={CANDIDATE_WELCOME} component={CandidateWelcomeScreen} />
    <Route path={CANDIDATE_LOGIN} component={LoginContainer} />
    <Route path={CANDIDATE_SIGNUP} component={CandidateSignupContainer} />
    <Route path={CANDIDATE_CONTRACT_AGREEMENT} component={CandidateContractAgreementContainer} />
  </>
);

export default CandidateAuthRouting;
