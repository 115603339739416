import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView, StyleSheet } from 'react-native';
import shallowequal from 'shallowequal';
import { SelectListItem, SelectListItemDataPropType, SelectListItemDataValuePropType } from './SelectListItem';
import { standardSpacings } from '../../styles/base.style';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: standardSpacings.formVertical,
  },
  scrollContainer: {
    paddingBottom: standardSpacings.formVertical,
  },
  scroll: {
    alignSelf: 'stretch',
  },
});

class SelectList extends Component {
  onItemPress = (value, selected) => {
    if (!this.props.multiple) {
      return this.onItemPressSingle(value, selected);
    }
    return this.onItemPressMultiple(value, selected);
  };

  onItemPressSingle = (value, selected) => {
    if (!selected) {
      this.props.onValueChange(value);
      return true;
    }
    this.props.onValueChange(null);
    return false;
  };

  onItemPressMultiple = (value, selected) => {
    const limit = this.props.maxSelect;
    const limitReached = this.props.value.length >= limit;

    if (limitReached && !selected) {
      return this.props.onMaxSelect();
    }

    if (selected) {
      this.props.onValueChange(this.props.value.filter((i) => i !== value));
    } else {
      this.props.onValueChange(this.props.value.concat([value]));
    }

    return !selected;
  };

  renderList = () => (
    this.props.items.map((item) => (
      <SelectListItem
        key={item.componentKey || item.value}
        value={item.value}
        label={item.label}
        selected={this.props.multiple ? this.props.value.includes(item.value) : shallowequal(item.value, this.props.value)}
        iconPosition={this.props.iconPosition}
        onPress={this.onItemPress}
      />
    )));

  render = () => {
    const { marginBottom } = this.props;

    if (this.props.scroll) {
      return (
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          pinchGestureEnabled={false}
          style={styles.scroll}
          contentContainerStyle={[styles.scrollContainer, marginBottom]}
        >
          {this.renderList()}
        </ScrollView>
      );
    }

    return (
      <View style={[styles.container, marginBottom]}>
        {this.renderList()}
      </View>
    );
  };
}

export const SelectListValuePropType = PropTypes.oneOfType([
  ...SelectListItemDataValuePropType,
  PropTypes.arrayOf(PropTypes.oneOfType(SelectListItemDataValuePropType)),
]);

SelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SelectListItemDataPropType)).isRequired,
  value: SelectListValuePropType,
  onValueChange: PropTypes.func.isRequired,
  onMaxSelect: PropTypes.func,
  multiple: PropTypes.bool,
  maxSelect: PropTypes.number,
  scroll: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  marginBottom: PropTypes.number,
};

SelectList.defaultProps = {
  multiple: false,
  // this should really be initialized to [] for multiple... maybe we should have separate components for multiple and single lists
  // for multiple lists initial value is set to [] in redux, that's why it works
  value: null,
  maxSelect: Number.MAX_SAFE_INTEGER,
  onMaxSelect: () => {},
  scroll: false,
  iconPosition: 'left',
  marginBottom: null,
};

export default SelectList;
