import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorStepCompensationScreen from './GigCreatorStepCompensationScreen';
import { gigCreatorAddCompensation } from '../../gigCreatorActions';
import Routing from '../../../../core/routing/index';
import { PER } from '../../../../constants/salaryTypes';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepCompensationContainer extends Component {
  constructor(props) {
    super(props);

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.COMPENSATION));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.COMPENSATION);
  }

  onSubmit = ({
    labourContract,
    salaryByAgreement,
    volunteering,
    internshipTrainee,
    salaryPer,
    min,
    max,
  }, { setStatus, setSubmitting }) => {
    this.props.gigCreatorAddCompensation({
      labourContract,
      salaryByAgreement,
      volunteering,
      internshipTrainee,
      salaryPer,
      min,
      max,
    });

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.SALARY, {
        compensation: {
          labourContract,
          salaryByAgreement,
          volunteering,
          internshipTrainee,
          salary: {
            min: parseInt(min, 10),
            max: parseInt(max, 10),
            per: salaryPer,
          },
        },
      })
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      setStatus({ redirect: true });
    }
  };

  render() {
    const {
      min,
      max,
      salaryPer,
      labourContract,
      salaryByAgreement,
      volunteering,
      internshipTrainee,
      isEdit,
    } = this.props;

    const initialValues = {
      min,
      max,
      salaryPer,
      labourContract,
      salaryByAgreement,
      volunteering,
      internshipTrainee,
    };

    return (
      <GigCreatorStepCompensationScreen
        stepNumber={this.stepNumber}
        nextScreen={this.nextScreen}
        maxStepsNumber={this.maxStepsNumber}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        isEdit={isEdit}
      />
    );
  }
}

GigCreatorStepCompensationContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  salaryPer: PropTypes.oneOf([PER.HOUR, PER.MONTH]),
  labourContract: PropTypes.bool,
  salaryByAgreement: PropTypes.bool,
  volunteering: PropTypes.bool,
  internshipTrainee: PropTypes.bool,
  gigCreatorAddCompensation: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  ...withGigEditPropTypes,
};

GigCreatorStepCompensationContainer.defaultProps = {
  min: null,
  max: null,
  salaryPer: PER.HOUR,
  labourContract: false,
  salaryByAgreement: false,
  volunteering: false,
  internshipTrainee: false,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      compensation: {
        salary: {
          min,
          max,
          per: salaryPer,
        },
        volunteering,
        internshipTrainee,
        labourContract,
        salaryByAgreement,
      },
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return {
    internshipTrainee,
    volunteering,
    min: min || min === 0 ? min.toString() : '',
    max: max ? max.toString() : '',
    salaryPer,
    labourContract,
    salaryByAgreement,
    grantedFeatures,

  };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddCompensation })(GigCreatorStepCompensationContainer));
