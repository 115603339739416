import React from 'react';

import PrimaryTextInput from './PrimaryTextInput';
import { stylePropTypes } from '../../../constants/propTypes';
import { colors } from '../../styles/base.style';

const PrimaryLocationAutocompleteTextInput = (props) => (
  <PrimaryTextInput
    {...props}
    style={{ container: { marginBottom: 0 }, label: { color: colors.black } }}
  />
);

PrimaryLocationAutocompleteTextInput.propTypes = {
  style: stylePropTypes,
};

PrimaryLocationAutocompleteTextInput.defaultProps = {
  style: null,
};

export default PrimaryLocationAutocompleteTextInput;
