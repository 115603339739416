import React, { Component } from 'react';
import { ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';

import Container from '../../common/components/Container';
import { CANDIDATE_MY_POOLS_POOL_PROFILE, CANDIDATE_MY_POOLS_SHIFTS_BOOKED, redirectToErrorScreen } from '../../constants/routes';
import { activityIndicator } from '../../common/styles/base.style';
import ShiftDetails from '../../common/gig/ShiftDetails';
import { bookShift, getShift } from './candidateShiftActions';
import { BOOKED } from '../../constants/shiftCandidateStatus';

class CandidateShiftDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShift: true, shift: undefined,
    };
  }

  componentDidMount() {
    const { id: shiftId } = this.props.match.params;
    this.fetchData(shiftId);
  }

  componentDidUpdate(prevProps) {
    const { id: shiftId } = this.props.match.params;
    if (shiftId !== prevProps.match.params.id) {
      this.fetchData(shiftId);
    }
  }

  openActionSheet = () => {
    const { translate } = this.props;
    const { shift } = this.state;

    const actionSheetParams = { options: [] };
    const actionHandlers = [];
    let optionsSize;

    if (shift && shift.shiftCandidateStatus !== BOOKED) {
      optionsSize = actionSheetParams.options.push(translate('shiftPreview.book'));
      actionHandlers[optionsSize - 1] = async () => {
        this.bookShift(shift.gigId, () => this.props.history.replace(CANDIDATE_MY_POOLS_SHIFTS_BOOKED));
      };
    }

    optionsSize = actionSheetParams.options.push(translate('shiftPreview.showPoolDetails'));
    actionHandlers[optionsSize - 1] = async () => {
      this.onOpenDetails(shift.company.companyId);
    };

    optionsSize = actionSheetParams.options.push(translate('generic.cancel'));
    actionSheetParams.cancelButtonIndex = optionsSize - 1;
    actionHandlers[optionsSize - 1] = () => {};

    this.props.showActionSheetWithOptions(
      {
        ...actionSheetParams,
        showSeparators: true,
      },
      (buttonIndex) => {
        actionHandlers[buttonIndex]();
      },
    );
  };

  bookShift = (gigId, callback) => {
    this.props.bookShift(gigId).then((response) => {
      if (!response.isError) {
        callback();
      } else if (response && response.status === 400) {
        if (response.errors.general === 'shift_already_booked') {
          redirectToErrorScreen(this.props.history, 'errors.shiftAlreadyBooked');
        }
        if (response.errors.general === 'shift_already_booked_by_current_user') {
          redirectToErrorScreen(this.props.history, 'errors.shiftAlreadyBookedByCurrentUser');
        }
      } else {
        redirectToErrorScreen(this.props.history, 'errors.candidateGigCurrentlyUnavailableTitle');
      }
    });
  };

  onOpenDetails = (companyId) => {
    this.props.history.push(CANDIDATE_MY_POOLS_POOL_PROFILE.replace(':companyId', companyId));
  };

  fetchData(shiftId) {
    this.setState({ loadingShift: true });
    this.props.getShift(shiftId).then((response) => {
      if (!response.isError) {
        this.setState({ shift: response.payload, loadingShift: false });
      } else if (response && response.status === 404) {
        redirectToErrorScreen(this.props.history, 'errors.candidateGigUnavailableTitle');
        this.setState({ loadingShift: false });
      } else {
        redirectToErrorScreen(this.props.history, 'errors.candidateGigCurrentlyUnavailableTitle');
        this.setState({ loadingShift: false });
      }
    });
  }

  render() {
    const { shift, loadingShift } = this.state;
    if (loadingShift) {
      return (
        <Container>
          <ActivityIndicator size="large" style={activityIndicator} />
        </Container>
      );
    }
    return (
      <ShiftDetails
        shift={shift}
        bookShiftWithCallback={this.bookShift}
        actionSheet={this.openActionSheet}
        onOpenPoolDetails={this.onOpenDetails}
      />
    );
  }
}

CandidateShiftDetailsContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  showActionSheetWithOptions: PropTypes.func.isRequired,
  getShift: PropTypes.func.isRequired,
  bookShift: PropTypes.func.isRequired,
};

export default connect(null, { getShift, bookShift })(withLocalize(connectActionSheet(CandidateShiftDetailsContainer)));
