import { combineReducers } from 'redux';
import authReducer from '../../common/auth/authReducer';
import requestsReducer from '../client/requestsReducer';
import localeReducer from '../localization/localeReducer';
import notificationsReducer from '../../common/notification/notificationsReducer';
import companyGigReducer from '../../company/companyGigReducer';
import configReducer from '../../common/config/configReducer';
import gigCategoriesReducer from '../../common/gig/gigCategoriesReducer';
import educationCategoriesReducer from '../../candidate/profile/education/category/educationCategoriesReducer';
import candidateReducer from '../../candidate/candidateReducer';
import candidateFindGigsReducer from '../../candidate/gig/find_gigs/candidateFindGigsReducer';
import candidateSimilarGigsReducer from '../../candidate/gig/candidateSimilarGigsReducer';
import candidateFindGigsFiltersReducer from '../../candidate/gig/find_gigs/filters/candidateFindGigsFiltersReducer';
import companyGigCandidatesReducer from '../../company/gigs/gigDetails/candidates/companyGigCandidatesReducer';
import chatReducer from '../../common/chat/chatReducer';
import chatAuthReducer from '../../common/chat/chatAuthReducer';
import candidateMyGigsReducer from '../../candidate/gig/my_gigs/candidateMyGigsReducer';
import companyReducer from '../../company/companyReducer';
import notificationCenterReducer from '../../common/notifications_center/notificationCenterReducer';
import appStateReducer from '../../common/watchers/app_state/appStateReducer';
import updateReducer from '../../common/updaters/bundle/updateReducer';
import userSettingsReducer from '../../common/user/userReducer';
import userStatusReducer from '../../common/updaters/user_status/userStatusReducer';
import grantedFeaturesReducer from '../../common/featureToggle/grantedFeaturesReducer';
import featureTrackingReducer from '../../common/featureTracking/featureTrackingReducer';
import internetConnectionReducer from '../../common/watchers/internet_connection/internetConnectionReducer';
import gigCitiesReducer from '../../common/gig/city/gigCitiesReducer';
import rateAppReducer from '../../common/rate_app/rateAppReducer';
import companyCandidatesPoolReducer from '../../company/candidatesPool/companyCandidatesPoolReducer';
import candidateMyPoolsReducer from '../../candidate/myPools/candidateMyPoolsReducer';
import companyShiftsReducer from '../../company/shifts/companyShiftsReducer';
import shiftCreatorReducer from '../../company/shifts/steps/shiftCreatorReducer';
import poolChatReducer from '../../common/chat/pool/poolChatReducer';
import logReducer from '../../common/log/logReducer';
import adminReducer from '../../admin/AdminReducer';
import associationReducer from '../../association/AssociationsReducer';

// this is the list of final reducers
export const appReducers = {
  locale: localeReducer, // locale related data
  requests: requestsReducer, // state of requests to backend
  companyGig: companyGigReducer, // gig data for company
  company: companyReducer,
  companyCandidatesPool: companyCandidatesPoolReducer,
  companyShifts: companyShiftsReducer,
  shiftCreator: shiftCreatorReducer,
  candidate: candidateReducer, // candidate data
  candidateFindGigs: candidateFindGigsReducer, // data for loading find gigs list
  candidateSimilarGigs: candidateSimilarGigsReducer,
  candidateFindGigsFilters: candidateFindGigsFiltersReducer, // filters on find gigs page
  candidateMyGigs: candidateMyGigsReducer, // lists of gigs with which candidate is connected
  candidateMyPools: candidateMyPoolsReducer,
  gigCategories: gigCategoriesReducer,
  educationCategories: educationCategoriesReducer,
  gigCities: gigCitiesReducer,
  gigCandidates: companyGigCandidatesReducer,
  chat: chatReducer,
  poolChat: poolChatReducer,
  chatAuth: chatAuthReducer,
  auth: authReducer, // authentication and authorization
  notifications: notificationsReducer, // stores which users enabled notifications
  notificationCenter: notificationCenterReducer,
  appStateData: appStateReducer,
  updateData: updateReducer,
  config: configReducer,
  userSettings: userSettingsReducer,
  internetConnection: internetConnectionReducer,
  userStatus: userStatusReducer,
  grantedFeatures: grantedFeaturesReducer,
  rateApp: rateAppReducer,
  log: logReducer,
  featureTracking: featureTrackingReducer,
  association: associationReducer,
  admin: adminReducer, // admin data
};

export default combineReducers(appReducers);
