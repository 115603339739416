import {
  ADMIN_PROFILE_REQUEST,
} from '../../constants/actionTypes';

export default function getAdminProfile() {
  return {
    type: ADMIN_PROFILE_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/profile',
      },
    },
  };
}
