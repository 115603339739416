/*
  I made an assumption that a field containing date will be named according to convention:
  date,
  time,
  *Date
  *Time
  date*
  time*
 */

const dateOrTimeFieldPattern = /^date[A-Z]|Date$|Date[A-Z]|^date$|^time[A-Z]|Time$|Time[A-Z]|^time$/;
export default function isDateField(key) {
  return dateOrTimeFieldPattern.test(key);
}
