import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard, Platform, LayoutAnimation } from 'react-native';
import Wrapper from './Wrapper';

class KeyboardAwareFlexWrapper extends Component {
  customLayoutKeyboard = {
    duration: 0, // doesn't matter when using keyboard
    update: {
      type: LayoutAnimation.Types.keyboard,
    },
  };

  constructor(props) {
    super(props);
    this.state = { flex: this.props.flexOnStart };
  }

  componentDidMount() {
    if (Platform.OS === 'ios') {
      this.keyboardDidShowListener = Keyboard.addListener('keyboardWillShow', this.keyboardWillShow);
      this.keyboardDidHideListener = Keyboard.addListener('keyboardWillHide', this.keyboardWillHide);
    }
  }

  componentWillUnmount() {
    if (Platform.OS === 'ios') {
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
    }
  }

  keyboardWillShow = () => {
    LayoutAnimation.configureNext(this.customLayoutKeyboard);
    this.setState({ flex: !this.props.flexOnStart });
  };

  keyboardWillHide = () => {
    LayoutAnimation.configureNext(this.customLayoutKeyboard);
    this.setState({ flex: this.props.flexOnStart });
  };

  render() {
    return <Wrapper noFlex={!this.state.flex} {...this.props} />;
  }
}

KeyboardAwareFlexWrapper.propTypes = {
  flexOnStart: PropTypes.bool,
};

KeyboardAwareFlexWrapper.defaultProps = {
  flexOnStart: true,
};

export default KeyboardAwareFlexWrapper;
