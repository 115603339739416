import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorHeader from '../../GigCreatorHeader';
import { LinkButton } from '../../../../common/components';
import Container from '../../../../common/components/Container';
import { shadowTop, spacings } from '../../../../common/styles/base.style';
import Button from '../../../../common/components/buttons/Button';
import PrimaryText from '../../../../common/components/PrimaryText';
import { OCCUPATION } from '../../../../constants/occupation';
import validation from './GigCreatorStepOccupationValidation';
import FormikFieldSelectList from '../../../../common/components/form/FormikSelectList';
import Wrapper from '../../../../common/components/Wrapper';

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
  titleLabel: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    paddingTop: spacings.lg,
    marginBottom: spacings.lg,
  },
});

const getAvailableOccupation = (translate) => Object.values(OCCUPATION).map((val) => ({
  label: translate(`companyGigCreator.preferredOccupation.occupation.${val}`),
  value: val,
}));

function GigCreatorStepOccupationScreen(props) {
  const {
    stepNumber, translate, handleSubmit, nextScreen, redirect, isEdit, preferredOccupation, maxStepsNumber,
  } = props;
  return (
    <Container>
      <FormikWithValidation
        onSubmit={handleSubmit}
        initialValues={{ preferredOccupation }}
        enableReinitialize
        validation={validation}
        render={({ handleSubmit: handleSubmitFormik, setFieldValue, isBtnDisabled }) => (
          <Container>
            <GigCreatorHeader
              isEdit={isEdit}
              stepNo={stepNumber}
              maxStepsNumber={maxStepsNumber}
              title={translate('companyGigCreator.preferredOccupation.header')}
              onRightButtonPress={handleSubmitFormik}
              isRightButtonDisabled={isBtnDisabled}
            />
            <Wrapper>
              <PrimaryText style={styles.titleLabel}>
                {translate('companyGigCreator.preferredOccupation.desc')}
                {translate('companyGigCreator.preferredOccupation.additionalInfo')}
              </PrimaryText>

              <FormikFieldSelectList
                items={getAvailableOccupation(translate)}
                name="preferredOccupation"
                multiple
                scroll
                iconPosition="right"
                onValueChange={
                  (val) => {
                    if (val.length > 1 && val.indexOf(OCCUPATION.ALL) === 0) {
                      setFieldValue('preferredOccupation', val.filter((name) => name !== OCCUPATION.ALL));
                    } else if (val.indexOf(OCCUPATION.ALL) === val.length - 1) {
                      setFieldValue('preferredOccupation', [OCCUPATION.ALL]);
                    }
                  }
                }
              />
            </Wrapper>
            <View style={styles.button}>
              {isEdit ? (
                <Button
                  title={translate('generic.save')}
                  disabled={isBtnDisabled}
                  onPress={handleSubmitFormik}
                />
              ) : (
                <LinkButton
                  to={nextScreen}
                  title={translate('generic.next')}
                  onPress={handleSubmitFormik}
                  doRedirect={redirect}
                  disabled={isBtnDisabled}
                />
              )}
            </View>
          </Container>
        )}
      />
    </Container>
  );
}

GigCreatorStepOccupationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  preferredOccupation: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepOccupationScreen);
