import { candidateSignupValidation } from '../../common/auth/UserSignupValidation';
import { companyNameValidator, companyRegistrationNumberValidators } from '../../company/auth/CompanySignupValidation';
import { loginValidation } from '../../common/auth/LoginValidation';

export default [
  ...loginValidation,
  ...candidateSignupValidation,
  ...companyNameValidator,
  ...companyRegistrationNumberValidators,
];
