import { ActivityIndicator, StyleSheet, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import Routing from '../../../../core/routing/index';
import { Button } from '../../../../common/components';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import ScreenHeader from '../../../../common/components/header/ScreenHeader';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import {
  activityIndicator, fontFamily, fontSizes, shadowTop, spacings, heightSpacingsBolek, standardSpacings,
} from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import ViewportController from '../../../../common/components/ViewportController';
import FormikSelectList from '../../../../common/components/form/FormikSelectList';
import FormikFieldCheckBox from '../../../../common/components/form/checkbox/FormikFieldCheckBox';
import CheckBoxBolek from '../../../../common/components/form/checkbox/CheckBoxBolek';
import Wrapper from '../../../../common/components/Wrapper';
import GeneralFormError from '../../../../common/components/form/GeneralFormError';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  buttonsWrapper: {
    paddingTop: spacings.md,
    paddingBottom: heightSpacingsBolek.p20,
    alignItems: 'center',
    ...shadowTop,
  },
  titleLabel: {
    textAlign: 'center',
    ...font(fontFamily.SFProTextSemiBold, fontSizes.md),
    paddingTop: spacings.md,
    marginBottom: spacings.md,
  },
});

const errorStyles = StyleSheet.create({
  wrapper: {
    marginVertical: standardSpacings.formVertical,
  },
});

class ShiftAccessEntitiesStepEditableScreen extends React.PureComponent {
  render() {
    const {
      translate, initialValues, handleSubmit, teams, errors,
    } = this.props;

    const translateByKey = (key) => translate(`company.shifts.shift.${key}`);

    if (!teams) {
      return (<ActivityIndicator size="large" style={{ ...activityIndicator }} />);
    }

    return (
      <ViewportController safeAreaBottom>
        <FormikWithValidation
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
            <>
              <ScreenHeader
                title={translateByKey('accessEntities.accessEntitiesHeader')}
                showBackArrow
                shadow
              />
              <KeyboardAwareFlexWrapper style={{ paddingTop: spacings.md }}>
                <FormikSelectList
                  items={this.props.teams}
                  name="teams"
                  multiple
                  scroll
                  iconPosition="right"
                />
              </KeyboardAwareFlexWrapper>
              <Wrapper noFlex>
                <GeneralFormError all errors={errors} style={errorStyles} />
              </Wrapper>
              <View style={styles.buttonsWrapper}>
                <Button
                  title={translateByKey('publish')}
                  disabled={isBtnDisabled}
                  onPress={handleSubmitFormik}
                  style={{ wrapper: { marginBottom: heightSpacingsBolek.p20 } }}
                />
                <View style={{ alignItems: 'center' }}>
                  <FormikFieldCheckBox
                    CheckBox={CheckBoxBolek}
                    name="saveAsTemplate"
                    label={translateByKey('saveAsTemplate')}
                  />
                </View>
              </View>
            </>
          )}
        />
      </ViewportController>
    );
  }
}

ShiftAccessEntitiesStepEditableScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    teams: PropTypes.arrayOf(PropTypes.string).isRequired,
    saveAsTemplate: PropTypes.bool.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any,
};

ShiftAccessEntitiesStepEditableScreen.defaultProps = {
  teams: null,
  errors: null,
};

export default withLocalize(withRouter(ShiftAccessEntitiesStepEditableScreen));
