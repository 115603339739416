import queryString from 'query-string';

import {
  ADMIN_ASSOCIATIONS_RESET_STATUS,
  ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST,
  ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST,
  ADMIN_ASSOCIATIONS_FIND_FILTER_FORM_SUBMIT,
  ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST,
  ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST,
} from '../../constants/actionTypes';

const ASSOCIATION_LIMIT = 10;

export function adminFindAssociations(params) {
  return {
    type: ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'admin/association/search',
        params: {
          offset: 0,
          limit: ASSOCIATION_LIMIT,
          ...params,
        },
        paramsSerializer: (p) => queryString.stringify(p),
      },
    },
  };
}

export function adminFindAssociationSearch(formValues) {
  return {
    type: ADMIN_ASSOCIATIONS_FIND_FILTER_FORM_SUBMIT,
    formValues,
  };
}

export function postAssociation(data) {
  const formData = new FormData();

  // Append regular data fields
  formData.append('association', data.association);
  formData.append('city', data.city);

  // Append the file if it exists
  if (data.associationCSV instanceof File) {
    formData.append('associationCSV', data.associationCSV);
  }

  return {
    type: ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'admin/association',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data', // Important: Set content type to multipart/form-data
        },
      },
    },
  };
}

export function editAssociation(id, data) {
  return {
    type: ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST,
    payload: {
      request: {
        method: 'put',
        url: `admin/association/${id}`,
        data
      },
    },
  };
}

export function deleteAssociation(id) {
  return {
    type: ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST,
    payload: {
      request: {
        method: 'delete',
        url: `admin/association/${id}`
      },
    },
  };
}

export function adminGetAssociation(id) {
  return {
    type: ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `admin/association/${id}`
      },
    },
  };
}

export function resetStatus() {
  return {
    type: ADMIN_ASSOCIATIONS_RESET_STATUS,
    payload: {},
  };
}
