import React, { Component } from 'react';
import {
  ScrollView, StyleSheet, Text, View,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Routing from '../../../../core/routing/index';
import { showConfirmAlert } from '../../../../common/components/alert/alerts';
import Touchable from '../../../../common/components/Touchable';
import ImageComponent from '../../../../common/components/image/ImageComponent';
import { fontFamily, fontSizes, spacings } from '../../../../common/styles/base.style';
import { font } from '../../../../common/styles/mixins';
import { matchingDataPropType } from '../../../../constants/propTypes';
import getDefinition from './listItemDefinitions';
import { COMPANY_GIG_CHAT } from '../../../../constants/routes';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  actionButton: {
    paddingHorizontal: spacings.xs,
  },
  actionButtonText: {
    marginTop: spacings.xxs,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xxs),
  },
  actionsScrollContainer: {
    paddingHorizontal: spacings.md,
    justifyContent: 'center',
    flexGrow: 1,
  },
  leftGradient: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 40,
  },
  rightGradient: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 40,
  },
});

class GigCandidateActionsSection extends Component {
  startChat(gigId, candidateId) {
    this.props.history.push(COMPANY_GIG_CHAT.replace(':gigId', gigId).replace(':candidateId', candidateId));
  }

  renderAction(action) {
    if (!action.supportedContexts.includes(this.props.context)) {
      return null;
    }
    const {
      dispatchAction, postAction, gigId, candidate, internalFunctions, translate, history, grantedFeatures,
    } = this.props;
    internalFunctions.dispatch = dispatchAction;
    internalFunctions.translate = translate;
    internalFunctions.history = history;
    internalFunctions.postAction = postAction;
    internalFunctions.startChat = () => this.startChat(gigId, candidate.userId);
    const params = {
      gigId,
      candidate,
      candidateId: candidate.userId,
      companyHasAccess: candidate.companyHasAccess,
      grantedFeatures,
    };
    const actionfnc = action.getAction(params, internalFunctions);
    if (actionfnc == null) {
      return null;
    }
    const onPressFnc = action.confirmWithAlert ? () => showConfirmAlert(translate, action.alertParams, actionfnc) : actionfnc;
    return (
      <View key={action.label} style={styles.actionButton}>
        <Touchable onPress={onPressFnc}>
          <ImageComponent size="xxs" image={action.icon} />
          <Text style={styles.actionButtonText}>{translate(action.label)}</Text>
        </Touchable>
      </View>
    );
  }

  render() {
    const itemDefinition = getDefinition(this.props.candidate.matching);
    const activeActions = itemDefinition.actions.filter((action) => {
      if (action.isActive) {
        return action.isActive(this.props.candidate, this.props.grantedFeatures);
      }
      return true;
    });

    return (
      <>
        <ScrollView
          horizontal
          bounces={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.actionsScrollContainer}
        >
          {activeActions.map((action) => this.renderAction(action))}
        </ScrollView>
        <LinearGradient
          colors={['rgba(255,255,255,1)', 'rgba(255,255,255,0)']}
          start={[0, 0]}
          end={[1, 0]}
          style={styles.leftGradient}
        />
        <LinearGradient
          colors={['rgba(255,255,255,1)', 'rgba(255,255,255,0)']}
          start={[1, 0]}
          end={[0, 0]}
          style={styles.rightGradient}
        />
      </>
    );
  }
}

GigCandidateActionsSection.propTypes = {
  candidate: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    matching: matchingDataPropType,
    companyHasAccess: PropTypes.bool.isRequired,
  }).isRequired,
  gigId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatchAction: PropTypes.func.isRequired,
  postAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  internalFunctions: PropTypes.object,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  context: PropTypes.string,
};

GigCandidateActionsSection.defaultProps = {
  postAction: null,
  internalFunctions: {},
  context: 'GIG_CANDIDATES_LIST',
};

export const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (action) => dispatch(action),
});

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLocalize(GigCandidateActionsSection)));
