import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Sentry from './SentryExpo';

class SentryProvider extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.setupUserInSentry();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authenticated === false && this.props.authenticated === true) {
      this.setupUserInSentry();
    } else if (prevProps.authenticated === true && this.props.authenticated === false) {
      this.clearUserInSentry();
    }
  }

  setupUserInSentry = () => {
    try {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: this.props.userId,
        });
        scope.setExtra('scope', this.props.userScope);
      });
    } catch (err) { console.error(err); }
  };

  clearUserInSentry = () => {
    try {
      Sentry.configureScope((scope) => {
        scope.setUser(null);
        scope.setExtra('scope', undefined);
      });
    } catch (err) { console.error(err); }
  };

  render() {
    return null;
  }
}

SentryProvider.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  userScope: PropTypes.string,
  userId: PropTypes.string,
};
SentryProvider.defaultProps = {
  userId: undefined,
  userScope: undefined,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.get('authenticated'),
  userScope: state.auth.get('userScope'),
  userId: state.auth.get('userId'),
});

export default connect(mapStateToProps)(SentryProvider);
