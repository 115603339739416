import React, { PureComponent } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';
import { formatHowLongAgo } from '../../utilities/dateFormatter';
import Container from '../../common/components/Container';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../common/styles/base.style';
import Touchable from '../../common/components/Touchable';
import ChatUnreadIndicator from '../../common/components/chat/ChatUnreadIndicator';
import ChatNoAccessIndicator from '../../common/components/chat/ChatNoAccessIndicator';
import ChatListItemProfileImage from '../../common/components/chat/ChatListItemProfileImage';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacings.lg,
    marginHorizontal: spacings.sm,
  },
  infoLine: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },
  name: {
    flex: 1,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayRegular,
  },
  unreadName: {
    fontFamily: fontFamily.SFProDisplayBold,
  },
  msg: {
    flex: 1,
    fontSize: fontSizes.md,
    color: colors.lightPeach,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginRight: spacings.xxxl,
  },
  unreadMsg: {
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.greyishBrown,
  },
  noAccessMsg: {
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.greyishBrown,
  },
  date: {
    fontSize: fontSizes.sm,
    color: colors.brownGrey,
    fontFamily: fontFamily.SFProDisplayBold,
  },
});

class CompanyChatGigCandidatesListItem extends PureComponent {
  onPress = () => {
    const {
      gigId, chatId, candidateId, position, name,
    } = this.props;
    this.props.onPress(gigId, chatId, candidateId, position, name);
  };

  render() {
    const {
      candidateName, companyHasAccess, lastMessageDate, lastMessage, unread, online, grantedFeatures, candidateProfileImageUri, translate,
    } = this.props;
    const isPaymentFeatureTurnedOn = grantedFeatures.includes('PAYMENTS');
    const accessLimited = isPaymentFeatureTurnedOn && !companyHasAccess;
    return (
      <Touchable onPress={this.onPress}>
        <View style={styles.container}>
          <ChatListItemProfileImage profileImageUri={candidateProfileImageUri} online={online} />
          <Container>
            <View style={[styles.infoLine]}>
              <Text style={[styles.name, unread && styles.unreadName]}>{candidateName}</Text>
              <Text style={[styles.date, unread && styles.unread]}>{formatHowLongAgo(lastMessageDate)}</Text>
            </View>
            <View style={styles.infoLine}>
              <Text
                numberOfLines={1}
                style={[styles.msg, accessLimited ? styles.noAccessMsg : unread && styles.unreadMsg]}
              >
                {accessLimited ? translate('generic.clickHere') : lastMessage}
              </Text>
              {!accessLimited && unread && <ChatUnreadIndicator />}
              {accessLimited && <ChatNoAccessIndicator />}
            </View>
          </Container>
        </View>
      </Touchable>
    );
  }
}

CompanyChatGigCandidatesListItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  gigId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  companyHasAccess: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  lastMessageDate: PropTypes.number.isRequired,
  lastMessage: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  chatId: PropTypes.string.isRequired,
  candidateProfileImageUri: PropTypes.string,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  online: PropTypes.bool,
  translate: PropTypes.func.isRequired,
};

CompanyChatGigCandidatesListItem.defaultProps = {
  candidateProfileImageUri: null,
  online: false,
};

export default withLocalize(CompanyChatGigCandidatesListItem);
