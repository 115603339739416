import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { clearGigCandidates, getGigCandidates, storeMyGigsScrollPosition } from '../companyGigCandidatesActions';
import { gigCandidatePropType } from '../../../../../constants/propTypes';
import { GIG_CANDIDATE_STATUS } from '../../../../../constants/matchingStatus';
import { HISTORY_LIST_KEY } from '../companyGigCandidatesReducer';
import Routing from '../../../../../core/routing/index';
import candidatesListStyles from '../components/GigCandidatesListStyle';
import PrimaryFlatList from '../../../../../common/components/PrimaryFlatList';
import CompanyGigCandidateListItem from '../CompanyGigCandidateListItem';
import ListItem from '../../../../../common/components/ListItem';
import Container from '../../../../../common/components/Container';

const { withRouter } = Routing;

class CompanyGigHistoryView extends Component {
  componentDidMount() {
    this.scrollPosition = 0;
    this.props.getGigCandidates(
      this.props.match.params.id,
      [GIG_CANDIDATE_STATUS.REJECTED_BY_CANDIDATE, GIG_CANDIDATE_STATUS.REJECTED_BY_COMPANY],
      HISTORY_LIST_KEY,
    ).then(() => {
      if (this.props.history.action === 'POP') {
        const { storedScrollPosition } = this.props;
        if (storedScrollPosition) {
          this.scrollToLastKnownPosition(storedScrollPosition);
        }
      } else {
        this.props.storeMyGigsScrollPosition(this.scrollPosition);
      }
    });
  }

  componentWillUnmount() {
    this.props.storeMyGigsScrollPosition(this.scrollPosition);
    this.props.clearGigCandidates(HISTORY_LIST_KEY);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToOffset({ offset: scrollPosition, animated: true }), 0);
  }

  render() {
    return (
      <Container>
        <PrimaryFlatList
          getItemLayout={(data, index) => ({ length: 275, offset: index * 275, index })} // fix for not scrolling over ~1300 pixels
          emptyListTextKey="companyGigs.candidates.noHistory"
          contentContainerStyle={candidatesListStyles.contentContainerStyle}
          data={this.props.candidates}
          keyExtractor={(item) => item.userId}
          onScroll={this.onScroll}
          innerRef={this.setListRef}
          renderItem={({ item: candidate }) => (
            <ListItem
              renderContent={() => (
                <CompanyGigCandidateListItem
                  candidate={candidate}
                  gigId={this.props.match.params.id}
                  online={this.props.usersPresence[candidate.userId]}
                />
              )}
            />
          )}
        />
      </Container>
    );
  }
}

CompanyGigHistoryView.propTypes = {
  getGigCandidates: PropTypes.func.isRequired,
  clearGigCandidates: PropTypes.func.isRequired,
  candidates: PropTypes.arrayOf(gigCandidatePropType),
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  storedScrollPosition: PropTypes.number.isRequired,
  storeMyGigsScrollPosition: PropTypes.func.isRequired,
};

CompanyGigHistoryView.defaultProps = {
  candidates: null,
};

const mapStateToProps = (state) => ({
  candidates: state.gigCandidates.get(HISTORY_LIST_KEY) ? state.gigCandidates.get(HISTORY_LIST_KEY).toJS() : null,
  storedScrollPosition: state.gigCandidates.get('companyMyGigsScrollPosition'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default withRouter(connect(mapStateToProps, { getGigCandidates, clearGigCandidates, storeMyGigsScrollPosition })(CompanyGigHistoryView));
