import {
  CANDIDATE_APPROVE_SHORTLISTING_REQUEST,
  CANDIDATE_CLEAR_CANDIDATE_GIGS,
  CANDIDATE_GET_CANDIDATE_GIGS_REQUEST,
  CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST,
  CANDIDATE_REJECT_REQUEST,
  CANDIDATE_ARCHIVE_GIG_REQUEST,
  CANDIDATE_APPLY_FOR_GIG_REQUEST,
  CANDIDATE_ACCEPT_OFFER_REQUEST,
  CANDIDATE_GET_OFFER_REQUEST,
  CANDIDATE_ADD_NEW_CONTENT_INDICATOR,
  CANDIDATE_REMOVE_NEW_CONTENT_INDICATOR,
} from '../../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../../common/analytics/googleAnalytics';

const handleGigValidationError = {
  titleKey: 'candidateMyGigs.errors.genericErrorTitle',
  textKey: 'candidateMyGigs.errors.gigStateChanged',
};

export function getCandidateGigs(listKey, statuses, archived) {
  return {
    type: CANDIDATE_GET_CANDIDATE_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/gig/my',
        params: {
          ...((statuses && statuses.length) ? { statuses } : {}),
          ...(archived !== undefined ? { archived } : {}),
        },
      },
    },
    listKey,
  };
}

export function countCandidateGigs() {
  return {
    type: CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/gig/my/count',
      },
    },
  };
}

export function approveShortlisting({ gigId, questionnaire }, { context, listKey }) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.CANDIDATE_APPROVE_SHORTLISTING);
  return {
    type: CANDIDATE_APPROVE_SHORTLISTING_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/gig/${gigId}/approveShortlisting`,
        data: { questionnaire },
      },
    },
    gigId,
    context,
    listKey,
    handleValidationError: handleGigValidationError,
  };
}

export function getGigOffer(gigId) {
  return {
    type: CANDIDATE_GET_OFFER_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/candidate/gig/${gigId}/offer`,
      },
    },
  };
}

export function acceptOffer(gigId, { context, listKey }) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.CANDIDATE_ACCEPT_OFFER);
  return {
    type: CANDIDATE_ACCEPT_OFFER_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/gig/${gigId}/acceptOffer`,
      },
    },
    gigId,
    context,
    listKey,
    handleValidationError: handleGigValidationError,
  };
}

export function rejectGig(gigId, { context, listKey }) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.CANDIDATE_REJECT);
  return {
    type: CANDIDATE_REJECT_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/gig/${gigId}/reject`,
      },
    },
    gigId,
    context,
    listKey,
    handleValidationError: handleGigValidationError,
  };
}

export function applyForGig({ gigId }, context, questionnaire) {
  googleAnalytics.trackGigEvent(gigId, ACTIONS.GIG.CANDIDATE_APPLY);
  return {
    type: CANDIDATE_APPLY_FOR_GIG_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/gig/${gigId}/apply`,
        data: questionnaire,
      },
    },
    gigId,
    context,
    handleValidationError: handleGigValidationError,
  };
}

export function archiveGig(gigId, { listKey, context }) {
  return {
    type: CANDIDATE_ARCHIVE_GIG_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/gig/${gigId}/archive`,
      },
    },
    gigId,
    listKey,
    context,
    handleValidationError: handleGigValidationError,
  };
}

export function clearCandidateGigs(listKey) {
  return {
    type: CANDIDATE_CLEAR_CANDIDATE_GIGS,
    listKey,
  };
}

export function addMyGigsNewContentIndicator(item) {
  return {
    type: CANDIDATE_ADD_NEW_CONTENT_INDICATOR,
    item,
  };
}

export function removeNewContentIndicator(item) {
  return {
    type: CANDIDATE_REMOVE_NEW_CONTENT_INDICATOR,
    item,
  };
}
