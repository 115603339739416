import { Component } from 'react';
import { Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { shareGig } from '../../common/share/share';
import { CLOSED } from '../../constants/gigStatus';
import { closeGig } from './companyGigActions';
import { successNotification } from '../../common/notifications_center/notificationCenterActions';
import { gigPropType } from '../../constants/propTypes';
import { showConfirmAlert } from '../../common/components/alert/alerts';

class CompanyGigActionSheet extends Component {
  componentDidMount() {
    // Child passes its method to the parent
    this.props.registerOpenActionSheetFunction(this.getOpenActionSheetFunction);
  }

  onCloseGigPress = () => {
    showConfirmAlert(this.props.translate, {
      confirmKey: 'generic.confirm',
      cancelKey: 'generic.cancel',
      textKey: 'companyGigs.details.closeGigPopup.text',
      titleKey: 'companyGigs.details.closeGigPopup.title',
    }, () => {
      this.closeGig();
    });
  };

  getOpenActionSheetFunction = () => {
    const { translate, gig } = this.props;

    const actionSheetParams = { options: [] };
    const actionHandlers = [];
    if (Platform.OS !== 'web') {
      const size = actionSheetParams.options.push(translate('generic.share'));
      actionHandlers[size - 1] = async () => {
        await shareGig(gig.shortId);
      };
    }
    if (gig.status !== CLOSED) {
      const size = actionSheetParams.options.push(translate('companyGigs.details.closeGigBtn'));
      actionSheetParams.destructiveButtonIndex = size - 1;
      actionHandlers[size - 1] = () => {
        this.onCloseGigPress();
      };
    }
    const size = actionSheetParams.options.push(translate('generic.cancel'));
    actionSheetParams.cancelButtonIndex = size - 1;
    actionHandlers[size - 1] = () => {};

    this.props.showActionSheetWithOptions(
      {
        ...actionSheetParams,
        showSeparators: true,
      },
      (buttonIndex) => {
        actionHandlers[buttonIndex]();
      },
    );
  };

  closeGig = () => {
    this.props.closeGig(this.props.gig.gigId).then((res) => {
      this.props.postCloseCallback(res.payload);
      this.props.successNotification('company.gig.gigHasBeenClosed');
    });
  };

  render() {
    return null;
  }
}

CompanyGigActionSheet.propTypes = {
  translate: PropTypes.func.isRequired,
  showActionSheetWithOptions: PropTypes.func.isRequired,
  gig: gigPropType,
  registerOpenActionSheetFunction: PropTypes.func.isRequired,
  postCloseCallback: PropTypes.func.isRequired,
  closeGig: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
};

CompanyGigActionSheet.defaultProps = {
  gig: null,
};

export default connect(null, { closeGig, successNotification })(connectActionSheet(withLocalize(CompanyGigActionSheet)));
