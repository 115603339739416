import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { APPLIED_LIST_KEY } from '../candidateMyGigsReducer';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import { clearCandidateGigs, getCandidateGigs } from '../candidateMyGigsActions';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import ListItem from '../../../../common/components/ListItem';
import GigListItem from '../GigListItem';

class CandidateMyGigsAppliedView extends Component {
  componentDidMount() {
    this.props.getCandidateGigs(APPLIED_LIST_KEY, [GIG_CANDIDATE_STATUS.APPLIED], false);
  }

  componentWillUnmount() {
    this.props.clearCandidateGigs(APPLIED_LIST_KEY);
  }

  render() {
    return (
      <PrimaryFlatList
        emptyListTextKey="candidateMyGigs.noApplied"
        data={this.props.gigsApplied}
        keyExtractor={(item) => item.gigId}
        renderItem={({ item: matchedGig }) => (
          <ListItem
            renderContent={() => (
              <GigListItem
                gig={matchedGig}
                online={this.props.usersPresence[matchedGig.createdBy.userId]}
              />
            )}
          />
        )}
      />
    );
  }
}

CandidateMyGigsAppliedView.propTypes = {
  getCandidateGigs: PropTypes.func.isRequired,
  clearCandidateGigs: PropTypes.func.isRequired,
  gigsApplied: PropTypes.arrayOf(matchedGigForCandidatePropType),
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

CandidateMyGigsAppliedView.defaultProps = {
  gigsApplied: null,
};

const mapStateToProps = (state) => ({
  gigsApplied: state.candidateMyGigs.get(APPLIED_LIST_KEY) ? state.candidateMyGigs.get(APPLIED_LIST_KEY).toJS() : null,
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(mapStateToProps, { getCandidateGigs, clearCandidateGigs })(CandidateMyGigsAppliedView);
