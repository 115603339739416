import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Animated, StyleSheet, Text, View, Dimensions, PanResponder, Platform } from 'react-native';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import shallowequal from 'shallowequal';

import {
  clearGigList,
  candidateFindGigsWithInterestingFilter,
  candidateFindGigsSetCurrentIndex,
  addFavouriteGig,
  addNotInterestingGig,
  GIGS_LIMIT,
  candidateSetShowFavouritesModal,
  candidateSetShowInfoModal,
} from './candidateFindGigsActions';
import { candidateProfilePropType, gigPropType } from '../../../constants/propTypes';
import {
  fontFamily,
  fontSizes,
  standardColors,
  colors,
  spacings,
  wp,
  webContentPercentWidth,
  contentWidth,
} from '../../../common/styles/base.style';
import Routing from '../../../core/routing/index';
import { CITY_OPTION_GIGS_ANYWHERE } from './filters/candidatesFindGigsFiltersHelpers';
import SingleFindGigsComponent from './SingleFindGigsComponent';
import SingleFindGigsButtons from './SingleFindGigsButtons';
import ProposeFavouritesTabModalView from './popup/ProposeFavouritesTabModalView';
import { font } from '../../../common/styles/mixins';
import ImageComponent from '../../../common/components/image/ImageComponent';
import { BOX_BORDER_RADIUS, BOX_MARGIN_HORIZONTAL, BOX_MARGIN_TOP } from './single/SingleFindGigsItemStyles';
import SingleGigInfoModalView from './popup/SingleGigInfoModalView';
import { FEATURE_TRACKING_FEATURES_SHOWN } from '../../../common/featureTracking/featureTrackingReducer';
import { candidateClearSimilarGigs } from '../candidateSimilarGigsActions';
import { showAlertMessage } from '../../../common/components/alert/alerts';
import { CANDIDATE_PROFILE_EDIT } from '../../../constants/routes';

const likeIcon = require('../../../assets/icons/singlegigview/100_heart.png');
const rejectIcon = require('../../../assets/icons/singlegigview/100_x.png');

const SCREEN_HEIGHT = Dimensions.get('window').height;
const SCREEN_WIDTH = Platform.OS === 'web' ? wp(webContentPercentWidth) : contentWidth;

const { withRouter } = Routing;

const LOAD_MORE_THRESHOLD = 3;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  container: {
    paddingBottom: spacings.xs,
    flex: 1,
    backgroundColor: colors.smokeWhite,
    overflow: 'hidden',
  },
  labelWrapper: {
    position: 'absolute',
    right: BOX_MARGIN_HORIZONTAL,
    top: BOX_MARGIN_TOP,
    borderRadius: BOX_BORDER_RADIUS,
    zIndex: 1001,
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelImage: { width: 50, height: 50, resizeMode: 'contain' },
  labelText: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxl),
    color: colors.white,
  },
});

const DO_ACTION_MOVE_THRESHOLD = 120;
const MOVE_BOX_OUT_OF_SCREEN_EXTRA = 50;
const FINISH_ACTION_MOVE_SPEED = 250;
const BUTTON_TOUCH_MOVE_SPEED = 500;
const MOVE_BACK_FRICTION = 5;
const SINGLE_TOUCH_THRESHOLD = 2;
const OVERLAY_TARGET_OPACITY = 0.7;
// to prevent overlay showing when springing the box back
const OVERLAY_OPACITY_MOVE_THRESHOLD = 10;

class SingleFindGigsScreen extends Component {
  constructor() {
    super();
    this.state = {};
    this.position = new Animated.ValueXY();

    this.rotate = this.position.x.interpolate({
      inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
      outputRange: ['-30deg', '0deg', '10deg'],
      extrapolate: 'clamp',
    });

    this.rotateAndTranslate = {
      transform: [
        {
          rotate: this.rotate,
        },
        ...this.position.getTranslateTransform(),
      ],
    };

    this.likeOpacity = this.position.x.interpolate({
      inputRange: [-SCREEN_WIDTH / 2.5, -OVERLAY_OPACITY_MOVE_THRESHOLD, 0, OVERLAY_OPACITY_MOVE_THRESHOLD, SCREEN_WIDTH / 2.5],
      outputRange: [0, 0, 0, 0, OVERLAY_TARGET_OPACITY],
      extrapolate: 'clamp',
    });
    this.dislikeOpacity = this.position.x.interpolate({
      inputRange: [-SCREEN_WIDTH / 2.5, -OVERLAY_OPACITY_MOVE_THRESHOLD, 0, OVERLAY_OPACITY_MOVE_THRESHOLD, SCREEN_WIDTH / 2.5],
      outputRange: [OVERLAY_TARGET_OPACITY, 0, 0, 0, 0],
      extrapolate: 'clamp',
    });

    this.nextCardOpacity = this.position.x.interpolate({
      inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
      outputRange: [1, 0, 1],
      extrapolate: 'clamp',
    });
    this.nextCardScale = this.position.x.interpolate({
      inputRange: [-SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2],
      outputRange: [1, 0.8, 1],
      extrapolate: 'clamp',
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.PanResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (evt, gestureState) => {
        this.position.setValue({ x: gestureState.dx, y: gestureState.dy });
      },
      onPanResponderRelease: (evt, gestureState) => {
        if (gestureState.dx > DO_ACTION_MOVE_THRESHOLD) {
          this.animateLike(gestureState.dy, FINISH_ACTION_MOVE_SPEED, false);
        } else if (gestureState.dx < -DO_ACTION_MOVE_THRESHOLD) {
          this.animateReject(gestureState.dy, FINISH_ACTION_MOVE_SPEED, false);
        } else {
          this.moveInProgress = true;
          Animated.spring(this.position, {
            toValue: { x: 0, y: 0 },
            friction: MOVE_BACK_FRICTION,
            useNativeDriver: true,
          }).start(() => {
            this.moveInProgress = false;
          });
        }
      },
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        const { dx, dy } = gestureState;
        return dx > SINGLE_TOUCH_THRESHOLD || dx < -SINGLE_TOUCH_THRESHOLD || dy > SINGLE_TOUCH_THRESHOLD || dy < -SINGLE_TOUCH_THRESHOLD;
      },
    });
  }

  componentDidMount() {
    if (['PUSH', 'REPLACE'].includes(this.props.history.action)) {
      this.loadGigs();
    }
    this.props.candidateClearSimilarGigs();
    this.checkRequirement();
  }

  componentDidUpdate(prevProps) {
    if (!this.loadingMore && this.props.currentIndex !== 0 && this.props.currentIndex + LOAD_MORE_THRESHOLD >= this.props.gigs.length) {
      this.loadingMore = true;
      this.getGigsRequest(false, this.props.offset).then(() => {
        this.loadingMore = false;
      });
    }
    if (
      prevProps.cityKey !== this.props.cityKey
      || !shallowequal(prevProps.categories, this.props.categories)
      || prevProps.positionSearch !== this.props.positionSearch
    ) {
      this.loadGigs();
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    if (!this.userOpenedGigDetailsView(history)) {
      this.props.clearGigList();
    }
  }

  checkRequirement = () => {
    const { translate, profile } = this.props;
    // check if profile prop has changed
    if (profile) {
      const { bankAccountNo, clearingNumber, personalNumber, msisdn } = profile;
      // fetch some data based on profile
      if (!bankAccountNo || !clearingNumber || !personalNumber || !msisdn) {
        showAlertMessage(translate, 'generic.alert.requireProfile.button', 'generic.alert.requireProfile.text', 'generic.alert.requireProfile.title', () => this.props.history.push(CANDIDATE_PROFILE_EDIT));
      }
    }
  };

  animateLike = (dy, duration, isButtonClick) => {
    if (isButtonClick && this.moveInProgress) {
      return;
    }

    this.moveInProgress = true;
    Animated.timing(this.position, {
      toValue: { x: SCREEN_WIDTH + MOVE_BOX_OUT_OF_SCREEN_EXTRA, y: dy },
      duration,
      useNativeDriver: true,
    }).start(() => {
      this.applyDecision(true);
      this.position.setValue({ x: 0, y: 0 });
      this.moveInProgress = false;
    });
  };

  animateReject = (dy, duration, isButtonClick) => {
    if (isButtonClick && this.moveInProgress) {
      return;
    }

    this.moveInProgress = true;
    Animated.timing(this.position, {
      toValue: { x: -SCREEN_WIDTH - MOVE_BOX_OUT_OF_SCREEN_EXTRA, y: dy },
      duration,
      useNativeDriver: true,
    }).start(() => {
      this.applyDecision(false);
      this.position.setValue({ x: 0, y: 0 });
      this.moveInProgress = false;
    });
  };

  handleBoxOnLayout = (event) => {
    const { width, height } = event.nativeEvent.layout;
    this.setState({ boxSize: { width, height } });
  };

  renderGigs = () => this.props.gigs
      .map((gig, i) => {
        const { currentIndex } = this.props;

        if (i === currentIndex) {
          return (
            <Animated.View
              {...this.PanResponder.panHandlers}
              key={gig.gigId}
              style={[
                this.rotateAndTranslate,
                {
                  height: SCREEN_HEIGHT - 120,
                  width: SCREEN_WIDTH,
                  position: 'absolute',
                },
              ]}
            >
              <Animated.View
                style={[
                  styles.labelWrapper,
                  {
                    opacity: this.likeOpacity,
                    backgroundColor: colors.lipstick,
                    width: this.state.boxSize ? this.state.boxSize.width : 0,
                    height: this.state.boxSize ? this.state.boxSize.height : 0,
                  },
                ]}
                pointerEvents="none"
              >
                <ImageComponent image={likeIcon} style={{ image: styles.labelImage }} />
              </Animated.View>
              <Animated.View
                style={[
                  styles.labelWrapper,
                  {
                    opacity: this.dislikeOpacity,
                    backgroundColor: colors.black,
                    width: this.state.boxSize ? this.state.boxSize.width : 0,
                    height: this.state.boxSize ? this.state.boxSize.height : 0,
                  },
                ]}
                pointerEvents="none"
              >
                <ImageComponent image={rejectIcon} style={{ image: styles.labelImage }} />
              </Animated.View>
              <SingleFindGigsComponent
                gig={gig}
                translate={this.props.translate}
                history={this.props.history}
                onLayout={this.handleBoxOnLayout}
              />
            </Animated.View>
          );
        }

        if (i === currentIndex + 1) {
          return (
            <Animated.View
              key={gig.gigId}
              style={[
                {
                  opacity: this.nextCardOpacity,
                  transform: [{ scale: this.nextCardScale }],
                  height: SCREEN_HEIGHT - 120,
                  width: SCREEN_WIDTH,
                  position: 'absolute',
                },
              ]}
            >
              <SingleFindGigsComponent gig={gig} translate={this.props.translate} history={this.props.history} />
            </Animated.View>
          );
        }

        return null;
      })
      .reverse();

  getGigsRequest = (reload, offset) => {
    const { cityKey, categories, positionSearch } = this.props;
    return this.props.candidateFindGigsWithInterestingFilter(
      reload,
      offset,
      categories,
      cityKey === CITY_OPTION_GIGS_ANYWHERE ? null : cityKey,
      positionSearch,
      true,
      GIGS_LIMIT + LOAD_MORE_THRESHOLD
    );
  };

  userOpenedGigDetailsView = (history) => {
    const detailsPath = '/candidate/gigs/.*/details';
    if (Platform.OS === 'web') {
      return history.location.pathname.match(detailsPath) != null;
    }

    return (
      history && history.entries && history.entries.length > 0 && history.entries[history.entries.length - 1].pathname.match(detailsPath) != null
    );
  };

  loadGigs = () => {
    this.props.candidateFindGigsSetCurrentIndex(0);
    this.getGigsRequest(true, 0);
  };

  applyDecision = (like) => {
    const { currentIndex, gigs } = this.props;
    const { gigId } = gigs[currentIndex];
    if (like) {
      this.props.addFavouriteGig(gigId, true);
    } else {
      this.props.addNotInterestingGig(gigId, true);
    }
    this.props.candidateFindGigsSetCurrentIndex(currentIndex + 1);
  };

  moveBack = () => {
    const { currentIndex } = this.props;
    this.props.candidateFindGigsSetCurrentIndex(currentIndex - 1);
  };
  shouldNotShowFavouritesPopUp = () => {
    this.props.candidateSetShowFavouritesModal(false);
  };

  renderFavouritesModalView = () => {
    const { translate, showFavouritesModal, history } = this.props;
    return (
      <ProposeFavouritesTabModalView
        visible={showFavouritesModal}
        translate={translate}
        closeModal={this.shouldNotShowFavouritesPopUp}
        history={history}
      />
    );
  };

  renderInfoModalView = () => {
    const { translate, showInfoModal } = this.props;
    return (
      <SingleGigInfoModalView visible={showInfoModal} translate={translate} closeModal={() => this.props.candidateSetShowInfoModal(false)} />
    );
  };

  render() {
    const { allLoaded, canMoveBack, gigs, currentIndex } = this.props;

    if (allLoaded && (!gigs.length || gigs.length <= currentIndex)) {
      return (
        <Text style={styles.noGigs}>
          <Translate id="candidateFindGigs.noGigs" />
        </Text>
      );
    }

    if (!gigs.length) {
      return null;
    }

    return (
      <>
        <View style={styles.container}>
          <View style={{ flex: 1 }}>{this.renderGigs()}</View>
          <SingleFindGigsButtons
            canMoveBack={canMoveBack}
            moveBack={this.moveBack}
            like={() => this.animateLike(0, BUTTON_TOUCH_MOVE_SPEED, true)}
            reject={() => this.animateReject(0, BUTTON_TOUCH_MOVE_SPEED, true)}
            showInfo={() => this.props.candidateSetShowInfoModal(true)}
          />
        </View>
        {this.renderFavouritesModalView()}
        {this.renderInfoModalView()}
      </>
    );
  }
}

SingleFindGigsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  cityKey: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  positionSearch: PropTypes.string,

  clearGigList: PropTypes.func.isRequired,
  candidateFindGigsWithInterestingFilter: PropTypes.func.isRequired,
  addFavouriteGig: PropTypes.func.isRequired,
  addNotInterestingGig: PropTypes.func.isRequired,
  candidateFindGigsSetCurrentIndex: PropTypes.func.isRequired,
  candidateSetShowFavouritesModal: PropTypes.func.isRequired,
  candidateSetShowInfoModal: PropTypes.func.isRequired,
  candidateClearSimilarGigs: PropTypes.func.isRequired,

  gigs: PropTypes.arrayOf(gigPropType).isRequired,
  offset: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  showFavouritesModal: PropTypes.bool.isRequired,
  showInfoModal: PropTypes.bool.isRequired,
  allLoaded: PropTypes.bool.isRequired,
  canMoveBack: PropTypes.bool.isRequired,
  profile: candidateProfilePropType,
};

SingleFindGigsScreen.defaultProps = {
  positionSearch: undefined,
  categories: undefined,
  profile: undefined,
};

const mapStateToProps = (state) => {
  let selectedCategories = state.candidateFindGigsFilters.get('categories');
  let selectedCity = state.candidateFindGigsFilters.get('cityKey');

  if (!selectedCategories) {
    selectedCategories = state.candidate.get('profile').get('categories');
  }

  if (!selectedCity) {
    const location = state.candidate.get('profile').get('location');
    selectedCity = (location && location.get('cityV2').get('key')) || CITY_OPTION_GIGS_ANYWHERE;
  }

  return {
    offset: state.candidateFindGigs.get('offset'),
    currentIndex: state.candidateFindGigs.get('currentIndex'),
    favouritesCount: state.candidateFindGigs.get('favouritesCount') || 0,
    allLoaded: state.candidateFindGigs.get('allLoaded'),
    gigs: state.candidateFindGigs.get('gigs').toJS(),
    canMoveBack: state.candidateFindGigs.get('canMoveBack'),
    cityKey: selectedCity,
    categories: selectedCategories.toJS(),
    positionSearch: state.candidateFindGigsFilters.get('positionSearch'),
    showFavouritesModal: state.candidateFindGigs.get('showFavouritesModal'),
    showInfoModal:
      state.candidateFindGigs.get('showInfoModal') || state.featureTracking.get(FEATURE_TRACKING_FEATURES_SHOWN.FIND_GIG_SWIPE_SHOWN) === false,
    profile: state.candidate.get('profile').toJS(),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    candidateFindGigsSetCurrentIndex,
    candidateSetShowFavouritesModal,
    clearGigList,
    candidateFindGigsWithInterestingFilter,
    addFavouriteGig,
    addNotInterestingGig,
    candidateSetShowInfoModal,
    candidateClearSimilarGigs,
  })(withLocalize(SingleFindGigsScreen))
);
