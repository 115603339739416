import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { dynamicSpacings } from '../styles/base.style';
import { stylePropTypes } from '../../constants/propTypes';

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    alignSelf: 'stretch',
  },
  flexOne: {
    flex: 1,
  },
  paddingHorizontal: {
    paddingHorizontal: dynamicSpacings.md,
  },
});

const Wrapper = ({
  children, style, paddingHorizontal, noFlex,
}) => {
  function getPaddingHorizontal() {
    if (paddingHorizontal === false) {
      return null;
    }
    return styles.paddingHorizontal;
  }

  return (
    <View style={[
      styles.wrapper,
      getPaddingHorizontal(),
      noFlex ? null : styles.flexOne,
      style,
    ]}
    >
      {children}
    </View>
  );
};

Wrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  paddingHorizontal: PropTypes.bool,
  noFlex: PropTypes.bool,
  style: stylePropTypes,
};

Wrapper.defaultProps = {
  paddingHorizontal: true,
  noFlex: false,
  style: null,
};

export default Wrapper;
