import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { SUPERADMIN_USER } from '../../constants/userScopes';
import { ADMIN_ASSOC_DETAILS, ADMIN_ASSOCIATIONS, ADMIN_NEW_ASSOCIATIONS } from '../../constants/routes';
import AdminFindAssociationsContainer from './find_associations/AdminFindAssociationsContainer';
import AdminNewAssociationContainer from './find_associations/AdminNewAssociationContainer';
import AdminEditAssociationContainer from './find_associations/AdminEditAssociationContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_ASSOCIATIONS;

const AdminAssociationsRouting = () => (
  <>
    <Route exact path={ADMIN_ASSOCIATIONS} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={FIRST_SCREEN} component={AdminFindAssociationsContainer} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_NEW_ASSOCIATIONS} component={AdminNewAssociationContainer} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_ASSOC_DETAILS} component={AdminEditAssociationContainer} />
  </>
);

export default AdminAssociationsRouting;
