import React from 'react';
import PropTypes from 'prop-types';

export const PrintingContext = React.createContext({ isPrint: false });

export const PrintingContextProvider = (props) => (
  <PrintingContext.Provider value={props}>
    {props.children}
  </PrintingContext.Provider>
);

PrintingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
