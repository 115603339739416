import { List, fromJS } from 'immutable';
import { GIG_CITIES_REQUEST_SUCCESS, GIG_MUNICIPALITIES_REQUEST_SUCCESS } from '../../../constants/actionTypes';

export default function gigCitiesReducer(state = List(), action) {
  switch (action.type) {
    case GIG_CITIES_REQUEST_SUCCESS:
      return fromJS(action.payload.cities.sort((a, b) => a.order - b.order));
    case GIG_MUNICIPALITIES_REQUEST_SUCCESS:
      return fromJS(action.payload.municipalities.sort((a, b) => a.order - b.order));
    default:
      return state;
  }
}
