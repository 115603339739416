import React from 'react';
import { StyleSheet, Image } from 'react-native';

const lockImg = require('../../../assets/icons/lock.png');

const styles = StyleSheet.create({
  style: {
    width: 19,
    height: 19,
  },
});

const ChatNoAccessIndicator = () => (
  <Image style={styles.style} source={lockImg} />
);

export default ChatNoAccessIndicator;
