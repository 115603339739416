import { StyleSheet } from 'react-native';
import { font } from '../../../../common/styles/mixins';
import {
  colors, fontFamily, fontSizes, hp, spacings, windowHeight,
} from '../../../../common/styles/base.style';

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  firstLineContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  publishDate: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xs, null, colors.brownishGrey),
    textAlign: 'right',
    marginRight: spacings.md,
  },
  shareIcon: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  position: {
    marginBottom: spacings.sm,
    textAlign: 'left',
  },
  description: {
    fontSize: fontSizes.md,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginTop: spacings.xs,
  },
  companyName: {
    color: colors.greyishBrown,
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplayBold,
    marginTop: spacings.xs,
  },
  disabled: {
    backgroundColor: colors.veryLightPink,
  },
  locationContainer: {
    flexDirection: 'row',
    marginTop: spacings.sm,
    alignItems: 'center',
  },
  locationIcon: {
    width: 13,
    height: 19,
    marginRight: spacings.xxs,
  },
  location: {
    ...font(fontFamily.SFProTextRegular, fontSizes.xs, undefined, colors.brownishGrey),
  },
  weeklyWorkload: {
    paddingTop: spacings.xs,
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md, undefined, colors.greyishBrown),
  },
  expiryDate: {
    paddingTop: spacings.sm,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, undefined, colors.brownishGrey),
  },
  image: {
    height: windowHeight > 800 ? hp(16) : hp(8),
  },
});

export default styles;
