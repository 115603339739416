import { store as reduxStore } from '../../core/redux/store';
import { COMPANY_USER, CANDIDATE_USER } from '../../constants/userScopes';
import { getCompanyChats, getCompanyGigChatsInfo } from '../../company/chat/companyChatActions';
import { getCandidateChatsInfo } from '../../candidate/chat/candidateChatActions';

export function getChatList(userScope) {
  if (COMPANY_USER === userScope) {
    return reduxStore.dispatch(getCompanyChats(false));
  }
  if (CANDIDATE_USER === userScope) {
    return reduxStore.dispatch(getCandidateChatsInfo(false));
  }
  return new Promise();
}

export function getGigChatsDetails(userScope, gigId) {
  if (COMPANY_USER === userScope) {
    return reduxStore.dispatch(getCompanyGigChatsInfo(gigId, false));
  }
  if (CANDIDATE_USER === userScope) {
    return reduxStore.dispatch(getCandidateChatsInfo(false));
  }
  return new Promise();
}

export function countUnreadChats(userScope, store = reduxStore) {
  if (COMPANY_USER === userScope) {
    return store.getState().chat.get('companyGigs').reduce((sum, gig) => sum + gig.get('candidateChats').count((chat) => chat.get('unread')), 0);
  }
  if (CANDIDATE_USER === userScope) {
    return store.getState().chat.get('candidateChats').count((chat) => chat.get('unread'));
  }
  return 0;
}

export function isNewGigChat(userScope, chatId, store = reduxStore) {
  if (COMPANY_USER === userScope) {
    const companyGigs = store.getState().chat.get('companyGigs');
    return companyGigs ? companyGigs.find((g) => {
      const unreadChat = g.get('candidateChats').find((chat) => chat.get('chatId') === chatId);
      return unreadChat !== undefined;
    }) === undefined
      : false;
  }
  if (CANDIDATE_USER === userScope) {
    const candidateChats = store.getState().chat.get('candidateChats');
    return candidateChats.find((chat) => chat.get('chatId') === chatId) === undefined;
  }
  return false;
}
