import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { Translate } from 'react-localize-redux';
import { standardColors, standardSpacings } from '../../styles/base.style';
import { ERROR_KEY_FATAL, ERROR_KEY_GENERAL } from '../../../core/client/axiosRequestsMiddleware';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    marginBottom: standardSpacings.formVertical,
  },
  text: {
    color: standardColors.error,
  },
});

const SHOW_FOR_KEYS = [ERROR_KEY_GENERAL, ERROR_KEY_FATAL];

function filterErrors(all, errors) {
  if (!errors) {
    return null;
  }
  if (all) {
    return errors;
  }

  const clonedfiltered = { ...errors };
  Object.keys(clonedfiltered).forEach((key) => {
    if (!SHOW_FOR_KEYS.includes(key)) {
      delete clonedfiltered[key];
    }
  });

  return clonedfiltered;
}

const GeneralFormError = ({ errors, all, style }) => {
  const errorsToShow = filterErrors(all, errors);
  if (!errorsToShow || (errorsToShow && !Object.keys(errorsToShow).length)) {
    return null;
  }

  return (
    <View style={[styles.wrapper, style.wrapper]}>
      {Object.keys(errorsToShow).map((key) => <Text key={key} style={[styles.text, style.text]}><Translate id={`validation.${errorsToShow[key]}`} /></Text>)}
    </View>
  );
};

GeneralFormError.propTypes = {
  errors: PropTypes.shape({
    general: PropTypes.string,
  }),
  all: PropTypes.bool,
  style: PropTypes.shape({
    wrapper: stylePropTypes,
    text: stylePropTypes,
  }),
};
GeneralFormError.defaultProps = {
  errors: null,
  all: false,
  style: {},
};

export default GeneralFormError;
