import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCompanyChats } from './companyChatActions';
import { chatGigItemPropType } from '../../constants/propTypes';
import CompanyChatGigList from './CompanyChatGigList';
import { CLOSED } from '../../constants/gigStatus';

class CompanyActiveChatGigListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, refreshing: true };
  }

  componentDidMount() {
    if (this.props.gigs.length === 0) {
      this.getChatGigList();
    } else {
      this.setState({ refreshing: false });
    }
  }

  onRefresh = () => {
    this.setState({ refreshing: true });
    this.getChatGigList();
  };

  getChatGigList = () => {
    this.props.getCompanyChats()
      .then((response) => {
        if (response.isError) {
          this.setState({ error: true });
        }
        this.setState({ refreshing: false });
      });
  };

  render() {
    return (
      <>
        <CompanyChatGigList
          gigs={this.props.gigs.filter((gig) => gig.status !== CLOSED)}
          error={this.state.error}
          refreshing={this.state.refreshing}
          onRefresh={this.onRefresh}
        />
      </>
    );
  }
}

CompanyActiveChatGigListContainer.propTypes = {
  getCompanyChats: PropTypes.func.isRequired,
  gigs: PropTypes.arrayOf(chatGigItemPropType).isRequired,
};

const mapStateToProps = (state) => ({
  gigs: state.chat.get('companyGigs').toJS(),
});

export default connect(mapStateToProps, { getCompanyChats })(CompanyActiveChatGigListContainer);
