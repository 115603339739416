import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getMinutesSinceDayStart } from '../../utilities/dateUtils';

const moment = extendMoment(Moment);

export const SALARY_COMPONENTS = {
  holidayPay: 12,
  socialFees: 31.24,
  insurance: 5,
  platformAndEducation: 3,
  letsgigMargin: 19,
};

export const getAmountWithTax = (amount, tax) => Math.ceil((amount * (100 + tax)) / 100);
export const getTaxFromAmount = (amount, tax) => Math.ceil((amount * tax) / 100);

export const calculateShiftTotalHours = (shiftsDatesAndTimes) => {
  const milisecondsWithinAnHour = 3600000;
  return shiftsDatesAndTimes
    .map((item) => Math.ceil((item.endDate - item.startDate) / milisecondsWithinAnHour))
    .reduce((sum, hoursNumber) => (sum + hoursNumber));
};

export const calculateShiftTotalIncome = (shiftsDatesAndTimes, shiftSalary) => {
  const totalHours = calculateShiftTotalHours(shiftsDatesAndTimes);
  const salary = shiftSalary.holidayPay ? getAmountWithTax(shiftSalary.salary, SALARY_COMPONENTS.holidayPay) : shiftSalary.salary;
  return +totalHours * +salary;
};

// TODO under construction
export const calculateTotalSalaryIncludingOBPayments = (shiftsDatesAndTimes, shiftSalary) => {
  let totalOBBonus = 0;
  const definedOBRanges = shiftSalary.obPayment.periods
    .map((item) => ({
      minutesStart: getMinutesSinceDayStart(item.startTime),
      minutesEnd: getMinutesSinceDayStart(item.endTime),
      salaryBonus: item.salaryBonus,
    }));
  shiftsDatesAndTimes.forEach((dateAndTime) => {
    const dateAndTimeRange = moment.range(dateAndTime.startDate, dateAndTime.endDate);
    const overlapingOBRange = definedOBRanges.find((obItem) => {
      const obItemRange = moment.range(
        moment(dateAndTime.startDate).startOf('day').add(obItem.minutesStart, 'm'),
        moment(dateAndTime.startDate).startOf('day').add(obItem.minutesStart < obItem.minutesEnd ? obItem.minutesEnd : obItem.minutesEnd + 24 * 60, 'm'),
      );
      return dateAndTimeRange.overlaps(obItemRange);
    });
    if (overlapingOBRange) {
      const intersection = dateAndTimeRange.intersect(overlapingOBRange.range);
      const hours = Math.ceil(intersection.diff('h'));
      totalOBBonus += hours * overlapingOBRange.salaryBonus;
    }
  });
  return totalOBBonus;
};
