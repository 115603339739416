import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ScrollView, StyleSheet, Text, View,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ProfileInfo from '../../ProfileInfo';
import { activeLanguagePropTypes, candidateProfilePropType } from '../../../../constants/propTypes';
import CandidateProfileAvailabilityInfo from './CandidateProfileAvailabilityInfo';
import { getShiftKey, getWorkloadLabel } from '../../../../constants/availabilityTypes';
import SectionHeader from '../../header/SectionHeader';
import { BADGE_TYPE } from '../../CategoryBadge';
import CandidateProfileHistoryList from '../../../../candidate/profile/history_list/CandidateProfileHistoryList';
import { getEducationKey } from '../../../../candidate/profile/profile_creator/steps/education/profileEducationHelper';
import { getExperienceKey } from '../../../../candidate/profile/profile_creator/steps/experience/profileExperienceHelper';
import Wrapper from '../../Wrapper';
import Container from '../../Container';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../styles/base.style';
import CategoryBadgeList from '../../CategoryBadgeList';
import { COMPANY_USER } from '../../../../constants/userScopes';
import { getLanguageNameFor } from '../../../../core/localization/languagesListProvider';
import FeatureToggle from '../../FeatureToggle';
import { font } from '../../../styles/mixins';
import { PrintingContext } from '../../../../core/printingContext';

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: spacings.md,
  },
  container: {
    display: 'flex', alignItems: 'center',
  },
  aboutMe: {
    fontFamily: fontFamily.SFProDisplayRegular,
    fontSize: fontSizes.sm,
    color: colors.greyishBrown,
    marginBottom: spacings.md,
    lineHeight: fontSizes.xl,
  },
  availability: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.md,
    color: colors.brownishGrey,
    marginRight: spacings.sm,
  },
  gigLocation: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.md,
    color: colors.brownishGrey,
  },
  languages: {
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.md,
    color: colors.brownishGrey,
  },
  sectionHeaderWrapper: {
    marginBottom: 0,
  },
  noEducationNorExperience: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
    lineHeight: 20,
    marginVertical: spacings.md,
  },
  heartContainer: {
    paddingHorizontal: spacings.sm,
    paddingBottom: spacings.xs,
    color: colors.lipstick,
    lineHeight: fontSizes.xxl,
  },
});

const CandidateProfileViewDetailsView = (props) => {
  const {
    translate, profile, renderExtraInfo, profileInfoProps, stickyFooter, userScope, activeLanguage, online, children, availability,
  } = props;
  const { isPrint } = useContext(PrintingContext);

  return (
    <Container>
      <ScrollView style={{ flex: 1 }}>
        <View style={[styles.container]}>
          <Wrapper noFlex>
            <ProfileInfo
              profile={profile}
              lastNameOneLetter={userScope === COMPANY_USER}
              renderExtraInfo={renderExtraInfo}
              online={online}
              {...profileInfoProps}
            />
            {availability || <CandidateProfileAvailabilityInfo available={profile.available} />}
            {!isPrint && profile.matching?.isInFavourites && <Text style={styles.heartContainer}>❤</Text>}

            <View style={styles.marginBottom}>
              {profile.hours && (
                <Text style={styles.availability}>{getWorkloadLabel(profile.hours, translate, true)}</Text>
              )}
              {profile.times.length > 0
              && <Text style={styles.availability}>{profile.times.map((t) => `${translate(`gigAvailability.${getShiftKey(t)}`)}`).join(', ')}</Text>}
            </View>
          </Wrapper>

          {profile.aboutMe
          && (
            <>
              <SectionHeader title={translate('candidateProfile.details.aboutMe')} />
              <Wrapper noFlex>
                <Text style={styles.aboutMe}>{profile.aboutMe}</Text>
              </Wrapper>
            </>
          )}

          <SectionHeader title={translate('candidateProfile.details.categoryHeader')} />
          <Wrapper noFlex style={styles.marginBottom}>
            <CategoryBadgeList
              type={BADGE_TYPE.ORANGE}
              categories={profile.categories}
            />
          </Wrapper>

          <FeatureToggle requestedFeature="HOME_LOCATION">
            <>
              {profile.location && (
                <>
                  <SectionHeader title={translate('candidateProfile.details.gigLocationLabel')} />
                  <Wrapper noFlex style={styles.marginBottom}>
                    <Text style={styles.gigLocation}>{profile.location.cityV2.name}</Text>
                  </Wrapper>
                </>
              )}
            </>
          </FeatureToggle>

          <FeatureToggle requestedFeature="PROFILE_DATA_EXTENSION">
            <>
              {profile.languages && profile.languages.length > 0
              && (
                <>
                  <SectionHeader title={translate('candidateProfile.details.languages')} />
                  <Wrapper noFlex style={styles.marginBottom}>
                    <Text style={styles.languages}>
                      {profile.languages.map((l) => `${getLanguageNameFor(l.code, activeLanguage.code)} (${translate(`languageLevel.${l.level}`)})`).join(', ')}
                    </Text>
                  </Wrapper>
                </>
              )}
            </>
          </FeatureToggle>

          <SectionHeader title={translate('candidateProfile.details.experienceHeader')} style={{ wrapper: styles.sectionHeaderWrapper }} />
          <Wrapper noFlex>
            <CandidateProfileHistoryList
              items={profile.experience.map((e) => (
                {
                  title: e.position,
                  subtitle: e.companyName,
                  ...e,
                }
              ))}
              keyExtractor={getExperienceKey}
            />
            {!profile.experience.length
            && (
            <Text style={styles.noEducationNorExperience}>
              {translate('candidateProfile.details.noExperienceText')}
            </Text>
            )}
          </Wrapper>

          <SectionHeader title={translate('candidateProfile.details.educationHeader')} style={{ wrapper: styles.sectionHeaderWrapper }} />
          <Wrapper noFlex>
            <CandidateProfileHistoryList
              items={profile.education.map((e) => (
                {
                  title: e.school,
                  subtitle: e.course,
                  ...e,
                }
              ))}
              keyExtractor={getEducationKey}
            />
            {!profile.education.length
            && (
            <Text style={styles.noEducationNorExperience}>
              {translate('candidateProfile.details.noEducationText')}
            </Text>
            )}
          </Wrapper>
        </View>
        {children}
      </ScrollView>
      { !isPrint && stickyFooter && stickyFooter() }
    </Container>
  );
};

CandidateProfileViewDetailsView.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  userScope: PropTypes.string,
  profile: candidateProfilePropType,
  renderExtraInfo: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  profileInfoProps: PropTypes.object,
  stickyFooter: PropTypes.func,
  online: PropTypes.bool,
  children: PropTypes.node,
  availability: PropTypes.node,
};

CandidateProfileViewDetailsView.defaultProps = {
  profile: undefined,
  renderExtraInfo: undefined,
  profileInfoProps: {},
  stickyFooter: undefined,
  userScope: undefined,
  online: null,
  children: null,
  availability: null,
};

const mapStateToProps = (state) => ({
  userScope: state.auth.get('userScope'),
});

export default connect(mapStateToProps, null)(withLocalize(CandidateProfileViewDetailsView));
