import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, Text, ScrollView, View,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import Container from '../../../common/components/Container';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import SettingsSupportSection from '../../../common/settings/SettingsSupportSection';
import SettingsNotificationContainer from '../../../common/settings/notifications/SettingsNotificationContainer';
import { colors, fontSizes, spacings } from '../../../common/styles/base.style';
import { version } from '../../../../package.json';
import FeatureToggle from '../../../common/components/FeatureToggle';
import ViewportController from '../../../common/components/ViewportController';
import LinkButton from '../../../common/components/buttons/LinkButton';
import Button, { BUTTON_TYPES } from '../../../common/components/buttons/Button';
import { CANDIDATE_CREATE_PROFILE } from '../../../constants/routes';
import LogsModal from '../../../common/log/LogsModal';

const styles = StyleSheet.create({
  containerWrapper: {
    padding: spacings.md,
  },
  version: {
    position: 'absolute',
    bottom: spacings.xs,
    right: spacings.xs,
    fontSize: fontSizes.xxs,
    color: colors.brownGrey,
  },
});

const CandidateProfileSettingsScreen = ({
  translate,
  handleBack,
  onLogout,
  onDelete,
  onOpenLogsModal,
  onCloseLogsModal,
  logsModalVisible,
}) => (
  <ViewportController safeAreaBottom>
    <Container>
      <ScreenHeader
        title={translate('settings.settingsScreenHeader')}
        showBackArrow
        handleBack={handleBack}
      />
      <ScrollView contentContainerStyle={styles.containerWrapper}>
        <FeatureToggle requestedFeature="ADMIN">
          <View style={{ marginBottom: spacings.lg }}>
            <LinkButton title="Complete profile" to={CANDIDATE_CREATE_PROFILE} type={BUTTON_TYPES.TEXT} />
            <Button title="Show Logs" type={BUTTON_TYPES.TEXT} onPress={onOpenLogsModal} />
          </View>
        </FeatureToggle>
        <FeatureToggle requestedFeature="PUSH_NOTIFICATIONS">
          <SettingsNotificationContainer />
        </FeatureToggle>
        <SettingsSupportSection onLogout={onLogout} onDelete={onDelete} />
      </ScrollView>
      <Text style={styles.version}>{version}</Text>
      <LogsModal
        visible={logsModalVisible}
        closeModal={onCloseLogsModal}
      />
    </Container>
  </ViewportController>
);

CandidateProfileSettingsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenLogsModal: PropTypes.func.isRequired,
  onCloseLogsModal: PropTypes.func.isRequired,
  logsModalVisible: PropTypes.bool,
};

CandidateProfileSettingsScreen.defaultProps = {
  logsModalVisible: false,
};

export default withLocalize(CandidateProfileSettingsScreen);
