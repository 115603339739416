import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CheckBox from './checkbox/CheckBox';

export class SelectListItem extends PureComponent {
  render = () => (
    <CheckBox
      value={this.props.selected}
      label={this.props.label}
      iconPosition={this.props.iconPosition}
      onPress={() => this.props.onPress(this.props.value, this.props.selected)}
      disabled={this.props.disabled}
    />
  );
}

export const SelectListItemDataValuePropType = [PropTypes.any];

export const SelectListItemDataPropType = {
  componentKey: PropTypes.string,
  value: PropTypes.oneOfType(SelectListItemDataValuePropType).isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

SelectListItem.propTypes = {
  ...SelectListItemDataPropType,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  iconPosition: PropTypes.oneOf(['left', 'right']).isRequired,
};

SelectListItem.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  icon: false,
  selected: false,
  disabled: false
};

export default SelectListItem;
