import React from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { fontFamily, fontSizes, spacings } from '../styles/base.style';
import * as propTypes from '../../constants/propTypes';
import { font } from '../styles/mixins';

const styles = StyleSheet.create({
  text: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
    lineHeight: 20,
    marginBottom: spacings.md,
  },
});

const PrimaryText = ({ children, style, ...props }) => (
  <Text style={[styles.text, style]} {...props}>{children}</Text>
);

PrimaryText.propTypes = {
  children: PropTypes.node.isRequired,
  style: propTypes.stylePropTypes,
};

PrimaryText.defaultProps = {
  style: undefined,
};

export default PrimaryText;
