import React from 'react';
import { StyleSheet, View } from 'react-native';
import { standardColors } from '../styles/base.style';

const styles = StyleSheet.create({
  gradient: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 0%, ${standardColors.webContentBackground} 95%)`,
  },
});

const BackgroundImageGradient = () => (
  <View style={styles.gradient} />
);

export default BackgroundImageGradient;
