import validator from 'validator';

export const MINIMUM_SALARY = 120;

export default [
  {
    field: 'salary',
    custom: (value) => value === null || value === '' || validator.isInt(value),
    message: 'wrong_format',
  },
  {
    field: 'salary',
    custom: (value) => value !== null && value !== '',
    message: 'null_value',
  },
  {
    field: 'salary',
    custom: (value) => value >= MINIMUM_SALARY,
    message: 'too_small',
  },
];
