import { combineReducers } from 'redux-immutable';
import adminGigsFindReducer from './gig/AdminGigsReducer';
import adminCandidatesFindReducer from './candidate/AdminCandidatesReducer';
import adminProfileReducer from './profile/adminProfileReducer';
import adminCompaniesFindReducer from './company/AdminCompaniesReducer';
import adminSettingsReducer from './settings/AdminSettingsReducer';
import adminAssociationsReducer from './associations/AdminAssociationsReducer';

export default combineReducers({
  gigs: adminGigsFindReducer,
  candidates: adminCandidatesFindReducer,
  profile: adminProfileReducer,
  companies: adminCompaniesFindReducer,
  settings: adminSettingsReducer,
  associations: adminAssociationsReducer
});
