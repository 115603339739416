import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';
import { formatHowLongAgo } from '../../utilities/dateFormatter';
import {
  colors,
  spacings,
  fontSizes,
  fontFamily,
} from '../../common/styles/base.style';
import Container from '../../common/components/Container';
import Touchable from '../../common/components/Touchable';
import ChatUnreadIndicator from '../../common/components/chat/ChatUnreadIndicator';
import ChatListItemProfileImage from '../../common/components/chat/ChatListItemProfileImage';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: spacings.sm,
    marginHorizontal: spacings.sm,
  },
  infoLine: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },
  name: {
    flex: 1,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
    fontFamily: fontFamily.SFProDisplayRegular,
  },
  unreadName: {
    fontFamily: fontFamily.SFProDisplayBold,
  },
  companyName: {
    fontSize: fontSizes.xs,
    color: colors.lightPeach,
    fontFamily: fontFamily.SFProDisplayBold,
  },
  msg: {
    flex: 1,
    fontSize: fontSizes.md,
    color: colors.lightPeach,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginRight: spacings.md,
  },
  unreadMsg: {
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.greyishBrown,
  },
  date: {
    fontSize: fontSizes.sm,
    color: colors.brownGrey,
    fontFamily: fontFamily.SFProDisplayBold,
  },
});

class CandidateChatGigListItem extends PureComponent {
  onPress = () => {
    this.props.onPress(this.props.gigId);
  };

  render() {
    const {
      companyName, lastMessageDate, lastMessage, user, unread, online, userProfileImageUri,
    } = this.props;
    return (
      <Touchable onPress={this.onPress}>
        <View style={styles.container}>
          <ChatListItemProfileImage profileImageUri={userProfileImageUri} online={online} />
          <Container>
            <View style={styles.infoLine}>
              <Text style={[styles.name, unread && styles.unreadName]}>{user.name || user.email}</Text>
              <Text style={[styles.date, unread && styles.unread]}>{formatHowLongAgo(lastMessageDate)}</Text>
            </View>
            <View style={styles.infoLine}>
              <Text style={styles.companyName}>{companyName}</Text>
            </View>
            <View style={styles.infoLine}>
              <Text numberOfLines={1} style={[styles.msg, unread && styles.unreadMsg]}>{lastMessage}</Text>
              { unread && <ChatUnreadIndicator /> }
            </View>
          </Container>
        </View>
      </Touchable>
    );
  }
}

CandidateChatGigListItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  gigId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  lastMessageDate: PropTypes.number.isRequired,
  lastMessage: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  online: PropTypes.bool,
  userProfileImageUri: PropTypes.string,
};

CandidateChatGigListItem.defaultProps = {
  userProfileImageUri: null,
  online: false,
};

export default CandidateChatGigListItem;
