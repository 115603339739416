const Sentry = {
  init: () => ({
  }),
  Native: {
    setUserContext: () => {},
    configureScope: () => {},
  },
};

export default Sentry;
