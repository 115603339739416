import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { gigCreatorAddPhoto, gigCreatorUploadGigPhoto } from '../../gigCreatorActions';
import GigCreatorStepPhotoScreen from './GigCreatorStepPhotoScreen';
import { showInformationAlert } from '../../../../common/components/alert/alerts';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepPhotoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      photoUri: this.props.photoUri,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.PHOTO));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.PHOTO);
  }

  handlePhotoUpload = (resp) => {
    this.setState({ photoUri: resp.uri });
  };

  validateImage = (result) => {
    const { width, height } = result.assets[0];
    if (width >= height) {
      return true;
    }

    showInformationAlert(this.props.translate, {
      confirmKey: 'generic.ok',
      titleKey: 'companyGigCreator.photo.alert.title',
      textKey: 'companyGigCreator.photo.alert.text',
    });
    return false;
  };

  handleEditGig = (photoUri) => {
    this.props.updateGig(GIG_EDIT_SECTIONS.PHOTO, { photoUri })
      .then(() => this.props.history.goBack());
  };

  handleSubmit = () => {
    this.props.gigCreatorAddPhoto(this.state.photoUri);

    if (this.props.isEdit) {
      this.handleEditGig(this.state.photoUri);
    } else {
      this.setState({ redirect: true });
    }
  };

  handleSkip = () => {
    this.props.gigCreatorAddPhoto(undefined);

    if (this.props.isEdit) {
      this.handleEditGig(null);
    } else {
      this.setState({ redirect: true });
    }
  };

  render = () => (
    <GigCreatorStepPhotoScreen
      stepNumber={this.stepNumber}
      nextScreen={this.nextScreen}
      maxStepsNumber={this.maxStepsNumber}
      photoUri={this.state.photoUri || this.props.photoUri}
      handlePhotoUpload={this.handlePhotoUpload}
      uploadPhotoAction={this.props.gigCreatorUploadGigPhoto}
      imageValidator={this.validateImage}
      handleSubmit={this.handleSubmit}
      handleSkip={this.handleSkip}
      redirect={this.state.redirect}
      isEdit={this.props.isEdit}
    />
  );
}

GigCreatorStepPhotoContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  gigCreatorAddPhoto: PropTypes.func.isRequired,
  gigCreatorUploadGigPhoto: PropTypes.func.isRequired,
  photoUri: PropTypes.string,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCreatorStepPhotoContainer.defaultProps = {
  photoUri: null,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      photoUri,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { photoUri, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddPhoto, gigCreatorUploadGigPhoto })(withLocalize(GigCreatorStepPhotoContainer)));
