import React from 'react';
import PropTypes from 'prop-types';
import Routing from '../../../core/routing/index';
import Button from './Button';
import * as propTypes from '../../../constants/propTypes';

const { Redirect } = Routing;

class LinkButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { letsGo: false, replacePathValue: undefined };
  }

  getRedirectTo() {
    if (this.state.replacePathValue) {
      return this.props.to.replace(':id', this.state.replacePathValue);
    } if (this.props.replacePathValue) {
      return this.props.to.replace(':id', this.props.replacePathValue);
    }
    return this.props.to;
  }

  doRedirect = (replacePathValue) => {
    this.setState({ letsGo: true, replacePathValue });
  };

  handlePress = () => {
    if (!this.props.onPress) {
      this.doRedirect();
    } else {
      this.props.onPress(this.doRedirect);
    }
  };

  render() {
    const {
      onPress, replace, doRedirect, ...other
    } = this.props;
    if (this.state.letsGo || doRedirect) {
      return <Redirect push={!replace} to={this.getRedirectTo()} />;
    }
    return <Button onPress={this.handlePress} {...other} />;
  }
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  style: PropTypes.shape({
    wrapper: propTypes.stylePropTypes,
    text: propTypes.stylePropTypes,
  }),
  onPress: PropTypes.func,
  doRedirect: PropTypes.bool,
  replace: PropTypes.bool,
  replacePathValue: PropTypes.string,
};

LinkButton.defaultProps = {
  style: null,
  onPress: null,
  doRedirect: false,
  replace: false,
  replacePathValue: undefined,
};

export default LinkButton;
