import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../../common/components/index';
import GigCreatorHeader from '../../GigCreatorHeader';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorStepDescriptionValidation, { MAX_INFO_LENGTH, MAX_STREET_LENGTH } from './GigCreatorStepLocationValidation';
import FormikFieldPrimarySwitch from '../../../../common/components/form/switch/FormikFieldPrimarySwitch';
import Container from '../../../../common/components/Container';
import { spacings } from '../../../../common/styles/base.style';
import AutoScrollableKeyboardAwareScrollView from '../../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import FormikFieldPrimaryInputTextNextSupport from '../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import Button from '../../../../common/components/buttons/Button';
import FormikFieldPrimaryPicker from '../../../../common/components/form/FormikFieldPrimaryPicker';

class GigCreatorStepLocationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      translate, initialValues, onSubmit, cities, stepNumber, isEdit, nextScreen, maxStepsNumber,
    } = this.props;

    return (
      <Container>
        <FormikWithValidation
          validation={GigCreatorStepDescriptionValidation}
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, status, isBtnDisabled }) => (
            <>
              <GigCreatorHeader
                isEdit={isEdit}
                stepNo={stepNumber}
                maxStepsNumber={maxStepsNumber}
                title={translate('companyGigCreator.location.header')}
                onRightButtonPress={handleSubmit}
                isRightButtonDisabled={isBtnDisabled}
              />
              <AutoScrollableKeyboardAwareScrollView>
                <KeyboardAwareFlexWrapper>
                  <View style={{ marginTop: spacings.md }}>
                    <FormikFieldPrimaryPicker
                      name="cityKey"
                      items={cities}
                      label={translate('companyGigCreator.location.cityTitle')}
                    />
                    <FormikFieldPrimaryInputTextNextSupport
                      name="street"
                      label={translate('companyGigCreator.location.streetTitle')}
                      placeholder={translate('companyGigCreator.location.streetPlaceholder')}
                      maxLength={MAX_STREET_LENGTH}
                      nextComponent={this.state.infoInputRef}
                      autoscroll
                    />
                    <FormikFieldPrimaryTextInput
                      innerRef={(ref) => !this.state.infoInputRef && this.setState({ infoInputRef: ref })}
                      name="info"
                      label={translate('companyGigCreator.location.informationTitle')}
                      placeholder={translate('companyGigCreator.location.informationPlaceholder')}
                      maxLength={MAX_INFO_LENGTH}
                      returnKeyType="done"
                      autoscroll
                    />
                    <FormikFieldPrimarySwitch
                      name="remoteWorkAllowed"
                      label={translate('companyGigCreator.location.remoteWorkAllowedTitle')}
                    />
                  </View>
                </KeyboardAwareFlexWrapper>

                {isEdit ? (
                  <Button
                    title={translate('generic.save')}
                    disabled={isBtnDisabled}
                    onPress={handleSubmit}
                  />
                ) : (
                  <LinkButton
                    to={nextScreen}
                    title={translate('generic.next')}
                    doRedirect={status && status.redirect}
                    disabled={isBtnDisabled}
                    onPress={handleSubmit}
                  />
                )}
              </AutoScrollableKeyboardAwareScrollView>
            </>
          )}
        />
      </Container>
    );
  }
}

GigCreatorStepLocationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValues: PropTypes.shape({
    cityKey: PropTypes.string,
    street: PropTypes.string.isRequired,
    info: PropTypes.string,
    remoteWorkAllowed: PropTypes.bool.isRequired,
  }).isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepLocationScreen);
