import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import { clearGigCandidates, getGigCandidates, storeMyGigsScrollPosition } from '../companyGigCandidatesActions';
import { gigCandidatePropType, msisdnPropType } from '../../../../../constants/propTypes';
import { MATCHED_LIST_KEY } from '../companyGigCandidatesReducer';
import { GIG_CANDIDATE_STATUS } from '../../../../../constants/matchingStatus';
import Routing from '../../../../../core/routing/index';
import candidatesListStyles from '../components/GigCandidatesListStyle';
import PrimaryFlatList from '../../../../../common/components/PrimaryFlatList';
import CompanyGigCandidateOfferModal from '../offerModal/CompanyGigCandidateOfferModal';
import CandidateQuestionnaireModalView from '../questionnaireModal/CandidateQuestionnaireModalView';
import CompanyGigCandidateListItem from '../CompanyGigCandidateListItem';
import ListItem from '../../../../../common/components/ListItem';
import Container from '../../../../../common/components/Container';
import { getProfile } from '../../../../profile/companyProfileActions';
import { showConfirmAlert, showCancelSendOfferAlert } from '../../../../../common/components/alert/alerts';
import { COMPANY_PROFILE_EDIT } from '../../../../../constants/routes';
import { removeGigCandidateStatusIndicator } from '../../../companyGigActions';

const { withRouter } = Routing;

class CompanyGigMatchedCandidatesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalGigId: undefined,
      modalCandidateId: undefined,
      questionnaireModalVisible: false,
      modalQuestionnaire: undefined,
    };
  }

  componentDidMount() {
    this.scrollPosition = 0;
    this.props.getGigCandidates(this.props.match.params.id, [GIG_CANDIDATE_STATUS.SHORTLISTED], MATCHED_LIST_KEY)
      .then(() => {
        if (this.props.history.action === 'POP') {
          const { storedScrollPosition } = this.props;
          if (storedScrollPosition) {
            this.scrollToLastKnownPosition(storedScrollPosition);
          }
        } else {
          this.props.storeMyGigsScrollPosition(this.scrollPosition);
        }
        this.setTabInactive();
      });
  }

  componentWillUnmount() {
    this.props.storeMyGigsScrollPosition(this.scrollPosition);
    this.props.clearGigCandidates(MATCHED_LIST_KEY);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  setTabInactive = () => {
    if (this.isNewContentInTab()) {
      this.props.removeGigCandidateStatusIndicator(this.props.match.params.id, GIG_CANDIDATE_STATUS.SHORTLISTED);
    }
  };

  isNewContentInTab = () => !!this.props.newContentIndicators
    .find(({ gigId, gigCandidateStatus }) => gigId === this.props.match.params.id && gigCandidateStatus === GIG_CANDIDATE_STATUS.SHORTLISTED);

  openOfferModal = (gigId, candidateId) => {
    const { msisdn } = this.props;
    if (msisdn === undefined) {
      this.props.getProfile().then(({ payload: profile, isError }) => {
        if (!isError) {
          this.checkProfileAndOpenModal(profile.msisdn, gigId, candidateId);
        }
      });
    } else {
      this.checkProfileAndOpenModal(msisdn, gigId, candidateId);
    }
  };

  checkProfileAndOpenModal = (msisdn, gigId, candidateId) => {
    const { translate } = this.props;
    if (!msisdn) {
      showConfirmAlert(translate, {
        confirmKey: 'companyGigs.candidates.missingDataPopup.confirm',
        cancelKey: 'companyGigs.candidates.missingDataPopup.cancel',
        titleKey: 'companyGigs.candidates.missingDataPopup.title',
        cancelStyle: false,
        textKey: 'companyGigs.candidates.missingDataPopup.missingMsisdn',
      }, () => {
        this.props.history.push(COMPANY_PROFILE_EDIT);
      });
    } else {
      this.setState({ modalVisible: true, modalGigId: gigId, modalCandidateId: candidateId });
    }
  };

  closeModal = (quiet) => {
    if (quiet) {
      this.setState({ modalVisible: false });
    } else {
      showCancelSendOfferAlert(
        (subKey) => this.props.translate(`company.gigOffer.${subKey}`),
        () => this.setState({ modalVisible: false }),
      );
    }
  };

  openQuestionnaireModal = (questionnaire) => {
    this.setState({ questionnaireModalVisible: true, modalQuestionnaire: questionnaire });
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToOffset({ offset: scrollPosition, animated: true }), 0);
  }

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, modalQuestionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalView
        visible={questionnaireModalVisible}
        closeModal={this.closeQuestionnaireModal}
        questionnaire={modalQuestionnaire}
      />
    );
  }

  renderModal() {
    const { modalVisible, modalGigId, modalCandidateId } = this.state;
    return (
      <CompanyGigCandidateOfferModal
        visible={modalVisible}
        closeModal={this.closeModal}
        gigId={modalGigId}
        candidateId={modalCandidateId}
      />
    );
  }

  render() {
    return (
      <Container>
        <PrimaryFlatList
          getItemLayout={(data, index) => ({ length: 275, offset: index * 275, index })} // fix for not scrolling over ~1300 pixels
          emptyListTextKey="companyGigs.candidates.noMatchingCandidates"
          contentContainerStyle={candidatesListStyles.contentContainerStyle}
          data={this.props.matchedCandidates}
          keyExtractor={(item) => item.userId}
          onScroll={this.onScroll}
          innerRef={this.setListRef}
          renderItem={({ item: candidate }) => (
            <ListItem
              renderContent={() => (
                <CompanyGigCandidateListItem
                  candidate={candidate}
                  gigId={this.props.match.params.id}
                  internalFunctions={{
                    openModal: () => this.openOfferModal(this.props.match.params.id, candidate.userId),
                    showQuestionnaire: () => this.openQuestionnaireModal(candidate.matching.questionnaire),
                  }}
                  online={this.props.usersPresence[candidate.userId]}
                />
              )}
            />
          )}
        />
        {this.renderModal()}
        {this.renderQuestionnaireModal()}
      </Container>
    );
  }
}

CompanyGigMatchedCandidatesView.propTypes = {
  getGigCandidates: PropTypes.func.isRequired,
  clearGigCandidates: PropTypes.func.isRequired,
  matchedCandidates: PropTypes.arrayOf(gigCandidatePropType),
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  msisdn: msisdnPropType,
  getProfile: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.shape({
    gigId: PropTypes.string.isRequired,
    gigCandidateStatus: PropTypes.string.isRequired,
  })).isRequired,
  removeGigCandidateStatusIndicator: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  storedScrollPosition: PropTypes.number.isRequired,
  storeMyGigsScrollPosition: PropTypes.func.isRequired,
};

CompanyGigMatchedCandidatesView.defaultProps = {
  matchedCandidates: null,
  msisdn: undefined,
};

const mapStateToProps = (state) => ({
  matchedCandidates: state.gigCandidates.get(MATCHED_LIST_KEY) ? state.gigCandidates.get(MATCHED_LIST_KEY).toJS() : null,
  storedScrollPosition: state.gigCandidates.get('companyMyGigsScrollPosition'),
  msisdn: state.candidate.get('profile').toJS().msisdn,
  newContentIndicators: state.companyGig.get('gigsList').get('newContentIndicators').toJS(),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default withRouter(connect(mapStateToProps, {
  getGigCandidates,
  clearGigCandidates,
  getProfile,
  removeGigCandidateStatusIndicator,
  storeMyGigsScrollPosition,
})(withLocalize(CompanyGigMatchedCandidatesView)));
