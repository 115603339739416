import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { colors, webContentPercentWidth, wp } from '../styles/base.style';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    height: 6,
  },
  progress: {
    height: '100%',
  },
  rest: {
    flex: 1,
    backgroundColor: colors.veryLightPinkTwo,
  },
});

class ProgressBar extends React.PureComponent {
  render() {
    const { progress, progressColor } = this.props;
    return (
      <View style={styles.container}>
        <View
          style={[
            styles.progress,
            { width: wp(Platform.OS === 'web' ? progress * webContentPercentWidth : progress * 100) },
            { backgroundColor: progressColor },
          ]}
        />
        <View style={styles.rest} />
      </View>
    );
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  progressColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  progressColor: colors.magenta,
};

export default ProgressBar;
