import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { fontFamily, fontSizes, spacings } from '../styles/base.style';
import { font } from '../styles/mixins';

const mainFont = font(fontFamily.SFProDisplayRegular, fontSizes.md);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginBottom: spacings.xs,
  },
  label: {
    ...mainFont,
    flex: 1,
  },
  value: {
    ...mainFont,
    textAlign: 'right',
  },
});

function KeyValueRow({ label, value, children }) {
  return (
    <View style={styles.row}>
      <Text style={styles.label}>{label}</Text>
      {value && <Text style={styles.value}>{value}</Text>}
      {children && children}
    </View>
  );
}

KeyValueRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.element,
};

KeyValueRow.defaultProps = {
  value: undefined,
  children: undefined,
};

export default KeyValueRow;
