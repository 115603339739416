import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { colors, fontFamily, fontSizes, spacings } from '../styles/base.style';
import { setLocale } from '../../core/localization/setLocaleAction';
import ExternalLink from '../components/buttons/ExternalLink';
import config from '../../config/config';
import Button, { BUTTON_ALIGN, BUTTON_TYPES } from '../components/buttons/Button';
import Header from '../components/header/Header';
import { font } from '../styles/mixins';
import { showConfirmAlert, showAlertMessage } from '../components/alert/alerts';
import { CANDIDATE_USER, COMPANY_USER } from '../../constants/userScopes';
import LanguageSelector from './language/LanguageSelector';
import UserScope from '../auth/UserScope';
import { gigActiveCount } from '../../company/gig_creator/gigCreatorActions';

const styles = StyleSheet.create({
  container: {
    marginBottom: spacings.sm,
  },
  languageFlags: {
    right: 0,
    bottom: 0,
    marginTop: spacings.md,
  },
  sectionHeader: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xl, 0.2),
    textAlign: 'center',
    marginBottom: spacings.sm,
  },
  switchLabel: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xl, undefined, colors.greyishBrown),
  },
});

const logOutButton = StyleSheet.create({
  text: {
    color: colors.magenta,
  },
  wrapper: {
    marginBottom: 0,
  },
});

const deleteAccountButton = StyleSheet.create({
  text: {
    color: colors.brownGrey,
    fontFamily: fontFamily.SFProDisplayRegular,
  },
  wrapper: {
    marginBottom: 0,
  },
});

class SettingsSupportSection extends React.PureComponent {
  componentDidMount = () => {
    this.props.gigActiveCount();
  };

  confirmDeletion = async () => {
    if (this.props.activeGigs > 0) {
      showAlertMessage(
        this.props.translate,
        'generic.cancel',
        'generic.alert.activeGigs.text',
        'settings.deleteAccount',
        () => {}
      );
      return;
    }
    showConfirmAlert(
      this.props.translate,
      {
        confirmKey: 'settings.deleteAccount',
        cancelKey: 'generic.cancel',
        textKey: 'settings.deleteAccountConfirmation',
        titleKey: 'settings.deleteAccount',
        confirmStyle: 'destructive',
      },
      () => {
        this.props.onDelete();
      }
    );
  };

  onLanguageSettingChange = (value) => {
    this.props.setLocale(value);
  };

  render() {
    const { translate, onLogout, onDelete, selectedLanguage } = this.props;
    return (
      <View>
        <View style={styles.container}>
          <Header style={styles.sectionHeader}>{translate('settings.supportSection')}</Header>
          <LanguageSelector
            labelKey="settings.languageScreenHeader"
            onValueChange={(value) => this.onLanguageSettingChange(value)}
            selectedValue={selectedLanguage}
          />
          <ExternalLink
            linkUrl={`mailto:${config.contactEmail}`}
            title={translate('settings.contactLabel')}
            fallbackAlertText={`${translate('generic.email')}: ${config.contactEmail}`}
          />
          <ExternalLink linkUrl={config.termsOfUseUrl} title={translate('settings.termsOfUse')} />
          <ExternalLink linkUrl={config.privacyPolicyUrl} title={translate('settings.privacyPolicy')} />
        </View>
        {onLogout && (
          <View>
            <Header style={styles.sectionHeader}>{translate('settings.accountSection')}</Header>
            <Button
              type={BUTTON_TYPES.TEXT}
              alignText={BUTTON_ALIGN.LEFT}
              title={translate('settings.logOut')}
              onPress={onLogout}
              style={logOutButton}
            />
          </View>
        )}
        {onDelete && (
          <UserScope allowedFor={[CANDIDATE_USER, COMPANY_USER]}>
            <View>
              <Header style={[styles.sectionHeader, { color: colors.brownGrey }]}>{translate('settings.deleteAccount')}</Header>
              <Button
                type={BUTTON_TYPES.TEXT}
                alignText={BUTTON_ALIGN.LEFT}
                title={translate('settings.deleteAccount')}
                onPress={this.confirmDeletion}
                style={deleteAccountButton}
              />
            </View>
          </UserScope>
        )}
      </View>
    );
  }
}

SettingsSupportSection.propTypes = {
  translate: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  gigActiveCount: PropTypes.func.isRequired,
  activeGigs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SettingsSupportSection.defaultProps = {
  activeGigs: 0, // Set a default value for activeGigs
};

const mapStateToProps = (state) => ({
  selectedLanguage: state.locale.get('locale'),
  activeGigs: state.companyGig.get('gigCreator').toJS().activeGigs,
});

export default connect(mapStateToProps, { setLocale, gigActiveCount })(withLocalize(SettingsSupportSection));
