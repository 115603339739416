import React from 'react';
import PropTypes from 'prop-types';
import PrimaryModal from '../../../common/components/modal/PrimaryModal';

const CandidateProfileEditDetailsFieldModal = ({
  header, visible, body, closeModal,
}) => (
  <PrimaryModal
    header={header}
    visible={visible}
    handleCancel={closeModal}
  >
    {body || null}
    {/* we cannot render footer here because buttons need to be part of Formik form */}
  </PrimaryModal>
);

CandidateProfileEditDetailsFieldModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.object,
};

CandidateProfileEditDetailsFieldModal.defaultProps = {
  body: undefined,
};

export default CandidateProfileEditDetailsFieldModal;
