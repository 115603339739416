import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProfileCreatorStepEducationScreen from './ProfileCreatorStepEducationScreen';
import { candidateEducationPropType, candidateProfilePropType } from '../../../../../constants/propTypes';
import { profileCreatorAddEducation, profileCreatorSaveProfile } from '../../profileCreatorActions';
import { getEducationByKey, getEducationKey, getNewEducation } from './profileEducationHelper';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepEducationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      education: props.savedEducation,
      educationToEdit: getNewEducation(),
      modalVisible: false,
      // MOVEME to the next step when time comes
      errors: null,
    };
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.EDUCATION);
  }

  openEmptyModal = () => {
    this.setState({
      modalVisible: true,
      educationToEdit: getNewEducation(),
    });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  handleEditEducation = (educationId) => {
    this.setState({
      educationToEdit: getEducationByKey(this.state.education, educationId),
      modalVisible: true,
    });
  };

  handleRemoveEducation = (educationId) => {
    const { education } = this.state;
    this.setState({ education: education.filter((e) => getEducationKey(e) !== educationId) });
  };

  handleAddEducation = (newEducation) => {
    this.closeModal();
    const educationExists = getEducationByKey(this.state.education, getEducationKey(newEducation)) != null;
    if (!educationExists) {
      this.addEducation(newEducation);
    } else {
      this.editEducation(newEducation);
    }
  };

  handleSubmit = () => {
    const { education } = this.state;
    this.props.profileCreatorAddEducation(education);
    this.saveProfile(education);
  };

  handleSkip = () => {
    const { education } = this.state;
    this.props.profileCreatorAddEducation(education);
    this.saveProfile(education);
  };

  // MOVEME to the next step when time comes
  saveProfile = (education) => {
    this.props.profileCreatorSaveProfile({ ...this.props.profileData, education })
      .then((response) => {
        if (!response.isError) {
          this.setState({ redirect: true });
        } else {
          this.setState({ errors: response.errors });
        }
      });
  };

  addEducation(newEducation) {
    this.setState({ education: this.state.education.concat([newEducation]) });
  }

  editEducation(newEducation) {
    const newEducationKey = getEducationKey(newEducation);
    const currentEducation = this.state.education;
    for (let i = 0; i < currentEducation.length; i += 1) {
      if (getEducationKey(currentEducation[i]) === newEducationKey) {
        currentEducation[i] = newEducation;
        this.setState({ education: currentEducation });
        break;
      }
    }
  }

  render = () => (
    <ProfileCreatorStepEducationScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.EDUCATION, this.props.grantedFeatures)}
      education={this.state.education}
      handleRemoveEducation={this.handleRemoveEducation}
      handleAddEducation={this.handleAddEducation}
      openEmptyModal={this.openEmptyModal}
      closeModal={this.closeModal}
      handleSubmit={this.handleSubmit}
      handleSkip={this.handleSkip}
      handleEditEducation={this.handleEditEducation}
      educationToEdit={this.state.educationToEdit}
      modalVisible={this.state.modalVisible}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.EDUCATION, this.props.grantedFeatures)}
      // MOVEME to the next step when time comes
      errors={this.state.errors}
      grantedFeatures={this.props.grantedFeatures}
    />
  );
}

ProfileCreatorStepEducationContainer.propTypes = {
  savedEducation: PropTypes.arrayOf(candidateEducationPropType.isRequired).isRequired,
  profileCreatorAddEducation: PropTypes.func.isRequired,
  // MOVEME to the next step when time comes
  profileCreatorSaveProfile: PropTypes.func.isRequired,
  profileData: candidateProfilePropType.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  savedEducation: state.candidate.get('profileData').get('education').toJS(),
  // MOVEME to the next step when time comes
  profileData: state.candidate.get('profileData').toJS(),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { profileCreatorAddEducation, profileCreatorSaveProfile })(ProfileCreatorStepEducationContainer);
