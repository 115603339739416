function isNotNil(obj) {
  return obj !== undefined && obj !== null;
}

const CandidateProfileCategoryFormValidation = (validationParam) => {
  const validators = [];
  if (validationParam && isNotNil(validationParam.min)) {
    validators.push({
      field: 'general',
      custom: (value, args, data) => data.categories.length >= validationParam.min,
      message: 'too_few',
    });
  }
  if (validationParam && isNotNil(validationParam.max)) {
    validators.push({
      field: 'general',
      custom: (value, args, data) => data.categories.length <= validationParam.max,
      message: 'too_many',
    });
  }
  return validators;
};

export default CandidateProfileCategoryFormValidation;
