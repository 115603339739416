import { Map } from 'immutable';
import {
  SET_FEATURE_INFO_SHOWN,
} from '../../constants/actionTypes';

export const FEATURE_TRACKING_FEATURES_SHOWN = {
  FIND_GIG_SWIPE_SHOWN: 'findGigSwipeShown',
};

const INITIAL_MAP = {
  [FEATURE_TRACKING_FEATURES_SHOWN.FIND_GIG_SWIPE_SHOWN]: false,
};

export default function getFeatureTrackingReducer(state = Map(INITIAL_MAP), action) {
  switch (action.type) {
    case SET_FEATURE_INFO_SHOWN:
      return state.set(action.feature, action.shown);
    default:
      return state;
  }
}
