const PRICE_LABEL_METADATA_PROPERTY_NAME = 'price';

export function getPricingPlanName({ name, metadata }, locale) {
  const nameFromMetaData = metadata[`nickname_${locale}`];
  return nameFromMetaData || name;
}

export const convertAmount = (number, locale) => (number / 100).toLocaleString(locale);

export const showCount = (intervalCount) => (intervalCount > 1 ? intervalCount : '');

export function getPriceLabel(plan, locale) {
  const priceLabelFromMetadata = plan.metadata[`${PRICE_LABEL_METADATA_PROPERTY_NAME}_${locale}`];
  if (priceLabelFromMetadata) {
    return priceLabelFromMetadata.trim();
  }
  return `${convertAmount(plan.amount, locale)} ${plan.currency.toUpperCase()} / ${showCount(plan.intervalCount)} ${plan.interval}`;
}
