import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { colors, shadow, spacings } from '../styles/base.style';
import { stylePropTypes } from '../../constants/propTypes';
import Container from './Container';

const styles = StyleSheet.create({
  base: {
    marginVertical: spacings.sm,
    borderRadius: 17,
    backgroundColor: colors.white,
    marginHorizontal: spacings.md,
  },
});

class ListItem extends PureComponent {
  render() {
    const {
      renderContent,
      style,
    } = this.props;
    return (
      <Container style={[styles.base, shadow, style]}>
        {renderContent()}
      </Container>
    );
  }
}

ListItem.propTypes = {
  renderContent: PropTypes.func.isRequired,
  style: stylePropTypes,
};

ListItem.defaultProps = {
  style: undefined,
};

export default ListItem;
