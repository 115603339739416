import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import CompanyCandidatesPoolProfileEditScreen from './CompanyCandidatesPoolProfileEditScreen';
import { getPoolProfile, savePoolProfile, uploadPoolProfilePhoto } from './poolProfileActions';
import { COMPANY_CANDIDATES_POOL_PROFILE_VIEW } from '../../../constants/routes';
import { goTo } from '../../../common/history/historyUtil';
import Routing from '../../../core/routing/index';

const { withRouter } = Routing;

class CompanyCandidatesPoolProfileEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { profile: null, photoUri: null };
  }

  componentDidMount() {
    this.props.getPoolProfile().then((res) => {
      this.setState({ profile: res.payload, photoUri: res.payload.photoUri });
    });
  }

  onPhotoUploadComplete = (resp) => {
    this.setState({ photoUri: resp.uri });
  };

  handleSubmit = (poolProfile, { setErrors, setSubmitting }) => {
    this.props.savePoolProfile({ ...poolProfile, photoUri: this.state.photoUri }).then((res) => {
      if (!res.isError) {
        goTo(this.props.history, COMPANY_CANDIDATES_POOL_PROFILE_VIEW);
      } else {
        setErrors(res.errors);
        setSubmitting(false);
      }
    });
  };

  render() {
    const { profile } = this.state;

    return (
      <CompanyCandidatesPoolProfileEditScreen
        profile={profile}
        handleSubmit={this.handleSubmit}
        uploadProfilePhoto={this.props.uploadPoolProfilePhoto}
        onPhotoUploadComplete={this.onPhotoUploadComplete}
        photoUri={this.state.photoUri}
      />
    );
  }
}

CompanyCandidatesPoolProfileEditContainer.propTypes = {
  getPoolProfile: PropTypes.func.isRequired,
  savePoolProfile: PropTypes.func.isRequired,
  uploadPoolProfilePhoto: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default connect(null, { getPoolProfile, savePoolProfile, uploadPoolProfilePhoto })(withRouter(CompanyCandidatesPoolProfileEditContainer));
