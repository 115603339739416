import { fromJS, List, Map } from 'immutable';
import {
  ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST_SUCCESS,
  ADMIN_SETTINGS_FORM_SUBMIT,
  ADMIN_SETTINGS_GET_CITIES_REQUEST_SUCCESS,
  ADMIN_SETTINGS_CREATE_CITY_REQUEST_SUCCESS,
  ADMIN_SETTINGS_CREATE_CITY_REQUEST_FAIL,
  ADMIN_SETTINGS,
  ADMIN_SETTINGS_GET_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

const INITIAL_ADMIN_SETTINGS_FILTERS = {
  formValues: fromJS({
    gigLimit: '',
    isCityEnabled: false,
  }),
  adminSettingsList: List(),
  adminSettingsGigLimit: fromJS({
    gigLimit: '',
  }),
  adminCities: List(),
  isSuccessAddCity: false,
  isErrorAddCity: false,
};

export default function adminSettingsReducer(state = new Map(INITIAL_ADMIN_SETTINGS_FILTERS), action) {
  switch (action.type) {
    case ADMIN_SETTINGS:
      return state.set('isSuccessAddCity', false).set('isErrorAddCity', false);
    case ADMIN_SETTINGS_FORM_SUBMIT:
      return state.set('formValues', fromJS(action.formValues));
    case ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST_SUCCESS:
      return state.set('adminSettingsGigLimit', { gigLimit: action.payload.value });
    case ADMIN_SETTINGS_GET_REQUEST_SUCCESS:
      return state.set('adminSettingsList', fromJS(action.payload));
    case ADMIN_SETTINGS_GET_CITIES_REQUEST_SUCCESS:
      return state.set('adminCities', fromJS(action.payload.data));
    case ADMIN_SETTINGS_CREATE_CITY_REQUEST_SUCCESS:
      return state.set('isSuccessAddCity', true);
    case ADMIN_SETTINGS_CREATE_CITY_REQUEST_FAIL:
      console.log('isSuccess false');
      return state.set('isSuccessAddCity', false).set('isErrorAddCity', true);
    default:
      return state;
  }
}
