import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Keyboard, StyleSheet, Switch, Text, View,
} from 'react-native';
import {
  colors, fontFamily, fontSizes, spacings, standardSpacings,
} from '../../../styles/base.style';
import FormFieldError from '../FormFieldError';
import { stylePropTypes } from '../../../../constants/propTypes';

const styles = StyleSheet.create({
  container: {
    marginBottom: standardSpacings.formVertical,
    display: 'flex',
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  textComponentWrapper: {
    flex: 1,
  },
  switchText: {
    fontFamily: fontFamily.SFProDisplayBold,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
    flex: 1,
    alignSelf: 'center',
  },
  switch: {
    marginLeft: spacings.sm,
  },
});

class PrimarySwitch extends PureComponent {
  render() {
    const {
      label,
      renderLabel,
      value,
      onValueChange,
      error,
      style,
      ...props
    } = this.props;

    return (
      <View style={[styles.container, style.container]}>
        <View style={styles.switchWrapper}>
          {
            renderLabel
              ? (
                <View style={styles.textComponentWrapper}>
                  <Text>{renderLabel()}</Text>
                </View>
              )
              : null
          }
          {
            label ? <Text style={[styles.switchText, style.label]}>{label}</Text> : null
          }

          <Switch
            style={[styles.switch, style.switch]}
            value={value}
            onValueChange={(val) => {
              Keyboard.dismiss();
              onValueChange(val);
            }}
            {...props}
          />
        </View>
        <FormFieldError error={error} />
      </View>
    );
  }
}

PrimarySwitch.propTypes = {
  value: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  renderLabel: PropTypes.func,
  error: PropTypes.string,
  style: PropTypes.shape({
    container: stylePropTypes,
    label: stylePropTypes,
    switch: stylePropTypes,
  }),
};
PrimarySwitch.defaultProps = {
  label: undefined,
  renderLabel: undefined,
  error: null,
  value: false,
  style: {},
};

export default PrimarySwitch;
