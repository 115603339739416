import {
  COMPANY_CANDIDATES_POOL_GET_REQUEST,
  COMPANY_CANDIDATES_POOL_INVITE_REQUEST,
  COMPANY_CANDIDATES_POOL_REMOVE_REQUEST,
  COMPANY_CANDIDATES_POOL_STORE_SCROLL_POSITION,
  COMPANY_CANDIDATES_POOL_GROUPS_STORE_SCROLL_POSITION,
  COMPANY_CANDIDATES_POOL_GROUP_MEMBERS_STORE_SCROLL_POSITION,
  COMPANY_CANDIDATES_POOL_SET_NEW_CONTENT,
  COMPANY_CANDIDATES_POOL_CLEAR_NEW_CONTENT,
} from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export function get() {
  return {
    type: COMPANY_CANDIDATES_POOL_GET_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'company/candidatesPool',
      },
    },
  };
}

export function invite(candidateId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.INVITE);
  return {
    type: COMPANY_CANDIDATES_POOL_INVITE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'company/candidatesPool/invite',
        data: { candidateId },
      },
    },
  };
}

export function remove(poolCandidateId) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CANDIDATE_POOLS.REMOVE);
  return {
    type: COMPANY_CANDIDATES_POOL_REMOVE_REQUEST,
    payload: {
      request: {
        method: 'delete',
        url: `company/candidatesPool/${poolCandidateId}`,
      },
    },
  };
}

export function storeCandidatesPoolScrollPosition(scrollPosition) {
  return {
    type: COMPANY_CANDIDATES_POOL_STORE_SCROLL_POSITION,
    scrollPosition,
  };
}

export function storeCandidatesPoolGroupsScrollPosition(scrollPosition) {
  return {
    type: COMPANY_CANDIDATES_POOL_GROUPS_STORE_SCROLL_POSITION,
    scrollPosition,
  };
}

export function storeCandidatesPoolGroupMembersScrollPosition(scrollPosition) {
  return {
    type: COMPANY_CANDIDATES_POOL_GROUP_MEMBERS_STORE_SCROLL_POSITION,
    scrollPosition,
  };
}

export function companySetMyPoolsNewContent(field, value) {
  return {
    type: COMPANY_CANDIDATES_POOL_SET_NEW_CONTENT,
    field,
    value,
  };
}

export function companyClearMyPoolsNewContent(field) {
  return {
    type: COMPANY_CANDIDATES_POOL_CLEAR_NEW_CONTENT,
    field,
  };
}
