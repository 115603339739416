import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import Routing from '../../core/routing/index';
// import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';
import { LinkButton } from '../../common/components/index';
import { CANDIDATE_LOGIN, CANDIDATE_CONTRACT_AGREEMENT, WELCOME } from '../../constants/routes';
import BackButton, { themes } from '../../common/components/navigation/BackButton';
import BackgroundImageScreen from '../../common/screens/BackgroundImageScreen';
import ViewportController from '../../common/components/ViewportController';
import { loginWithFacebook } from '../../common/auth/authActions';
// import FacebookLoginButton from '../../common/components/buttons/FacebookLoginButton';
// import facebookAuth from '../../auth/facebook/facebookAuth';
import { showInformationAlert } from '../../common/components/alert/alerts';
// import FeatureToggle from '../../common/components/FeatureToggle';
import { log } from '../../common/log/logActions';

const { withRouter } = Routing;

const imgSrc = require('../../assets/photos/welcomeCandidate/welcomeCandidate.jpg');

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
    alignItems: 'center',
  },
});

class CandidateWelcomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* isFacebookInitialized: false, */
    };
  }

/*   async componentDidMount() {
    if (facebookAuth.supports()) {
      const res = await facebookAuth.initFacebook();
      this.props.log(res);
      if (res.success) {
        // this.setState({ isFacebookInitialized: true });
      } else {
        googleAnalytics.trackErrorEvent(ACTIONS.ERROR.FACEBOOK_INIT, res.msg);
      }
    }
  }

  async onContinueWithFacebook() {
    const fbAuthRes = await facebookAuth.authenticate();
    this.props.log(fbAuthRes);
    if (fbAuthRes.success) {
      this.props.loginWithFacebook(fbAuthRes.token).then((lgRes) => {
        this.props.log(lgRes);
        if (!lgRes.isError) {
          this.props.history.push(NOTIFICATION_PERMISSION);
        }
      });
    } else {
      if (fbAuthRes.code && fbAuthRes.code === 'unknown_error') {
        // do nothing as it is just user's resign from facebook login
        return;
      }
      this.showError(fbAuthRes.msg);
      this.props.log('Facebook login failed!');
      googleAnalytics.trackErrorEvent(ACTIONS.ERROR.FACEBOOK_AUTH, fbAuthRes.msg);
    }
  } */

  showError = (err) => (
    showInformationAlert(
      this.props.translate,
      { confirmKey: 'generic.close', titleKey: 'errors.error', textKey: JSON.stringify(err) },
    )
  );

  handleBack = () => {
    this.props.history.replace(WELCOME);
  }

  render() {
    const { translate } = this.props;
    return (
      <ViewportController safeAreaBottom statusBarHidden>
        <View style={{ position: 'absolute', zIndex: 100 }}>
          <BackButton onPress={this.handleBack} theme={themes.LIGHT} />
        </View>
        <BackgroundImageScreen img={imgSrc}>
          <View style={styles.container}>
            <LinkButton to={CANDIDATE_LOGIN} title={translate('generic.loginButton')} />
            <LinkButton to={CANDIDATE_CONTRACT_AGREEMENT} type="primary-blue" title={translate('generic.signupButton')} />
            {/* {Platform.OS === 'android' && (
              <FeatureToggle requestedFeature="FACEBOOK_LOGIN_ANDROID">
                {this.state.isFacebookInitialized && (
                  <FacebookLoginButton onPress={() => this.onContinueWithFacebook()} label={translate('candidate.welcome.facebookButton')} />
                )}
              </FeatureToggle>
            )}
            {Platform.OS === 'ios' && (
              <FeatureToggle requestedFeature="FACEBOOK_LOGIN_IOS">
                {this.state.isFacebookInitialized && (
                  <FacebookLoginButton onPress={() => this.onContinueWithFacebook()} label={translate('candidate.welcome.facebookButton')} />
                )}
              </FeatureToggle>
            )} */}
          </View>
        </BackgroundImageScreen>
      </ViewportController>
    );
  }
}

CandidateWelcomeScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  // loginWithFacebook: PropTypes.func.isRequired,
  // log: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { loginWithFacebook, log })(withLocalize(CandidateWelcomeScreen)));
