import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIndicator, StyleSheet, Text, View, ScrollView,
} from 'react-native';
import moment from 'moment';
import { withLocalize } from 'react-localize-redux';
import Container from '../../common/components/Container';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../common/styles/base.style';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../common/components/buttons/Button';
import {
  COMPANY_PAYMENTS_BILLING_PROFILE_CHANGE,
  COMPANY_PAYMENTS_CARD_CHANGE,
  COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE,
} from '../../constants/routes';
import LinkButton from '../../common/components/buttons/LinkButton';
import { font } from '../../common/styles/mixins';
import SectionHeader from '../../common/components/header/SectionHeader';
import Wrapper from '../../common/components/Wrapper';
import { activeLanguagePropTypes, paymentsDataPropTypes } from '../../constants/propTypes';
import { convertAmount, getPriceLabel, getPricingPlanName } from './pricingPlan/pricingPlanUtils';
import { toDate } from '../../utilities/dateFormatter';
import KeyValueRow from '../../common/components/KeyValueRow';
import { showConfirmAlert, showInformationAlert } from '../../common/components/alert/alerts';
import { SUBSCRIPTION_STATUS_ENUM } from '../../constants/subscriptionTypes';

const mainFont = font(fontFamily.SFProDisplayRegular, fontSizes.md);

const styles = StyleSheet.create({
  sectionWrapper: {
    marginBottom: spacings.sm,
  },
  mainFont: {
    ...mainFont,
  },
  cardBrand: {
    ...mainFont,
    paddingRight: spacings.sm,
    flex: 1,
  },
  cardLast4: {
    ...mainFont,
    paddingRight: spacings.sm,
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  toRight: {
    ...mainFont,
    textAlign: 'right',
  },
  changePlanButton: {
    marginBottom: 10,
    alignSelf: 'flex-end',
  },
});

function informThatCancellationIsNotPossible(translate, cancelPossibleFromDate) {
  showInformationAlert(translate, {
    confirmKey: 'generic.close',
    titleKey: 'company.payments.subscription.cancelingLocked.informationTitle',
    textKey: 'company.payments.subscription.cancelingLocked.informationText',
    textArgs: { cancelPossibleFromDate: toDate(cancelPossibleFromDate) },
  });
}

function informThatChangingPlanIsNotPossible(translate) {
  showInformationAlert(translate, {
    confirmKey: 'generic.close',
    titleKey: 'company.payments.subscription.changingPlanLocked.informationTitle',
    textKey: 'company.payments.subscription.changingPlanLocked.informationText',
  });
}

function isCancellationPossible(cancelPossibleFromDate) {
  return !!cancelPossibleFromDate && moment(cancelPossibleFromDate).isBefore(moment());
}

function onCancelSubscription(translate, subscription, cancelSubscriptionAction) {
  const { cancelPossibleFromDate } = subscription;
  if (isCancellationPossible(cancelPossibleFromDate)) {
    showConfirmAlert(translate, {
      confirmKey: 'company.payments.subscription.canceling.alertConfirm',
      cancelKey: 'company.payments.subscription.canceling.alertCancel',
      textKey: 'company.payments.subscription.canceling.alertText',
      titleKey: 'company.payments.subscription.canceling.alertTitle',
    }, () => { cancelSubscriptionAction(); });
  } else {
    informThatCancellationIsNotPossible(translate, cancelPossibleFromDate);
  }
}

function onChangePlan(translate, subscription, changePlanAction) {
  const { min_subscription_intervals: minSubscriptionsInterval } = subscription.plan.metadata;
  if (!minSubscriptionsInterval) {
    changePlanAction();
  } else {
    informThatChangingPlanIsNotPossible(translate);
  }
}

function isSubscriptionPendingCancel(subscription) {
  return subscription && subscription.cancelAtPeriodEnd === true;
}

function isSubscriptionEmptyOrCanceled(subscription) {
  return !subscription || [SUBSCRIPTION_STATUS_ENUM.CANCELED, SUBSCRIPTION_STATUS_ENUM.INCOMPLETE_EXPIRED].includes(subscription.status);
}

function renderSubscriptionInfo(subscription, upcomingInvoice, translate, activeLanguage, cancelSubscription, changePlan) {
  const t = (key, args) => translate(`company.payments.mainView.subscriptionInfo.${key}`, args);
  const priceLabel = getPriceLabel(subscription.plan, activeLanguage.code);
  return (
    <View>
      <SectionHeader title={t('header')}>
        {!isSubscriptionPendingCancel(subscription)
          && (
          <Button
            type={BUTTON_TYPES.TEXT}
            size={BUTTON_SIZES.MD}
            title={t('cancelSubscription')}
            onPress={() => { onCancelSubscription(translate, subscription, cancelSubscription); }}
            style={{ wrapper: { marginBottom: 0 }, text: { color: colors.brownGrey } }}
          />
          )}
      </SectionHeader>
      <Wrapper noFlex style={styles.sectionWrapper}>
        <View>
          <KeyValueRow label={t('pricingPlan')}>
            <View style={{ alignItems: 'flex-end' }}>
              <Text style={styles.toRight}>{getPricingPlanName(subscription.plan, activeLanguage.code)}</Text>
              {!!priceLabel && <Text style={styles.toRight}>{priceLabel}</Text>}
              {!isSubscriptionPendingCancel(subscription)
                && (
                <Button
                  type={BUTTON_TYPES.TEXT}
                  title={t('changePlan')}
                  onPress={() => onChangePlan(translate, subscription, changePlan)}
                  style={{ wrapper: styles.changePlanButton }}
                />
                )}
            </View>
          </KeyValueRow>
          <KeyValueRow
            label={t('status')}
            value={isSubscriptionPendingCancel(subscription)
              ? `${t(`statusValues.${subscription.status}`)} ${t('pendingCancelText', { cancellationDate: toDate(subscription.cancellationDate) })}`
              : t(`statusValues.${subscription.status}`)}
          />
          {subscription.nextInvoiceDate
            && (
            <KeyValueRow
              label={t('endOfBillingCycle')}
              value={toDate(subscription.nextInvoiceDate)}
            />
            )}
          {upcomingInvoice
            && (
            <KeyValueRow
              label={t('currentInvoiceAmount')}
              value={`${convertAmount(upcomingInvoice.total)} ${upcomingInvoice.currency.toUpperCase()}`}
            />
            )}
        </View>
      </Wrapper>
    </View>
  );
}

function renderPaymentMethod(card, translate) {
  return (
    <View>
      <SectionHeader title={translate('company.payments.mainView.paymentMethodInfo.header')}>
        <LinkButton
          type={BUTTON_TYPES.TEXT}
          size={BUTTON_SIZES.MD}
          to={COMPANY_PAYMENTS_CARD_CHANGE}
          title={translate('company.payments.mainView.edit')}
          preventUpperCase
          style={{ wrapper: { marginBottom: 0 } }}
        />
      </SectionHeader>
      <Wrapper noFlex style={styles.sectionWrapper}>
        <KeyValueRow
          label={translate('company.payments.mainView.paymentMethodInfo.card')}
          value={`${card.brand} **** ${card.last4} ${card.expMonth}/${card.expYear}`}
        />
      </Wrapper>
    </View>
  );
}

function renderBillingProfile(billingProfile, translate) {
  return (
    <View>
      <SectionHeader title={translate('company.payments.mainView.billingProfileInfo.header')}>
        <LinkButton
          type={BUTTON_TYPES.TEXT}
          size={BUTTON_SIZES.MD}
          to={COMPANY_PAYMENTS_BILLING_PROFILE_CHANGE}
          title={translate('company.payments.mainView.edit')}
          preventUpperCase
          style={{ wrapper: { marginBottom: 0 } }}
        />
      </SectionHeader>
      <Wrapper noFlex style={styles.sectionWrapper}>
        <KeyValueRow
          label={translate('company.payments.mainView.billingProfileInfo.email')}
          value={billingProfile.email}
        />
        {billingProfile.address && (
          <KeyValueRow label={translate('company.payments.mainView.billingProfileInfo.address')}>
            <View>
              <Text style={styles.toRight}>{billingProfile.address.line1}</Text>
              {!!billingProfile.address.line2 && <Text style={styles.toRight}>{billingProfile.address.line2}</Text>}
              <Text style={styles.toRight}>{billingProfile.address.city}</Text>
              <Text style={styles.toRight}>{billingProfile.address.postal_code}</Text>
              <Text style={styles.toRight}>{billingProfile.address.country}</Text>
            </View>
          </KeyValueRow>
        )}
        {!!billingProfile.vatNumber && (
          <KeyValueRow
            label={translate('company.payments.mainView.billingProfileInfo.vatNumber')}
            value={billingProfile.vatNumber}
          />
        )}
        {!!billingProfile.invoiceReference && (
          <KeyValueRow
            label={translate('company.payments.mainView.billingProfileInfo.invoiceReference')}
            value={billingProfile.invoiceReference}
          />
        )}
      </Wrapper>
    </View>
  );
}

const CompanyPaymentsScreen = ({
  translate, handleBack, paymentsData, activeLanguage, cancelSubscription, changePlan,
}) => (
  <Container>
    <ScreenHeader
      title={translate('company.payments.mainView.header')}
      showBackArrow
      handleBack={handleBack}
      shadow
    />
    {paymentsData ? (
      <ScrollView style={styles.container}>
        {isSubscriptionEmptyOrCanceled(paymentsData.subscription) && (
          <LinkButton
            type={BUTTON_TYPES.PRIMARY_RED}
            to={COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE}
            title={translate('company.payments.mainView.subscribe')}
            preventUpperCase
            style={{ wrapper: { marginTop: spacings.md } }}
          />
        )}
        {!isSubscriptionEmptyOrCanceled(paymentsData.subscription)
          && renderSubscriptionInfo(paymentsData.subscription, paymentsData.upcomingInvoice, translate, activeLanguage, cancelSubscription, changePlan)}
        {paymentsData.card && renderPaymentMethod(paymentsData.card, translate)}
        {paymentsData.billingProfile && renderBillingProfile(paymentsData.billingProfile, translate)}
      </ScrollView>
    ) : (
      <ActivityIndicator size="large" color={colors.magenta} style={styles.spinner} />
    )}
  </Container>
);

CompanyPaymentsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  handleBack: PropTypes.func.isRequired,
  paymentsData: paymentsDataPropTypes,
  cancelSubscription: PropTypes.func.isRequired,
  changePlan: PropTypes.func.isRequired,
};

CompanyPaymentsScreen.defaultProps = {
  paymentsData: undefined,
};

export default withLocalize(CompanyPaymentsScreen);
