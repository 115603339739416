import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import ProfileCreatorStepLanguagesScreen from './ProfileCreatorStepLanguagesScreen';
import { profileCreatorAddLanguages } from '../../profileCreatorActions';
import { getNextToggledStepRoute, getStepNumber, PROFILE_CREATION_STEP } from '../../candidateProfileStepsConfig';
import { activeLanguagePropTypes, languagePropType } from '../../../../../constants/propTypes';
import getWorldLanguagesListFor from '../../../../../core/localization/languagesListProvider';
import { getStepInfo, STEP_NAME } from '../../../../../company/gig_creator/stepsConfig';
import { LANGUAGE_LEVEL } from '../../../../../constants/languageTypes';
import googleAnalytics, { ACTIONS } from '../../../../../common/analytics/googleAnalytics';

class ProfileCreatorStepLanguagesContainer extends Component {
  constructor(props) {
    super(props);
    this.worldLanguagesList = getWorldLanguagesListFor(this.props.activeLanguage.code);
    this.state = {
      selectedLanguages: this.props.selectedLanguages,
      redirect: false,
    };

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.LANGUAGE));
  }

  componentDidMount() {
    googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.COMPLETE_PROFILE.LANGUAGE);
  }

  getFirstNotUsedLanguage = () => {
    const usedLanguagesCode = this.state.selectedLanguages.map((l) => l.code);
    return this.worldLanguagesList
      .find((i) => !usedLanguagesCode.includes(i.code));
  };

  getLanguageKey = (language) => (language ? language.code : null);

  handleAddLanguage = () => {
    const firstNotUsedLanguage = this.getFirstNotUsedLanguage();
    const newLanguage = {
      code: (firstNotUsedLanguage && firstNotUsedLanguage.code) || '',
      level: LANGUAGE_LEVEL.NATIVE,
    };
    this.setState({ selectedLanguages: this.state.selectedLanguages.concat(newLanguage) });
  };

  handleRemoveLanguage = (key) => {
    this.setState({ selectedLanguages: this.state.selectedLanguages.filter((lang) => lang.code !== key) });
  };

  handleUpdateLanguage = (oldItem, changedLanguage) => {
    const oldLanguageKey = this.getLanguageKey(oldItem);
    const currentLanguages = this.state.selectedLanguages;
    for (let i = 0; i < currentLanguages.length; i += 1) {
      if (this.getLanguageKey(currentLanguages[i]) === oldLanguageKey) {
        currentLanguages[i] = changedLanguage;
        this.setState({ selectedLanguages: currentLanguages });
        break;
      }
    }
  };

  handleSubmit = (languages) => {
    this.props.profileCreatorAddLanguages(languages);
    this.setState({ redirect: true });
  };

  render = () => (
    <ProfileCreatorStepLanguagesScreen
      stepNumber={getStepNumber(PROFILE_CREATION_STEP.LANGUAGES, this.props.grantedFeatures)}
      selectedLanguages={this.state.selectedLanguages}
      handleSubmit={this.handleSubmit}
      handleAddLanguage={this.handleAddLanguage}
      handleRemoveLanguage={this.handleRemoveLanguage}
      handleUpdateLanguage={this.handleUpdateLanguage}
      redirect={this.state.redirect}
      nextScreen={getNextToggledStepRoute(PROFILE_CREATION_STEP.LANGUAGES, this.props.grantedFeatures)}
    />
  );
}

ProfileCreatorStepLanguagesContainer.propTypes = {
  activeLanguage: activeLanguagePropTypes.isRequired,
  profileCreatorAddLanguages: PropTypes.func.isRequired,
  selectedLanguages: PropTypes.arrayOf(languagePropType).isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const languages = state.candidate.get('profileData').get('languages').toJS();
  return ({
    selectedLanguages: languages,
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  });
};

export default connect(mapStateToProps, { profileCreatorAddLanguages })(withLocalize(ProfileCreatorStepLanguagesContainer));
