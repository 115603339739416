import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CompanyProfileScreen from './CompanyProfileScreen';
import { getProfile, companyProfileEditProfileImage } from './companyProfileActions';
import { logoutUser } from '../../common/auth/authActions';
import { companyProfilePropType } from '../../constants/propTypes';
import { setLocale } from '../../core/localization/setLocaleAction';

class CompanyProfileContainer extends React.Component {
  componentDidMount() {
    this.props.getProfile();
  }

  render() {
    return (
      <CompanyProfileScreen
        profile={this.props.profile}
        companyProfileEditProfileImage={this.props.companyProfileEditProfileImage}
        onSetLocale={this.props.setLocale}
      />
    );
  }
}

CompanyProfileContainer.propTypes = {
  profile: companyProfilePropType,
  getProfile: PropTypes.func.isRequired,
  companyProfileEditProfileImage: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
};

CompanyProfileContainer.defaultProps = {
  profile: null,
};

const mapStateToProps = (state) => ({
  profile: state.company.get('profile').toJS(),
});

export default connect(mapStateToProps, {
  getProfile,
  logoutUser,
  companyProfileEditProfileImage,
  setLocale,
})(CompanyProfileContainer);
