import { List, Map, fromJS } from 'immutable';
import {
  GIG_CREATOR_ADD_AVAILABILITY,
  GIG_CREATOR_ADD_CANDIDATES_NUMBER,
  GIG_CREATOR_ADD_CATEGORIES,
  GIG_CREATOR_ADD_COMPENSATION,
  GIG_CREATOR_ADD_DATES,
  GIG_CREATOR_ADD_DESCRIPTION,
  GIG_CREATOR_ADD_EXPERIENCE,
  GIG_CREATOR_ADD_LANGUAGES,
  GIG_CREATOR_ADD_EXPIRY_DATE,
  GIG_CREATOR_ADD_LOCATION,
  GIG_CREATOR_ADD_NAME,
  GIG_CREATOR_ADD_PHOTO,
  GIG_CREATOR_ADD_POSITION,
  GIG_CREATOR_ADD_REMOTE_WORK_ALLOWED,
  GIG_CREATOR_CLEAR_ALL_DATA,
  GIG_CREATOR_INIT_EDIT_GIG,
  GIG_CREATOR_UPDATE_GIG_SUCCESS,
  GIG_CREATOR_ADD_OCCUPATION,
  GIG_CREATOR_ADD_QUESTIONNAIRE,
  GIG_CREATOR_ADD_CAREER_URL,
  GIG_CREATOR_CHECK_GIG_LIMIT_SUCCESS,
  GIG_ACTIVE_COUNT_SUCCESS
} from '../../constants/actionTypes';
import { PER } from '../../constants/salaryTypes';

export const INITIAL_NEW_GIG_DATA_STATE = {
  gig: Map({
    name: '',
    expiryDate: null,
    categories: List(),
    startDate: null,
    noStartDate: false,
    endDate: null,
    noEndDate: false,

    position: '',
    description: '',
    candidatesNumber: '1',
    careerUrl: undefined,

    remoteWorkAllowed: false,
    location: Map({
      cityV2: Map({
        key: 'stockholm',
        name: 'Stockholm',
      }),
      street: '',
      info: '',
    }),
    preferredOccupation: List(),
    workload: null,
    shifts: List(),
    experience: null,
    languages: List(),
    questionnaire: List(),
    photoUri: undefined,
    compensation: Map({
      salary: Map({
        min: '',
        max: '',
        salaryPer: PER.HOUR,
      }),
      labourContract: false,
      salaryByAgreement: false,
      volunteering: false,
      internshipTrainee: false,
    }),
  }),

  gigsLeft: null,
  activeGigs: null,
};

export default function gigCreatorReducer(state = new Map(INITIAL_NEW_GIG_DATA_STATE), action) {
  switch (action.type) {
    case GIG_CREATOR_CLEAR_ALL_DATA:
      return new Map(INITIAL_NEW_GIG_DATA_STATE);
    case GIG_CREATOR_INIT_EDIT_GIG:
      return new Map(
        fromJS({
          gig: action.gig,
        })
      );
    case GIG_CREATOR_ADD_NAME:
      return state.setIn(['gig', 'name'], action.name);
    case GIG_CREATOR_ADD_EXPIRY_DATE:
      return state.setIn(['gig', 'expiryDate'], action.expiryDate);
    case GIG_CREATOR_ADD_CATEGORIES:
      return state.setIn(['gig', 'categories'], List(action.categories));
    case GIG_CREATOR_ADD_POSITION:
      return state.setIn(['gig', 'position'], action.position);
    case GIG_CREATOR_ADD_DESCRIPTION:
      return state.setIn(['gig', 'description'], action.description);
    case GIG_CREATOR_ADD_CANDIDATES_NUMBER:
      return state.setIn(['gig', 'candidatesNumber'], action.candidatesNumber);
    case GIG_CREATOR_ADD_CAREER_URL:
      return state.setIn(['gig', 'careerUrl'], action.careerUrl);
    case GIG_CREATOR_ADD_REMOTE_WORK_ALLOWED:
      return state.setIn(['gig', 'remoteWorkAllowed'], action.remoteWorkAllowed);
    case GIG_CREATOR_ADD_LOCATION:
      return state.setIn(['gig', 'location'], Map({ ...action.location }));
    case GIG_CREATOR_ADD_DATES: {
      const { startDate, endDate, noStartDate, noEndDate } = action.dates;

      return state
        .setIn(['gig', 'startDate'], startDate)
        .setIn(['gig', 'endDate'], endDate)
        .setIn(['gig', 'noStartDate'], noStartDate)
        .setIn(['gig', 'noEndDate'], noEndDate);
    }
    case GIG_CREATOR_ADD_COMPENSATION:
      return state.setIn(['gig', 'compensation'], fromJS(action.compensation));
    case GIG_CREATOR_ADD_AVAILABILITY:
      return state.setIn(['gig', 'workload'], action.workload).setIn(['gig', 'shifts'], List(action.shifts));
    case GIG_CREATOR_ADD_EXPERIENCE:
      return state.setIn(['gig', 'experience'], action.experience);
    case GIG_CREATOR_ADD_LANGUAGES:
      return state.setIn(['gig', 'languages'], fromJS(action.languages));
    case GIG_CREATOR_ADD_PHOTO:
      return state.setIn(['gig', 'photoUri'], action.photoUri);
    case GIG_CREATOR_ADD_OCCUPATION:
      return state.setIn(['gig', 'preferredOccupation'], List(action.preferredOccupation));
    case GIG_CREATOR_ADD_QUESTIONNAIRE:
      return state.setIn(['gig', 'questionnaire'], List(action.questionnaire));
    case GIG_CREATOR_UPDATE_GIG_SUCCESS:
      return state.setIn(['gig'], fromJS(action.payload));
    case GIG_CREATOR_CHECK_GIG_LIMIT_SUCCESS:
      console.log(`GIG_CREATOR_CHECK_GIG_LIMIT_SUCCESSS${fromJS(action.payload.gigsLeft)}`);
      return state.setIn(['gigsLeft'], action.payload.gigsLeft);
    case GIG_ACTIVE_COUNT_SUCCESS:
      console.log(`GIG_ACTIVE_COUNT_SUCCESS ${fromJS(action.payload.activeGigs)}`);
      return state.setIn(['activeGigs'], action.payload.activeGigs);
    default:
      return state;
  }
}
