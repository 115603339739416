export function getExperienceKey(experience) {
  return experience ? experience.experienceId || experience.tmpExperienceId : null;
}

export function getExperienceByKey(experienceList, key) {
  return experienceList.find((e) => getExperienceKey(e) === key);
}

export function getNewExperience() {
  return {
    tmpExperienceId: `${Math.random()}`,
    category: undefined,
    position: '',
    companyName: '',
    fromDate: undefined,
    toDate: undefined,
    ongoing: false,
    description: '',
  };
}
