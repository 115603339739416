import validator from 'validator';

export const MAX_COUNTRY_CODE_LENGTH = 4;
export const MAX_PHONE_NUMBER_LENGTH = 14;

const countryCodeRegExp = /^\+\d{1,3}$/;
const phoneNumberRegExp = /^\d{5,14}$/;

export const phoneNumberValidation = [
  {
    field: 'msisdn.countryCode',
    custom: (value) => countryCodeRegExp.test(value),
    message: 'wrong_format',
  },
  {
    field: 'msisdn.phoneNumber',
    custom: (value) => (value ? phoneNumberRegExp.test(value) : true),
    message: 'wrong_format',
  },
  {
    field: 'msisdn.phoneNumber',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
];
