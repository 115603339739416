import { EDUCATION_CATEGORIES_REQUEST } from '../../../../constants/actionTypes';

function getEducationCategoriesAction() {
  return {
    type: EDUCATION_CATEGORIES_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/education/category',
      },
    },
  };
}

export function getEducationCategories() {
  return async (dispatch, getState) => {
    const categories = getState().educationCategories;
    if (categories.size > 0) {
      return;
    }
    dispatch(getEducationCategoriesAction());
  };
}
