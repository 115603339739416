import React from 'react';
import PropTypes from 'prop-types';
import {
  View, StyleSheet, Text,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';

import Container from '../../../common/components/Container';
import Routing from '../../../core/routing/index';
import {
  spacings, colors, fontFamily, fontSizes,
} from '../../../common/styles/base.style';
import Touchable from '../../../common/components/Touchable';
import ListItem from '../../../common/components/ListItem';
import { COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE, COMPANY_CANDIDATES_POOL_DIRECT_CHAT } from '../../../constants/routes';
import candidatesListStyles from '../../gigs/gigDetails/candidates/components/GigCandidatesListStyle';
import PrimarySectionList from '../../../common/components/PrimarySectionList';
import { companyClearMyPoolsNewContent, storeCandidatesPoolScrollPosition } from '../companyCandidatesPoolActions';
import ProfileInfo from '../../../common/components/ProfileInfo';
import { COMPANY_POOLS_CONTENT_FIELDS } from '../companyCandidatesPoolReducer';
import { font } from '../../../common/styles/mixins';
import PoolChatButton from '../../../common/chat/pool/PoolChatButton';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  listItem: {
    margin: spacings.sm,
    flexDirection: 'row',
  },
  profileImage: {
    width: 54,
    height: 54,
    borderRadius: 27,
  },
});

const candidateProfileInfoStyles = StyleSheet.create({
  wrapper: {
    marginBottom: spacings.xxs,
  },
  infoWrapper: {
    marginLeft: spacings.sm,
    marginRight: spacings.sm,
  },
  name: {
    marginBottom: spacings.xxs,
  },
  partOfContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  partOf: {
    ...font(fontFamily.SFProTextRegular, fontSizes.xs, -0.2, colors.lightPeach),
  },
  partOfShowHide: {
    ...font(fontFamily.SFProTextSemiBold, fontSizes.xs, -0.2, colors.brownGrey),
    paddingLeft: spacings.xs,
  },
  team: {
    ...font(fontFamily.SFProTextRegular, fontSizes.xs, -0.2, colors.lightPeach),
  },
});

class CompanyCandidatesPoolMyPoolScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showTeamsMap: {} };
  }

  componentDidMount() {
    this.scrollPosition = 0;
    if (this.props.history.action === 'POP') {
      const { storedScrollPosition } = this.props;
      if (storedScrollPosition) {
        this.scrollToLastKnownPosition(storedScrollPosition);
      }
    } else {
      this.props.storeScrollPosition(this.scrollPosition);
    }
  }

  componentWillUnmount() {
    this.props.storeScrollPosition(this.scrollPosition);
    this.props.companyClearMyPoolsNewContent(COMPANY_POOLS_CONTENT_FIELDS.MY_POOL);
  }

  onShowTeams(poolCandidateId) {
    const { showTeamsMap } = this.state;
    showTeamsMap[poolCandidateId] = true;
    this.setState({ showTeamsMap });
  }

  onHideTeams(poolCandidateId) {
    const { showTeamsMap } = this.state;
    showTeamsMap[poolCandidateId] = false;
    this.setState({ showTeamsMap });
  }

  viewProfile = (candidateId) => {
    this.props.history.push(COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE.replace(':candidateId', candidateId));
  };

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  startChat = (poolCandidateId) => {
    this.props.history.push(COMPANY_CANDIDATES_POOL_DIRECT_CHAT.replace(':poolCandidateId', poolCandidateId));
  };

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToLocation({
      sectionIndex: 0,
      animated: true,
      itemIndex: 0,
      viewOffset: -scrollPosition + 7,
      viewPosition: 0,
    }), 0);
  }

  renderPartOf(poolCandidate) {
    const { groups, poolCandidateId } = poolCandidate;
    const { translate } = this.props;
    return (
      <View style={{ paddingBottom: spacings.xxs }}>
        <View style={candidateProfileInfoStyles.partOfContainer}>
          <Text style={candidateProfileInfoStyles.partOf}>
            {translate('company.candidatesPool.poolCandidatesList.teamsPartOf', { teamsNumber: groups.length })}
          </Text>
          {groups.length > 0 && this.state.showTeamsMap[poolCandidateId] !== true
          && (
            <Text style={candidateProfileInfoStyles.partOfShowHide} onPress={() => this.onShowTeams(poolCandidateId)}>
              {translate('company.candidatesPool.poolCandidatesList.teamsShow')}
            </Text>
          )}
          {this.state.showTeamsMap[poolCandidateId] === true
          && (
            <Text style={candidateProfileInfoStyles.partOfShowHide} onPress={() => this.onHideTeams(poolCandidateId)}>
              {translate('company.candidatesPool.poolCandidatesList.teamsHide')}
            </Text>
          )}
        </View>
        {this.state.showTeamsMap[poolCandidateId] === true
        && (
          groups.map((group) => <Text key={group.groupId} style={candidateProfileInfoStyles.team}>{`• ${group.name}`}</Text>)
        )}
      </View>
    );
  }

  render() {
    const { poolCandidates, translate, newContentList } = this.props;
    let invitedCandidates;
    let activeCandidates;
    if (poolCandidates) {
      invitedCandidates = poolCandidates.filter((poolCandidate) => poolCandidate.status === 'INVITED');
      activeCandidates = poolCandidates.filter((poolCandidate) => poolCandidate.status === 'ACTIVE');
    }
    return (
      <Container>
        <PrimarySectionList
          emptyListTextKey={({ key }) => translate(`company.candidatesPool.poolCandidatesList.emptyList.${key}`)}
          contentContainerStyle={candidatesListStyles.contentContainerStyle}
          sections={[
            {
              key: 'active',
              title: translate('company.candidatesPool.poolCandidatesList.activeList'),
              data: activeCandidates || [],
              dataLoading: !activeCandidates,
            },
            {
              key: 'invited',
              title: translate('company.candidatesPool.poolCandidatesList.invitedList'),
              data: invitedCandidates || [],
              dataLoading: !invitedCandidates,
            },
          ]}
          keyExtractor={(poolCandidate) => poolCandidate.candidate.userId}
          innerRef={this.setListRef}
          onScroll={this.onScroll}
          renderItem={({ item: poolCandidate }) => (
            <ListItem
              renderContent={() => (
                <Touchable activeOpacity={0.5} onPress={() => this.viewProfile(poolCandidate.candidate.userId)}>
                  <View style={styles.listItem}>
                    <Container>
                      <ProfileInfo
                        profile={poolCandidate.candidate}
                        lastNameOneLetter={poolCandidate.status !== 'ACTIVE'}
                        imageSize={styles.profileImage}
                        style={candidateProfileInfoStyles}
                        online={this.props.usersPresence[poolCandidate.candidate.userId]}
                        renderExtraInfo={() => this.renderPartOf(poolCandidate)}
                      />
                    </Container>
                    {poolCandidate.status === 'ACTIVE' && (
                      <PoolChatButton poolChatId={poolCandidate.poolCandidateId} onPress={() => this.startChat(poolCandidate.poolCandidateId)} />
                    )}
                  </View>
                </Touchable>
              )}
              style={newContentList.includes(poolCandidate.poolCandidateId) ? { borderColor: colors.seablue, borderWidth: 2 } : null}
            />
          )}
        />
      </Container>
    );
  }
}

CompanyCandidatesPoolMyPoolScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  poolCandidates: PropTypes.arrayOf(PropTypes.shape({
    poolCandidateId: PropTypes.string.isRequired,
    candidate: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    invitationDate: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  })),
  storedScrollPosition: PropTypes.number.isRequired,
  storeScrollPosition: PropTypes.func.isRequired,
  companyClearMyPoolsNewContent: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  newContentList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CompanyCandidatesPoolMyPoolScreen.defaultProps = {
  poolCandidates: [],
};

const mapStateToProps = (state) => ({
  storedScrollPosition: state.companyCandidatesPool.get('candidatesPoolScrollPosition'),
  newContentList: state.companyCandidatesPool.get('newContent').get(COMPANY_POOLS_CONTENT_FIELDS.MY_POOL).toJS(),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

export default connect(
  mapStateToProps,
  { storeScrollPosition: storeCandidatesPoolScrollPosition, companyClearMyPoolsNewContent },
)(withLocalize(withRouter(CompanyCandidatesPoolMyPoolScreen)));
