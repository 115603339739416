import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorStepDescriptionScreen from './GigCreatorStepDescriptionScreen';
import {
  gigCreatorAddCandidatesNumber, gigCreatorAddDescription, gigCreatorAddPosition, gigCreatorAddCareerUrl,
} from '../../gigCreatorActions';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepDescriptionContainer extends Component {
  constructor(props) {
    super(props);

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.POSITION));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.DESCRIPTION);
  }

  onSubmit = ({
    position, description, candidatesNumber, careerUrl,
  }, { setStatus, setSubmitting }) => {
      this.props.gigCreatorAddPosition(position);
      this.props.gigCreatorAddDescription(description);
      this.props.gigCreatorAddCandidatesNumber(candidatesNumber);
      this.props.gigCreatorAddCareerUrl(careerUrl);

      if (this.props.isEdit) {
        this.props.updateGig(GIG_EDIT_SECTIONS.POSITION_AND_DESCRIPTION, {
          position,
          description,
          candidatesNumber: parseInt(candidatesNumber, 10),
          careerUrl,
        })
          .then(() => this.props.history.goBack())
          .catch(() => {
            setSubmitting(false);
          });
      } else {
        setStatus({ redirect: true });
      }
  };

  render() {
    const {
      position, description, candidatesNumber, careerUrl, isEdit,
    } = this.props;
    const initialValues = {
      position, description, candidatesNumber, careerUrl,
    };

    return (
      <GigCreatorStepDescriptionScreen
        stepNumber={this.stepNumber}
        nextScreen={this.nextScreen}
        maxStepsNumber={this.maxStepsNumber}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        isEdit={isEdit}
      />
    );
  }
}

GigCreatorStepDescriptionContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  candidatesNumber: PropTypes.string.isRequired,
  careerUrl: PropTypes.string,
  gigCreatorAddPosition: PropTypes.func.isRequired,
  gigCreatorAddDescription: PropTypes.func.isRequired,
  gigCreatorAddCandidatesNumber: PropTypes.func.isRequired,
  gigCreatorAddCareerUrl: PropTypes.func.isRequired,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

GigCreatorStepDescriptionContainer.defaultProps = {
  careerUrl: undefined,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      position,
      description,
      candidatesNumber,
      careerUrl,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return {
    position,
    description,
    candidatesNumber: `${candidatesNumber}`,
    careerUrl,
    grantedFeatures,
  };
};

export default withRouter(withGigEdit(mapStateToProps, {
  gigCreatorAddPosition,
  gigCreatorAddCareerUrl,
  gigCreatorAddDescription,
  gigCreatorAddCandidatesNumber,
})(GigCreatorStepDescriptionContainer));
