import React, { Component } from 'react';
import { isAvailableAsync, requestReview, storeUrl } from 'expo-store-review';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ActivityIndicator, Linking, StyleSheet, Text, View, Platform,
} from 'react-native';
import { connect } from 'react-redux';
import LottieView from 'lottie-react-native';
import { withLocalize } from 'react-localize-redux';

import { Button } from '../../../../common/components';
import { BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import { FIND_GIGS_CONTEXT } from '../../../../constants/actionContext';
import {
  fontFamily, fontSizes, shadowTop, spacings,
} from '../../../../common/styles/base.style';
import { setRateAppPopupShownTimestamp } from '../../../../common/rate_app/rateAppActions';
import { DAYS_TO_NEXT_APP_RATE_REQUEST } from '../../../../common/rate_app/rateAppConstants';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import { applyForGig } from '../../my_gigs/candidateMyGigsActions';
import { showInformationAlert, showOptionsAlert } from '../../../../common/components/alert/alerts';
import { font } from '../../../../common/styles/mixins';
import CandidateQuestionnaireModalEdit from '../../questionnaire/CandidateQuestionnaireModalEdit';
import { filledOutQuestionnairePropType } from '../../../../constants/propTypes';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const checkAnimation = require('../../../../assets/animations/check.json');

const {
  APPLIED,
} = GIG_CANDIDATE_STATUS;

const styles = StyleSheet.create({
  base: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  baseWithBackground: {
    ...shadowTop,
    paddingVertical: spacings.md,
    backgroundColor: 'white',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  appliedInfoText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md),
  },
});

class ApplyAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMode: 'button',
      questionnaireModalVisible: false,
      questionnaire: props.questionnaire,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutOnAnimation);
    clearTimeout(this.timeoutOnAppRate);
  }

  onApply() {
    googleAnalytics.trackGigEvent(this.props.gigId, ACTIONS.GIG.CANDIDATE_CLICK_APPLY);
    const { questionnaire } = this.state;
    if (questionnaire && questionnaire.length) {
      this.openQuestionnaireModal();
    } else {
      this.apply();
    }
  }

  rateApp = async () => {
    const { lastRateAppPopupTimestamp, translate, grantedFeatures } = this.props;
    if (!grantedFeatures.includes('RATE_APP')) {
      return;
    }
    if (!lastRateAppPopupTimestamp || moment.utc().isAfter(moment.utc(lastRateAppPopupTimestamp).add(DAYS_TO_NEXT_APP_RATE_REQUEST, 'day'))) {
      const isSupported = await isAvailableAsync();
      if (isSupported && Platform.OS === 'ios') {
        this.props.setRateAppPopupShownTimestamp();
        requestReview();
      } else if (Platform.OS !== 'web') {
        const url = storeUrl();
        if (url) {
          showOptionsAlert(
            translate,
            {
              titleKey: 'appRate.appRateTitle',
              textKey: 'appRate.appRateText',
              cancelKey: 'appRate.later',
              cancelOnPress: () => this.props.setRateAppPopupShownTimestamp(),
            },
            [
              {
                optionKey: 'appRate.never',
                onPress: () => this.props.setRateAppPopupShownTimestamp(moment.utc().add(5, 'year').valueOf()),
              },
              {
                optionKey: 'appRate.rate',
                onPress: () => {
                  Linking.openURL(url);
                  this.props.setRateAppPopupShownTimestamp(moment.utc().add(5, 'year').valueOf());
                },
              },
            ],
          );
        }
      }
    }
  };

  openQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: true });
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  saveQuestionnaireAnswers = () => {
    this.closeQuestionnaireModal();
    this.apply();
  };

  handleUpdateAnswer = (questionId, newAnswer) => {
    const currentQuestionnaireItems = this.state.questionnaire;
    for (let i = 0; i < currentQuestionnaireItems.length; i += 1) {
      if (currentQuestionnaireItems[i].question.questionId === questionId) {
        currentQuestionnaireItems[i].answer.text = newAnswer;
        this.setState({ questionnaire: currentQuestionnaireItems });
        break;
      }
    }
  };

  apply() {
    const { gigId, applyForGigAction, updateGigDataOnPreviewScreen } = this.props;
    const { questionnaire } = this.state;
    this.setState({ displayMode: 'loading' });
    applyForGigAction({ gigId }, FIND_GIGS_CONTEXT, { questionnaire }).then((response) => {
      if (!response.isError) {
        this.setState({ displayMode: 'animation' });
        this.timeoutOnAnimation = setTimeout(() => { updateGigDataOnPreviewScreen(gigId, APPLIED); }, 2000);
        this.timeoutOnAppRate = setTimeout(() => this.rateApp(), 1500);
      } else {
        if (response && response.status === 404) {
          showInformationAlert(
            this.props.translate,
            {
              confirmKey: 'generic.close',
              titleKey: 'candidateMyGigs.errors.genericErrorTitle',
              textKey: 'candidateMyGigs.errors.gigClosedApplyError',
            },
          );
        }
        this.setState({ displayMode: 'button' });
      }
    });
  }

  renderApply() {
    const { translate } = this.props;
    switch (this.state.displayMode) {
      case 'button':
        return (
          <Button
            type={BUTTON_TYPES.PRIMARY_RED}
            title={translate('generic.applyNow')}
            onPress={() => this.onApply()}
          />
        );
      case 'loading':
        return (
          <View style={styles.baseWithBackground}>
            <ActivityIndicator size="large" />
          </View>
        );
      case 'animation':
        return (
          <View style={styles.baseWithBackground}>
            <Text style={styles.appliedInfoText}>{translate('candidateMyGigs.actions.youHaveApplied')}</Text>
            <LottieView
              source={checkAnimation}
              autoPlay
              loop={false}
              style={{ height: 60, width: 60 }}
            />
          </View>
        );
      default:
        return null;
    }
  }

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, questionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalEdit
        visible={questionnaireModalVisible}
        onClose={this.closeQuestionnaireModal}
        onSave={this.saveQuestionnaireAnswers}
        handleUpdateAnswer={this.handleUpdateAnswer}
        questionnaire={questionnaire}
      />
    );
  }

  render() {
    return (
      <View style={styles.base}>
        {this.renderApply()}
        {this.renderQuestionnaireModal()}
      </View>
    );
  }
}

ApplyAction.propTypes = {
  translate: PropTypes.func.isRequired,
  gigId: PropTypes.string.isRequired,
  applyForGigAction: PropTypes.func.isRequired,
  updateGigDataOnPreviewScreen: PropTypes.func.isRequired,
  questionnaire: PropTypes.arrayOf(filledOutQuestionnairePropType),
  setRateAppPopupShownTimestamp: PropTypes.func.isRequired,
  lastRateAppPopupTimestamp: PropTypes.number.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ApplyAction.defaultProps = {
  questionnaire: undefined,
};

const mapStateToProps = (state) => ({
  lastRateAppPopupTimestamp: state.rateApp.get('lastRateAppPopupTimestamp'),
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default connect(mapStateToProps, { applyForGigAction: applyForGig, setRateAppPopupShownTimestamp })(withLocalize(ApplyAction));
