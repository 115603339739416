import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../common/components/form/FormikWithValidation';
import { emailValidation, USER_EMAIL_MAX_LENGTH } from '../../../common/auth/LoginValidation';
import FormikFieldPrimaryInputTextNextSupport from '../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import KeyboardAwareFlexWrapper from '../../../common/components/KeyboardAwareFlexWrapper';

class CandidateProfileEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      translate, renderFooter, handleSubmit, email,
    } = this.props;

    return (
      <FormikWithValidation
        validation={emailValidation}
        initialValues={{ email }}
        onSubmit={handleSubmit}
        render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
          <>
            <KeyboardAwareFlexWrapper>
              <FormikFieldPrimaryInputTextNextSupport
                name="email"
                label={translate('candidateWelcomeScreen.signupScreen.emailLabel')}
                maxLength={USER_EMAIL_MAX_LENGTH}
              />

            </KeyboardAwareFlexWrapper>
            {renderFooter({ handleSubmitFormik, isBtnDisabled })}
          </>
        )}
      />
    );
  }
}

CandidateProfileEmailForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default withLocalize(CandidateProfileEmailForm);
