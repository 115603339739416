import {
  POOL_CHAT_SET_MESSAGES,
  POOL_CHAT_NEW_MESSAGE,
  POOL_CHAT_SAVE_LAST_MESSAGE_READ,
  POOL_CHAT_UPDATED,
} from '../../../constants/actionTypes';

export function poolChatLoadMessages(chatId, messages) {
  return {
    type: POOL_CHAT_SET_MESSAGES,
    messages,
    chatId,
  };
}

export function poolChatNewMessage(chatId, message) {
  return {
    type: POOL_CHAT_NEW_MESSAGE,
    message,
    chatId,
  };
}

export function poolChatSaveLastMessageRead(poolChatId, chatId, timestamp) {
  return {
    type: POOL_CHAT_SAVE_LAST_MESSAGE_READ,
    poolChatId,
    chatId,
    timestamp,
  };
}

export function poolChatUpdated(poolChatId, chatId, updatedDate, msg) {
  return {
    type: POOL_CHAT_UPDATED,
    poolChatId,
    chatId,
    updatedDate,
    msg,
  };
}
