import { Platform, StyleSheet } from 'react-native';
import { color, contentWidth, spacings } from './base.style';

export default StyleSheet.create({
  fullScreenModal: {
    backgroundColor: color.primaryContrast,
    flex: 1,
    ...Platform.select({
      web: {
        width: contentWidth,
        marginHorizontal: 'auto',
      },
    }),
    marginTop: Platform.OS === 'ios' ? 35 : 0
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: spacings.sm,
    paddingBottom: spacings.md,
  },
});
