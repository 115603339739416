import { StyleSheet, Platform } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { spacings } from '../styles/base.style';
import Header from '../components/header/Header';

const styles = StyleSheet.create({
  text: {
    marginBottom: spacings.sm,
    width: 260,
    minHeight: 57,
    textAlignVertical: 'center',
    ...Platform.select({
      web: {
        width: '100%',
        minHeight: 35,
      },
    }),
  },
});

const WelcomeScreenTitle = ({ title }) => (
  <Header style={styles.text}>{title}</Header>
);

WelcomeScreenTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default WelcomeScreenTitle;
