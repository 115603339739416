import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';

import validation from './GigCreatorStepExperienceValidation';
import FormikFieldSelectList from '../../../../common/components/form/FormikSelectList';
import { GIG_EXPERIENCE } from '../../../../constants/experienceTypes';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorHeader from '../../GigCreatorHeader';
import LinkButton from '../../../../common/components/buttons/LinkButton';
import { dynamicSpacings, spacings } from '../../../../common/styles/base.style';
import Container from '../../../../common/components/Container';
import Wrapper from '../../../../common/components/Wrapper';
import Button, { BUTTON_SIZES } from '../../../../common/components/buttons/Button';

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    paddingTop: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
  },
});

const GigCreatorStepExperienceScreen = ({
  translate, experience, handleSubmit, handleSkip, stepNumber, nextScreen, redirect, isEdit, maxStepsNumber,
}) => (
  <Container>
    <FormikWithValidation
      validation={isEdit ? [] : validation}
      initialValues={{ experience }}
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
        <>
          <GigCreatorHeader
            isEdit={isEdit}
            stepNo={stepNumber}
            maxStepsNumber={maxStepsNumber}
            title={translate('companyGigCreator.experience.header')}
            optional
            onRightButtonPress={handleSubmitFormik}
          />
          <Wrapper style={{ marginTop: spacings.lg }}>
            <FormikFieldSelectList
              name="experience"
              items={Object.keys(GIG_EXPERIENCE).map((key) => ({ value: GIG_EXPERIENCE[key], label: translate(`gigExperience.${key}`) }))}
            />
          </Wrapper>
          <View style={[styles.buttonsContainer, isEdit ? { justifyContent: 'center' } : null]}>

            {isEdit ? (
              <Button
                title={translate('generic.save')}
                onPress={handleSubmitFormik}
              />
            ) : (
              <>
                <LinkButton
                  size={BUTTON_SIZES.FLEX_MD}
                  type="secondary"
                  to={nextScreen}
                  title={translate('generic.skip')}
                  onPress={handleSkip}
                  // we don't have redirect here, because it's already on LinkButton
                />
                <LinkButton
                  size={BUTTON_SIZES.FLEX_MD}
                  to={nextScreen}
                  title={translate('generic.next')}
                  doRedirect={redirect}
                  disabled={isBtnDisabled}
                  onPress={handleSubmitFormik}
                />
              </>
            )}
          </View>
        </>
      )}
    />
  </Container>
);

GigCreatorStepExperienceScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  experience: PropTypes.number,

  handleSubmit: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

GigCreatorStepExperienceScreen.defaultProps = {
  experience: null,
};

export default withLocalize(GigCreatorStepExperienceScreen);
