import React, { Component } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { languagePropType } from '../../constants/propTypes';
import { spacings } from '../styles/base.style';
import Wrapper from '../components/Wrapper';
import PrimaryText from '../components/PrimaryText';
import LanguagesFormRow from './LanguagesFormRow';
import Button, { BUTTON_SIZES } from '../components/buttons/Button';

const styles = StyleSheet.create({
  wrapper: {
    marginTop: spacings.md,
  },
  buttonWrapper: {
    marginTop: spacings.md,
    marginBottom: spacings.lg,
  },
});

class LanguagesForm extends Component {
  render() {
    const {
      languages, handleAddLanguage, handleRemoveLanguage, handleUpdateLanguage, formDescription, btnTitle,
    } = this.props;
    const usedLanguageCodes = languages.map((l) => l.code);
    return (
      <ScrollView ref={(c) => { this.scrollViewRef = c; }}>
        <Wrapper style={styles.wrapper}>
          <PrimaryText>{formDescription}</PrimaryText>
          {
            languages.map((langItem) => (
              <LanguagesFormRow
                item={langItem}
                usedLanguages={usedLanguageCodes}
                handleOnChange={handleUpdateLanguage}
                handleRemove={handleRemoveLanguage}
                key={`${langItem.code}_${langItem.level}`}
              />
            ))
          }
          <Button
            style={{ wrapper: styles.buttonWrapper }}
            title={btnTitle}
            onPress={() => {
              handleAddLanguage();
              setTimeout(() => this.scrollViewRef.scrollToEnd(), 300);
            }}
            size={BUTTON_SIZES.MD}
          />
        </Wrapper>
      </ScrollView>
    );
  }
}

LanguagesForm.propTypes = {
  languages: PropTypes.arrayOf(languagePropType).isRequired,
  handleAddLanguage: PropTypes.func.isRequired,
  handleRemoveLanguage: PropTypes.func.isRequired,
  handleUpdateLanguage: PropTypes.func.isRequired,
  formDescription: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
};

export default withLocalize(LanguagesForm);
