import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import PrimaryModal from '../../../../common/components/modal/PrimaryModal';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../../common/components/buttons/Button';
import ImageComponent from '../../../../common/components/image/ImageComponent';
import PrimaryText from '../../../../common/components/PrimaryText';
import {
  fontFamily, fontSizes, hp, spacings, wp,
} from '../../../../common/styles/base.style';
import { CANDIDATE_FIND_GIGS_SINGLE_FAVOURITES } from '../../../../constants/routes';

const imgSrc = require('../../../../assets/photos/favouritesPopup/gigs-info.png');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  boldText: {
    width: '75%',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: fontSizes.xxl,
    fontFamily: fontFamily.SFProDisplayBold,
    marginBottom: spacings.sm,
  },
  regularText: {
    width: '90%',
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginBottom: spacings.sm,
  },
});

class ProposeFavouritesTabModalView extends Component {
  redirectToFavouritesScreen = () => {
    this.props.closeModal();
    this.props.history.push(CANDIDATE_FIND_GIGS_SINGLE_FAVOURITES);
  };

  render() {
    const { visible, closeModal, translate } = this.props;
    return (
      <PrimaryModal
        header=""
        visible={visible}
        handleCancel={closeModal}
        headerProps={{ showBackArrow: false, shadow: false }}
      >
        <View style={styles.container}>
          <ImageComponent image={imgSrc} size="md" style={{ image: { width: wp(65), height: hp(45), resizeMode: 'contain' } }} />
          <PrimaryText style={styles.boldText}>{translate('proposeFavouritesModal.paragraphOne')}</PrimaryText>
          <PrimaryText style={styles.regularText}>{translate('proposeFavouritesModal.paragraphTwo')}</PrimaryText>
          <Button
            size={BUTTON_SIZES.MD}
            type={BUTTON_TYPES.PRIMARY_RED}
            title={translate('proposeFavouritesModal.seeFavouritesButton')}
            onPress={this.redirectToFavouritesScreen}
          />
          <Button
            size={BUTTON_SIZES.SM}
            type={BUTTON_TYPES.GRAY_TEXT}
            title={translate('proposeFavouritesModal.skipFavouritesButton')}
            onPress={closeModal}
          />
        </View>
      </PrimaryModal>
    );
  }
}

ProposeFavouritesTabModalView.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default withLocalize(ProposeFavouritesTabModalView);
