import validator from 'validator';

export const MAX_PERSONAL_NUMBER_LENGTH = 12; // Adjusted for Swedish personal number

export const personalNumberValidation = [
  {
    field: 'personalNumber',
    method: validator.isLength,
    args: [{ min: MAX_PERSONAL_NUMBER_LENGTH, max: MAX_PERSONAL_NUMBER_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'personalNumber',
    method: (value) => {
      const re = /^(19|20)\d{2}(-)?(0[1-9]|1[012])(-)?(0[1-9]|[12][0-9]|3[01])\d{4}$/;
      return re.test(value);
    },
    message: 'invalid_format',
  }
];
