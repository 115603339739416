export const GIG_MAX_CATEGORIES = 3;
export default
[
  {
    field: 'categories',
    custom: (categories) => categories.length > 0,
    message: 'too_few',
  },
  {
    field: 'categories',
    custom: (categories) => categories.length <= GIG_MAX_CATEGORIES,
    message: 'too_many',
  },
];
