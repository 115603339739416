// eslint-disable-next-line import/prefer-default-export
export function clearToDateIfOngoing(e) {
  return ({ ...e, toDate: e.ongoing ? undefined : e.toDate });
}

export function getMissingProfilePropsNumber(profile) {
  let missingProfilePropsNumber = 0;
  if (!profile.categories || profile.categories.length === 0) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.languages || profile.languages.length === 0) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.currentOccupation || profile.currentOccupation.length === 0) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.hours) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.times || profile.times.length === 0) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.experience || profile.experience.length === 0) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.education || profile.education.length === 0) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.aboutMe) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.msisdn) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.personalNumber) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.location || !profile.location.cityV2) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.homeLocation) {
    missingProfilePropsNumber += 1;
  }
  if (!profile.profileImageUri) {
    missingProfilePropsNumber += 1;
  }
  return missingProfilePropsNumber;
}
