import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  colors, dynamicSpacings, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import Container from '../../../common/components/Container';
import ScreenHeader from '../../../common/components/header/ScreenHeader';
import Wrapper from '../../../common/components/Wrapper';
import { font } from '../../../common/styles/mixins';
import { activeLanguagePropTypes, companyProfilePropType } from '../../../constants/propTypes';
import UserProfileImage from '../../../common/components/image/UserProfileImage';
import FormikWithValidation from '../../../common/components/form/FormikWithValidation';
import { COMPANY_DESCRIPTION_MAX_LENGTH, companyProfileValidation } from './CompanyProfileFormValidation';
import FormikFieldPrimaryTextInput from '../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikEffect from '../../../common/components/form/FormikEffect';
import { USER_FIRST_NAME_MAX_LENGTH, USER_LAST_NAME_MAX_LENGTH } from '../../../common/auth/UserSignupValidation';
import { COMPANY_NAME_MAX_LENGTH, getRegistrationNumberWithDash } from '../../auth/CompanySignupValidation';
import AutoScrollableKeyboardAwareScrollView from '../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import FormikFieldPrimaryInputTextNextSupport from '../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import FormikPhoneNumber from '../../../common/components/form/FormikPhoneNumber';
import COUNTRY_CODES from '../../../common/msisdn/msisdnConstants';
import GeneralFormError from '../../../common/components/form/GeneralFormError';
import NavigationSaveButton from '../../../common/components/navigation/NavigationSaveButton';
import FormikFieldPrimaryPicker from '../../../common/components/form/FormikFieldPrimaryPicker';
import { getAssociations } from '../../../association/AssociationActions';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacings.md,
    ...Platform.select({
      web: {
        paddingTop: spacings.lg,
      },
    }),
  },
  profileInfoHeader: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xxl, 0.4),
    textAlign: 'left',
    marginBottom: spacings.sm,
  },
  linksWrapper: {
    backgroundColor: colors.smokeWhite,
    paddingVertical: spacings.md,
    paddingHorizontal: dynamicSpacings.md,
  },
  defaultImageSize: {
    width: 107,
    height: 107,
    borderRadius: 53,
  },
  infoWrapper: {
    marginLeft: spacings.md,
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  linkColor: {
    color: colors.greyishBrown,
  },
});

class CompanyProfileEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAssociationsByCity = (city) => {
    this.props.getAssociations(city);
  }

  getTranslatedList = (nullable, list) => {
    const categories = list.map((c) => ({
      key: c.key,
      value: c.key,
      label: c.name ?? '',
    }));
    if (Platform.OS !== 'ios' && !list.category && nullable) {
      categories.unshift({ key: 'unselectable', label: '', value: null });
    }
    return categories;
  };

  render() {
    const {
      profile,
      translate,
      handleSave,
      onFormChange,
      handleBack,
      associations,
      availableCities,
      activeLanguage
    } = this.props;

    if (!profile.msisdn) {
      profile.msisdn = {
        countryCode: COUNTRY_CODES.SE,
        phoneNumber: '',
      };
    }

    return (
      <Container>
        <FormikWithValidation
          ref={(c) => { this.formikWithValidationRef = c; }}
          initialTouched={{ msisdn: { phoneNumber: true } }}
          validation={companyProfileValidation}
          enableReinitialize
          initialValues={profile}
          onSubmit={handleSave}
          render={({ handleSubmit, isBtnDisabled, errors }) => (
            <>
              <ScreenHeader
                title={translate('generic.editProfile')}
                showBackArrow
                handleBack={handleBack}
                rightButton={() => <NavigationSaveButton onPress={handleSubmit} isBtnDisabled={isBtnDisabled} />}
              />
              <AutoScrollableKeyboardAwareScrollView>
                <FormikEffect onChange={onFormChange} />
                <Wrapper>
                  <View style={[styles.container]}>
                    <UserProfileImage profileImageUri={profile.profileImageUri} style={[styles.defaultImageSize, { alignSelf: 'flex-start' }]} />
                    <View style={styles.infoWrapper}>
                      <FormikFieldPrimaryInputTextNextSupport
                        name="firstName"
                        label={translate('companyWelcomeScreen.signupScreen.firstNameLabel')}
                        maxLength={USER_FIRST_NAME_MAX_LENGTH}
                        nextComponent={this.state.lastNameInputRef}
                      />
                      <FormikFieldPrimaryInputTextNextSupport
                        innerRef={(ref) => !this.state.lastNameInputRef && this.setState({ lastNameInputRef: ref })}
                        name="lastName"
                        label={translate('companyWelcomeScreen.signupScreen.lastNameLabel')}
                        maxLength={USER_LAST_NAME_MAX_LENGTH}
                        nextComponent={this.state.phoneNumberInputRef}
                      />
                    </View>
                  </View>
                  <FormikPhoneNumber
                    countryCodeName="msisdn.countryCode"
                    phoneNumberName="msisdn.phoneNumber"
                    countryCodeLabel={translate('candidateProfile.details.countryCodeLabel')}
                    phoneNumberLabel={translate('candidateProfile.details.phoneLabel')}
                    innerRef={(ref) => !this.state.phoneNumberInputRef && this.setState({ phoneNumberInputRef: ref })}
                    nextComponent={this.state.companyNameInputRef}
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    innerRef={(ref) => !this.state.companyNameInputRef && this.setState({ companyNameInputRef: ref })}
                    name="companyName"
                    label={translate('companyMyProfile.companyNameLabel')}
                    maxLength={COMPANY_NAME_MAX_LENGTH}
                    nextComponent={this.state.companyRegistrationNumberInputRef}
                    autoscroll
                  />
                  <FormikFieldPrimaryInputTextNextSupport
                    innerRef={(ref) => !this.state.companyRegistrationNumberInputRef && this.setState({ companyRegistrationNumberInputRef: ref })}
                    name="companyRegistrationNumber"
                    label={translate('companyMyProfile.companyRegistrationNumberLabel')}
                    placeholder={translate('companyMyProfile.companyRegistrationNumberPlaceholder')}
                    onChangeTextModifyValue={getRegistrationNumberWithDash}
                    style={{ container: styles.inputText }}
                    nextComponent={this.state.companyDescriptionInputRef}
                    autoscroll
                    keyboardType={Platform.OS === 'web' ? null : 'numeric'}
                    returnKeyType="done"
                  />
                  <FormikFieldPrimaryPicker
                    name="city"
                    items={this.getTranslatedList(true, availableCities, activeLanguage)}
                    label={translate('adminPanel.chooseCity')}
                    nullable
                    onValueChange={(e) => this.getAssociationsByCity(e)}
                  />
                  <FormikFieldPrimaryPicker
                    enabled={associations.length > 1}
                    name="association"
                    items={associations}
                    label={translate('adminPanel.chooseAssociation')}
                    nullable
                  />
                  <FormikFieldPrimaryTextInput
                    innerRef={(ref) => !this.state.companyDescriptionInputRef && this.setState({ companyDescriptionInputRef: ref })}
                    name="companyDescription"
                    multiline
                    numberOfLines={4}
                    counter
                    maxLength={COMPANY_DESCRIPTION_MAX_LENGTH}
                    label={translate('companyMyProfile.aboutCompany')}
                    autoscroll
                    scrollEnabled={false}
                  />
                  <GeneralFormError errors={errors} />
                </Wrapper>
              </AutoScrollableKeyboardAwareScrollView>
            </>
          )}
        />
      </Container>
    );
  }
}

CompanyProfileEditScreen.propTypes = {
  profile: companyProfilePropType.isRequired,
  translate: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  associations: PropTypes.arrayOf(PropTypes.any).isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  availableCities: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAssociations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  associations: state.association.get('associationsList')
});

export default connect(mapStateToProps, { getAssociations })(withLocalize(CompanyProfileEditScreen));
