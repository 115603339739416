import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import Wrapper from '../../../../common/components/Wrapper';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import { MAX_PERSONAL_NUMBER_LENGTH, clearingNumberValidation } from './clearingNumberValidation';

const CandidateProfileClearingNumberForm = ({
  translate, renderFooter, handleSubmit, clearingNumber,
}) => (
  <FormikWithValidation
    validation={clearingNumberValidation}
    initialValues={{ clearingNumber }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <KeyboardAwareFlexWrapper>
          <Wrapper>
            <FormikFieldPrimaryTextInput
              name="clearingNumber"
              label={translate('candidateProfile.details.clearingNumber')}
              placeholder="XXXXX"
              maxLength={MAX_PERSONAL_NUMBER_LENGTH}
              returnKeyType="done"
              onSubmitEditing={handleSubmitFormik}
            />
          </Wrapper>
        </KeyboardAwareFlexWrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfileClearingNumberForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clearingNumber: PropTypes.string,
};

CandidateProfileClearingNumberForm.defaultProps = {
  clearingNumber: undefined,
};

export default withLocalize(CandidateProfileClearingNumberForm);
