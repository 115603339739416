import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from './Button';

const ButtonRequestAware = ({ disabledBecauseOfRequest, ...props }) => (
  <Button {...props} disabled={disabledBecauseOfRequest} />
);

ButtonRequestAware.propTypes = {
  disabledForRequest: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  disabledBecauseOfRequest: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  let { disabledForRequest } = props;
  if (!Array.isArray(disabledForRequest)) {
    disabledForRequest = [disabledForRequest];
  }
  const { requests } = state;
  const anyRequestInProgress = disabledForRequest.reduce((anyInProgress, request) => anyInProgress || requests.get(request).get('inProgress'), false);
  return ({
    disabledBecauseOfRequest: anyRequestInProgress,
  });
};
export default connect(mapStateToProps)(ButtonRequestAware);
