import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { workload as workloadPropType } from '../../../../constants/propTypes';
import validation from './GigCreatorStepAvailabilityValidation';
import FormikFieldSelectList from '../../../../common/components/form/FormikSelectList';
import { getWorkloadLabel, GIG_SHIFTS, GIG_WORKLOAD } from '../../../../constants/availabilityTypes';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorHeader from '../../GigCreatorHeader';
import LinkButton from '../../../../common/components/buttons/LinkButton';
import { shadowTop, spacings } from '../../../../common/styles/base.style';
import Container from '../../../../common/components/Container';
import Wrapper from '../../../../common/components/Wrapper';
import PrimaryText from '../../../../common/components/PrimaryText';
import Button from '../../../../common/components/buttons/Button';

const styles = StyleSheet.create({
  button: {
    paddingTop: spacings.md,
    ...shadowTop,
  },
});

const GigCreatorStepAvailabilityScreen = ({
  translate, shifts, workload, handleSubmit, stepNumber, nextScreen, redirect, isEdit, maxStepsNumber,
}) => (
  <Container>
    <FormikWithValidation
      validation={validation}
      initialValues={{ workload, shifts }}
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
        <>
          <GigCreatorHeader
            isEdit={isEdit}
            stepNo={stepNumber}
            maxStepsNumber={maxStepsNumber}
            title={translate('companyGigCreator.availability.header')}
            onRightButtonPress={handleSubmitFormik}
            isRightButtonDisabled={isBtnDisabled}
          />
          <ScrollView>
            <Wrapper style={{ marginTop: spacings.lg }}>
              <PrimaryText>{translate('companyGigCreator.availability.workloadLabel')}</PrimaryText>
              <FormikFieldSelectList
                name="workload"
                items={Object.keys(GIG_WORKLOAD).map((key) => ({
                  componentKey: key,
                  value: { min: GIG_WORKLOAD[key].min, max: GIG_WORKLOAD[key].max },
                  label: getWorkloadLabel(GIG_WORKLOAD[key], translate),
                }))}
              />

              <PrimaryText>{translate('companyGigCreator.availability.shiftsLabel')}</PrimaryText>
              <FormikFieldSelectList
                name="shifts"
                items={Object.keys(GIG_SHIFTS).map((key) => ({ value: GIG_SHIFTS[key], label: translate(`gigAvailability.${key}`) }))}
                multiple
              />
            </Wrapper>
          </ScrollView>
          <View style={styles.button}>
            {isEdit ? (
              <Button
                title={translate('generic.save')}
                disabled={isBtnDisabled}
                onPress={handleSubmitFormik}
              />
            ) : (
              <LinkButton
                to={nextScreen}
                title={translate('generic.next')}
                doRedirect={redirect}
                disabled={isBtnDisabled}
                onPress={handleSubmitFormik}
              />
            )}
          </View>
        </>
      )}
    />
  </Container>
);

GigCreatorStepAvailabilityScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.number).isRequired,
  workload: workloadPropType,
  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

GigCreatorStepAvailabilityScreen.defaultProps = {
  workload: null,
};

export default withLocalize(GigCreatorStepAvailabilityScreen);
