import { fromJS, Map } from 'immutable';
import {
  ADMIN_PROFILE_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

export const INITIAL_PROFILE_STATE = {
};

export default function (state = new Map(INITIAL_PROFILE_STATE), action) {
  switch (action.type) {
    case ADMIN_PROFILE_REQUEST_SUCCESS:
      return state.merge(fromJS(action.payload));
    default:
      return state;
  }
}
