import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Button from '../buttons/Button';
import ModalFooter from './ModalFooter';

const EditModalFooter = ({
  translate, handleSubmitFormik, isBtnDisabled, handleCancel, confirmLabel, cancelLabel,
}) => (
  <ModalFooter>
    <Button
      size="md"
      type="secondary"
      title={cancelLabel || translate('generic.cancel')}
      onPress={handleCancel}
    />
    <Button
      size="md"
      title={confirmLabel || translate('generic.save')}
      disabled={isBtnDisabled}
      onPress={handleSubmitFormik}
    />
  </ModalFooter>
);

EditModalFooter.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmitFormik: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isBtnDisabled: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

EditModalFooter.defaultProps = {
  confirmLabel: undefined,
  cancelLabel: undefined,
};

export default withLocalize(EditModalFooter);
