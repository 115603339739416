import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SHORTLISTED_LIST_KEY } from '../candidateMyGigsReducer';
import { matchedGigForCandidatePropType } from '../../../../constants/propTypes';
import {
  clearCandidateGigs, getCandidateGigs, removeNewContentIndicator, approveShortlisting,
} from '../candidateMyGigsActions';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import ListItem from '../../../../common/components/ListItem';
import GigListItem from '../GigListItem';
import { dispatchActionAndRefreshCounter } from '../CandidateListItemDefinitions';
import CandidateQuestionnaireModalEdit from '../../questionnaire/CandidateQuestionnaireModalEdit';
import { MY_GIGS_CONTEXT } from '../../../../constants/actionContext';

class CandidateMyGigsShortlistedView extends Component {
  constructor(props) {
    super(props);
    this.state = { questionnaireModalVisible: false, questionnaire: null, questionnaireGigId: null };
  }

  componentDidMount() {
    this.props.getCandidateGigs(SHORTLISTED_LIST_KEY, [GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL], false)
      .then(() => {
        this.setTabInactive();
      });
  }

  componentWillUnmount() {
    this.props.clearCandidateGigs(SHORTLISTED_LIST_KEY);
  }

  setTabInactive = () => {
    if (this.props.newContentIndicators.includes(GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL)) {
      this.props.removeNewContentIndicator(GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL);
    }
  };

  openQuestionnaireModal = (gigId, questionnaire) => {
    if (!questionnaire || !questionnaire.length) {
      this.approveShortlisting(gigId, questionnaire);
    } else {
      this.setState({ questionnaire, questionnaireGigId: gigId, questionnaireModalVisible: true });
    }
  };

  closeQuestionnaireModal = () => {
    this.setState({ questionnaireModalVisible: false });
  };

  saveQuestionnaireAnswers = () => {
    this.closeQuestionnaireModal();
    this.approveShortlisting(this.state.questionnaireGigId, this.state.questionnaire);
    this.setState({ questionnaire: null, questionnaireGigId: null });
  };

  handleUpdateAnswer = (questionId, newAnswer) => {
    const currentQuestionnaireItems = this.state.questionnaire;
    for (let i = 0; i < currentQuestionnaireItems.length; i += 1) {
      if (currentQuestionnaireItems[i].question.questionId === questionId) {
        currentQuestionnaireItems[i].answer.text = newAnswer;
        this.setState({ questionnaire: currentQuestionnaireItems });
        break;
      }
    }
  };

  approveShortlisting(gigId, questionnaire) {
    dispatchActionAndRefreshCounter(
      this.props.dispatchAction,
      approveShortlisting({ gigId, questionnaire }, { context: MY_GIGS_CONTEXT, listKey: SHORTLISTED_LIST_KEY }),
    );
  }

  renderQuestionnaireModal() {
    const { questionnaireModalVisible, questionnaire } = this.state;
    return (
      <CandidateQuestionnaireModalEdit
        visible={questionnaireModalVisible}
        onClose={this.closeQuestionnaireModal}
        onSave={this.saveQuestionnaireAnswers}
        handleUpdateAnswer={this.handleUpdateAnswer}
        questionnaire={questionnaire}
      />
    );
  }

  render() {
    return (
      <>
        <PrimaryFlatList
          emptyListTextKey="candidateMyGigs.notShortlisted"
          data={this.props.shortlisted}
          keyExtractor={(item) => item.gigId}
          renderItem={({ item: matchedGig }) => (
            <ListItem
              renderContent={() => (
                <GigListItem
                  gig={matchedGig}
                  online={this.props.usersPresence[matchedGig.createdBy.userId]}
                  internalFunctions={{
                    approveShortlisting: () => this.openQuestionnaireModal(matchedGig.gigId, matchedGig.questionnaire),
                  }}
                />
              )}
            />
          )}
        />
        {this.renderQuestionnaireModal()}
      </>
    );
  }
}

CandidateMyGigsShortlistedView.propTypes = {
  getCandidateGigs: PropTypes.func.isRequired,
  clearCandidateGigs: PropTypes.func.isRequired,
  removeNewContentIndicator: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  shortlisted: PropTypes.arrayOf(matchedGigForCandidatePropType),
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  dispatchAction: PropTypes.func.isRequired,
};

CandidateMyGigsShortlistedView.defaultProps = {
  shortlisted: null,
};

const mapStateToProps = (state) => ({
  shortlisted: state.candidateMyGigs.get(SHORTLISTED_LIST_KEY) ? state.candidateMyGigs.get(SHORTLISTED_LIST_KEY).toJS() : null,
  newContentIndicators: state.candidateMyGigs.get('newContentIndicators'),
  usersPresence: state.chat.get('usersPresence').toJS(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchAction: (action) => dispatch(action),
    ...bindActionCreators({ getCandidateGigs, clearCandidateGigs, removeNewContentIndicator }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateMyGigsShortlistedView);
