import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-native';
import * as propTypes from '../../../constants/propTypes';
import { getResizedImageUriFromStyle } from './imageHelper';

const WebImageWithFallback = ({ uri, fallbackImage, style }) => (
  uri
    ? <Image source={{ uri: getResizedImageUriFromStyle(uri, style) }} style={style} fadeDuration={0} />
    : <Image source={fallbackImage} style={style} fadeDuration={0} />
);

WebImageWithFallback.propTypes = {
  uri: PropTypes.string,
  fallbackImage: propTypes.imagePropType,
  style: propTypes.stylePropTypes.isRequired,
};

WebImageWithFallback.defaultProps = {
  uri: undefined,
  fallbackImage: undefined,
};

export default WebImageWithFallback;
