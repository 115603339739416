import englishTranslations from '@cospired/i18n-iso-languages/langs/en.json';
import swedishTranslations from '@cospired/i18n-iso-languages/langs/sv.json';
import { registerLocale, getNames } from '@cospired/i18n-iso-languages';

const popularLanguagesCodes = ['sv', 'en', 'de', 'fr', 'es', 'dk', 'fi', 'it', 'ar', 'pl'];
let worldLanguagesList;

function byLanguageName(l1, l2) {
  return l1[1].localeCompare(l2[1]);
}

function sortAndMovePopularToFront(langArray) {
  langArray.sort(byLanguageName);
  popularLanguagesCodes.slice().reverse().forEach((p) => {
    const foundLangIndex = langArray.findIndex((l) => l[0] === p);
    langArray.splice(0, 0, langArray[foundLangIndex]);
    langArray.splice(foundLangIndex + 1, 1);
  });
  return langArray.map((l) => ({ code: l[0], name: l[1] }));
}

function getWorldLanguagesList() {
  registerLocale(englishTranslations);
  registerLocale(swedishTranslations);
  const englishArray = Object.entries(getNames('en'));
  const swedishArray = Object.entries(getNames('sv'));
  return {
    en: sortAndMovePopularToFront(englishArray),
    sv: sortAndMovePopularToFront(swedishArray),
  };
}

export default function getWorldLanguagesListFor(lang) {
  if (worldLanguagesList) {
    return worldLanguagesList[lang];
  }
  worldLanguagesList = getWorldLanguagesList();

  return worldLanguagesList[lang];
}

export function getLanguageNameFor(lang, code) {
  const foundLanguage = getWorldLanguagesListFor(code)
    .find((l) => l.code === lang);
  return (foundLanguage && foundLanguage.name) || null;
}
