import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  View, StyleSheet, PixelRatio, ScrollView, Platform,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../../core/routing/index';
import TabBarItem from './TabBarItem';
import { contentWidth, webContentPercentWidth, wp } from '../../styles/base.style';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  tabBar: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
});

const contentWidthCalculated = Platform.OS === 'web' ? wp(webContentPercentWidth) : contentWidth;

class TabBar extends Component {
  static calculateWidthOfSingleTab(tabsCount) {
    const NAV_ITEM_WIDTH = 120;
    const HALF_TAB_RATIO = 0.5;

    const fitCount = contentWidthCalculated / NAV_ITEM_WIDTH;
    const isSpaceForAllTabs = fitCount >= tabsCount;
    const fitCountFull = Math.floor(fitCount);
    if (isSpaceForAllTabs) {
      return { scrollView: false, tabWidth: PixelRatio.roundToNearestPixel(contentWidthCalculated / tabsCount) };
    } if ((fitCount) - fitCountFull >= HALF_TAB_RATIO) {
      return { scrollView: true, tabWidth: PixelRatio.roundToNearestPixel((contentWidthCalculated / (fitCountFull + HALF_TAB_RATIO))) };
    }

    return { scrollView: true, tabWidth: PixelRatio.roundToNearestPixel((contentWidthCalculated / (fitCountFull - HALF_TAB_RATIO))) };
  }

  constructor(props) {
    super(props);
    this.navItemProps = TabBar.calculateWidthOfSingleTab(props.items.length);
  }

  componentDidUpdate() {
    this.navItemProps = TabBar.calculateWidthOfSingleTab(this.props.items.length);
    if (this.props.scrollToTabIndex > 0 && this.navItemProps.scrollView) {
      this.scrollTo(this.props.scrollToTabIndex);
    }
  }

  scrollTo(tabIndex) {
    const { tabWidth } = this.navItemProps;
    this.scrollview.scrollTo({ x: (tabWidth * (tabIndex - 1)) - ((contentWidthCalculated - tabWidth) / 2), y: 0, animated: true });
  }

  addScrollView(tabBar, scrollToTabIndex) {
    return (
      <ScrollView
        horizontal
        bounces
        showsHorizontalScrollIndicator={false}
        ref={(ref) => { this.scrollview = ref; }}
        onContentSizeChange={scrollToTabIndex > 0 ? () => {
          this.scrollTo(scrollToTabIndex);
        } : () => {}}
      >
        {tabBar}
      </ScrollView>
    );
  }

  render() {
    const {
      items, translate, location, history, onTabPressCallback, scrollToTabIndex,
    } = this.props;

    const tabBar = (
      <View style={styles.tabBar}>
        {
          items.map((item, index) => (
            <TabBarItem
              isLastInTabBar={(items.length - 1) === index}
              width={this.navItemProps.tabWidth}
              scrollView={this.navItemProps.scrollView}
              key={item.link}
              text={translate(item.textKey)}
              active={item.active || location.pathname.startsWith(item.link)}
              onPress={item.onPress ? () => {
                item.onPress();
                if (onTabPressCallback) {
                  onTabPressCallback();
                }
                if (item.onPressCallback) {
                  item.onPressCallback();
                }
              } : () => {
                history.replace(`${item.link}?${queryString.stringify({ tabIndex: item.tabIndex })}`);
                if (onTabPressCallback) {
                  onTabPressCallback();
                }
                if (item.onPressCallback) {
                  item.onPressCallback();
                }
              }}
              showCounter={item.showCounter}
              counterValue={item.counterValue}
              counterHighlighted={!!(item.active || location.pathname.startsWith(item.link))}
              isNewContent={item.isNewContent}
            />
          ))
        }
      </View>
    );

    return this.navItemProps.scrollView ? this.addScrollView(tabBar, scrollToTabIndex) : tabBar;
  }
}

TabBar.propTypes = {
  translate: PropTypes.func.isRequired,
  onTabPressCallback: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string.isRequired,
    textKey: PropTypes.string.isRequired,
  })).isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  scrollToTabIndex: PropTypes.number,
};

TabBar.defaultProps = {
  onTabPressCallback: undefined,
  scrollToTabIndex: -1,
};
export default withRouter(withLocalize(TabBar));
