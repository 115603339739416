import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import CompanyChatGigCandidatesScreen from './CompanyChatGigCandidatesScreen';
import { COMPANY_GIG_CHAT } from '../../constants/routes';
import { candidateDetailChatPropType, chatGigItemPropType } from '../../constants/propTypes';
import { getCompanyGigChatsInfo } from './companyChatActions';

class CompanyChatGigCandidatesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, refreshing: false };
  }

  componentDidMount() {
    if (this.props.companyGigChats.length === 0) {
      this.getGigChatsInfo();
    } else {
      this.setState({ refreshing: false });
    }
  }

  onRefresh = () => {
    this.setState({ refreshing: true });
    this.getGigChatsInfo();
  };

  onPress = (gigId, chatId, candidateId, position, name) => {
    this.props.history.push(COMPANY_GIG_CHAT.replace(':gigId', gigId).replace(':candidateId', candidateId), {
      chatId, position, name,
    });
  };

  getGigChatsInfo = () => {
    this.props.getCompanyGigChatsInfo(this.props.gigId)
      .then((response) => {
        this.setState({ refreshing: false, error: response.isError === true });
      });
  };

  render() {
    const { gig, companyGigChats, grantedFeatures } = this.props;
    if (!gig) {
      return null;
    }
    return (
      <CompanyChatGigCandidatesScreen
        gig={gig}
        companyGigChats={companyGigChats}
        usersPresence={this.props.usersPresence}
        onPress={this.onPress}
        refreshing={this.state.refreshing}
        onRefresh={this.onRefresh}
        error={this.state.error}
        grantedFeatures={grantedFeatures}
      />
    );
  }
}

CompanyChatGigCandidatesContainer.propTypes = {
  getCompanyGigChatsInfo: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  match: ReactRouterPropTypes.match.isRequired,
  gigId: PropTypes.string.isRequired,
  gig: chatGigItemPropType,
  companyGigChats: PropTypes.arrayOf(candidateDetailChatPropType),
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CompanyChatGigCandidatesContainer.defaultProps = {
  gig: undefined,
  companyGigChats: [],
};

const mapStateToProps = (state, props) => {
  const gigId = props.match.params.id;
  const gig = state.chat.get('companyGigs').find((g) => g.get('gigId') === gigId);
  const gigChats = state.chat.get('companyGigsChats').get(gigId);

  return {
    gigId,
    gig: gig ? gig.toJS() : undefined,
    companyGigChats: gigChats ? gigChats.toJS() : [],
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
    usersPresence: state.chat.get('usersPresence').toJS(),
  };
};

export default connect(mapStateToProps, { getCompanyGigChatsInfo })(CompanyChatGigCandidatesContainer);
