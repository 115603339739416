import { fromJS, Map } from 'immutable';
import {
  CANDIDATE_ACCEPT_OFFER_REQUEST_SUCCESS,
  CANDIDATE_APPROVE_SHORTLISTING_REQUEST_SUCCESS,
  CANDIDATE_ARCHIVE_GIG_REQUEST_SUCCESS,
  CANDIDATE_CLEAR_CANDIDATE_GIGS,
  CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST_SUCCESS,
  CANDIDATE_GET_CANDIDATE_GIGS_REQUEST_SUCCESS,
  CANDIDATE_REJECT_REQUEST_SUCCESS,
  CANDIDATE_ADD_NEW_CONTENT_INDICATOR,
  CANDIDATE_REMOVE_NEW_CONTENT_INDICATOR,
} from '../../../constants/actionTypes';
import { GIG_CANDIDATE_STATUS } from '../../../constants/matchingStatus';
import { MY_GIGS_CONTEXT } from '../../../constants/actionContext';
import { context } from '../../../common/gig/gigReducerHelper';
import { CLOSED } from '../../../constants/gigStatus';

export const APPLIED_LIST_KEY = 'applied';
export const SHORTLISTED_LIST_KEY = 'shortlisted';
export const MATCHED_LIST_KEY = 'matched';
export const OFFERS_LIST_KEY = 'offers';
export const HIRED_LIST_KEY = 'hired';
export const HISTORY_LIST_KEY = 'history';

export const candidateGetListKeyForStatus = (status) => {
  switch (status) {
    case GIG_CANDIDATE_STATUS.APPLIED:
      return APPLIED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL:
      return SHORTLISTED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.SHORTLISTED:
      return MATCHED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER:
    case GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED:
      return OFFERS_LIST_KEY;
    case GIG_CANDIDATE_STATUS.HIRED:
      return HIRED_LIST_KEY;
    case GIG_CANDIDATE_STATUS.REJECTED_BY_CANDIDATE:
    case GIG_CANDIDATE_STATUS.REJECTED_BY_COMPANY:
      return HISTORY_LIST_KEY;
    default:
      return undefined;
  }
};

export const candidateGetPreviousGigStatuses = (status) => {
  switch (status) {
    case GIG_CANDIDATE_STATUS.PENDING_SHORTLIST_APPROVAL:
      return [GIG_CANDIDATE_STATUS.SUGGESTED];
    case GIG_CANDIDATE_STATUS.SHORTLISTED:
      return [GIG_CANDIDATE_STATUS.APPLIED];
    case GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER:
      return [GIG_CANDIDATE_STATUS.SHORTLISTED];
    case GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED:
      return [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER];
    case GIG_CANDIDATE_STATUS.HIRED:
      return [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER, GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED];
    default:
      return undefined;
  }
};

export const CANDIDATE_MY_GIG_INITIAL_STATE = {
  count: null,
  newContentIndicators: [],
  [SHORTLISTED_LIST_KEY]: null,
  [HISTORY_LIST_KEY]: null,
  [APPLIED_LIST_KEY]: null,
  [OFFERS_LIST_KEY]: null,
  [MATCHED_LIST_KEY]: null,
  [HIRED_LIST_KEY]: null,
};

function sortByLastTransitionDateComparator(a, b) {
  return b.get('matching').get('lastTransitionDate') - a.get('matching').get('lastTransitionDate');
}

function sortByGigIdDescComparator(a, b) {
  return b.get('gigId') - a.get('gigId');
}

function offersGigComparator(a, b) {
  const aMatching = a.get('matching');
  const bMatching = b.get('matching');
  const aStatus = aMatching.get('status');
  const bStatus = bMatching.get('status');
  const matchingStatusesAreDifferent = aStatus !== bStatus;
  const aIsClosed = a.get('status') === CLOSED;
  const bIsClosed = b.get('status') === CLOSED;
  const gigStatusesAreDifferent = aIsClosed !== bIsClosed;
  const lastTransitionDatesComparison = sortByLastTransitionDateComparator(a, b);
  const lastTransitionDatesAreDifferent = lastTransitionDatesComparison !== 0;

  if (matchingStatusesAreDifferent) {
    if (aStatus === GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER) {
      return -1;
    } if (bStatus === GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER) {
      return 1;
    }
  }
  if (lastTransitionDatesAreDifferent) {
    return lastTransitionDatesComparison;
  }
  if (gigStatusesAreDifferent) {
    if (aIsClosed) {
      return 1;
    } if (bIsClosed) {
      return -1;
    }
  }
  return sortByGigIdDescComparator(a, b);
}

const listToComparatorMap = {
  [APPLIED_LIST_KEY]: sortByLastTransitionDateComparator,
  [SHORTLISTED_LIST_KEY]: sortByLastTransitionDateComparator,
  [MATCHED_LIST_KEY]: sortByLastTransitionDateComparator,
  [OFFERS_LIST_KEY]: offersGigComparator,
  [HIRED_LIST_KEY]: sortByLastTransitionDateComparator,
  [HISTORY_LIST_KEY]: sortByLastTransitionDateComparator,
};

function removeGigFromList(state, listKey, gigId) {
  return state.set(listKey, state.get(listKey).filterNot((matchedGig) => matchedGig.get('gigId') === gigId));
}

export default function candidateMyGigsReducer(state = new Map(CANDIDATE_MY_GIG_INITIAL_STATE), action) {
  if (!context(action, MY_GIGS_CONTEXT)) {
    return state;
  }

  switch (action.type) {
    case CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST_SUCCESS:
      return state.set('count', fromJS(action.payload));
    case CANDIDATE_GET_CANDIDATE_GIGS_REQUEST_SUCCESS:
      return state.set(action.meta.previousAction.listKey, fromJS(action.payload).sort(listToComparatorMap[action.meta.previousAction.listKey]));
    case CANDIDATE_CLEAR_CANDIDATE_GIGS:
      return state.set(action.listKey, null);
    case CANDIDATE_APPROVE_SHORTLISTING_REQUEST_SUCCESS: {
      return removeGigFromList(state, SHORTLISTED_LIST_KEY, action.meta.previousAction.gigId);
    }
    case CANDIDATE_ACCEPT_OFFER_REQUEST_SUCCESS: {
      const offersList = state.get(OFFERS_LIST_KEY);

      return state.set(
        OFFERS_LIST_KEY,
        offersList.set(
          offersList.findIndex((matchedGig) => matchedGig.get('gigId') === action.meta.previousAction.gigId),
          fromJS(action.payload),
        )
          .sort(offersGigComparator),
      );
    }
    case CANDIDATE_REJECT_REQUEST_SUCCESS:
      return removeGigFromList(state, action.meta.previousAction.listKey, action.meta.previousAction.gigId);
    case CANDIDATE_ARCHIVE_GIG_REQUEST_SUCCESS:
      return removeGigFromList(state, action.meta.previousAction.listKey, action.meta.previousAction.gigId);
    case CANDIDATE_ADD_NEW_CONTENT_INDICATOR: {
      if (state.get('newContentIndicators').includes(action.item)) {
        return state;
      }
      return state.set('newContentIndicators', [...state.get('newContentIndicators'), action.item]);
    }
    case CANDIDATE_REMOVE_NEW_CONTENT_INDICATOR: {
      return state.set('newContentIndicators', state.get('newContentIndicators').filter((item) => item !== action.item));
    }
    default:
      return state;
  }
}
