import React, { PureComponent } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import PropTypes from 'prop-types';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../styles/base.style';
import * as propTypes from '../../../../constants/propTypes';
import CheckboxImageChecked from './CheckboxImageChecked';
import CheckboxImageUnchecked from './CheckboxImageUnchecked';
import { checkboxIconSize } from './checkboxIcon.style';
import { font } from '../../../styles/mixins';

const uncheckedIcon = require('../../../../assets/icons/checkbox/modernOff.png');
const checkedIcon = require('../../../../assets/icons/checkbox/on.png');

const checkBoxStyle = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  contentView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.md, 0, colors.greyishBrown),
  },
  iconWrapper: {
    width: checkboxIconSize,
    height: checkboxIconSize,
  },
  disabled: {
    color: colors.lightPeach,
  },
});

const checkBoxStyleLeft = StyleSheet.create({
  iconWrapper: {
    marginRight: spacings.sm,
  },
});

const checkBoxStyleRight = StyleSheet.create({
  iconWrapper: {
    marginLeft: spacings.sm,
  },
});

class CheckBoxBolek extends PureComponent {
  getHitSlop = () => (this.isIconLeft() ? {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  } : null);

  isIconLeft = () => this.props.iconPosition === 'left';

  isIconRight = () => this.props.iconPosition === 'right';

  renderIcon = () => (
    <View style={[checkBoxStyle.iconWrapper, this.isIconLeft() ? checkBoxStyleLeft.iconWrapper : checkBoxStyleRight.iconWrapper]}>
      <CheckboxImageUnchecked show={!this.props.value} customIcon={uncheckedIcon} />
      <CheckboxImageChecked show={this.props.value} customIcon={checkedIcon} />
    </View>
  );

  renderLabel = () => (
    <Text style={[checkBoxStyle.label, (this.props.disabled ? checkBoxStyle.disabled : undefined)]}>
      {this.props.label}
    </Text>
  );

  render() {
    const {
      onPress, disabled, style,
    } = this.props;

    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={[
          checkBoxStyle.wrapper,
          this.isIconLeft() ? checkBoxStyleLeft.wrapper : checkBoxStyleRight.wrapper,
          style,
        ]}
        onPress={() => { if (!disabled && onPress) onPress(); }}
        hitSlop={this.getHitSlop()}
        disabled={disabled}
      >
        <View style={checkBoxStyle.contentView}>
          {this.isIconLeft() && this.renderIcon()}
          {this.renderLabel()}
          {this.isIconRight() && this.renderIcon()}
        </View>
      </TouchableOpacity>
    );
  }
}

CheckBoxBolek.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  style: propTypes.stylePropTypes,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

CheckBoxBolek.defaultProps = {
  style: undefined,
  disabled: false,
  iconPosition: 'left',
  onPress: null,
};

export default CheckBoxBolek;
