import React, { PureComponent } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import PropTypes from 'prop-types';
import {
  colors, fontFamily, fontSizes, spacings, standardColors,
} from '../../../styles/base.style';
import CheckboxImageChecked from './CheckboxImageChecked';
import CheckboxImageUnchecked from './CheckboxImageUnchecked';
import { checkboxIconSize } from './checkboxIcon.style';
import { imagePropType, stylePropTypes } from '../../../../constants/propTypes';

const checkBoxStyle = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  contentView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flex: 1,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    fontSize: fontSizes.xl,
    color: colors.greyishBrown,
    paddingTop: 1, // just looks better
  },
  iconWrapper: {
    marginLeft: spacings.sm,
    width: checkboxIconSize,
    height: checkboxIconSize,
  },
  disabled: {
    color: colors.lightPeach,
  },
});

const checkBoxLeftMarginBottom = spacings.sm;
const checkBoxLeftMarginLeft = spacings.md;
const checkBoxStyleLeft = StyleSheet.create({
  wrapper: {
    marginLeft: checkBoxLeftMarginLeft,
    marginBottom: checkBoxLeftMarginBottom,
  },
  iconWrapper: {
    marginRight: spacings.sm,
  },
});

const checkBoxStyleRight = StyleSheet.create({
  wrapper: {
    paddingVertical: spacings.sm,
    borderBottomWidth: 1,
    borderBottomColor: standardColors.border,
  },
  iconWrapper: {
    marginLeft: spacings.sm,
  },
});

class CheckBox extends PureComponent {
  getHitSlop = () => (this.isIconLeft() ? {
    top: checkBoxLeftMarginBottom / 2,
    bottom: checkBoxLeftMarginBottom / 2,
    left: checkBoxLeftMarginLeft / 2,
    right: 0,
  }
    // right version has paddings set
    : null);

  isIconLeft = () => this.props.iconPosition === 'left';

  isIconRight = () => this.props.iconPosition === 'right';

  renderIcon = () => (
    <View style={[checkBoxStyle.iconWrapper, this.isIconLeft() ? checkBoxStyleLeft.iconWrapper : checkBoxStyleRight.iconWrapper]}>
      <CheckboxImageUnchecked show={!this.props.value} customIcon={this.props.uncheckedIcon} />
      <CheckboxImageChecked show={this.props.value} customIcon={this.props.checkedIcon} />
    </View>
  );

  renderLabel = () => {
    const { label, disabled } = this.props;
    return (<Text style={[checkBoxStyle.label, (disabled ? checkBoxStyle.disabled : undefined)]}>{label}</Text>);
  };

  render() {
    const {
      onPress, disabled, style,
    } = this.props;

    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={[
          checkBoxStyle.wrapper,
          this.isIconLeft() ? checkBoxStyleLeft.wrapper : checkBoxStyleRight.wrapper,
          style,
        ]}
        onPress={() => { if (!disabled) onPress(); }}
        hitSlop={this.getHitSlop()}
        disabled={disabled}
      >
        <View style={checkBoxStyle.contentView}>
          {this.isIconLeft() && this.renderIcon()}
          {this.renderLabel()}
          {this.isIconRight() && this.renderIcon()}
        </View>
      </TouchableOpacity>
    );
  }
}

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  style: stylePropTypes,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  uncheckedIcon: imagePropType,
  checkedIcon: imagePropType,
};

CheckBox.defaultProps = {
  style: undefined,
  disabled: false,
  iconPosition: 'left',
  uncheckedIcon: undefined,
  checkedIcon: undefined,
};

export default CheckBox;
