import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorStepInfoScreen from './GigCreatorStepInfoScreen';
import { gigCreatorAddExpiryDate, gigCreatorAddName } from '../../gigCreatorActions';
import Routing from '../../../../core/routing/index';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepInfoContainer extends React.Component {
  constructor(props) {
    super(props);

    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.INFO));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.INFO);
  }

  onSubmit = (values, { setStatus, setSubmitting }) => {
    this.props.gigCreatorAddName(values.name);
    this.props.gigCreatorAddExpiryDate(values.expiryDate);

    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.INFO, values)
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      setStatus({ redirect: true });
    }
  };

  render() {
    const { name, expiryDate, isEdit } = this.props;
    const initialValues = { name, expiryDate };

    return (
      <GigCreatorStepInfoScreen
        stepNumber={this.stepNumber}
        nextScreen={this.nextScreen}
        maxStepsNumber={this.maxStepsNumber}
        isEdit={isEdit}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
      />
    );
  }
}

GigCreatorStepInfoContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  gigCreatorAddName: PropTypes.func.isRequired,
  gigCreatorAddExpiryDate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  expiryDate: PropTypes.number,
  ...withGigEditPropTypes,
};
GigCreatorStepInfoContainer.defaultProps = {
  expiryDate: null,
};

const mapStateToProps = (state) => {
  const {
    gig: {
      name,
      expiryDate,
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();

  return { name, expiryDate, grantedFeatures };
};

export default withRouter(withGigEdit(mapStateToProps, { gigCreatorAddName, gigCreatorAddExpiryDate })(GigCreatorStepInfoContainer));
