import { combineReducers } from 'redux-immutable';
import companyStatusReducer from './status/companyStatusReducer';
import companyProfileReducer from './profile/companyProfileReducer';
import companyPaymentsReducer from './payments/companyPaymentsReducer';

export default combineReducers({
  status: companyStatusReducer,
  profile: companyProfileReducer,
  payments: companyPaymentsReducer,
});
