import randomatic from 'randomatic';
import {
  NOTIFICATIONS_ADD, NOTIFICATIONS_MINIMIZE, NOTIFICATIONS_REMOVE, NOTIFICATIONS_REVIVE,
} from '../../constants/actionTypes';
import notificationsTypes from '../../constants/notificationsTypes';

export const NO_INTERNET_NOTIFICATION_ID = 'no_internet';
export const NO_CHAT_NOTIFICATION_ID = 'no_chat';
export const CONNECTION_ERROR_NOTIFICATION_ID = 'connection_error';
export const SERVER_ERROR_NOTIFICATION_ID = 'server_error';

export const getKey = (key) => `notifications.${key}`;

export const removeNotification = (notificationId) => ({
  type: NOTIFICATIONS_REMOVE,
  notificationId,
});

export const minimizeNotification = (notificationId) => ({
  type: NOTIFICATIONS_MINIMIZE,
  notificationId,
});

export const reviveNotification = (notificationId) => ({
  type: NOTIFICATIONS_REVIVE,
  notificationId,
});

export const addNotification = (config) => {
  const {
    id = `${new Date().getTime()}-${randomatic('aA0', 5)}`,
    type,
    title,
    titleKey,
    text,
    textKey,
    permanent = false,
    debounce, // will not show another notification with this id for x millis after hiding
    shouldDisappear = false,
  } = config;

  if (!type) {
    console.error('type is required for notification');
    return {};
  }

  if (!titleKey && !title) {
    console.error('titleKey or title is required for notification');
    return {};
  }

  return ({
    type: NOTIFICATIONS_ADD,
    notification: {
      id,
      type,
      titleKey,
      title,
      textKey,
      text,
      permanent,
      debounce,
      shouldDisappear,
      minimized: false,
    },
  });
};

export const addNoInternetNotification = () => addNotification({
  id: NO_INTERNET_NOTIFICATION_ID,
  type: notificationsTypes.ERROR,
  titleKey: getKey('noInternetTitle'),
  textKey: getKey('noInternetText'),
  permanent: true,
});

export const removeNoInternetNotification = () => removeNotification(NO_INTERNET_NOTIFICATION_ID);

export const addNoChatNotification = () => addNotification({
  id: NO_CHAT_NOTIFICATION_ID,
  type: notificationsTypes.ERROR,
  titleKey: getKey('noChatTitle'),
  textKey: getKey('noChatText'),
  permanent: true,
  debounce: 15 * 1000, // 15 seconds
});

export const removeNoChatNotification = () => removeNotification(NO_CHAT_NOTIFICATION_ID);

export const connectionErrorNotification = () => addNotification({
  id: CONNECTION_ERROR_NOTIFICATION_ID,
  type: notificationsTypes.ERROR,
  titleKey: getKey('connectionErrorTitle'),
  textKey: getKey('connectionErrorText'),
});

export const serverErrorNotification = () => addNotification({
  id: SERVER_ERROR_NOTIFICATION_ID,
  type: notificationsTypes.ERROR,
  titleKey: getKey('connectionErrorTitle'),
  textKey: getKey('connectionErrorText'),
});

export const errorNotification = (titleKey, textKey) => addNotification({
  type: notificationsTypes.ERROR, titleKey, textKey,
});

export const infoNotification = (titleKey, textKey) => addNotification({
  type: notificationsTypes.INFO, titleKey, textKey,
});

export const successNotification = (titleKey, textKey, keepVisible) => addNotification({
  type: notificationsTypes.SUCCESS,
  titleKey,
  textKey,
  shouldDisappear: !keepVisible,
});

export const onDemandNotification = (type, title, text) => addNotification({
  type,
  title,
  text,
  shouldDisappear: false,
  permanent: true,
});
