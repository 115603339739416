import {
  Animated, Image, StyleSheet, Text, TouchableWithoutFeedback, View, Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { fontFamily, colors, spacings } from '../../styles/base.style';

const ICON_WIDTH = Platform.OS === 'web' ? 45 : 35;
const ICON_HEIGHT = Platform.OS === 'web' ? 36 : 28;
const MENU_SPACING = Platform.OS === 'web' ? 15 : 12;

const styles = StyleSheet.create({
  navItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: MENU_SPACING,
    paddingHorizontal: MENU_SPACING,
    ...Platform.select({
      web: {
        paddingVertical: MENU_SPACING,
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
    }),
  },
  text: {
    paddingTop: 2,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    // menu may be a bit special after all
    fontSize: 11,
    color: '#919191',
    letterSpacing: 0.3,
    ...Platform.select({
      web: {
        fontSize: 16,
        letterSpacing: 0.7,
        paddingTop: 0,
      },
    }),
  },
  activeText: {
    color: colors.magenta,
  },
  imageWrapper: {
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      web: {
        marginRight: spacings.xs,
      },
    }),
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    resizeMode: 'contain',
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
  },
});

class NavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAnimation: new Animated.Value(this.isActive() ? 1 : 0),
    };
  }

  shouldComponentUpdate(nextProps) {
    return (this.isActive(this.props.location.pathname) !== this.isActive(nextProps.location.pathname)
    || this.props.renderExtrasData !== nextProps.renderExtrasData);
  }

  componentDidUpdate(prevProps) {
    const prevPathname = prevProps.location.pathname;
    const currentPathname = this.props.location.pathname;
    if (prevPathname !== currentPathname) {
      if (!this.isActive(prevPathname) && this.isActive(currentPathname)) {
        this.animate(1);
      } else if (this.isActive(prevPathname) && !this.isActive(currentPathname)) {
        this.animate(0);
      }
    }
  }

  isActive = (pathname = this.props.location.pathname) => pathname.startsWith(this.props.link);

  animate(toValue) {
    Animated.timing(this.state.activeAnimation, {
      toValue,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }

  render() {
    const {
      text, link, iconOn, iconOff, history, renderExtras, onPressCallback,
    } = this.props;

    const animationStyles = {
      opacity: this.state.activeAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      }),
    };

    const active = this.isActive();
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          history.push(link);
          onPressCallback();
        }}
      >
        <View style={styles.navItem}>
          <View style={styles.imageWrapper}>
            <Image
              style={styles.image}
              source={iconOff}
              fadeDuration={0}
            />
            <Animated.Image
              style={[styles.image, animationStyles]}
              source={iconOn}
              fadeDuration={0}
            />
          </View>
          <Text style={[styles.text, active && styles.activeText]}>{text}</Text>
          {renderExtras && renderExtras()}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

NavItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  iconOn: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  iconOff: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  renderExtras: PropTypes.func,
  // we need a simple prop which we can check in shouldComponentUpdate
  // eslint-disable-next-line react/forbid-prop-types
  renderExtrasData: PropTypes.any,
  onPressCallback: PropTypes.func,
};

NavItem.defaultProps = {
  location: null,
  history: null,
  renderExtras: undefined,
  renderExtrasData: undefined,
  onPressCallback: () => {},
};

export default NavItem;
