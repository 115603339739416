import React from 'react';
import PropTypes from 'prop-types';
import FormikFieldPrimaryTextInput from './FormikFieldPrimaryTextInput';
import { innerRefPropType } from '../../../constants/propTypes';

const FormikFieldPrimaryInputTextNextSupport = ({ returnKeyType, nextComponent, ...props }) => (
  <FormikFieldPrimaryTextInput
    returnKeyType={returnKeyType}
    onSubmitEditing={() => { if (nextComponent) nextComponent.focus(); }}
    blurOnSubmit={false}
    {...props}
  />
);

FormikFieldPrimaryInputTextNextSupport.propTypes = {
  returnKeyType: PropTypes.string,
  nextComponent: innerRefPropType,
};

FormikFieldPrimaryInputTextNextSupport.defaultProps = {
  returnKeyType: 'next',
  nextComponent: undefined,
};

export default FormikFieldPrimaryInputTextNextSupport;
