import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Routing from '../../../core/routing/index';
import AuthorizedRoute from '../../../common/components/navigation/AuthorizedRoute';

import { CANDIDATE_USER } from '../../../constants/userScopes';
import {
  CANDIDATE_FIND_GIGS, CANDIDATE_FIND_GIGS_SINGLE, CANDIDATE_FIND_GIGS_LIST,
} from '../../../constants/routes';
import CandidateFindGigsListContainer from './CandidateFindGigsListContainer';
import CandidateFindGigsSingleContainer from './CandidateFindGigsSingleContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN_GIGS_LIST = CANDIDATE_FIND_GIGS_LIST;
const FIRST_SCREEN_SINGLE_GIG = CANDIDATE_FIND_GIGS_SINGLE;

class CandidateFindGigsRouting extends React.Component {
  isFavouriteViewApplicable() {
    const { grantedFeatures } = this.props;
    return grantedFeatures.includes('SHOW_FAVOURITE_GIGS');
  }

  render() {
    return (
      <>
        <Route
          exact
          path={CANDIDATE_FIND_GIGS}
          render={() => (
            <Redirect to={this.isFavouriteViewApplicable() ? FIRST_SCREEN_SINGLE_GIG : FIRST_SCREEN_GIGS_LIST} />
          )}
        />
        <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_FIND_GIGS_LIST} component={CandidateFindGigsListContainer} />
        <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_FIND_GIGS_SINGLE} component={CandidateFindGigsSingleContainer} />
      </>
    );
  }
}

CandidateFindGigsRouting.propTypes = {
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
});

export default withRouter(connect(mapStateToProps, null)(CandidateFindGigsRouting));
