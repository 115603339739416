import React, { Component } from 'react';
import { Platform, Linking, AppState } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import Constants from 'expo-constants';
import NavigationBar from '../../common/components/navigation/NavigationBar';
import NavItem from '../../common/components/navigation/NavItem';
import ChatNavItem from '../../common/components/navigation/ChatNavItem';
import NavItemWithIndicator from '../../common/components/navigation/NavItemWithIndicator';
import {
  COMPANY_CANDIDATES_POOL,
  COMPANY_CHAT_GIG_LIST,
  COMPANY_GIGS_LIST,
  COMPANY_POSTGIG,
  COMPANY_PROFILE,
} from '../../constants/routes';
import Routing from '../../core/routing/index';
import { COMPANY_POOLS_CONTENT_FIELDS } from '../candidatesPool/companyCandidatesPoolReducer';
import renderMenu from './appMenuConfig';
import { showAlertMessage } from '../../common/components/alert/alerts';
import { getCurrentVersion } from '../../common/version/VersionActions';

const { withRouter } = Routing;

const addIconOn = require('../../assets/icons/menu/iconPostGigOn.png');
const addIconOff = require('../../assets/icons/menu/iconPostGigOff.png');

const myGigsIconOn = require('../../assets/icons/menu/iconMyGigsOn.png');
const myGigsIconOff = require('../../assets/icons/menu/iconMyGigsOff.png');

const chatIconOn = require('../../assets/icons/menu/iconChatOffFullOn.png');
const chatIconOff = require('../../assets/icons/menu/iconChatOffFull.png');

const myPoolsIconOn = require('../../assets/icons/menu/iconMyPoolsOn.png');
const myPoolsIconOff = require('../../assets/icons/menu/iconMyPoolsOff.png');

const profileIconOn = require('../../assets/icons/menu/iconMyProfileOn.png');
const profileIconOff = require('../../assets/icons/menu/iconMyProfileOff.png');

class CompanyNavigatorBar extends Component {
  componentDidMount() {
    if (renderMenu(this.props.location.pathname)) {
      AppState.addEventListener('change', this.handleAppStateChange);
      // calls API function that checks version based on platform, if app is not updated, display alert message
      this.checkAppVersion();
    }
  }

  handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active') {
      // The app has resumed from the background
      this.checkAppVersion();
    }
  };

  checkAppVersion = () => {
    if (Platform.OS !== 'web') {
      this.props.getCurrentVersion().then((response) => {
        if (!response.isError) {
          if (Platform.OS === 'ios') {
            if (Constants.expoConfig.version < response.payload.ios) {
              showAlertMessage(
                this.props.translate,
                'generic.updateNow',
                'generic.iOSNewVersionMessage',
                'generic.newVersionAvailable',
                () => {
                  // redirects app store
                  this.openStoreForUpdate('ios');
                }
              );
            }
          } else if (Constants.expoConfig.version < response.payload.android) {
            showAlertMessage(
              this.props.translate,
              'generic.updateNow',
              'generic.androidNewVersionMessage',
              'generic.newVersionAvailable',
              () => {
                // redirects to google play store
                this.openStoreForUpdate('android');
              }
            );
          }
        }
      });
    }
  };

  openStoreForUpdate = (platform) => {
    let storeUrl = '';

    if (platform === 'ios') {
      storeUrl = 'https://apps.apple.com/app/id1448023337';
    } else if (platform === 'android') {
      storeUrl = 'https://play.google.com/store/apps/details?id=com.letsgig.prd&pli=1';
    }

    if (storeUrl) {
      Linking.openURL(storeUrl)
        .then(() => {
          console.log(`Opened ${platform === 'ios' ? 'App Store' : 'Play Store'}`);
        })
        .catch((err) => {
          console.error('Error opening store URL', err);
        });
    }
  };

  render() {
    const { translate, location, isNewContentInMyGigs, isNewContentInMyPools, grantedFeatures } = this.props;

    return (
      renderMenu(location.pathname) && (
        <NavigationBar>
          <NavItemWithIndicator
            showSthNewIndicator={isNewContentInMyGigs}
            iconOn={myGigsIconOn}
            iconOff={myGigsIconOff}
            text={translate('generic.myGigs')}
            link={COMPANY_GIGS_LIST}
          />
          <ChatNavItem
            iconOn={chatIconOn}
            iconOff={chatIconOff}
            text={translate('generic.chat')}
            link={COMPANY_CHAT_GIG_LIST}
          />
          <NavItem iconOn={addIconOn} iconOff={addIconOff} text={translate('generic.postGig')} link={COMPANY_POSTGIG} />
          {grantedFeatures.includes('CANDIDATES_POOL') && (
            <NavItemWithIndicator
              showSthNewIndicator={isNewContentInMyPools}
              iconOn={myPoolsIconOn}
              iconOff={myPoolsIconOff}
              text={translate('company.candidatesPool.menu')}
              link={COMPANY_CANDIDATES_POOL}
            />
          )}
          <NavItem
            iconOn={profileIconOn}
            iconOff={profileIconOff}
            text={translate('generic.myProfile')}
            link={COMPANY_PROFILE}
          />
        </NavigationBar>
      )
    );
  }
}

CompanyNavigatorBar.propTypes = {
  translate: PropTypes.func.isRequired,
  isNewContentInMyGigs: PropTypes.bool.isRequired,
  isNewContentInMyPools: PropTypes.bool.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  getCurrentVersion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const isNewPoolChat = Object.values(state.poolChat.get('poolChatsData').toJS()).find((item) => item.unread) != null;
  const myPoolsContentMap = state.companyCandidatesPool.get('newContent').toJS();
  const isNewCandidateInPool = myPoolsContentMap[COMPANY_POOLS_CONTENT_FIELDS.MY_POOL].length > 0
    || myPoolsContentMap[COMPANY_POOLS_CONTENT_FIELDS.GROUPS].length > 0;
  return {
    isNewContentInMyGigs: state.companyGig.get('gigsList').get('newContentIndicators').toJS().length > 0,
    isNewContentInMyPools: isNewPoolChat || isNewCandidateInPool,
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
  };
};

export default connect(mapStateToProps, { getCurrentVersion })(withRouter(withLocalize(CompanyNavigatorBar)));
