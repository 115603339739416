import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { CLOSED, OPEN } from './gigStatus';
import { PER } from './salaryTypes';
import { PROVIDER_ENUM } from './providerTypes';

export const stylePropTypes = PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]);

export const activeLanguagePropTypes = PropTypes.shape({
  code: PropTypes.string.isRequired,
});

export const imagePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({
    uri: PropTypes.string,
  }),
]);

export const translationsPropType = PropTypes.shape({
  en: PropTypes.string.isRequired,
  sv: PropTypes.string.isRequired,
});

export const innerRefPropType = PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]);

export const validationSchemaPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    field: PropTypes.string.isRequired,
    method: PropTypes.func, // either (validator.js) method or custom
    custom: PropTypes.func,
    validWhen: PropTypes.bool,
    message: PropTypes.string.isRequired,
  })
);

export const gigCategoryPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
});

export const educationCategoryPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
});

export const gigCityPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
});

export const languagePropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
});

export const questionnairePropType = PropTypes.shape({
  questionId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const filledOutQuestionnairePropType = PropTypes.shape({
  question: questionnairePropType,
  answer: PropTypes.shape({
    text: PropTypes.string,
  }),
});

export const gigItemPropType = {
  gigId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf([OPEN, CLOSED]).isRequired,
  expiryDate: PropTypes.number.isRequired,
  closeDate: PropTypes.number.isRequired,
  candidatesCount: PropTypes.shape({
    status: PropTypes.number,
  }),
  accessUntilDate: PropTypes.number,
  unlimitedAccess: PropTypes.bool,
};

export const candidateChatPropType = PropTypes.shape({
  chatId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  lastMessageDate: PropTypes.number.isRequired,
  unread: PropTypes.bool.isRequired,
});

export const candidateDetailChatPropType = PropTypes.shape({
  candidateId: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateProfileImageUri: PropTypes.string,
  lastMessageDate: PropTypes.number.isRequired,
  lastMessage: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  companyHasAccess: PropTypes.bool.isRequired,
});

export const chatGigItemPropType = PropTypes.shape({
  gigId: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  expiryDate: PropTypes.number.isRequired,
  candidateChats: PropTypes.arrayOf(candidateChatPropType).isRequired,
  lastMessageDate: PropTypes.number.isRequired,
  unread: PropTypes.bool.isRequired,
});

export const candidateChatGigItemPropType = PropTypes.shape({
  chatId: PropTypes.string.isRequired,
  gigId: PropTypes.string.isRequired,
  companyUserId: PropTypes.string.isRequired,
  gigPosition: PropTypes.string.isRequired,
  closeDate: PropTypes.number.isRequired,
  lastMessageDate: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  status: PropTypes.oneOf([OPEN, CLOSED]).isRequired,
});

const gigPaymentsPropType = PropTypes.shape({
  accessUntilDate: PropTypes.number,
  unlimitedAccess: PropTypes.bool,
});

export const gigLocationPropType = PropTypes.shape({
  city: PropTypes.string,
  cityV2: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
  street: PropTypes.string,
  place: PropTypes.string,
  info: PropTypes.string,
  geoLoc: PropTypes.shape({
    lon: PropTypes.number,
    lat: PropTypes.number,
  }),
});

export const matchingDataPropType = PropTypes.shape({
  status: PropTypes.string,
  source: PropTypes.string,
  employmentChannel: PropTypes.string,
  lastTransitionDate: PropTypes.number,
  isInFavourites: PropTypes.bool,
});

const gigPropTypeObj = {
  gigId: PropTypes.string,
  gigProvider: PropTypes.oneOf(Object.keys(PROVIDER_ENUM)),
  name: PropTypes.string,
  expiryDate: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.string,
  description: PropTypes.string,
  candidatesNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: gigLocationPropType,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  closeDate: PropTypes.number,
  noStartDate: PropTypes.bool,
  noEndDate: PropTypes.bool,
  status: PropTypes.string,
  remoteWorkAllowed: PropTypes.bool,
  experience: PropTypes.number,
  languages: PropTypes.arrayOf(languagePropType),
  compensation: PropTypes.shape({
    salary: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      per: PropTypes.oneOf(Object.keys(PER)),
    }),
    labourContract: PropTypes.bool,
    salaryByAgreement: PropTypes.bool,
    volunteering: PropTypes.bool,
    internshipTrainee: PropTypes.bool,
  }),
  workload: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    type: PropTypes.string,
  }),
  createdBy: PropTypes.shape({
    userId: PropTypes.string,
  }),
  payments: gigPaymentsPropType,
  careerUrl: PropTypes.string,
  questionnaire: PropTypes.arrayOf(filledOutQuestionnairePropType),
};

export const { workload } = gigPropTypeObj;

export const gigCompensation = gigPropTypeObj.compensation;

export const gigPropType = PropTypes.shape(gigPropTypeObj);

export const fingGigPropType = PropTypes.shape({
  gigId: PropTypes.string.isRequired,
  gigProvider: PropTypes.oneOf(Object.keys(PROVIDER_ENUM)),
  expiryDate: PropTypes.number,
  publishDate: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  photoUri: PropTypes.string,
  imageUrl: PropTypes.string,
  applyUrl: PropTypes.string,
  shortId: PropTypes.string,
  location: PropTypes.shape({
    city: PropTypes.string,
  }).isRequired,
});

export const matchedGigForCandidatePropType = PropTypes.shape({
  ...gigPropTypeObj,
  matching: matchingDataPropType,
});

export const candidateLocationPropType = PropTypes.shape({
  city: PropTypes.string,
  cityV2: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  geoLoc: PropTypes.object,
});

export const candidateHomeLocationPropType = PropTypes.shape({
  address: PropTypes.shape({
    suburb: PropTypes.string,
    neighbourhood: PropTypes.string,
    hamlet: PropTypes.string,
    village: PropTypes.string,
    town: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
  }),
  locationName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  geoLoc: PropTypes.object,
  key: PropTypes.string,
});

export const gigCandidatePropType = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  workload,
  shifts: PropTypes.arrayOf(PropTypes.number),
  location: candidateLocationPropType,
  profileImageUri: PropTypes.string,
  companyHasAccess: PropTypes.bool,
  matching: matchingDataPropType,
  poolCandidate: PropTypes.shape({
    poolCandidateId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
});

// TODO unify location shape
const candidateProfileAvailabilityPropTypeObj = {
  available: PropTypes.bool,
  hours: workload,
  times: PropTypes.array,
  location: candidateLocationPropType,
};
export const candidateProfileAvailabilityPropType = PropTypes.shape(candidateProfileAvailabilityPropTypeObj);

export const candidateExperiencePropType = PropTypes.shape({
  tmpExperienceId: PropTypes.string,
  experienceId: PropTypes.string,
  category: PropTypes.string,
  position: PropTypes.string,
  companyName: PropTypes.string,
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  ongoing: PropTypes.bool,
  description: PropTypes.string,
});

export const candidateEducationPropType = PropTypes.shape({
  tmpEducationId: PropTypes.string,
  educationId: PropTypes.string,
  course: PropTypes.string,
  school: PropTypes.string,
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  ongoing: PropTypes.bool,
});

export const msisdnPropType = PropTypes.shape({
  countryCode: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
});

export const candidateCategoryValidationParams = PropTypes.shape({
  min: PropTypes.number,
  max: PropTypes.number,
});

export const candidateProfilePropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  profileCompleteness: PropTypes.string,
  profileImageUri: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  ...candidateProfileAvailabilityPropTypeObj,
  experience: PropTypes.arrayOf(candidateExperiencePropType),
  education: PropTypes.arrayOf(candidateEducationPropType),
  aboutMe: PropTypes.string,
  msisdn: msisdnPropType,
});

export const companyProfilePropType = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyRegistrationNumber: PropTypes.string.isRequired,
  companyDescription: PropTypes.string,
  profileImageUri: PropTypes.string,
  msisdn: msisdnPropType,
});

export const withGigEditPropTypes = {
  isEdit: PropTypes.bool.isRequired,
  updateGig: PropTypes.func.isRequired,
};

export const withGigCandidateAccessHandlePropTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  handleBilling: PropTypes.func.isRequired,
};

export const withGigCandidateAccessOnHireHandlePropTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  handleBillingOnHire: PropTypes.func.isRequired,
};

export const companyBillingProfilePropTypes = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    postal_code: PropTypes.string,
    country: PropTypes.string,
  }),
  email: PropTypes.string,
  reference: PropTypes.string,
  vatNumber: PropTypes.string,
});

export const paymentsCardPropTypes = PropTypes.shape({
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  expMonth: PropTypes.number.isRequired,
  expYear: PropTypes.number.isRequired,
});

export const pricingPlanPropTypes = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  intervalCount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
});

export const subscriptionPropTypes = PropTypes.shape({
  status: PropTypes.string,
  billingType: PropTypes.string,
  trial: PropTypes.bool,
});

export const paymentsDataPropTypes = PropTypes.shape({
  subscription: PropTypes.shape({
    nextInvoiceDate: PropTypes.number,
    cancelPossibleFromDate: PropTypes.number,
    cancelAtPeriodEnd: PropTypes.bool,
    cancellationDate: PropTypes.number,
    status: PropTypes.string,
    plan: pricingPlanPropTypes,
  }),
  card: paymentsCardPropTypes,
  upcomingInvoice: PropTypes.shape({
    total: PropTypes.number,
    currency: PropTypes.string,
  }),
  creditsNumber: PropTypes.number.isRequired,
});

export const poolCandidatePropType = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
});

export const poolGroupPropType = PropTypes.shape({
  candidates: PropTypes.arrayOf(poolCandidatePropType),
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  access: PropTypes.string.isRequired,
  ownerUserId: PropTypes.string.isRequired,
});

export const shiftCandidatePropType = PropTypes.shape({
  userId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const shiftLocationPropType = PropTypes.shape({
  street: PropTypes.string,
  place: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  geoLoc: PropTypes.object,
  key: PropTypes.string,
});

export const shiftDateAndTimePropType = PropTypes.shape({
  startDate: PropTypes.number,
  endDate: PropTypes.number,
});

export const obPaymentPeriodPropType = PropTypes.shape({
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  salaryBonus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
});

export const obPaymentPropType = PropTypes.shape({
  applicable: PropTypes.bool,
  periods: PropTypes.arrayOf(obPaymentPeriodPropType),
});

export const shiftSalaryPropType = PropTypes.shape({
  salary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  holidayPay: PropTypes.bool,
  obPayment: obPaymentPropType,
});

export const shiftPropType = PropTypes.shape({
  gigId: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string,
  candidatesNumber: PropTypes.number,
  companyName: PropTypes.string,
  shiftCandidateStatus: PropTypes.string,
  bookedCandidatesNumber: PropTypes.number,
  shiftCandidates: PropTypes.arrayOf(shiftCandidatePropType),
  location: shiftLocationPropType,
  shiftDatesAndTimes: PropTypes.arrayOf(shiftDateAndTimePropType),
  shiftSalary: shiftSalaryPropType,
  company: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    poolProfileImageUri: imagePropType,
  }),
  companyUser: PropTypes.shape({
    profileImageUri: imagePropType,
  }),
});

export const shiftCreatorAccessEntitiesPropType = PropTypes.shape({
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.string.isRequired,
    })
  ),
});

export const shiftCreatorPropType = PropTypes.shape({
  position: PropTypes.string,
  description: PropTypes.string,
  candidatesNumber: PropTypes.number,
  accessEntities: shiftCreatorAccessEntitiesPropType,
});

export const poolProfilePropType = PropTypes.shape({
  description: PropTypes.string,
  contactInfo: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
});

export const adminFindGigsFilterPropType = PropTypes.shape({
  providerGigId: PropTypes.string,
  gigId: PropTypes.string,
  category: PropTypes.string,
  city: PropTypes.string,
  gigProvider: PropTypes.string,
  companyName: PropTypes.string,
  limit: PropTypes.string,
  page: PropTypes.string,
  minCandidatesNumber: PropTypes.string,
});

export const adminFindAssociationFilterPropType = PropTypes.shape({
  city: PropTypes.string,
  limit: PropTypes.string,
  page: PropTypes.string,
});

export const adminSettingsFreeGig = PropTypes.shape({
  gigLimit: PropTypes.string,
});

export const adminSettingsCity = PropTypes.shape({
  key: PropTypes.string,
  order: PropTypes.string,
  name: PropTypes.string,
  long: PropTypes.string,
  lat: PropTypes.string,
});

export const adminSettingCity = PropTypes.shape({
  isCityEnabled: PropTypes.bool,
});
