import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { COMPANY_USER } from '../../constants/userScopes';
import {
  COMPANY_PAYMENTS,
  COMPANY_PAYMENTS_BILLING_PROFILE_NEW,
  COMPANY_PAYMENTS_BILLING_PROFILE_CHANGE,
  COMPANY_PAYMENTS_CARD_NEW,
  COMPANY_PAYMENTS_CARD_CHANGE,
  COMPANY_PAYMENTS_OVERVIEW,
  COMPANY_PAYMENTS_PRICING_PLANS_INITIAL,
  COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE,
  COMPANY_PAYMENTS_PRICING_PLANS_CHANGE,
  COMPANY_PAYMENTS_PRICING_PLANS_HIRE,
  COMPANY_PAYMENTS_CARD_NEW_ON_HIRE
} from '../../constants/routes';
import CompanyPaymentsContainer from './CompanyPaymentsContainer';
import PaymentCardContainer from './card/PaymentCardContainer';
import PaymentCardOnHireContainer from './card/PaymentCardOnHireContainer';
import BillingProfileContainer from './billingProfile/BillingProfileContainer';
import PricingPlansContainer from './pricingPlan/PricingPlansContainer';
import PricingPlansOnHireContainer from './pricingPlan/PricingPlansOnHireContainer';
import ChangePlanContainer from './pricingPlan/ChangePlanContainer';
import { getPaymentsOverview } from './companyPaymentsActions';
import { colors } from '../../common/styles/base.style';
import Routing from '../../core/routing/index';

const { Redirect, withRouter } = Routing;

const DEFAULT_SCREEN = COMPANY_PAYMENTS_OVERVIEW;

const styles = StyleSheet.create({
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class CompanyPaymentsRouting extends Component {
  constructor(props) {
    super(props);
    this.state = { paymentsData: undefined };
  }

  componentDidMount() {
    this.props.getPaymentsOverview().then((response) => {
      if (!response.isError) {
        this.setState({ paymentsData: response.payload });
      }
    });
  }

  checkDataAndRoute = () => {
    if (this.state.paymentsData === null || this.state.paymentsData.subscription == null) {
      return <Redirect to={COMPANY_PAYMENTS_PRICING_PLANS_INITIAL} />;
    }
    return <Redirect to={DEFAULT_SCREEN} />;
  };

  render() {
    return this.state.paymentsData === undefined ? (
      <ActivityIndicator size="large" color={colors.magenta} style={styles.spinner} />
    ) : (
      <>
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS} render={this.checkDataAndRoute} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_PRICING_PLANS_INITIAL} component={PricingPlansContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_CARD_NEW} component={PaymentCardContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_BILLING_PROFILE_NEW} component={BillingProfileContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE} component={PricingPlansContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_PRICING_PLANS_CHANGE} component={ChangePlanContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_CARD_CHANGE} component={PaymentCardContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_CARD_NEW_ON_HIRE} component={PaymentCardOnHireContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_BILLING_PROFILE_CHANGE} component={BillingProfileContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_OVERVIEW} component={CompanyPaymentsContainer} />
        <AuthorizedRoute exact only={COMPANY_USER} path={COMPANY_PAYMENTS_PRICING_PLANS_HIRE} component={PricingPlansOnHireContainer} />
      </>
    );
  }
}

CompanyPaymentsRouting.propTypes = {
  getPaymentsOverview: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { getPaymentsOverview })(CompanyPaymentsRouting));
