import React from 'react';
import { StyleSheet, View } from 'react-native';
import AdminFindCompanyFilters from './filters/AdminFindCompanyFilters';
import AdminFindCompanyList from './AdminFindCompanyList';

import { shadowBottom, spacings } from '../../../common/styles/base.style';
import Container from '../../../common/components/Container';

const styles = StyleSheet.create({
  container: {
    ...shadowBottom,
    paddingHorizontal: spacings.sm,
  },
});

function AdminFindCompanyContainer() {
  return (
    <Container>
      <View style={styles.container}>
        <AdminFindCompanyFilters />
      </View>
      <AdminFindCompanyList />
    </Container>
  );
}

export default AdminFindCompanyContainer;
