import queryString from 'query-string';

import {
  CANDIDATE_GET_GIG_REQUEST,
  CANDIDATE_GET_SIMILAR_GIGS_REQUEST,
} from '../../constants/actionTypes';

export function getGig(gigId, origin) {
  return {
    type: CANDIDATE_GET_GIG_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/candidate/gig/${gigId}?origin=${origin}`,
      },
    },
  };
}

export function getSimilarGigs({ gigId }, lastSeen, limit = 2) {
  return {
    type: CANDIDATE_GET_SIMILAR_GIGS_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/candidate/gig/${gigId}/similar?${queryString.stringify({ limit, lastSeen })}`,
      },
    },
  };
}
