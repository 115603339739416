import validator from 'validator';

export const MAX_PERSONAL_NUMBER_LENGTH = 8;

export const clearingNumberValidation = [
  {
    field: 'clearingNumber',
    method: validator.isLength,
    args: [{ max: MAX_PERSONAL_NUMBER_LENGTH }],
    message: 'too_long',
  },
];
