import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import { msisdnPropType } from '../../../../constants/propTypes';
import { phoneNumberValidation } from '../../../../common/msisdn/phoneNumberValidation';
import FormikPhoneNumber from '../../../../common/components/form/FormikPhoneNumber';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import Wrapper from '../../../../common/components/Wrapper';

const CandidateProfilePhoneForm = ({
  translate, renderFooter, handleSubmit, msisdn,
}) => (
  <FormikWithValidation
    validation={phoneNumberValidation}
    initialValues={{ msisdn }}
    onSubmit={handleSubmit}
    render={({ handleSubmit: handleSubmitFormik, isBtnDisabled }) => (
      <>
        <KeyboardAwareFlexWrapper>
          <Wrapper>
            <FormikPhoneNumber
              countryCodeName="msisdn.countryCode"
              phoneNumberName="msisdn.phoneNumber"
              countryCodeLabel={translate('candidateProfile.details.countryCodeLabel')}
              phoneNumberLabel={translate('candidateProfile.details.phoneLabel')}
              onSubmitEditing={handleSubmitFormik}
            />
          </Wrapper>
        </KeyboardAwareFlexWrapper>
        {renderFooter({ handleSubmitFormik, isBtnDisabled })}
      </>
    )}
  />
);

CandidateProfilePhoneForm.propTypes = {
  translate: PropTypes.func.isRequired,
  renderFooter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  msisdn: msisdnPropType.isRequired,
};

export default withLocalize(CandidateProfilePhoneForm);
