import React, { PureComponent } from 'react';
import { Animated, Easing } from 'react-native';
import PropTypes from 'prop-types';
import { checkboxIconStyle } from './checkboxIcon.style';
import { imagePropType } from '../../../../constants/propTypes';

const checkedImage = require('../../../../assets/icons/checkbox/on.png');

class CheckboxImageChecked extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAnimation: new Animated.Value(this.props.show ? 1 : 0),
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.animate(1, Easing.in(Easing.ease));
    } else if (prevProps.show && !this.props.show) {
      this.animate(0, Easing.out(Easing.ease));
    }
  }

  animate(toValue, easing) {
    Animated.timing(this.state.showAnimation, {
      toValue,
      duration: 100,
      useNativeDriver: true,
      easing,
    }).start();
  }

  render() {
    const animationStyles = {
      opacity: this.state.showAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      }),
      transform: [
        {
          scale: this.state.showAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [1.2, 1],
          }),
        },
      ],
    };

    return (
      <Animated.Image
        style={[checkboxIconStyle.icon, animationStyles]}
        source={this.props.customIcon || checkedImage}
      />
    );
  }
}

CheckboxImageChecked.propTypes = {
  show: PropTypes.bool.isRequired,
  customIcon: imagePropType,
};

CheckboxImageChecked.defaultProps = {
  customIcon: undefined,
};

export default CheckboxImageChecked;
