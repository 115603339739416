import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet, TouchableOpacity, Image, View,
} from 'react-native';
import { connect } from 'react-redux';

import { colors, spacings } from '../../styles/base.style';
import * as propTypes from '../../../constants/propTypes';

const chatIcon = require('../../../assets/icons/chat/startChat.png');

const styles = StyleSheet.create({
  bubble: {
    position: 'absolute',
    right: 0,
    top: 5,
    backgroundColor: colors.seablue,
    height: 12,
    width: 12,
    borderRadius: 6,
  },
});
const hitSlop = {
  top: 15, bottom: 15, left: 15, right: 15,
};

class PoolChatButton extends React.PureComponent {
  render() {
    const {
      newMessageAwaiting, style, onPress,
    } = this.props;
    return (
      <TouchableOpacity
        onPress={onPress}
        style={{ paddingLeft: spacings.sm }}
        hitSlop={hitSlop}
      >
        <Image style={[{ width: 30, height: 30 }, style.icon]} source={chatIcon} resizeMode="contain" />
        {newMessageAwaiting && <View style={styles.bubble} />}
      </TouchableOpacity>
    );
  }
}

PoolChatButton.propTypes = {
  newMessageAwaiting: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  style: PropTypes.shape({
    icon: propTypes.stylePropTypes,
  }),
};

PoolChatButton.defaultProps = {
  style: {
    icon: null,
  },
};

const mapStateToProps = (state, props) => {
  const poolChatData = state.poolChat.get('poolChatsData').get(props.poolChatId);
  return {
    newMessageAwaiting: poolChatData ? poolChatData.toJS().unread : false,
  };
};

export default connect(mapStateToProps, null)(PoolChatButton);
