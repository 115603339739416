import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';

import { colors, fontFamily, fontSizes } from '../styles/base.style';
import { font } from '../styles/mixins';

const styles = StyleSheet.create({
  text: {
    backgroundColor: colors.gold,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xxxs),
  },
});

const TenantIndicator = ({ tenantId }) => (!!tenantId && (
  <Text style={styles.text}>{`TENANT ID: ${tenantId}`}</Text>
));

TenantIndicator.propTypes = {
  tenantId: PropTypes.string,
};

TenantIndicator.defaultProps = {
  tenantId: null,
};

const mapStateToProps = (state) => ({
  tenantId: state.userStatus.get('tenantId'),
});

export default connect(mapStateToProps, null)(TenantIndicator);
