import {
  COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST,
  COMPANY_PROFILE_EDIT_REQUEST,
  COMPANY_PROFILE_REQUEST,
} from '../../constants/actionTypes';
import { uploadImage } from '../../core/client/imageUploadClient';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export const getProfile = () => ({
  type: COMPANY_PROFILE_REQUEST,
  payload: {
    request: {
      method: 'get',
      url: 'company/profile',
    },
  },
});

export function saveProfile(profileData) {
  googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.UPDATE_PROFILE);
  return {
    type: COMPANY_PROFILE_EDIT_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'company/profile',
        data: profileData,
      },
    },
  };
}

export const companyProfileEditProfileImage = (imageUri) => uploadImage(
  imageUri, 'company/profile/userProfileImage', COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST,
);
