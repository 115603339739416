import validator from 'validator';
import { emailValidation } from '../../../common/auth/LoginValidation';

const TAX_NUMBERS_PATTERNS = {
  AT: /^ATU\d{8}$/,
  BE: /^BE\d{10}$/,
  DE: /^DE\d{9}$/,
  DK: /^DK\d{8}$/,
  ES: /^ES\w\d{7}\w$/,
  FI: /^FI\d{8}$/,
  FR: /^FR\w{2}\d{9}$/,
  GB: /^(GB\d{9}$|GB\d{12}$|GBGD\d{3}$|GBHA\d{3}$)/,
  SE: /^SE\d{12}$/,
  PL: /^PL\d{10}$/,
};

function validateVatNumberFormat(vatNumber) {
  const regex = TAX_NUMBERS_PATTERNS[vatNumber.substr(0, 2)];
  return regex && regex.test(vatNumber);
}

export const billingProfileFormValidation = [
  ...emailValidation,
  {
    field: 'address.city',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'address.postal_code',
    method: validator.isPostalCode,
    args: ['any'],
    message: 'wrong_format',
  },
  {
    field: 'address.line1',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
   /* {
    field: 'address.country',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'vatNumber',
    method: validateVatNumberFormat,
    args: [],
    message: 'wrong_format',
  }, */
];
