import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../../../core/routing/index';
import { showConfirmAlert } from '../../../../common/components/alert/alerts';
import { successNotification } from '../../../../common/notifications_center/notificationCenterActions';
import { getBillingProfile, saveBillingProfile, payForGig } from '../../companyPaymentsActions';
import BillingProfileScreen from '../../billingProfile/BillingProfileScreen';
import { spacings } from '../../../../common/styles/base.style';
import { getGig } from '../../../gigs/companyGigActions';
import { COMPANY_GIG_MATCHING_HIRED } from '../../../../constants/routes';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  subheader: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacings.sm,
    marginHorizontal: spacings.xxl,
  },
  subheaderText: {
    textAlign: 'center',
  },
});

const INIT_BILLING_PROFILE = {
  address: {
    city: '',
    line1: '',
    line2: '',
    postal_code: '',
    country: 'Sverige',
  },
  email: '',
  invoiceReference: '',
  vatNumber: '',
};

class PayForGigBillingProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.isFormChanged = false;
    const pathnameParts = this.props.location.pathname.split('/');
    this.state = {
      billingProfile: null,
      gigId: pathnameParts[3],
      paymentPlanType: pathnameParts[6],
    };
  }

  componentDidMount() {
    this.props.getBillingProfile().then(({ payload, isError }) => {
      if (!isError) {
        this.setState({ billingProfile: payload });
      } else {
        this.setState({ billingProfile: INIT_BILLING_PROFILE });
      }
    });
  }

  onFormChange = (prevState, { values }) => {
    this.isFormChanged = JSON.stringify(values) !== JSON.stringify(this.state.billingProfile);
  };

  onSaveProfileSuccess = () => {
    const { gigId, paymentPlanType } = this.state;
    const { grandTotal } = this.props.location.state;

    this.props.payForGig(gigId, paymentPlanType, grandTotal).then(({ isError }) => {
      if (!isError) {
        this.props.successNotification(
          'company.payments.gig.invoiceCreateNotificationTitle',
          'company.payments.gig.invoiceCreateNotificationDesc',
          true
        );
        this.props.getGig(gigId);
        if (paymentPlanType === 'directHiring') {
          this.goToOffers(gigId);
        } else {
          this.goBack();
        }
      }
    });
  };

  handleBack = () => {
    if (this.isFormChanged) {
      showConfirmAlert(
        this.props.translate,
        {
          confirmKey: 'generic.discard',
          cancelKey: 'generic.cancel',
          textKey: 'companyMyProfile.doYouWantToDiscard',
          titleKey: 'companyMyProfile.unsavedChanges',
        },
        () => this.goBack()
      );
      return;
    }
    this.goBack();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goToOffers = (gigId) => {
    const redirectFunc = this.props.history.push;
    redirectFunc(COMPANY_GIG_MATCHING_HIRED.replace(':id', gigId));
  };

  saveProfile = (billingProfile, { setErrors, setSubmitting }) => {
    this.props.saveBillingProfile(billingProfile).then((response) => {
      if (!response.isError) {
        this.onSaveProfileSuccess();
      } else {
        setErrors(response.errors);
        setSubmitting(false);
      }
    });
  };

  render() {
    const { translate } = this.props;
    return (
      <BillingProfileScreen
        handleBack={this.handleBack}
        profile={this.state.billingProfile}
        handleSave={this.saveProfile}
        onFormChange={this.onFormChange}
        headerTitle={translate('company.payments.gig.invoiceBillingData')}
        buttonLabel={translate('company.payments.gig.btnLabel')}
        subheaderFunc={() => (
          <View style={styles.subheader}>
            <Text style={styles.subheaderText}>{translate('company.payments.gig.invoiceBillingDataDesc')}</Text>
          </View>
        )}
      />
    );
  }
}

PayForGigBillingProfileContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location,
  translate: PropTypes.func.isRequired,
  getBillingProfile: PropTypes.func.isRequired,
  saveBillingProfile: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  payForGig: PropTypes.func.isRequired,
  getGig: PropTypes.func.isRequired,
  grandTotal: PropTypes.number,
};

PayForGigBillingProfileContainer.defaultProps = {
  location: null,
  grandTotal: 0,
};

export default connect(null, {
  getBillingProfile,
  saveBillingProfile,
  successNotification,
  payForGig,
  getGig,
})(withRouter(withLocalize(PayForGigBillingProfileContainer)));
