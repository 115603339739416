import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';

import Routing from '../../../../core/routing/index';
import { gigPropType, subscriptionPropTypes } from '../../../../constants/propTypes';
import {
  COMPANY_GIG_PAYMENT_BILLING_PROFILE,
  COMPANY_PAYMENTS_PRICING_PLANS_INITIAL,
  COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE,
  COMPANY_PAYMENTS_OVERVIEW,
} from '../../../../constants/routes';
import { showConfirmAlert } from '../../../../common/components/alert/alerts';
import { infoNotification } from '../../../../common/notifications_center/notificationCenterActions';
import { getGigCandidateProfile } from './companyGigCandidatesActions';
import PaymentOnHireModal from '../../../payments/gigPayment/PaymentOnHireModal';
import { getGig } from '../../companyGigActions';

const { withRouter } = Routing;

export const withGigCandidateAccessHandleOnHire = (component, replacePathOnRedirectToPricingPlans = false) => {
  class DecoratingComponent extends React.Component {
    constructor(props) {
      super(props);
      this.wrappedComponent = component;
      this.replacePathOnRedirectToPricingPlans = replacePathOnRedirectToPricingPlans;
      this.state = { showPaymentModal: false, selectedPaymentPlanType: undefined };
    }

    componentDidMount() {
      if (!this.props.gig) {
        this.props.getGig(this.props.match.params.gigId);
      }
    }

    billConfirmation = (onConfirm, onCancel) => {
      showConfirmAlert(
        this.props.translate,
        {
          confirmKey: 'companyGigs.candidates.billingContinue',
          cancelKey: 'generic.cancel',
          titleKey: 'companyGigs.candidates.billingMessageBoxTitle',
          textKey: 'companyGigs.candidates.billingMessageBoxText',
        },
        onConfirm,
        onCancel
      );
    };

    redirectToPricingPlans = () => {
      let redirectFunc = this.props.history.push;
      if (this.replacePathOnRedirectToPricingPlans) {
        redirectFunc = this.props.history.replace;
      }
      if (this.props.companySubscription) {
        redirectFunc(COMPANY_PAYMENTS_PRICING_PLANS_SUBSCRIBE, { goBackTo: this.props.history.location });
      } else {
        redirectFunc(COMPANY_PAYMENTS_PRICING_PLANS_INITIAL, { goBackTo: this.props.history.location });
      }
    };

    goToPricingPlan = (gigId, candidateId) => {
      /*  let redirectFunc = this.props.history.push;
      if (this.replacePathOnRedirectToPricingPlans) {
        redirectFunc = this.props.history.replace;
      }
      redirectFunc(COMPANY_PAYMENTS_PRICING_PLANS_HIRE, { goBackTo: this.props.history.location }); */
      this.showPaymentModal();
      this.setState({ gigId, candidateId });
    };

    redirectToBillingProfile = (gigId, paymentPlanType, grandTotal) => {
      let redirectFunc = this.props.history.push;
      if (this.replacePathOnRedirectToPricingPlans) {
        redirectFunc = this.props.history.replace;
      }
      redirectFunc(COMPANY_GIG_PAYMENT_BILLING_PROFILE.replace(':gigId', gigId).replace(':paymentPlanType', paymentPlanType), {
        goBackTo: this.props.history.location,
        grandTotal,
      });
    };

    redirectToPaymentBreakdown = (gigId, paymentPlanType, offer, candidateId) => {
      let redirectFunc = this.props.history.push;
      if (this.replacePathOnRedirectToPricingPlans) {
        redirectFunc = this.props.history.replace;
      }

      redirectFunc(COMPANY_PAYMENTS_OVERVIEW, {
        gigId,
        paymentPlanType,
        goBackTo: this.props.history.location,
        offer,
        isPaymentBreakdown: true,
        candidateId
      });
    };

    selectPaymentPlan = (paymentPlanType) => {
      this.setState({ selectedPaymentPlanType: paymentPlanType });
    };

    handleBillingOnHire = async (gigId, onSuccess) => {
      this.showPaymentModal();
      const { gig } = this.props;

      this.setState({ gigId });

      if (!gig) {
        return;
      }

      if (gig.gigId !== gigId) {
        infoNotification('generic.warning', 'generic.sww');
        return;
      }

      onSuccess();
    };

    showPaymentModal = () => {
      this.setState({ showPaymentModal: true });
    };

    closePaymentModal = () => {
      this.setState({ showPaymentModal: false });
    };

    render = () => {
      const WrappedComponent = this.wrappedComponent;
      return (
        <>
          <WrappedComponent handleBillingOnHire={this.handleBillingOnHire} goToPricingPlan={this.goToPricingPlan} {...this.props} />
          {this.state.showPaymentModal && (
            <PaymentOnHireModal
              visible={this.state.showPaymentModal}
              gigId={this.state.gigId}
              selectedPaymentPlanType={this.state.selectedPaymentPlanType}
              selectPaymentPlan={this.selectPaymentPlan}
              closeModal={this.closePaymentModal}
              onRedirectToPricingPlan={this.redirectToPricingPlans}
              redirectToBillingProfile={this.redirectToBillingProfile}
              candidateId={this.state.candidateId}
              redirectToPaymentBreakdown={this.redirectToPaymentBreakdown}
            />
          )}
        </>
      );
    };
  }

  DecoratingComponent.propTypes = {
    getGigCandidateProfile: PropTypes.func.isRequired,
    getGig: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    companySubscription: subscriptionPropTypes,
    grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    candidateId: PropTypes.string,
    gig: gigPropType,
    match: ReactRouterPropTypes.match.isRequired,
  };

  DecoratingComponent.defaultProps = {
    candidateId: undefined,
    companySubscription: null,
    gig: null,
  };

  const mapStateToProps = (state) => {
    const gigDataToPreview = state.companyGig.get('gigsList').get('gigDataToPreview');

    return {
      companySubscription: state.userStatus.get('subscription'),
      grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
      gig: gigDataToPreview ? gigDataToPreview.toJS() : null,
    };
  };

  return withRouter(withLocalize(connect(mapStateToProps, { getGigCandidateProfile, getGig })(DecoratingComponent)));
};
