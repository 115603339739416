import { Map } from 'immutable';
import { ON_APP_STATE_CHANGE } from '../../../constants/actionTypes';
import { APP_STATE_BACKGROUND } from './AppStateWatcher';

const INITIAL_APP_STATE = {
  lastAppStateChangeTimestamp: undefined,
  lastAppToBackgroundTimestamp: Date.now(),
  appState: undefined,
};

export default function appStateReducer(state = Map(INITIAL_APP_STATE), action) {
  switch (action.type) {
    case ON_APP_STATE_CHANGE:
      if (action.appState === APP_STATE_BACKGROUND) {
        return state.set('lastAppStateChangeTimestamp', Date.now()).set('appState', action.appState).set('lastAppToBackgroundTimestamp', Date.now());
      }
      return state.set('lastAppStateChangeTimestamp', Date.now()).set('appState', action.appState);
    default:
      return state;
  }
}
