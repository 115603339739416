import { List, Map, fromJS } from 'immutable';
import {
  CANDIDATE_GIGS_FIND_GIGS_CHANGE_OFFSET,
  CANDIDATE_GIGS_FIND_GIGS_REQUEST_SUCCESS,
  CANDIDATE_GIGS_FIND_GIGS_SET_SCROLL_POSITION,
  CANDIDATE_APPLY_FOR_GIG_REQUEST_SUCCESS,
  CANDIDATE_APPROVE_SHORTLISTING_REQUEST_SUCCESS,
  CANDIDATE_REJECT_REQUEST_SUCCESS,
  CANDIDATE_GIGS_FIND_GIGS_CLEAR_LIST,
  CANDIDATE_GIGS_FIND_GIGS_SET_CURRENT_INDEX,
  CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_MODAL,
  CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST_SUCCESS,
  CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST_SUCCESS,
  CANDIDATE_GIGS_REMOVE_FAVOURITE_GIG_FROM_LIST,
  CANDIDATE_GIGS_REMOVE_NOT_INTERESTING_GIG_FROM_LIST,
  CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST_SUCCESS,
  CANDIDATE_CHANGE_FAVOURITE_COUNTER,
  CANDIDATE_CHANGE_NOT_INTERESTING_COUNTER,
  CANDIDATE_ADD_FAVOURITE_GIG_SUCCESS,
  CANDIDATE_ADD_NOT_INTERESTING_GIG_SUCCESS,
  CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_INFO_MODAL,
} from '../../../constants/actionTypes';
import { GIG_CANDIDATE_STATUS } from '../../../constants/matchingStatus';
import { FIND_GIGS_CONTEXT } from '../../../constants/actionContext';
import { context } from '../../../common/gig/gigReducerHelper';

export const INITIAL_CANDIDATE_GIGS = {
  offset: 0,
  allLoaded: false,
  canMoveBack: false,
  scrollPosition: 0,
  currentIndex: 0,
  gigs: List(),
  gigsFavourite: null,
  gigsNotInteresting: null,
  favouritesCount: undefined,
  notInterestingCount: undefined,
  showFavouritesModal: false,
  showInfoModal: false,
};

const FAVOURITES_DELTA_FOR_MODAL = 20;

const updateGigStatusOnList = (state, gigId, status) => (state.set('gigs', state.get('gigs')
  .map((gig) => (gig.get('gigId') === gigId ? gig.set('matchingStatus', status) : gig))));

export default function candidateFindGigsReducer(state = new Map(INITIAL_CANDIDATE_GIGS), action) {
  if (!context(action, FIND_GIGS_CONTEXT)) {
    return state;
  }
  switch (action.type) {
    case CANDIDATE_GIGS_FIND_GIGS_REQUEST_SUCCESS: {
      let modifiedState = state;

      if (action.payload.length === 0) {
        modifiedState = modifiedState.set('allLoaded', true);
      }

      if (action.meta.previousAction.reload) {
        return modifiedState.set('gigs', fromJS(action.payload));
      }
      const previouslyLoadedIds = state.get('gigs').toJS().map((item) => item.gigId);

      const newGigs = action.payload;
      const filteredGigs = newGigs.filter((gig) => !previouslyLoadedIds.includes(gig.gigId));
      if (filteredGigs.length === 0) {
        modifiedState = modifiedState.set('allLoaded', true);
      }

      return modifiedState.set('gigs', modifiedState.get('gigs').concat(fromJS(filteredGigs)));
    }
    case CANDIDATE_GIGS_FIND_GIGS_CHANGE_OFFSET: {
      const { offset } = action;
      let modifiedState = state;
      // if we're at the beginning of the list
      if (offset === 0) {
        modifiedState = modifiedState.set('allLoaded', false);
      }
      return modifiedState.set('offset', offset);
    }
    case CANDIDATE_GIGS_FIND_GIGS_SET_SCROLL_POSITION:
      return state.set('scrollPosition', action.scrollPosition);
    case CANDIDATE_GIGS_FIND_GIGS_SET_CURRENT_INDEX: {
      let modifiedState = state;
      modifiedState = modifiedState.set('canMoveBack', state.get('currentIndex') < action.currentIndex);
      return modifiedState.set('currentIndex', action.currentIndex);
    }
    case CANDIDATE_GIGS_FIND_GIGS_CLEAR_LIST:
      return state.set('gigs', List());
    case CANDIDATE_APPLY_FOR_GIG_REQUEST_SUCCESS:
      return updateGigStatusOnList(state, action.meta.previousAction.gigId, GIG_CANDIDATE_STATUS.APPLIED);
    case CANDIDATE_APPROVE_SHORTLISTING_REQUEST_SUCCESS:
      return updateGigStatusOnList(state, action.meta.previousAction.gigId, GIG_CANDIDATE_STATUS.SHORTLISTED);
    case CANDIDATE_REJECT_REQUEST_SUCCESS:
      return updateGigStatusOnList(state, action.meta.previousAction.gigId, GIG_CANDIDATE_STATUS.REJECTED_BY_CANDIDATE);
    case CANDIDATE_ADD_FAVOURITE_GIG_SUCCESS:
      return state.set('lastSingleAction', fromJS({ gigId: action.meta.previousAction.gigId, action: 'favourite' }));
    case CANDIDATE_ADD_NOT_INTERESTING_GIG_SUCCESS:
      return state.set('lastSingleAction', fromJS({ gigId: action.meta.previousAction.gigId, action: 'notInteresting' }));
    case CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST_SUCCESS:
      return state.set('gigsFavourite', fromJS(action.payload));
    case CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST_SUCCESS:
      return state.set('gigsNotInteresting', fromJS(action.payload));
    case CANDIDATE_GIGS_REMOVE_FAVOURITE_GIG_FROM_LIST: {
      if (!state.get('gigsFavourite')) {
        return state;
      }
      return state.set('gigsFavourite', state.get('gigsFavourite').filterNot((gig) => gig.get('gigId') === action.gigId));
    }
    case CANDIDATE_GIGS_REMOVE_NOT_INTERESTING_GIG_FROM_LIST: {
      if (!state.get('gigsNotInteresting')) {
        return state;
      }
      return state.set('gigsNotInteresting', state.get('gigsNotInteresting').filterNot((gig) => gig.get('gigId') === action.gigId));
    }
    case CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST_SUCCESS:
      return state.set('favouritesCount', action.payload.favourite).set('notInterestingCount', action.payload.notInteresting);
    case CANDIDATE_CHANGE_FAVOURITE_COUNTER: {
      let modifiedState = state;
      modifiedState = modifiedState.set('favouritesCount', state.get('favouritesCount') + action.modifier);
      const newFavouritesCount = modifiedState.get('favouritesCount');
      if (newFavouritesCount > 0 && newFavouritesCount % FAVOURITES_DELTA_FOR_MODAL === 0) {
        modifiedState = modifiedState.set('showFavouritesModal', true);
      }

      return modifiedState;
    }
    case CANDIDATE_CHANGE_NOT_INTERESTING_COUNTER:
      return state.set('notInterestingCount', state.get('notInterestingCount') + action.modifier);
    case CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_MODAL:
      return state.set('showFavouritesModal', action.shouldShow);
    case CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_INFO_MODAL:
      return state.set('showInfoModal', action.shouldShow);
    default:
      return state;
  }
}
