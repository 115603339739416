import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import queryString from 'query-string';

import { fontFamily, fontSizes, spacings } from '../../styles/base.style';
import { font } from '../../styles/mixins';
import { gigPropType } from '../../../constants/propTypes';
import { CANDIDATE_GIG_DETAILS } from '../../../constants/routes';
import Routing from '../../../core/routing/index';
import SimilarGig from './SimilarGig';
import { GIG_DETAILS_ORIGIN } from '../../../constants/gigDetailsOrigin';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  header: {
    alignSelf: 'center',
    paddingTop: spacings.md,
    paddingBottom: spacings.sm,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.lg),
  },
  jobOffers: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: spacings.lg,
  },
});

class SimilarGigs extends Component {
  redirectToGigDetails = (gig) => {
    const { history } = this.props;
    const queryStr = queryString.stringify({ origin: GIG_DETAILS_ORIGIN.SIMILAR_GIGS });
    history.push(`${CANDIDATE_GIG_DETAILS.replace(':id', gig.gigId)}?${queryStr}`);
  };

  render() {
    const { translate, gigs } = this.props;

    if (gigs && gigs.length > 0) {
      return (
        <View>
          <Text style={styles.header}>{translate('candidate.similarGigs.header')}</Text>
          <View style={styles.jobOffers}>
            {
              gigs.map((gig) => <SimilarGig key={gig.gigId} gig={gig} onPress={() => this.redirectToGigDetails(gig)} />)
            }
          </View>
        </View>
      );
    }
    return null;
  }
}

SimilarGigs.propTypes = {
  translate: PropTypes.func.isRequired,
  gigs: PropTypes.arrayOf(gigPropType),
  history: ReactRouterPropTypes.history.isRequired,
};

SimilarGigs.defaultProps = {
  gigs: [],
};

export default withLocalize(withRouter(SimilarGigs));
