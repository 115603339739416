import React from 'react';
import { Platform } from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';

import Routing from '../core/routing/index';
import AuthorizedRoute from '../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../constants/userScopes';
import {
  CANDIDATE,
  CANDIDATE_AUTH,
  CANDIDATE_CHAT,
  CANDIDATE_GIGS,
  CANDIDATE_MY_POOLS,
  CANDIDATE_PROFILE,
  CANDIDATE_SHIFT_DETAILS,
  CANDIDATE_SPLASH,
  FIRST_CHECK_USER_STATUS,
} from '../constants/routes';
import CandidateProfileRouting from './profile/CandidateProfileRouting';
import Container from '../common/components/Container';
import TenantIndicator from '../common/user/TenantIndicator';
import ViewportController from '../common/components/ViewportController';
import CandidateGigRouting from './gig/CandidateGigRouting';
import CandidateChatRouting from './chat/CandidateChatRouting';
import CandidateMyPoolsRouting from './myPools/CandidateMyPoolsRouting';
import CandidateNavigationBar from './menu/CandidateNavigationBar';
import CandidateAuthRouting from './auth/CandidateAuthRouting';
import CandidateWelcomeSplashScreen from './auth/CandidateWelcomeSplashScreen';
import CandidateShiftDetailsContainer from './shift/CandidateShiftDetailsContainer';
import renderMenu from './menu/appMenuConfig';

const { Route, Redirect, withRouter } = Routing;
const DEFAULT_SCREEN = FIRST_CHECK_USER_STATUS;

const CandidateRouting = (props) => (
  <ViewportController safeAreaBottom={renderMenu(props.location.pathname)}>
    <Container>
      <Route exact path={CANDIDATE} render={() => <Redirect to={DEFAULT_SCREEN} />} />
      <Route path={CANDIDATE_AUTH} component={CandidateAuthRouting} />
      <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_SPLASH} component={CandidateWelcomeSplashScreen} />
      <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_GIGS} component={CandidateGigRouting} />
      <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_CHAT} component={CandidateChatRouting} />
      <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS} component={CandidateMyPoolsRouting} />
      <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_PROFILE} component={CandidateProfileRouting} />
      <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_SHIFT_DETAILS} component={CandidateShiftDetailsContainer} />
    </Container>
    {Platform.OS !== 'web' && <CandidateNavigationBar />}
    <TenantIndicator />
  </ViewportController>
);

CandidateRouting.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(CandidateRouting);
