import moment from 'moment';
// needed for moment().tz
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

export function toDatetime(date) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function toModernDate(date) {
  return moment(date).format('MMM DD');
}

export function toModernDateWithWeekDayName(date) {
  return moment(date).format('ddd, MMM DD');
}

export function toDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function toTime(date) {
  return moment(date).format('HH:mm');
}

/*
  We add 1 to diff, so that 01-01 to 01-02 counted as 2 months.
 */
export function formatDuration(from, to) {
  return moment.duration(to.diff(from, 'months') + 1, 'months').format('Y __ M __', { trim: 'both' });
}

export function formatHowLongAgo(lastMessageDate) {
  const lastMessageMoment = moment(lastMessageDate);
  if (lastMessageMoment.isSame(new Date(), 'day')) {
    return toTime(lastMessageDate);
  }
  return lastMessageMoment.fromNow();
}

/* In the future we need to make timezone dependent on the country where gig is created. For now it's always sweden.
  We should also considere is we should take this into account in format functions.
 */
export function getUtcOffsetForGigLocation() {
  return moment().tz('Europe/Stockholm').utcOffset();
}
