import React, { PureComponent } from 'react';
import { Animated, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { inputTextStyle } from '../../styles/form.style';

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
  },
});

// how much we need to scale the label to achieve target fontSize
const SCALE = (inputTextStyle.inputLabelSmall.fontSize / inputTextStyle.inputLabelLarge.fontSize);
// we need some value for animations calculations before real width is computed. it crashes with undef
const INITIAL_BASE_LABEL_WIDTH = 0.01;
const ANIMATION_DURATION = 200;

class FloatingFormLabel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      floatingAnim: new Animated.Value(this.props.hasValue ? 1 : 0),
      baseLabelWidth: INITIAL_BASE_LABEL_WIDTH,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.hasFocus && this.props.hasFocus) {
      this.animate(1);
    } else if (!prevProps.hasValue && this.props.hasValue) { // value was set externally = skip animation
      this.state.floatingAnim.setValue(1);
    } else if (prevProps.hasValue && !this.props.hasValue && !this.props.hasFocus) { // value was set externally = skip animation
      this.state.floatingAnim.setValue(0);
    } else if (prevProps.hasFocus && !this.props.hasFocus && !this.props.hasValue) {
      this.animate(0);
    }
  }

  // we need to know label width to use it in translateX transform (no support of transform origin)
  onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    this.setState({ baseLabelWidth: width });
  };

  animate(toValue) {
    Animated.timing(this.state.floatingAnim, {
      toValue,
      duration: ANIMATION_DURATION,
      useNativeDriver: true,
    }).start();
  }

  render() {
    const { style, label } = this.props;
    if (!label) {
      return null;
    }

    const labelStyles = {
      transform: [
        {
          translateY: this.state.floatingAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [1, -inputTextStyle.inputLabelSmall.fontSize - 5],
          }),
        },
        {
          translateX: this.state.floatingAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0, (this.state.baseLabelWidth * (SCALE - 1)) / 2],
          }),
        },
        {
          scaleX: this.state.floatingAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [1, SCALE],
          }),
        },
        {
          scaleY: this.state.floatingAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [1, SCALE],
          }),
        },
      ],
    };

    return (
      // pointerEvents="none" don't work on android, it works on View though...
      <Animated.View
        pointerEvents="none"
        onLayout={this.onLayout}
        style={[
          styles.wrapper,
          labelStyles,
          this.state.baseLabelWidth === INITIAL_BASE_LABEL_WIDTH ? { opacity: 0 } : null,
        ]}
      >
        <Text
          style={[
            inputTextStyle.inputLabelLarge,
            style,
          ]}
        >
          {label}
        </Text>
      </Animated.View>
    );
  }
}

FloatingFormLabel.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.array]),
  label: PropTypes.string,
  hasFocus: PropTypes.bool,
  hasValue: PropTypes.bool,
};

FloatingFormLabel.defaultProps = {
  style: null,
  label: null,
  hasFocus: false,
  hasValue: true,
};
export default FloatingFormLabel;
