import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { StyleSheet, ActivityIndicator } from 'react-native';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import { candidateProfileSetCategory, getProfileCategoryValidationParams } from '../../CandidateProfileActions';
import { getTranslationForCategoryKey } from '../../../../common/gig/gigCategoriesHelper';
import { activeLanguagePropTypes, candidateCategoryValidationParams, gigCategoryPropType } from '../../../../constants/propTypes';
import { getAvailableGigCategories } from '../../../../common/gig/gigCategoriesActions';
import CandidateProfileCategoryForm from '../../forms/category/CandidateProfileCategoryForm';
import { spacings } from '../../../../common/styles/base.style';

const styles = StyleSheet.create({
  loadingIcon: {
    marginVertical: spacings.sm,
    justifyContent: 'center',
  },
});

class EditableFieldCategoryModalAware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationParamsFetched: false,
    };
  }

  componentDidMount() {
    this.props.getAvailableGigCategories();
    this.props.getProfileCategoryValidationParams()
      .then((response) => {
        if (!response.isError) {
          this.setState({ validationParamsFetched: true });
        }
      });
  }

  openModal = () => {
    const {
      translate, closeModal, categories, candidateCategoriesValidationParams,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.categoryLabel'),

      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileCategoryForm}
        closeModal={closeModal}
        candidateCategoriesValidationParams={candidateCategoriesValidationParams}
        selectedCategories={categories}
        handleSubmit={(values) => {
          this.props.candidateProfileSetCategory(values)
            .then((response) => {
              if (!response.isError) {
                closeModal();
              }
            });
        }}
      />,
    );
  };

  render() {
    const {
      translate, activeLanguage, categories, availableCategories,
    } = this.props;
    const { validationParamsFetched } = this.state;
    if (validationParamsFetched) {
      return (
        <EditableField
          label={translate('candidateProfile.details.categoryLabel')}
          value={categories.map((cKey) => getTranslationForCategoryKey(availableCategories, cKey, activeLanguage)).join(', ')}
          onPress={this.openModal}
        />
      );
    }
    return (<ActivityIndicator size="large" style={styles.loadingIcon} />);
  }
}

EditableFieldCategoryModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  candidateProfileSetCategory: PropTypes.func.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  getProfileCategoryValidationParams: PropTypes.func.isRequired,
  candidateCategoriesValidationParams: candidateCategoryValidationParams.isRequired,
};

const mapStateToProps = (state) => ({
  candidateCategoriesValidationParams: state.candidate.get('profile').get('categoryValidationParams').toJS(),
  categories: state.candidate.get('profile').get('categories').toJS(),
  availableCategories: state.gigCategories.toJS(),
});

export default connect(mapStateToProps, {
  candidateProfileSetCategory, getAvailableGigCategories, getProfileCategoryValidationParams,
})(withLocalize(EditableFieldCategoryModalAware));
