import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { getLanguageItems } from './languageUtils';
import PrimaryPicker from '../../components/form/PrimaryPicker';
import { spacings } from '../../styles/base.style';

const pickerStyle = StyleSheet.create({
  container: {
    marginBottom: spacings.sm,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  androidAndWebWrapper: {
    borderBottomWidth: 1,
    width: 125,
  },
  label: {
    marginBottom: 0,
    marginTop: 0,
    alignSelf: 'center',
  },
});

const pickerInputStyle = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
  input: {
    paddingVertical: 0,
    height: 30,
    width: Platform.select({ android: 140, ios: 80, web: 80 }),
    transform: [],
  },
});

const LanguageSelector = ({
  translate,
  labelKey,
  onValueChange,
  selectedValue,
}) => (
  <PrimaryPicker
    label={translate(labelKey)}
    labelSize="lg"
    name={labelKey}
    style={pickerStyle}
    inputStyle={pickerInputStyle}
    items={getLanguageItems()}
    onValueChange={onValueChange}
    selectedValue={selectedValue}
  />
);

LanguageSelector.propTypes = {
  translate: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any.isRequired,
};

export default withLocalize(LanguageSelector);
