import { Component } from 'react';
import PropTypes from 'prop-types';
import { Keyboard, Platform, LayoutAnimation } from 'react-native';

class KeyboardAwareShowView extends Component {
  customLayoutKeyboard = {
    duration: 200,
    create: {
      type: LayoutAnimation.Types.easeInEaseOut,
      property: LayoutAnimation.Properties.opacity,
    },
    delete: {
      type: LayoutAnimation.Types.easeInEaseOut,
      property: LayoutAnimation.Properties.opacity,
    },
  };

  constructor(props) {
    super(props);
    this.state = { showFooter: false };
  }

  componentDidMount() {
    if (Platform.OS === 'ios') {
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.keyboardDidShow);
      this.keyboardDidHideListener = Keyboard.addListener('keyboardWillHide', this.keyboardWillHide);
    }
  }

  componentWillUnmount() {
    if (Platform.OS === 'ios') {
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
    }
  }

  keyboardDidShow = () => {
    LayoutAnimation.configureNext(this.customLayoutKeyboard);
    this.setState({ showFooter: true });
  };

  keyboardWillHide = () => {
    LayoutAnimation.configureNext(this.customLayoutKeyboard);
    this.setState({ showFooter: false });
  };

  render() {
    return this.state.showFooter ? this.props.children : null;
  }
}

KeyboardAwareShowView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KeyboardAwareShowView;
