import { Map, fromJS } from 'immutable';
import {
  COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS,
  COMPANY_PROFILE_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

export default function companyProfileReducer(state = Map({
  companyDescription: '',
  companyName: '',
  companyRegistrationNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  profileImageUri: undefined,
  msisdn: undefined,
}), action) {
  switch (action.type) {
    case COMPANY_PROFILE_REQUEST_SUCCESS:
      return fromJS(action.payload);
    case COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS: {
      return state.set('profileImageUri', action.payload.uri);
    }
    default:
      return state;
  }
}
