import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { MAX_COUNTRY_CODE_LENGTH, MAX_PHONE_NUMBER_LENGTH } from '../../msisdn/phoneNumberValidation';
import FormikFieldPrimaryInputTextNextSupport from './FormikFieldPrimaryInputTextNextSupport';
import FormikFieldPrimaryTextInput from './FormikFieldPrimaryTextInput';
import { spacings } from '../../styles/base.style';
import { innerRefPropType } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  countryCodeWrapper: {
    width: 70,
    marginRight: spacings.sm,
  },
  phoneNumberWrapper: {
    flex: 1,
  },
});

class FormikPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      countryCodeLabel,
      phoneNumberLabel,
      countryCodePlaceholder,
      phoneNumberPlaceholder,
      phoneNumberName,
      countryCodeName,
      onSubmitEditing,
      innerRef,
    } = this.props;

    return (
      <View style={styles.wrapper}>
        <FormikFieldPrimaryInputTextNextSupport
          innerRef={innerRef}
          name={countryCodeName}
          label={countryCodeLabel}
          placeholder={countryCodePlaceholder}
          maxLength={MAX_COUNTRY_CODE_LENGTH}
          keyboardType="phone-pad"
          style={{ container: styles.countryCodeWrapper }}
          nextComponent={this.state.phoneNumberInputRef}
          returnKeyType="done"
        />
        <FormikFieldPrimaryTextInput
          innerRef={(ref) => !this.state.phoneNumberInputRef && this.setState({ phoneNumberInputRef: ref })}
          name={phoneNumberName}
          keyboardType="phone-pad"
          label={phoneNumberLabel}
          placeholder={phoneNumberPlaceholder}
          onSubmitEditing={onSubmitEditing}
          maxLength={MAX_PHONE_NUMBER_LENGTH}
          returnKeyType="done"
          style={{ container: styles.phoneNumberWrapper }}
        />
      </View>
    );
  }
}

FormikPhoneNumber.propTypes = {
  countryCodeLabel: PropTypes.string.isRequired,
  phoneNumberLabel: PropTypes.string.isRequired,
  countryCodePlaceholder: PropTypes.string,
  phoneNumberPlaceholder: PropTypes.string,
  countryCodeName: PropTypes.string,
  phoneNumberName: PropTypes.string,
  onSubmitEditing: PropTypes.func,
  innerRef: innerRefPropType,
};

FormikPhoneNumber.defaultProps = {
  countryCodeName: 'countryCode',
  phoneNumberName: 'phoneNumber',
  countryCodePlaceholder: undefined,
  phoneNumberPlaceholder: undefined,
  onSubmitEditing: undefined,
  innerRef: undefined,
};

export default FormikPhoneNumber;
