import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { activeLanguagePropTypes, gigCategoryPropType } from '../../../../constants/propTypes';
import FindGigsSelectCategoryModal from './FindGigsSelectCategoryModal';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../../../common/styles/base.style';
import Touchable from '../../../../common/components/Touchable';
import { renderCategories } from './candidatesFindGigsFiltersHelpers';
import Arrow from '../../../../common/components/Arrow';

const styles = StyleSheet.create({
  label: {
    color: colors.greyishBrown,
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginBottom: 3,
  },
  value: {
    color: colors.greyishBrown,
    fontSize: fontSizes.md,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    marginRight: spacings.xs,
    textAlign: 'left',
  },
  container: {
    marginBottom: spacings.md,
  },
});

class FindGigsSelectCategoryField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  handleSubmit = ({ categories }, { setSubmitting }) => {
    setSubmitting(false);
    this.closeModal();
    this.props.handleSubmit(categories);
  };

  handleSelectCategories = () => {
    this.setState({ modalVisible: true });
  };
  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      translate, activeLanguage, categories, availableCategories,
    } = this.props;
    return (
      <>
        <Touchable style={styles.container} onPress={this.handleSelectCategories}>
          <Text style={styles.label}>{translate('candidateFindGigs.categoryLabel')}</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <View style={{ flex: 1, marginRight: 5 }}>
              {renderCategories(categories, availableCategories, activeLanguage, translate)}
            </View>
            <Arrow />
          </View>
        </Touchable>

        <FindGigsSelectCategoryModal
          categories={categories}
          handleSubmit={this.handleSubmit}
          handleCancel={this.closeModal}
          visible={this.state.modalVisible}
        />
      </>

    );
  }
}

FindGigsSelectCategoryField.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: activeLanguagePropTypes.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FindGigsSelectCategoryField;
