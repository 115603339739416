/* eslint-disable no-param-reassign */
export function updateLastMessageOnCandidateChatList(candidateChats, chatId, msg, date) {
  return candidateChats.map((candidateChat) => (
    candidateChat.get('chatId') === chatId ? candidateChat.set('lastMessageDate', date).set('lastMessage', msg) : candidateChat
  ));
}

export function sortCandidateChats(candidateChats) {
  return candidateChats.sort((chat1, chat2) => {
    if (chat1.get('lastMessageDate') == null && chat2.get('lastMessageDate') == null) {
      return chat1.get('chatId').localeCompare(chat2.get('chatId'));
    } if (chat1.get('lastMessageDate') == null) {
      return 1;
    } if (chat2.get('lastMessageDate') == null) {
      return -1;
    }
    return chat2.get('lastMessageDate') - chat1.get('lastMessageDate');
  });
}

function updateUnreadOnCandidateChat(chat, unreadChatsMap) {
  const unreadFromMap = unreadChatsMap.get(chat.get('chatId'));
  const chatUnread = unreadFromMap !== undefined ? unreadFromMap : false;
  return chat.set('unread', chatUnread);
}

export function updateUnreadOnCandidateChatList(chats, unreadChatsMap) {
  return chats.map((chat) => updateUnreadOnCandidateChat(chat, unreadChatsMap));
}

/**
 * generate gig data for each chat based on chats list from backend
 */
export function reduceCandidateChats(payload) {
  return payload.reduce((map, chat) => {
    const {
      gigId, chatId, companyName, companyUserId, gigPosition, userName, userProfileImageUri, user,
    } = chat;
    map[gigId] = {
      chatId,
      companyName,
      companyUserId,
      gigPosition,
      userName,
      userProfileImageUri,
      user,
    };
    return map;
  }, {});
}
