import { fromJS, Map } from 'immutable';
import {
  getLastMessageReadTimestampsMap, updateLastMessageOnPoolChatsList, updateUnreadOnPoolChats, updateUnreadOnPoolChat, POOL_CHAT_TYPE,
} from './poolChatReducerHelper';
import {
  POOL_CHAT_UPDATED,
  CHAT_SUBSCRIPTIONS_RECEIVED,
  POOL_CHAT_NEW_MESSAGE,
  POOL_CHAT_SET_MESSAGES,
  POOL_CHAT_SAVE_LAST_MESSAGE_READ,
  COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS,
  COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS,
  CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS,
  CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS,
  CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST_SUCCESS,
  CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST_SUCCESS,
  COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST_SUCCESS,
  COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST_SUCCESS,
} from '../../../constants/actionTypes';

export const INITIAL_POOL_CHAT_STATE = {
  // { poolChatId (groupId or poolCandidateId): { chatId, lastMessageDate, lastMessage, lastReadDate } } - data needed to display a single pool direct chat
  poolChatsData: Map(),

  // { chatId: [messages] } - messages of single chat
  chatMessages: Map(),

  // { chatId: bool } - map of unread chats. some of them might not be present on gigs list
  poolChatsUnread: Map(),

  // { poolChatId: timestamp } - stores local interactions with the chat
  poolChatsLastMessageReadTimestamps: Map(),
};

function insertOrUpdate(list, value, keyProperty) {
  const valueIndex = list.findIndex((msg) => msg.get(keyProperty) === value[keyProperty]);
  return valueIndex > -1 ? list.set(valueIndex, fromJS(value)) : list.insert(0, fromJS(value));
}

/* eslint-disable no-param-reassign */
function mapSubscriptionsToUnread(subscriptions) {
  return subscriptions.reduce((map, subscription) => {
    if (subscription.ls) {
      map[subscription.rid] = subscription.alert;
    } else {
      // must be a chat user never opened
      map[subscription.rid] = true;
    }
    return map;
  }, {});
}

const convertArrayToImmutableMap = (array, key) => fromJS(array.reduce((obj, item) => ({
  [item.toJS()[key]]: { ...item.toJS(), [key]: undefined },
  ...obj,
}), {}));

function isChatUnreadAfterUpdate(updateDate, lastMessageReadTimestamp) {
  if (!lastMessageReadTimestamp) {
    return true;
  }
  return lastMessageReadTimestamp < updateDate;
}

const isAdminMessage = (msg) => msg.t && ['ru', 'au'].includes(msg.t);

export default function poolChatReducer(state = new Map(INITIAL_POOL_CHAT_STATE), action) {
  switch (action.type) {
    case COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS:
    case CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS:
    case COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST_SUCCESS:
    case CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST_SUCCESS: {
      const { chatType } = action.meta.previousAction;
      let chatsData;
      if (chatType === POOL_CHAT_TYPE.GROUP) {
        chatsData = convertArrayToImmutableMap(
          updateUnreadOnPoolChats(fromJS(action.payload.chats.map((chat) => ({ ...chat, type: chatType }))), state.get('poolChatsUnread')),
          'groupId',
        );
      }
      if (chatType === POOL_CHAT_TYPE.DIRECT) {
        chatsData = convertArrayToImmutableMap(
          updateUnreadOnPoolChats(fromJS(action.payload.chats.map((chat) => ({ ...chat, type: chatType }))), state.get('poolChatsUnread')),
          'poolCandidateId',
        );
      }
      const poolChatsData = state.get('poolChatsData');
      const poolChatsLMRTData = state.get('poolChatsLastMessageReadTimestamps');
      return state.set('poolChatsData', poolChatsData.merge(chatsData))
        .set('poolChatsLastMessageReadTimestamps', poolChatsLMRTData.merge(getLastMessageReadTimestampsMap(chatsData)));
    }
    case COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST_SUCCESS:
    case CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST_SUCCESS:
    case COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS:
    case CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS: {
      const { poolChatId, chatType } = action.meta.previousAction;
      return state.setIn(
        ['poolChatsData', poolChatId],
        updateUnreadOnPoolChat(fromJS({ ...action.payload, type: chatType }), state.get('poolChatsUnread')),
      );
    }
    case POOL_CHAT_SET_MESSAGES: {
      return state.setIn(['chatMessages', action.chatId], fromJS(action.messages.filter((msg) => !isAdminMessage(msg))));
    }
    case POOL_CHAT_NEW_MESSAGE: {
      if (isAdminMessage(action.message)) {
        return state;
      }
      return state.setIn(
        ['chatMessages', action.chatId],
        insertOrUpdate(state.get('chatMessages').get(action.chatId), action.message, '_id'),
      );
    }
    case POOL_CHAT_SAVE_LAST_MESSAGE_READ: {
      const { poolChatId, chatId, timestamp } = action;
      const updatedPoolChatsUnread = state.get('poolChatsUnread').set(chatId, false);
      return state
        .setIn(['poolChatsLastMessageReadTimestamps', poolChatId], timestamp)
        .set('poolChatsUnread', updatedPoolChatsUnread)
        .set('poolChatsData', updateUnreadOnPoolChats(state.get('poolChatsData'), updatedPoolChatsUnread));
    }
    case CHAT_SUBSCRIPTIONS_RECEIVED: {
      const poolChatsUnread = fromJS(mapSubscriptionsToUnread(action.subscriptions));
      return state.set('poolChatsUnread', poolChatsUnread)
        .set('poolChatsData', updateUnreadOnPoolChats(state.get('poolChatsData'), poolChatsUnread));
    }
    case POOL_CHAT_UPDATED: {
      const {
        poolChatId, chatId, updatedDate, msg,
      } = action;

      const poolChatUnreadAfterUpdate = isChatUnreadAfterUpdate(
        updatedDate,
        state.get('poolChatsLastMessageReadTimestamps').get(poolChatId),
      );
      const updatedPoolChatsUnread = state.get('poolChatsUnread').set(chatId, poolChatUnreadAfterUpdate);

      let updatedPoolChats = updateUnreadOnPoolChats(state.get('poolChatsData'), updatedPoolChatsUnread);
      updatedPoolChats = updateLastMessageOnPoolChatsList(updatedPoolChats, chatId, msg, updatedDate);

      return state.set('poolChatsUnread', updatedPoolChatsUnread).set('poolChatsData', updatedPoolChats);
    }
    default:
      return state;
  }
}
