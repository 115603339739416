import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { getChatCredentials } from '../../../common/chat/chatActions';

import Routing from '../../../core/routing/index';
import { redirectToErrorScreen } from '../../../constants/routes';
import { createOrGetCompanyPoolDirectChat } from './companyPoolChatActions';
import PoolChatView from '../../../common/chat/pool/PoolChatView';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class CompanyPoolDirectChatContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accessToChatScreen: false,
    };
  }

  componentDidMount() {
    this.openChat();
  }

  getChatView = () => {
    const {
      translate,
      poolCandidateId,
      poolChatData: { chatId, userName },
    } = this.props;

    return (
      <PoolChatView
        poolChatId={poolCandidateId}
        chatId={chatId}
        chatHeader={userName}
        emptyChatPlaceholder={translate('companyChat.emptyChatPlaceholder')}
      />
    );
  };

  getChatToken() {
    if (this.props.chatToken) {
      return Promise.resolve();
    }
    return this.props.getChatCredentials();
  }

  openChat = () => {
    this.getChatToken().then(this.fetchChatData).then((response) => {
      if (response.isError) {
        this.handleError();
      } else {
        this.setState({ accessToChatScreen: true });
      }
    });
  };

  fetchChatData = () => this.props.createOrGetCompanyPoolDirectChat(this.props.poolCandidateId);

  handleError = () => {
    redirectToErrorScreen(this.props.history, 'errors.chatUnavailableTitle', 'errors.chatUnavailableText');
  };

  render() {
    if (!this.state.accessToChatScreen) {
      return (<ActivityIndicator size="large" style={styles.spinner} />);
    }
    return (
      <>
        {this.getChatView()}
      </>
    );
  }
}

CompanyPoolDirectChatContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  createOrGetCompanyPoolDirectChat: PropTypes.func.isRequired,
  getChatCredentials: PropTypes.func.isRequired,
  chatToken: PropTypes.string,
  poolCandidateId: PropTypes.string.isRequired,
  poolChatData: PropTypes.shape({
    chatId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userProfileImageUri: PropTypes.string,
  }),
};

CompanyPoolDirectChatContainer.defaultProps = {
  poolChatData: undefined,
  chatToken: undefined,
};

const mapStateToProps = (state, props) => {
  const { poolCandidateId } = props.match.params;
  const poolChatData = state.poolChat.get('poolChatsData').get(poolCandidateId);
  return {
    poolCandidateId,
    poolChatData: poolChatData ? poolChatData.toJS() : null,
    chatToken: state.chatAuth.get('chatToken'),
  };
};

export default connect(mapStateToProps, { createOrGetCompanyPoolDirectChat, getChatCredentials })(withLocalize(withRouter(CompanyPoolDirectChatContainer)));
