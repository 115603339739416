import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import PrimaryModal from '../../../../../common/components/modal/PrimaryModal';
import validation, {
  MAX_ADDITIONAL_INFO_LENGTH,
  MAX_POSITION_LENGTH,
  MAX_SHIFT_LENGTH,
  MAX_STREET_LENGTH,
  MAX_WORKLOAD_LENGTH,
} from './companyGigCandidateOfferModalValidation';
import ModalKeyboardAwareScrollView from '../../../../../common/keyboard/ModalKeyboardAwareScrollView';
import KeyboardAwareShowView from '../../../../../common/components/KeyboardAwareShowView';
import Wrapper from '../../../../../common/components/Wrapper';
import FormikFieldPrimaryTextInput from '../../../../../common/components/form/FormikFieldPrimaryTextInput';
import EditModalFooter from '../../../../../common/components/modal/EditModalFooter';
import FormikWithValidation from '../../../../../common/components/form/FormikWithValidation';
import SectionHeader from '../../../../../common/components/header/SectionHeader';
import {
  fontFamily, fontSizes, spacings, standardSpacings,
} from '../../../../../common/styles/base.style';
import FormikFieldDateTimePicker from '../../../../../common/components/form/FormikFieldDateTimePicker';
import FormikFieldPrimarySwitch from '../../../../../common/components/form/switch/FormikFieldPrimarySwitch';
import { font } from '../../../../../common/styles/mixins';
import HorizontalWrapper from '../../../../../common/components/HorizontalWrapper';
import FormikFieldCompensationInput from '../../../../../common/components/form/FormikFieldCompensationInput';
import FormikFieldPrimaryPicker from '../../../../../common/components/form/FormikFieldPrimaryPicker';
import FormikFieldPrimaryInputTextNextSupport from '../../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import { getGigOffer, sendOfferToCandidate } from '../companyGigCandidatesActions';
import { OFFERS_LIST_KEY, MATCHED_LIST_KEY } from '../companyGigCandidatesReducer';
import { MessageBox } from '../../../../../common/components/MessageBox';
import DisplayField from '../../../../../common/components/DisplayField';
import { startOfToday } from '../../../../../utilities/dateUtils';
import { showConfirmSendOfferAlert, showConfirmResendOfferAlert } from '../../../../../common/components/alert/alerts';
import { getSalaryUnits } from '../../../../../common/gig/salaryFormatter';
import { getAvailableGigCities } from '../../../../../common/gig/city/gigCitiesActions';
import { getTranslationForCity } from '../../../../../common/gig/city/gigCitiesHelper';

const messageBoxStyles = StyleSheet.create({
  container: { marginBottom: standardSpacings.formVertical },
});
const salaryLeftLabelStyle = StyleSheet.create({
  style: {
    marginTop: 12,
  },
});
const salaryPerStyles = StyleSheet.create({
  androidAndWebWrapper: {
    borderBottomWidth: 0,
    width: 150,
    alignItems: 'flex-end',
  },
  container: {
    marginLeft: spacings.xs,
  },
  input: {
    width: 150,
  },
});

const startDateStyle = StyleSheet.create({
  container: {
    flex: 1,
    marginRight: spacings.xs,
  },
});
const endDateStyle = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: spacings.xs,
  },
});
const noEndDateStyle = StyleSheet.create({
  label: {
    textAlign: 'right',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
    alignSelf: 'center',
  },
});

class CompanyGigCandidateOfferModal extends Component {
  translationKey = 'company.gigOffer';

  constructor(props) {
    super(props);
    this.state = {
      showVolunteering: false,
      showInternshipTrainee: false,
    };
  }

  componentDidMount() {
    this.props.getAvailableGigCities();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      // eslint-disable-next-line
      this.setState({ offer: undefined });
      this.loadOffer();
    }
  }

  getCitiesWithTranslations = () => this.props.availableCities
    && this.props.availableCities.map((city) => ({ value: city.key, label: getTranslationForCity(city) }));

  handleSubmit = (offer, { setSubmitting }) => {
    const {
      sendOfferToCandidateAction, gigId, candidateId, closeModal, edit,
    } = this.props;

    sendOfferToCandidateAction(gigId, candidateId, { listKey: edit ? OFFERS_LIST_KEY : MATCHED_LIST_KEY }, offer)
      .then((response) => {
        if (!response.isError) {
          closeModal(true);
        } else {
          setSubmitting(false);
        }
      });
  };

  loadOffer = () => {
    const {
      getGigOfferAction, gigId, candidateId,
    } = this.props;
    getGigOfferAction(gigId, candidateId)
      .then(({ payload: offer, isError }) => {
        if (!isError) {
          const salary = offer.compensation.salary ? offer.compensation.salary.toString() : '';
          const convertedOffer = {
            ...offer,
            // BACKWARD COMPATIBILITY - ignoring location.city for new apps; in future migrating location.cityV2 on location.city
            location: { ...offer.location, city: undefined },
            compensation: { ...offer.compensation, salary },
          };
          const showLabourContract = convertedOffer.compensation.labourContract === true;
          const showVolunteering = convertedOffer.compensation.volunteering === true;
          const showInternshipTrainee = convertedOffer.compensation.internshipTrainee === true;
          this.setState({
            offer: convertedOffer, showLabourContract, showVolunteering, showInternshipTrainee,
          }, () => {
            const touchedMap = Object.keys(this.formikWithValidationRef.formik.current.getFieldProps().value)
              .reduce((map, field) => {
                // eslint-disable-next-line no-param-reassign
                map[field] = true;
                return map;
              }, {});
            touchedMap.compensation = { salary: true };
            this.formikWithValidationRef.formik.current.setTouched(touchedMap);
          });
        }
      });
  };

  t = (key, ...args) => this.props.translate(`${this.translationKey}.${key}`, ...args);
  renderFooter = (handleSubmitFormik, isBtnDisabled) => (
    <EditModalFooter
      handleSubmitFormik={
        this.props.edit
          ? () => showConfirmResendOfferAlert(this.t, handleSubmitFormik)
          : () => showConfirmSendOfferAlert(this.t, handleSubmitFormik)
      }
      handleCancel={this.props.closeModal}
      isBtnDisabled={isBtnDisabled}
      cancelLabel={this.props.translate('generic.close')}
      confirmLabel={this.props.edit ? this.t('confirmBtnEdit') : this.t('confirmBtn')}
    />
  );

  renderForm() {
    const {
      offer, showLabourContract, showVolunteering, showInternshipTrainee,
    } = this.state;
    const { t } = this;

    return (
      <FormikWithValidation
        ref={(c) => { this.formikWithValidationRef = c; }}
        validation={validation}
        initialValues={offer}
        onSubmit={this.handleSubmit}
        enableReinitialize
        render={({
          handleSubmit: handleSubmitFormik, setFieldValue, isBtnDisabled, values,
        }) => (
          <>
            <ModalKeyboardAwareScrollView>
              <MessageBox title={t('messageBoxHeader')} text={t('messageBoxText')} style={messageBoxStyles} />
              <Wrapper>
                <DisplayField label={t('candidateLabel')} value={`${offer.candidate.firstName} ${offer.candidate.lastName}`} />
              </Wrapper>
              <SectionHeader title={t('generalInfoSubheader')} />
              <Wrapper noFlex>
                <FormikFieldPrimaryInputTextNextSupport
                  name="position"
                  label={t('positionLabel')}
                  maxLength={MAX_POSITION_LENGTH}
                  nextComponent={this.startDateRef}
                />

                <HorizontalWrapper>
                  <FormikFieldDateTimePicker
                    innerRef={(ref) => { this.startDateRef = ref; }}
                    name="startDate"
                    mode="date"
                    minimumDateFunc={() => startOfToday()}
                    label={t('startDateLabel')}
                    style={startDateStyle}
                  />
                  <FormikFieldDateTimePicker
                    name="endDate"
                    mode="date"
                    disabled={values.noEndDate}
                    minimumDateFunc={() => (values.startDate ? values.startDate : startOfToday())}
                    label={t('endDateLabel')}
                    style={endDateStyle}
                  />
                </HorizontalWrapper>
                {/* <FormikFieldPrimarySwitch
                  name="noEndDate"
                  label={t('noEndDateLabel')}
                  onValueChange={
                            (val) => {
                              if (val) { setFieldValue('endDate', null); }
                            }
                          }
                  style={noEndDateStyle}
                /> */}
              </Wrapper>
              <SectionHeader title={t('salary.salarySubheader')} />
              <Wrapper noFlex>
                <HorizontalWrapper noFlex>
                  <FormikFieldCompensationInput
                    name="compensation.salary"
                    nextComponent={this.workloadRef}
                    leftLabel="SEK"
                    leftLabelStyle={salaryLeftLabelStyle.style}
                  />
                  <FormikFieldPrimaryPicker
                    name="compensation.salaryPer"
                    items={getSalaryUnits(this.props.translate)}
                    style={salaryPerStyles}
                  />
                </HorizontalWrapper>
                {
                         showLabourContract && (
                         <FormikFieldPrimarySwitch
                           name="compensation.labourContract"
                           label={t('salary.labourContract')}
                         />
                         )
                       }
                {
                         showVolunteering && (
                         <FormikFieldPrimarySwitch
                           name="compensation.volunteering"
                           label={t('salary.volunteering')}
                         />
                         )
                       }
                {
                         showInternshipTrainee && (
                         <FormikFieldPrimarySwitch
                           name="compensation.internshipTrainee"
                           label={t('salary.internshipTrainee')}
                         />
                         )
                       }
              </Wrapper>
              <SectionHeader title={t('availabilitySubheader')} />
              <Wrapper noFlex>
                <FormikFieldPrimaryInputTextNextSupport
                  innerRef={(ref) => { this.workloadRef = ref; }}
                  name="workload"
                  label={t('workloadLabel')}
                  placeholder={t('workloadPlaceholder')}
                  maxLength={MAX_WORKLOAD_LENGTH}
                  nextComponent={this.shiftRef}
                />
                <FormikFieldPrimaryTextInput
                  innerRef={(ref) => { this.shiftRef = ref; }}
                  name="shift"
                  label={t('shiftLabel')}
                  placeholder={t('shiftPlaceholder')}
                  maxLength={MAX_SHIFT_LENGTH}
                  disabled={values.noShift}
                />
                <FormikFieldPrimarySwitch
                  name="noShift"
                  label={t('noShiftLabel')}
                  onValueChange={
                           (val) => {
                             if (val) { setFieldValue('shift', null); }
                           }
                         }
                />
              </Wrapper>
              <SectionHeader title={t('locationSubheader')} />
              <Wrapper noFlex>
                <FormikFieldPrimaryPicker
                  name="location.cityV2.key"
                  items={this.getCitiesWithTranslations()}
                  label={t('cityLabel')}
                />
                <FormikFieldPrimaryTextInput
                  name="location.street"
                  label={t('streetLabel')}
                  maxLength={MAX_STREET_LENGTH}
                />
                <FormikFieldPrimarySwitch
                  name="remoteWorkAllowed"
                  label={t('remoteWorkAllowedLabel')}
                />
              </Wrapper>
              <SectionHeader title={t('otherSubheader')} />
              <Wrapper>
                <FormikFieldPrimaryTextInput
                  name="additionalInfo"
                  label={t('additionalInfoLabel')}
                  multiline
                  maxLength={MAX_ADDITIONAL_INFO_LENGTH}
                  scrollEnabled={false}
                />
              </Wrapper>

              <KeyboardAwareShowView>
                {this.renderFooter(handleSubmitFormik, isBtnDisabled)}
              </KeyboardAwareShowView>
            </ModalKeyboardAwareScrollView>
            {this.renderFooter(handleSubmitFormik, isBtnDisabled)}
          </>
        )}
      />
    );
  }

  render() {
    const { visible, closeModal, availableCities } = this.props;
    if (!availableCities) {
      return null;
    }
    return (
      <PrimaryModal visible={visible} handleCancel={closeModal} header={this.t('header')} headerProps={{ showBackArrow: true, shadow: true }}>
        {this.state.offer ? this.renderForm() : null}
      </PrimaryModal>
    );
  }
}

CompanyGigCandidateOfferModal.propTypes = {
  translate: PropTypes.func.isRequired,
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  sendOfferToCandidateAction: PropTypes.func.isRequired,
  getGigOfferAction: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  candidateId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  availableCities: PropTypes.arrayOf(PropTypes.object).isRequired,
  edit: PropTypes.bool,
};

CompanyGigCandidateOfferModal.defaultProps = {
  gigId: undefined,
  candidateId: undefined,
  edit: false,
};

const mapStateToProps = (state) => ({
  availableCities: state.gigCities.toJS(),
});

export default connect(
  mapStateToProps,
  { getGigOfferAction: getGigOffer, sendOfferToCandidateAction: sendOfferToCandidate, getAvailableGigCities },
)(withLocalize(CompanyGigCandidateOfferModal));
