import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { FlatList, ActivityIndicator, StyleSheet } from 'react-native';
import ListEmptyLabel from './ListEmptyLabel';
import EmptyElementForPadding from './EmptyElementForPadding';
import { spacings, standardColors } from '../styles/base.style';
import * as propTypes from '../../constants/propTypes';

const styles = StyleSheet.create({
  container: {
    backgroundColor: standardColors.listBackground,
  },
  emptyLabel: {
    paddingHorizontal: spacings.md,
  },
});

const PrimaryFlatList = ({
  paddingTop,
  paddingBottom,
  emptyListTextKey,
  data,
  style,
  innerRef,
  ...props
}) => (
  <FlatList
    ListEmptyComponent={
      data ? () => <ListEmptyLabel style={styles.emptyLabel} textKey={emptyListTextKey} /> : <ActivityIndicator style={{ marginTop: 10 }} size="small" />
    }
    ListHeaderComponent={<EmptyElementForPadding padding={paddingTop} />}
    ListFooterComponent={<EmptyElementForPadding padding={paddingBottom} />}
    data={data}
    style={[styles.container, style]}
    ref={innerRef}
    {...props}
  />
);

PrimaryFlatList.propTypes = {
  emptyListTextKey: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  innerRef: PropTypes.func,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  style: propTypes.stylePropTypes,
};

PrimaryFlatList.defaultProps = {
  data: undefined,
  innerRef: undefined,
  paddingTop: 10,
  paddingBottom: 10,
  style: undefined,
};

export default withLocalize(PrimaryFlatList);
