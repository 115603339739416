import { PER } from '../../constants/salaryTypes';

function getSalaryValueText(salary, minExists, maxExists, valueSuffix, langCode) {
  let salaryValueText = '';
  if (minExists !== maxExists || salary.max === salary.min) {
    const salaryValue = minExists ? salary.min : salary.max;
    salaryValueText += `SEK ${salaryValue.toLocaleString(langCode)}`;
  } else {
    salaryValueText += `SEK ${salary.min.toLocaleString(langCode)} - ${salary.max.toLocaleString(langCode)}`;
  }
  if (valueSuffix) {
    salaryValueText += `${valueSuffix}`;
  }
  return salaryValueText;
}

export const getSalaryText = (compensation, valueSuffix, translate, langCode) => {
  const { salary } = compensation;
  const minExists = salary.min !== null && salary.min !== undefined && !Number.isNaN(salary.min);
  const maxExists = salary.max !== null && salary.max !== undefined && !Number.isNaN(salary.max);

  const compensationTextLines = [];
  if (compensation.salaryByAgreement) {
    compensationTextLines.push(translate('salaryByAgreement'));
  } else if (minExists || maxExists) {
    compensationTextLines.push(getSalaryValueText(salary, minExists, maxExists, valueSuffix, langCode));
  }
  if (compensation.labourContract) compensationTextLines.push(translate('labourContract'));
  if (compensation.volunteering) compensationTextLines.push(translate('volunteering'));
  if (compensation.internshipTrainee) compensationTextLines.push(translate('internshipTrainee'));

  return compensationTextLines.join('\n');
};

export const getSalaryUnits = (translate) => Object.keys(PER).map((key) => ({ label: translate(`gigSalary.per.${key}`), value: PER[key] }));
