import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import CompanyShiftsDatesAndTimesStepEditableScreen from './CompanyShiftsDatesAndTimesStepEditableScreen';
import { shiftCreatorSetDatesAndTimes, shiftCreatorSetBookSeparately } from '../shiftCreatorActions';
import { COMPANY_SHIFT_CREATE_SALARY } from '../../../../constants/routes';
import { shiftDateAndTimePropType } from '../../../../constants/propTypes';
import Routing from '../../../../core/routing/index';

const { withRouter } = Routing;

class CompanyShiftsDatesAndTimesStepEditableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftDatesAndTimes: props.datesAndTimes,
      bookSeparately: props.bookSeparately,
    };
  }

  onAddDateAndTime = (dateAndTime, { resetForm }) => {
    const existingEntry = this.state.shiftDatesAndTimes.filter((item) => item.startDate === dateAndTime.startDate && item.endDate === dateAndTime.endDate);
    if (existingEntry.length > 0) {
      resetForm();
      return;
    }
    const id = `${(Math.ceil(Math.random() * (10 ** 16)))}`;
    this.setState({
      shiftDatesAndTimes: this.state.shiftDatesAndTimes
        .concat({ ...dateAndTime, id })
        .sort((item1, item2) => (item1.startDate !== item2.startDate ? item1.startDate - item2.startDate : item1.endDate - item2.endDate)),
    });
    resetForm();
  };

  onRemoveDateAndTime = (id) => {
    const currentDatesAndTimes = this.state.shiftDatesAndTimes.filter((dateAndTime) => dateAndTime.id !== id);
    if (currentDatesAndTimes.length < 2) {
      this.setState({ bookSeparately: false });
    }
    this.setState({ shiftDatesAndTimes: this.state.shiftDatesAndTimes.filter((dateAndTime) => dateAndTime.id !== id) });
  };

  setBookSeparately = (value) => {
    this.setState({ bookSeparately: value });
  };

  handleNext = () => {
    this.props.shiftCreatorSetDatesAndTimes(this.state.shiftDatesAndTimes);
    this.props.shiftCreatorSetBookSeparately(this.state.bookSeparately);
    this.props.history.push(COMPANY_SHIFT_CREATE_SALARY);
  };

  render() {
    const { shiftDatesAndTimes, bookSeparately } = this.state;

    return (
      <CompanyShiftsDatesAndTimesStepEditableScreen
        shiftDatesAndTimes={shiftDatesAndTimes}
        handleNext={this.handleNext}
        onAddDateAndTime={this.onAddDateAndTime}
        onRemoveDateAndTime={this.onRemoveDateAndTime}
        setBookSeparately={this.setBookSeparately}
        bookSeparately={bookSeparately}
      />
    );
  }
}

CompanyShiftsDatesAndTimesStepEditableContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  shiftCreatorSetDatesAndTimes: PropTypes.func.isRequired,
  shiftCreatorSetBookSeparately: PropTypes.func.isRequired,
  datesAndTimes: PropTypes.arrayOf(shiftDateAndTimePropType),
  bookSeparately: PropTypes.bool.isRequired,
};

CompanyShiftsDatesAndTimesStepEditableContainer.defaultProps = {
  datesAndTimes: [],
};

const mapStateToProps = (state) => ({
  datesAndTimes: state.shiftCreator.get('shift').get('shiftDatesAndTimes').toJS(),
  bookSeparately: state.shiftCreator.get('shift').get('bookSeparately'),
});

export default connect(mapStateToProps, {
  shiftCreatorSetDatesAndTimes, shiftCreatorSetBookSeparately,
})(withRouter(CompanyShiftsDatesAndTimesStepEditableContainer));
