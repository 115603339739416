import {
  REGISTER_DEVICE_FOR_PUSH_REQUEST,
  SAVE_NOTIFICATIONS_SETTING_REQUEST,
  SAVE_INTERESTING_GIG_NOTIFICATION_SETTING_REQUEST,
  USER_REGISTER_INSTALLATION,
  DELETE_ACCOUNT_REQUEST,
} from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../analytics/googleAnalytics';

export function saveNotificationsSetting(key, value) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.UPDATE_NOTIFICATION_SETTINGS);
  if (key === 'enabled' && value === false) {
    googleAnalytics.trackUserEvent(ACTIONS.USER.DISABLED_NOTIFICATIONS);
  }
  return {
    type: SAVE_NOTIFICATIONS_SETTING_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `user/settings/notifications/${key}`,
        data: { value },
      },
    },
  };
}

export function saveInterestingGigNotificationsSettings(value) {
  googleAnalytics.trackUserEvent(ACTIONS.USER.UPDATE_FEED_NOTIFICATION_SETTINGS);
  return {
    type: SAVE_INTERESTING_GIG_NOTIFICATION_SETTING_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: 'user/settings/interestingGigNotifications',
        data: { value },
      },
    },
  };
}

export function registerInstallation(installationId, device) {
  return {
    type: USER_REGISTER_INSTALLATION,
    payload: {
      request: {
        method: 'post',
        url: `user/installation/${installationId}`,
        data: { installationId, device },
      },
    },
  };
}

export function registerDeviceForPush(token, installationId) {
  return {
    type: REGISTER_DEVICE_FOR_PUSH_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `user/installation/${installationId}/registerPushToken`,
        data: { pushToken: token },
      },
    },
  };
}

export function deleteAccount() {
  googleAnalytics.trackUserEvent(ACTIONS.USER.DELETE_ACCOUT);
  return {
    type: DELETE_ACCOUNT_REQUEST,
    payload: {
      request: {
        method: 'delete',
        url: 'user/account',
      },
    },
  };
}
