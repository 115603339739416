import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from './core/routing/index';

import AdminNavigationBar from './admin/menu/AdminNavigationBar';
import CompanyNavigatorBar from './company/menu/CompanyNavigatorBar';
import CandidateNavigationBar from './candidate/menu/CandidateNavigationBar';
import UserScope from './common/auth/UserScope';
import {
  COMPANY_USER, CANDIDATE_USER, ADMIN_USER, SUPERADMIN_USER,
} from './constants/userScopes';
import LogoComponent, { LogoImages } from './common/components/LogoComponent';
import { spacings, webMenuPercentWidth, wp } from './common/styles/base.style';
import adminRenderMenu from './admin/menu/appMenuConfig';
import companyRenderMenu from './company/menu/appMenuConfig';
import candidateRenderMenu from './candidate/menu/appMenuConfig';
import { PrintingContext } from './core/printingContext';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  menu: {
    display: 'flex',
    width: wp(webMenuPercentWidth),
    flexDirection: 'column',
    justifyContent: 'flex-start',
    shadowColor: '#000',
    zIndex: 1,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 4,
  },
});

const Logo = () => (
  <LogoComponent
    style={{ padding: spacings.md, marginBottom: spacings.md }}
    logo={{
      imgSrc: LogoImages.LG_LOGO.imgSrc,
      width: 98,
      height: 69,
    }}
  />
);

const WebMenuContainer = ({ location }) => {
  const { isPrint } = useContext(PrintingContext);
  return (
    <>
      {!isPrint && companyRenderMenu(location.pathname) && (
        <UserScope allowedFor={[COMPANY_USER]}>
          <View style={styles.menu}>
            <Logo />
            <CompanyNavigatorBar />
          </View>
        </UserScope>
      )}
      {!isPrint && candidateRenderMenu(location.pathname) && (
        <UserScope allowedFor={[CANDIDATE_USER]}>
          <View style={styles.menu}>
            <Logo />
            <CandidateNavigationBar />
          </View>
        </UserScope>
      )}
      {!isPrint && adminRenderMenu(location.pathname) && (
        <UserScope allowedFor={[SUPERADMIN_USER, ADMIN_USER]}>
          <View style={styles.menu}>
            <Logo />
            <AdminNavigationBar />
          </View>
        </UserScope>
      )}
    </>
  );
};

WebMenuContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(WebMenuContainer);
