import React from 'react';
import { View, Alert, Platform } from 'react-native';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withLocalize } from 'react-localize-redux';
import CreatorStepHeader, { creatorStepHeaderExternalPropTypes } from '../../common/components/CreatorStepHeader';
import Routing from '../../core/routing/index';
import { shadowBottom, spacings } from '../../common/styles/base.style';
import NavigationSaveButton from '../../common/components/navigation/NavigationSaveButton';
import { RIGHT_BUTTONS } from '../../common/components/header/ScreenHeader';
import { COMPANY_POSTGIG } from '../../constants/routes';

const { withRouter } = Routing;

const PAGE_AFTER_CLOSE = COMPANY_POSTGIG;

class GigCreatorHeader extends React.PureComponent {
  onClose = () => {
    const t = this.props.translate;
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      const r = window.confirm(t('companyGigCreator.header.close.text'));
      if (r) {
        this.doClose();
      }
    } else {
      Alert.alert(
        t('companyGigCreator.header.close.title'),
        t('companyGigCreator.header.close.text'),
        [
          { text: t('generic.cancel'), style: 'cancel' },
          { text: t('companyGigCreator.header.close.close'), onPress: this.doClose },
        ],
        { cancelable: true },
      );
    }
  };

  doClose = () => {
    this.props.history.replace(PAGE_AFTER_CLOSE);
  };

  renderSaveButton = (onPress) => (<NavigationSaveButton onPress={onPress} isBtnDisabled={this.props.isRightButtonDisabled} />);

  render() {
    return (
      <View style={{ ...shadowBottom }}>
        {this.props.isEdit ? (
          <CreatorStepHeader
            headerTranslationKey="companyGigCreator.header.editHeader"
            rightButton={this.props.onRightButtonPress ? this.renderSaveButton : undefined}
            onRightButtonPress={this.props.onRightButtonPress}
            marginBottom={spacings.md}
          />
        ) : (
          <CreatorStepHeader
            headerTranslationKey="companyGigCreator.header.header"
            maxSteps={this.props.maxStepsNumber}
            rightButton={RIGHT_BUTTONS.CANCEL}
            marginBottom={0}
            {...this.props}
            onRightButtonPress={this.onClose}
          />
        )}
      </View>
    );
  }
}

GigCreatorHeader.propTypes = {
  translate: PropTypes.func.isRequired,
  ...creatorStepHeaderExternalPropTypes,
  onRightButtonPress: PropTypes.func,
  isRightButtonDisabled: PropTypes.bool,
  history: ReactRouterPropTypes.history.isRequired,
  isEdit: PropTypes.bool,
  maxStepsNumber: PropTypes.number,
};

GigCreatorHeader.defaultProps = {
  onRightButtonPress: undefined,
  isRightButtonDisabled: undefined,
  isEdit: false,
  maxStepsNumber: undefined,
};

export default withRouter(withLocalize(GigCreatorHeader));
