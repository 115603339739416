import config from '../../config/config';

/**
 * Get language code from device. Fallbacks to 'en' if error
 * @returns {Promise} two letter language code
 * @example 'en' for english, 'sv' for swedish
 */
export default () => new Promise((resolve) => {
  let langCode;

  langCode = (navigator.languages && navigator.languages.length)
    ? navigator.languages[0] : navigator.language;

  if (!langCode) {
    langCode = config.defaultLocale;
  }

  // cut _XX or -XX part if any
  if (langCode.length > 2) {
    langCode = langCode.slice(0, 2);
  }

  return resolve(langCode);
});
