import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { adminSetFreeGig, adminSetSetting, adminSettingsChangeForm, createAdminCity, resetAdminValues } from './AdminSettingsActions';
import { windowWidth, spacings } from '../../common/styles/base.style';
import { Button, LinkButton } from '../../common/components';
import FormikFieldPrimaryTextInput from '../../common/components/form/FormikFieldPrimaryTextInput';
import Banner from '../../common/components/Banner';
import { ADMIN_SETTINGS_GET_CITY_LIST_SUCCESS } from '../../constants/routes';

import { BUTTON_SIZES } from '../../common/components/buttons/Button';

const styles = StyleSheet.create({
  wrapperGigLimit: {
    paddingTop: spacings.lg,
    paddingHorizontal: spacings.xl,
    paddingBottom: spacings.sm,
    backgroundColor: '#FFF',
    marginHorizontal: spacings.xl,
    marginTop: spacings.xl,
    marginBottom: spacings.l,
    borderRadius: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperCity: {
    paddingTop: spacings.lg,
    paddingHorizontal: spacings.xl,
    paddingBottom: spacings.sm,
    backgroundColor: '#FFF',
    marginHorizontal: spacings.xl,
    marginTop: spacings.xl,
    marginBottom: spacings.l,
    borderRadius: 25,
    justifyContent: 'space-between',
  },
  switchLabel: {
    fontSize: spacings.sm,
    paddingBottom: spacings.sm,
    color: 'black',
  },
  location: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  citiesButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

class AdminSettingsScreen extends Component {
  constructor(props) {
    super(props);
    this.formik = React.createRef();
    this.windowWidth = windowWidth;
    this.state = {
      success: false,
      hasError: false,
      message: '',
    };
  }

  updateSetting = async (data) => {
    const modifiedValues = { ...data };
    this.props.adminSettingsChangeForm(modifiedValues);

    console.log(`data ${JSON.stringify(data)}`);

    this.props.adminSetSetting(data).then((response) => {
      if (response.isError) {
        this.setState({ success: false, hasError: true, message: this.props.translate('adminSettings.errorSettings') });

        this.timer = setTimeout(() => {
          this.setState({ success: false, hasError: false });
        }, 3000);
      } else {
        this.setState({ success: true, hasError: false, message: this.props.translate('adminSettings.successSettings') });
        this.timer = setTimeout(() => {
          this.setState({ success: false, hasError: false });
        }, 3000);
      }
    });
  };

  createCity = async (values) => {
    const modifiedValues = { ...values };
    this.props.adminSettingsChangeForm(modifiedValues);
    this.props.createAdminCity(modifiedValues);

    this.timer = setTimeout(() => {
      this.props.resetAdminValues();
    }, 3000);
  };

  renderAllSettings = (setting, index) => {
    const { value } = setting;
    const { name } = setting;
    const settings = (
      <Formik key={index} validate={false} onSubmit={this.updateSetting} initialValues={{ name, value }} innerRef={this.formik}>
        {({ handleSubmit, isBtnDisabled }) => (
          <View style={styles.wrapperGigLimit}>
            <>
              <FormikFieldPrimaryTextInput style={{ label: { width: '120%' } }} name="value" label={setting.name} placeholder={setting.name} value={value} disabled={isBtnDisabled} />
              <Button size="md" title="update" onPress={handleSubmit} />
            </>
          </View>
        )}
      </Formik>
    );

    return <View key={index}>{settings}</View>;
  };

  render() {
    const { translate, settingsList, key, order, name, long, lat } = this.props;
    const t = (key) => translate(`adminSettings.${key}`);

    return (
      <View>
        {this.state.success && <Banner isSuccess text={this.state.message} />}
        {this.state.hasError && <Banner isSuccess={false} text={this.state.message} />}

        {this.props.isSuccessAddCity && <Banner isSuccess text={this.props.translate('adminSettings.successAddCity')} />}
        {this.props.isErrorAddCity && <Banner isSuccess={false} text={this.props.translate('adminSettings.errorAddCity')} />}

        {settingsList.map((setting, index) => [this.renderAllSettings(setting, index)])}

        <View style={styles.wrapperCity}>
          <Formik
            validate={false}
            onSubmit={this.createCity}
            initialValues={{
              key,
              order,
              name,
              long,
              lat,
            }}
            innerRef={this.formik}
          >
            {({ handleSubmit }) => (
              <>
                <FormikFieldPrimaryTextInput name="key" label={t('key')} placeholder={t('key')} />
                <FormikFieldPrimaryTextInput name="order" label={t('order')} placeholder={t('order')} />
                <FormikFieldPrimaryTextInput name="name" label={t('addCity')} placeholder={t('addCity')} />
                <FormikFieldPrimaryTextInput name="long" label={t('longitude')} placeholder={t('longitude')} />
                <FormikFieldPrimaryTextInput name="lat" label={t('latitude')} placeholder={t('latitude')} />
                <View style={styles.citiesButton}>
                  <Button size="md" title={t('save')} onPress={handleSubmit} />
                  <LinkButton title="View Cities" to={ADMIN_SETTINGS_GET_CITY_LIST_SUCCESS} size={BUTTON_SIZES.MD} />
                </View>
              </>
            )}
          </Formik>
        </View>
      </View>
    );
  }
}

AdminSettingsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  adminSettingsChangeForm: PropTypes.func.isRequired,
  createAdminCity: PropTypes.func.isRequired,
  isSuccessAddCity: PropTypes.bool.isRequired,
  isErrorAddCity: PropTypes.bool.isRequired,
  resetAdminValues: PropTypes.func.isRequired,
  adminSetSetting: PropTypes.func.isRequired,
};

AdminSettingsScreen.defaultProps = {};

const mapStateToProps = (state) => {
  const formValues = state.admin.get('settings').get('formValues');
  const isSuccessAddCity = state.admin.get('settings').get('isSuccessAddCity');
  const isErrorAddCity = state.admin.get('settings').get('isErrorAddCity');

  return {
    formValues,
    isSuccessAddCity,
    isErrorAddCity,
  };
};

export default connect(mapStateToProps, {
  adminSetFreeGig,
  adminSettingsChangeForm,
  createAdminCity,
  resetAdminValues,
  adminSetSetting,
})(withLocalize(AdminSettingsScreen));
