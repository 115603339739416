import validator from 'validator';

export const MAX_POSITION_LENGTH = 70;
export const MAX_DESCRIPTION_LENGTH = 3000;
export const MAX_CAREER_URL_LENGTH = 200;

export default [
  {
    field: 'position',
    method: validator.isLength,
    args: [{ min: 1 }],
    message: 'too_short',
  },
  {
    field: 'position',
    method: validator.isLength,
    args: [{ max: MAX_POSITION_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'description',
    method: validator.isLength,
    args: [{ min: 1 }],
    validWhen: true,
    message: 'too_short',
  },
  {
    field: 'description',
    method: validator.isLength,
    args: [{ max: MAX_DESCRIPTION_LENGTH }],
    message: 'too_long',
  },
  {
    field: 'candidatesNumber',
    method: validator.isInt,
    args: [{ max: 99 }],
    message: 'too_big',
  },
  {
    field: 'candidatesNumber',
    method: validator.isInt,
    args: [{ min: 1 }],
    message: 'too_small',
  },
  {
    field: 'careerUrl',
    custom: (value) => !value || validator.isURL(value, { require_protocol: true }),
    message: 'wrong_format',
  },
  {
    field: 'careerUrl',
    method: validator.isLength,
    args: [{ max: MAX_CAREER_URL_LENGTH }],
    message: 'too_long',
  },
];
