import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { PrimarySwitch as DefaultSwitch } from '../../index';
import { getValueByPath } from '../../../../utilities/objectUtils';

const FormikPrimarySwitch = ({
  Switch,
  field: { name, value },
  form: {
    setFieldValue, setFieldTouched, touched, errors,
  },
  onValueChange,
  ...props
}) => (
  <Switch
    {...props}
    onValueChange={(val) => {
      setFieldValue(name, val);
      setFieldTouched(name, true);
      if (onValueChange) {
        onValueChange(val);
      }
    }}
    value={value}
    error={(getValueByPath(touched, name) && errors[name]) || null}
    testID={`${name}Switch`}
  />
);

FormikPrimarySwitch.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.any,
  }).isRequired,
  onValueChange: PropTypes.func,
  Switch: PropTypes.func,
};

FormikPrimarySwitch.defaultProps = {
  onValueChange: null,
  Switch: DefaultSwitch,
};

const FormikFieldPrimarySwitch = (props) => (
  <Field
    component={FormikPrimarySwitch}
    {...props}
  />
);

export default FormikFieldPrimarySwitch;
