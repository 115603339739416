import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import Routing from '../../core/routing/index';
import { getChatCredentials } from '../../common/chat/chatActions';
import GigChatView from '../../common/chat/GigChatView';
import { createOrGetCandidateChat } from './candidateChatActions';
import { redirectToErrorScreen } from '../../constants/routes';

const { withRouter } = Routing;

class CandidateGigChatContainer extends Component {
  componentDidMount() {
    this.checkChatAccountCreatedDuringSignUp();
  }

  getChatView = () => {
    const { translate, gigId, gigChatData } = this.props;
    if (!gigChatData) {
      return null;
    }
    const {
      chatId,
      companyUserId,
      companyName,
      userProfileImageUri,
      user,
    } = this.props.gigChatData;

    return (
      <GigChatView
        gigId={gigId}
        chatId={chatId}
        chatData={gigChatData}
        chatHeader={user.name || user.email}
        chatSubheader={`${gigChatData.gigPosition} @${companyName}`}
        otherUserProfileImageUri={userProfileImageUri}
        otherUserId={companyUserId}
        emptyChatPlaceholder={translate('candidateChat.emptyChatPlaceholder')}
      />
    );
  };

  handleError = () => {
    redirectToErrorScreen(this.props.history, 'errors.chatUnavailableTitle', 'errors.chatUnavailableText');
  };

  checkChatAccountCreatedDuringSignUp() {
    if (!this.props.chatToken) {
      this.props.getChatCredentials().then((response) => {
        if (response.isError) {
          this.handleError();
        } else {
          this.checkChatData();
        }
      });
    } else {
      this.checkChatData();
    }
  }

  checkChatData() {
    if (!this.props.gigChatData) {
      this.props.createOrGetCandidateChat(this.props.gigId)
        .then((response) => {
          if (response.isError) {
            this.handleError();
          }
        });
    }
  }

  render() {
    return (
      <>
        {this.getChatView()}
      </>

    );
  }
}

CandidateGigChatContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  createOrGetCandidateChat: PropTypes.func.isRequired,
  getChatCredentials: PropTypes.func.isRequired,
  chatToken: PropTypes.string,
  gigId: PropTypes.string.isRequired,
  gigChatData: PropTypes.shape({
    chatId: PropTypes.string.isRequired,
    companyUserId: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    gigPosition: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userProfileImageUri: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      email: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

CandidateGigChatContainer.defaultProps = {
  gigChatData: undefined,
  chatToken: undefined,
};

const mapStateToProps = (state, props) => {
  const gigId = props.match.params.id;
  const gigChatData = state.chat.get('gigsChatData').get(gigId);

  return {
    gigId,
    gigChatData: gigChatData ? gigChatData.toJS() : undefined,
    chatToken: state.chatAuth.get('chatToken'),
  };
};

export default withRouter(connect(mapStateToProps, { createOrGetCandidateChat, getChatCredentials })(withLocalize(CandidateGigChatContainer)));
