import React, { PureComponent } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import PrimaryPicker from './PrimaryPicker';
import { spacings, standardSpacings } from '../../styles/base.style';
import StaticFormLabel from './StaticFormLabel';
import { formBorderBottomWidth } from '../../styles/form.style';
import FormFieldError from './FormFieldError';

const styles = StyleSheet.create({
  container: {
    marginBottom: standardSpacings.formVertical,
  },
  flexed: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  pickersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  label: {
    marginRight: spacings.sm,
    marginBottom: Platform.select({
      // we cannot style android picker and it's rendered differently than expected
      android: spacings.xxs + formBorderBottomWidth + 8,
      ios: spacings.xxs + formBorderBottomWidth,
      web: 0,
    }),
    alignSelf: 'flex-end',
    width: 70,
    textAlign: 'right',
  },
});

const pickerInputStyleMonth = StyleSheet.create({
  container: {
    marginRight: spacings.xs,
    marginBottom: 0,
  },
  input: {
    width: Platform.select({ android: 100, ios: 80, web: 80 }),
  },
});

const pickerInputStyleYear = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
  input: {
    width: Platform.select({ android: 140, ios: 120, web: 120 }),
  },
});

const pickerStyle = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
});

const momentFirstDayOfMonth = {
  d: 1,
  h: 0,
  m: 0,
  s: 0,
  ms: 0,
};

const MIN_YEAR = 1940;
const MAX_YEAR = 2099;

const years = [];
const months = [];

class MonthYearPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.setDefaultValueIfNeeded();
  }

  componentDidUpdate() {
    this.setDefaultValueIfNeeded();
  }

  onMonthChange = (val) => {
    const { value, onValueChange } = this.props;
    const currentValue = value;
    const newValue = moment.utc({
      y: currentValue ? moment.utc(currentValue).year() : this.getDefaultYear(),
      M: val,
      ...momentFirstDayOfMonth,
    }).utc().valueOf();

    onValueChange(newValue);
  };

  onYearChange = (val) => {
    const { value, onValueChange } = this.props;

    const currentValue = value;
    const newValue = moment.utc({
      y: val,
      M: currentValue ? moment.utc(currentValue).month() : this.getDefaultMonth(),
      ...momentFirstDayOfMonth,
    }).valueOf();
    onValueChange(newValue);
  };

  setDefaultValueIfNeeded = () => {
    if (!this.props.value && !this.props.nullable) {
      this.onMonthChange(this.getDefaultMonth());
      this.onYearChange(this.getDefaultYear());
    }
  };

  getDefaultMonth = () => 0;

  getDefaultYear = () => moment().utc().year();

  getMonthFromValue = () => {
    const { value } = this.props;

    // although we set these defaults in componentWillMount we need some values for render until it gets updated
    if (!value) {
      if (this.props.nullable) {
        return null;
      }
      return this.getDefaultMonth();
    }
    return moment(value).utc().month();
  };

  getYearFromValue = () => {
    const { value } = this.props;

    // although we set these defaults in componentWillMount we need some values for render until it gets updated
    if (!value) {
      if (this.props.nullable) {
        return null;
      }
      return this.getDefaultYear();
    }
    return moment(value).utc().year();
  };

  getMonths = () => {
    if (!months.length) {
      const now = moment();
      for (let i = 0; i <= 11; i += 1) {
        months.push({ label: now.month(i).format('MMMM'), value: i });
      }
    }
    if (Platform.OS !== 'ios' && this.props.nullable) {
      const monthsCopy = months.slice();
      monthsCopy.unshift({ label: '', value: null });
      return monthsCopy;
    }
    return months;
  };

  getYears = () => {
    if (!years.length) {
      for (let i = MIN_YEAR; i <= MAX_YEAR; i += 1) {
        years.push({ label: `${i}`, value: i });
      }
    }
    const yearsCopy = years.slice();
    if (Platform.OS !== 'ios' && this.props.nullable) {
      yearsCopy.unshift({ label: '', value: null });
    }
    if (this.props.withFuture) {
      return yearsCopy;
    }
    return yearsCopy.filter((item) => item.value <= moment().year());
  };

  render() {
    const {
      translate, error, enabled, label, testID, nullable,
    } = this.props;

    return (
      <>
        <View style={styles.container}>
          <View style={styles.flexed}>
            <StaticFormLabel
              label={label}
              style={styles.label}
            />
            <View style={styles.pickersContainer}>
              <PrimaryPicker
                style={pickerStyle}
                inputStyle={pickerInputStyleMonth}
                label={translate('candidateProfileCreator.monthAndYearPicker.year')}
                onValueChange={this.onYearChange}
                selectedValue={this.getYearFromValue()}
                enabled={enabled}
                items={this.getYears()}
                testID={`${testID}YearPicker`}
                nullable={nullable}
                defaultValue={this.getDefaultYear()}
              />
              <PrimaryPicker
                style={pickerStyle}
                inputStyle={pickerInputStyleYear}
                label={translate('candidateProfileCreator.monthAndYearPicker.month')}
                onValueChange={this.onMonthChange}
                selectedValue={this.getMonthFromValue()}
                enabled={enabled}
                items={this.getMonths()}
                testID={`${testID}MonthPicker`}
                nullable={nullable}
                defaultValue={this.getDefaultMonth()}
              />
            </View>
          </View>
          <FormFieldError error={error} />
        </View>
      </>
    );
  }
}

MonthYearPicker.propTypes = {
  translate: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  withFuture: PropTypes.bool,
  nullable: PropTypes.bool,
  error: PropTypes.string,
  testID: PropTypes.string,
};
MonthYearPicker.defaultProps = {
  value: null,
  error: null,
  enabled: true,
  withFuture: false,
  nullable: false,
  testID: undefined,
};

export default withLocalize(MonthYearPicker);
