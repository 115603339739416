const candidateAppTerms = `
ANSTÄLLNINGSAVTAL

1. Mellan Letsgig AB 559114-4505, Wenner-Gren Center plan 20, 113 46, Stockholm, nedan kallad (”Letsgig AB”) och den person som härmed registrerar sig som arbetssökande i tjänsten nedan kallad (”Arbetstagaren”) har denna dag träffats följande anställningsavtal.

2.	Anställning, befattning, tillträde och upphörande

2.1	Letsgig anställer härmed Arbetstagaren i befattningen som uthyrd anställd (konsultgigg). Anställningen är en särskild visstidsanställning under den tidsperiod som framgår under 2.2 under rubriken Bokade Uppdrag. Arbetet ska utföras hos Kund till Letsgig som framgår nedan. Anställningsavtalet avser endast den angivna tidsperioden och därefter upphör anställningen automatiskt. Som anställd omfattas ni under faktisk arbetstid av företagets ansvarsförsäkring.

2.2	Bokat uppdrag
Arbetstagaren har alltid rätt att välja vilka uppdrag som denne vill utföra och kan alltid välja att tacka nej till uppdrag som erbjuds via appen. När Arbetstagaren har tackat ja till ett uppdrag så förbinder sig denne att utföra uppdraget hos Letsgig’s kund på av denna i appen angiven adress. Arbetstagaren är skyldig att arbeta i Kundens system och enligt Kunds instruktioner. Arbetstagaren erhåller inför varje uppdrag information om arbetsuppgifter som ska utföras mm. och har möjlighet att kommunicera med Let’s Gigs kund via appen.
	
(a) Övertid och avbokning av bokat uppdrag: För att övertidsarbete ska ersättas måste Kunden ha beställt och genomfört betalning för önskad övertid i appen som Arbetstagaren också måste ha godkänt. Vid händelse av eventuell avbokning av arbetspass som sker senast 24 timmar innan uppdraget skulle påbörjas utgår inte ersättning för den tid som avbokats.

(b) Vid sjukdom eller frånvaro: Meddela alltid Kunden via appen så snart som möjligt så denne blir informerad om läget och har en möjlighet att försöka lösa situationen. Informera även Let’s Gig alltid via e-post på sales@letsgig.com vid sjukdom eller frånvaro.

2.3	Arbetstagaren är införstådd med att personuppgifter (såsom namn, personnummer, adress, telefonnummer, befattning, CV och därmed jämförliga uppgifter) kommer att behandlas av Letsgig, vilket bland annat innebär att personuppgifterna behandlas av bolaget. Ändamålen med behandlingen skall vara relaterade till Arbetstagarens anställning, såsom att administrera anställningsförhållandet och att planera, organisera, leda och följa upp arbetet och uppgifter för att kunna hantera system i det dagliga arbetet. 

2.4	Anställningen upphör vid anställningens utgång enligt punkt 2.1 ovan utan uppsägning. 

3.	Arbetsuppgifter och behörighet

3.1	Förhållandet mellan Letsgig och Arbetstagaren grundar sig på ömsesidig lojalitet och förtroende. Vid utövande av sin befattning har arbetstagaren att efter bästa förmåga bevaka och tillvarata Letsgigs intresse samt följa de instruktioner som Letsgig utfärdat och kan komma att utfärda.

3.2	Arbetstagaren skall vid vart tillfälle följa de direktiv och anvisningar som från tid till annan lämnas av närmaste chef.

4.	Arbetstid

4.1	Tjänsten är en timanställning motsvarande det som framgår i punkten 2.2 bokat uppdrag. Arbetsgivaren bestämmer om arbetstidens förläggning och bestämmer hur arbetstiden ska hanteras i samband med t.ex helgdagar.
Arbetet är initialt förlagt över den tidsperiod med timmar som framgår enligt punkten 2.2 bokat uppdrag enligt ovan. Eventuell lunch är obetald arbetstid. 
Den exakta schemaläggningen meddelas löpande i förväg av Letsgig med skälig framförhållning. Arbetstagaren har alltid rätt att acceptera ett bokat uppdrag.
Arbetstagaren åtar sig att vara lojal mot Letsgig i alla avseenden som rör bokade uppdrag och dess utförande.

5.	Lön och annan ersättning

5.1	Arbetstagaren är inte ansluten till något kollektivavtal och den aktuella timlönen för respektive uppdrag kan förhandlas mellan Arbetstagaren och Letsgigs slutkund innan uppdraget accepteras.
Lönen betalas i efterskott senast den 25:e i varje månad. För övertidsarbete, restid eller annan övrig tidsåtgång utgår ingen särskild ersättning.Arbetstagaren har möjlighet att begära ”expressutbetalning” om denne t.ex. vill ha sin lön utbetald nästkommande vardag men då tillkommer en extra avgift som beslutas av Letsgig som Arbetstagaren alltid kan ta ställning till i förväg innan denne beslutar om denne vill ha en expressutbetalning eller invänta nästkommande lönekörning.

5.5	Arbetstagaren förväntas i normalfallet inte göra några utlägg alls för Arbetsgivaren. För eventuell ersättning av utlägg skall Arbetstagaren alltid i förväg erhålla Letsgig skriftliga godkännande och redovisa kvitton eller andra underlag som kan godkännas av Letsgigs revisor.

5.5	OB-ersättning
OB-ersättning utgår ej, istället har varje uppdrag specifik timlön angiven som Arbetstagaren får ta ställning till innan denne väljer att acceptera ett uppdrag.

6.	Semester 

6.1	Arbetstagaren avstår från semesterledighet. Semesterersättning utgår enligt lag, fn 12% och utbetalas i samband med löneutbetalning.  

7.	Sekretess och konfidentiell information

7.1	Detta avtal och dess innehåll är konfidentiellt och båda parter förpliktar sig att inte delge innehållet i avtalet, så som till exempel information om lön eller annan ersättning, till andra än parterna.

7.2	Det i punkt 7.1 ovan angivna åtagandena skall inte i något avseende anses utgöra begränsning i förhållande till tvingande lagstiftning.

8. 	Samtycke från vårdnadshavare
Enligt 6 kap 12 § Föräldrabalken (FB) får Arbetstagare som är under 18 år självt ingå avtal om anställning eller annat arbete men endast om vårdnadshavaren samtycker till avtalet.

BILAGA TILL ANSTÄLLNINGSAVTAL

Anställningsavtalet avser en anställning under den dag/de dagar som anges i avtalet. 

Om arbetsgivaren och Arbetstagaren efter nämnda dag kommer överens om att Arbetstagaren utför arbete vid ytterligare tillfälle är parterna överens om att ett nytt anställningsavtal ingås för varje sådant tillfälle. Varje eventuellt sådant anställningsavtal ska anses ha det innehåll som framgår av detta anställningsavtal, vilket bland annat innebär att Arbetstagaren har rätt till lön för de tillfällen då avtal om arbete träffas, dvs när Arbetstagaren är schemalagd. 

Schemaläggning av Arbetstagaren sker genom överenskommelse mellan parterna och kan omfatta ett antal timmar, 1 arbetsdag eller flera efterföljande arbetsdagar. Semesterlön betalas i form av Semesterersättning enligt lag, fn 12% och utbetalas i samband med löneutbetalning.

Mellan arbetsinsatserna föreligger ingen anställning. Arbetstagaren har ingen skyldighet att acceptera erbjudanden om anställning utan kan tacka nej vid varje tillfälle hon/han tillfrågas. Vid beräkning av anställningstid enligt lagen om anställningsskydd inräknas endast de tillfällen då Arbetstagaren har utfört arbete.

Detta avtal har upprättats elektroniskt och godkänts av båda parter, varav parterna erhåller var sin kopia. Detta avtal gäller när en kund till Let’s Gig väljer att anlita en Arbetstagare via appen och dessutom väljer att anställningen ska gå via Let’s Gig. 

Detta avtal gäller inte om kunden har valt att själv anställa Arbetstagaren i egen regi. I sådana fall så har Let’s Gig inget arbetsgivaransvar eller liknande utan agerar endast förmedlare mellan Arbetstagare och Arbetsgivare (Kunden) och det är då upp till parterna att själv upprätta kontrakt kring anställningen, sköta betalning av löner, skatter och allt övrigt som rör anställningen.
`;

export default candidateAppTerms;
