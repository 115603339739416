import React, { Component } from 'react';
import {
  ActivityIndicator, ScrollView, StyleSheet, Text, View,
} from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import PrimaryModal from '../../../common/components/modal/PrimaryModal';
import ModalFooter from '../../../common/components/modal/ModalFooter';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../../../common/components/buttons/Button';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import BaseListItem from '../../../common/components/BaseListItem';
import PaymentPlanItem from './PaymentPlanItem';
import { colors, spacings, standardColors } from '../../../common/styles/base.style';
import { getPricingPlans } from '../companyPaymentsActions';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: spacings.sm,
  },
  subheader: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacings.sm,
    marginHorizontal: spacings.xxl,
  },
  subheaderText: {
    textAlign: 'center',
  },
  scroll: {
    backgroundColor: standardColors.listBackground,
  },
  item: {
    marginBottom: spacings.sm,
    borderColor: 'transparent',
    borderWidth: 2,
  },
  selectedItem: {
    borderColor: colors.magenta,
    borderWidth: 2,
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const SUBSCRIPTION = 'subscription';
const PAY_PER_GIG_BASIC = 'payPerGig';
const PAY_PER_GIG_ENTERPRISE = 'payPerGigEnterprise';

class PaymentPlansModal extends Component {
  constructor(props) {
    super(props);
    this.state = { paymentPlans: null };
  }

  componentDidMount() {
    this.props.getPricingPlans().then(({ payload, isError }) => {
      if (!isError) {
        this.setState({ paymentPlans: payload.paymentPlans });
      }
    });
  }

  onNext = (selectedPaymentPlanType) => {
    const { onRedirectToPricingPlan, redirectToBillingProfile, gigId } = this.props;
    if (selectedPaymentPlanType === SUBSCRIPTION) {
      onRedirectToPricingPlan();
    }
    if ([PAY_PER_GIG_BASIC, PAY_PER_GIG_ENTERPRISE].includes(selectedPaymentPlanType)) {
      redirectToBillingProfile(gigId, selectedPaymentPlanType);
    }
  };

  render() {
    const {
      gigId,
      visible,
      translate,
      closeModal,
      selectPaymentPlan,
      selectedPaymentPlanType,
    } = this.props;

    return (
      <PrimaryModal
        visible={visible}
        handleCancel={closeModal}
        header={translate('company.payments.gig.modalHeader')}
        headerProps={{
          showBackArrow: true,
          shadow: true,
          subheaderFunc: () => (
            <View style={[styles.subheader]}>
              <Text style={styles.subheaderText}>{translate('company.payments.gig.modalSubheader')}</Text>
            </View>
          ),
        }}
      >
        {!this.state.paymentPlans
          ? <ActivityIndicator size="large" color={colors.magenta} style={styles.spinner} />
          : (
            <>
              <ScrollView contentContainerStyle={{ flex: 1 }} style={styles.scroll}>
                <PrimaryFlatList
                  emptyListTextKey={translate('emptyList')}
                  data={this.state.paymentPlans}
                  keyExtractor={(paymentPlan) => paymentPlan.type}
                  renderItem={({ item: paymentPlan }) => (
                    <BaseListItem
                      onPress={() => selectPaymentPlan(paymentPlan.type)}
                      style={[styles.item, selectedPaymentPlanType === paymentPlan.type && styles.selectedItem]}
                      hideArrow
                      renderMainContent={() => (
                        <PaymentPlanItem
                          key={paymentPlan.type}
                          paymentPlan={paymentPlan}
                          translate={translate}
                          isSelected={selectedPaymentPlanType === paymentPlan.type}
                        />
                      )}
                      key={paymentPlan.key}
                    />
                  )}
                />
              </ScrollView>
              <ModalFooter>
                <Button
                  size={BUTTON_SIZES.MD}
                  type={BUTTON_TYPES.SECONDARY}
                  title={this.props.translate('generic.cancel')}
                  onPress={closeModal}
                />
                <Button
                  size={BUTTON_SIZES.MD}
                  type={BUTTON_TYPES.PRIMARY_RED}
                  title={this.props.translate('generic.next')}
                  disabled={!selectedPaymentPlanType || !gigId}
                  onPress={() => this.onNext(selectedPaymentPlanType)}
                />
              </ModalFooter>
            </>
          )}
      </PrimaryModal>
    );
  }
}

PaymentPlansModal.propTypes = {
  translate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectPaymentPlan: PropTypes.func.isRequired,
  getPricingPlans: PropTypes.func.isRequired,
  gigId: PropTypes.string,
  selectedPaymentPlanType: PropTypes.string,
  onRedirectToPricingPlan: PropTypes.func.isRequired,
  redirectToBillingProfile: PropTypes.func.isRequired,
};

PaymentPlansModal.defaultProps = {
  selectedPaymentPlanType: undefined,
  gigId: undefined,
};

export default connect(null, { getPricingPlans })(withLocalize(PaymentPlansModal));
