import PropTypes from 'prop-types';

export const poolListEntryPropType = PropTypes.shape({
  poolCandidateId: PropTypes.string.isRequired,
  company: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  companyUser: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  invitationDate: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
});

export const poolPropType = PropTypes.shape({
  poolCandidateId: PropTypes.string,
  company: PropTypes.shape({
    companyId: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  companyUser: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  status: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
});
