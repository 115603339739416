import {
  StyleSheet, View, Platform,
} from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { shadowBottom, spacings } from '../../styles/base.style';
import * as propTypes from '../../../constants/propTypes';
import BackButton, { themes } from '../navigation/BackButton';
import Header from './Header';

import ViewportController from '../ViewportController';
import NavigationRightButton from '../navigation/NavigationRightButton';
import NavigationSaveButton from '../navigation/NavigationSaveButton';
import RewindButton from '../navigation/RewindButton';

const cancelImg = require('../../../assets/icons/header/close.png');
const filtersIcon = require('../../../assets/icons/header/filters.png');
const editImg = require('../../../assets/icons/header/edit.png');
const settingsImg = require('../../../assets/icons/header/settings.png');
const menuImg = require('../../../assets/icons/header/menu.png');

export const RIGHT_BUTTONS = {
  CANCEL: (onPress) => (<NavigationRightButton onPress={onPress} icon={cancelImg} />),
  FILTERS: (onPress) => (<NavigationRightButton onPress={onPress} icon={filtersIcon} iconWidth={21} paddingRight={1} />),
  EDIT: (onPress) => (<NavigationRightButton onPress={onPress} icon={editImg} />),
  SETTINGS: (onPress) => (<NavigationRightButton onPress={onPress} icon={settingsImg} />),
  MENU: (onPress) => (<NavigationRightButton onPress={onPress} icon={menuImg} />),
  SAVE: (onPress) => (<NavigationSaveButton onPress={onPress} />),
};

const styles = StyleSheet.create({
  container: {
    paddingTop: spacings.sm,
    alignItems: 'center',
    ...Platform.select({
      web: {
        paddingTop: spacings.xl,
      },
    }),
  },
  header: {
    // letterSpacing: 0.2,
    marginBottom: spacings.sm,
    ...Platform.select({
      web: {
        marginBottom: spacings.xl,
      },
    }),
  },
  leftButton: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
  },
});

const ScreenHeader = ({
  title, showBackArrow, onRewind, safeAreaTop, subheaderFunc, handleBack, theme, rightButton, handlerForRightButton, containerStyle, headerStyle, shadow,
  headerProps,
}) => (
  <ViewportController safeAreaTop={safeAreaTop} noFlex style={shadow ? shadowBottom : undefined}>
    <View style={[styles.container, containerStyle, Platform.OS === 'web' && shadowBottom]}>
      <View style={styles.leftButton}>
        {showBackArrow && (<BackButton onPress={handleBack} theme={theme} noSafeArea />)}
        {onRewind && (<RewindButton onPress={onRewind} theme={theme} noSafeArea />)}
      </View>
      <Header {...headerProps} style={[styles.header, headerStyle]}>{title}</Header>
      {rightButton && rightButton(handlerForRightButton)}
      {subheaderFunc
      && (
      <View style={{ width: '100%' }}>
        {subheaderFunc()}
      </View>
      )}
    </View>
  </ViewportController>
);

ScreenHeader.propTypes = {
  title: PropTypes.string,
  showBackArrow: PropTypes.bool,
  onRewind: PropTypes.func,
  safeAreaTop: PropTypes.bool,
  rightButton: PropTypes.func,
  subheaderFunc: PropTypes.func,
  handlerForRightButton: PropTypes.func,
  handleBack: PropTypes.func,
  theme: PropTypes.oneOf(Object.values(themes)),
  containerStyle: propTypes.stylePropTypes,
  headerStyle: propTypes.stylePropTypes,
  shadow: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  headerProps: PropTypes.object,
};

ScreenHeader.defaultProps = {
  rightButton: () => {},
  handlerForRightButton: () => { console.warn('Handler for right button not implemented'); },
  showBackArrow: false,
  onRewind: null,
  safeAreaTop: true,
  subheaderFunc: undefined,
  handleBack: undefined,
  theme: undefined,
  title: undefined,
  containerStyle: {},
  headerStyle: {},
  shadow: false,
  headerProps: {},
};

export default ScreenHeader;
