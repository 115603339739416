import {
  COMPANY_CANDIDATES_POOL_PROFILE_GET_REQUEST,
  COMPANY_CANDIDATES_POOL_PROFILE_SAVE_REQUEST,
  COMPANY_CANDIDATES_POOL_PROFILE_UPLOAD_PHOTO_REQUEST,
} from '../../../constants/actionTypes';
import { uploadImage } from '../../../core/client/imageUploadClient';

export function getPoolProfile() {
  return {
    type: COMPANY_CANDIDATES_POOL_PROFILE_GET_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: '/company/candidatesPool/profile',
      },
    },
  };
}

export function savePoolProfile(poolProfile) {
  return {
    type: COMPANY_CANDIDATES_POOL_PROFILE_SAVE_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: '/company/candidatesPool/profile',
        data: poolProfile,
      },
    },
  };
}

export const uploadPoolProfilePhoto = (imageUri) => uploadImage(imageUri, 'company/candidatesPool/profile/photo',
  COMPANY_CANDIDATES_POOL_PROFILE_UPLOAD_PHOTO_REQUEST);
