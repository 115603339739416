import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../../../common/components/index';
import ProfileCreatorHeader from '../../ProfileCreatorHeader';
import CandidateProfileLocationForm from '../../../forms/location/CandidateProfileLocationForm';
import { candidateLocationPropType } from '../../../../../constants/propTypes';
import StickyButtonsWrapper from '../../../../../common/components/StickyButtonsWrapper';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const ProfileCreatorStepLocationScreen = ({
  translate, cities, location, handleSubmit, stepNumber, nextScreen, redirect,
}) => (
  <View style={styles.container}>
    <ProfileCreatorHeader
      stepNo={stepNumber}
      title={translate('candidateProfileCreator.location.header')}
      infoText={translate('candidateProfileCreator.location.locationSubLabel')}
    />
    <CandidateProfileLocationForm
      cities={cities}
      location={location}
      handleSubmit={handleSubmit}
      renderFooter={({ handleSubmitFormik, isBtnDisabled }) => (
        <StickyButtonsWrapper>
          <LinkButton
            to={nextScreen}
            title={translate('generic.next')}
            doRedirect={redirect}
            disabled={isBtnDisabled}
            onPress={handleSubmitFormik}
          />
        </StickyButtonsWrapper>
      )}
    />
  </View>
);

ProfileCreatorStepLocationScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  location: candidateLocationPropType,
  cities: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,

  handleSubmit: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  nextScreen: PropTypes.string.isRequired,
};

ProfileCreatorStepLocationScreen.defaultProps = {
  location: undefined,
};

export default withLocalize(ProfileCreatorStepLocationScreen);
