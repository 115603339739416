import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../common/components/header/ScreenHeader';
import CandidateProfileViewDetailsView from '../../../common/components/candidate_profile/view/CandidateProfileViewDetailsView';
import Routing from '../../../core/routing/index';
import {
  colors, fontFamily, fontSizes, shadowTop, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import CandidateActionSheet from '../../action_sheet/CandidateActionSheet';
import { COMPANY_CANDIDATES_POOL_DIRECT_CHAT, redirectToErrorScreen } from '../../../constants/routes';
import { getCandidateProfile } from './candidateActions';
import { invite, remove } from '../companyCandidatesPoolActions';
import { successNotification } from '../../../common/notifications_center/notificationCenterActions';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonsWrapper: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  onlineIndicator: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacings.xxs,
    paddingHorizontal: spacings.xxs,
    borderRadius: 16,
    height: 32,
    borderColor: colors.greenishTeal,
    borderWidth: 2,
  },
  onlineIndicatorText: {
    backgroundColor: colors.colgateWhite,
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0.2, colors.tealish),
  },
  actionsSectionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: spacings.xs,
    ...shadowTop,
  },
});

class CandidatesPoolCandidateProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadCandidateProfile();
  }

  onRemoveCandidateFromPool(poolCandidateId) {
    this.props.removeCandidateFromPool(poolCandidateId).then(() => {
      this.props.successNotification('Candidate has been successfully removed.');
      this.setState({ profile: null });
      this.loadCandidateProfile();
    });
  }

  onInviteCandidateToPool() {
    this.props.inviteCandidateToPool(this.state.profile.userId).then(() => {
      this.props.successNotification('Candidate has been successfully invited.');
      this.setState({ profile: null });
      this.loadCandidateProfile();
    });
  }

  onStartDirectChat(poolCandidateId) {
    this.props.history.push(COMPANY_CANDIDATES_POOL_DIRECT_CHAT.replace(':poolCandidateId', poolCandidateId));
  }

  registerOpenActionSheet = (openActionSheet) => {
    this.openActionSheet = openActionSheet;
  };

  loadCandidateProfile() {
    this.props.getCandidateProfile(this.props.match.params.candidateId).then((res) => {
      if (!res.isError) {
        this.setState({ profile: res.payload });
      } else if (res && res.status === 404) {
        redirectToErrorScreen(this.props.history, 'errors.notFoundTitle', 'errors.notFoundText');
      }
    });
  }

  renderProfileView() {
    return (<CandidateProfileViewDetailsView profile={this.state.profile} online={this.props.online} />);
  }

  render() {
    const { translate } = this.props;
    const { profile } = this.state;
    return (
      <View style={styles.container}>
        <ScreenHeader
          title={translate('candidateProfile.details.view')}
          showBackArrow
          rightButton={RIGHT_BUTTONS.MENU}
          handlerForRightButton={this.openActionSheet}
        />
        {profile ? this.renderProfileView() : (<ActivityIndicator />)}
        <CandidateActionSheet
          registerOpenActionSheetFunction={this.registerOpenActionSheet}
          candidate={profile}
          onRemove={(poolCandidateId) => this.onRemoveCandidateFromPool(poolCandidateId)}
          onInviteCandidateToPool={(candidateId) => this.onInviteCandidateToPool(candidateId)}
          onStartDirectChat={(poolCandidateId) => this.onStartDirectChat(poolCandidateId)}
        />
      </View>
    );
  }
}

CandidatesPoolCandidateProfileContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  getCandidateProfile: PropTypes.func.isRequired,
  removeCandidateFromPool: PropTypes.func.isRequired,
  inviteCandidateToPool: PropTypes.func.isRequired,
  successNotification: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  online: PropTypes.bool,
};

CandidatesPoolCandidateProfileContainer.defaultProps = {
  online: null,
};

export default connect(null, {
  getCandidateProfile,
  inviteCandidateToPool: invite,
  removeCandidateFromPool: remove,
  successNotification,
})(withRouter(withLocalize(CandidatesPoolCandidateProfileContainer)));
