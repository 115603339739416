import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import AsyncStorage from '@react-native-async-storage/async-storage';
import rootClearingReducer from './rootClearingReducer';
import reduxSimpleLogger from './reduxSimpleLogger';
import axiosMiddleware from '../client/axiosRequestsMiddleware';
import migrations from './migrations';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'letsgig-persistance',
  storage: AsyncStorage,
  version: 3,
  migrate: createMigrate(migrations, { debug: false }),
  // if you persist data, remember to remove it upon logout or session timeout (see rootClearingReducer)
  whitelist: ['auth', 'locale', 'notifications', 'candidate', 'rateApp', 'log', 'featureTracking'],
};

const clearWhitelist = ['auth', 'locale', 'notifications', 'gigCategories', 'appStateData', 'internetConnection', 'updateData', 'rateApp', 'log',
  'featureTracking'];

const persistedReducer = persistReducer(persistConfig, rootClearingReducer(clearWhitelist));

/*
  although we use Immutable objects for substates, the root object has to be a simple JS object
  because of limitations of redux-persist-transform-immutable
*/

const initialState = {};
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, axiosMiddleware, reduxSimpleLogger)),
);
const persistor = persistStore(store);

export { store, persistor };
