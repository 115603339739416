import React from 'react';
import PropTypes from 'prop-types';
import WebImageWithFallback from './WebImageWithFallback';
import * as propTypes from '../../../constants/propTypes';

const ImageSelectorComponent = ({ uri, defaultImage, style }) => (
  <WebImageWithFallback uri={uri} fallbackImage={defaultImage} style={style.image} />
);

ImageSelectorComponent.propTypes = {
  defaultImage: propTypes.imagePropType.isRequired,
  uri: PropTypes.string,
  style: propTypes.stylePropTypes.isRequired,
};

ImageSelectorComponent.defaultProps = {
  uri: undefined,
};

export default ImageSelectorComponent;
