import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import CandidateProfileViewDetailsView from '../../../common/components/candidate_profile/view/CandidateProfileViewDetailsView';
import { candidateProfilePropType } from '../../../constants/propTypes';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../common/components/header/ScreenHeader';
import Container from '../../../common/components/Container';
import ProfileInfo, { renderEmail } from '../../../common/components/ProfileInfo';
import { isCompletedOrRequiredCompleted } from '../../../constants/profileCompletenessStatus';
import CompleteProfileButton from './CompleteProfileButton';
import Wrapper from '../../../common/components/Wrapper';
import PrimarySwitch from '../../../common/components/form/switch/PrimarySwitch';
import { colors, fontFamily, fontSizes, widthSpacingsBolek } from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import { getMissingProfilePropsNumber } from '../profileHelper';

const editIcon = require('../../../assets/icons/edit/editIcon.png');

const renderExtraInfo = (profile) => <>{renderEmail(profile)}</>;

const styles = StyleSheet.create({
  editIconWrapper: {
    position: 'absolute',
    bottom: widthSpacingsBolek.p40,
    right: widthSpacingsBolek.p40,
  },
  editIcon: {
    width: widthSpacingsBolek.p160,
    height: widthSpacingsBolek.p160,
  },
  profileIncompleteIndicator: {
    position: 'absolute',
    width: 20,
    height: 20,
    right: 0,
    bottom: 0,
    backgroundColor: colors.seablue,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  profileIncompleteMsg: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxxs, -0.5, colors.white),
  },
});

const CandidateProfileViewDetailsScreen = ({
  translate,
  profile,
  available,
  onSettingsButton,
  onEditProfile,
  onEditProfileImage,
  onChangeAvailable,
}) => (
  <Container>
    <ScreenHeader title={translate('candidateMyProfile.header')} rightButton={RIGHT_BUTTONS.SETTINGS} handlerForRightButton={onSettingsButton} />
    {profile && isCompletedOrRequiredCompleted(profile.profileCompleteness) && (
      <Container>
        <CandidateProfileViewDetailsView
          profile={profile}
          renderExtraInfo={renderExtraInfo}
          profileInfoProps={{
            editImage: true,
            editProfileImageAction: onEditProfileImage,
          }}
          availability={
            <PrimarySwitch label={translate('candidateProfile.availableForGigs.label')} value={available} onValueChange={onChangeAvailable} />
          }
        />
        <TouchableOpacity style={styles.editIconWrapper} onPress={onEditProfile}>
          <Image source={editIcon} style={styles.editIcon} resizeMode="contain" />
          {getMissingProfilePropsNumber(profile) > 0 && (
            <View style={styles.profileIncompleteIndicator}>
              <Text style={styles.profileIncompleteMsg}>{getMissingProfilePropsNumber(profile)}</Text>
            </View>
          )}
        </TouchableOpacity>
      </Container>
    )}
    {!(profile && isCompletedOrRequiredCompleted(profile.profileCompleteness)) && (
      <Wrapper>
        <ProfileInfo profile={profile} renderExtraInfo={renderExtraInfo} editImage editProfileImageAction={onEditProfileImage} online />
        <CompleteProfileButton profile={profile} />
        <TouchableOpacity style={styles.editIconWrapper} onPress={onEditProfile}>
          <Image source={editIcon} style={styles.editIcon} resizeMode="contain" />
          {getMissingProfilePropsNumber(profile) > 0 && (
            <View style={styles.profileIncompleteIndicator}>
              <Text style={styles.profileIncompleteMsg}>{getMissingProfilePropsNumber(profile)}</Text>
            </View>
          )}
        </TouchableOpacity>
      </Wrapper>
    )}
  </Container>
);

CandidateProfileViewDetailsScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  onSettingsButton: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onEditProfileImage: PropTypes.func.isRequired,
  onChangeAvailable: PropTypes.func.isRequired,
  profile: candidateProfilePropType,
  available: PropTypes.bool.isRequired,
};

CandidateProfileViewDetailsScreen.defaultProps = {
  profile: undefined,
};

export default withLocalize(CandidateProfileViewDetailsScreen);
