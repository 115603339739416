import React from 'react';
import {
  View, Text, StyleSheet, ScrollView,
} from 'react-native';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import PrimaryModal from '../components/modal/PrimaryModal';
import { colors, spacings } from '../styles/base.style';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacings.sm,
  },
  log: {
    paddingVertical: spacings.xxs,
  },
  logMsg: {
    backgroundColor: colors.veryLightGrey,
    borderWidth: 1,
    padding: 2,
  },
});

const LogsModal = ({
  visible, closeModal, logs,
}) => (
  <PrimaryModal
    header="Logs"
    visible={visible}
    handleCancel={closeModal}
    headerProps={{ showBackArrow: true, shadow: true }}
  >
    <ScrollView contentContainerStyle={styles.container}>
      {logs.sort((l1, l2) => l2.ts > l1.ts).map((log) => (
        <View key={log.ts} style={styles.log}>
          <Text selectable>{moment(log.ts).format('YYYY-MM-DD HH:mm:ss.SSS')}</Text>
          <Text selectable style={styles.logMsg}>{log.msg}</Text>
        </View>
      ))}
    </ScrollView>
  </PrimaryModal>
);

LogsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  logs: PropTypes.arrayOf(PropTypes.shape({
    msg: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    ts: PropTypes.any,
  })).isRequired,
};

const mapStateToProps = (state) => ({
  logs: state.log.get('logs').toJS(),
});

export default connect(mapStateToProps, null)(LogsModal);
