import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import {
  colors, fontFamily, fontSizes, shadowBottom,
} from '../../common/styles/base.style';
import ScreenHeader from '../../common/components/header/ScreenHeader';
import {
  CANDIDATE_MY_POOLS,
  CANDIDATE_MY_POOLS_POOLS,
  CANDIDATE_MY_POOLS_SHIFTS,
} from '../../constants/routes';
import TabBar from '../../common/components/tabs/TabBar';
import { font } from '../../common/styles/mixins';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';
import { CANDIDATE_USER } from '../../constants/userScopes';
import CandidateMyPoolsContainer from './pools/CandidateMyPoolsContainer';
import { CANDIDATES_POOLS_CONTENT_FIELDS } from './candidateMyPoolsReducer';
import CandidateMyPoolsShiftsContainer from './shifts/CandidateMyPoolsShiftsContainer';
import { POOL_CHAT_TYPE } from '../../common/chat/pool/poolChatReducerHelper';

const { Route, Redirect } = Routing;

const styles = StyleSheet.create({
  header: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxxl, null, colors.greyishBrown),
  },
});

class CandidateMyPoolsTabsContainer extends React.PureComponent {
  render() {
    const {
      translate,
      newGroupChatContent,
      newDirectChatContent,
      newMyPoolsContent,
      newShiftsContent,
      grantedFeatures,
    } = this.props;

    const tabs = [
      {
        textKey: 'candidate.myPools.myPoolTab',
        link: CANDIDATE_MY_POOLS_POOLS,
        isNewContent: newMyPoolsContent || newGroupChatContent || newDirectChatContent,
      },
    ];

    if (grantedFeatures.includes('CANDIDATES_POOL_SHIFTS')) {
      tabs.push({
        textKey: 'candidate.myPools.shiftsTab',
        link: CANDIDATE_MY_POOLS_SHIFTS,
        isNewContent: newShiftsContent,
      });
    }

    return (
      <>
        <View style={shadowBottom}>
          <ScreenHeader title={translate('candidate.myPools.header')} style={styles.header} />
          <Route exact path={CANDIDATE_MY_POOLS} render={() => <Redirect to={CANDIDATE_MY_POOLS_POOLS} />} />
          <TabBar items={tabs} />
        </View>
        <AuthorizedRoute exact only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS_POOLS} component={CandidateMyPoolsContainer} />
        {/* <AuthorizedRoute exact only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS_GROUPS} component={CandidateMyPoolsGroupsContainer} /> */}
        <AuthorizedRoute only={CANDIDATE_USER} path={CANDIDATE_MY_POOLS_SHIFTS} component={CandidateMyPoolsShiftsContainer} />
      </>
    );
  }
}

CandidateMyPoolsTabsContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  newGroupChatContent: PropTypes.bool.isRequired,
  newDirectChatContent: PropTypes.bool.isRequired,
  newMyPoolsContent: PropTypes.bool.isRequired,
  newShiftsContent: PropTypes.bool.isRequired,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const myPoolsContentMap = state.candidateMyPools.get('newContent').toJS();
  const poolChatsData = Object.values(state.poolChat.get('poolChatsData').toJS());
  return ({
    grantedFeatures: state.grantedFeatures.get('grantedFeatures').toJS(),
    newGroupChatContent: poolChatsData.find((chat) => chat.unread && chat.type === POOL_CHAT_TYPE.GROUP) != null,
    newDirectChatContent: poolChatsData.find((chat) => chat.unread && chat.type === POOL_CHAT_TYPE.DIRECT) != null,
    newMyPoolsContent: myPoolsContentMap[CANDIDATES_POOLS_CONTENT_FIELDS.MY_POOLS].length > 0,
    newShiftsContent: myPoolsContentMap[CANDIDATES_POOLS_CONTENT_FIELDS.SHIFTS].length > 0,
  });
};

export default connect(mapStateToProps, {})(withLocalize(CandidateMyPoolsTabsContainer));
