import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View } from 'react-native';

class SwipeListView extends PureComponent {
  render() {
    const { data, renderItem } = this.props;
    if (!data.length) {
      return null;
    }
    return (
      <ScrollView style={this.props.style}>
        {data.map((item, index) => renderItem({ item, index }))}
      </ScrollView>
    );
  }
}

SwipeListView.propTypes = {
// eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  style: PropTypes.number,
};

SwipeListView.defaultProps = {
  style: null,
};

const SwipeRow = View;
export {
  SwipeListView,
  SwipeRow,
};
