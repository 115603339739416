import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from 'react-native';
import { Translate } from 'react-localize-redux';
import { spacings, standardColors } from '../../styles/base.style';
import { stylePropTypes } from '../../../constants/propTypes';

const styles = StyleSheet.create({
  text: {
    color: standardColors.error,
    marginTop: spacings.xxs,
    alignSelf: 'flex-start',
  },
});
const FormFieldError = ({ error, style }) => (
  (error
    && <Text style={[styles.text, style]}><Translate id={`validation.${error}`} /></Text>)
  || null
);

FormFieldError.propTypes = {
  error: PropTypes.string,
  style: stylePropTypes,
};
FormFieldError.defaultProps = {
  error: null,
  style: undefined,
};

export default FormFieldError;
