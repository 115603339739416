import jwtDecode from 'jwt-decode';
import { fromJS, List, Map } from 'immutable';
import * as chatClient from '../chat/chatClient';

import {
  CANDIDATE_SIGNUP_REQUEST_SUCCESS,
  COMPANY_AUTOCREATE_SIGNUP_REQUEST_SUCCESS,
  DELETE_ACCOUNT_REQUEST_SUCCESS,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_WITH_FACEBOOK_REQUEST_SUCCESS,
  LOGOUT_REQUEST_FAIL,
  LOGOUT_REQUEST_SUCCESS,
  SESSION_TIMEOUT,
  USER_REGISTER_INSTALLATION_FAIL,
  USER_REGISTER_INSTALLATION_SUCCESS,
  USER_VISITED_FIRST_SCREEN,
  COMPANY_SIGNUP_REQUEST_SUCCESS,
  RESET_SIGNUP_SUCCESS,
  COMPANY_SIGNUP_FORM_SUBMIT
} from '../../constants/actionTypes';

export default function authReducer(
  state = Map({
    authenticated: false,
    token: null,
    userId: null,
    userScope: null,
    timeout: false,
    loggedInAtLeastOnce: false,
    firstLogin: false,
    loginOnNewInstallation: null,
    installationRegistered: null,
    signUpSuccess: false,
    formValues: fromJS({}),
  }),
  action,
) {
  switch (action.type) {
    case LOGIN_REQUEST_SUCCESS:
    case LOGIN_WITH_FACEBOOK_REQUEST_SUCCESS: {
      chatClient.connect();
      const { scope, sub: userId } = jwtDecode(action.payload.token);

      return state.merge({
        authenticated: true,
        token: action.payload.token,
        userId,
        userScope: scope,
        timeout: false,
        loggedInAtLeastOnce: true,
        firstLogin: false,
        signUpSuccess: false,
      });
    }
    case COMPANY_SIGNUP_REQUEST_SUCCESS: {
      return state.merge({
        authenticated: false,
        token: null,
        timeout: false,
        userId: null,
        userScope: null,
        firstLogin: true,
        loggedInAtLeastOnce: true,
        loginOnNewInstallation: true,
        signUpSuccess: true,
      });
    }
    case COMPANY_AUTOCREATE_SIGNUP_REQUEST_SUCCESS:
    case CANDIDATE_SIGNUP_REQUEST_SUCCESS: {
      return state.merge({
        authenticated: false,
        token: null,
        timeout: false,
        userId: null,
        userScope: null,
        firstLogin: true,
        loggedInAtLeastOnce: true,
        loginOnNewInstallation: true,
        signUpSuccess: true,
      });
    }
    case DELETE_ACCOUNT_REQUEST_SUCCESS:
    case LOGOUT_REQUEST_SUCCESS:
    case LOGOUT_REQUEST_FAIL: {
      chatClient.disconnect();
      return state.merge({
        authenticated: false,
        token: null,
        timeout: false,
        userId: null,
        userScope: null,
        firstLogin: false,
        loginOnNewInstallation: null,
        signUpSuccess: false,
      });
    }
    case SESSION_TIMEOUT: {
      return state.merge({
        authenticated: false,
        token: null,
        timeout: true,
        userId: null,
        userScope: null,
        firstLogin: false,
        signUpSuccess: false,
      });
    }
    case USER_REGISTER_INSTALLATION_SUCCESS: {
      return state.set('loginOnNewInstallation', action.payload.isNew).set('installationRegistered', true);
    }
    case USER_REGISTER_INSTALLATION_FAIL: {
      return state.set('loginOnNewInstallation', false).set('installationRegistered', false);
    }
    case USER_VISITED_FIRST_SCREEN: {
      return state.set('firstLogin', false);
    }
    case RESET_SIGNUP_SUCCESS: {
      return state.set('signUpSuccess', false);
    }
    case COMPANY_SIGNUP_FORM_SUBMIT: {
      console.log('COMPANY_SIGNUP_FORM_SUBMITtest', JSON.stringify(action));
      return state.set('formValues', fromJS(action.formValues));
    }
    default:
      return state;
  }
}
