import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native';

import { get } from '../companyCandidatesPoolActions';
import CompanyCandidatesPoolMyPoolScreen from './CompanyCandidatesPoolMyPoolScreen';
import { activityIndicator } from '../../../common/styles/base.style';
import Container from '../../../common/components/Container';

class CompanyCandidatesPoolMyPoolContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poolCandidates: null,
    };
  }

  componentDidMount() {
    this.props.getPoolCandidates().then((res) => {
      this.setState({ poolCandidates: res.payload });
    });
  }

  render() {
    if (!this.state.poolCandidates) {
      return (
        <Container>
          <ActivityIndicator size="large" style={{ ...activityIndicator }} />
        </Container>
      );
    }
    return (
      <CompanyCandidatesPoolMyPoolScreen
        poolCandidates={this.state.poolCandidates}
      />
    );
  }
}

CompanyCandidatesPoolMyPoolContainer.propTypes = {
  getPoolCandidates: PropTypes.func.isRequired,
};

CompanyCandidatesPoolMyPoolContainer.defaultProps = {
};

export default connect(null, {
  getPoolCandidates: get,
})(CompanyCandidatesPoolMyPoolContainer);
