import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Text, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { gigPropType, stylePropTypes } from '../../../constants/propTypes';
import {
  colors, fontSizes, fontFamily, spacings,
} from '../../../common/styles/base.style';
import Container from '../../../common/components/Container';
import { font } from '../../../common/styles/mixins';
import ImageComponent from '../../../common/components/image/ImageComponent';
import GigSalary from '../GigSalary';

const locationIcon = require('../../../assets/icons/location/locationIcon.png');

const styles = StyleSheet.create({
  position: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxl),
    marginRight: spacings.xs,
    flex: 1,
  },
  companyName: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm),
  },
  locationContainer: {
    flexDirection: 'row',
    marginTop: spacings.xxs,
    alignItems: 'center',
  },
  location: {
    ...font(fontFamily.SFProTextRegular, fontSizes.xs, undefined, colors.brownGrey),
  },
  detailsInfo: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, undefined, colors.brownishGrey),
  },
  onlineIndicatorContainer: {
    marginVertical: spacings.xs,
    alignItems: 'center',
    flexDirection: 'row',
  },
  onlineIndicator: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacings.xxs,
    paddingHorizontal: 5,
    borderRadius: 10,
    height: 20,
    borderColor: colors.greenishTeal,
    borderWidth: 1,
  },
  onlineIndicatorText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xxs, 0.2, colors.tealish),
  },
});

class CandidateGigOverview extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.gig.lastUpdateDate !== this.props.gig.lastUpdateDate || nextProps.online !== this.props.online;
  }

  getGigAddress = (location) => (location.street ? `${location.city}, ${location.street}` : location.city);

  render() {
    const {
      gig, online, translate, closedGigStyle,
    } = this.props;
    return (
      <Container>
        <Text style={[styles.position, closedGigStyle]}>{gig.position}</Text>
        <View style={styles.onlineIndicatorContainer}>
          {online && (
            <View style={styles.onlineIndicator}>
              <Text style={[styles.onlineIndicatorText, closedGigStyle]}>{translate('generic.online')}</Text>
            </View>
          )}
          <Text style={[styles.companyName, closedGigStyle]}>{gig.companyName}</Text>
        </View>
        {gig.compensation.salary && <GigSalary compensation={gig.compensation} style={[{ paddingBottom: spacings.xs }, closedGigStyle]} />}
        <View style={styles.locationContainer}>
          <ImageComponent style={{ image: { width: 13, height: 19, marginRight: spacings.xxs } }} image={locationIcon} />
          <Text style={[styles.location, closedGigStyle]}>{this.getGigAddress(gig.location)}</Text>
        </View>
      </Container>
    );
  }
}

CandidateGigOverview.propTypes = {
  translate: PropTypes.func.isRequired,
  gig: gigPropType.isRequired,
  online: PropTypes.bool,
  closedGigStyle: stylePropTypes,
};

CandidateGigOverview.defaultProps = {
  online: null,
  closedGigStyle: {},
};

export default withLocalize(CandidateGigOverview);
