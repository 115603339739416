import React from 'react';
import { StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { fontFamily, fontSizes } from '../../styles/base.style';
import * as propTypes from '../../../constants/propTypes';
import { font } from '../../styles/mixins';

const styles = StyleSheet.create({
  header: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxl, 0.4),
    lineHeight: 26,
    textAlign: 'center',
  },
});

const Header = ({ children, style, ...props }) => (
  <Text style={[styles.header, style]} {...props}>{children}</Text>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  style: propTypes.stylePropTypes,
};
Header.defaultProps = {
  style: undefined,
};

export default Header;
