import {
  StyleSheet, Text, View,
} from 'react-native';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import ReactRouterPropTypes from 'react-router-prop-types';

import Container from '../../../../common/components/Container';
import Routing from '../../../../core/routing/index';
import { spacings } from '../../../../common/styles/base.style';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import { poolGroupPropType } from '../../../../constants/propTypes';
import ScreenHeader, { RIGHT_BUTTONS } from '../../../../common/components/header/ScreenHeader';
import getPoolGroupActionSheet from './CompanyCandidatesPoolGroupActionSheet';
import {
  COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE,
  COMPANY_CANDIDATES_POOL_DIRECT_CHAT,
  COMPANY_CANDIDATES_POOL_GROUP_MODIFY,
} from '../../../../constants/routes';
import ListItem from '../../../../common/components/ListItem';
import Touchable from '../../../../common/components/Touchable';
import { storeCandidatesPoolGroupMembersScrollPosition } from '../../companyCandidatesPoolActions';
import ProfileInfo from '../../../../common/components/ProfileInfo';
import PoolChatButton from '../../../../common/chat/pool/PoolChatButton';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  listItemContainer: {
    flexDirection: 'row',
    padding: spacings.sm,
    alignItems: 'flex-start',
  },
  candidateName: {
    flex: 1,
  },
  subheader: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: spacings.sm,
    marginHorizontal: spacings.xxl,
  },
  subheaderText: {
    textAlign: 'center',
  },
  profileImage: {
    width: 54,
    height: 54,
    borderRadius: 27,
  },
});

const candidateProfileInfoStyles = StyleSheet.create({
  wrapper: {
    marginBottom: spacings.xxs,
  },
  infoWrapper: {
    marginLeft: spacings.sm,
    marginRight: spacings.sm,
  },
  name: {
    marginBottom: spacings.xxs,
  },
});

class CompanyCandidatesPoolGroupViewScreen extends React.PureComponent {
  componentDidMount() {
    this.scrollPosition = 0;
    if (this.props.history.action === 'POP') {
      const { storedScrollPosition } = this.props;
      if (storedScrollPosition) {
        this.scrollToLastKnownPosition(storedScrollPosition);
      }
    } else {
      this.props.storeScrollPosition(this.scrollPosition);
    }
  }

  componentWillUnmount() {
    this.props.storeScrollPosition(this.scrollPosition);
  }

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  showPoolGroupActionSheet = () => {
    const {
      translate, deleteAction, poolGroup, history,
    } = this.props;

    const actionSheet = getPoolGroupActionSheet({
      translate,
      onDelete: () => deleteAction(),
      onModify: () => history.replace(COMPANY_CANDIDATES_POOL_GROUP_MODIFY.replace(':groupId', poolGroup.groupId)),
    });
    this.props.showActionSheetWithOptions(actionSheet.options, actionSheet.handlers);
  };

  viewProfile = (candidateId) => {
    this.props.history.push(COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE.replace(':candidateId', candidateId));
  };

  startChat = (poolCandidateId) => {
    this.props.history.push(COMPANY_CANDIDATES_POOL_DIRECT_CHAT.replace(':poolCandidateId', poolCandidateId));
  };

  scrollToLastKnownPosition(scrollPosition) {
    setTimeout(() => this.listRef.scrollToOffset({ offset: scrollPosition, animated: true }), 0);
  }

  render() {
    const {
      translate, poolGroup, userId,
    } = this.props;

    const ownerDependantActionMenuForGroup = (ownerUserId) => (ownerUserId === userId ? {
      rightButton: RIGHT_BUTTONS.MENU,
      handlerForRightButton: this.showPoolGroupActionSheet,
    } : {});

    return (
      <Container>
        <ScreenHeader
          title={poolGroup.name}
          subheaderFunc={() => (
            <View style={styles.subheader}>
              <Text style={styles.subheaderText}>{poolGroup.description}</Text>
            </View>
          )}
          showBackArrow
          shadow
          {...ownerDependantActionMenuForGroup(poolGroup.ownerUserId)}
        />
        <Container>
          <PrimaryFlatList
            emptyListTextKey={translate('company.candidatesPool.poolCandidatesList.emptyGroup')}
            data={poolGroup.candidates}
            keyExtractor={(poolGroupCandidate) => poolGroupCandidate.userId}
            innerRef={this.setListRef}
            onScroll={this.onScroll}
            renderItem={({ item: poolGroupCandidate }) => (
              <ListItem
                renderContent={() => (
                  <Touchable
                    activeOpacity={0.5}
                    onPress={() => this.viewProfile(poolGroupCandidate.userId)}
                  >
                    <View style={styles.listItemContainer}>
                      <Container>
                        <ProfileInfo
                          profile={poolGroupCandidate}
                          imageSize={styles.profileImage}
                          style={candidateProfileInfoStyles}
                          online={this.props.usersPresence[poolGroupCandidate.userId]}
                        />
                      </Container>
                      <PoolChatButton poolChatId={poolGroupCandidate.poolCandidateId} onPress={() => this.startChat(poolGroupCandidate.poolCandidateId)} />
                    </View>
                  </Touchable>
                )}
              />
            )}
          />
        </Container>
      </Container>
    );
  }
}

CompanyCandidatesPoolGroupViewScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  deleteAction: PropTypes.func.isRequired,
  showActionSheetWithOptions: PropTypes.func.isRequired,
  poolGroup: poolGroupPropType,
  storedScrollPosition: PropTypes.number.isRequired,
  storeScrollPosition: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
};

CompanyCandidatesPoolGroupViewScreen.defaultProps = {
  poolGroup: undefined,
};

const mapStateToProps = (state) => ({
  userId: state.auth.get('userId'),
  usersPresence: state.chat.get('usersPresence').toJS(),
  storedScrollPosition: state.companyCandidatesPool.get('candidatesPoolGroupMembersScrollPosition'),
});

export default connect(
  mapStateToProps,
  { storeScrollPosition: storeCandidatesPoolGroupMembersScrollPosition },
)(withLocalize(withRouter(connectActionSheet(CompanyCandidatesPoolGroupViewScreen))));
