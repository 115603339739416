import { Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {
  CANDIDATE_ACCEPT_OFFER_REQUEST,
  CANDIDATE_REJECT_REQUEST,
} from '../../constants/actionTypes';

const TRACKED_REQUESTS = [CANDIDATE_REJECT_REQUEST, CANDIDATE_ACCEPT_OFFER_REQUEST];

function reducerForRequest(requestActionName) {
  return (state = Map({ inProgress: false }), action) => {
    switch (action.type) {
      case requestActionName:
        return state.set('inProgress', true);
      case `${requestActionName}_SUCCESS`:
        return state.set('inProgress', false);
      case `${requestActionName}_FAIL`:
        return state.set('inProgress', false);
      default:
        return state;
    }
  };
}

const TRACKED_REQUESTS_MAP = TRACKED_REQUESTS.reduce((map, request) => {
// eslint-disable-next-line no-param-reassign
  map[request] = reducerForRequest(request);
  return map;
}, {});

export default combineReducers(TRACKED_REQUESTS_MAP);
