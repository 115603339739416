import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../../config/config';

class StripeCardView extends Component {
  componentDidMount() {
    this.senderOriginWhitelist = [config.mainPageUrl];
    window.addEventListener('message', this.eventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.eventHandler);
  }

  eventHandler = (event) => {
    if (!this.senderOriginWhitelist.includes(event.origin)) {
      console.warn('Sender origin not allowed.');
      return;
    }
    const { trigger, cardToken } = JSON.parse(event.data);
    this.props.onMessage({ trigger, cardToken });
  };

  render() {
    const { sourceUri } = this.props;
    return (
      <iframe
        title="tokenizecard"
        style={{ border: 0, height: '100%' }}
        src={`${sourceUri}?parentPlatform=web`}
      />
    );
  }
}

StripeCardView.propTypes = {
  onMessage: PropTypes.func.isRequired,
  sourceUri: PropTypes.string.isRequired,
};

export default StripeCardView;
