import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import { getTranslationForCategoryKey, sortCategoriesByOrder } from '../gig/gigCategoriesHelper';
import CategoryBadge from './CategoryBadge';
import { activeLanguagePropTypes, gigCategoryPropType, stylePropTypes } from '../../constants/propTypes';
import { getAvailableGigCategories } from '../gig/gigCategoriesActions';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

class CategoryBadgeList extends Component {
  componentDidMount() {
    this.props.getAvailableGigCategories();
  }

  render() {
    const {
      activeLanguage,
      availableCategories,
      categories,
      type,
      style,
    } = this.props;

    const sortedCategories = sortCategoriesByOrder(categories, availableCategories);

    return (
      <View style={[styles.container, style]}>
        {sortedCategories.map((cKey) => (
          <CategoryBadge
            type={type}
            key={cKey}
            categoryName={getTranslationForCategoryKey(availableCategories, cKey, activeLanguage)}
          />
        ))}
      </View>
    );
  }
}

CategoryBadgeList.propTypes = {
  activeLanguage: activeLanguagePropTypes.isRequired,
  getAvailableGigCategories: PropTypes.func.isRequired,
  availableCategories: PropTypes.arrayOf(gigCategoryPropType).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
  style: stylePropTypes,
};

CategoryBadgeList.defaultProps = {
  type: null,
  style: undefined,
};

const mapStateToProps = (state) => ({
  availableCategories: state.gigCategories.toJS(),
});

export default connect(mapStateToProps, { getAvailableGigCategories })(withLocalize(CategoryBadgeList));
