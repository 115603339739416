import React from 'react';
import PropTypes from 'prop-types';
import {
  Platform, Alert, StyleSheet, View, ActivityIndicator,
} from 'react-native';
import GeneralFormError from '../components/form/GeneralFormError';
import FormikSelectList from '../components/form/FormikSelectList';
import { spacings } from '../styles/base.style';
import Wrapper from '../components/Wrapper';

const styles = StyleSheet.create({
  centeredWithMargins: {
    alignItems: 'center',
    marginTop: spacings.xl,
    marginBottom: spacings.xl,
  },
  loading: {
    marginTop: spacings.lg,
    justifyContent: 'center',
  },
  loadingIcon: {
    marginTop: spacings.lg,
    justifyContent: 'center',
  },
});

class GigCategorySelectListScreen extends React.PureComponent {
  renderErrors = (errors) => (
    <View style={styles.centeredWithMargins}>
      <GeneralFormError errors={errors} />
    </View>
  );

  renderLoading = () => (
    <View style={styles.centeredWithMargins}>
      <ActivityIndicator size="large" style={styles.loadingIcon} />
    </View>
  );

  renderMaxCategoriesPopup = () => {
    const { translate } = this.props;
    const errorTitle = translate('companyGigCreator.category.selectionErrorTitle', { number: this.props.maxSelect });
    const errorBody = translate('companyGigCreator.category.selectionErrorBody', { number: this.props.maxSelect });
    const okButtonText = translate('generic.ok');

    if (Platform.OS === 'web') {
      /* eslint-disable no-alert */
      alert(errorBody);
      return false;
    }
    Alert.alert(errorTitle, errorBody, [{ text: okButtonText }], { cancelable: true });
    return false;
  };

  renderList = () => (
    <Wrapper>
      <FormikSelectList
        items={this.props.categories}
        name="categories"
        multiple
        scroll
        iconPosition="right"
        maxSelect={this.props.maxSelect}
        onMaxSelect={this.renderMaxCategoriesPopup}
      />
    </Wrapper>
  );

  render = () => {
    if (this.props.errors) {
      return this.renderErrors(this.props.errors);
    } if (!this.props.categories.length) {
      return this.renderLoading();
    }

    return this.renderList();
  };
}

GigCategorySelectListScreen.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
  maxSelect: PropTypes.number,
  translate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
};

GigCategorySelectListScreen.defaultProps = {
  categories: [],
  maxSelect: undefined,
  errors: null,
};

export default GigCategorySelectListScreen;
