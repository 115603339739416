import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import GigCreatorStepLocationScreen from './GigCreatorStepLocationScreen';
import { gigCreatorAddLocation, gigCreatorAddRemoteWorkAllowed } from '../../gigCreatorActions';
import { withGigEdit } from '../../withGigEdit';
import { withGigEditPropTypes, gigCityPropType } from '../../../../constants/propTypes';
import { GIG_EDIT_SECTIONS } from '../../../../constants/gigEditSections';
import Routing from '../../../../core/routing/index';
import { getStepInfo, STEP_NAME } from '../../stepsConfig';
import { getAvailableGigCities } from '../../../../common/gig/city/gigCitiesActions';
import { findCityByKey, getTranslationForCity, getTranslationForCityKey } from '../../../../common/gig/city/gigCitiesHelper';
import googleAnalytics, { ACTIONS } from '../../../../common/analytics/googleAnalytics';

const { withRouter } = Routing;

class GigCreatorStepLocationContainer extends Component {
  constructor(props) {
    super(props);
    ({
      stepNumber: this.stepNumber,
      nextScreen: this.nextScreen,
      maxStepsNumber: this.maxStepsNumber,
    } = getStepInfo(props.grantedFeatures, STEP_NAME.LOCATION));
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.LOCATION);
    this.props.getAvailableGigCities();
  }

  onSubmit = (values, { setStatus, setSubmitting }) => {
    this.props.gigCreatorAddRemoteWorkAllowed(values.remoteWorkAllowed);

    const location = {
      cityV2: {
        key: values.cityKey,
        name: getTranslationForCityKey(this.props.availableCities, values.cityKey),
        geoLoc: findCityByKey(this.props.availableCities, values.cityKey).geoLoc,
      },
    };
    this.props.gigCreatorAddLocation(location, values.street, values.info);
    if (this.props.isEdit) {
      this.props.updateGig(GIG_EDIT_SECTIONS.LOCATION, {
        location: {
          ...location,
          street: values.street,
          info: values.info,
        },
        remoteWorkAllowed: values.remoteWorkAllowed,
      })
        .then(() => this.props.history.goBack())
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      setStatus({ redirect: true });
    }
  };

  getCitiesWithTranslations = () => this.props.availableCities
    && this.props.availableCities.map((city) => ({ value: city.key, label: getTranslationForCity(city) }));

  render() {
    const {
      cityV2, street, info, remoteWorkAllowed, isEdit,
    } = this.props;

    const initialValues = {
      cityKey: cityV2.key, street, info, remoteWorkAllowed,
    };

    return (
      <GigCreatorStepLocationScreen
        stepNumber={this.stepNumber}
        nextScreen={this.nextScreen}
        maxStepsNumber={this.maxStepsNumber}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        cities={this.getCitiesWithTranslations()}
        isEdit={isEdit}
      />
    );
  }
}

GigCreatorStepLocationContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  cityV2: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  street: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  remoteWorkAllowed: PropTypes.bool.isRequired,
  gigCreatorAddLocation: PropTypes.func.isRequired,
  gigCreatorAddRemoteWorkAllowed: PropTypes.func.isRequired,
  ...withGigEditPropTypes,
  grantedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType).isRequired,
};

GigCreatorStepLocationContainer.propTypes = {};

const mapStateToProps = (state) => {
  const {
    gig: {
      remoteWorkAllowed,
      location: {
        cityV2,
        street,
        info,
      },
    },
  } = state.companyGig.get('gigCreator').toJS();

  const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();
  const availableCities = state.gigCities.toJS();

  return {
    remoteWorkAllowed, cityV2, street, info, grantedFeatures, availableCities,
  };
};

export default withRouter(withGigEdit(
  mapStateToProps,
  { gigCreatorAddRemoteWorkAllowed, gigCreatorAddLocation, getAvailableGigCities },
)(GigCreatorStepLocationContainer));
