import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import Routing from '../../../core/routing/index';
import CompanySignupValidation from '../CompanySignupValidation';
import CompanyAutoCreateScreen from './CompanyAutoCreateScreen';
import { autoCreateSignUpCompany } from '../companySignupActions';
import { NOTIFICATION_PERMISSION } from '../../../constants/routes';

const { withRouter } = Routing;

class CompanyAutoCreateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsModalVisible: false,
      privacyModalVisible: false,
      privacyText: '',
      termsText: '',
    };
  }

  componentDidMount() {
    // 1. Fetch autoCreateId status
    // If created then log In
    // If not then show screen

    fetch('https://s3-eu-west-1.amazonaws.com/assets.letsgig.com/privacyPolicy.txt')
      .then((resp) => {
        if (resp.ok) {
          return resp.text();
        }
        return '';
      })
      .then((theText) => {
        this.setState({ privacyText: theText });
      });

    fetch('https://s3-eu-west-1.amazonaws.com/assets.letsgig.com/terms.txt')
      .then((resp) => {
        if (resp.ok) {
          return resp.text();
        }
        return '';
      })
      .then((theText) => {
        this.setState({ termsText: theText });
      });
  }

  onOpenPrivacy = () => {
    this.setState({ privacyModalVisible: true });
  };

  onOpenTerms = () => {
    this.setState({ termsModalVisible: true });
  };

  handleCloseModal = () => {
    this.setState({ privacyModalVisible: false });
    this.setState({ termsModalVisible: false });
  };

  onSubmit = (values, { setErrors, setSubmitting }) => {
    const { autoCreateId } = this.props.match.params;
    this.props.autoCreateSignUpCompany(autoCreateId)
      .then((response) => {
        if (response.status === 404) {
          setErrors({ general: 'autoCreateLinkNoLongerAvailable' });
          setSubmitting(false);
          return;
        }
        if (response.isError) {
          setErrors(response.errors);
          setSubmitting(false);
          return;
        }
        this.props.history.push(NOTIFICATION_PERMISSION);
      });
  };

  render() {
    const initialValues = {
      termsAndPrivacy: false,
    };

    return (
      <CompanyAutoCreateScreen
        validation={CompanySignupValidation}
        onSubmit={this.onSubmit}
        onOpenPrivacy={this.onOpenPrivacy}
        onOpenTerms={this.onOpenTerms}
        handleCloseModal={this.handleCloseModal}
        termsModalVisible={this.state.termsModalVisible}
        privacyModalVisible={this.state.privacyModalVisible}
        privacyText={this.state.privacyText}
        termsText={this.state.termsText}
        initialValues={initialValues}
      />
    );
  }
}

CompanyAutoCreateContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  autoCreateSignUpCompany: PropTypes.func.isRequired,
};

export default connect(null, { autoCreateSignUpCompany })(withRouter(CompanyAutoCreateContainer));
