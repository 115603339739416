/* eslint-disable global-require  */
import { Image, StyleSheet } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';

export const ARROW = {
  COLOR: {
    BLACK: 'black',
    MAGENTA: 'magenta',
    WHITE: 'white',
    BROWN_GREY: 'BrownGrey',
  },
  DIRECTION: {
    LEFT: 'left',
    RIGHT: 'right',
  },
};

const arrowIcons = {
  [ARROW.COLOR.BLACK]: require('../../assets/icons/arrows/iconsArrowLeft.png'),
  [ARROW.COLOR.MAGENTA]: require('../../assets/icons/arrows/iconsArrowLeftMagenta.png'),
  [ARROW.COLOR.WHITE]: require('../../assets/icons/arrows/arrowLeftWhite.png'),
  [ARROW.COLOR.BROWN_GREY]: require('../../assets/icons/arrows/iconsArrowLeftBrownGrey.png'),
};

const styles = StyleSheet.create({
  icon: {
    width: 9,
    height: 14,
  },
  left: {},
  right: {
    transform: [{ rotateY: '180deg' }],
  },
});

class Arrow extends React.PureComponent {
  render() {
    const { color, direction } = this.props;
    return <Image source={arrowIcons[color]} style={[styles.icon, styles[direction]]} />;
  }
}

Arrow.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.string,
};

Arrow.defaultProps = {
  color: ARROW.COLOR.MAGENTA,
  direction: ARROW.DIRECTION.RIGHT,
};

export default Arrow;
