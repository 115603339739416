/*
Not all of these constants are used right now, however,
let's keep them so that we know what actions are flying in the system.
Some are created with redux-axios-middleware on-the-go
 */

/* App Version */
export const APP_VERSION_REQUEST = 'APP_VERSION_REQUEST';
export const APP_VERSION_REQUEST_SUCCESS = 'APP_VERSION_REQUEST_SUCCESS';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_SET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_REGISTER_INSTALLATION = 'USER_REGISTER_INSTALLATION';
export const USER_REGISTER_INSTALLATION_SUCCESS = 'USER_REGISTER_INSTALLATION_SUCCESS';
export const USER_REGISTER_INSTALLATION_FAIL = 'USER_REGISTER_INSTALLATION_FAIL';

/* Company signup */

export const COMPANY_SIGNUP_REQUEST = 'COMPANY_SIGNUP_REQUEST';
export const COMPANY_SIGNUP_REQUEST_SUCCESS = 'COMPANY_SIGNUP_REQUEST_SUCCESS';
export const COMPANY_AUTOCREATE_SIGNUP_REQUEST = 'COMPANY_AUTOCREATE_SIGNUP_REQUEST';
export const COMPANY_AUTOCREATE_SIGNUP_REQUEST_SUCCESS = 'COMPANY_AUTOCREATE_SIGNUP_REQUEST_SUCCESS';

/* Candidate signup */

export const CANDIDATE_SIGNUP_REQUEST = 'CANDIDATE_SIGNUP_REQUEST';
export const CANDIDATE_SIGNUP_REQUEST_SUCCESS = 'CANDIDATE_SIGNUP_REQUEST_SUCCESS';

/* Reset signup */

export const RESET_SIGNUP_SUCCESS = 'RESET_SIGNUP_SUCCESS';

// noinspection JSUnusedGlobalSymbols
export const CANDIDATE_SIGNUP_REQUEST_FAIL = 'CANDIDATE_SIGNUP_REQUEST_FAIL';

/* Company profile */

export const COMPANY_PROFILE_REQUEST = 'COMPANY_PROFILE_REQUEST';
export const COMPANY_PROFILE_REQUEST_SUCCESS = 'COMPANY_PROFILE_REQUEST_SUCCESS';

/* Company payments */
export const COMPANY_SAVE_CARD_REQUEST = 'COMPANY_SAVE_CARD_REQUEST';
export const COMPANY_SAVE_CARD_REQUEST_SUCCESS = 'COMPANY_SAVE_CARD_REQUEST_SUCCESS';
export const COMPANY_GET_CARD_REQUEST = 'COMPANY_GET_CARD_REQUEST';
export const COMPANY_GET_PAYMENTS_OVERVIEW_REQUEST = 'COMPANY_GET_PAYMENTS_OVERVIEW_REQUEST';
export const COMPANY_GET_PAYMENTS_OVERVIEW_REQUEST_SUCCESS = 'COMPANY_GET_PAYMENTS_OVERVIEW_REQUEST_SUCCESS';
export const COMPANY_GET_PROFIT_MARGIN_REQUEST = 'COMPANY_GET_PROFIT_MARGIN_REQUEST';
export const COMPANY_GET_PROFIT_MARGIN_REQUEST_SUCCESS = 'COMPANY_GET_PROFIT_MARGIN_REQUEST_SUCCESS';
export const COMPANY_GET_PRICING_PLANS_REQUEST = 'COMPANY_GET_PRICING_PLANS_REQUEST';
export const COMPANY_GET_PRICING_PLANS_REQUEST_SUCCESS = 'COMPANY_GET_PRICING_PLANS_REQUEST_SUCCESS';
export const COMPANY_PAYMENTS_GET_CURRENT_SUBSCRIPTION_REQUEST = 'COMPANY_PAYMENTS_GET_CURRENT_SUBSCRIPTION_REQUEST';
export const COMPANY_PAYMENTS_SUBSCRIBE_REQUEST = 'COMPANY_PAYMENTS_SUBSCRIBE_REQUEST';
export const COMPANY_PAYMENTS_SUBSCRIBE_REQUEST_SUCCESS = 'COMPANY_PAYMENTS_SUBSCRIBE_REQUEST_SUCCESS';
export const COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST = 'COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST';
export const COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST_SUCCESS = 'COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST_SUCCESS';
export const COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST = 'COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST';
export const COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST_SUCCESS = 'COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST_SUCCESS';
export const COMPANY_SAVE_SELECTED_PRICING_PLAN = 'COMPANY_SAVE_SELECTED_PRICING_PLAN';
export const COMPANY_GIG_PAY_REQUEST = 'COMPANY_GIG_PAY_REQUEST';
export const COMPANY_GIG_PAY_REQUEST_SUCCESS = 'COMPANY_GIG_PAY_REQUEST_SUCCESS';

/* Company status */

export const COMPANY_STATUS_REQUEST = 'COMPANY_STATUS_REQUEST';
export const COMPANY_STATUS_REQUEST_SUCCESS = 'COMPANY_STATUS_REQUEST_SUCCESS';

export const COMPANY_PROFILE_EDIT_REQUEST = 'COMPANY_PROFILE_EDIT_REQUEST';
// noinspection JSUnusedGlobalSymbols
export const COMPANY_PROFILE_EDIT_REQUEST_SUCCESS = 'COMPANY_PROFILE_EDIT_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const COMPANY_PROFILE_EDIT_REQUEST_FAIL = 'COMPANY_PROFILE_EDIT_REQUEST_FAIL';

// noinspection JSUnusedGlobalSymbols
export const COMPANY_PROFILE_REQUEST_FAIL = 'COMPANY_PROFILE_REQUEST_FAIL';
export const COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST = 'COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST';
export const COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS = 'COMPANY_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS';

/* Company payments */
export const COMPANY_PAYMENTS_GET_BILLING_PROFILE_REQUEST = 'COMPANY_PAYMENTS_GET_BILLING_PROFILE_REQUEST';
export const COMPANY_PAYMENTS_GET_BILLING_PROFILE_REQUEST_SUCCESS = 'COMPANY_PAYMENTS_GET_BILLING_PROFILE_REQUEST_SUCCESS';
export const COMPANY_PAYMENTS_SAVE_BILLING_PROFILE_REQUEST = 'COMPANY_PAYMENTS_SAVE_BILLING_PROFILE_REQUEST';
export const COMPANY_PAYMENTS_SAVE_BILLING_PROFILE_REQUEST_SUCCESS = 'COMPANY_PAYMENTS_SAVE_BILLING_PROFILE_REQUEST_SUCCESS';
export const COMPANY_PAYMENTS_CARD_REQUEST = 'COMPANY_PAYMENTS_CARD_REQUEST';
/* Candidate status */

export const CANDIDATE_STATUS_REQUEST = 'CANDIDATE_STATUS_REQUEST';
export const CANDIDATE_STATUS_REQUEST_SUCCESS = 'CANDIDATE_STATUS_REQUEST_SUCCESS';
export const USER_VISITED_FIRST_SCREEN = 'USER_VISITED_FIRST_SCREEN';

/* Candidate profile */

export const CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST = 'CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST';
export const CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_GET_CATEGORY_VALIDATION_PARAMS_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_REQUEST = 'CANDIDATE_PROFILE_REQUEST';
// noinspection JSUnusedGlobalSymbols
export const CANDIDATE_PROFILE_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const CANDIDATE_PROFILE_REQUEST_FAIL = 'CANDIDATE_PROFILE_REQUEST_FAIL';
export const CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST = 'CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST';
export const CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_AVAILABLE_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST = 'CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST';
export const CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_AVAILABILITY_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST = 'CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST';
export const CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_FULL_NAME_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_EMAIL_REQUEST = 'CANDIDATE_PROFILE_SET_EMAIL_REQUEST';
export const CANDIDATE_PROFILE_SET_EMAIL_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_EMAIL_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST = 'CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST';
export const CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_GIG_LOCATION_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST = 'CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST';
export const CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_HOME_LOCATION_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_CATEGORY_REQUEST = 'CANDIDATE_PROFILE_SET_CATEGORY_REQUEST';
export const CANDIDATE_PROFILE_SET_CATEGORY_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_CATEGORY_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST = 'CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST';
export const CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_CURRENT_OCCUPATION_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST = 'CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST';
export const CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_SET_LANGUAGES_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST = 'CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST';
export const CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_ADD_EDUCATION_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST = 'CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST';
export const CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_EDUCATION_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST = 'CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST';
export const CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_REMOVE_EDUCATION_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST = 'CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST';
export const CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_ADD_EXPERIENCE_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST = 'CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST';
export const CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_EXPERIENCE_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST = 'CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST';
export const CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_REMOVE_EXPERIENCE_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST = 'CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST';
export const CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_ABOUT_ME_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST = 'CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST';
export const CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_PROFILE_IMAGE_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST = 'CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST';
export const CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_PHONE_NUMBER_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST = 'CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST';
export const CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_PERSONAL_NUMBER_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST = 'CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST';
export const CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_CLEARING_NUMBER_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST = 'CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST';
export const CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_EDIT_BANK_ACCOUNT_NUMBER_REQUEST_SUCCESS';
export const CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST = 'CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST';
export const CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST_SUCCESS = 'CANDIDATE_PROFILE_CONTRACT_STATUS_REQUEST_SUCCESS';

/* Candidate gigs */
export const CANDIDATE_GIGS_FIND_GIGS_REQUEST = 'CANDIDATE_GIGS_FIND_GIGS_REQUEST';
export const CANDIDATE_GIGS_FIND_GIGS_REQUEST_SUCCESS = 'CANDIDATE_GIGS_FIND_GIGS_REQUEST_SUCCESS';

export const CANDIDATE_GIGS_FIND_GIGS_CHANGE_LOCATION = 'CANDIDATE_GIGS_FIND_GIGS_CHANGE_LOCATION';
export const CANDIDATE_GIGS_FIND_GIGS_CHANGE_CATEGORIES = 'CANDIDATE_GIGS_FIND_GIGS_CHANGE_CATEGORIES';
export const CANDIDATE_GIGS_FIND_GIGS_CHANGE_POSITION_SEARCH = 'CANDIDATE_GIGS_FIND_GIGS_CHANGE_POSITION_SEARCH';
export const CANDIDATE_GIGS_FIND_GIGS_CHANGE_OFFSET = 'CANDIDATE_GIGS_FIND_GIGS_CHANGE_OFFSET';
export const CANDIDATE_GIGS_FIND_GIGS_CLEAR_LIST = 'CANDIDATE_GIGS_FIND_GIGS_CLEAR_LIST';
export const CANDIDATE_GIGS_FIND_GIGS_SET_SCROLL_POSITION = 'CANDIDATE_GIGS_FIND_GIGS_SET_SCROLL_POSITION';
export const CANDIDATE_GIGS_FIND_GIGS_SET_CURRENT_INDEX = 'CANDIDATE_GIGS_FIND_GIGS_SET_CURRENT_INDEX';
export const CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_MODAL = 'CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_MODAL';
export const CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_INFO_MODAL = 'CANDIDATE_GIGS_FIND_GIGS_SET_SHOW_INFO_MODAL';

export const CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST = 'CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST';
export const CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST_SUCCESS = 'CANDIDATE_GIGS_GET_FAVOURITE_GIGS_REQUEST_SUCCESS';
export const CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST = 'CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST';
export const CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST_SUCCESS = 'CANDIDATE_GIGS_GET_NOT_INTERESTING_GIGS_REQUEST_SUCCESS';

export const CANDIDATE_GIGS_REMOVE_FAVOURITE_GIG_FROM_LIST = 'CANDIDATE_GIGS_REMOVE_FAVOURITE_GIG_FROM_LIST';
export const CANDIDATE_GIGS_REMOVE_NOT_INTERESTING_GIG_FROM_LIST = 'CANDIDATE_GIGS_REMOVE_NOT_INTERESTING_GIG_FROM_LIST';

export const CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST = 'CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST';
export const CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST_SUCCESS = 'CANDIDATE_GIGS_GET_FAV_AND_NOT_INTERESTING_COUNTERS_REQUEST_SUCCESS';
export const CANDIDATE_CHANGE_FAVOURITE_COUNTER = 'CANDIDATE_CHANGE_FAVOURITE_COUNTER';
export const CANDIDATE_CHANGE_NOT_INTERESTING_COUNTER = 'CANDIDATE_CHANGE_NOT_INTERESTING_COUNTER';

export const CANDIDATE_GET_GIG_REQUEST = 'CANDIDATE_GET_GIG_REQUEST';
export const CANDIDATE_GET_GIG_REQUEST_SUCCESS = 'CANDIDATE_GET_GIG_REQUEST_SUCCESS';
export const CANDIDATE_GET_SIMILAR_GIGS_REQUEST = 'CANDIDATE_GET_SIMILAR_GIGS_REQUEST';
export const CANDIDATE_GET_SIMILAR_GIGS_REQUEST_SUCCESS = 'CANDIDATE_GET_SIMILAR_GIGS_REQUEST_SUCCESS';
export const CANDIDATE_CLEAR_SIMILAR_GIGS = 'CANDIDATE_CLEAR_SIMILAR_GIGS';

export const CANDIDATE_ADD_NEW_CONTENT_INDICATOR = 'CANDIDATE_ADD_NEW_CONTENT_INDICATOR';
export const CANDIDATE_REMOVE_NEW_CONTENT_INDICATOR = 'CANDIDATE_REMOVE_NEW_CONTENT_INDICATOR';

export const CANDIDATE_CLEAR_CANDIDATE_GIGS = 'CANDIDATE_CLEAR_CANDIDATE_GIGS';
export const CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST = 'CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST';
export const CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST_SUCCESS = 'CANDIDATE_COUNT_CANDIDATE_GIGS_REQUEST_SUCCESS';
export const CANDIDATE_GET_CANDIDATE_GIGS_REQUEST = 'CANDIDATE_GET_CANDIDATE_GIGS_REQUEST';
export const CANDIDATE_GET_CANDIDATE_GIGS_REQUEST_SUCCESS = 'CANDIDATE_GET_CANDIDATE_GIGS_REQUEST_SUCCESS';
export const CANDIDATE_APPROVE_SHORTLISTING_REQUEST = 'CANDIDATE_APPROVE_SHORTLISTING_REQUEST';
export const CANDIDATE_APPROVE_SHORTLISTING_REQUEST_SUCCESS = 'CANDIDATE_APPROVE_SHORTLISTING_REQUEST_SUCCESS';
export const CANDIDATE_REJECT_REQUEST = 'CANDIDATE_REJECT_REQUEST';
export const CANDIDATE_REJECT_REQUEST_SUCCESS = 'CANDIDATE_REJECT_REQUEST_SUCCESS';
export const CANDIDATE_APPLY_FOR_GIG_REQUEST = 'CANDIDATE_APPLY_FOR_GIG_REQUEST';
export const CANDIDATE_APPLY_FOR_GIG_REQUEST_SUCCESS = 'CANDIDATE_APPLY_FOR_GIG_REQUEST_SUCCESS';
export const CANDIDATE_ACCEPT_OFFER_REQUEST = 'CANDIDATE_ACCEPT_OFFER_REQUEST';
export const CANDIDATE_ACCEPT_OFFER_REQUEST_SUCCESS = 'CANDIDATE_ACCEPT_OFFER_REQUEST_SUCCESS';
export const CANDIDATE_GET_OFFER_REQUEST = 'CANDIDATE_GET_OFFER_REQUEST';
export const CANDIDATE_ARCHIVE_GIG_REQUEST = 'CANDIDATE_ARCHIVE_GIG_REQUEST';
export const CANDIDATE_ARCHIVE_GIG_REQUEST_SUCCESS = 'CANDIDATE_ARCHIVE_GIG_REQUEST_SUCCESS';
export const CANDIDATE_ADD_FAVOURITE_GIG = 'CANDIDATE_ADD_FAVOURITE_GIG';
export const CANDIDATE_ADD_FAVOURITE_GIG_SUCCESS = 'CANDIDATE_ADD_FAVOURITE_GIG_SUCCESS';
export const CANDIDATE_ADD_NOT_INTERESTING_GIG = 'CANDIDATE_ADD_NOT_INTERESTING_GIG';
export const CANDIDATE_ADD_NOT_INTERESTING_GIG_SUCCESS = 'CANDIDATE_ADD_NOT_INTERESTING_GIG_SUCCESS';

export const CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST = 'CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST';
export const CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST_SUCCESS = 'CANDIDATE_MY_POOLS_GROUP_CHAT_DATA_REQUEST_SUCCESS';
export const CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST = 'CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST';
export const CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST_SUCCESS = 'CANDIDATE_MY_POOLS_GROUP_CHATS_INFO_REQUEST_SUCCESS';

export const CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST = 'CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST';
export const CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS = 'CANDIDATE_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS';
export const CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST = 'CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST';
export const CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS = 'CANDIDATE_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS';

export const CANDIDATE_POOL_CHAT_DATA_REQUEST = 'CANDIDATE_POOL_CHAT_DATA_REQUEST';
export const CANDIDATE_POOL_CHAT_DATA_REQUEST_SUCCESS = 'CANDIDATE_POOL_CHAT_DATA_REQUEST_SUCCESS';
export const CANDIDATE_POOL_CHATS_INFO_REQUEST = 'CANDIDATE_POOL_CHATS_INFO_REQUEST';
export const CANDIDATE_POOL_CHATS_INFO_REQUEST_SUCCESS = 'CANDIDATE_POOL_CHATS_INFO_REQUEST_SUCCESS';

/* Authentication */

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAIL = 'LOGIN_REQUEST_FAIL';

export const LOGIN_WITH_FACEBOOK_REQUEST = 'LOGIN_WITH_FACEBOOK_REQUEST';
export const LOGIN_WITH_FACEBOOK_REQUEST_SUCCESS = 'LOGIN_WITH_FACEBOOK_REQUEST_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const LOGOUT_REQUEST_FAIL = 'LOGOUT_REQUEST_FAIL';

export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';

export const LOCALE_SET = 'LOCALE_SET';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_REQUEST_SUCCESS = 'DELETE_ACCOUNT_REQUEST_SUCCESS';

/* User user_settings */

export const SAVE_NOTIFICATIONS_SETTING_REQUEST = 'SAVE_NOTIFICATIONS_SETTING_REQUEST';
export const SAVE_INTERESTING_GIG_NOTIFICATION_SETTING_REQUEST = 'SAVE_INTERESTING_GIG_NOTIFICATION_SETTING_REQUEST';

export const REGISTER_DEVICE_FOR_PUSH_REQUEST = 'REGISTER_DEVICE_FOR_PUSH_REQUEST';
// noinspection JSUnusedGlobalSymbols
export const REGISTER_DEVICE_FOR_PUSH_REQUEST_SUCCESS = 'REGISTER_DEVICE_FOR_PUSH_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const REGISTER_DEVICE_FOR_PUSH_REQUEST_FAIL = 'REGISTER_DEVICE_FOR_PUSH_REQUEST_FAIL';

export const CONFIG_GET_VALUE_REQUEST = 'CONFIG_GET_VALUE_REQUEST';
// noinspection JSUnusedGlobalSymbols
export const CONFIG_GET_VALUE_REQUEST_SUCCESS = 'CONFIG_GET_VALUE_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const CONFIG_GET_VALUE_REQUEST_FAIL = 'CONFIG_GET_VALUE_REQUEST_FAIL';

export const GIG_CATEGORIES_REQUEST = 'GIG_CATEGORIES_REQUEST';
// noinspection JSUnusedGlobalSymbols
export const GIG_CATEGORIES_REQUEST_SUCCESS = 'GIG_CATEGORIES_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const GIG_CATEGORIES_REQUEST_FAIL = 'GIG_CATEGORIES_REQUEST_FAIL';

export const EDUCATION_CATEGORIES_REQUEST = 'EDUCATION_CATEGORIES_REQUEST';
export const EDUCATION_CATEGORIES_REQUEST_SUCCESS = 'EDUCATION_CATEGORIES_REQUEST_SUCCESS';
export const EDUCATION_CATEGORIES_REQUEST_FAIL = 'EDUCATION_CATEGORIES_REQUEST_FAIL';

export const GIG_CITIES_REQUEST = 'GIG_CITIES_REQUEST';
export const GIG_CITIES_REQUEST_SUCCESS = 'GIG_CITIES_REQUEST_SUCCESS';

export const GIG_MUNICIPALITIES_REQUEST = 'GIG_MUNICIPALITIES_REQUEST';
export const GIG_MUNICIPALITIES_REQUEST_SUCCESS = 'GIG_MUNICIPALITIES_REQUEST_SUCCESS';

/* Chat */
export const CHAT_CREDENTIALS_REQUEST = 'CHAT_CREDENTIALS_REQUEST';
export const CHAT_CREDENTIALS_REQUEST_SUCCESS = 'CHAT_CREDENTIALS_REQUEST_SUCCESS';
export const CHAT_SET_MESSAGES = 'CHAT_SET_MESSAGES';
export const CHAT_NEW_MESSAGE = 'CHAT_NEW_MESSAGE';
export const CHAT_SAVE_LAST_MESSAGE_READ = 'CHAT_SAVE_LAST_MESSAGE_READ';
export const CHAT_SUBSCRIPTIONS_RECEIVED = 'CHAT_SUBSCRIPTIONS_RECEIVED';
export const CHAT_UPDATED = 'CHAT_UPDATED';
export const USER_PRESENCE_UPDATED = 'USER_PRESENCE_UPDATED';

/* Group chat */
export const GROUP_CHAT_SET_MESSAGES = 'GROUP_CHAT_SET_MESSAGES';
export const GROUP_CHAT_NEW_MESSAGE = 'GROUP_CHAT_NEW_MESSAGE';
export const GROUP_CHAT_SAVE_LAST_MESSAGE_READ = 'GROUP_CHAT_SAVE_LAST_MESSAGE_READ';
export const GROUP_CHAT_UPDATED = 'GROUP_CHAT_UPDATED';

/* Pool chat */
export const POOL_DIRECT_CHAT_SET_MESSAGES = 'POOL_DIRECT_CHAT_SET_MESSAGES';
export const POOL_DIRECT_CHAT_NEW_MESSAGE = 'POOL_DIRECT_CHAT_NEW_MESSAGE';
export const POOL_DIRECT_CHAT_SAVE_LAST_MESSAGE_READ = 'POOL_DIRECT_CHAT_SAVE_LAST_MESSAGE_READ';
export const POOL_DIRECT_CHAT_UPDATED = 'POOL_DIRECT_CHAT_UPDATED';
export const POOL_CHAT_SET_MESSAGES = 'POOL_CHAT_SET_MESSAGES';
export const POOL_CHAT_NEW_MESSAGE = 'POOL_CHAT_NEW_MESSAGE';
export const POOL_CHAT_SAVE_LAST_MESSAGE_READ = 'POOL_CHAT_SAVE_LAST_MESSAGE_READ';
export const POOL_CHAT_UPDATED = 'POOL_CHAT_UPDATED';

/* Candidate chat */
export const CANDIDATE_CHAT_DATA_REQUEST = 'CANDIDATE_CHAT_DATA_REQUEST';
export const CANDIDATE_CHAT_DATA_REQUEST_SUCCESS = 'CANDIDATE_CHAT_DATA_REQUEST_SUCCESS';
export const CANDIDATE_CHATS_INFO_REQUEST = 'CANDIDATE_CHATS_INFO_REQUEST';
export const CANDIDATE_CHATS_INFO_REQUEST_SUCCESS = 'CANDIDATE_CHATS_INFO_REQUEST_SUCCESS';

/* Candidate pools */
export const CANDIDATE_POOLS_LIST_REQUEST = 'CANDIDATE_POOLS_LIST_REQUEST';
export const CANDIDATE_POOLS_GET_REQUEST = 'CANDIDATE_POOLS_GET_REQUEST';
export const CANDIDATE_POOLS_ACCEPT_INVITATION_REQUEST = 'CANDIDATE_POOLS_ACCEPT_INVITATION_REQUEST';
export const CANDIDATE_POOLS_REJECT_INVITATION_REQUEST = 'CANDIDATE_POOLS_REJECT_INVITATION_REQUEST';
export const CANDIDATE_POOLS_SIGN_OFF_REQUEST = 'CANDIDATE_POOLS_SIGN_OFF_REQUEST';

export const CANDIDATE_POOLS_GROUPS_GET_REQUEST = 'CANDIDATE_POOLS_GROUPS_GET_REQUEST';
export const CANDIDATE_POOLS_GROUPS_SIGN_OFF_REQUEST = 'CANDIDATE_POOLS_GROUPS_SIGN_OFF_REQUEST';
export const CANDIDATE_POOLS_SET_NEW_CONTENT = 'CANDIDATE_POOLS_SET_NEW_CONTENT';
export const CANDIDATE_POOLS_CLEAR_NEW_CONTENT = 'CANDIDATE_POOLS_CLEAR_NEW_CONTENT';
export const CANDIDATE_POOLS_STORE_SHIFTS_SCROLL_POSITION = 'CANDIDATE_POOLS_STORE_SHIFTS_SCROLL_POSITION';

export const CANDIDATE_POOLS_GET_AVAILABLE_SHIFTS_REQUEST = 'CANDIDATE_POOLS_GET_AVAILABLE_SHIFTS_REQUEST';
export const CANDIDATE_POOLS_GET_SHIFT_REQUEST = 'CANDIDATE_POOLS_GET_SHIFT_REQUEST';
export const CANDIDATE_POOLS_BOOK_SHIFT_REQUEST = 'CANDIDATE_POOLS_BOOK_SHIFT_REQUEST';

/* Gig Creator */
export const GIG_CREATOR_ADD_NAME = 'GIG_CREATOR_ADD_NAME';
export const GIG_CREATOR_ADD_EXPIRY_DATE = 'GIG_CREATOR_ADD_EXPIRY_DATE';
export const GIG_CREATOR_ADD_OCCUPATION = 'GIG_CREATOR_ADD_OCCUPATION';
export const GIG_CREATOR_ADD_CATEGORIES = 'GIG_CREATOR_ADD_CATEGORIES';
export const GIG_CREATOR_ADD_POSITION = 'GIG_CREATOR_ADD_POSITION';
export const GIG_CREATOR_ADD_DESCRIPTION = 'GIG_CREATOR_ADD_DESCRIPTION';
export const GIG_CREATOR_ADD_CANDIDATES_NUMBER = 'GIG_CREATOR_ADD_CANDIDATES_NUMBER';
export const GIG_CREATOR_ADD_CAREER_URL = 'GIG_CREATOR_ADD_CAREER_URL';
export const GIG_CREATOR_ADD_DATES = 'GIG_CREATOR_ADD_DATES';
export const GIG_CREATOR_ADD_COMPENSATION = 'GIG_CREATOR_ADD_COMPENSATION';
export const GIG_CREATOR_ADD_LOCATION = 'GIG_CREATOR_ADD_LOCATION';
export const GIG_CREATOR_ADD_REMOTE_WORK_ALLOWED = 'GIG_CREATOR_ADD_REMOTE_WORK_ALLOWED';
export const GIG_CREATOR_ADD_AVAILABILITY = 'GIG_CREATOR_ADD_AVAILABILITY';
export const GIG_CREATOR_ADD_EXPERIENCE = 'GIG_CREATOR_ADD_EXPERIENCE';
export const GIG_CREATOR_ADD_LANGUAGES = 'GIG_CREATOR_ADD_LANGUAGES';
export const GIG_CREATOR_ADD_PHOTO = 'GIG_CREATOR_ADD_PHOTO';
export const GIG_CREATOR_ADD_QUESTIONNAIRE = 'GIG_CREATOR_ADD_QUESTIONNAIRE';
export const GIG_CREATOR_UPLOAD_PHOTO_REQUEST = 'GIG_CREATOR_UPLOAD_PHOTO_REQUEST';
export const GIG_CREATOR_CLEAR_ALL_DATA = 'GIG_CREATOR_CLEAR_ALL_DATA';
export const GIG_CREATOR_SAVE_NEW_GIG = 'GIG_CREATOR_SAVE_NEW_GIG';
export const GIG_CREATOR_INIT_EDIT_GIG = 'GIG_CREATOR_INIT_EDIT_GIG';
export const GIG_CREATOR_UPDATE_GIG = 'GIG_CREATOR_UPDATE_GIG';
export const GIG_CREATOR_UPDATE_GIG_SUCCESS = 'GIG_CREATOR_UPDATE_GIG_SUCCESS';
export const GIG_CREATOR_CHECK_GIG_LIMIT = 'GIG_CREATOR_CHECK_GIG_LIMIT';
export const GIG_CREATOR_CHECK_GIG_LIMIT_SUCCESS = 'GIG_CREATOR_CHECK_GIG_LIMIT_SUCCESS';
export const GIG_ACTIVE_COUNT = 'GIG_ACTIVE_COUNT';
export const GIG_ACTIVE_COUNT_SUCCESS = 'GIG_ACTIVE_COUNT_SUCCESS';

/* Company Gigs */
export const COMPANY_GIGS_GET_OPEN_GIGS_REQUEST = 'COMPANY_GIGS_GET_OPEN_GIGS_REQUEST';
export const COMPANY_GIGS_GET_OPEN_GIGS_REQUEST_SUCCESS = 'COMPANY_GIGS_GET_OPEN_GIGS_REQUEST_SUCCESS';
export const COMPANY_GIG_GET_CANDIDATES_REQUEST = 'COMPANY_GIG_GET_CANDIDATES_REQUEST';
export const COMPANY_GIG_GET_CANDIDATES_REQUEST_SUCCESS = 'COMPANY_GIG_GET_CANDIDATES_REQUEST_SUCCESS';
export const COMPANY_GIG_CLEAR_CANDIDATES = 'COMPANY_GIG_CLEAR_CANDIDATES';
export const COMPANY_GIGS_CLEAR_LIST = 'COMPANY_GIGS_CLEAR_LIST';
export const COMPANY_GIGS_STORE_GIG_DATA = 'COMPANY_GIGS_STORE_GIG_DATA';
export const COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_SET = 'COMPANY_GIGS_NEW_UPDATED_GIG';
export const COMPANY_GIG_CANDIDATE_STATUS_INDICATOR_REMOVE = 'COMPANY_GIGS_REMOVE_FROM_UPDATED_GIGS';
export const COMPANY_GIG_CANDIDATE_PROFILE_REQUEST = 'COMPANY_GIG_CANDIDATE_PROFILE_REQUEST';
export const COMPANY_GIG_CANDIDATE_PROFILE_REQUEST_SUCCESS = 'COMPANY_GIG_CANDIDATE_PROFILE_REQUEST_SUCCESS';

export const COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST = 'COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST';
export const COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST = 'COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST';
export const COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST_SUCCESS = 'COMPANY_GIG_SEND_OFFER_TO_CANDIDATE_REQUEST_SUCCESS';
export const COMPANY_GIG_HIRE_BY_OWN_REQUEST = 'COMPANY_GIG_HIRE_BY_OWN_REQUEST';
export const COMPANY_GIG_HIRE_BY_OWN_REQUEST_SUCCESS = 'COMPANY_GIG_HIRE_BY_OWN_REQUEST_SUCCESS';
export const COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST = 'COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST';
export const COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST_SUCCESS = 'COMPANY_GIG_HIRE_BY_LETSGIG_REQUEST_SUCCESS';
export const COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST_SUCCESS = 'COMPANY_GIG_SHORTLIST_CANDIDATE_REQUEST_SUCCESS';
export const COMPANY_GIG_REJECT_CANDIDATE_REQUEST = 'COMPANY_GIG_REJECT_CANDIDATE_REQUEST';
export const COMPANY_GIG_REJECT_CANDIDATE_REQUEST_SUCCESS = 'COMPANY_GIG_REJECT_CANDIDATE_REQUEST_SUCCESS';
export const COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST = 'COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST';
export const COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST_SUCCESS = 'COMPANY_GIG_EXCLUDE_SUGGESTED_CANDIDATE_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const COMPANY_GIGS_GET_OPEN_GIGS_REQUEST_FAIL = 'COMPANY_GIGS_GET_OPEN_GIGS_REQUEST_FAIL';
export const COMPANY_GIG_GET_OFFER_REQUEST = 'COMPANY_GIG_GET_OFFER_REQUEST';
export const COMPANY_GIG_STORE_SCROLL_POSITION = 'COMPANY_GIG_STORE_SCROLL_POSITION';

export const COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST = 'COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST';
export const COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST_SUCCESS = 'COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST_FAIL = 'COMPANY_GIGS_GET_CLOSED_GIGS_REQUEST_FAIL';

export const GET_GIG_REQUEST = 'GET_GIG_REQUEST';
// noinspection JSUnusedGlobalSymbols
export const GET_GIG_REQUEST_SUCCESS = 'GET_GIG_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const GET_GIG_REQUEST_FAIL = 'GET_GIG_REQUEST_FAIL';

export const CLOSE_GIG_REQUEST = 'CLOSE_GIG_REQUEST';
export const CLOSE_GIG_REQUEST_SUCCESS = 'CLOSE_GIG_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const CLOSE_GIG_REQUEST_FAIL = 'CLOSE_GIG_REQUEST_FAIL';

/* Company chat */
export const COMPANY_CHATS_REQUEST = 'COMPANY_CHATS_REQUEST';
export const COMPANY_CHATS_REQUEST_SUCCESS = 'COMPANY_CHATS_REQUEST_SUCCESS';
export const COMPANY_GIG_CHATS_DETAILS_REQUEST = 'COMPANY_GIG_CHATS_DETAILS_REQUEST';
export const COMPANY_GIG_CHATS_DETAILS_REQUEST_SUCCESS = 'COMPANY_GIG_CHATS_DETAILS_REQUEST_SUCCESS';

export const COMPANY_CHAT_DATA_REQUEST = 'COMPANY_CHAT_DATA_REQUEST';
export const COMPANY_CHAT_DATA_REQUEST_SUCCESS = 'COMPANY_CHAT_DATA_REQUEST_SUCCESS';

export const COMPANY_CANDIDATES_POOL_GET_REQUEST = 'COMPANY_CANDIDATES_POOL_GET_REQUEST';
export const COMPANY_CANDIDATES_POOL_INVITE_REQUEST = 'COMPANY_CANDIDATES_POOL_INVITE_REQUEST';
export const COMPANY_CANDIDATES_POOL_REMOVE_REQUEST = 'COMPANY_CANDIDATES_POOL_REMOVE_REQUEST';
export const COMPANY_CANDIDATES_POOL_GET_GROUPS_REQUEST = 'COMPANY_CANDIDATES_POOL_GET_GROUPS_REQUEST';
export const COMPANY_CANDIDATES_POOL_GET_GROUP_REQUEST = 'COMPANY_CANDIDATES_POOL_GET_GROUP_REQUEST';
export const COMPANY_CANDIDATES_POOL_DELETE_GROUP_REQUEST = 'COMPANY_CANDIDATES_POOL_DELETE_GROUP_REQUEST';
export const COMPANY_CANDIDATES_POOL_CREATE_GROUP_REQUEST = 'COMPANY_CANDIDATES_POOL_CREATE_GROUP_REQUEST';
export const COMPANY_CANDIDATES_POOL_RENAME_GROUP_REQUEST = 'COMPANY_CANDIDATES_POOL_RENAME_GROUP_REQUEST';
export const COMPANY_CANDIDATES_POOL_CANDIDATE_PROFILE_GET_REQUEST = 'COMPANY_CANDIDATES_POOL_CANDODATE_PROFILE_GET_REQUEST';
export const COMPANY_CANDIDATES_POOL_STORE_SCROLL_POSITION = 'COMPANY_CANDIDATES_POOL_STORE_SCROLL_POSITION';
export const COMPANY_CANDIDATES_POOL_GROUPS_STORE_SCROLL_POSITION = 'COMPANY_CANDIDATES_POOL_GROUPS_STORE_SCROLL_POSITION';
export const COMPANY_CANDIDATES_POOL_GROUP_MEMBERS_STORE_SCROLL_POSITION = 'COMPANY_CANDIDATES_POOL_GROUP_MEMBERS_STORE_SCROLL_POSITION';
export const COMPANY_CANDIDATES_POOL_SET_NEW_CONTENT = 'COMPANY_CANDIDATES_POOL_SET_NEW_CONTENT';
export const COMPANY_CANDIDATES_POOL_CLEAR_NEW_CONTENT = 'COMPANY_CANDIDATES_POOL_CLEAR_NEW_CONTENT';

export const COMPANY_CANDIDATES_POOL_PROFILE_GET_REQUEST = 'COMPANY_CANDIDATES_POOL_PROFILE_GET_REQUEST';
export const COMPANY_CANDIDATES_POOL_PROFILE_SAVE_REQUEST = 'COMPANY_CANDIDATES_POOL_PROFILE_SAVE_REQUEST';
export const COMPANY_CANDIDATES_POOL_PROFILE_UPLOAD_PHOTO_REQUEST = 'COMPANY_CANDIDATES_POOL_PROFILE_UPLOAD_PHOTO_REQUEST';

export const COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST = 'COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST';
export const COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST_SUCCESS = 'COMPANY_CANDIDATES_POOL_GROUP_CHAT_DATA_REQUEST_SUCCESS';
export const COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST = 'COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST';
export const COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST_SUCCESS = 'COMPANY_CANDIDATES_POOL_GROUP_CHATS_INFO_REQUEST_SUCCESS';

export const COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST = 'COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST';
export const COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS = 'COMPANY_POOL_DIRECT_CHAT_DATA_REQUEST_SUCCESS';
export const COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST = 'COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST';
export const COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS = 'COMPANY_POOL_DIRECT_CHATS_INFO_REQUEST_SUCCESS';

export const COMPANY_POOL_CHAT_DATA_REQUEST = 'COMPANY_POOL_CHAT_DATA_REQUEST';
export const COMPANY_POOL_CHAT_DATA_REQUEST_SUCCESS = 'COMPANY_POOL_CHAT_DATA_REQUEST_SUCCESS';
export const COMPANY_POOL_CHATS_INFO_REQUEST = 'COMPANY_POOL_CHATS_INFO_REQUEST';
export const COMPANY_POOL_CHATS_INFO_REQUEST_SUCCESS = 'COMPANY_POOL_CHATS_INFO_REQUEST_SUCCESS';

/* Company shifts */
export const COMPANY_SHIFTS_CREATE_REQUEST = 'COMPANY_SHIFTS_CREATE_REQUEST';
export const COMPANY_SHIFTS_GET_SHIFTS_REQUEST = 'COMPANY_SHIFTS_GET_SHIFTS_REQUEST';
export const COMPANY_SHIFTS_GET_SHIFT_REQUEST = 'COMPANY_SHIFTS_GET_SHIFT_REQUEST';
export const COMPANY_SHIFTS_GET_SHIFT_TEMPLATES_REQUEST = 'COMPANY_SHIFTS_GET_SHIFT_TEMPLATES_REQUEST';
export const COMPANY_SHIFTS_REMOVE_SHIFT_TEMPLATE_REQUEST = 'COMPANY_SHIFTS_REMOVE_SHIFT_TEMPLATE_REQUEST';
export const COMPANY_SHIFTS_CLOSE_SHIFT_REQUEST = 'COMPANY_SHIFTS_CLOSE_SHIFT_REQUEST';
export const COMPANY_SHIFTS_DELETE_SHIFT_REQUEST = 'COMPANY_SHIFTS_DELETE_SHIFT_REQUEST';

/* Shift Creator */
export const SHIFT_CREATOR_CLEAR = 'SHIFT_CREATOR_CLEAR';
export const SHIFT_CREATOR_USE_TEMPLATE = 'SHIFT_CREATOR_USE_TEMPLATE';
export const SHIFT_CREATOR_SET_POSITION = 'SHIFT_CREATOR_SET_POSITION';
export const SHIFT_CREATOR_SET_DESCRIPTION = 'SHIFT_CREATOR_SET_DESCRIPTION';
export const SHIFT_CREATOR_SET_CANDIDATES_NUMBER = 'SHIFT_CREATOR_SET_CANDIDATES_NUMBER';
export const SHIFT_CREATOR_SET_LOCATION = 'SHIFT_CREATOR_SET_LOCATION';
export const SHIFT_CREATOR_SET_ACCESS_ENTITIES = 'SHIFT_CREATOR_SET_ACCESS_ENTITIES';
export const SHIFT_CREATOR_SET_DATE_AND_TIMES = 'SHIFT_CREATOR_SET_DATE_AND_TIMES';
export const SHIFT_CREATOR_SET_BOOK_SEPARATELY = 'SHIFT_CREATOR_SET_BOOK_SEPARATELY';
export const SHIFT_CREATOR_SET_SAVE_AS_TEMPLATE = 'SHIFT_CREATOR_SET_SAVE_AS_TEMPLATE';
export const SHIFT_CREATOR_SET_SALARY = 'SHIFT_CREATOR_SET_SALARY';
export const SHIFT_CREATOR_SAVE = 'SHIFT_CREATOR_SAVE';

export const COMPANY_SHIFTS_STORE_SCROLL_POSITION = 'COMPANY_SHIFTS_STORE_SCROLL_POSITION';

/* Candidate profile creator */

export const PROFILE_CREATOR_CLEAR_ALL_DATA = 'PROFILE_CREATOR_CLEAR_ALL_DATA';
export const PROFILE_CREATOR_ADD_CATEGORIES = 'PROFILE_CREATOR_ADD_CATEGORIES';
export const PROFILE_CREATOR_ADD_AVAILABILITY = 'PROFILE_CREATOR_ADD_AVAILABILITY';
export const PROFILE_CREATOR_ADD_GIG_LOCATION = 'PROFILE_CREATOR_ADD_GIG_LOCATION';
export const PROFILE_CREATOR_ADD_HOME_LOCATION = 'PROFILE_CREATOR_ADD_HOME_LOCATION';
export const PROFILE_CREATOR_ADD_EXPERIENCE = 'PROFILE_CREATOR_ADD_EXPERIENCE';
export const PROFILE_CREATOR_ADD_EDUCATION = 'PROFILE_CREATOR_ADD_EDUCATION';
export const PROFILE_CREATOR_ADD_CURRENT_OCCUPATION = 'PROFILE_CREATOR_ADD_CURRENT_OCCUPATION';
export const PROFILE_CREATOR_ADD_LANGUAGES = 'PROFILE_CREATOR_ADD_LANGUAGES';
export const PROFILE_CREATOR_COPY_DATA_FROM_PROFILE = 'PROFILE_CREATOR_COPY_DATA_FROM_PROFILE';

export const PROFILE_CREATOR_SAVE_PROFILE_REQUEST = 'PROFILE_CREATOR_SAVE_PROFILE_REQUEST';
export const PROFILE_CREATOR_SAVE_PROFILE_REQUEST_SUCCESS = 'PROFILE_CREATOR_SAVE_PROFILE_REQUEST_SUCCESS';
// noinspection JSUnusedGlobalSymbols
export const PROFILE_CREATOR_SAVE_PROFILE_REQUEST_FAIL = 'PROFILE_CREATOR_SAVE_PROFILE_REQUEST_FAIL';

/* Notifications */

export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';
export const NOTIFICATIONS_MINIMIZE = 'NOTIFICATIONS_MINIMIZE';
export const NOTIFICATIONS_REVIVE = 'NOTIFICATIONS_REVIVE';

export const PUSH_NOTIFICATION_RECEIVED = 'PUSH_NOTIFICATION_RECEIVED';

/* APP STATE ACTIONS */
export const ON_APP_STATE_CHANGE = 'ON_APP_STATE_CHANGE';
export const ON_INTERNET_CONNECTION_CHANGE = 'ON_INTERNET_CONNECTION_CHANGE';

/* USER STATUS CALL */
export const GET_USER_STATUS_REQUEST = 'GET_USER_STATUS_REQUEST';
export const GET_USER_STATUS_REQUEST_SUCCESS = 'GET_USER_STATUS_REQUEST_SUCCESS';

/* update */
export const BUNDLE_UPDATE_READY = 'BUNDLE_UPDATE_READY';
export const BUNDLE_RELOADED = 'BUNDLE_RELOADED';

/* rate app */
export const SET_RATE_APP_POPUP_TIMESTAMP = 'SET_RATE_APP_POPUP_TIMESTAMP';

export const TEST_REQUEST = ' JACEK_REQUEST';
export const SEARCH_LOCATION_BY_COORDINATES = ' SEARCH_LOCATION_BY_COORDINATES';
export const SEARCH_LOCATIONS_BY_NAME = ' SEARCH_LOCATIONS_BY_NAME';

/* featureNameRequest */
export const GET_ENABLED_FEATURES_REQUEST = 'GET_ENABLED_FEATURES_REQUEST';
export const GET_ENABLED_FEATURES_REQUEST_SUCCESS = 'GET_ENABLED_FEATURES_REQUEST_SUCCESS';

/* feature tracking */
export const SET_FEATURE_INFO_SHOWN = 'SET_FEATURE_INFO_SHOWN';

/* configuration */
export const GET_CONFIGURATION_BY_KEY = 'GET_CONFIGURATION_BY_KEY';

/* log */
export const STORE_LOG = 'STORE_LOG';

/* admin gigs */
export const ADMIN_GIGS_FIND_FILTER_FORM_SUBMIT = 'ADMIN_GIGS_FIND_GIGS_FORM_SUBMIT';
export const ADMIN_GIGS_FIND_GIGS_REQUEST = 'ADMIN_GIGS_FIND_GIGS_REQUEST';
export const ADMIN_GIGS_FIND_GIGS_REQUEST_SUCCESS = 'ADMIN_GIGS_FIND_GIGS_REQUEST_SUCCESS';
export const ADMIN_GIG_DETAILS_CLEAR = 'ADMIN_GIG_DETAILS_CLEAR';
export const ADMIN_GIG_DETAILS_REQUEST = 'ADMIN_GIG_DETAILS_REQUEST';
export const ADMIN_GIG_DETAILS_REQUEST_SUCCESS = 'ADMIN_GIG_DETAILS_REQUEST_SUCCESS';
export const ADMIN_GIG_PAUSE_REQUEST = 'ADMIN_GIG_PAUSE_REQUEST';
export const ADMIN_GIG_PAUSE_REQUEST_SUCCESS = 'ADMIN_GIG_PAUSE_REQUEST_SUCCESS';

/* admin candidates */
export const ADMIN_CANDIDATES_FIND_FILTER_FORM_SUBMIT = 'ADMIN_CANDIDATES_FIND_FILTER_FORM_SUBMIT';
export const ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST = 'ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST';
export const ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST_SUCCESS = 'ADMIN_CANDIDATES_FIND_CANDIDATES_REQUEST_SUCCESS';

/* admin companies */
export const ADMIN_COMPANIES_SIGN_UP_COMPANY_REQUEST = 'ADMIN_COMPANIES_SIGN_UP_COMPANY_REQUEST';
export const ADMIN_COMPANY_FIND_COMPANY_REQUEST = 'ADMIN_COMPANY_FIND_COMPANY_REQUEST';
export const ADMIN_COMPANY_FIND_COMPANY_REQUEST_SUCCESS = 'ADMIN_COMPANY_FIND_COMPANY_REQUEST_SUCCESS';
export const ADMIN_COMPANY_FIND_FILTER_FORM_SUBMIT = 'ADMIN_COMPANY_FIND_FILTER_FORM_SUBMIT';
export const ADMIN_COMPANY_DETAILS_REQUEST = 'ADMIN_COMPANY_DETAILS_REQUEST';
export const ADMIN_COMPANY_DETAILS_CLEAR = 'ADMIN_COMPANY_DETAILS_CLEAR';
export const ADMIN_COMPANY_DETAILS_REQUEST_SUCCESS = 'ADMIN_COMPANY_DETAILS_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_SET_GIG_LIMIT_REQUEST = 'ADMIN_SETTINGS_SET_GIG_LIMIT_REQUEST';
export const ADMIN_SETTINGS_SET_GIG_LIMIT_REQUEST_SUCCESS = 'ADMIN_SETTINGS_SET_GIG_LIMIT_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST = 'ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST';
export const ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST_SUCCESS = 'ADMIN_SETTINGS_GET_GIG_LIMIT_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_GET_REQUEST = 'ADMIN_SETTINGS_GET_REQUEST';
export const ADMIN_SETTINGS_GET_REQUEST_SUCCESS = 'ADMIN_SETTINGS_GET_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_FORM_SUBMIT = 'ADMIN_SETTINGS_FORM_SUBMIT';
export const ADMIN_SETTINGS_CREATE_CITY_REQUEST = 'ADMIN_SETTINGS_CREATE_CITY_REQUEST';
export const ADMIN_SETTINGS_CREATE_CITY_REQUEST_SUCCESS = 'ADMIN_SETTINGS_CREATE_CITY_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_GET_CITIES_REQUEST = 'ADMIN_SETTINGS_GET_CITIES_REQUEST';
export const ADMIN_SETTINGS_GET_CITIES_REQUEST_SUCCESS = 'ADMIN_SETTINGS_GET_CITIES_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_UPDATE_CITY_REQUEST = 'ADMIN_SETTINGS_UPDATE_CITY_REQUEST';
export const ADMIN_SETTINGS_UPDATE_CITY_REQUEST_SUCCESS = 'ADMIN_SETTINGS_UPDATE_CITY_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_CREATE_CITY_REQUEST_FAIL = 'ADMIN_SETTINGS_CREATE_CITY_REQUEST_FAIL';
export const ADMIN_SETTINGS = 'ADMIN_SETTINGS';
export const ADMIN_SETTINGS_SET_SETTING_REQUEST = 'ADMIN_SETTINGS_SET_SETTING_REQUEST';
export const ADMIN_SETTINGS_SET_SETTING_REQUEST_SUCCESS = 'ADMIN_SETTINGS_SET_SETTING_REQUEST_SUCCESS';
export const ADMIN_SETTINGS_GET_FORTNOX_CANDIDATES_REQUEST = 'ADMIN_SETTINGS_GET_FORTNOX_CANDIDATES_REQUEST';
export const ADMIN_SETTINGS_GET_FORTNOX_CANDIDATES_REQUEST_SUCCESS = 'ADMIN_SETTINGS_GET_FORTNOX_CANDIDATES_REQUEST_SUCCESS';

/* admin associations */
export const ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST = 'ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST';
export const ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS = 'ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS';
export const ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_FAIL = 'ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_FAIL';
export const ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST = 'ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST';
export const ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_SUCCESS = 'ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_SUCCESS';
export const ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_FAIL = 'ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_FAIL';
export const ADMIN_ASSOCIATIONS_FIND_FILTER_FORM_SUBMIT = 'ADMIN_ASSOCIATIONS_FIND_FILTER_FORM_SUBMIT';
export const ADMIN_ASSOCIATIONS_RESET_STATUS = 'ADMIN_ASSOCIATIONS_RESET_STATUS';
export const ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST = 'ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST';
export const ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST_SUCCESS = 'ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST_SUCCESS';
export const ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST_FAIL = 'ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST_FAIL';
export const ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST = 'ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST';
export const ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST_SUCCESS = 'ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST_SUCCESS';
export const ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST_FAIL = 'ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST_FAIL';

/* client side associations */
export const ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST = 'ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST';
export const ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS = 'ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS';
export const ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_FAIL = 'ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_FAIL';
export const ASSOCIATIONS_RESET_STATUS = 'ASSOCIATIONS_RESET_STATUS';
export const ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST = 'ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST';
export const ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST_SUCCESS = 'ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST_SUCCESS';
export const ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST_FAIL = 'ASSOCIATIONS_UPDATE_CANDIDATE_ASSOCIATION_REQUEST_FAIL';

/* admin notifications */
export const ADMIN_CANDIDATES_SEND_NOTIFICATION_REQUEST = 'ADMIN_CANDIDATES_SEND_NOTIFICATION_REQUEST';

/* admin profile */
export const ADMIN_PROFILE_REQUEST = 'ADMIN_PROFILE_REQUEST';
export const ADMIN_PROFILE_REQUEST_SUCCESS = 'ADMIN_PROFILE_REQUEST_SUCCESS';
