import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import queryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import Header from '../components/header/Header';
import PrimaryText from '../components/PrimaryText';
import Button, { BUTTON_TYPES } from '../components/buttons/Button';
import Routing from '../../core/routing/index';
import { spacings } from '../styles/base.style';
import containerStyles from '../styles/container.style';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headers: {
    marginBottom: spacings.lg,
  },
  text: {
    marginBottom: spacings.lg,
    textAlign: 'center',
  },
});

const ErrorScreen = ({
  translate, history, location,
}) => {
  const { titleKey, textKey } = queryString.parse(location.search);

  const onPress = () => {
    history.goBack();
  };

  return (
    <View style={containerStyles.flexVerticalCentered}>
      <Header style={styles.headers}>{translate(titleKey)}</Header>
      {!!textKey && <PrimaryText style={styles.text}>{translate(textKey)}</PrimaryText>}
      <Button onPress={onPress} title={translate('generic.goBack')} type={BUTTON_TYPES.SECONDARY} />
    </View>
  );
};

ErrorScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(withLocalize(ErrorScreen));
