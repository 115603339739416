import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { LinkButton } from '../../../../common/components/index';
import GigCreatorHeader from '../../GigCreatorHeader';
import FormikFieldPrimaryTextInput from '../../../../common/components/form/FormikFieldPrimaryTextInput';
import FormikWithValidation from '../../../../common/components/form/FormikWithValidation';
import GigCreatorStepDescriptionValidation, { MAX_DESCRIPTION_LENGTH, MAX_POSITION_LENGTH, MAX_CAREER_URL_LENGTH } from './GigCreatorStepDescriptionValidation';
import Container from '../../../../common/components/Container';
import FormikFieldPrimaryInputTextNextSupport from '../../../../common/components/form/FormikFieldPrimaryInputTextNextSupport';
import AutoScrollableKeyboardAwareScrollView from '../../../../common/keyboard/AutoScrollableKeyboardAwareScrollView';
import KeyboardAwareFlexWrapper from '../../../../common/components/KeyboardAwareFlexWrapper';
import { spacings } from '../../../../common/styles/base.style';
import Button from '../../../../common/components/buttons/Button';
import FeatureToggle from '../../../../common/components/FeatureToggle';

class GigCreatorStepDescriptionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      translate, initialValues, onSubmit, stepNumber, isEdit, nextScreen, maxStepsNumber,
    } = this.props;

    return (
      <Container>
        <FormikWithValidation
          validation={GigCreatorStepDescriptionValidation}
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, status, isBtnDisabled }) => (
            <>
              <GigCreatorHeader
                isEdit={isEdit}
                stepNo={stepNumber}
                maxStepsNumber={maxStepsNumber}
                title={translate('companyGigCreator.description.header')}
                onRightButtonPress={handleSubmit}
                isRightButtonDisabled={isBtnDisabled}
              />
              <AutoScrollableKeyboardAwareScrollView>
                <KeyboardAwareFlexWrapper>
                  <FormikFieldPrimaryInputTextNextSupport
                    name="position"
                    label={translate('companyGigCreator.description.positionTitle')}
                    placeholder={translate('companyGigCreator.description.positionPlaceholder')}
                    maxLength={MAX_POSITION_LENGTH}
                    counter
                    nextComponent={this.state.descriptionInputRef}
                    style={{ label: { paddingTop: spacings.lg }, input: { paddingTop: spacings.lg } }}
                  />
                  <FormikFieldPrimaryTextInput
                    innerRef={(ref) => !this.state.descriptionInputRef && this.setState({ descriptionInputRef: ref })}
                    name="description"
                    multiline
                    numberOfLines={Platform.OS === 'web' ? 20 : 2}
                    label={translate('companyGigCreator.description.descriptionTitle')}
                    placeholder={translate('companyGigCreator.description.descriptionPlaceholder')}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                    counter
                    autoscroll
                    scrollEnabled={false}
                  />
                  <FormikFieldPrimaryTextInput
                    name="candidatesNumber"
                    keyboardType="numeric"
                    label={translate('companyGigCreator.description.candidatesNumberTitle')}
                    selectTextOnFocus
                    clearTextOnFocus
                    returnKeyType="done"
                    autoscroll
                  />
                  {/* <FeatureToggle requestedFeature="CAREER_URL">
                    <FormikFieldPrimaryTextInput
                      name="careerUrl"
                      keyboardType="url"
                      label={translate('companyGigCreator.description.careerUrlTitle')}
                      placeholder={translate('companyGigCreator.description.careerUrlPlaceholder')}
                      maxLength={MAX_CAREER_URL_LENGTH}
                      counter
                      selectTextOnFocus
                      returnKeyType="done"
                      autoCapitalize="none"
                      autoscroll
                    />
                  </FeatureToggle> */}
                </KeyboardAwareFlexWrapper>

                {isEdit ? (
                  <Button
                    title={translate('generic.save')}
                    disabled={isBtnDisabled}
                    onPress={handleSubmit}
                  />
                ) : (
                  <LinkButton
                    to={nextScreen}
                    title={translate('generic.next')}
                    doRedirect={status && status.redirect}
                    disabled={isBtnDisabled}
                    onPress={handleSubmit}
                  />
                )}
              </AutoScrollableKeyboardAwareScrollView>
            </>
          )}
        />
      </Container>
    );
  }
}

GigCreatorStepDescriptionScreen.propTypes = {
  translate: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    position: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    candidatesNumber: PropTypes.string.isRequired,
  }).isRequired,
  nextScreen: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  maxStepsNumber: PropTypes.number.isRequired,
};

export default withLocalize(GigCreatorStepDescriptionScreen);
