import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import CompanyProfileEditScreen from './CompanyProfileEditScreen';
import { companyProfilePropType } from '../../../constants/propTypes';
import Routing from '../../../core/routing/index';
import { getProfile, saveProfile } from '../companyProfileActions';
import { showConfirmAlert } from '../../../common/components/alert/alerts';
import { getAssociations } from '../../../association/AssociationActions';
import { getAvailableMunicipalities } from '../../../common/gig/city/gigCitiesActions';

const { withRouter } = Routing;

class CompanyProfileEditContainer extends React.Component {
  constructor(props) {
    super(props);
    this.isFormChanged = false;
  }

  componentDidMount() {
    this.props.getAvailableMunicipalities();
  }

  onFormChange = (prevState, { values }) => {
    this.isFormChanged = JSON.stringify(values) !== JSON.stringify(this.props.profile);
  };

  handleBack = () => {
    if (this.isFormChanged) {
      showConfirmAlert(
        this.props.translate,
        {
          confirmKey: 'generic.discard',
          cancelKey: 'generic.cancel',
          textKey: 'companyMyProfile.doYouWantToDiscard',
          titleKey: 'companyMyProfile.unsavedChanges',
        },
        () => this.goBack()
      );
      return false;
    }
    this.goBack();
    return false;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleSave = (profileData, { setErrors, setSubmitting }) => {
    const { saveProfileAction } = this.props;
    saveProfileAction(profileData).then((response) => {
      if (!response.isError) {
        this.goBack();
      } else {
        setErrors(response.errors);
        setSubmitting(false);
      }
    });
  };

  render() {
    return (
      <CompanyProfileEditScreen
        profile={this.props.profile}
        handleSave={this.handleSave}
        handleBack={this.handleBack}
        onFormChange={this.onFormChange}
        availableCities={this.props.availableMunicipalities}
      />
    );
  }
}

CompanyProfileEditContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  profile: companyProfilePropType.isRequired,
  saveProfileAction: PropTypes.func.isRequired,
  availableMunicipalities: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAvailableMunicipalities: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.company.get('profile').toJS(),
  availableMunicipalities: state.gigCities.toJS(),
});

export default withRouter(
  connect(mapStateToProps, { saveProfileAction: saveProfile,
getProfile,
    getAssociations,
getAvailableMunicipalities })(withLocalize(CompanyProfileEditContainer))
);
