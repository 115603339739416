import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import EditableField from '../../../../common/components/EditableField';
import CandidateProfileEditDetailsFormWithFooter from './CandidateProfileEditDetailsFormWithFooter';
import getProfile, { candidateProfileSetGigLocation } from '../../CandidateProfileActions';
import CandidateProfileLocationForm from '../../forms/location/CandidateProfileLocationForm';
import { candidateLocationPropType, gigCityPropType } from '../../../../constants/propTypes';
import { getAvailableGigCities } from '../../../../common/gig/city/gigCitiesActions';
import { findCityByKey, getTranslationForCity } from '../../../../common/gig/city/gigCitiesHelper';
import { getAssociations } from '../../../../association/AssociationActions';

class EditableFieldLocationModalAware extends React.Component {
  componentDidMount() {
    this.props.getAvailableGigCities();
  }

  openModal = () => {
    const {
      translate, closeModal, location,
    } = this.props;

    this.props.setModalContent(
      translate('candidateProfile.details.locationLabel'),

      <CandidateProfileEditDetailsFormWithFooter
        component={CandidateProfileLocationForm}
        closeModal={closeModal}
        cities={this.props.availableCities.map((city) => ({ value: city.key, label: getTranslationForCity(city) }))}
        location={location}
        handleSubmit={({ cityKey }) => {
          this.props.candidateProfileSetGigLocation({ location: { cityV2: findCityByKey(this.props.availableCities, cityKey) } })
            .then((response) => {
              if (!response.isError) {
                this.props.getProfile();
                closeModal();
              }
            });
        }}

      />,
    );
  };

  render() {
    const { translate, location } = this.props;
    return (
      <EditableField
        label={translate('candidateProfile.details.locationLabel')}
        value={location?.cityV2?.name}
        onPress={this.openModal}
      />
    );
  }
}

EditableFieldLocationModalAware.propTypes = {
  translate: PropTypes.func.isRequired,
  getAvailableGigCities: PropTypes.func.isRequired,
  availableCities: PropTypes.arrayOf(gigCityPropType),
  candidateProfileSetGigLocation: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  location: candidateLocationPropType.isRequired,
  getProfile: PropTypes.func.isRequired,
};

EditableFieldLocationModalAware.defaultProps = {
  availableCities: [],
};

const mapStateToProps = (state) => ({
  location: state.candidate.get('profile')?.get('location')?.toJS() ?? null,
  availableCities: state.gigCities.toJS(),
});

export default connect(mapStateToProps,
  { candidateProfileSetGigLocation, getAvailableGigCities, getProfile, getAssociations })(withLocalize(EditableFieldLocationModalAware));
