import { List, Map, fromJS } from 'immutable';
import {
  ADMIN_ASSOCIATIONS_RESET_STATUS,
  ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST,
  ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_FAIL,
  ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_SUCCESS,
  ADMIN_ASSOCIATIONS_FIND_FILTER_FORM_SUBMIT,
  ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS,
  ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST_SUCCESS,
  ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST_SUCCESS,
} from '../../constants/actionTypes';

const INITIAL_ADMIN_ASSOCIATION_FILTERS = {
  associationsList: List(),
  isSuccess: false,
  isLoading: false,
  isError: false,
  formValues: fromJS({
    city: undefined, limit: '100', page: '1',
  }),
  errorMessage: '',
  editAssociation: null
};

export default function adminAssociationsReducer(state = new Map(INITIAL_ADMIN_ASSOCIATION_FILTERS), action) {
  switch (action.type) {
    case ADMIN_ASSOCIATIONS_FIND_FILTER_FORM_SUBMIT:
      return state.set('formValues', fromJS(action.formValues));
    case ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST:
      return state.set('isLoading', true);
    case ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_SUCCESS:
      return state.set('isLoading', false).set('isSuccess', true);
    case ADMIN_ASSOCIATIONS_POST_ASSOCIATIONS_REQUEST_FAIL:
      return state.set('isLoading', false).set('isSuccess', false).set('isError', true);
    case ADMIN_ASSOCIATIONS_RESET_STATUS:
      return state.set('isLoading', false).set('isSuccess', false).set('isError', false).set('errorMessage', '');
    case ADMIN_ASSOCIATIONS_GET_ASSOCIATIONS_REQUEST_SUCCESS:
      return state.set('associationsList', action.payload);
    case ADMIN_ASSOCIATIONS_GET_ASSOCIATION_REQUEST_SUCCESS:
      return state.set('editAssociation', action.payload);
    case ADMIN_ASSOCIATIONS_DELETE_ASSOCIATION_REQUEST_SUCCESS:
      return state.set('isLoading', false).set('isSuccess', true);
    default:
      return state;
  }
}
