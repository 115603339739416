import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import Touchable from '../../../common/components/Touchable';
import ImageComponent from '../../../common/components/image/ImageComponent';

const undoIconActive = require('../../../assets/icons/singlegigview/undo_active.png');
const undoIconInactive = require('../../../assets/icons/singlegigview/undo_inactive.png');
const favouriteIcon = require('../../../assets/icons/singlegigview/btn_shortlist.png');
const explainIcon = require('../../../assets/icons/singlegigview/explain_active.png');
const rejectIcon = require('../../../assets/icons/singlegigview/Reject_active.png');

const styles = StyleSheet.create({
  buttonsContainer: {
    height: 100,
    zIndex: 3000,
    elevation: 99,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  decision: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const SingleFindGigsButtons = ({
  canMoveBack, moveBack, like, reject, showInfo,
}) => (
  <View style={styles.buttonsContainer}>
    <Touchable
      disabled={!canMoveBack}
      style={styles.backButton}
      onPress={moveBack}
    >
      {canMoveBack && <ImageComponent size="xxs" image={undoIconActive} style={{ image: { width: 50, height: 50 } }} />}
      {!canMoveBack && <ImageComponent size="xxs" image={undoIconInactive} style={{ image: { width: 50, height: 50 } }} />}
    </Touchable>
    <View style={styles.decision}>
      <Touchable
        style={[{ left: 5, zIndex: 0 }]}
        onPress={reject}
      >
        <ImageComponent size="md" image={rejectIcon} style={{ image: { width: 90, height: 90 } }} />
      </Touchable>
      <Touchable style={[{ left: -5, zIndex: 1 }]} onPress={like}>
        <ImageComponent size="md" image={favouriteIcon} style={{ image: { width: 90, height: 90 } }} />
      </Touchable>
    </View>
    <Touchable
      onPress={showInfo}
    >
      <ImageComponent size="xxs" image={explainIcon} style={{ image: { width: 50, height: 50 } }} />
    </Touchable>
  </View>
);

SingleFindGigsButtons.propTypes = {
  moveBack: PropTypes.func.isRequired,
  like: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  showInfo: PropTypes.func.isRequired,
  canMoveBack: PropTypes.bool.isRequired,
};

export default SingleFindGigsButtons;
