import { Map } from 'immutable';
import {
  GET_USER_STATUS_REQUEST_SUCCESS,
  REGISTER_DEVICE_FOR_PUSH_REQUEST_SUCCESS,
  COMPANY_PAYMENTS_SUBSCRIBE_REQUEST_SUCCESS,
  COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST_SUCCESS,
  COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST_SUCCESS,
} from '../../../constants/actionTypes';

const INITIAL_MAP = {
  pushTokenStatus: undefined,
  subscription: undefined,
  settings: Map({ notifications: { enabled: false } }),
  internalUse: undefined,
};

export default function getUserSettingsReducer(state = Map(INITIAL_MAP), action) {
  switch (action.type) {
    case GET_USER_STATUS_REQUEST_SUCCESS:
      return state
        .set('settings', action.payload.settings)
        .set('subscription', action.payload.subscription)
        .set('pushTokenStatus', action.payload.installationSettings.pushTokenStatus)
        .set('tenantId', action.payload.tenantId);
    case REGISTER_DEVICE_FOR_PUSH_REQUEST_SUCCESS:
      return state.set('pushTokenStatus', 'ACTIVE');
    case COMPANY_PAYMENTS_SUBSCRIBE_REQUEST_SUCCESS:
    case COMPANY_PAYMENTS_CANCEL_SUBSCRIPTION_REQUEST_SUCCESS:
    case COMPANY_PAYMENTS_CHANGE_PRICING_PLAN_REQUEST_SUCCESS:
      return state.set('subscription', action.payload);
    default:
      return state;
  }
}
