import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Alert } from 'react-native';
import Routing from '../../core/routing/index';
import CompanyPostGigSplashScreen from './CompanyPostGigSplashScreen';
import { logoutUser } from '../../common/auth/authActions';
import { COMPANY_CREATE_GIG } from '../../constants/routes';
import { gigCreatorClearAllData, gigCreatorCheckGigLimit } from './gigCreatorActions';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

const { Redirect } = Routing;

// show modal first time with dismiss state stored

class CompanyPostGigContainer extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      splashShown: false,
    };
    this.props.gigCreatorClearAllData();
    this.props.gigCreatorCheckGigLimit();
  }

  componentDidMount() {
    googleAnalytics.trackCompanyEvent(ACTIONS.COMPANY.CREATE_GIG_FLOW.WELCOME_SCREEN);
  }

  onSplashDismiss = () => {
    console.log(`onCheckGigLimitLeft${this.props.gigsLeft}`);
    this.setState({ splashShown: true });

    // if (this.props.gigsLeft <= 0) {
    //   this.showAlert()
    // } else {
    //   this.setState({ splashShown: true });
    // }
  };

  // eslint-disable-next-line class-methods-use-this

  showAlert = () => {
    Alert.alert(
      'Error',
      'You have reached your free gig limit. To continue, please subscribe and proceed with the payment.',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Subscribe',
          onPress: () => console.log('OK Pressed'),
        },
      ],
      { cancelable: false }
    );
  };

  renderNormal = () => <Redirect push to={COMPANY_CREATE_GIG} />;

  renderSplash() {
    return <CompanyPostGigSplashScreen onButtonPress={this.onSplashDismiss} {...this.props} />;
  }

  render() {
    return this.state.splashShown ? this.renderNormal() : this.renderSplash();
  }
}

const mapStateToProps = (state) => {
  const authenticated = state.auth.get('authenticated');
  // const {
  //   gig: {
  //     gigsLeft,
  //   },
  // } = state.companyGig.get('gigCreator').toJS();

  const { gigsLeft } = state.companyGig.get('gigCreator').toJS();
  console.log(`gigsLeftgigsLeftttttt ${JSON.stringify(gigsLeft)}`);
  // const grantedFeatures = state.grantedFeatures.get('grantedFeatures').toJS();
  return { gigsLeft, authenticated };
};

CompanyPostGigContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  dispatchLogoutUser: PropTypes.func.isRequired,
  gigCreatorClearAllData: PropTypes.func.isRequired,
  gigCreatorCheckGigLimit: PropTypes.func.isRequired,
  gigsLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect(mapStateToProps, {
  dispatchLogoutUser: logoutUser,
  gigCreatorClearAllData,
  gigCreatorCheckGigLimit,
})(withLocalize(CompanyPostGigContainer));
