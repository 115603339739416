import validator from 'validator';

export default [
  {
    field: 'startTime',
    custom: (value) => !!value,
    message: 'required',
  },
  {
    field: 'endTime',
    custom: (value) => !!value,
    message: 'required',
  },
  {
    field: 'salaryBonus',
    custom: (value) => value === null || value === '' || validator.isInt(value),
    message: 'wrong_format',
  },
  {
    field: 'salaryBonus',
    custom: (value) => value !== null && value !== '',
    message: 'null_value',
  },
];
