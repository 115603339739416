import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { OFFERS_LIST_KEY } from '../candidateMyGigsReducer';
import { matchedGigForCandidatePropType, msisdnPropType } from '../../../../constants/propTypes';
import { clearCandidateGigs, getCandidateGigs, removeNewContentIndicator } from '../candidateMyGigsActions';
import { GIG_CANDIDATE_STATUS } from '../../../../constants/matchingStatus';
import PrimaryFlatList from '../../../../common/components/PrimaryFlatList';
import CandidateAcceptOfferModal from './CandidateAcceptOfferModal';
import CandidateViewOfferModal from './CandidateViewOfferModal';
import GigListItem from '../GigListItem';
import ListItem from '../../../../common/components/ListItem';
import getProfile from '../../../profile/CandidateProfileActions';
import { CANDIDATE_PROFILE_EDIT, CANDIDATE_GIG_CHAT } from '../../../../constants/routes';
import { showConfirmAlert } from '../../../../common/components/alert/alerts';

class CandidateMyGigsOffersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptOfferModalVisible: false,
      viewOfferModalVisible: false,
      modalGigId: undefined,
    };
  }

  componentDidMount() {
    this.props.getCandidateGigs(
      OFFERS_LIST_KEY,
      [GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER, GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED],
      false,
    ).then(() => {
      this.setTabInactive();
    });
  }

  componentWillUnmount() {
    this.props.clearCandidateGigs(OFFERS_LIST_KEY);
  }

  setTabInactive = () => {
    if (this.props.newContentIndicators.includes(GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER)) {
      this.props.removeNewContentIndicator(GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER);
    }
  };

  openAcceptOfferModal = (gigId) => {
    const { personalNumber, msisdn, bankAccountNo, clearingNumber } = this.props;

    if (personalNumber === undefined || msisdn === undefined || bankAccountNo === undefined || clearingNumber === undefined) {
      this.props.getProfile().then(({ payload: profile }) => {
        this.checkProfileAndOpenAcceptOfferModal(profile.personalNumber, profile.msisdn, gigId, profile.bankAccountNo, profile.clearingNumber);
      });
    } else {
      this.checkProfileAndOpenAcceptOfferModal(personalNumber, msisdn, gigId, bankAccountNo, clearingNumber);
    }
  };

  openViewOfferModal = (gigId) => {
    this.setState({ viewOfferModalVisible: true, modalGigId: gigId });
  };

  checkProfileAndOpenAcceptOfferModal = (personalNumber, msisdn, gigId, bankAccountNo, clearingNumber) => {
    const { translate } = this.props;
    if ((personalNumber === null || msisdn === null) || (bankAccountNo === null || clearingNumber === null)) {
      let textKey;
      if ((personalNumber == null && msisdn == null) || (bankAccountNo === null || clearingNumber === null)) {
        textKey = 'candidateMyGigs.missingDataPopup.missingMsisdnAndPersonalNumber';
      } else if (personalNumber == null) {
        textKey = 'candidateMyGigs.missingDataPopup.missingPersonalNumber';
      } else {
        textKey = 'candidateMyGigs.missingDataPopup.missingMsisdn';
      }
      showConfirmAlert(translate, {
        confirmKey: 'candidateMyGigs.missingDataPopup.confirm',
        cancelKey: 'candidateMyGigs.missingDataPopup.cancel',
        titleKey: 'candidateMyGigs.missingDataPopup.title',
        cancelStyle: false,
        textKey,
      }, () => {
        this.props.history.push(CANDIDATE_PROFILE_EDIT);
      });
    } else {
      this.setState({ acceptOfferModalVisible: true, modalGigId: gigId });
    }
  };

  handleOpenModal = (gigId, editable) => (editable ? this.openAcceptOfferModal(gigId) : this.openViewOfferModal(gigId));

  startChat(gigId) {
    this.props.history.push(CANDIDATE_GIG_CHAT.replace(':id', gigId));
  }

  renderAcceptOfferModal() {
    const { acceptOfferModalVisible, modalGigId } = this.state;
    return (
      <CandidateAcceptOfferModal
        visible={acceptOfferModalVisible}
        closeModal={() => (this.setState({ acceptOfferModalVisible: false }))}
        gigId={modalGigId}
      />
    );
  }

  renderViewOfferModal() {
    const { viewOfferModalVisible, modalGigId } = this.state;
    return (
      <CandidateViewOfferModal
        visible={viewOfferModalVisible}
        closeModal={() => (this.setState({ viewOfferModalVisible: false }))}
        gigId={modalGigId}
      />
    );
  }

  render() {
    const { gigs } = this.props;
    return (
      <>
        <PrimaryFlatList
          emptyListTextKey="candidateMyGigs.noOffers"
          data={gigs}
          keyExtractor={(item) => item.gigId}
          renderItem={({ item: matchedGig }) => (
            <ListItem
              renderContent={() => (
                <GigListItem
                  gig={matchedGig}
                  online={this.props.usersPresence[matchedGig.createdBy.userId]}
                  internalFunctions={{
                    openModal: (gigId, editable) => this.handleOpenModal(gigId, editable),
                    startChat: () => this.startChat(matchedGig.gigId),
                  }}
                />
              )}
            />
          )}
        />
        {this.renderAcceptOfferModal()}
        {this.renderViewOfferModal()}
      </>
    );
  }
}

CandidateMyGigsOffersView.propTypes = {
  getCandidateGigs: PropTypes.func.isRequired,
  clearCandidateGigs: PropTypes.func.isRequired,
  removeNewContentIndicator: PropTypes.func.isRequired,
  newContentIndicators: PropTypes.arrayOf(PropTypes.string).isRequired,
  gigs: PropTypes.arrayOf(matchedGigForCandidatePropType),
  getProfile: PropTypes.func.isRequired,
  personalNumber: PropTypes.string,
  msisdn: msisdnPropType,
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  usersPresence: PropTypes.object.isRequired,
  bankAccountNo: PropTypes.string,
  clearingNumber: PropTypes.string,
};

CandidateMyGigsOffersView.defaultProps = {
  gigs: null,
  personalNumber: undefined,
  msisdn: undefined,
  bankAccountNo: undefined,
  clearingNumber: undefined,
};

const mapStateToProps = (state) => {
  const profile = state.candidate.get('profile').toJS();
  return ({
    gigs: state.candidateMyGigs.get(OFFERS_LIST_KEY) ? state.candidateMyGigs.get(OFFERS_LIST_KEY).toJS() : null,
    personalNumber: profile.personalNumber,
    msisdn: profile.msisdn,
    bankAccountNo: profile.bankAccountNo,
    clearingNumber: profile.clearingNumber,
    newContentIndicators: state.candidateMyGigs.get('newContentIndicators'),
    usersPresence: state.chat.get('usersPresence').toJS(),
  });
};

export default connect(mapStateToProps, {
  getCandidateGigs,
  clearCandidateGigs,
  getProfile,
  removeNewContentIndicator,
})(withLocalize(CandidateMyGigsOffersView));
