export const GIG_CANDIDATE_STATUS = {
  SUGGESTED: 'SUGGESTED',
  APPLIED: 'APPLIED',
  PENDING_SHORTLIST_APPROVAL: 'PENDING_SHORTLIST_APPROVAL',
  SHORTLISTED: 'SHORTLISTED',
  PENDING_GIG_OFFER: 'PENDING_GIG_OFFER',
  GIG_OFFER_ACCEPTED: 'GIG_OFFER_ACCEPTED',
  HIRED: 'HIRED',
  REJECTED_BY_CANDIDATE: 'REJECTED_BY_CANDIDATE',
  REJECTED_BY_COMPANY: 'REJECTED_BY_COMPANY',
};

export const GIG_CANDIDATE_STATUSES_WITH_CHAT_AVAILABLE = [
  GIG_CANDIDATE_STATUS.SHORTLISTED,
  GIG_CANDIDATE_STATUS.PENDING_GIG_OFFER,
  GIG_CANDIDATE_STATUS.GIG_OFFER_ACCEPTED,
  GIG_CANDIDATE_STATUS.HIRED,
];

export const MATCHING_SOURCE = {
  SUGGESTED_CANDIDATE: 'SUGGESTED_CANDIDATE',
  CANDIDATE_APPLY: 'CANDIDATE_APPLY',
};

export const EMPLOYMENT_CHANNEL = {
  COMPANY: 'COMPANY',
  LETSGIG: 'LETSGIG',
};
