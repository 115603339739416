import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import { setLocale } from './setLocaleAction';
import config from '../../config/config';
// we import setup for extra languages
// eslint-disable-next-line no-unused-vars
import * as extraLocales from './extraLocales';

import getLanguageCode from './getLanguageCode';
import swedishTranslation from '../../translations/sv.json';
import englishTranslation from '../../translations/en.json';

const FALLBACK_LOCALE = config.defaultLocale;

export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Svenska', code: 'sv' },
];

class TranslationsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.setupLocalization();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.setLanguageInApp(this.props.locale);
    }
  }

  setLanguageInApp = (locale) => {
    this.props.setActiveLanguage(locale);
    moment.locale(locale);
  };

  setupLocalization() {
    this.props.initialize({
      languages,
      options: {
        renderInnerHtml: false,
        renderToStaticMarkup: false,
        ignoreTranslateChildren: false,
        onMissingTranslation: ({ translationId }) => translationId,
      },
    });
    this.props.addTranslationForLanguage(englishTranslation, 'en');
    this.props.addTranslationForLanguage(swedishTranslation, 'sv');

    if (this.props.locale) {
      this.setLanguageInApp(this.props.locale);
    } else {
      // locale have not been set: find it and store it
      getLanguageCode().then((langCode) => {
        const availableLangCodes = languages.map((language) => language.code);
        if (availableLangCodes.includes(langCode)) {
          this.props.setLocale(langCode);
        } else {
          this.props.setLocale(FALLBACK_LOCALE);
        }
      });
    }
  }

  render() {
    if (!this.props.activeLanguage) {
      return null;
    }
    return this.props.children;
  }
}

TranslationsWrapper.defaultProps = {
  activeLanguage: null,
  locale: null,
};

TranslationsWrapper.propTypes = {
  initialize: PropTypes.func.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.node,
  children: PropTypes.node.isRequired,
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  locale: state.locale.get('locale'),
});

export default connect(mapStateToProps, { setLocale })(withLocalize(TranslationsWrapper));
