import React from 'react';
import Routing from '../../core/routing/index';
import AuthorizedRoute from '../../common/components/navigation/AuthorizedRoute';

import { SUPERADMIN_USER } from '../../constants/userScopes';
import { ADMIN_COMPANIES, ADMIN_COMPANY_SIGN_UP, ADMIN_COMPANY_SIGN_UP_SUCCESS } from '../../constants/routes';
import AdminCompanySignUpSuccessContainer from './AdminCompanySignUpSuccessContainer';
import AdminCompanySignUpContainer from './AdminCompanySignUpContainer';

const { Route, Redirect } = Routing;

const FIRST_SCREEN = ADMIN_COMPANY_SIGN_UP;

const AdminCompanyRouting = () => (
  <>
    <Route exact path={ADMIN_COMPANIES} render={() => <Redirect to={FIRST_SCREEN} />} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_COMPANY_SIGN_UP} component={AdminCompanySignUpContainer} />
    <AuthorizedRoute exact only={[SUPERADMIN_USER]} path={ADMIN_COMPANY_SIGN_UP_SUCCESS} component={AdminCompanySignUpSuccessContainer} />
  </>
);

export default AdminCompanyRouting;
