import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import Routing from '../../core/routing/index';
import {
  spacings, colors, fontSizes, fontFamily,
} from '../styles/base.style';
import ScreenHeader from './header/ScreenHeader';
import ProgressBar from './ProgressBar';
import PrimaryText from './PrimaryText';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: spacings.xl,
  },
  steps: {
    fontSize: fontSizes.xxs,
    color: colors.magenta,
    fontFamily: fontFamily.SFProDisplayRegular,
    marginBottom: spacings.sm,
    alignItems: 'center',
    textAlign: 'center',

  },
  title: {
    fontSize: fontSizes.lg,
    fontFamily: fontFamily.SFProDisplaySemiBold,
    color: colors.greyishBrown,
    textAlign: 'center',
  },
  infoText: {
    width: '85%',
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: spacings.sm,
  },
});

class CreatorStepHeader extends Component {
  getMarginBottom = (marginBottom) => marginBottom !== undefined && { marginBottom };

  renderProgressBar = ({
    optional,
    maxSteps,
    infoText,
    progressColor,
    stepNo,
    translate,
  }) => (
    <>
      <Text style={styles.steps}>
        {translate(!optional ? 'companyGigCreator.header.requiredStep' : 'companyGigCreator.header.optionalStep')}
      </Text>
      {infoText ? (<PrimaryText style={styles.infoText}>{infoText}</PrimaryText>) : null}
      <ProgressBar progressColor={progressColor} progress={stepNo / maxSteps} />
    </>
  );

  render() {
    const {
      stepNo, title, marginBottom, translate, rightButton, onRightButtonPress, isEdit, mainView,
    } = this.props;
    return (
      <ScreenHeader
        title={translate(this.props.headerTranslationKey)}
        showBackArrow
        rightButton={rightButton}
        handlerForRightButton={onRightButtonPress}
        subheaderFunc={() => (
          stepNo
          && (
          <View style={[styles.container, this.getMarginBottom(marginBottom)]}>
            {title ? (<Text style={styles.title}>{title}</Text>) : null}
            {isEdit ? null : this.renderProgressBar(this.props)}
          </View>
          )
        )}
        headerStyle={mainView ? { marginBottom: spacings.lg } : null}
      />
    );
  }
}

const creatorStepHeaderExternalPropTypes = {
  optional: PropTypes.bool,
  mainView: PropTypes.bool,
  stepNo: PropTypes.number,
  title: PropTypes.string,
  marginBottom: PropTypes.number,
  infoText: PropTypes.string,
};

CreatorStepHeader.propTypes = {
  ...creatorStepHeaderExternalPropTypes,
  // eslint-disable-next-line react/no-unused-prop-types
  maxSteps: PropTypes.number,
  rightButton: PropTypes.func,
  onRightButtonPress: PropTypes.func,
  headerTranslationKey: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  progressColor: PropTypes.string,
};

CreatorStepHeader.defaultProps = {
  rightButton: undefined,
  onRightButtonPress: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  optional: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  stepNo: null,
  maxSteps: undefined,
  progressColor: colors.magenta,
};

export { creatorStepHeaderExternalPropTypes };
export default withRouter(withLocalize(CreatorStepHeader));
