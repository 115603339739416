import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FlatList, Platform, StyleSheet, Text, View,
} from 'react-native';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import shallowequal from 'shallowequal';

import {
  candidateFindGigsChangeOffset, candidateFindGigsSetScrollPosition, GIGS_LIMIT, clearGigList, candidateFindGigs,
} from './candidateFindGigsActions';
import { candidateClearSimilarGigs } from '../candidateSimilarGigsActions';
import { gigPropType } from '../../../constants/propTypes';
import { standardColors } from '../../../common/styles/base.style';
import RefreshButton from './RefreshButton';
import EmptyElementForPadding from '../../../common/components/EmptyElementForPadding';
import Routing from '../../../core/routing/index';
import { CITY_OPTION_GIGS_ANYWHERE } from './filters/candidatesFindGigsFiltersHelpers';
import LetsgigFindGigsListItem from './item/LetsgigFindGigsListItem';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  noGigs: {
    textAlign: 'center',
    backgroundColor: standardColors.listBackground,
    paddingTop: 20,
  },
  listContainer: {
    backgroundColor: standardColors.listBackground,
    flex: 1,
  },
});

class CandidateFindGigsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false, // we want to track locally when the list is refreshing (but not loading more)
      refreshCount: 0,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (['PUSH', 'REPLACE'].includes(history.action)) {
      this.loadGigs();
    } else {
      this.scrollToLastKnowPosition();
    }

    this.props.candidateClearSimilarGigs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.offset !== 0 && !this.props.allLoaded && (prevProps.offset !== this.props.offset)) {
      this.getGigsRequest(false, this.props.offset).then(() => this.unsetLoadingMore());
    }
    if (prevProps.cityKey !== this.props.cityKey
      || (!shallowequal(prevProps.categories, this.props.categories))
      || prevProps.positionSearch !== this.props.positionSearch) {
      this.loadGigs();
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    this.props.candidateFindGigsSetScrollPosition(this.scrollPosition != null ? this.scrollPosition : this.props.scrollPosition);
    if (!this.userOpenedGigDetailsView(history)) {
      this.props.clearGigList();
    }
  }

  onNearEndOfTheList = () => {
    if (!this.props.allLoaded && !this.loadingMore) {
      this.loadingMore = true;
      this.props.candidateFindGigsChangeOffset(this.props.offset + GIGS_LIMIT);
    }
  };

  onScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset.y;
  };

  onPullToReload = () => {
    this.loadGigs();
  };

  getGigsRequest = (reload, offset) => {
    const { cityKey, categories, positionSearch } = this.props;
    return this.props.candidateFindGigs(reload, offset, categories, cityKey === CITY_OPTION_GIGS_ANYWHERE ? null : cityKey, positionSearch);
  };

  setFlatListRef = (ref) => {
    this.flatListRef = ref;
  };

  setStateRefreshing = (cb) => {
    this.setState({ refreshing: true, refreshCount: this.state.refreshCount + 1 }, cb);
  };

  userOpenedGigDetailsView = (history) => (history && history.entries && history.entries.length > 0
    && history.entries[history.entries.length - 1].pathname.match('/candidate/gigs/.*/details') != null);

  loadGigs = () => {
    this.props.candidateFindGigsSetScrollPosition(0);
    this.flatListRef.scrollToOffset({ offset: 0, animated: false });
    this.setStateRefreshing(() => {
      // the RefreshControl doesn't work that well on ios, we need to enforce showing it.
      // https://github.com/facebook/react-native/issues/15892
      if (Platform.OS === 'ios') {
        this.flatListRef.scrollToOffset({ offset: -65, animated: true });
      }
    });
    this.refreshList();
  };

  refreshList = () => {
    this.props.candidateFindGigsChangeOffset(0);
    return this.getGigsRequest(true, 0)
      .then(() => {
        this.setState({ refreshing: false });
      });
  };

  keyExtractor = (refreshCount) => (item) => `${refreshCount}-${item.gigId}`;

  unsetLoadingMore() {
    // give some time to render the view with scroll offset in proper position and then unlock fetching more gigs
    setTimeout(() => { this.loadingMore = false; }, 400);
  }

  scrollToLastKnowPosition() {
    const { flatListRef } = this;
    const { scrollPosition } = this.props;
    setTimeout(() => flatListRef.scrollToOffset({ offset: scrollPosition, animated: false }), 0);
  }

  render() {
    const { allLoaded, gigs } = this.props;
    return (
      <>
        {allLoaded && !gigs.length && <Text style={styles.noGigs}><Translate id="candidateFindGigs.noGigs" /></Text>}
        <View style={styles.listContainer}>
          <FlatList
            ListHeaderComponent={Platform.OS === 'web' ? <RefreshButton handleRefresh={this.onPullToReload} /> : <EmptyElementForPadding padding={10} />}
            ListFooterComponent={<EmptyElementForPadding padding={10} />}
            ref={this.setFlatListRef}
            data={gigs}
            keyExtractor={this.keyExtractor(this.state.refreshCount)}
            renderItem={({ item }) => (<LetsgigFindGigsListItem gig={item} translate={this.props.translate} history={this.props.history} />)}
            onEndReached={this.onNearEndOfTheList}
            // depends on item height; condition where it's used: distanceFromEnd < onEndReachedThreshold * visibleLength
            // e.g. 10000 < onEndReachedThreshold * 600
            onEndReachedThreshold={3}
            refreshing={this.state.refreshing}
            onRefresh={this.onPullToReload}
            onScroll={this.onScroll}
            initialNumToRender={gigs.length}
          />
        </View>
      </>

    );
  }
}

CandidateFindGigsList.propTypes = {
  translate: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  cityKey: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  positionSearch: PropTypes.string,

  candidateFindGigsChangeOffset: PropTypes.func.isRequired,
  clearGigList: PropTypes.func.isRequired,
  candidateFindGigs: PropTypes.func.isRequired,
  candidateFindGigsSetScrollPosition: PropTypes.func.isRequired,
  candidateClearSimilarGigs: PropTypes.func.isRequired,

  gigs: PropTypes.arrayOf(gigPropType).isRequired,
  offset: PropTypes.number.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  allLoaded: PropTypes.bool.isRequired,
};

CandidateFindGigsList.defaultProps = {
  positionSearch: undefined,
  categories: undefined,
};

const mapStateToProps = (state) => {
  let selectedCategories = state.candidateFindGigsFilters.get('categories');
  let selectedCity = state.candidateFindGigsFilters.get('cityKey');

  if (!selectedCategories) {
    selectedCategories = state.candidate.get('profile').get('categories');
  }

  if (!selectedCity) {
    const location = state.candidate.get('profile').get('location');
    selectedCity = (location && location.get('cityV2').get('key')) || CITY_OPTION_GIGS_ANYWHERE;
  }

  return {
    offset: state.candidateFindGigs.get('offset'),
    scrollPosition: state.candidateFindGigs.get('scrollPosition'),
    allLoaded: state.candidateFindGigs.get('allLoaded'),
    gigs: state.candidateFindGigs.get('gigs').toJS(),
    cityKey: selectedCity,
    categories: selectedCategories.toJS(),
    positionSearch: state.candidateFindGigsFilters.get('positionSearch'),
  };
};

export default withRouter(connect(mapStateToProps, {
  candidateFindGigsChangeOffset,
  candidateFindGigsSetScrollPosition,
  clearGigList,
  candidateFindGigs,
  candidateClearSimilarGigs,
})(withLocalize(CandidateFindGigsList)));
