import React from 'react';
import {
  StyleSheet, Text, View, Image, ScrollView, Platform, Linking,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { toDate } from '../../utilities/dateFormatter';
import {
  colors, fontSizes, fontFamily, spacings,
} from '../styles/base.style';
import { gigPropType, stylePropTypes } from '../../constants/propTypes';
import { CANDIDATE_GIG_CHAT } from '../../constants/routes';
import LinkButton from '../components/buttons/LinkButton';
import GigDetailsImage from './GigDetailsImage';
import GigDetailsConditions from './GigDetailsConditions';
import PrimaryText from '../components/PrimaryText';
import { font } from '../styles/mixins';
import CategoryBadgeList from '../components/CategoryBadgeList';
import UserScope from '../auth/UserScope';
import { CANDIDATE_USER } from '../../constants/userScopes';
import SimilarGigs from './similarGigs/SimilarGigs';
import LocationMap from './LocationMap';
import Touchable from '../components/Touchable';
import GigDetailsFavouriteButtons from './GigDetailsFavouriteButtons';
import FeatureToggle from '../components/FeatureToggle';

const locationImage = require('../../assets/icons/singlegigview/location.png');

const styles = StyleSheet.create({
  headerWrapper: {
    flex: 1,
    borderBottomColor: colors.veryLightPinkTwo,
    borderBottomWidth: 1,
    paddingTop: spacings.md,
    marginHorizontal: spacings.lg,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: spacings.lg,
    marginBottom: spacings.md,
    paddingTop: spacings.md,
  },
  secondRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacings.lg,
    marginTop: spacings.md,
  },
  categoriesWrapper: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginBottom: spacings.md,
  },
  descriptionWrapper: {
    marginTop: spacings.sm,
    display: 'flex',
  },
  description: {
    marginBottom: 0,
    padding: spacings.md,
  },
  companyDescription: {
    marginBottom: 0,
    paddingVertical: spacings.md,
  },
  company: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.lg, 0.3),
    marginTop: 0,
  },
  companyWrapper: {
    flex: 1,
    paddingBottom: spacings.md,
    borderBottomColor: colors.veryLightPinkTwo,
    borderBottomWidth: 1,
    marginHorizontal: spacings.md,
  },
  position: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.xxl),
    marginBottom: spacings.md,
  },
  propertiesWrapper: {
    paddingTop: spacings.md,
    marginHorizontal: spacings.md,
  },
  expiryDate: {
    paddingTop: spacings.md,
    textAlign: 'center',
    ...font(fontFamily.SFProDisplayRegular, fontSizes.md),
  },
  locationWrapper: { flexDirection: 'row', alignItems: 'flex-start' },
  location: {
    color: colors.gondola,
    fontSize: fontSizes.xs,
    fontFamily: fontFamily.SFProDisplaySemiBold,
  },
  gigImage: {
    ...Platform.select({
      web: { height: 400 },
    }),
  },
  chatButton: {
    alignSelf: 'flex-start',
    marginTop: -16,
    height: 32,
    marginLeft: 21,
  },
  plainText: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.sm),
  },
  clickableText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm),
    textDecorationLine: 'underline',
  },
});

const GigDetails = (props) => {
  const {
    translate,
    translationKey,
    gig,
    similarGigs,
    renderChatButton,
    renderStatusLabel,
    showConditions,
    style,
    isFavourite,
    isNotInteresting,
    like, reject,
    showFavouritesButtons,
  } = props;

  const gigCityRegion = {
    longitude: gig.location.cityV2.geoLoc.coordinates[0],
    latitude: gig.location.cityV2.geoLoc.coordinates[1],
    latitudeDelta: 0.035,
    longitudeDelta: 0.035,
  };

  const openMaps = (city, street) => {
    Linking.openURL(`http://maps.google.com/?q=${city} ${street}`);
  };

  const openCompanyLink = () => {
    Linking.openURL('https://www.letsgig.se/bemanningsforetag-stockholm/');
  };

  const openRecruitmentLink = () => {
    Linking.openURL('https://www.letsgig.se/rekrytering/');
  };

  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={style && style.scrollViewContainer}>
      <GigDetailsImage
        gigImageStyles={styles.gigImage}
        gig={gig}
        renderStatusLabel={renderStatusLabel}
        isFavourite={isFavourite}
        isNotInteresting={isNotInteresting}
      />
      <UserScope allowedFor={[CANDIDATE_USER]}>
        {!!renderChatButton
          && (
          <View style={styles.chatButton}>
            <LinkButton
              size="sm"
              to={CANDIDATE_GIG_CHAT}
              title={translate('gigPreview.startChat')}
              replacePathValue={gig.gigId}
            />
          </View>
          )}
      </UserScope>
      <View style={styles.headerWrapper}>
        <Text style={[styles.position, style && style.position]}>{gig.position}</Text>
        <CategoryBadgeList
          style={styles.categoriesWrapper}
          categories={gig.categories}
        />
      </View>
      <View style={[styles.secondRowWrapper]}>
        <Touchable onPress={() => gig.location.street && openMaps(gig.location.cityV2.name, gig.location.street)} style={styles.locationWrapper}>
          <Image style={{ marginRight: 5 }} source={locationImage} />
          <Text style={styles.location}>
            {gig.location.cityV2.name}
          </Text>
        </Touchable>
        <UserScope allowedFor={[CANDIDATE_USER]}>
          <FeatureToggle requestedFeature="SHOW_FAVOURITE_GIGS">
            {showFavouritesButtons && (
            <GigDetailsFavouriteButtons
              isFavourite={isFavourite}
              isNotInteresting={isNotInteresting}
              translate={translate}
              like={like}
              reject={reject}
            />
            )}
          </FeatureToggle>
        </UserScope>
      </View>
      <PrimaryText style={styles.description}>{gig.description}</PrimaryText>
      {/* </View> */}
      {gig.company && !!gig.company.name && (
        <View style={styles.companyWrapper}>
          <Text style={styles.company}>{gig.company.name}</Text>
          <Text style={styles.company}>{gig.company.association}</Text>
          {!!gig.company.description && <PrimaryText style={styles.companyDescription}>{gig.company.description}</PrimaryText>}
          <Text style={[styles.plainText, { marginVertical: 10 }]}>
            <Text>Let's Gig - </Text>
            <Text style={styles.clickableText} onPress={openCompanyLink}>Bemanningsföretag</Text>
            <Text> & </Text>
            <Text style={styles.clickableText} onPress={openRecruitmentLink}>Rekrytering </Text>
          </Text>
        </View>
      )}
      <View style={[styles.contentWrapper, { paddingHorizontal: 0 }]}>
        {showConditions && <GigDetailsConditions gig={gig} translate={translate} translationKey={`${translationKey}.salary`} />}
        <Text style={[styles.expiryDate, !showConditions && { paddingTop: 5 }]}>
          {`${translate('companyGigCreator.preview.expiryDate')}: ${toDate(gig.expiryDate)}`}
        </Text>
      </View>
      <LocationMap
        initialRegion={gigCityRegion}
        street={gig.location.street}
        cityName={gig.location.cityV2.name}
        style={{ height: 200 }}
        showPin={false}
        showAddress
      />
      <SimilarGigs gigs={similarGigs} />
    </ScrollView>
  );
};

GigDetails.propTypes = {
  translate: PropTypes.func.isRequired,
  translationKey: PropTypes.string.isRequired,
  gig: gigPropType.isRequired,
  similarGigs: PropTypes.arrayOf(gigPropType),
  renderChatButton: PropTypes.bool,
  renderStatusLabel: PropTypes.bool,
  showConditions: PropTypes.bool,
  isNotInteresting: PropTypes.bool.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  style: stylePropTypes,
  like: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  showFavouritesButtons: PropTypes.bool,
};

GigDetails.defaultProps = {
  renderChatButton: false,
  renderStatusLabel: undefined,
  showConditions: true,
  style: null,
  similarGigs: null,
  showFavouritesButtons: false,
};

export default withLocalize(GigDetails);
