import React from 'react';
import { Platform } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import NavigationRightButtonWrapper from './NavigationRightButtonWrapper';
import Button, { BUTTON_TYPES } from '../buttons/Button';
import { spacings } from '../../styles/base.style';

const NavigationSaveButton = ({ onPress, isBtnDisabled, translate }) => (
  <NavigationRightButtonWrapper paddingTop={Platform.OS === 'web' ? spacings.lg : spacings.xs}>
    <Button
      onPress={onPress}
      title={translate('generic.save')}
      type={BUTTON_TYPES.TEXT}
      disabled={isBtnDisabled}
    />
  </NavigationRightButtonWrapper>
);

NavigationSaveButton.propTypes = {
  translate: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
  isBtnDisabled: PropTypes.bool,
};

NavigationSaveButton.defaultProps = {
  isBtnDisabled: false,
};

export default withLocalize(NavigationSaveButton);
