import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Text } from 'react-native';
import { withLocalize } from 'react-localize-redux';
import {
  colors, fontFamily, spacings, fontSizes,
} from '../../common/styles/base.style';
import { font } from '../../common/styles/mixins';
import { candidateChatPropType } from '../../constants/propTypes';

const styles = StyleSheet.create({
  label: {
    backgroundColor: colors.veryLightGrey,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 20.5,
    flexDirection: 'row',
    flex: 1,
    paddingLeft: spacings.sm,
    paddingRight: spacings.sm,
  },
  shape: {
    height: 22,
    borderRadius: 11,
    backgroundColor: colors.seablue,
    paddingHorizontal: spacings.xs,
    marginRight: spacings.xs,
    justifyContent: 'center',
  },
  shapeText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.xs, null, colors.white),
  },
  boldText: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.md, null, colors.greyishBrown),
  },
  text: {
    color: colors.brownGrey,
  },
});

const ChatListItemBadge = ({ candidateChats, translate }) => {
  const chatsTotal = candidateChats.length;
  const unreadChats = candidateChats.filter((c) => c.unread).length;
  const label = chatsTotal > 1 ? translate('companyChat.gigBadgeChats') : translate('companyChat.gigBadgeChat');
  return (
    <View style={styles.label}>
      {!!unreadChats && (
        <View style={styles.shape}>
          <Text style={styles.shapeText}>{unreadChats}</Text>
        </View>
      )}
      <Text style={styles.boldText}>
        {`${label}: `}
        <Text style={styles.text}>{chatsTotal}</Text>
      </Text>
    </View>
  );
};

ChatListItemBadge.propTypes = {
  translate: PropTypes.func.isRequired,
  candidateChats: PropTypes.arrayOf(candidateChatPropType).isRequired,
};

export default withLocalize(ChatListItemBadge);
