import { isFutureDateIgnoreTime } from '../../../../core/validation/validators';
import { getUtcOffsetForGigLocation } from '../../../../utilities/dateFormatter';

export default [
  {
    field: 'startDate',
    custom: (value) => isFutureDateIgnoreTime(value, getUtcOffsetForGigLocation()),
    message: 'past_date',
  },
  {
    field: 'endDate',
    custom: (value) => isFutureDateIgnoreTime(value, getUtcOffsetForGigLocation()),
    message: 'past_date',
  },
  {
    field: 'endDate',
    custom: (value, args, data) => {
      if (data.startDate && data.endDate) {
        return (data.endDate < data.startDate);
      }
      return false;
    },
    validWhen: false,
    message: 'before_start_date',
  },
];
