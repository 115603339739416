import React from 'react';
import Routing from '../../core/routing/index';

import CompanyWelcomeScreen from './CompanyWelcomeScreen';
import CompanyAutoCreateContainer from './autocreate/CompanyAutoCreateContainer';
import CompanySignupContainer from './CompanySignupContainer';
import CompanyTermsAgreementContainer from './CompanyTermsAgreementContainer';

import {
  COMPANY_AUTH,
  COMPANY_WELCOME,
  COMPANY_AUTOCREATE,
  COMPANY_LOGIN,
  COMPANY_SIGNUP,
  COMPANY_CONTACT_REQUEST_SENT,
  COMPANY_CONTRACT_AGREEMENT
} from '../../constants/routes';
import LoginContainer from '../../candidate/auth/LoginContainer';
import CompanyContactRequestSentScreen from './CompanyContactRequestSentScreen';

const { Route, Redirect } = Routing;

const CompanyAuthRouting = () => (
  <>
    <Route exact path={COMPANY_AUTH} render={() => <Redirect to={COMPANY_WELCOME} />} />
    <Route path={COMPANY_WELCOME} component={CompanyWelcomeScreen} />
    <Route exact path={COMPANY_SIGNUP} component={CompanySignupContainer} />
    <Route exact path={COMPANY_CONTACT_REQUEST_SENT} component={CompanyContactRequestSentScreen} />
    <Route path={COMPANY_AUTOCREATE} component={CompanyAutoCreateContainer} />
    <Route path={COMPANY_LOGIN} component={LoginContainer} />
    <Route path={COMPANY_CONTRACT_AGREEMENT} component={CompanyTermsAgreementContainer} />

  </>
);

export default CompanyAuthRouting;
