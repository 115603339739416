import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import CompanyGigDetailsScreen from './CompanyGigDetailsScreen';
import { getGig } from '../companyGigActions';

class CompanyGigDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gig: null,
    };
  }

  componentDidMount() {
    const gigId = this.props.match.params.id;
    this.props.getGig(gigId)
      .then((response) => {
        if (!response.isError) {
          this.setState({ gig: response.payload });
        }
      });
  }

  postCloseCallback = (gig) => this.setState({ gig });

  render() {
    return <CompanyGigDetailsScreen gig={this.state.gig} postCloseCallback={this.postCloseCallback} />;
  }
}

CompanyGigDetailsContainer.propTypes = {
  getGig: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default connect(null, { getGig })(CompanyGigDetailsContainer);
