import React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import HorizontalWrapper from '../HorizontalWrapper';
import {
  colors, fontFamily, fontSizes, spacings,
} from '../../styles/base.style';
import { font } from '../../styles/mixins';
import FormikFieldPrimaryInputTextNextSupport from './FormikFieldPrimaryInputTextNextSupport';
import { innerRefPropType, stylePropTypes } from '../../../constants/propTypes';
import FormikFieldPrimaryTextInput from './FormikFieldPrimaryTextInput';

const styles = StyleSheet.create({
  currencyLabel: {
    ...font(fontFamily.SFProDisplayMedium, fontSizes.xl, null, colors.lightPeach),
    marginTop: 22,
  },
  salary: {
    flex: 1,
    marginLeft: spacings.xs,
  },
});

const FormikFieldCompensationInput = ({
  nextComponent, leftLabel, leftLabelStyle, rightLabel, rightLabelStyle, wrapperStyle, style, ...props
}) => {
  const Component = nextComponent ? FormikFieldPrimaryInputTextNextSupport : FormikFieldPrimaryTextInput;

  let stylePack = { container: styles.salary };
  if (style) {
    stylePack = {
      ...stylePack,
      container: style.container,
      label: style.label,
      inputBackgroundWrapper: style.inputBackgroundWrapper,
      input: style.input,
    };
  }

  return (
    <HorizontalWrapper style={wrapperStyle}>
      {!!leftLabel && (<Text style={[styles.currencyLabel, leftLabelStyle]}>{leftLabel}</Text>)}
      <Component
        style={stylePack}
        keyboardType={Platform.OS === 'android' ? 'numeric' : 'number-pad'}
        returnKeyType="done"
        nextComponent={nextComponent}
        {...props}
      />
      {!!rightLabel && (<Text style={[styles.currencyLabel, rightLabelStyle]}>{rightLabel}</Text>)}
    </HorizontalWrapper>
  );
};

FormikFieldCompensationInput.propTypes = {
  nextComponent: innerRefPropType,
  leftLabel: PropTypes.string,
  leftLabelStyle: stylePropTypes,
  rightLabel: PropTypes.string,
  rightLabelStyle: stylePropTypes,
  wrapperStyle: stylePropTypes,
  style: stylePropTypes,
};

FormikFieldCompensationInput.defaultProps = {
  nextComponent: undefined,
  leftLabel: null,
  leftLabelStyle: null,
  rightLabel: null,
  rightLabelStyle: null,
  wrapperStyle: null,
  style: null,
};

export default FormikFieldCompensationInput;
