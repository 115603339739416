import {
  CANDIDATE_POOLS_LIST_REQUEST,
  CANDIDATE_POOLS_GET_REQUEST,
  CANDIDATE_POOLS_ACCEPT_INVITATION_REQUEST,
  CANDIDATE_POOLS_REJECT_INVITATION_REQUEST,
  CANDIDATE_POOLS_SIGN_OFF_REQUEST,
  CANDIDATE_POOLS_SET_NEW_CONTENT,
  CANDIDATE_POOLS_CLEAR_NEW_CONTENT,
  CANDIDATE_POOLS_STORE_SHIFTS_SCROLL_POSITION,
} from '../../constants/actionTypes';
import googleAnalytics, { ACTIONS } from '../../common/analytics/googleAnalytics';

export function get() {
  return {
    type: CANDIDATE_POOLS_LIST_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'candidate/candidatesPool',
      },
    },
  };
}

export function getPoolProfile(companyId) {
  return {
    type: CANDIDATE_POOLS_GET_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `candidate/candidatesPool/profile/${companyId}`,
      },
    },
  };
}

export function accept(companyId) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.MY_POOLS.ACCEPT_INVITATION);
  return {
    type: CANDIDATE_POOLS_ACCEPT_INVITATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/candidatesPool/${companyId}/accept`,
      },
    },
  };
}

export function reject(companyId) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.MY_POOLS.REJECT_INVITATION);
  return {
    type: CANDIDATE_POOLS_REJECT_INVITATION_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/candidatesPool/${companyId}/reject`,
      },
    },
  };
}

export function signOff(companyId) {
  googleAnalytics.trackCandidateEvent(ACTIONS.CANDIDATE.MY_POOLS.SIGN_OFF);
  return {
    type: CANDIDATE_POOLS_SIGN_OFF_REQUEST,
    payload: {
      request: {
        method: 'post',
        url: `/candidate/candidatesPool/${companyId}/signOff`,
      },
    },
  };
}

export function candidateSetMyPoolsNewContent(field, value) {
  return {
    type: CANDIDATE_POOLS_SET_NEW_CONTENT,
    field,
    value,
  };
}

export function candidateClearMyPoolsNewContent(field) {
  return {
    type: CANDIDATE_POOLS_CLEAR_NEW_CONTENT,
    field,
  };
}

export function storeShiftsScrollPosition(scrollPosition) {
  return {
    type: CANDIDATE_POOLS_STORE_SHIFTS_SCROLL_POSITION,
    scrollPosition,
  };
}
