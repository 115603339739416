import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {
  colors, fontFamily, fontSizes, shadowBottom, spacings,
} from '../../common/styles/base.style';
import Container from '../../common/components/Container';
import { font } from '../../common/styles/mixins';

const styles = StyleSheet.create({
  container: {
    ...shadowBottom,
    marginBottom: 0,
    paddingHorizontal: spacings.sm,
  },
  successContainer: {
    padding: spacings.md,
    backgroundColor: colors.greenishTeal,
    marginBottom: spacings.sm,
  },
  successText: {
    ...font(fontFamily.SFProDisplaySemiBold, fontSizes.sm, 0, colors.white),
  },
});

function AdminCompanySignUpSuccessContainer() {
  return (
    <Container>
      <View style={styles.container}>
        <View style={styles.successContainer}>
          <Text style={styles.successText}>Company created</Text>
        </View>
      </View>
    </Container>
  );
}

export default AdminCompanySignUpSuccessContainer;
