import { Map, List } from 'immutable';
import { STORE_LOG } from '../../constants/actionTypes';

const LOGS_LIST_SIZE = 100;

const INITIAL_APP_STATE = {
  logs: List(),
};

export default function logReducer(state = Map(INITIAL_APP_STATE), action) {
  let logs;
  switch (action.type) {
    case STORE_LOG:
      logs = state.get('logs');

      if (logs.size >= LOGS_LIST_SIZE) {
        logs = logs.shift();
      }
      logs = logs.push(action.log);

      return state.set('logs', logs);
    default:
      return state;
  }
}
