import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  View, Text, ActivityIndicator, TouchableOpacity, Image, StyleSheet,
} from 'react-native';
import { withLocalize } from 'react-localize-redux';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import Container from '../../../common/components/Container';
import Routing from '../../../core/routing/index';
import PrimaryFlatList from '../../../common/components/PrimaryFlatList';
import {
  activityIndicator, colors, fontFamily, fontSizes, spacings,
} from '../../../common/styles/base.style';
import { font } from '../../../common/styles/mixins';
import menuImg from '../../../assets/icons/header/menu.png';
import getCandidateMyPoolsActionSheet from './CandidateMyPoolsActionSheet';
import ListItem from '../../../common/components/ListItem';
import { storeCandidatesPoolScrollPosition } from '../../../company/candidatesPool/companyCandidatesPoolActions';
import { CANDIDATES_POOLS_CONTENT_FIELDS } from '../candidateMyPoolsReducer';
import { candidateClearMyPoolsNewContent } from '../candidateMyPoolsActions';
import Touchable from '../../../common/components/Touchable';
import { poolListEntryPropType } from '../../../constants/propTypes/candidatePropTypes';
import UserProfileImage from '../../../common/components/image/UserProfileImage';
import { CANDIDATE_MY_POOLS_DIRECT_CHAT } from '../../../constants/routes';
import PoolChatButton from '../../../common/chat/pool/PoolChatButton';

const { withRouter } = Routing;

const styles = StyleSheet.create({
  listItemContainer: {
    flexDirection: 'row',
    paddingHorizontal: spacings.sm,
    paddingVertical: spacings.sm,
    alignItems: 'center',
  },

  groupLabelUser: {
    fontSize: fontSizes.sm,
    fontFamily: fontFamily.SFProDisplayBold,
    color: colors.greyishBrown,
  },
  groupLabelContainer: {
    flex: 1,
    flexDirection: 'column',
    marginRight: spacings.sm,
  },
  groupLabelCompany: {
    ...font(fontFamily.SFProDisplayBold, fontSizes.sm, null, colors.brownGrey),
  },
  statusLabel: {
    ...font(fontFamily.SFProDisplayRegular, fontSizes.xs, null, colors.brownGrey),
  },
  profileImage: {
    width: 54,
    height: 54,
    borderRadius: 27,
    marginRight: spacings.sm,
  },
});

const hitSlop = {
  top: 30, bottom: 30, left: 5, right: 10,
};

class CandidateMyPoolsScreen extends Component {
  componentWillUnmount() {
    this.props.candidateClearMyPoolsNewContent(CANDIDATES_POOLS_CONTENT_FIELDS.MY_POOLS);
  }

  startChat = (poolCandidateId) => {
    this.props.history.push(CANDIDATE_MY_POOLS_DIRECT_CHAT.replace(':poolCandidateId', poolCandidateId));
  };

  showCandidateMyPoolsActionSheet(pool) {
    const {
      translate, onAccept, onReject, onSignOff, onOpenPoolProfile, onOpenDirectChat,
    } = this.props;

    const actionSheet = getCandidateMyPoolsActionSheet({
      translate,
      status: pool.status,
      onAccept: () => onAccept(pool.poolCandidateId),
      onReject: () => onReject(pool.poolCandidateId),
      onSignOff: () => onSignOff(pool.poolCandidateId),
      onOpenDirectChat: () => onOpenDirectChat(pool.poolCandidateId),
      onOpenPoolProfile: () => onOpenPoolProfile(pool.company.companyId),
    });
    this.props.showActionSheetWithOptions(actionSheet.options, actionSheet.handlers);
  }

  newContentOnPool(pool) {
    const { newContentList, newGroupChatMsgList } = this.props;
    return newContentList.includes(pool.poolCandidateId)
      || pool.groups.find((group) => newGroupChatMsgList[group.groupId] && newGroupChatMsgList[group.groupId].unread) != null;
  }

  render() {
    const {
      pools, loading, translate, onOpenPoolProfile,
    } = this.props;

    return (
      <Container>
        {(pools && !loading) && (
          <PrimaryFlatList
            emptyListTextKey={translate('candidate.myPools.pools.emptyList')}
            data={pools}
            keyExtractor={(pool) => pool.poolCandidateId}
            renderItem={({ item: pool }) => (
              <ListItem
                renderContent={() => (
                  <Touchable activeOpacity={0.5} onPress={() => onOpenPoolProfile(pool.company.companyId)}>
                    <View style={styles.listItemContainer}>
                      <UserProfileImage profileImageUri={pool.companyUser.profileImageUri} style={styles.profileImage} />
                      <View style={styles.groupLabelContainer}>
                        <Text style={styles.groupLabelUser}>
                          { pool.companyUser.firstName ? `${pool.companyUser.firstName} ${pool.companyUser.lastName}` : pool.companyUser.email }
                        </Text>
                        <Text style={styles.groupLabelCompany}>{`${translate('generic.at')} ${pool.company.name}`}</Text>
                        {pool.status === 'INVITED' && <Text style={styles.statusLabel}>{translate('candidate.myPools.pools.approvalAwaiting')}</Text>}
                      </View>
                      {pool.status === 'ACTIVE' && (
                        <PoolChatButton poolChatId={pool.poolCandidateId} onPress={() => this.startChat(pool.poolCandidateId)} />
                      )}
                      <TouchableOpacity
                        onPress={() => this.showCandidateMyPoolsActionSheet(pool)}
                        style={{ paddingLeft: spacings.md }}
                        hitSlop={hitSlop}
                      >
                        <Image source={menuImg} style={{ width: 18, height: 18 }} />
                      </TouchableOpacity>
                    </View>
                  </Touchable>
                )}
                style={this.newContentOnPool(pool) ? { borderColor: colors.seablue, borderWidth: 2 } : null}
              />
            )}
          />
        )}
        {(!pools || loading) && (
          <ActivityIndicator size="large" style={{ ...activityIndicator }} />
        )}
      </Container>
    );
  }
}

CandidateMyPoolsScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  translate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onSignOff: PropTypes.func.isRequired,
  onOpenPoolProfile: PropTypes.func.isRequired,
  onOpenDirectChat: PropTypes.func.isRequired,
  pools: PropTypes.arrayOf(poolListEntryPropType),
  showActionSheetWithOptions: PropTypes.func.isRequired,
  candidateClearMyPoolsNewContent: PropTypes.func.isRequired,
  newContentList: PropTypes.arrayOf(PropTypes.string).isRequired,
  newGroupChatMsgList: PropTypes.shape({
    unread: PropTypes.bool,
  }).isRequired,
};

CandidateMyPoolsScreen.defaultProps = {
  pools: [],
};

const mapStateToProps = (state) => ({
  newContentList: state.candidateMyPools.get('newContent').get(CANDIDATES_POOLS_CONTENT_FIELDS.MY_POOLS).toJS(),
  newGroupChatMsgList: state.poolChat.get('poolChatsData').toJS(),
});

export default connect(
  mapStateToProps,
  { storeScrollPosition: storeCandidatesPoolScrollPosition, candidateClearMyPoolsNewContent },
)(withLocalize(withRouter(connectActionSheet(CandidateMyPoolsScreen))));
