import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import AdminProfileSettingsScreen from './AdminProfileSettingsScreen';
import { logoutUser } from '../../../common/auth/authActions';
import { deleteAccount } from '../../../common/user/userActions';
import Routing from '../../../core/routing/index';
import { getInstallationId } from '../../../common/auth/deviceDetailsUtil';

const { withRouter } = Routing;

class AdminProfileSettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logsModalVisible: false };
  }

  onLogout = () => {
    this.props.logoutUser(getInstallationId());
  };

  onDelete = () => {
    this.props.deleteAccount();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  onOpenLogsModal = () => {
    this.setState({
      logsModalVisible: true,
    });
  };

  onCloseLogsModal = () => {
    this.setState({ logsModalVisible: false });
  };

  render() {
    return (
      <AdminProfileSettingsScreen
        onLogout={this.onLogout}
        onDelete={this.onDelete}
        handleBack={this.goBack}
        onOpenLogsModal={this.onOpenLogsModal}
        onCloseLogsModal={this.onCloseLogsModal}
        logsModalVisible={this.state.logsModalVisible}
      />
    );
  }
}

AdminProfileSettingsContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  logoutUser: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { logoutUser, deleteAccount })(AdminProfileSettingsContainer));
